import { combineReducers } from 'redux'
import { getAppData } from './appReducer'
import { updateErrorMsg } from './commonReducer'
import { updateSettings } from './settingReducer'
import { updateSessions } from './sessionReducer'
import { updateLocalData } from './localReducer'
import { updateResponses } from './responseReducer'
import { getDeviceData } from './deviceReducer'
import { updatePaxValidationData } from './paxValidationReducer'
import {updateCustomData} from 'custom/reducers/customReducer'

const rootReducer = combineReducers({
  kioskInfo: getAppData,
  deviceInfo: getDeviceData,
  settings: updateSettings,
  sessions: updateSessions,
  localData: updateLocalData,
  responses: updateResponses,
  errorDetails: updateErrorMsg,
  paxValidation: updatePaxValidationData,
  custom: updateCustomData,
})

export default rootReducer
