import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { Animation } from 'components'
import { DynamicImage, PageContent, PageSubContent, PageSubTitle, PageTitle } from 'components/styledComponents'
import { PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import usePassportScanner from 'hooks/usePassportScanner'
import { getAcuantClient, getBuildAccessibility, getDeviceManager, getSummaryStore, history, playSound } from 'main'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { documentCheck } from 'utils/documentCheck'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { GeneratePP, samplePPData } from 'utils/passportGenerator'
import { Footer } from '../footer'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const ScanOppositeDoc = (props) => {
  const summaryStore = getSummaryStore()
  const intl = useIntl()
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const buildAccessibility = getBuildAccessibility()
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const currentPassenger = useSelector((state) => state.paxValidation.currentPassenger)
  const paxFaceImage = useSelector((state) => state.paxValidation.paxFaceImage)
  const paxDocImage = useSelector((state) => state.paxValidation.paxDocImage)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const acuantClient = getAcuantClient()
  const isLandscape = useCheckLandscape()
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const scannedCallback = (mediaData, image, docType) => {
    console.log('what is the scanned value??????', mediaData, image, docType)
    logEvents(EventFlows.DocScan, EventFunctions.DocScanResult, 'Success')
    logEvents(EventFlows.DocScan, EventFunctions.DocType, docType)
    logEvents(EventFlows.DocScan, EventFunctions.DocMRZ, maskPCI(JSON.stringify(mediaData), deviceIds.PASSPORT_READER))
    if (image.faceImage) {
      dispatch(updatePaxValidationData('updateFaceImage', image.faceImage))
    }

    const socketStatus = acuantClient.getStatus()
    if (socketStatus) {
      console.log('check opposite acuant image::::', paxDocImage, image.docImage)
      logEvents(EventFlows.Acuant, EventFunctions.AcuantSecondSide, 'Yes')
      documentCheck(paxDocImage, image.docImage, null)
    } else {
      const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
      faceTracking.disable()
      faceTracking.OnDeviceEvent = null
      let newValidateResults = []
      let updatedItem = {}
      validatePaxResult.forEach((item) => {
        if (item.ordinal === currentPassenger.ordinal) {
          updatedItem = item
          updatedItem.passportScanned = false
          updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
          updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
          updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
          updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED

          newValidateResults.push(updatedItem)
        } else {
          newValidateResults.push(item)
        }
      })

      summaryStore.updateDocScan()
      summaryStore.updateDocScanError('ACCUANT_CONNECTION_FAIL')
      summaryStore.endDocScan(true)

      const updatePaxStatus = {
        ordinal: currentPassenger.ordinal,
        docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
        faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
        privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
      }

      dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
      dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
      dispatch(updatePaxValidationData('initialPaxData'))
      dispatch(updateLocalData('updateDeviceAttempts', 0))
    }
  }

  const [enable, disable, passportReaderCallback, popupContainer] = usePassportScanner(scannedCallback, sbdModel)

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js)  did mount,call scan passport enable function...')

    if (config.isCUSSRequired) {
      enable()
      setTimeout(() => {
        /*
        we see time to time, when the previous socket is not yet close, we already to scan oppositeDoc screen
        and try to open the next scoket. This will cause the socket fail to open. For this issue, we add a timer
        to prevent this situation happen: ticket number AS-346
        */
        acuantClient.open({})
      }, config.acuantReopenDelay * 1000)
    }

    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js) UNMOUNTED')
      //turnLights('scanPassport', false)
      if (config.isCUSSRequired) {
        appLog(TraceLevels.LOG_TRACE, '(ScanOppositeDoc.js) disable passport reader.')
        disable()
      }
    }
  }, [])

  const getNextPassenger = (validatePaxResult) => {
    let remains = null
    remains = validatePaxResult.filter((pax) => {
      return pax.passportScanned === false
    })
    if (remains.length > 0) {
      return remains[0]
    } else {
      return validatePaxResult[0]
    }
  }

  /** For Testing **/
  const handleClick = (e) => {
    let passportData = null
    let testPassenger = getNextPassenger(validatePaxResult)
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js) : handleClick() ... ' + e.currentTarget.id)
    switch (e.currentTarget.id) {
      case 'buttonScanDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        // passportData = GenerateChipPP(testPassenger.firstName, testPassenger.lastName, 'CAN', photo1)
        passportData = samplePPData
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        break
      case 'buttonSwipeDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        passportData = GeneratePP(
          testPassenger.firstName,
          testPassenger.lastName,
          testPassenger.dateOfBirth,
          'CAN',
          photo1
        )
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        break
      case 'buttonScanDocsRemove':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GeneratePP(
          testPassenger.firstName,
          testPassenger.lastName,
          testPassenger.dateOfBirth,
          'CAN',
          photo1
        )
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        setTimeout(() => disable(), 7000)
        break
      case 'buttonNotReadable':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportDamaged ')
        return passportReaderCallback({
          key: 'passportDamaged',
          value: ''
        })
      case 'buttonInvalidPassport':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GeneratePP('FirstName', 'LastName', null)
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanOppositeDoc.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        break
      case 'buttonCloseSocket':
        acuantClient.close()
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'push: /')
        history.push(config.firstScreen)
    }
  }

  const testButtons = [
    {
      id: 'buttonScanDocs',
      text: 'Scan',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonSwipeDocs',
      text: 'Swipe',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanDocsRemove',
      text: 'Remove',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonInvalidPassport',
      text: 'Invalid',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonNotReadable',
      text: 'Not Readable',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonCloseSocket',
      text: 'Close Socket',
      handler: handleClick,
      cssName: 'test-button-up'
    }
  ]

  const getScanSequence = (validatePaxResult) => {
    let remains = null
    remains = validatePaxResult.filter((pax) => {
      return pax.passportScanned === true
    })
    return remains.length + 1
  }

  let travelerName = currentPassenger
    ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
    : getScanSequence(validatePaxResult)

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const animationSection = themeContext.ImageScanDocument ? (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${themeContext.ImageScanDocument}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  ) : (
    <>
      <PageSubContent flexFlow={'row'} padding={'0'}>
        <PageContent padding={isLandscape ? '0 20px' : '0 5px'}>
          <DynamicImage imageName={'scan-card.png'} width={isLandscape ? 447 : 335} height={isLandscape ? 320 : 240} />
          <PageSubContent width={isLandscape ? '447px' : '335px'} background={'grey'} padding={'0'}>
            <PageTitle minHeight={isLandscape ? '60px' : '80px'} fontSize={'18px'} color={'white'}>
              {intl.formatMessage(messages.ScanCard_Title)}
            </PageTitle>
          </PageSubContent>
        </PageContent>
        <PageContent padding={isLandscape ? '0 20px' : '0 5px'}>
          <DynamicImage
            imageName={'scan-passport.png'}
            width={isLandscape ? 447 : 335}
            height={isLandscape ? 320 : 240}
          />
          <PageSubContent width={isLandscape ? '447px' : '335px'} background={'grey'} padding={'0'}>
            <PageTitle minHeight={isLandscape ? '60px' : '80px'} fontSize={'18px'} color={'white'}>
              {intl.formatMessage(messages.ScanPassport_Title)}
            </PageTitle>
          </PageSubContent>
        </PageContent>
      </PageSubContent>
    </>
  )

  const textSection = (
    <>
      <PageTitle
        minHeight={'30px'}
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        alignItems="center"
        justifyContent="center"
        color={themeContext.PrimaryFontColor}
      >
        {travelerName}
      </PageTitle>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.ScanOppositeDocs_Body)}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.ScanDocs_SubTitle)}
      </PageSubTitle>
    </>
  )
  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        testData={testButtons}
      />
    </>
  )

  const popupSection = (
    <>
      {popupContainer.removePassport}
      {popupContainer.retryMediaAccess}
      {popupContainer.processingPassport}
    </>
  )

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const textParameters = [
      intl.formatMessage(messages.ScanOppositeDocs_Body),
      intl.formatMessage(messages.ScanDocs_SubTitle)
    ]
    const accDef = {
      pathName: 'ScanOppositeDoc',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters }]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ScanOppositeDoc
