import { LocalActions } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'
import { isEmpty } from 'utils/helper'
export function updateLocalData(type, obj) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'updateLocalData ... ' + type)
  switch (type) {
    case 'switchLocale':
      return {
        type: LocalActions.SWITCH_LOCALE,
        locale: obj
      }
    case 'switchDirection':
      return {
        type: LocalActions.SWITCH_DIRECTION,
        direction: obj
      }
    case 'updateBarcodeAttempts':
      return {
        type: LocalActions.UPDATE_BARCODE_ATTEMPTS,
        errorBarcodeAttempts: obj
      }
    case 'updateDeviceAttempts':
      return {
        type: LocalActions.UPDATE_DEVICE_ATTEMPTS,
        errorDeviceAttempts: obj
      }
    case 'initialLocalData':
      return {
        type: LocalActions.INITIAL_LOCAL_DATA
      }
    case 'clearLocalData':
      return {
        type: LocalActions.CLEAR_LOCAL_DATA
      }
    case 'active':
      return {
        type: LocalActions.ACTIVE,
        active: obj
      }
    case 'accessibleMode':
      return {
        type: LocalActions.ACCESSIBLE_MODE,
        accessibleMode: obj
      }
    case 'quitReason':
      return {
        type: LocalActions.QUIT_REASON,
        quitReason: obj
      }
    case 'quitDetail':
      return {
        type: LocalActions.QUIT_DETAIL,
        quitDetail: obj
      }
    case 'appFlow':
      appLog(TraceLevels.LOG_EXT_TRACE, '==> appFlow: ' + obj)
      return {
        type: LocalActions.APP_FLOW,
        appFlow: obj
      }
    case 'BagsDetail':
      return {
        type: LocalActions.BAGS_DETAILS,
        BagsDetail: obj
      }
    case 'BagsEntryDetail':
      return {
        type: LocalActions.BAGS_ENTRY_DETAILS,
        BagsEntryDetail: obj
      }
    case 'transactionDetail':
      return {
        type: LocalActions.TRANSACTION_DETAIL,
        transactionDetail: obj
      }
    case 'startTxnNextScreen':
      return {
        type: LocalActions.START_TXN_NEXT_SCREEN,
        startTxnNextScreen: obj
      }
    case 'bagtagObject':
      return {
        type: LocalActions.BAGTAG_OBJECT,
        bagtagObject: obj
      }
    case 'bagMeasurements':
      return {
        type: LocalActions.BAG_MEASUREMENTS,
        bagMeasurements: obj
      }
    case 'prevBagtagObject':
      return {
        type: LocalActions.PREV_BAGTAG_OBJECT,
        prevBagtagObject: obj
      }
    case 'prevBagMeasurements':
      return {
        type: LocalActions.PREV_BAG_MEASUREMENTS,
        prevBagMeasurements: obj
      }
    case 'prevBagOnQBelt':
      return {
        type: LocalActions.PREV_BAG_ON_QBELT,
        prevBagOnQBelt: obj
      }
    case 'numberOfProcessedBags':
      return {
        type: LocalActions.NUMBER_OF_PROCESSED_BAGS,
        numberOfProcessedBags: obj
      }
    case 'bagtag':
      return {
        type: LocalActions.BAGTAG,
        bagtag: obj
      }
      case 'scannedBagtag':
        return {
          type: LocalActions.SCANNED_BAGTAG,
          scannedBagtag: obj
        }  
    case 'activatedBagtags':
      return {
        type: LocalActions.ACTIVATED_BAGTAGS,
        activatedBagtags: obj
      }
    case 'overrideCode':
      return {
        type: LocalActions.OVERRIDE_CODE,
        overrideCode: obj
      }
    case 'overrideCodeScan':
      return {
        type: LocalActions.OVERRIDE_CODE_FOR_SCAN,
        overrideCodeScan: obj
      }
    case 'totalWeight':
      return {
        type: LocalActions.TOTAL_WEIGHT,
        totalWeight: obj
      }
    case 'ticket':
      return {
        type: LocalActions.TICKET,
        printStream: obj
      }
    case 'pectab':
      return {
        type: LocalActions.TICKET_PECTAB,
        pectab: obj
      }
    case 'printTransition':
      return {
        type: LocalActions.PRINTDATA_TRANSITION,
        printTransition: obj
      }
    case 'bagProcessStatus':
      return {
        type: LocalActions.BAG_PROCESS_STATUS,
        bagProcessStatus: obj
      }
    case 'AEA_BagdropMsg':
      return {
        type: LocalActions.AEA_BAGDROP_MSG,
        bagdropMsg: obj
      }
    case 'updateTravelDocAttempts':
      return {
        type: LocalActions.UPDATE_DOCUMENT_ATTEMPTS,
        errorTravelDocAttempts: obj
      }
    case 'updatePhotoInfo':
      return {
        type: LocalActions.UPDATE_PHOTO_INFO,
        photoInfo: obj
      }
    case 'updateTravelDoc':
      return {
        type: LocalActions.UPDATE_TRAVEL_DOC,
        travelDoc: obj
      }
    case 'updateTravelDocImage':
      return {
        type: LocalActions.UPDATE_TRAVEL_DOC_IMAGE,
        travelDocImage: obj
      }
    case 'updatePhotoAttempts':
      return {
        type: LocalActions.UPDATE_PHOTO_ATTEMPTS,
        errorPhotoAttempts: obj
      }
    case 'updateErrorName':
      return {
        type: LocalActions.UPDATE_ERROR_NAME,
        errorName: obj
      }
    case 'updateBarcodeData':
      return {
        type: LocalActions.UPDATE_BARCODE_DATA,
        barcodeData: obj
      }
    case 'updateMismatchBPError':
      return {
        type: LocalActions.MISMATCH_BP_ERROR,
        mismatchBPError: obj
      }
    case 'updateBagObj':
      return {
        type: LocalActions.UPDATE_BAG_OBJ,
        bagObj: obj
      }
    case 'updateCarrierCode':
      return {
        type: LocalActions.UPDATE_CARRIER_CODE,
        carrierCode: obj
      }
    case 'updateServerTime':
      return {
        type: LocalActions.UPDATE_SERVER_TIME,
        serverTime: obj
      }
    case 'updateClientBaseTime':
      return {
        type: LocalActions.UPDATE_CLIENT_BASE_TIME,
        clientBaseTime: obj
      }
    case 'updateStartTransactionTime':
      return {
        type: LocalActions.UPDATE_START_TRANSACTION_TIME,
        startTransactionTime: obj
      }
    case 'updateErrorCodeList':
      return {
        type: LocalActions.UPDATE_ERROR_CODE_LIST,
        errorCodeList: obj
      }
    case 'updateTotalWeightLimit':
      return {
        type: LocalActions.TOTAL_WEIGHT_LIMIT,
        totalWeightLimit: obj
      }
    case 'updateValidatePaxResult':
      return {
        type: LocalActions.VALIDATE_PAX_RESULT,
        validatePaxResult: obj
      }
    case 'updateSequence':
      return {
        type: LocalActions.UPDATE_SEQUENCE,
        sequence: obj
      }
    case 'updateCurrentPassenger':
      return {
        type: LocalActions.UPDATE_CURRENT_PASSENGER,
        currentPassenger: obj
      }
    case 'updateIsLastPassenger':
      return {
        type: LocalActions.UPDATE_IS_LAST_PASSENGER,
        isLastPassenger: obj
      }
    case 'updatePooling':
      return {
        type: LocalActions.UPDATE_POOLING,
        pooling: obj
      }
    case 'updateBaggageLoadedStatus':
      return {
        type: LocalActions.UPDATE_BAGGAGE_LOADED_STATUS,
        baggageLoadedStatus: obj
      }
    case 'updateBqCommand':
      return {
        type: LocalActions.UPDATE_BQ_COMMAND,
        bqCommand: obj
      }
    case 'updateFaceMatchingStage':
      return {
        type: LocalActions.UPDATE_FACE_MATCHING_STAGE,
        faceMatchingStage: obj
      }
    case 'updateItineraryObj':
      return {
        type: LocalActions.UPDATE_ITINERARY_OBJ,
        itineraryObj: obj
      }
    case 'updateNextBagQuestion':
      return {
        type: LocalActions.UPDATE_NEXT_BAG_QUESTION,
        nextBagQuestion: obj
      }
    case 'updateTotalBagWeight':
      return {
        type: LocalActions.UPDATE_TOTAL_BAG_WEIGHT,
        totalBagWeight: obj
      }
    case 'updateBagWeight':
      let weightString = obj
      let weightNumber = Number(obj)
      if (weightNumber !== NaN && config.weightWholeNumber) {
        weightString = Math.round(weightNumber)
      }
      return {
        type: LocalActions.UPDATE_BAG_WEIGHT,
        bagWeight: weightString
      }
    case 'updateBagWeightUnit':
      return {
        type: LocalActions.UPDATE_BAG_WEIGHT_UNIT,
        bagWeightUnit: obj
      }
    case 'updateBagAllowance':
      return {
        type: LocalActions.UPDATE_BAG_ALLOWANCE,
        bagAllowance: obj
      }
    case 'updateTubUsed':
      return {
        type: LocalActions.TUB_USED,
        tubUsed: obj
      }      
    default:
  }
}

export function setBagProcessStatus(statusId, checkmark) {
  appLog(TraceLevels.LOG_EXT_TRACE, '>> (actions.js) setBagProcessStatus() - BAG_PROCESS_STATUS = ')
  appLog(TraceLevels.LOG_EXT_TRACE, '\n\tstatusId: "' + JSON.stringify(statusId) + '"')
  appLog(TraceLevels.LOG_EXT_TRACE, '\n\tcheckmark: "' + JSON.stringify(checkmark) + '"')
  //appLog(TraceLevels.LOG_EXT_TRACE,'setBagProcessStatus: ' + statusId + ' ' + checkmark )
  return {
    type: LocalActions.BAG_PROCESS_STATUS,
    statusId: statusId,
    checkmark: checkmark
  }
}
