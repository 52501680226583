import React, { useRef, useContext } from 'react'
import { Button, AuroraPanel, Popup, IconButton } from 'components'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import {
  PopupTitle,
  PopupSubTitle,
  Spacer,
  PageSubContent,
  DynamicImage,
  PageSubTitle,
  PageTitle
} from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'

const HelpTxn = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const { visible, popupCallback, buttonGap } = props
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 500, height: 500 },
    { width: 350, height: 350 }
  ])

  //   const onYesBtnClicked = () => {
  //     console.log('QuitTxn onYesBtnClicked() ... cancel transaction')
  //     if (popupCallback) {
  //       popupCallback('YES')
  //     }
  //   }

  const onNoBtnClicked = () => {
    console.log('HelpTxn onNoBtnClicked() ... back to page')
    if (popupCallback) {
      popupCallback('NO')
    }
  }
  return (
    <Popup visible={visible} cssDialogName="popup-dialog">
      <PageSubContent width="100%">
        {themeContext.ImageHelp ? (
          <PageSubContent padding={themeContext.Popup.imagePadding[ratioKey]}>
            <DynamicImage
              imageName={themeContext.ImageHelp}
              width={animationSize.width}
              height={animationSize.height}
            />
          </PageSubContent>
        ) : (
          ''
        )}
        <div id="helpTxnLabel" tabIndex="0">
          <PageSubContent flexFlow={'column'} padding="10px">
            <PopupTitle
              justifyContent={themeContext.TextJustifyContent}
              fontSize={themeContext.Popup.titleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              {formatMessage(messages.HelpTxnTitle)}
            </PopupTitle>
            <PopupSubTitle
              justifyContent={themeContext.TextJustifyContent}
              fontSize={themeContext.Popup.subTitleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              {formatMessage(messages.HelpTxnSubTitle)}
            </PopupSubTitle>
          </PageSubContent>
        </div>
        <Spacer height={'50px'} />
        <PageSubContent flexFlow={themeContext.Popup.buttonFlow}>
          <IconButton
            id={'helpTxnNo'}
            color={themeContext.HelpCancelButton.color}
            bgColor={themeContext.HelpCancelButton.bgColor}
            width={themeContext.HelpCancelButton.width[ratioKey]}
            height={themeContext.HelpCancelButton.height[ratioKey]}
            //disable={props.disableAction}
            buttonPosition={themeContext.HelpCancelButton.buttonPosition}
            iconLocation={themeContext.HelpCancelButton.buttonPosition}
            padding={themeContext.HelpCancelButton.padding}
            onClick={onNoBtnClicked}
            fontSize={themeContext.HelpCancelButton.fontSize[ratioKey]}
            text={formatMessage(messages.buttonHelpCancel)}
            icon={themeContext.HelpCancelButton.icon}
            iconColor={themeContext.HelpCancelButton.iconColor}
            borderRadius={themeContext.HelpCancelButton.borderRadius[ratioKey]}
            border={themeContext.HelpCancelButton.border ? themeContext.BackButton.border : ''}
          />
        </PageSubContent>
      </PageSubContent>
    </Popup>
  )
}

export default HelpTxn
