import { endBiometricsTransaction } from 'actions/biometricsApis'
import { END_TXN_REASON } from 'constants/Constants'
import { history, store } from 'main'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { logError, logInfo } from 'utils/Logger'
import { fetchWithTimeout } from './FetchWithTimeout'

const matchOneToMany = (visiblePhoto, session, callback, isEndTransaction = true) => {
  history.push('pleaseWait')
  const sessionInfo = session ? session: {
    etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
    emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
  }
  const request = {
    sessionInfo: sessionInfo,
    photo: visiblePhoto,
    currentClientTime: new Date().getTime(),
    asyncRequested: true,
    carrierCode: undefined,
    jsonExtend: undefined
  }

  
  logInfo('matchOneToMany:: request ... ' + JSON.stringify(request))
  fetchWithTimeout('matchOneToMany/', request)
    .then((json) => {
      logInfo('matchOneToMany receive response ... ', json)
      if (isEndTransaction) {
        endBiometricsTransaction(sessionInfo, END_TXN_REASON.COMPLETE_OK,null,null)
      }      
      if (json.etsResponse) {         
        if (json.etsResponse.matched) {
          logEvents(
            EventFlows.FaceMatchN,
            EventFunctions.FaceMatchResult,
            'Success'
          )   
          const travelerInfo = json.etsResponse.travelerInfo
          if (travelerInfo) {            
            /* let recordLocator = {
              pnr: json.etsResponse.travelerInfo.pnr,
              firstName: json.etsResponse.travelerInfo.firstName,
              lastName: json.etsResponse.travelerInfo.lastName
            } */
            //store.dispatch(setTravelerInfo(travelerInfo))
            if (config.enableConfirmFaceMatched) {
              history.push('confirmCheckin')
            } else {
              console.log('travelerInfo:', travelerInfo) 
              //startEtsTransaction(store, 'SBD', getVersion(),{barcodeData: json.etsResponse.boardingPass.bcbp}, sessionInfo.etsSessionID )
              if (callback) {
                callback(sessionInfo.etsSessionID, {barcodeData: json.etsResponse.boardingPass.bcbp})
              }
            }
            
          } else {
            // non cuss env, go to inactive.
            history.push('inactive')
          }
        } else {
          // not match / registerred
          logInfo('matchOneToMany:: not matched')
          logEvents(
            EventFlows.FaceMatchN,
            EventFunctions.FaceMatchResult,
            'Fail'
          )   
          //history.push('welcome')
          if (callback) {
            callback(sessionInfo.etsSessionID, null)
          }
        }
        
      } else {
        logEvents(
          EventFlows.FaceMatchN,
          EventFunctions.FaceMatchResult,
          'Fail'
        )   
        logInfo('matchOneToMany:: not matched')
        if (callback) {
          callback(sessionInfo.etsSessionID, null)
        }
        //history.push('welcome')
      }
    })
    .catch((err) => {
      logError('matchOneToMany Catch:' + err)
      if (callback) {
        callback(sessionInfo.etsSessionID, null)
      }
      //history.push('welcome')
    })
}
export default matchOneToMany