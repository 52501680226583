import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Payment listener.
 *  @extends {Listener}
 */
export default class PaymentListener extends Listener {
  /** Default stateChanged callback
   * @param {string} state - new_state, old_state  -----> Example) "STATE_READY, STATE_PENDING"
   * @example
   * STATE_UNAVAILABLE – offline
   * STATE_INITIALIZING – initially initializing
   * STATE_WAITING – expecting user interaction and waiting for amount to be charged to customer
   * STATE_READY – amount has been set by the application
   * STATE_PENDING – transaction has been started by application and waiting result of transaction from device
   * STATE_SUCCESS – transaction has been approved
   * STATE_FAILURE – transaction has failed
   */
  stateChanged(state) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PaymentListener.stateChanged() called')
    this.setEvent('stateChanged', state)
  }

  /**
   * Default getTransactionResult callback
   * @param {string} result - result of payment transaction
   */
  getTransactionResult(result) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PaymentListener.getTransactionResult() called')
    this.setEvent('getTransactionResult', result)
  }

  /**
   * Default getPaymentState callback.
   * @param {string} state - state of payment device
   */
  getPaymentState(state) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PaymentListener.getPaymentState() called')
    this.setEvent('getPaymentState', state)
  }

  /**
   *  Default keyPressed callback.
   */
  keyPressed() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PaymentListener.keyPressed() called')
    this.setEvent('keyPressed')
  }

  /**
   *  Default messageChanged callback.
   */
  messageChanged() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PaymentListener.messageChanged() called')
    this.setEvent('messageChanged')
  }
}
