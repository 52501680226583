import React, { useRef, useContext } from 'react'
import { Button, AuroraPanel, Popup, IconButton } from 'components'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import {
  PopupTitle,
  PopupSubTitle,
  Spacer,
  PageSubContent,
  DynamicImage,
  PageSubTitle,
  PageTitle
} from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'

const OverweightTxn = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const { visible, popupCallback, buttonGap } = props
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 500, height: 500 },
    { width: 350, height: 350 }
  ])

    const onYesBtnClicked = () => {
      console.log('QuitTxn onYesBtnClicked() ... cancel transaction')
      if (popupCallback) {
        popupCallback('YES')
      }
    }

  // const onNoBtnClicked = () => {
  //   console.log('OverweightTxn onNoBtnClicked() ... back to page')
  //   if (popupCallback) {
  //     popupCallback('NO')
  //   }
  // }
  return (
    <Popup visible={visible} cssDialogName="popup-dialog">
      <PageSubContent width="100%">
        {/* <PageSubContent padding={themeContext.Popup.imagePadding[ratioKey]}>
          <DynamicImage imageName={props.popupImage} width={animationSize.width} height={animationSize.height} />
        </PageSubContent> */}
        <div id="overweightTxnLabel" tabIndex="0">
          <PageSubContent flexFlow={'column'} padding="10px">
            <PopupTitle
              justifyContent={themeContext.TextJustifyContent}
              fontSize={themeContext.Popup.titleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              {formatMessage(messages.OverWeightRepackTitle)}
            </PopupTitle>
            <PopupSubTitle
              justifyContent={themeContext.TextJustifyContent}
              fontSize={themeContext.Popup.subTitleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              {formatMessage(messages.OverWeightRepackSubTitle)}
            </PopupSubTitle>
          </PageSubContent>
        </div>
        <Spacer height={'50px'} />
        <PageSubContent flexFlow={themeContext.Popup.buttonFlow}>
          <IconButton
            id={'overweightTxnYes'}
            color={themeContext.CancelButton.color}
            bgColor={themeContext.CancelButton.bgColor}
            width={themeContext.CancelButton.width[ratioKey]}
            height={themeContext.CancelButton.height[ratioKey]}
            //disable={props.disableAction}
            buttonPosition={themeContext.CancelButton.buttonPosition}
            iconLocation={themeContext.CancelButton.buttonPosition}
            padding={themeContext.CancelButton.padding}
            onClick={onYesBtnClicked}
            fontSize={themeContext.CancelButton.fontSize[ratioKey]}
            text={formatMessage(messages.buttonQuitYes)}
            icon={themeContext.CancelButton.icon}
            iconColor={themeContext.CancelButton.iconColor}
            borderRadius={themeContext.CancelButton.borderRadius[ratioKey]}
            border={themeContext.CancelButton.border ? themeContext.CancelButton.border : ''}
          />
        </PageSubContent>
      </PageSubContent>
    </Popup>
  )
}

export default OverweightTxn
