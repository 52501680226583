import device from 'devices/device'
import {deviceIds, TraceLevels } from 'constants/Constants'
import BagtagPrinterListener from 'listeners/bagtagPrinterListener'

/**
 *  Device representing a Bagtag Printer
 *  @extends {Device}
 */
export default class BagtagPrinter extends device
{
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Bagtag Printer'
    /**
     * Device id - deviceIds.BAGTAG_PRINTER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.BAGTAG_PRINTER
    this.setListener(new BagtagPrinterListener())
  }

  /**
   *  Asynch call to determine whether all bins are empty
   */
  areAllBinsEmpty()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'areAllBinsEmpty().')
      this.socket.sendRequest(this.deviceId, 'areAllBinsEmpty')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'areAllBinsEmpty() exception: ' + e)
    }
  }

  /**
   *  Asynch call to determine whether pectabs have previously been loaded into the printer
   */
  isPECTABLoadedInPrinter()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPECTABLoadedInPrinter().')
      this.socket.sendRequest(this.deviceId, 'isPECTABLoadedInPrinter')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPECTABLoadedInPrinter() exception: ' + e)
    }
  }
  /**
   *  Asynch call to determine the BagtagPrinterBinStatus
   */
  getBinStatus()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'getBinStatus().')
      this.socket.sendRequest(this.deviceId, 'getBinStatus')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getBinStatus() exception: ' + e)
    }
  }

  /**
   *  Load a pectab into the printer
   *  @param {String} pectab - pectab to be loaded (can also be a file)
   *  @param {...String} params -
   */
  loadPECTAB(pectab, ...params)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'loadPECTAB().')
      this.socket.sendCommand(this.deviceId, 'loadPECTAB', pectab, ...params)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'loadPECTAB() exception: ' + e)
    }
  }

  /**
   *  Load a pectab into the printer
   *  @param {String} pectab pectab to be loaded in Encoded in Base64
   */
  loadPECTABBase64(pectab)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'loadPECTABBase64().')
      this.socket.sendCommand(this.deviceId, 'loadPECTAB', pectab, true)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'loadPECTABBase64() exception: ' + e)
    }
  }

  /**
   *  Load a pectab into the printer
   *  @param {String[]} arrayPectabs - array of pectabs to be loaded
   *  @param {Boolean} isBase64Encoded - true when encoded, false otherwise
   */
  loadPECTABArray(arrayPectabs, isBase64Encoded)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'loadPECTABArray() lth: ' + arrayPectabs.length)
      this.socket.sendCommand(this.deviceId, 'loadPECTABArray', arrayPectabs, isBase64Encoded)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'loadPECTABArray() exception: ' + e)
    }
  }

  /**
   *  Asynch call to offer the bagtag to the user
   *  @param {int} timeout Timeout to wait in msec.
   */
  offer(timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'offer(): ' + timeout)
      this.socket.sendRequest(this.deviceId, 'offer', timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'offer() exception: ' + e)
    }
  }

  /**
   *  Print a data stream
   *  @param {String} stream Datastream
   *  @param {int} timeout Timeout to wait in msec.
   */
  print(stream, timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'print() lth: ' + (stream ? stream.length : '0') + ', ' + timeout)
      this.socket.sendCommand(this.deviceId, 'print', stream, timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'print() exception: ' + e)
    }
  }

  /**
   *  Print a data stream
   *  @param {String} stream Datastream
   *  @param {int} timeout Timeout to wait in msec.
   */
  printBase64(stream, timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printBase64() lth: ' + (stream ? stream.length : '0') + ', ' + timeout)
      this.socket.sendCommand(this.deviceId, 'print', stream, true, timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printBase64() exception: ' + e)
    }
  }

  /**
   *  Print an array of data streams
   *  @param {String[]} arrayStream array of Datastreams
   *  @param {int} timeout Timeout to wait in msec.
   */
  printArray(arrayStream, timeout)
  {
    //convert the javascript array and encapsulate it
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printArray() lth: ' + (arrayStream ? arrayStream.length : '0') + ', ' + timeout)
      this.socket.sendCommand(this.deviceId, 'printArray', arrayStream, false, timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printArray() exception: ' + e)
    }
  }
  
  /**
   *  Print an array of data streams in base64 format
   *  @param {String[]} arrayStream array of Datastreams in base64
   *  @param {int} timeout Timeout to wait in msec.
   */
  printBase64Array(arrayStream, timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printBase64Array() lth: ' + (arrayStream ? arrayStream.length : '0') + ', ' + timeout)
      this.socket.sendCommand(this.deviceId, 'printArray', arrayStream, true, timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printBase64Array() exception: ' + e)
    }
  }
}
