import { PageHeader, PageText, Spacer } from 'components/styledComponents'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility, getSBDAppMan, history, playSound } from 'main'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { formatCurrency, getScreenId } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { Footer } from '../footer'

const isQuitRequired = false

const DisplayPaymentReceipt = (props) => {
  const intl = useIntl()
  const paymentInfo = useSelector((state) => state.responses.overweightPayment)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  let SBDAppManager = getSBDAppMan()
  const [results, setResults] = useState(paymentInfo ? paymentInfo : { totalAmount: 0 })

  const handleActions = (e) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(DisplayPaymentReceipt.js) handleClick() ... target: ' + e.currentTarget.id)

    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        console.log('handleConfirmAction')
        history.push('GetContactEmail')
        break
      case 'skipBtn':
        SBDAppManager.setAppFlow(2)
        if (baggageLoadedStatus) {
          history.push('BagProcessing')
        } else {
          history.push('PutBagOnBelt')
        }
        break
      default:
        break
    }
  }

  const amountPaidText = intl.formatMessage(messages.AmountPaidText, { amount: formatCurrency(results.totalAmount) })
  const title = intl.formatMessage(messages.DisplayReceiptHeader)
  const subtitle = intl.formatMessage(messages.DisplayReceiptSubHeader, {
    b: (chunks) => <b>{chunks}</b>
  })
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'DisplayPaymentReceipt',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [title, subtitle]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonContinue), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const header = (
    <>
      <PageHeader>Your payment was successful</PageHeader>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={isQuitRequired}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={true}
        isSkipInCenter={false}
        confirmBtnText={intl.formatMessage(messages.buttonContinue)}
        skipAction={handleActions}
        isConfirmRequired={true}
        confirmAction={handleActions}
      />
    </>
  )

  const receiptSection = (
    <>
      <PageHeader justifyContent="center">{amountPaidText}</PageHeader>
      <Spacer height="200px" />
      <PageText justifyContent="center">{title}</PageText>
      <Spacer height="50px" />
      <PageText justifyContent="center">{subtitle}</PageText>
      <Spacer height="140px" />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: receiptSection, footer },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default DisplayPaymentReceipt
