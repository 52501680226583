import { PageHeader, PageSubContent, PageText, Spacer } from 'components/styledComponents'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { getBuildAccessibility, history, playSound } from 'main'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { formatCurrency, getScreenId } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { Footer } from '../footer'

const OverweightPayment = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()

  const paymentInfo = useSelector((state) => state.responses.overweightPayment)
  const [results, setResults] = useState(paymentInfo)

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_TRACE,
      '(OverweightPayment.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        console.log('handleConfirmAction')
        history.push('PaymentCardSwipe')
        break
      default:
        break
    }
  }

  const title = intl.formatMessage(messages.OverweightPaymentTitle, { amount: formatCurrency(results.totalAmount) })
  const subtitle = intl.formatMessage(messages.BaggageAllowanceText, { baggageAllowance: results.baggageAllowance })
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'OverweightPayment',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [title, subtitle]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonContinue), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const textSection = (
    <>
      <PageSubContent width={isLandscape ? '900px' : '600px'}>
        <PageText justifyContent={'center'}>{title}</PageText>
        <Spacer height={100} />
        <PageText justifyContent={'center'}>{subtitle}</PageText>
        <Spacer height={50} />
        <PageText justifyContent={'center'}>
          {intl.formatMessage(messages.TotalWeightText, { totalBagWeight: results.totalBagWeight })}
        </PageText>
        <Spacer height={100} />
      </PageSubContent>
    </>
  )

  const header = <PageHeader alignItems="center">{intl.formatMessage(messages.OverweightPayment)}</PageHeader>

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmBtnText={intl.formatMessage(messages.buttonContinue)}
        confirmAction={handleActions}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, footer },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default OverweightPayment
