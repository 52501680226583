import { useState, useEffect } from 'react'
import { SCREEN_TYPES } from 'constants/Constants'
/*function useMedia(queries, values, defaultValue) {
  let mediaQueries = queries ? queries : SCREEN_TYPES
  // Array containing a media query list for each query
  const mediaQueryLists = mediaQueries.map(q => window.matchMedia(q))

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches)
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue
  }
}*/
function useMedia(queries, values, defaultValue) {
  let mediaQueries = queries ? queries : SCREEN_TYPES
  // State update function
  const match = () => {
    // Get first media query that matches
    const query = mediaQueries.findIndex(q => matchMedia(q).matches)
    // Return related value or defaultValue if none
    return values[query] || defaultValue
  }

  // State and setter for current value
  const [value, set] = useState(match)

  useEffect(() => {
    // Update state on window resize
    // Usage of match function defined outside of useEffect ...
    // ... ensures that it has current values of arguments.
    const handler = () => {
      set(match)
    }
    window.addEventListener('resize', handler)
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handler)
  }, []) // Empty array ensures effect is only run on mount and unmount

  return value
}
export default useMedia
