import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSummaryStore } from 'main'
import React, { useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, navigate } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { PASSENGER_VALIDATION_STATUS } from '../../constants/Constants'
import { Footer } from '../footer'

const isQuitRequired = false
const ShowDisclaimer = (props) => {
  const summaryStore = getSummaryStore()
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const dispatch = useDispatch()
  const buildAccessibility = getBuildAccessibility()
  const disclaimerResult = useRef(null)
  const faceServiceStatus = useRef(false)
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const currentPassenger = useSelector((state) => state.paxValidation.currentPassenger)
  const faceTracking = useRef(getDeviceManager().getDevice(deviceIds.FACE_TRACKING))
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const faceTrackingCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, 'disclaimer faceTrackingOnEvent() is called: ' + event.key + ' value: ' + event.value)
    switch (event.key) {
      case 'statusResponse':
        if (event.value === '0') {
          faceServiceStatus.current = true
        } else {
          faceServiceStatus.current = false
        }
        break
      case 'pictureTaken':
        // new event callback, need to modify after true testing
        dispatch(updatePaxValidationData('updatePaxPhotoStatus', true))
        break
      case 'enable':
        appLog(TraceLevels.LOG_TRACE, 'disclaimer faceTracking Event: enable: ' + event.value)
        dispatch(updatePaxValidationData('updatePaxPhotoStatus', false))
        break
      case 'dataError':
        //      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  dataError: ' + event.value)
        dispatch(updatePaxValidationData('updatePaxPhotoStatus', false))
        // 1. hide camera stream
        // 2. show popup with instruction and Retry button
        // 3. if Retry button pressed (or timeout) then show camera (hide popup)
        /*this.faceTracking.hide()
        this.faceTracking.disable()*/
        break
      default:
        appLog(TraceLevels.LOG_TRACE, 'Event: unknownEvent : ' + event.key + ' event value' + event.value)
        dispatch(updatePaxValidationData('updatePaxPhotoStatus', false))
        break
    }
  }

  useEffect(() => {
    summaryStore.CurrentPax = currentPassenger.ordinal
    faceTracking.current.OnDeviceEvent = faceTrackingCallback
    faceTracking.current.status()
    return () => {
      appLog(
        TraceLevels.LOG_TRACE,
        `disclaimer componentwillunmount is called. disclaimer result :${disclaimerResult.current}, enableFSAfterDisclamerAcceptence: ${config.enableFSAfterDisclamerAcceptence}`
      )
      if (disclaimerResult.current && config.enableFSAfterDisclamerAcceptence) {
        console.log('show disclaimer end.')
        faceTracking.current.enable(config.bestShotTime)
      }
    }
  }, [])

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  // const handleBackActions = () => {
  //   appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, No button press')
  //   disclaimerResult.current = false
  //   // TODO: Call identify service once ETS is ready
  //   dispatch(identifyService(FunctionCodes.BYPASSED, ServiceNames.SBD_PAX_VALIDATION))
  // }

  const handleActions = (e) => {
    let newValidateResults = []
    let updatedItem = {}
    let updatePaxStatus = {}
    switch (e.currentTarget.id) {
      case 'backBtn':
        appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, No button press')
        logEvents(EventFlows.PrivacyStatement, EventFunctions.UserAcceptance, 'Declined')
        disclaimerResult.current = false
        // TODO: Call identify service once ETS is ready
        // dispatch(identifyService(FunctionCodes.BYPASSED, ServiceNames.SBD_PAX_VALIDATION))
        logEvent('PrivacyStatement,Denied')
        validatePaxResult.forEach((item) => {
          if (item.ordinal === currentPassenger.ordinal) {
            updatedItem = item
            updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.FAILED

            newValidateResults.push(updatedItem)
          } else {
            newValidateResults.push(item)
          }
        })

        updatePaxStatus = {
          ordinal: currentPassenger.ordinal,
          docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
        }

        dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
        dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
        dispatch(updatePaxValidationData('initialPaxData'))
        break
      case 'confirmBtn':
        appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, Yes button press')
        logEvents(EventFlows.PrivacyStatement, EventFunctions.UserAcceptance, 'Accepted')
        logEvent('PrivacyStatement,Accepted')
        validatePaxResult.forEach((item) => {
          if (item.ordinal === currentPassenger.ordinal) {
            updatedItem = item
            updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED

            newValidateResults.push(updatedItem)
          } else {
            newValidateResults.push(item)
          }
        })

        updatePaxStatus = {
          ordinal: currentPassenger.ordinal,
          docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
        }

        dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
        if (faceServiceStatus.current) {
          summaryStore.startDocScan()
          disclaimerResult.current = true
        } else {
          appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, Yes button press, but faceService fail')
          disclaimerResult.current = false
          logEvent('FaceCapture,service is not available.')
          // TODO: Call identify service once ETS is ready
          // dispatch(identifyService(FunctionCodes.BYPASSED, ServiceNames.SBD_PAX_VALIDATION))
          dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
          dispatch(updatePaxValidationData('initialPaxData'))
        }
        break
      default:
        break
    }
    navigate('ScanDocuments', 21)
  }

  const header = <PageHeader alignItems="center">{intl.formatMessage(messages.Disclaimer_Header)}</PageHeader>

  const paxName = currentPassenger ? `${currentPassenger.firstName} ${currentPassenger.lastName}` : ''

  const animationSection = (
    <PageSubTitle
      alignItems="center"
      justifyContent="center"
      fontSize={themeContext.DisclaimerText.fontSize[ratioKey]}
      color={themeContext.PrimaryFontColor}
      style={{ textAlign: 'left' }}
    >
      {intl.formatMessage(messages.Disclaimer_Message, {
        name: paxName
      })}
    </PageSubTitle>
  )

  const textSection = (
    <>
      <PageSubTitle alignItems="center" fontSize={'25px'} color={themeContext.PrimaryFontColor}>
        {paxName}
      </PageSubTitle>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.Disclaimer_Header)}
      </PageTitle>
      <PageSubTitle
        alignItems="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.Disclaimer_SubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={isQuitRequired}
        isBackRequired={true}
        backBtnText={intl.formatMessage(messages.buttonDecline)}
        backAction={handleActions}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmBtnText={intl.formatMessage(messages.buttonAccept)}
        confirmAction={handleActions}
      />
    </>
  )

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const textParameters = [
      intl.formatMessage(messages.Disclaimer_Header),
      intl.formatMessage(messages.Disclaimer_Message, {
        name: `${currentPassenger.firstName} ${currentPassenger.lastName}`
      })
    ]
    const accDef = {
      pathName: 'ShowDisclaimer',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [
          { id: 'page-content', textId: 'TwoDynamicText', textParameters },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonAccept), buttonId: 'confirmBtn' },
          { id: 'backBtn', text: intl.formatMessage(messages.buttonDecline), buttonId: 'backBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer: footer },
    { contentWidth: contentWidth, displayItinerary: false }
  )

  return <>{UIDisplay}</>
}

export default ShowDisclaimer
