import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from 'components'
import { playSound } from 'main'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'

const ConfirmAct = (props) => {
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const handleConfirmAction = (e) => {
    playSound.beepOK()
    console.log('handleConfirmAction() ... event id : ' + e.currentTarget.id)
    props.confirmAction(e)
  }
  const confirmBtnText = props.confirmBtnText || 'Confirm'
  const confirmBtnStyle  = props.confirmBtnStyle ||  'btn-nav-confirm-up'
  return (
    <IconButton
      id={'confirmBtn'}
      cssName={confirmBtnStyle}
      color={themeContext.ConfirmButton.color}
      bgColor={themeContext.ConfirmButton.bgColor}
      width={themeContext.ConfirmButton.width[ratioKey]}
      height={themeContext.ConfirmButton.height[ratioKey]}
      disable={props.disableAction}
      onClick={handleConfirmAction}
      fontSize={themeContext.ConfirmButton.fontSize[ratioKey]}
      buttonPosition={themeContext.ConfirmButton.buttonPosition}
      iconLocation={themeContext.ConfirmButton.buttonPosition}
      padding={themeContext.ConfirmButton.padding}
      text={confirmBtnText}
      icon={props.confirmIcon ? props.confirmIcon : themeContext.ConfirmButton.icon}
      iconColor={themeContext.ConfirmButton.iconColor}
      borderRadius={themeContext.ConfirmButton.borderRadius[ratioKey]}
      border={themeContext.ConfirmButton.border ? themeContext.ConfirmButton.border : ''}
    />
  )
}

ConfirmAct.propTypes = {
  confirmAction: PropTypes.func,
  disableAction: PropTypes.bool,
  confirmBtnText: PropTypes.string,
  confirmBtnStyle: PropTypes.string,
  textSpanStyle: PropTypes.string,
  textContainerStyle: PropTypes.string,
  iconStyle: PropTypes.string
}

export default ConfirmAct
