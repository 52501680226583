import * as embrossDeviceManager from 'embross-device-manager'
import { getDeviceManager } from '../main'
import { syncTxnStateSummary } from '../actions/etsTransactions/syncTxnStateSummary'
import { formatTSDEvents } from '../utils/appTransactions'

export default class EventLogger {
  constructor(logName, kioskId = '', appVersion = '', location = '') {
    this.dm = getDeviceManager()
    this.logName = logName
    this.isLoggerSet = false
    this.kioskId = kioskId
    this.appVersion = appVersion
    this.location = location
    this.carrierCode = ''
    this.clientSessionId = null
    this.eventLogs = []
  }

  setEventLogger = () => {
    let isLoggerSet = this.dm.setLogger(this.logName, false)
    if (isLoggerSet) {
      this.dm.dmLoggerSet = true
    } else {
      console.log('setEventLogger - Device Manager not ready')
    }
  }

  set KioskId(kioskId) {
    this.kioskId = kioskId
  }

  set AppVersion(version) {
    this.appVersion = version
  }

  set Location(location) {
    this.location = location
  }
  set ClientSessionId(clientSessionId) {
    this.clientSessionId = clientSessionId
  }

  set CarrierCode(carrierCode) {
    this.carrierCode = carrierCode
  }
  postEventLogs() {
    if (clientConfig.hostURL) {
      console.log('eventLogs:', JSON.stringify(this.eventLogs))
      const eventLogs = formatTSDEvents(this.kioskId, this.location, this.eventLogs)
      syncTxnStateSummary(this.kioskId, JSON.stringify(eventLogs))
      this.carrierCode = ''
      this.eventLogs = []
    }
  }

  logEvent(message) {
    const dm = getDeviceManager()
    let logMsg =
      new Date().toISOString() +
      ',' +
      this.kioskId +
      ',' +
      this.appVersion +
      ',' +
      this.location +
      ',' +
      this.clientSessionId +
      ',' +
      message

    if (dm.setLogger(this.logName, false)) {
      dm.sendNamedLogMsg(this.logName, 3, logMsg)
    } else {
      console.log('set logger error:')
    }
    if (config.logToConsole) {
      console.log('[' + this.logName + '] ', logMsg)
    }
    if (this.clientSessionId) {
      this.eventLogs.push({
        eventSource: this.kioskId,
        locationID: this.location,
        subClientID: this.carrierCode,
        sessionID: this.clientSessionId,
        eventTime: new Date().getTime(),
        eventType: 'BUSINESS_FLOW_EVENT_DETAILS',
        stateData: {
          eventID: 'SBD_APP_EVENT',
          eventLog: message
        }
      })
    }
  }
}
