import { updateLocalData } from 'actions/localActions'
import { Animation } from 'components'
import { PageSubTitle, PageTitle } from 'components/styledComponents'
import { deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, playSound } from 'main'
import React, { useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, navigate, populateItineraryInfo } from 'utils/helper'
import { Footer } from '../footer'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const isQuitRequired = false
const isLangRequired = false

const AcceptingBag = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const myFlow = useRef(0)
  const title = intl.formatMessage(messages.BagProcessingTitle2)
  const subtitle = intl.formatMessage(messages.BagProcessingSubTitle2)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [])

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined

    const accDef = {
      pathName: 'AcceptingBag',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        isLangRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters: [title, subtitle] }]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const handleActions = (e) => {
    const aeaBagDrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'myTest':
        console.log('current flow:', appFlow)
        navigate('ClosingRemarks')
        break
      case 'dispatch1':
        dispatch(updateLocalData('appFlow', 6))
        setTimeout(() => {
          aeaBagDrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCPROK#B'
          })
        }, 2000)
        break
      case 'dispatch2':
        dispatch(updateLocalData('appFlow', 6))
        setTimeout(() => {
          aeaBagDrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCPROK#R0'
          })
        }, 2000)
        break
      case 'dispatch3':
        dispatch(updateLocalData('appFlow', 6))
        setTimeout(() => {
          aeaBagDrop.onDeviceEvent({
            key: 'unsolicitedMessage',
            value: 'HDCPROK#B'
          })
        }, 2000)
        break
      case 'dispatch4':
        dispatch(updateLocalData('appFlow', 6))
        setTimeout(() => {
          aeaBagDrop.onDeviceEvent({
            key: 'unsolicitedMessage',
            value: 'HDCPROK#R0'
          })
        }, 2000)
        break
        case 'dispatch5':
          dispatch(updateLocalData('appFlow', 6))
          setTimeout(() => {
            aeaBagDrop.onDeviceEvent({
              key: 'commandCompleted',
              value: 'HDCERR5#CBIO'
            })
          }, 2000)  
    }
  }

  let testButtons = [
    {
      id: 'myTest',
      text: 'my test',
      handler: handleActions,
      group: 0
    },
    {
      id: 'dispatch1',
      text: 'HDCPROK#B',
      handler: handleActions,
      group: 0
    },
    {
      id: 'dispatch2',
      text: 'HDCPROK#R0',
      handler: handleActions,
      group: 0
    },
    {
      id: 'dispatch3',
      text: 'unsolicited#B',
      handler: handleActions,
      group: 0
    },
    {
      id: 'dispatch4',
      text: 'unsolicited#R0',
      handler: handleActions,
      group: 0
    },
    {
      id: 'dispatch5',
      text: 'ERR5#CBIO',
      handler: handleActions,
      group: 0
    }
  ]

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  const animationAcceptingBag = isLandscape
    ? themeContext.AnimationLandscape.AcceptingBag
    : themeContext.AnimationPortrait.AcceptingBag

  const animationSection = (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${animationAcceptingBag}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )
  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {title}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {subtitle}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isLangRequired={isLangRequired}
      isHelpRequired={false}
      testData={testButtons}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header: null, topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default AcceptingBag
