/******************************************************************************
 * [TakePhoto.js]
 *****************************************************************************/
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { sendPhotoDetail } from 'actions/etsTransactions/sendPhotoDetail'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { AuroraPanel } from 'components'
import {
  DynamicImage,
  PageContent,
  PageFooter,
  PageSubContent,
  PageSubTitle,
  PageText,
  PageTitle,
  Spacer
} from 'components/styledComponents'
import { PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSummaryStore, history, playSound } from 'main'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Frame from 'react-frame-component'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getObjFromXml, getScreenId } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { Footer } from 'layouts/footer'
//import './takePhoto.scss'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
// one-to-one face service hook
// need to pass in passport image when enable the service and it will return a matching score between
// the image we pass in and the image it take.
const useOTOFaceService = (otoCallback, faceTrackingMode, defaultMessage, setLoadingStage) => {
  const dispatch = useDispatch()
  const summaryStore = getSummaryStore()
  const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
  const themeContext = useContext(ThemeContext)
  const [faceServiceTrigger, setFaceServiceTrigger] = useState(false)
  const [faceServiceStage, setFaceServiceStage] = useState(false)
  const [otoMessage, setOtoMessage] = useState(defaultMessage)
  const [errorStatus, setErrorStatus] = useState(false)
  const [paxTakenImage, setPaxTakenImage] = useState(null)
  const [liveStreamUrl, setLiveStreamUrl] = useState(themeContext.TakePhoto.liveViewPlaceHolder)
  const [overlay, setOverlay] = useState(true)
  const retryCount = useRef(0)
  const settingRef = useRef(null)
  const captureDetect = useRef(0)
  const lowestCaptureScore = useRef(null)
  const lowestCaptureImage = useRef(null)
  const faceTrackingCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTrackingOnEvent() is called: ' + event.key + ' value: ' + event.value)
    switch (event.key) {
      case 'facePresent':
        appLog(TraceLevels.LOG_TRACE, 'Event: facePresent: ' + event.value)
        logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureDetected, 'Face detected')
        break
      case 'analyzeResult':
        appLog(TraceLevels.LOG_TRACE, 'Event: analyzeResult: ' + event.value)
        const photoResult = event.value.split(',')

        if (photoResult[0] === 'null') {
          retryCount.current += 1
          summaryStore.updateFacialDataError('DATA_ERROR')
          if (retryCount.current === config.mediaAccessRetryCount) {
            setOtoMessage('Reach maximum retry limit')
            setTimeout(() => {
              otoCallback(0, null)
            }, 1500)
          } else {
            setOtoMessage('photo capture fail\nPlease retry')
            setErrorStatus(true)
          }
          break
        }

        dispatch(
          updatePaxValidationData('updatePhotoMatchDetail', {
            captureImage: photoResult[0],
            matchScore: photoResult[3]
          })
        )
        setPaxTakenImage(photoResult[0])
        captureDetect.current = captureDetect.current + 1
        const photoScore = parseFloat(photoResult[3])
        if (
          lowestCaptureScore.current === null ||
          photoScore >= lowestCaptureScore.current
          // compareScientificNumber(photoResult[3], lowestCaptureScore.current)
        ) {
          lowestCaptureScore.current = photoResult[3]
          lowestCaptureImage.current = photoResult[0]
        }
        const lowestScoreMatchResult =
          photoScore >=
          config.photoPassingScore /*compareScientificNumber(lowestCaptureScore.current, config.photoPassingScore)*/
            ? 'PASS'
            : 'FAIL'
        summaryStore.updateFacialData(lowestCaptureScore.current, lowestScoreMatchResult, null)
        // if (!compareScientificNumber(photoResult[3], config.photoPassingScore)) {
        if (photoScore < config.photoPassingScore) {
          retryCount.current += 1
          summaryStore.updateFacialDataError('MATCH_SCORE_FAIL')
          if (retryCount.current === config.mediaAccessRetryCount) {
            logEvent('FaceMatch,reach maximum retry limit')
            logEvent('FaceMatch,fail')
            logEvent('FaceMatch,score=' + photoResult[3])
            logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Fail')
            setOtoMessage('reach maximum retry limit')
            setTimeout(() => {
              otoCallback(0, null)
            }, 1500)
          } else {
            logEvent('FaceMatch,fail')
            logEvent('FaceMatch,score=' + photoResult[3])
            logEvent('FaceMatch,retry=' + retryCount.current)
            logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchRetry, 'Face match retry')
            setOtoMessage('Match score fail\nPlease retry')
            setErrorStatus(true)
            // setTimeout(() => {
            //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
            //   setLoadingStage(false)
            // }, 1500)
          }
        } else {
          logEvent('FaceMatch,success')
          logEvent('FaceMatch,score=' + photoResult[3])
          logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Success')
          logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchScore, `${photoResult[3]}`)
          otoCallback(photoResult[3], photoResult[0])
        }
        setFaceServiceTrigger(false)
        break
      case 'faceDescriptor':
        let descriptorResult = event.value.split(',')
        console.log('descriptorResult::::', descriptorResult)

        //'NULL' from faceDescriptor result
        //const photoData1 = descriptorResult[0].trim()
        const photoData2 = descriptorResult[1].trim()
        const compareScore = parseFloat(descriptorResult[2].trim())

        const spoofProbability = descriptorResult?.[3]
          ? getObjFromXml(`<PhotoDetectionInfo>${descriptorResult[3]}</PhotoDetectionInfo>`)?.PhotoDetectionInfo
              ?.SpoofProbability
          : undefined

        const quality = descriptorResult?.[3]
          ? getObjFromXml(`<PhotoDetectionInfo>${descriptorResult[3]}</PhotoDetectionInfo>`)?.PhotoDetectionInfo
              ?.Quality
          : undefined

        logEvent('FaceMatch,score=' + compareScore)
        //APP-1422-update-bagdrop-btprinted-eventlog
        logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureResult, 'Success')
        logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureQuality, quality)
        logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureSpoof, spoofProbability)
        logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchInitiated, 'Face match initialized')
        logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchScore, `${compareScore}`)
        console.log(`THIS IS WORKING AS EXPECTED, compare score = ${compareScore}, photoData = ${photoData2}`)
        captureDetect.current = captureDetect.current + 1
        if (+spoofProbability < config.photoSpoofScore) {
          if (compareScore < config.photoPassingScore) {
            logEvent('FaceMatch,retry=' + retryCount.current)
            logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchRetry, `${compareScore}`)
            retryCount.current += 1
            summaryStore.updateFacialDataError('MATCH_SCORE_FAIL')
            if (retryCount.current === config.mediaAccessRetryCount) {
              setOtoMessage('reach maximum retry limit')
              setTimeout(() => {
                otoCallback(0, null)
              }, 1500)
              logEvent('FaceMatch,fail')
              logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Fail, reach max retry limit')
            } else {
              setOtoMessage('Match score fail\nPlease retry')
              setErrorStatus(true)
              // setTimeout(() => {
              //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
              // }, 1500)
            }
          } else {
            logEvent('FaceMatch,Success')
            logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Success')
            lowestCaptureScore.current = compareScore
            lowestCaptureImage.current = photoData2
            otoCallback(compareScore, photoData2)
          }
        } else {
          console.log('Spoof image, faceMatch fail')
          logEvent('SpoofImage,FaceMatch,retry=' + retryCount.current)
          logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureRetrySpoof, spoofProbability)
          logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureRetryQuality, quality)
          retryCount.current += 1
          summaryStore.updateFacialDataError('MATCH_SCORE_FAIL')
          if (retryCount.current === config.mediaAccessRetryCount) {
            setOtoMessage('reach maximum retry limit')
            setTimeout(() => {
              otoCallback(0, null)
            }, 1500)
            logEvent('FaceMatch,fail')
            logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureResult, 'Fail')
          } else {
            setOtoMessage('Match score fail\nPlease retry')
            setErrorStatus(true)
            // setTimeout(() => {
            //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
            // }, 1500)
          }
        }

        setFaceServiceTrigger(false)
        break
      case 'enable':
        appLog(TraceLevels.LOG_TRACE, 'Event: enable: ' + event.value)
        if (event.value === '0') {
          setOtoMessage('Face Service start')
          setFaceServiceTrigger(true)
        }

        if (event.value === '0' && config.showLiveStream) {
          faceTracking.show(faceTrackingMode)
          faceTracking.focusApp()
        } else if (event.value !== '0') {
          setOtoMessage('Face Service fail to start')
          summaryStore.updateFacialDataError('FACE_SERVICE_FAIL')
          logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureStart, 'Face capture initialize fail')
          setTimeout(() => {
            otoCallback(0, null)
          }, 1500)
        }
        break
      case 'show':
        appLog(TraceLevels.LOG_TRACE, 'Event: show rc: ' + event.value)
        break
      case 'hide':
        appLog(TraceLevels.LOG_TRACE, 'Event: hide rc: ' + event.value)
        setFaceServiceTrigger(false)
        break
      case 'dataError':
        //      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  dataError: ' + event.value)
        // 1. hide camera stream
        // 2. show popup with instruction and Retry button
        // 3. if Retry button pressed (or timeout) then show camera (hide popup)
        /*this.faceTracking.hide()
        this.faceTracking.disable()*/
        retryCount.current += 1
        summaryStore.updateFacialDataError('DATA_ERROR')
        logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchError, 'Face match error, data error')
        if (retryCount.current === config.mediaAccessRetryCount) {
          logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Fail, reach max retry limit')
          setOtoMessage('Reach maximum retry limit')
          setTimeout(() => {
            otoCallback(0, null)
          }, 1500)
        } else {
          setOtoMessage('photo capture fail\nPlease retry')
          setErrorStatus(true)
          // setTimeout(() => {
          //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
          // }, 1500)
        }
        break
      case 'getStreamingURL':
        appLog(TraceLevels.LOG_TRACE, 'Event:  getStreamingURL: ' + event.value)
        if (event.value !== '') {
          setLiveStreamUrl(event.value)
        } else {
          faceTrackingShow()
        }
        break
      default:
        appLog(TraceLevels.LOG_TRACE, 'Event: unknownEvent : ' + event.key + ' event value' + event.value)
        break
    }
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_TRACE, 'faceServiceTrigger value: ' + faceServiceTrigger)
    let timer = null
    if (faceServiceTrigger) {
      timer = setTimeout(() => {
        appLog(TraceLevels.LOG_TRACE, 'timeout trigger')
        tempDisable()
        retryCount.current += 1
        summaryStore.updateFacialDataError('TIMEOUT')
        if (retryCount.current === config.mediaAccessRetryCount) {
          logEvent('FaceMatch,reach maximum retry limit=' + config.mediaAccessRetryCount)
          logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Fail, timeout')
          setOtoMessage('reach maximum retry limit')
          setTimeout(() => {
            otoCallback(0, null)
          }, 1500)
        } else {
          setOtoMessage(`timeout\nPlease retry`)
          // setTimeout(() => {
          //   enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
          //   setLoadingStage(false)
          // }, 1500)
          setErrorStatus(true)
        }
      }, config.photoFailWaitTime * 1000)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [faceServiceTrigger])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!errorStatus && settingRef.current !== null) {
        setOverlay(false)
        enable(settingRef.current.bestShotTime, settingRef.current.dg2Image)
        setLoadingStage(false)
      }
    }, config.takePhotoPrepareTime * 1000)

    return () => clearTimeout(timer)
  }, [errorStatus])

  const enableFaceTracking = () => {
    faceTracking.OnDeviceEvent = faceTrackingCallback
  }

  const enable = (bestShotTime, dg2Image, cameraStatus = false) => {
    console.log('oto call enable')
    settingRef.current = {
      bestShotTime,
      dg2Image
    }
    //faceTracking.OnDeviceEvent = faceTrackingCallback
    faceTracking.enable(bestShotTime, dg2Image)
    setFaceServiceStage(true)
    logEvent('FaceMatch,initiated')
    logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureStart, 'Initiated')
  }

  const tempDisable = () => {
    console.log('oto temp disable')
    faceTracking.hide()
    faceTracking.disable()
    setFaceServiceStage(false)
    setFaceServiceTrigger(false)
  }

  const disable = () => {
    console.log('oto call disable')
    faceTracking.hide()
    faceTracking.disable()
    faceTracking.OnDeviceEvent = null
    setFaceServiceStage(false)
    logEvent('FaceMatch,disabled')
  }

  const faceTrackingGetStreamingURL = () => {
    if (faceTracking) {
      faceTracking.getStreamingURL()
    }
  }

  const faceTrackingShow = () => {
    console.log('faceService call show')
    if (faceTracking) {
      faceTracking.show(config.faceTrackingMode)
      faceTracking.focusApp()
    }
  }

  return [
    enable,
    disable,
    tempDisable,
    faceTrackingCallback,
    faceServiceStage,
    otoMessage,
    captureDetect,
    lowestCaptureScore,
    lowestCaptureImage,
    errorStatus,
    setErrorStatus,
    paxTakenImage,
    faceTrackingGetStreamingURL,
    liveStreamUrl,
    overlay,
    setOverlay,
    enableFaceTracking
  ]
}

const TakePhoto = (props) => {
  const intl = useIntl()
  const summaryStore = getSummaryStore()
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()
  const buildAccessibility = getBuildAccessibility()
  const themeContext = useContext(ThemeContext)
  const { formatMessage } = intl
  const [takePhotoBorder, setTakePhotoBorder] = useState('none')
  const paxFaceImage = useSelector((state) => state.paxValidation.paxFaceImage)
  const isAccuantImage = useSelector((state) => state.paxValidation.isAccuantImage)
  const docType = useSelector((state) => state.paxValidation.docType)
  const paxDocImage = useSelector((state) => state.paxValidation.paxDocImage)
  const currentPassenger = useSelector((state) => state.paxValidation.currentPassenger)
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  // const paxCameraStatus = useSelector(state => state.paxValidation.paxCameraStatus)
  const paxPhotoStatus = useSelector((state) => state.paxValidation.paxPhotoStatus)
  const photoMatchDetail = useSelector((state) => state.paxValidation.photoMatchDetail)
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const [loadingStage, setLoadingStage] = useState(false)
  //const [takePhotoStatusText, setTakePhotoStatusText] = useState(messages.TakePhoto_Status1)
  const [countNumber, setCountNumber] = useState(config.takePhotoPrepareTime)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  //Test Data
  // const paxFaceImage = '/justsomerandombase64imagetest'
  // const currentPassenger = { ordinal: 0 }
  // const validatePaxResult = [
  //   {
  //     ordinal: 0,
  //     docCheckOk: true,
  //     faceMatch: false,
  //     privacyAgreement: true
  //   }
  // ]

  const handleClick = (e) => {
    appLog(TraceLevels.LOG_TRACE, '(TakePhoto.js) handleClick() ... ' + e.currentTarget.id)
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'buttonPhotoMatch':
        faceTrackingCallback({
          key: 'analyzeResult',
          //value: 'xxxx, 0, 0, 1e-3'
          value: 'xxxx, 0, 0, 0.9'
        })
        break
      case 'buttonPhotoNotMatch':
        faceTrackingCallback({
          key: 'faceDescriptor',
          value: 'xxxx, 0, 0, 2e-2'
        })
        // procesPhoto(0.5)
        break
      case 'buttonPhotoEnable':
        faceTrackingCallback({
          key: 'enable',
          value: '0'
        })
        break
      default:
        appLog(TraceLevels.LOG_TRACE, '(TakePhoto.js) default url: /')
        history.push(config.firstScreen)
    }
  }

  const testData = [
    {
      id: 'buttonPhotoMatch',
      text: 'match case',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonPhotoNotMatch',
      text: 'not match case',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonPhotoEnable',
      text: 'enable',
      handler: handleClick,
      cssName: 'test-button-up'
    }
  ]

  const procesPhoto = (photoScore, photo) => {
    //update reslut in validatePaxResult
    let passingScore = config.photoPassingScore
    let newValidateResults = []
    let updatedItem = null
    validatePaxResult.forEach((item) => {
      if (item.ordinal === currentPassenger.ordinal) {
        updatedItem = item
        // updatedItem.bioCheck = compareScientificNumber(photoScore, passingScore)
        updatedItem.bioCheck =
          photoScore >= passingScore ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.FAILED
        newValidateResults.push(updatedItem)
      } else {
        newValidateResults.push(item)
      }
    })

    const updatePaxStatus = {
      ordinal: currentPassenger.ordinal,
      docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
      faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
      privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
    }

    summaryStore.endFacialData(updatedItem.bioCheck !== PASSENGER_VALIDATION_STATUS.PASSED)

    dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
    dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))

    if (lowestCaptureImage.current && Number(lowestCaptureScore.current) !== NaN) {
      dispatch(
        sendPhotoDetail(
          currentPassenger.ordinal,
          lowestCaptureImage.current,
          lowestCaptureScore.current,
          captureDetect.current,
          false,
          null
        )
      )
    } else {
      dispatch(sendPhotoDetail(currentPassenger.ordinal, null, null, null, true, docType))
    }
  }

  const [
    enable,
    disable,
    tempDisable,
    faceTrackingCallback,
    faceServiceStage,
    otoMessage,
    captureDetect,
    lowestCaptureScore,
    lowestCaptureImage,
    errorStatus,
    setErrorStatus,
    paxTakenImage,
    faceTrackingGetStreamingURL,
    liveStreamUrl,
    overlay,
    setOverlay,
    enableFaceTracking
  ] = useOTOFaceService(
    procesPhoto,
    config.faceTrackingMode,
    formatMessage(messages.PleaseWaitDefault),
    setLoadingStage
  )

  useEffect(() => {
    if (paxFaceImage === 'null' || !paxFaceImage) {
      appLog(TraceLevels.LOG_TRACE, '(TakePhoto.js) Face image was not found in document. Go to agent.')
      logEvent('FaceMatch,Face image was not found in document.')
      logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Fail, face image was not found in document.')
      summaryStore.updateFacialDataError('MISSING_DOC_IMAGE')
      procesPhoto(0, null)
    }
  }, [])

  useEffect(() => {
    handleAccessibility()
  }, [errorStatus, locale])

  useEffect(() => {
    enableFaceTracking()
  }, [])

  useEffect(() => {
    if (config.showLiveStream) {
      faceTrackingGetStreamingURL()
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlay(false)
      setTakePhotoBorder(themeContext.TakePhoto.border ? themeContext.TakePhoto.border : null)
      if (paxFaceImage) {
        console.log('Take photo start.')
        enable(config.bestShotTime, paxFaceImage)
      }
      if (paxPhotoStatus) {
        setLoadingStage(true)
      }
    }, config.takePhotoPrepareTime * 1000)

    return () => {
      console.log('take photo end.')
      clearTimeout(timer)
      appLog(
        TraceLevels.LOG_TRACE,
        `TMR log capture attempt:${captureDetect.current}, lowest score within attempts:${lowestCaptureScore.current}`
      )
      disable()
      dispatch(updateLocalData('updateTravelDocImage', null))
    }
  }, [])

  //count down timer
  useEffect(() => {
    const refInterval = window.setInterval(() => {
      if (countNumber > 0) {
        setCountNumber(countNumber - 1)
      } else {
        setCountNumber('')
        //setTakePhotoStatusText(messages.TakePhoto_Status2)
        return
      }
    }, 1000)

    return () => {
      clearInterval(refInterval)
    }
  }, [countNumber])

  const quitAction = (action, response) => {
    if (action === 'quitAction') {
      tempDisable()
    } else if (action === 'callback' && response === 'NO') {
      enable(config.bestShotTime, paxFaceImage)
    } else if (action === 'callback' && response === 'YES') {
      appLog(TraceLevels.LOG_TRACE, `user quit: clear out photo attempts`)
      captureDetect.current = 0
      lowestCaptureScore.current = null
      lowestCaptureImage.current = null
    }
  }

  const helpAction = (action, response) => {
    if (action === 'helpAction') {
      tempDisable()
    } else if (action === 'callback' && response === 'NO') {
      enable(config.bestShotTime, paxFaceImage)
    } else if (action === 'callback' && response === 'YES') {
      appLog(TraceLevels.LOG_TRACE, `user quit: clear out photo attempts`)
      captureDetect.current = 0
      lowestCaptureScore.current = null
      lowestCaptureImage.current = null
    }
  }

  const retryAction = () => {
    setErrorStatus(false)
    setCountNumber(config.takePhotoPrepareTime)
    //setTakePhotoStatusText(messages.TakePhoto_Status1)
    setOverlay(true)
  }

  const imageSectionRegular = (
    <PageSubContent flexFlow={themeContext.TakePhoto.flow} justifyContent="center" width={'100%'} padding={'0'}>
      <PageContent flexFlow={'column'} width={'25%'}>
        {paxFaceImage && isAccuantImage ? (
          <img
            alt="paxFaceImage"
            src={`data:image/jpeg;base64,${paxFaceImage}`}
            style={{
              width: themeContext.TakePhoto.idFaceImageSmall.width[ratioKey],
              height: themeContext.TakePhoto.idFaceImageSmall.height[ratioKey]
            }}
          />
        ) : (
          <div
            style={{
              width: themeContext.TakePhoto.idFaceImageSmall.width[ratioKey],
              height: themeContext.TakePhoto.idFaceImageSmall.height[ratioKey],
              background: 'grey'
            }}
          >
            {formatMessage(messages.TakePhoto_NoIdImage)}
          </div>
        )}
        <PageText fontSize={themeContext.TakePhoto.idFaceImageTitle[ratioKey]}>
          {formatMessage(messages.TakePhoto_IdImageTitle)}
        </PageText>
      </PageContent>
      <PageContent width={'50%'}>
        {config.showLiveStream && liveStreamUrl ? (
          <Frame
            initialContent={`<!DOCTYPE html><html><head></head><body style="margin: 0"><div id="insertFrame"></div></body></html>`}
            mountTarget="#insertFrame"
            style={{
              width: themeContext.TakePhoto.liveStream.width[ratioKey],
              height: themeContext.TakePhoto.liveStream.height[ratioKey],
              border: takePhotoBorder,
              boxSizing: 'content-box',
              overflow: 'hidden'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${liveStreamUrl})`,
                width: themeContext.TakePhoto.liveStream.width[ratioKey],
                height: themeContext.TakePhoto.liveStream.height[ratioKey],
                backgroundSize: 'contain',
                overflow: 'hidden',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <div
                style={
                  overlay
                    ? {
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.4)'
                      }
                    : {
                        display: 'none'
                      }
                }
              />
              {overlay ? (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: themeContext.TakePhoto.countDown.imageSize[ratioKey],
                    height: themeContext.TakePhoto.countDown.imageSize[ratioKey],
                    backgroundImage: `url(images/${themeContext.AnimationPath}/pleaseWait_spinner_white.gif`,
                    backgroundSize: 'contain',
                    overflow: 'hidden',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'sans-serif',
                      fontWeight: '400',
                      fontSize: themeContext.TakePhoto.countDown.fontSize[ratioKey],
                      color: 'white'
                    }}
                  >
                    {countNumber}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Frame>
        ) : (
          <DynamicImage
            imageName={`${themeContext.AnimationPath}/pleaseWait_spinner.gif`}
            width={themeContext.TakePhoto.countDown.imageSize[ratioKey]}
            height={themeContext.TakePhoto.countDown.imageSize[ratioKey]}
          >
            <PageText fontSize={themeContext.TakePhoto.countDown.fontSize[ratioKey]}>{countNumber}</PageText>
          </DynamicImage>
        )}
      </PageContent>
      <PageContent width={'25%'}></PageContent>
    </PageSubContent>
  )

  const imageSectionError = themeContext.TakePhoto.photoNotMatchImage ? (
    <DynamicImage
      imageName={`${themeContext.TakePhoto.photoNotMatchImage}`}
      width={animationSize.width}
      height={animationSize.height}
    ></DynamicImage>
  ) : (
    <PageSubContent flexFlow={'row'} width={'100%'} padding={'0'}>
      <PageContent flexFlow={'column'} width={'40%'}>
        {paxFaceImage && isAccuantImage ? (
          <img
            alt="paxFaceImage"
            src={`data:image/jpeg;base64,${paxFaceImage}`}
            style={isLandscape ? { width: '300px', height: '350px' } : { width: '240px', height: '300px' }}
          />
        ) : (
          <div
            style={
              isLandscape
                ? { width: '300px', height: '350px', background: 'grey' }
                : { width: '240px', height: '300px', background: 'grey' }
            }
          >
            {formatMessage(messages.TakePhoto_NoIdImage)}
          </div>
        )}
        <PageText fontSize={themeContext.TakePhoto.idImage.fontSize[ratioKey]}>
          {formatMessage(messages.TakePhoto_IdImageTitle)}
        </PageText>
      </PageContent>
      <PageContent flexFlow={'column'} width={'20%'}>
        <DynamicImage imageName={'error-icon.svg'} width={isLandscape ? 200 : 125} height={isLandscape ? 200 : 125} />
      </PageContent>
      <PageContent width={'40%'}>
        {paxTakenImage ? (
          <img
            alt="paxTakenImage"
            src={`data:image/jpeg;base64,${paxTakenImage}`}
            style={isLandscape ? { width: '300px', height: '350px' } : { width: '240px', height: '300px' }}
          />
        ) : (
          <div
            style={
              isLandscape
                ? { width: '300px', height: '350px', background: 'grey' }
                : { width: '240px', height: '300px', background: 'grey' }
            }
          >
            {formatMessage(messages.TakePhoto_NoFaceImage)}
          </div>
        )}
        <PageText fontSize={themeContext.TakePhoto.idImage.fontSize[ratioKey]}>
          {formatMessage(messages.TakePhoto_FaceImageTitle)}
        </PageText>
      </PageContent>
    </PageSubContent>
  )

  const paxFullName = currentPassenger ? `${currentPassenger.firstName} ${currentPassenger.lastName}` : '-'
  const message = (
    <FormattedMessage
      {...messages.TakePhoto_Title}
      values={{
        b: (...chunks) => <div>{chunks}</div>,
        paxName: <span className="scan-document-title">{paxFullName}</span>,
        br: <Spacer />
      }}
    />
  )

  const textSectionRegular = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {message}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {formatMessage(messages.TakePhoto_SubTitle)}
      </PageSubTitle>
    </>
  )
  const textSectionError = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {formatMessage(messages.TakePhoto_Title_Error)}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {formatMessage(messages.TakePhoto_SubTitle_Error)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitAction={quitAction}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={errorStatus ? true : false}
        confirmBtnText={formatMessage(messages.buttonRetry)}
        confirmAction={retryAction}
        helpAction={helpAction}
        testData={testData}
      />
      {/* <TestButtons data={testButtons} /> */}
    </>
  )

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const textParameters = errorStatus
      ? [formatMessage(messages.TakePhoto_Title_Error), formatMessage(messages.TakePhoto_SubTitle_Error)]
      : [
          formatMessage(messages.TakePhoto_Title).replace('paxName', paxFullName),
          formatMessage(messages.TakePhoto_SubTitle)
        ]
    const accDef = {
      pathName: 'takePhoto',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters }]
      }
    }
    if (errorStatus) {
      accDef.sequenceDef.sequence.push({
        id: 'confirmBtn',
        text: intl.formatMessage(messages.buttonRetry),
        buttonId: 'confirmBtn'
      })
    }
    buildAccessibility(accDef)
  }

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    {
      topSection: errorStatus ? textSectionError : textSectionRegular,
      bottomSection: errorStatus ? imageSectionError : imageSectionRegular,
      footer
    },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  const auroraHeight = useMedia(null, [730, 730, 0], 1050)

  const pleaseWaitHeaderDisplay = (
    <>
      <PageTitle>{formatMessage(messages.PleaseWaitTitle)}</PageTitle>
      <PageSubTitle justifyContent="center"></PageSubTitle>
    </>
  )

  const pleaseWaitBodyDisplay = (
    <>
      <PageContent flexFlow={isLandscape ? 'row' : 'column'}>
        <PageSubContent
          justifyContent={'flex-start'}
          width={isLandscape ? contentWidth.landscapeMode : contentWidth.portraitMode}
        >
          {isLandscape ? null : (
            <>
              {pleaseWaitHeaderDisplay}
              <Spacer height={150} />
            </>
          )}
          <PageText justifyContent="center" />
          {/* <DynamicImage
            imageName={`pleaseWait_rowNoStops.gif`}
            cssName={'emb_animation_drawbox'}
            width={'75%'}
            height={'100px'}
          /> */}
          <DynamicImage
            imageName={`pleaseWait_spinner.gif`}
            cssName={'emb_animation_drawbox'}
            width={300}
            height={300}
          />
        </PageSubContent>
      </PageContent>
    </>
  )

  const loading = (
    <div className="page-container">
      <AuroraPanel width={'90%'} style={{ flex: 1 }}>
        <PageContent flexFlow={'column'}>
          {pleaseWaitBodyDisplay}
          {isLandscape ? pleaseWaitHeaderDisplay : null}
        </PageContent>
      </AuroraPanel>
      <Spacer height={20} />
      <PageFooter />
    </div>
  )

  if (loadingStage) {
    return loading
  } else {
    return <>{UIDisplay}</>
  }
}

export default TakePhoto
