import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Card Reader listener.
 *  @extends {Listener}
 */
export default class CardReaderListener extends Listener {

  /**
   *  Default cardReadInternal callback.
   *  @param {string} readData read card data track1,track2,track3,chipData
   */
  cardReadInternal(readData)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardReadInternal called')
    this.setEvent('cardReadInternal', readData)
  }

  /**
   *  Default cardEjected callback.  Called when the card is ejected
   */
  cardEjected()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardEjected() called')
    this.setEvent('cardEjected', null)
  }

  /**
   *  Default cardInsertedIncomplete callback.  Called when the card is inserted incompletely
   */
  cardInsertedIncomplete()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardInsertedIncomplete() called')
    this.setEvent('cardInsertedIncomplete', null)
  }

  /**
   *  Default cardInThroat callback.  Called when the card is in throat
   */
  cardInThroat()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardInThroat() called')
    this.setEvent('cardInThroat', null)
  }

  /**
   *  Default cardInserted callback.  Called when the card is fully inserted into the reader
   */
  cardInserted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardInserted() called')
    this.setEvent('cardInserted', null)
  }

  /**
   *  Default cardRemoved callback.  Called when the card is fully removed
   */
  cardRemoved()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardRemoved() called')
    this.setEvent('cardRemoved', null)
  }

  /**
   *  Default cardDamaged callback.  Called when the card / card data is damaged
   */
  cardDamaged()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardDamaged() called')
    this.setEvent('cardDamaged', null)
  }

  /**
   *  Default cardRead callback.  Called when the card reader reads data
   *  @param {Object} readData Object containing track data
   */
  cardRead(readData)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.cardRead() called')
    this.setEvent('cardRead', readData)
  }

  /** Default callback for response to isDIP() call
   * @param {string} response '1' when true
   */
  isDIP(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.isDIP() ' + response)
    this.setEvent('isDIP', response)
  }
  
  /** Default callback for response to isSwipe() call
   * @param {string} response '1' when true
   */
  isSwipe(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.isSwipe() ' + response)
    this.setEvent('isSwipe', response)
  }
  
  /** Default callback for response to isMotorized() call
   * @param {string} response '1' when true
   */
  isMotorized(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.isMotorized() ' + response)
    this.setEvent('isMotorized', response)
  }
  
  /** Default callback for response to isMagneticStripe() call
   * @param {string} response '1' when true
   */
  isMagneticStripe(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.isMagneticStripe() ' + response)
    this.setEvent('isMagneticStripe', response)
  }
  
  /** Default callback for response to isCardInReader() call
   * @param {string} response '1' when true
   */
  isCardInReader(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.isCardInReader() ' + response)
    this.setEvent('isCardInReader', response)
  }
  
  /** Default callback for response to isCardPartiallyInserted() call
   * @param {string} response '1' when true
   */
  isCardPartiallyInserted(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CardReaderListener.isCardPartiallyInserted() ' + response)
    this.setEvent('isCardPartiallyInserted', response)
  }
}
