import { SessionActions } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

export function updateSessions(type, obj) {
  appLog(TraceLevels.LOG_LEVEL6, 'updateSessions: ' + type)
  switch (type) {
    case 'updateSessionInfo':
      return {
        type: SessionActions.UPDATE_SESSION,
        sessionInfo: obj
      }
    case 'updateSessionConfig':
      return {
        type: SessionActions.UPDATE_SESSION_CONFIG,
        configElements: obj
      }
    case 'updateServiceName':
      return {
        type: SessionActions.CURRENT_SERVICE_NAME,
        currentServiceName: obj
      }
    case 'updateTransition':
      appLog(TraceLevels.LOG_EXT_TRACE, 'updateSessions updateTransition: ' + obj)
      return {
        type: SessionActions.UPDATE_TRANSITION,
        transition: obj
      }
    case 'updateTransitionOption':
      return {
        type: SessionActions.UPDATE_TRANSITION_OPTION,
        transitionOption: obj
      }
    case 'initialSessions':
      return {
        type: SessionActions.INITIAL_SESSION_DATA
      }
    case 'clearSessions':
      return {
        type: SessionActions.CLEAR_SESSION_DATA
      }
    case 'cussTxnStarted':
      return {
        type: SessionActions.CUSS_TXN_STARTED,
        cussTxnStarted: obj
      }
    case 'updateNotificationText':
      return {
        type: SessionActions.UPDATE_NOTIFICATION_TEXT,
        notificationText: obj
      }
    /* case 'updateOnActive':
      return {
        type: SessionActions.UPDATE_ON_ACTIVE,
        onActive: obj
      } */
    case 'updateEtsTxnStatus':
      return {
        type: SessionActions.UPDATE_ETS_TXN_STATUS,
        ETSTxnStatus: obj
      }
    case 'updateSessionStats':
      return {
        type: SessionActions.UPDATE_SESSION_STATS,
        sessionStats: obj
      }
    case 'updateETSVersion':
      return {
        type: SessionActions.UPDATE_ETS_VERSION,
        version: obj
      }
    case 'updateETSTime':
      return {
        type: SessionActions.UPDATE_ETS_TIME,
        etsTime: obj
      }
    case 'updateETSTimeDelta':
      return {
        type: SessionActions.UPDATE_ETS_TIME_DELTA,
        delta: obj
      }
    case 'updateOOSstatus':
      return {
        type: SessionActions.UPDATE_OOS_STATUS,
        oos: obj
      }
    case 'updateETSRequest':
      return {
        type: SessionActions.UPDATE_ETS_REQUEST,
        etsRequest: obj
      }
    case 'updateIsHeavytagRequired':
      return {
        type: SessionActions.UPDATE_IS_HEAVYTAG_REQUIRED,
        isHeavytagRequired: obj
      }
    case 'updateTransferData':
      return {
        type: SessionActions.UPDATE_TRANSFER_DATA,
        transferData: obj
      }
    case 'updateAttachHeavyRetryAttemptCounts':
      return {
        type: SessionActions.UPDATE_HEAVYTAG_RETRY_COUNT,
        attachHeavyRetryAttemptCounts: obj
      }
      case 'updateAgentOverrideError':
      return {
        type: SessionActions.AGENT_OVERRIDE_ERROR,
        agentOverrideError: obj
      }
    default:
  }
}
