import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Passport Reader listener.
 *  @extends {Listener}
 */
export default class PassportReaderListener extends Listener {
  /**
   *  Default passportReadInternal callback.
   *  @param {string} readData passport data: mrz, rfmrz, rfphoto, parsed mrz, efcom, efsod, dg1file, dg2file
      <ul><li>0 - mrz string separated by '_@'</li>
      <li>1 - rfmrz string separated by '_@' (optional)- in <EPASSPORT_DG1>..</EPASSPORT_DG1>  for RF
                         vis if not RS and available</li>
      <li>2 - rfphoto base64 encoded photo string (optional)  - in <EPASSPORT_DG2>..</EPASSPORT_DG2></li>
      <li>3 - parsed mrz or rfmrz(in present) as an array of strings (optional if the global parameter ParseMedia is true
                          then Media library will be used to parse passport data)
                          if case of parse errors then it will contain error code</li>
      <li>4 - efcom base64 encoded string (optional)   - in <EPASSPORT_EFCOM>..</EPASSPORT_EFCOM></li>
      <li>5 - efsod base64 encoded string (optional)   - in <EPASSPORT_EFSOD>..</EPASSPORT_EFSOD></li>
      <li>6 - dg1file base64 encoded string (optional) - raw dg1 image in <EPASSPORT_DG1FILE>..</EPASSPORT_DG1FILE></li>
      <li>7 - dg2file base64 encoded string (optional) - raw dg2 image in <EPASSPORT_DG2FILE>..</EPASSPORT_DG2FILE></li>
      </ul>
   */
  passportReadInternal(readData) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.passportReadInternalMethod() called')
    this.setEvent('passportReadInternal', readData)
  }

  /**
   *  Default passportDamaged callback.
   */
  passportDamaged() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.passportDamagedMethod() called')
    this.setEvent('passportDamaged', null)
  }

  /**
   *  Default passportInserted callback.
   */
  passportInserted() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.passportInsertedMethod() called')
    this.setEvent('passportInserted', null)
  }

  /**
   *  Default passportRead callback.
   *  @param {string} data passport data
   */
  passportRead(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.passportReadMethod() called')
    this.setEvent('passportRead', data)
  }

  /**
   *  Default passportRemoved callback.
   */
  passportRemoved() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.passportRemovedMethod() called')
    this.setEvent('passportRemoved', null)
  }

  /**
   *  Default accessingRFChip callback.
   */
  accessingRFChip() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.accessingRFChip() called')
    this.setEvent('accessingRFChip', null)
  }

  /**
   *  Default passportDataError callback.
   *  @param {string} data error
   */
  passportDataError(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.passportDataError() called')
    this.setEvent('passportDataError', data)
  }

  /**
   *  Default setup callback.
   *  @param {string} response setup response
   *  @example <USE_RFID_COUNTRY_BLACKLIST>FALSE<\/USE_RFID_COUNTRY_BLACKLIST><RFID_COUNTRY_BLACKLIST><\/RFID_COUNTRY_BLACKLIST>
   */
  setup(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.setup() called: ' + response)
    this.setEvent('setup', response)
  }

  /**
   *  Default parse callback.
   *  @param {string} response parsed response in the array
   *  <ul>
   *  <li>0 - media type e.g.: PPT
   *  <li>1 - media name e.g.: Passport
   *  <li>2 - last name
   *  <li>3 - first name
   *  <li>4 - middle name
   *  <li>5 - nationality
   *  <li>6 - gender
   *  <li>7 - year of birth
   *  <li>8 - month of birth
   *  <li>9 - day of birth
   *  <li>10 - personal number
   *  <li>11 - document number
   *  <li>12 - document type
   *  <li>13 - issuing country or organization
   *  <li>14 - expiry year
   *  <li>15 - expiry month
   *  <li>16 - expiry day
   *  <li>17 - birth date checksum
   *  <li>18 - expiry date checksum
   *  <li>19 - personal number checksum
   *  <li>20 - composite checksum
   *  <li>21 - document number checksum
   *  <li>22 - age
   *  </ul>
   *
   */
  parse(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.parse() called.')
    let res = response.split(',').map((item) => item.trim())
    if (res.length > 1) {
      this.setEvent('parse', res)
    } else {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.parse() incorrect Response! ' + response)
      this.setEvent('parse', [])
    }
  }

  /** Default callback for response to isDIP() call
   * @param {string} response '1' when true
   */
  isDIP(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.isDIP() ' + response)
    this.setEvent('isDIP', response)
  }

  /** Default callback for response to isSwipe() call
   * @param {string} response '1' when true
   */
  isSwipe(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.isSwipe() ' + response)
    this.setEvent('isSwipe', response)
  }

  /** Default callback for response to isMotorized() call
   * @param {string} response '1' when true
   */
  isMotorized(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.isMotorized() ' + response)
    this.setEvent('isMotorized', response)
  }

  /** Default callback for response to isPassportInReader() call
   * @param {string} response '1' when true
   */
  isPassportInReader(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.isPassportInReader() ' + response)
    this.setEvent('isPassportInReader', response)
  }

  /** Default callback for response to isPassportPartiallyInserted() call
   * @param {string} response '1' when true
   */
  isPassportPartiallyInserted(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.isPassportPartiallyInserted() ' + response)
    this.setEvent('isPassportPartiallyInserted', response)
  }

  /**
   *  Default getVersion callback.
   *  @param {String} data
   */
  getVersion(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'PassportReaderListener.getVersion() called')
    this.setEvent('getVersion', data)
  }
}
