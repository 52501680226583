import { store, getSBDAppMan, history, getTSDManager, getSummaryStore } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { updateError } from 'actions/commonActions'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData } from 'actions/localActions'
import { updateLocalError } from 'actions/commonActions'
import { getBagTagPrintStreams } from 'actions/etsTransactions/getBagTagPrintStreams'
import { transitions } from 'actions/etsTransactions/transitions'
import { isNotEmpty, navigate } from 'utils/helper'
import {
  TYPE_KIOSK,
  TransitionCodes,
  PASSENGER_VALIDATION_STATUS,
  FunctionCodes,
  ServiceNames
} from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { replacer, getScanSequence, updateUseOnlyTagNumber } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { getTotalWeightInfo } from 'actions/etsTransactions/getTotalWeightInfo'
import { identifyService } from 'actions/etsTransactions/identifyService'
import {logEvents, sessionStart, EventFlows, EventFunctions} from 'utils/appEventLogger'
import { SourceType } from '../../constants/Errors'

function buildGetItinerarySBD(searchObj, overrideCode) {
  appLog(
    TraceLevels.LOG_EXT_TRACE,
    '(getItinerarySBD.js) buildGetItinerarySBD() - searchObj: ' + JSON.stringify(searchObj)
  )
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo?.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo?.emhaSessionID
      },
      jsonExtend: store.getState().settings.sbdScanExtend,
      // carrierCode: null,
      // currentClientTime: 0,
      // asyncRequested: false,
      // clientId: null,
      overrideCode: overrideCode ? overrideCode : null,
      nextPax: false
      // trackData: null,
      // recordLocator: null,
      // lastName: null,
      // firstName: null,
      // barcodeData: null,
    },
    searchObj
  )
}

export function getItinerarySBD(searchObj, location, overrideCode = '') {
  const summaryStore = getSummaryStore()
  let funcName = '(getItinerarySBD.js)'
  /*let barcode = null
  if (searchObj.hasOwnProperty('barcodeData')) {
    barcode = parseBarcode(searchObj.barcodeData)
  }
  console.log('barcode:', barcode)*/
  const skipFailRequest = searchObj['skipFailRequest']
  delete searchObj['skipFailRequest']

  const resetFaceService = searchObj['resetFaceService']
  delete searchObj['resetFaceService']

  appLog(
    TraceLevels.LOG_EXT_TRACE,
    '(getItinerarySBD.js) - Build ETS Request ... appFlow: ' + store.getState().localData.appFlow
  )
  appLog(TraceLevels.LOG_EXT_TRACE, '(getItinerarySBD.js) - location: ' + JSON.stringify(location))
  let request = buildGetItinerarySBD(searchObj, overrideCode)
  return (dispatch) => {
    //dispatch(updateLocalData('updateBarcodeData', barcode))
    // do not reset - it may come from errorBPMismatch
    if (!(store.getState().responses && store.getState().responses.itineraryInfo)) {
      if (store.getState().localData.appFlow !== 1) {
        dispatch(updateLocalData('appFlow', 1))
      }
    }
    if (overrideCode !== '') {
      let location = {
        state: {
          from: 'ErrorPage',
          overrideCode: overrideCode
        }
      }
      let url = Object.assign({}, location, { pathname: 'PleaseWaitFindReservation' })
      if (!skipFailRequest) {
        navigate(url)
      }
    } else {
      navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitFindReservation' } })
    }
    appLog(TraceLevels.LOG_TRACE, funcName + ' REQUEST ... ' + JSON.stringify(request, replacer))
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('SBDScan/', request) //boardingPassScan
      .then((json) => {
        appLog(TraceLevels.LOG_TRACE, funcName + ' response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (store.getState().localData.appFlow === 10) {
          return
        }
        if (isNotEmpty(json.etsResponse)) {
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            funcName + ' response ... etsResponse ... ' + JSON.stringify(json.etsResponse, replacer)
          )
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            '(getItinerarySBD.js) getItinerarySBD() - updateTransition ... transition = "' + json.transition + '"'
          )
          if (isNotEmpty(json.etsResponse.jsonExtend)) {
            const bagAllowance = json.etsResponse.jsonExtend.split('=')[1]
            appLog(TraceLevels.LOG_EXT_TRACE, 'update local baggage allowance' + bagAllowance)
            dispatch(updateLocalData('updateBagAllowance', bagAllowance))
          }
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          // config.testBagtag1 = json.etsResponse.passengerBagtagDetails[0].bagTags[0].baseTagNo
          dispatch(updateSessions('updateTransition', json.transition))
          let appFlow = store.getState().localData.appFlow
          appLog(TraceLevels.LOG_EXT_TRACE, '(getItinerarySBD.js) appFlow: ' + appFlow)
          dispatch(updateSessions('updateTransition', json.transition))
          dispatch(updateLocalData('updateBarcodeAttempts', 0))
          appLog(TraceLevels.LOG_EXT_TRACE, 'update new session info ...')
          store.dispatch(updateSessions('updateSessionInfo', json.etsResponse.sessionInfo))
          let prevSeq =
            store.getState().responses && store.getState().responses.itineraryInfo
              ? store.getState().responses.itineraryInfo.scanSequence
              : []
          let newSeq = getScanSequence(prevSeq, json.etsResponse.passengerBagtagDetails)
          // test
          //newSeq = [1,2,3]
          if (config.checkTagLength && prevSeq.length <= 1) {
            updateUseOnlyTagNumber(json.etsResponse.passengerBagtagDetails)
            appLog(TraceLevels.LOG_EXT_TRACE, '(getItinerarySBD.js) useOnlyTagNumber: ' + config.useOnlyTagNumber)
          }
          let allowance = 0
          let allowanceUnit = ''
          if (isNotEmpty(json.etsResponse.jsonExtend)) {
            if (
              (json.etsResponse.jsonExtend.toUpperCase().endsWith('PC') ||
                json.etsResponse.jsonExtend.toUpperCase().endsWith('KG') ||
                json.etsResponse.jsonExtend.toUpperCase().endsWith('LB')) &&
              json.etsResponse.jsonExtend.length > 2
            ) {
              let allowancePart = json.etsResponse.jsonExtend.split('=')
              let n = allowancePart[1].length - 2
              allowanceUnit = allowancePart[1].substr(n)
              allowance = allowancePart[1].substr(0, n)
            } else {
              logEvents(EventFlows.Reservation, EventFunctions.ReservationError, 'Invalid allowance')
              appLog(TraceLevels.LOG_TRACE, 'Invalid allowance: ' + json.etsResponse.jsonExtend)
            }
          }
          let itineraryInfo = {
            recordLocator: json.etsResponse.recordLocator,
            outboundFlights: json.etsResponse.outboundFlights,
            passengerBagtagDetails: json.etsResponse.passengerBagtagDetails,
            scanSequence: newSeq,
            allowance: {
              value: allowance,
              unit: allowanceUnit
            }
          }

          dispatch(updateResponses('updateItinerary', itineraryInfo))
          // set PNR and bags
          summaryStore.RecordLocator = json.etsResponse.recordLocator
          summaryStore.BaggageDetail = json.etsResponse.passengerBagtagDetails
          if (json.etsResponse.outboundFlights) {
            let carrierCode = json.etsResponse.outboundFlights[0].mktCarrierCode
            dispatch(updateLocalData('updateCarrierCode', carrierCode))
            const tsdMgr = getTSDManager()
            if (tsdMgr) {
              tsdMgr.carrierCode = carrierCode
            }
            let departure = itineraryInfo.outboundFlights[0].departureSchedule.schedule.airport.code
            let arrival = itineraryInfo.outboundFlights[0].arrivalSchedule.schedule.airport.code
            let flightNumber = itineraryInfo.outboundFlights[0].mktFlightNo
            logEvents(EventFlows.Reservation, EventFunctions.PNR, itineraryInfo.recordLocator)
            logEvents(EventFlows.Reservation, EventFunctions.BagAllowance,  itineraryInfo.allowance.value + ' '+ itineraryInfo.allowance.unit)            
            logEvents(EventFlows.Reservation, EventFunctions.ETSString,'PNR:'+ itineraryInfo.recordLocator + ', CarrierCode:' + carrierCode + ', Flight:' + flightNumber + ', Destination:' + arrival + ', Departure:' + departure)
            logEvents(EventFlows.Reservation, EventFunctions.Destination, arrival)
            logEvents(EventFlows.Reservation, EventFunctions.Flight, carrierCode + flightNumber)
            logEvents(EventFlows.Reservation, EventFunctions.Departure, departure)

            // set Itinerary Information
            summaryStore.Carrier = carrierCode
            summaryStore.Flight = flightNumber
            summaryStore.Departure = departure
            summaryStore.Arrival = arrival
          }
          //itineraryInfo.outboundFlights
          let fromBPMismatch = store.getState().localData.mismatchBPError
          if (fromBPMismatch) {
            logEvents(EventFlows.Reservation, EventFunctions.ReservationError, 'mismatchBPError')
            appLog(TraceLevels.LOG_EXT_TRACE, '(getItinerarySBD.js) mismatchBPError: ' + fromBPMismatch)
          }

          // update valid pax result from the response
          let totalBags = 0 //get total number of bags
          if (json.etsResponse.passengerBagtagDetails.length > 0) {
            let passenger = null
            let selectedPassengers = []
            let currentPassenger = null
            for (let i = 0; i < json.etsResponse.passengerBagtagDetails.length; i++) {
              passenger = json.etsResponse.passengerBagtagDetails[i].passenger
              totalBags += json.etsResponse.passengerBagtagDetails[i].bagTags.length
              if (json.etsResponse.passengerBagtagDetails[i].bpScanned) {
                currentPassenger = passenger                
              }
              selectedPassengers.push({
                ordinal: passenger.ordinal,
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                dateOfBirth: passenger.dateOfBirth,
                bioCheck: PASSENGER_VALIDATION_STATUS.UNKNOWN,
                visaCheck: PASSENGER_VALIDATION_STATUS.UNKNOWN,
                passportScanned: false
              })
            }
            logEvents(EventFlows.Reservation, EventFunctions.NumberOfPAX, selectedPassengers.length)
            dispatch(updateLocalData('updateValidatePaxResult', selectedPassengers))
            dispatch(updateLocalData('updateCurrentPassenger', currentPassenger))
          }
          logEvents(EventFlows.Reservation, EventFunctions.NumberofBags, totalBags)
          if (json.transition === TransitionCodes.PASSENGER_SELECTION) {
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              '(getItinerarySBD.js) getItinerarySBD() - Dispatch --> "passenger selection" screen ...'
            )
            let dummyTravelers = []
            for (let i = 0; i < 1; i++) {
              // change to number of passengers returned
              dummyTravelers.push(
                Object.assign(
                  {},
                  {
                    travelerOrdinal: i,
                    firstName: '',
                    lastName: '',
                    updated: false
                  }
                )
              )
            }
            dispatch(updateResponses('updateTravelers', dummyTravelers))
            navigate('PassengerSelection', 21)
          } else if (json.transition === TransitionCodes.SCAN_BP) {
            if (appFlow === 1 || appFlow === 21) {
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                '(getItinerarySBD.js) getItinerarySBD() - Dispatch --> "ScanBoardingPass" screen ...'
              )
              navigate('ScanBoardingPass', 21)
            } else {
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                '(getItinerarySBD.js) getItinerarySBD() - Dispatch --> revalidate the bag...'
              )
              getSBDAppMan().revalidateBag()
            }
          } else if (json.transition === TransitionCodes.PRINT_DOCUMENT) {
            // check if bagTagResources and passengerDocuments are not empty arrays
            if (
              json.etsResponse.bagTagResources &&
              Array.isArray(json.etsResponse.bagTagResources) &&
              json.etsResponse.passengerDocuments &&
              Array.isArray(json.etsResponse.passengerDocuments) &&
              isNotEmpty(json.etsResponse.passengerDocuments)
            ) {
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                '(getItinerarySBD.js) getItinerarySBD() - Dispatch --> "PrintBagtag" screen ...'
              )
              dispatch(updateResponses('updateBagtagResources', json.etsResponse.bagTagResources))
              dispatch(updateResponses('updatePassengerDocuments', json.etsResponse.passengerDocuments))
              navigate('PrintBagtag', 21)
            } else {
              // call
              if (config.EnableBagTagPrinting) {
                appLog(TraceLevels.LOG_EXT_TRACE, '>> call getBagTagPrintStreams')
                location = Object.assign({}, location, {
                  state: Object.assign({}, location.state, {
                    maxAttemptsReached: false
                  })
                })
                store.dispatch(getBagTagPrintStreams(location))
              } else {
                dispatch(updateLocalData('appFlow', 10))
                logEvents(EventFlows.Reservation, EventFunctions.ReservationError, ErrCodes.ETS_MESSAGE_ERROR)
                goToLocalGenericError('getItinerarySBD', ErrCodes.ETS_MESSAGE_ERROR, 'Error_Default', 'END_TXN', null)
              }
            }
          } else if (json.transition === TransitionCodes.GET_BAG_WEIGHT_LIMIT) {
            //only one passenger in PNR
            appLog(TraceLevels.LOG_EXT_TRACE, 'getItinerarySBD() get bag weight limit ')
            dispatch(getTotalWeightInfo())
          } else if (json.transition === TransitionCodes.NONE) {
            dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_SCAN))
          } else if (json.transition === TransitionCodes.DANGEROUS_GOODS_QUESTIONS || json.transition === TransitionCodes.DANGEROUS_GOODS) {
            navigate('hazMatQuestion')
          } else if (json.transition === TransitionCodes.CUSTOM_9) {
            //navigate({ pathname: 'agentOverride', state: { transition: json.transition } })
            logEvents(EventFlows.Reservation, EventFunctions.ReservationError, 'Too early for baggage')
            navigate('agentOverride', 21)
            dispatch(updateLocalError(ErrCodes.TOO_EARLY_BAGGAGE, 'Err_AgentTooEarly', SourceType.LOCAL, json.transition))
            dispatch(updateSessions('updateAgentOverrideError', ErrCodes.TOO_EARLY_BAGGAGE))
          } else if (json.transition === TransitionCodes.CUSTOM_10) {
            //navigate({ pathname: 'agentOverride', state: { transition: json.transition } })
            logEvents(EventFlows.Reservation, EventFunctions.ReservationError, 'Too late for baggage')
            navigate('agentOverride',21)
            dispatch(updateLocalError(ErrCodes.TOO_LATE_BAGGAGE, 'Err_AgentTooLate', SourceType.LOCAL, json.transition))
            dispatch(updateSessions('updateAgentOverrideError', ErrCodes.TOO_LATE_BAGGAGE))
          } else if (json.transition === TransitionCodes.SBD_PROCESS) {
            // default is SBD_PROCESS
            let targetScreen = store.getState().localData.baggageLoadedStatus ? 'BagProcessing' : 'PutBagOnBelt'
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              '(getItinerarySBD.js) getItinerarySBD() - Dispatch --> ' + targetScreen + ' screen ...'
            )
            navigate(targetScreen, 2)
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, '(getItinerarySBD.js) unhandled transition code')
          }
        } else if (json.error) {
          if (!skipFailRequest) {
            if (json.error.description !== null && json.error.description.includes('PaxInfo')) {
              let jsonExtArr = json.error.description.split(',')
              let name = jsonExtArr[1]
              let flightNumber = jsonExtArr[2]
              let flightDetails = jsonExtArr[3].split('-')
              let departFlight = flightDetails[0]
              let arrivalFlight = flightDetails[1]
              let time = jsonExtArr[4].split('-')
              let itineraryInfo = {
                // recordLocator: json.etsResponse.recordLocator,
                name: name,
                flightNumber: flightNumber,
                departFlight: departFlight,
                arrivalFlight: arrivalFlight,
                flightTime: time[1]
              }
              dispatch(updateResponses('updateItinerary', itineraryInfo))
            }
            let modifyJson = json
            // modify error from BPMISMATCH PAGE
            if (location && location.state.from === 'ErrorBPMismatch') {
              modifyJson = { ...json, transition: TransitionCodes.BARCODE_RETRY }
            }
            goToFetchGenericError('getItinerarySBD', modifyJson, dispatch)
          }
          if (resetFaceService) {
            dispatch(updateLocalData('updateFaceMatchingStage', false))
          }
        }
      })
      .catch((err) => {
        let errorLog = err ? err.message : ''
        if(err && err.response && err.response.error){
          errorLog = err.response.error.errorCode
        }
        logEvents(EventFlows.Reservation, EventFunctions.ReservationError, errorLog)
        if (!skipFailRequest) {
          handleFetchErrors(err, 'getItinerarySBD()', 'error')
        }
      })
  }
}
