import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import {formatTSDEvents} from '../../utils/appTransactions'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
export function syncTxnStateSummary(kioskId, eventLogs) {
  const request =  {
    sessionInfo: null,
    currentClientTime: new Date().getTime(),
    clientId: kioskId,
    jsonExtend: undefined,
    carrierCode: undefined,
    asyncRequested: true,
    txnStateData: eventLogs
  }
  fetchWithTimeout('syncTxnStateSummary/', request)
  .then((response) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'syncTxnStateSummary response ... ' + JSON.stringify(response))
    if (response.etsResponse) {
      //store.dispatch(processTravelers(location, statusCode))
    }else if (response.error) {
        console.log('syncTxnStateSummary response ... ' + JSON.stringify(response))        
      }
  }).catch((error) => {
    console.log('error:', error)
  })
}