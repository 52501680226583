import acc_en from './translations/acc_en'
import acc_fr from './translations/acc_fr'
import acc_es from './translations/acc_es'
import acc_zh from './translations/acc_zh'

export function isEmpty(obj) {
  return (
    obj === undefined ||
    obj === null ||
    obj === '' ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0)
  )
}

export function getScreenId(pathName) {
  let pathParts = pathName.split('/')
  let screenId = ''
  if (pathParts.length > 2) {
    //screenId = pathParts[2]
    if (pathParts[2] === '') {
      screenId = 'OutOfService'
    } else {
      screenId = pathParts[2]
    }
  } else {
    if (pathParts[1] === '') {
      screenId = 'OutOfService'
    } else {
      screenId = pathParts[1]
    }
  }
  return screenId.toLowerCase()
}

export function getConveyorPosition(kioskType) {
  const res = kioskType ? kioskType.split('_') : ['EMBROSS']
  const pos = res.pop()
  return pos.toUpperCase()
}

export function applyParameters(targetStr, params) {
  let resultStr = targetStr === undefined ? '' : targetStr
  if (params && params.length > 0) {
    for (let i = 0; i < params.length; i++) {
      resultStr = resultStr.replace('{' + i + '}', params[i])
    }
  }
  return resultStr
}

export function getTranslations(translations) {
  if (translations) {
    return translations
  } else {
    return {
      en: acc_en,
      fr: acc_fr,
    }
  }
}

export function getSequenceById(id, group) {
  let sequence = null
  if (group && group.sequence) {
    group.sequence.forEach((seq) => {
      if (seq.subSequence) {
        if (seq.id && seq.id === id) {
          sequence = seq.subSequence
        }
      }
    })
  }

  return sequence
}
