/**
 * ETS Request and Response - identifyService
 */

import { store, history } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { ServiceNames, TransitionCodes, FunctionCodes, APISTransitions, END_TXN_REASON } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData } from 'actions/localActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { getBagDetails } from 'actions/etsTransactions/getBagDetails'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { isNotEmpty, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer } from 'utils/helper'
import { getTotalWeightInfo } from 'actions/etsTransactions/getTotalWeightInfo'
import { updateApis } from 'actions/etsTransactions/updateApis'

function buildIdentifyService(functionCode, serviceName) {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
      },
      jsonExtend: null,
      function: functionCode,
      serviceName: serviceName
    }
  )
}

export function identifyService(functionCode, serviceName) {
  // appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() request begins ... ')
  let request = buildIdentifyService(functionCode, serviceName)
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate('pleaseWait')
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('identifyService/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() receive response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))
          // dispatch based on transition code
          switch (json.transition) {
            case TransitionCodes.CUSTOM_4:
              navigate('AgentScan', 21)
              // dispatch(genericCommand('validateAgent', null, null))
              break
            case TransitionCodes.GET_BAGGAGE_DETAILS:
              dispatch(getBagDetails())
              break
            case TransitionCodes.GET_BAG_WEIGHT_LIMIT:
              dispatch(getTotalWeightInfo())
              break
            case TransitionCodes.ADD_APIS_V_2:
              appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() receive response to start passenger validation. ')
              //TODO: do we need something like validation start call to ETS ?
              appLog(TraceLevels.LOG_EXT_TRACE, '(identifyService.js) url: ScanDocuments')
              dispatch(updateApis())
              break
            case TransitionCodes.PAX_VALIDATION:
              appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() receive response to start passenger validation. ')
              //TODO: do we need something like validation start call to ETS ?
              appLog(TraceLevels.LOG_EXT_TRACE, '(identifyService.js) url: ScanDocuments')
              let pax = store.getState().localData.validatePaxResult[0]
              dispatch(updateLocalData('updateCurrentPassenger', pax))
              navigate('ScanDocuments', 21)
              break
            case TransitionCodes.SCAN_BP:
              appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() receive response to start scan boarding pass. ')
              //TODO: do we need something like validation start call to ETS ?
              appLog(TraceLevels.LOG_EXT_TRACE, '(identifyService.js) url: ScanBoardingPass')
              navigate('ScanBoardingPass', 21)
              break
            case TransitionCodes.SBD_PROCESS:
              //let url = Object.assign({}, { pathname: 'PutBagOnBelt' })
              appLog(TraceLevels.LOG_EXT_TRACE, '(identifyService.js) url: PutBagOnBelt')
              if (store.getState().localData.baggageLoadedStatus) {
                navigate('BagProcessing', 2)
              } else {
                navigate('PutBagOnBelt', 2)
              }
              break
            case TransitionCodes.DANGEROUS_GOODS:
              navigate('HazMatQuestion', 21)
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'identifyService() receive response, call for transitions.')
              dispatch(transitions(json.transition, json.etsResponse))
          }
        } else if (json.error) {
          goToFetchGenericError('identifyService', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'identifyService()', 'error')
      })
  }
}
