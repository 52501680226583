/************************************************************/
/**                   EKC Accessibility                    **/
/************************************************************/
export const AccEvents = {
  KEY_PRESSED: 'keyPressed',
  STATUS_CHANGED: 'enabled',
  LANGUAGE_CHANGED: 'LANGUAGE_CHANGED',
  PAGE_LOADED: 'PAGE_LOADED',
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  PLAY: 'play',
  LANGUAGE: 'language',
}

export const ACC_MODE = {
  SIMPLE: 'simple',
  SPEAKDIRECT: 'speakDirect',
  DYNAMIC: 'dynamic',
  DYNAMIC_SPEAK: 'dynamic_speak',
}

export const EZAKPKeys = {
  NAVHELP: 'NAVHELP',
  NAVPREVIOUS: 'NAVPREVIOUS',
  NAVNEXT: 'NAVNEXT',
  NAVDOWN: 'NAVDOWN',
  NAVUP: 'NAVUP',
  NAVENTER: 'NAVENTER',
  NAVHOME: 'NAVHOME',
  NAVEND: 'NAVEND',
}

export const KBKeys = {
  PLUS: 'PLUS', //HEADSET INSERTED
  MINUS: 'MINUS', //HEADSET REMOVED
  STAR: 'STAR', //CHANGE VOLUME
  ESC: 'ESC',
  LEFT: 'NAVPREVIOUS',
  RIGHT: 'NAVNEXT',
  UP: 'NAVUP',
  DOWN: 'NAVDOWN',
  ENTER: 'NAVENTER',
  TAB: 'TAB',
  SHIFT_TAB: 'SHIFT_TAB',
  HELP: 'NAVHELP',
}

export const KeyboardEventCode = {
  ESC: 'Escape',
  ENTER: 'Enter',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  HELP: 'Backquote',
}

/************************************************************/
/**                      Keypad Type                       **/
/************************************************************/
export const KEYPADS = {
  ALPHA_KEYPAD: 'ALPHA_KEYPAD',
  NUMERIC_KEYPAD: 'NUMERIC_KEYPAD',
  SYMBOL_KEYPAD: 'SYMBOL_KEYPAD',
}

// trace levels
export const LOGLevels = {
  LOG_SYSTEM: 0,
  LOG_ALERT: 1, // error message
  LOG_LEVEL2: 2,
  LOG_TRACE: 3, // trace
  LOG_LEVEL4: 4,
  LOG_EXT_TRACE: 5, // extended trace
  LOG_LEVEL6: 6,
  LOG_SECURE: 7, // Secure Data}
}

export const ACC_POPUPS = {
  POPUP: 'POPUP',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SHOW: 'POPUP_SHOW',
}
export const FLIGHT_STATUS = {
  ON_TIME: 'ON_TIME',
  DELAYED: 'DELAYED',
  CANCELLED: 'CANCELLED',
}
export const ACC_KEYPAD_TYPES = {
  ALL: 'ALL',
  NUMBER: 'NUMBER',
  CHAR: 'CHAR',
  SYMBOL: 'SYMBOL',
}
export const INPUT_TYPES = {
  CHARS: 'CHARS',
  PHONE: 'PHONE',
}

export const NUM_INPUT_FIELDS = {
  ONE: 'ONE',
  TWO: 'TWO',
}
export const ACC_KEY_GROUPS = {
  // NOTE: this is old syntax but keep for now because it's using RTA project.
  // TODO: RTA project has to be updated.
  keypadNumbers: {
    id: 'keypadNumbers',
    text: '0 - 9',
    status: '-1',
    detail: [
      { id: 'btn1', text: '1', buttonId: 'btn1' },
      { id: 'btn2', text: '2', buttonId: 'btn2' },
      { id: 'btn3', text: '3', buttonId: 'btn3' },
      { id: 'btn4', text: '4', buttonId: 'btn4' },
      { id: 'btn5', text: '5', buttonId: 'btn5' },
      { id: 'btn6', text: '6', buttonId: 'btn6' },
      { id: 'btn7', text: '7', buttonId: 'btn7' },
      { id: 'btn8', text: '8', buttonId: 'btn8' },
      { id: 'btn9', text: '9', buttonId: 'btn9' },
      { id: 'btn0', text: '0', buttonId: 'btn0' },
    ],
  },
  keypadNumbers0_9: [
    { id: 'btn0', text: '0', textId: 'btn0', buttonId: 'btn0', keypadGroupId: 'number' },
    { id: 'btn1', text: '1', textId: 'btn1', buttonId: 'btn1', keypadGroupId: 'number' },
    { id: 'btn2', text: '2', textId: 'btn2', buttonId: 'btn2', keypadGroupId: 'number' },
    { id: 'btn3', text: '3', textId: 'btn3', buttonId: 'btn3', keypadGroupId: 'number' },
    { id: 'btn4', text: '4', textId: 'btn4', buttonId: 'btn4', keypadGroupId: 'number' },
    { id: 'btn5', text: '5', textId: 'btn5', buttonId: 'btn5', keypadGroupId: 'number' },
    { id: 'btn6', text: '6', textId: 'btn6', buttonId: 'btn6', keypadGroupId: 'number' },
    { id: 'btn7', text: '7', textId: 'btn7', buttonId: 'btn7', keypadGroupId: 'number' },
    { id: 'btn8', text: '8', textId: 'btn8', buttonId: 'btn8', keypadGroupId: 'number' },
    { id: 'btn9', text: '9', textId: 'btn9', buttonId: 'btn9', keypadGroupId: 'number' },
  ],
  keypadNumbers1_0: [
    { id: 'btn1', text: '1', textId: 'btn1', buttonId: 'btn1', keypadGroupId: 'number' },
    { id: 'btn2', text: '2', textId: 'btn2', buttonId: 'btn2', keypadGroupId: 'number' },
    { id: 'btn3', text: '3', textId: 'btn3', buttonId: 'btn3', keypadGroupId: 'number' },
    { id: 'btn4', text: '4', textId: 'btn4', buttonId: 'btn4', keypadGroupId: 'number' },
    { id: 'btn5', text: '5', textId: 'btn5', buttonId: 'btn5', keypadGroupId: 'number' },
    { id: 'btn6', text: '6', textId: 'btn6', buttonId: 'btn6', keypadGroupId: 'number' },
    { id: 'btn7', text: '7', textId: 'btn7', buttonId: 'btn7', keypadGroupId: 'number' },
    { id: 'btn8', text: '8', textId: 'btn8', buttonId: 'btn8', keypadGroupId: 'number' },
    { id: 'btn9', text: '9', textId: 'btn9', buttonId: 'btn9', keypadGroupId: 'number' },
    { id: 'btn0', text: '0', textId: 'btn0', buttonId: 'btn0', keypadGroupId: 'number' },
  ],
  keypadLetters: [
    { id: 'btnA', text: 'A', textId: 'btnA', buttonId: 'btnA', keypadGroupId: 'letter' },
    { id: 'btnB', text: 'B', textId: 'btnB', buttonId: 'btnB', keypadGroupId: 'letter' },
    { id: 'btnC', text: 'C', textId: 'btnC', buttonId: 'btnC', keypadGroupId: 'letter' },
    { id: 'btnD', text: 'D', textId: 'btnD', buttonId: 'btnD', keypadGroupId: 'letter' },
    { id: 'btnE', text: 'E', textId: 'btnE', buttonId: 'btnE', keypadGroupId: 'letter' },
    { id: 'btnF', text: 'F', textId: 'btnF', buttonId: 'btnF', keypadGroupId: 'letter' },
    { id: 'btnG', text: 'G', textId: 'btnG', buttonId: 'btnG', keypadGroupId: 'letter' },
    { id: 'btnH', text: 'H', textId: 'btnH', buttonId: 'btnH', keypadGroupId: 'letter' },
    { id: 'btnI', text: 'I', textId: 'btnI', buttonId: 'btnI', keypadGroupId: 'letter' },
    { id: 'btnJ', text: 'J', textId: 'btnJ', buttonId: 'btnJ', keypadGroupId: 'letter' },
    { id: 'btnK', text: 'K', textId: 'btnK', buttonId: 'btnK', keypadGroupId: 'letter' },
    { id: 'btnL', text: 'L', textId: 'btnL', buttonId: 'btnL', keypadGroupId: 'letter' },
    { id: 'btnM', text: 'M', textId: 'btnM', buttonId: 'btnM', keypadGroupId: 'letter' },
    { id: 'btnN', text: 'N', textId: 'btnN', buttonId: 'btnN', keypadGroupId: 'letter' },
    { id: 'btnO', text: 'O', textId: 'btnO', buttonId: 'btnO', keypadGroupId: 'letter' },
    { id: 'btnP', text: 'P', textId: 'btnP', buttonId: 'btnP', keypadGroupId: 'letter' },
    { id: 'btnQ', text: 'Q', textId: 'btnQ', buttonId: 'btnQ', keypadGroupId: 'letter' },
    { id: 'btnR', text: 'R', textId: 'btnR', buttonId: 'btnR', keypadGroupId: 'letter' },
    { id: 'btnS', text: 'S', textId: 'btnS', buttonId: 'btnS', keypadGroupId: 'letter' },
    { id: 'btnT', text: 'T', textId: 'btnT', buttonId: 'btnT', keypadGroupId: 'letter' },
    { id: 'btnU', text: 'U', textId: 'btnU', buttonId: 'btnU', keypadGroupId: 'letter' },
    { id: 'btnV', text: 'V', textId: 'btnV', buttonId: 'btnV', keypadGroupId: 'letter' },
    { id: 'btnW', text: 'W', textId: 'btnW', buttonId: 'btnW', keypadGroupId: 'letter' },
    { id: 'btnX', text: 'X', textId: 'btnX', buttonId: 'btnX', keypadGroupId: 'letter' },
    { id: 'btnY', text: 'Y', textId: 'btnY', buttonId: 'btnY', keypadGroupId: 'letter' },
    { id: 'btnZ', text: 'Z', textId: 'btnZ', buttonId: 'btnZ', keypadGroupId: 'letter' },
  ],
  keypadLetterA_J: [
    { id: 'btnA', text: 'A', textId: 'btnA', buttonId: 'btnA', keypadGroupId: 'letterA_J' },
    { id: 'btnB', text: 'B', textId: 'btnB', buttonId: 'btnB', keypadGroupId: 'letterA_J' },
    { id: 'btnC', text: 'C', textId: 'btnC', buttonId: 'btnC', keypadGroupId: 'letterA_J' },
    { id: 'btnD', text: 'D', textId: 'btnD', buttonId: 'btnD', keypadGroupId: 'letterA_J' },
    { id: 'btnE', text: 'E', textId: 'btnE', buttonId: 'btnE', keypadGroupId: 'letterA_J' },
    { id: 'btnF', text: 'F', textId: 'btnF', buttonId: 'btnF', keypadGroupId: 'letterA_J' },
    { id: 'btnG', text: 'G', textId: 'btnG', buttonId: 'btnG', keypadGroupId: 'letterA_J' },
    { id: 'btnH', text: 'H', textId: 'btnH', buttonId: 'btnH', keypadGroupId: 'letterA_J' },
    { id: 'btnI', text: 'I', textId: 'btnI', buttonId: 'btnI', keypadGroupId: 'letterA_J' },
    { id: 'btnJ', text: 'J', textId: 'btnJ', buttonId: 'btnJ', keypadGroupId: 'letterA_J' },
  ],
  keypadLetterK_T: [
    { id: 'btnK', text: 'K', textId: 'btnK', buttonId: 'btnK', keypadGroupId: 'letterK_T' },
    { id: 'btnL', text: 'L', textId: 'btnL', buttonId: 'btnL', keypadGroupId: 'letterK_T' },
    { id: 'btnM', text: 'M', textId: 'btnM', buttonId: 'btnM', keypadGroupId: 'letterK_T' },
    { id: 'btnN', text: 'N', textId: 'btnN', buttonId: 'btnN', keypadGroupId: 'letterK_T' },
    { id: 'btnO', text: 'O', textId: 'btnO', buttonId: 'btnO', keypadGroupId: 'letterK_T' },
    { id: 'btnP', text: 'P', textId: 'btnP', buttonId: 'btnP', keypadGroupId: 'letterK_T' },
    { id: 'btnQ', text: 'Q', textId: 'btnQ', buttonId: 'btnQ', keypadGroupId: 'letterK_T' },
    { id: 'btnR', text: 'R', textId: 'btnR', buttonId: 'btnR', keypadGroupId: 'letterK_T' },
    { id: 'btnS', text: 'S', textId: 'btnS', buttonId: 'btnS', keypadGroupId: 'letterK_T' },
    { id: 'btnT', text: 'T', textId: 'btnT', buttonId: 'btnT', keypadGroupId: 'letterK_T' },
  ],
  keypadLetterU_Z: [
    { id: 'btnU', text: 'U', textId: 'btnU', buttonId: 'btnU', keypadGroupId: 'letterU_Z' },
    { id: 'btnV', text: 'V', textId: 'btnV', buttonId: 'btnV', keypadGroupId: 'letterU_Z' },
    { id: 'btnW', text: 'W', textId: 'btnW', buttonId: 'btnW', keypadGroupId: 'letterU_Z' },
    { id: 'btnX', text: 'X', textId: 'btnX', buttonId: 'btnX', keypadGroupId: 'letterU_Z' },
    { id: 'btnY', text: 'Y', textId: 'btnY', buttonId: 'btnY', keypadGroupId: 'letterU_Z' },
    { id: 'btnZ', text: 'Z', textId: 'btnZ', buttonId: 'btnZ', keypadGroupId: 'letterU_Z' },
  ],
  // NOTE: Old syntax. Eventually all should be replaced with new format
  keypadLetterU_Z_DSH_SQ: {
    id: 'keypadLetterU_ZWithAccent',
    text: 'U - Z',
    status: '-1',
    detail: [
      { id: 'btnU', text: 'U', textId: 'btnU' },
      { id: 'btnV', text: 'V', textId: 'btnV' },
      { id: 'btnW', text: 'W', textId: 'btnW' },
      { id: 'btnX', text: 'X', textId: 'btnX' },
      { id: 'btnY', text: 'Y', textId: 'btnY' },
      { id: 'btnZ', text: 'Z', textId: 'btnZ' },
      { id: 'btnDSH', text: '-', textId: 'btnDSH' },
      { id: 'btnSQ', text: '\u0027', textId: 'btnSQ' },
    ],
  },
  keypadLetterOnlyU_Z: {
    id: 'keypadLetterOnlyU_Z',
    text: 'U - Z',
    status: '-1',
    detail: [
      { id: 'btnU', text: 'U', textId: 'btnU' },
      { id: 'btnV', text: 'V', textId: 'btnV' },
      { id: 'btnW', text: 'W', textId: 'btnW' },
      { id: 'btnX', text: 'X', textId: 'btnX' },
      { id: 'btnY', text: 'Y', textId: 'btnY' },
      { id: 'btnZ', text: 'Z', textId: 'btnZ' },
    ],
  },
  keypadFunction: {
    id: 'keypadFunction',
    text: 'functionKeys',
    status: '-1',
    detail: [
      { id: 'abuttonBS', textId: 'buttonBS', text: 'BACK SPACE', type: 'function' },
      { id: 'abuttonSPC', textId: 'buttonSPC', text: 'SPACE', type: 'function' },
      { id: 'abuttonCLR', textId: 'buttonCLR', text: 'CLEAR', type: 'function' },
      { id: 'abuttonAction', textId: 'buttonExitInputMode', text: 'EXIT', type: 'action' },
    ],
  },
  keypadFunctionWithoutSpace: {
    id: 'keypadFunctionWithoutSpace',
    text: 'functionKeys',
    status: '-1',
    detail: [
      { id: 'abuttonBS', textId: 'buttonBS', text: 'BACK SPACE', type: 'function' },
      { id: 'abuttonCLR', textId: 'buttonCLR', text: 'CLEAR', type: 'function' },
      { id: 'abuttonAction', textId: 'buttonExitInputMode', text: 'EXIT', type: 'action' },
    ],
  },
  keypadFunctionWithSymbol: {
    id: 'keypadFunctionWithoutSpace',
    text: 'functionKeys',
    status: '-1',
    detail: [
      { id: 'abuttonBS', textId: 'buttonBS', text: 'BACK SPACE', type: 'function' },
      { id: 'abuttonCLR', textId: 'buttonCLR', text: 'CLEAR', type: 'function' },
      { id: 'abuttonSymbol', textId: 'buttonToSymKeypad', text: '#@?', type: 'action' },
      { id: 'abuttonAction', textId: 'buttonExitInputMode', text: 'EXIT', type: 'action' },
    ],
  },
  keypadFunctionWithAlpha: {
    id: 'keypadFunctionWithoutSpace',
    text: 'functionKeys',
    status: '-1',
    detail: [
      { id: 'abuttonBS', textId: 'buttonBS', text: 'BACK SPACE', type: 'function' },
      { id: 'abuttonCLR', textId: 'buttonCLR', text: 'CLEAR', type: 'function' },
      { id: 'abuttonABC', textId: 'buttonToABCKeypad', text: 'ABC', type: 'action' },
      { id: 'abuttonAction', textId: 'buttonExitInputMode', text: 'EXIT', type: 'action' },
    ],
  },

  keypadFunTwoInput: {
    id: 'keypadFunction',
    text: 'functionKeys',
    status: '-1',
    detail: [
      { id: 'abuttonBS', textId: 'buttonBS', text: 'BACK SPACE', type: 'function' },
      { id: 'abuttonSPC', textId: 'buttonSPC', text: 'SPACE', type: 'function' },
      { id: 'abuttonCLR', textId: 'buttonCLR', text: 'CLEAR', type: 'function' },
      { id: 'inputSwitchAction', textId: 'switchField', text: '', type: 'action' },
      { id: 'abuttonAction', textId: 'buttonExitInputMode', text: 'EXIT', type: 'action' },
    ],
  },

  keypadSymbolNormal: {
    id: 'keypadSymbolsNormal',
    text: 'SymbolsNormal',
    status: '-1',
    detail: [
      { id: 'btnDSH', text: '-' },
      { id: 'btnNUM', text: '#' },
      { id: 'btnSQ', text: '\u0027' },
      { id: 'btnCMM', text: ',' },
      { id: 'btnDT', text: '.' },
      { id: 'btnBS', text: '\u2190' },
      { id: 'buttonSPC', text: 'SPACE', special: true },
      { id: 'buttonCLR', text: 'CLEAR', special: true },
    ],
  },

  keypadSymbolOne: {
    id: 'keypadSymbolOne',
    text: 'SymbolsOne',
    status: '-1',
    detail: [
      { id: 'btnEM', text: '!', textId: 'btnEM' },
      { id: 'btnNUM', text: '#', textId: 'btnNUM' },
      { id: 'btnDL', text: '$', textId: 'btnDL' },
      { id: 'btnPC', text: '%', textId: 'btnPC' },
      { id: 'btnCRT', text: '^', textId: 'btnCRT' },
      { id: 'btnAMP', text: '\u0026', textId: 'btnAMP' },
      { id: 'btnASK', text: '*', textId: 'btnASK' },
      { id: 'btnPS', text: '+', textId: 'btnPS' },
      { id: 'btnDSH', text: '-', textId: 'btnDSH' },
      { id: 'btnEQ', text: '=', textId: 'btnEQ' },
    ],
  },
  keypadSymbolTwo: {
    id: 'keypadSymbolTwo',
    text: 'SymbolsTwo',
    status: '-1',
    detail: [
      { id: 'btnUSC', text: '_', textId: 'btnUSC' },
      { id: 'btnOBS', text: '\u007B', textId: 'btnOBS' },
      { id: 'btnCBS', text: '\u007D', textId: 'btnCBS' },
      { id: 'btnVB', text: '\u007C', textId: 'btnVB' },
      { id: 'btnSQ', text: '\u0027', textId: 'btnSQ' },
      { id: 'btnSL', text: '/', textId: 'btnSL' },
      { id: 'btnES', text: '~', textId: 'btnES' },
      { id: 'btnQU', text: '?', textId: 'btnQU' },
      { id: 'btnGA', text: '`', textId: 'btnGA' },
      { id: 'btnDT', text: '.', textId: 'btnDT' },
    ],
  },

  keypadSymbol1: {
    id: 'keypadSymbols1',
    text: 'Symbols1',
    status: '-1',
    detail: [
      { id: 'btnAT', text: '@' },
      { id: 'btnDT', text: '.' },
      { id: 'btnUSC', text: '_' },
      { id: 'btnDSH', text: '-' },
      { id: 'btnCOM', text: '.COM', special: true },
      { id: 'btnBS', text: '\u2190' },
    ],
  },
  keypadSymbol2: {
    id: 'keypadSymbols2',
    text: 'Symbols2',
    status: '-1',
    detail: [
      { id: 'btnEM', text: '!' },
      { id: 'btnNUM', text: '#' },
      { id: 'btnDL', text: '$' },
      { id: 'btnPC', text: '%' },
      { id: 'btnAMP', text: '\u0026' },
      { id: 'btnSQ', text: '\u0027' },
      { id: 'btnEQ', text: '=' },
      { id: 'btnQU', text: '?' },
      { id: 'btnCRT', text: '^' },
      { id: 'btnSL', text: '/' },
      { id: 'btnVB', text: '\u007C' },
      { id: 'btnGA', text: '`' },
      { id: 'btnASK', text: '*' },
      { id: 'btnPS', text: '+' },
      { id: 'btnOBS', text: '\u007B' },
      { id: 'btnCBS', text: '\u007D' },
      { id: 'btnES', text: '~' },
      { id: 'btnBS', text: '\u2190' },
    ],
  },

  emailShortcut: {
    id: 'emailShortcut',
    text: '@shortcuts',
    status: '-1',
    detailCss: 'nk_acc_keypad_key',
    // detail: config.emailButtons,
  },
}

export const symbol2ButtonId = [
  { id: 'btnSpace', text: ' ' },
  { id: 'btnAT', text: '@' },
  { id: 'btnDT', text: '.' },
  { id: 'btnUSC', text: '_' },
  { id: 'btnDSH', text: '-' },
  { id: 'btnCMM', text: ',' },
  { id: 'btnEM', text: '!' },
  { id: 'btnNUM', text: '#' },
  { id: 'btnDL', text: '$' },
  { id: 'btnPC', text: '%' },
  { id: 'btnAMP', text: '\u0026' },
  { id: 'btnSQ', text: '\u0027' },
  { id: 'btnEQ', text: '=' },
  { id: 'btnQU', text: '?' },
  { id: 'btnCRT', text: '^' },
  { id: 'btnSL', text: '/' },
  { id: 'btnVB', text: '\u007C' },
  { id: 'btnGA', text: '`' },
  { id: 'btnASK', text: '*' },
  { id: 'btnPS', text: '+' },
  { id: 'btnOBS', text: '\u007B' },
  { id: 'btnCBS', text: '\u007D' },
  { id: 'btnES', text: '~' },
]

export const ACCESSIBILITY_HANDLER = {
  DEFAULT: 'DEFAULT',
  KEYPAD: 'KEYPAD',
  SUBLIST: 'SUBLIST',
  COUNTER: 'COUNTER',
  CHECKBOX: 'CHECKBOX',
  CALENDAR: 'CALENDAR',
  POPUP_SCROLL: 'POPUP_SCROLL',
}
