import { isInteger } from 'utils/helper'
import axios from 'axios'
/**
 *  stringify the error object
 *  @param {Object} err error object
 *  @return {string} string representing the error object
 */
function stringifyError(err) {
  let plainObject = {}
  Object.getOwnPropertyNames(err).forEach((key) => {
    plainObject[key] = err[key]
  })
  return JSON.stringify(plainObject)
}

/**
 *  Handle fetch response
 *  @param {json} response in JSON format
 *  @throws {Error} when the status of the response is not OK (200)
 *  @return {json} when response is OK the returns it
 */
function fetchStatusHandler(response) {
  if (response.status === 200) {
    return response
  } else {
    //console.log('fetchStatusHandler:', response)
    let error = new Error(response.statusText)
    error.detail = response.status
    error.source = 'fetch'
    //console.log(stringifyError(error))
    throw error
  }
}

/**
 *  Builds the error object for timeout
 *  @return {Error} error object for timeout
 */
function timeoutError() {
  let error = new Error('timeout')
  error.detail = 777
  error.source = 'fetch'
  //console.log(stringifyError(error))
  return error
}

export async function fetchWithTimeout(uri, data, ms, log) {
  let response = null
  if (!isInteger(ms) || ms === 0) ms = 60000
  if (log) {
    log('fetchWithTimeout: url:[' + uri + '] ' + 'timeout(ms):' + ms)
  } else {
    //console.log('fetchWithTimeout: url:[' + uri + '] ' + 'timeout(ms):' + ms)
  }
  try {
    response = await axios.post(uri, data, { timeout: ms })
  } catch (error) {
    if (log) {
      log('>> (FetchWithTimeout.js) Catch:' + error)
    }
    //console.log('>> (FetchWithTimeout.js) Catch:' + error)
  }
  if (response) {
    return response.data
  } else {
    return null
  }
}
