import { ErrCodes } from '../constants/Errors'

export function parseBarcode(barcodeData) {
  let names = barcodeData
    .substr(2, 20)
    .trim()
    .split('/')
  let barcode = {
    pnr: barcodeData.substr(23, 6),
    toAirport: barcodeData.substr(33, 3),
    fromAirport: barcodeData.substr(30, 3),
    airline: barcodeData.substr(36, 3).trim(),
    flightNumber: barcodeData.substr(39, 4).trim(),
    firstName: names[1],
    lastName: names[0]
  }
  return barcode
}

export function parseDocumentData(data) {
  let ret = null
  try {
    console.log('(ScanDocuments.js) parseDocumentData() - Data Read: ' + data.substring(0, 5))
    //appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) parseDocumentData() - Data Read Complete: ' + data)
    let parsedDataStart = data.indexOf('[')
    let passportData = []
    let parsedData = null
    let extendedPassportData = null
    let rfchipFlag = false
    let efcom = ''
    let efsod = ''
    let dg1file = ''
    let dg2file = ''
    if (parsedDataStart === -1) {
      passportData = data.split(',')
      if (passportData && passportData.length > 0) {
        ret = {
          rfchipFlag,
          mrz: passportData[0],
          // 'rfid': rfid,
          rfmrz: '',
          emrtdData: {
            dataGroup1: dg1file,
            dataGroup2: dg2file,
            efcom: efcom,
            efsod: efsod,
            dg2: passportData.length > 1 ? passportData[1] : ''
          }
        }
      } else {
        ret = {
          error: {
            errorCode: ErrCodes.PASSPORT_EDATA_NOT_COMPLETE
          }
        }
      }
    } else {
      if (parsedDataStart >= 0) {
        passportData = data.substring(0, parsedDataStart).split(',')
        let parsedDataEnd = data.indexOf(']')
        parsedData = data.substring(parsedDataStart + 1, parsedDataEnd).split(',')
        if (parsedDataEnd + 2 < data.length) extendedPassportData = data.substring(parsedDataEnd + 2).split(',')
      } else {
        passportData = data.split(',')
      }
      //appLog(TraceLevels.LOG_TRACE, '(ScanDocuments.js) parseDocumentData() passportData.length: ' + passportData.length)
      //appLog(TraceLevels.LOG_TRACE, '(ScanDocuments.js) parseDocumentData() passportData ' + passportData)
      for (let i = 0; i < passportData.length; i++) {
        console.log('passportData[' + i + '] = ' + passportData[i].substring(0, 5))
      }
      if (parsedData === null) {
        // incorrect travel doc
        return ErrCodes.DOCUMENT_NOT_SUPPORTED
      }
      console.log('(ScanDocuments.js) parsedData.length: ' + parsedData.length)
      // appLog(TraceLevels.LOG_EXT_TRACE,'parsedData = '+ parsedData)  // remove - contains sensitive data
      // to do make one line log with masked data
      let skipItems = [8, 9, 11]
      /*for (let i = 0; i < parsedData.length; i++) {
        parsedData[i] = parsedData[i].trim()
        if (i === 2) {  // last name
          appLog(TraceLevels.LOG_LEVEL6, 'parsedData[' + i + '] =' + parsedData[i].replace(/./gi, 'N'))
        } else if (i === 22) {  // age
          appLog(TraceLevels.LOG_LEVEL6, 'parsedData[' + i + '] =' + parsedData[i])
        } else if (!skipItems.includes(i))
          appLog(TraceLevels.LOG_LEVEL6, 'parsedData[' + i + '] =' + parsedData[i])
      }*/

      let mediaCode = parsedData[0]
      // check if travel doc is acceptable mediaType must be on the config.acceptableTravelDocs list
      /*if (!config.acceptableTravelDocs.includes(mediaCode)) {
        return ErrCodes.DOCUMENT_NOT_SUPPORTED
      }
*/
      let mrz = passportData.length >= 1 ? passportData[0] : ''
      let rfmrz = passportData.length >= 2 ? passportData[1].trim() : ''
      let dg2 = passportData.length >= 3 ? passportData[2].trim() : ''
      // let rfid = data

      if (extendedPassportData !== null) {
        for (let i = 0; i < extendedPassportData.length; i++) {
          console.log('extendedPassportData[' + i + '] = ' + extendedPassportData[i].substring(0, 10))
        }
        if (extendedPassportData.length >= 1) rfchipFlag = extendedPassportData[0].trim() === 'RFCHIP=Y' ? true : false
        efcom = extendedPassportData.length >= 2 ? extendedPassportData[1].trim() : ''
        efsod = extendedPassportData.length >= 3 ? extendedPassportData[2].trim() : ''
        dg1file = extendedPassportData.length >= 4 ? extendedPassportData[3].trim() : ''
        dg2file = extendedPassportData.length >= 5 ? extendedPassportData[4].trim() : ''
      }

      // if epassport and data not complete then retry - if max retry reached - continue with partial data
      /*if (rfchipFlag && (rfmrz === '' || dg2 === '' || efcom === '' || efsod === '' || dg1file === '' || dg2file === '')) {
        let updatedTravelDocScanAttempts = this.props.travelDocScanAttempts + 1
        if ((this.props.travelDocScanAttempts + 1) < this.maxTravelDocScanAttempts) {
          return ErrCodes.PASSPORT_EDATA_NOT_COMPLETE
        }
      }*/
      // if (rfchipFlag && (rfid === '')) {
      // let updatedTravelDocScanAttempts = this.props.travelDocScanAttempts + 1
      // if ((this.props.travelDocScanAttempts + 1) < this.maxTravelDocScanAttempts) {
      //   return ErrCodes.PASSPORT_EDATA_NOT_COMPLETE
      // }
      // }

      let mediaType = parsedData[1]
      let lastName = parsedData[2]
      let firstName = parsedData[3]
      let middleName = parsedData[4]
      let nationality = parsedData[5]
      let dateOfBirth = ''
      if (parsedData.length >= 10) {
        dateOfBirth =
          '' +
          parsedData[7].substring(2) +
          (parsedData[8].length === 1 ? '0' + parsedData[8] : parsedData[8]) +
          (parsedData[9].length === 1 ? '0' + parsedData[9] : parsedData[9])
      }
      let age = parseInt(parsedData[parsedData.length - 1])

      let tracks = mrz.split('_@')
      let rfTracks = rfmrz.split('_@')
      let mrzMain = '' + tracks[0] + ',' + tracks[1] + ',' + tracks[2]
      if (rfmrz !== '' && (rfTracks.length === 4 || rfTracks.length === 3)) {
        rfmrz = '' + rfTracks[0] + ',' + rfTracks[1] + ',' + rfTracks[2]
        mrzMain = rfmrz
      }
      // else {
      //   mrzMain = rfid
      // }

      /*if (this.isMrzDuplicate(mrzMain)) {
        return ErrCodes.TRAVEL_DOCUMENT_DUPLICATE
      }*/

      /*if (config.useAltStore) {
        getAltStore().addDG2Photo(this.props.paxOrdinal, dg2)
        if (rfchipFlag) {
          getAltStore().addEMRTD(this.props.paxOrdinal, {
            'dataGroup1': dg1file,
            'dataGroup2': dg2file,
            'efcom': efcom,
            'efsod': efsod
          })
        }
      }*/

      ret = {
        rfchipFlag,
        mrz: mrz,
        // 'rfid': rfid,
        rfmrz: rfmrz,
        emrtdData: {
          dataGroup1: dg1file,
          dataGroup2: dg2file,
          efcom: efcom,
          efsod: efsod,
          dg2: dg2
        }
      }
    }
  } catch (err) {
    ret = {
      error: {
        errorCode: ErrCodes.PASSPORT_ERROR
      }
    }
  }
  return ret
}
