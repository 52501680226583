import { updateError } from 'actions/commonActions'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { updateLocalData } from 'actions/localActions'
import { DynamicImage, PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { ErrCodes, SourceType } from 'constants/Errors'
import { TraceLevels } from 'embross-device-manager'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, playSound, history } from 'main'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getScreenId, goToLocalGenericError, navigate, populateItineraryInfo } from 'utils/helper'
import { turnLights } from 'utils/lights'
import { appLog, logEvent } from 'utils/Logger'
import { Footer } from '../footer'

import { identifyService } from 'actions/etsTransactions/identifyService'
import { FunctionCodes, TransitionCodes } from 'constants/Constants'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const mapErrImage = (errCode) => {
  const themeContext = useContext(ThemeContext)
  const width = themeContext.AnimationWidth
  const height = themeContext.AnimationHeight
  const imageError = themeContext.ImageError

  const animationSize = useMedia(null, [500, 500, 420], 420)
  let location = null
  switch (errCode) {
    case ErrCodes.TOO_EARLY_BAGGAGE:
      location = `too-early.svg`
      break
    case ErrCodes.TOO_LATE_BAGGAGE:
      location = `too-late.svg`
      break
    default:
      location = imageError?.agentOverride ?? `generic-agent.svg`
      break
  }
  let result = (
    <DynamicImage
      imageName={location}
      cssName={'emb_animation_drawbox'}
      width={width ? width : animationSize}
      height={height ? height : animationSize}
    />
  )
  return result
}

const isQuitRequired = true

const AgentOverride = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const errorDetails = useSelector((state) => state.errorDetails)
  const errorCode = useSelector((state) => state.sessions.agentOverrideError)
  const serviceName = useSelector((state) => state.sessions.currentServiceName)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const errImage = mapErrImage(errorCode)
  const [errMsgTitle, setErrMsgTitle] = useState('')
  const [errMsgSubTitle, setErrMsgSubTitle] = useState('')
  const locale = useSelector((state) => state.localData.locale)
  const bagtagObj = useSelector((state) => state.localData.bagtagObject)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  const itineraryObj = useSelector((state) => state.localData.itineraryObj)
  const location = useLocation()
  //const transition = location.state.transition
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const buildAccessibility = getBuildAccessibility()
  const itineraryData = itineraryInfo ? populateItineraryInfo(itineraryInfo) : null
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const scannedCallback = (scannedValue) => {
    let tempBagtag = { ...{}, ...bagtagObj }
    delete tempBagtag.paxOrdinal
    delete tempBagtag.paxName
    //const errorCode = errorDetails.errCode
    const overrideScanErrorsList = config.overrideScanErrors.map((error) => ErrCodes[error])
    const overrideBagErrorsList = config.overrideBagErrors.map((error) => ErrCodes[error])
    /*  dispatch(
      genericCommand(
        'overrideStatus',
        { barcodeData: scannedValue, bagTagData: tempBagtag, isOverWeight: true },
        TransitionCodes.SBD_PROCESS
      )
    ) */
    logEvents(EventFlows.AgentOverride, EventFunctions.QRScan, 'Agent QR code scanned for override')
    if (overrideScanErrorsList.includes(errorCode)) {
      history.push('pleaseWait')
      dispatch(genericCommand('overrideStatus', { barcodeData: scannedValue }, TransitionCodes.SBD_PROCESS))
    } else if (overrideBagErrorsList.includes(errorCode)) {
      history.push('pleaseWait')
      dispatch(
        genericCommand(
          'overrideStatus',
          { barcodeData: scannedValue, bagTagData: tempBagtag, isOverWeight: true },
          TransitionCodes.SBD_PROCESS
        )
      )
    }
    // if (errorCode === ErrCodes.MAX_POOL_WEIGHT) {
    //   dispatch(
    //     genericCommand(
    //       'overrideStatus',
    //       { barcodeData: scannedValue, bagTagData: tempBagtag, isOverWeight: true },
    //       TransitionCodes.SBD_PROCESS
    //     )
    //   )
    // } else {
    //   dispatch(
    //     genericCommand(
    //       'overrideStatus',
    //       { barcodeData: scannedValue, bagTagData: tempBagtag, isOverWeight: true },
    //       null
    //     )
    //   )
    // }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
  let SBDAppManager = getSBDAppMan()

  let titleMainDisplay = null

  useEffect(() => {
    handleAccessibility()
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(AgentOverride.js) call barcode enable function')
      if (config.override) {
        enable()
        turnLights('kioskAgentHelp', true)
      }
    }
    if (errorDetails.errCode === 'SBDERR_HEAVYTAG') {
      SBDAppManager.setAppFlow(21)
    }
    return () => {
      if (config.override) {
        disable()
        turnLights('kioskAgentHelp', false)
      }
      getDeviceManager()
        .getAppManager()
        .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)

      dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
      dispatch(updateError(null)) // RESET ERROR
    }
  }, [])

  const getErrMsg = (msgs, language) => {
    let errMsg = {
      errorTitle: '',
      errorSubTitle: ''
    }
    let message = msgs.filter((msg) => msg.languageCode.toUpperCase() === language.toUpperCase())
    if (message.length > 0) {
      let msgParts = message[0].screenMsg.split('***')
      if (msgParts.length > 1) {
        const subMessage = msgParts.slice(1).join('\n\n')
        errMsg = {
          errorTitle: msgParts[0],
          errorSubTitle: subMessage
        }
      } else {
        errMsg = {
          errorTitle: msgParts[0],
          errorSubTitle: ''
        }
      }
    }
    return errMsg
  }

  const setErrorMessage = () => {
    let errorMessage = {
      errorTitle: '',
      errorSubTitle: ''
    }
    if (errorDetails.sourceType === SourceType.FETCH) {
      if (Array.isArray(errorDetails.msg)) {
        errorMessage = getErrMsg(errorDetails.msg, locale)
      }
    } else {
      let msgParts = []
      let msgText = ''
      if (messages[errorDetails.msg]) {
        if (errorDetails.params) {
          msgText = intl.formatMessage(messages[errorDetails.msg], errorDetails.params)
        } else {
          msgText = intl.formatMessage(messages[errorDetails.msg])
        }
      }
      if (msgText.length > 0) {
        msgParts = msgText.split('\n\n')
      }
      if (msgParts.length == 1) {
        errorMessage.errorTitle = msgParts[0]
      } else if (msgParts.length > 1) {
        errorMessage.errorTitle = msgParts[0]
        errorMessage.errorSubTitle = msgParts[1]
      }
    }
    return errorMessage
  }

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined
    const errorMessage = setErrorMessage()
    const errorMsg = [errorMessage.errorTitle, errorMessage.errorSubTitle]
    const accDef = {
      pathName: 'AgentOverride',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'OneDynamicText', textParameters: [errorMsg.join('. ')] }]
      }
    }
    buildAccessibility(accDef)
  }

  useEffect(() => {
    const errorMessage = setErrorMessage()
    setErrMsgTitle(errorMessage.errorTitle)
    setErrMsgSubTitle(errorMessage.errorSubTitle)
    logEvent('Agent override: ' + errorMessage.errorTitle)
  }, [locale])

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_TRACE,
      '(AgentOverride.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    playSound.beepOK()

    /// TEST CODE
    // let barcode = 'EMBROSSTEST'
    const barcode = 'OverrideSBD'
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'confirmBtn':
          handleExitPage()
          break
        case 'buttonScanBarcodeTEST':
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: barcode
          })
          break
        case 'buttonQuitTEST':
          goToLocalGenericError('TESTING', ErrCodes.CONVEYOR_INTRUSION, 'Error_ConveyorIntrusion', 'PutBagOnBelt', [])
          break
        case 'buttonInvalid':
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: 'Invalid'
          })
          break
        default:
          break
      }
    }
    // end OF TEST CODE

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        handleExitPage()
        break
      case 'backBtn':
        navigate('PutBagOnBelt', 2)
        break

      default:
        break
    }
  }

  const handleExitPage = () => {
    dispatch(identifyService(FunctionCodes.NEXT, serviceName))
  }

  const testData = [
    {
      id: 'buttonScanBarcodeTEST',
      text: 'Test Override',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonQuitTEST',
      text: 'Intrusion',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonInvalid',
      text: 'Invalid',
      handler: handleActions,
      cssName: 'test-button-up'
    }
  ]

  const iconSize = useMedia(null, [50, 50, 45], 45)
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )

  const animationSection = errImage

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {errMsgTitle}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {errMsgSubTitle}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      {intl.formatMessage(messages.ErrorTitle)}
    </PageHeader>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={isQuitRequired}
        isBackRequired={false}
        isSkipRequired={false}
        //isConfirmRequired={!(errorDetails && errorDetails.confirmDisable)}
        isConfirmRequired={false}
        //confirmAction={handleActions}
        isLangRequired={true}
        isHelpRequired={false}
        testData={testData}
      />
      {/* <TestButtons data={testButtons} /> */}
    </>
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default AgentOverride
