import device from 'devices/device'
import { deviceIds, TraceLevels } from 'constants/Constants'
import ATBPrinterListener from 'listeners/ATBPrinterListener'

/**
 *  Device representing an ATB Printer
 *  @extends {Device}
 */
export default class ATBPrinter extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'ATB Printer'
    /**
     * Device id - deviceIds.ATB_PRINTER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.ATB_PRINTER
    this.setListener(new ATBPrinterListener())
  }

  /**
   *  Async call to determine whether all bins are empty
   */
  areAllBinsEmpty() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'areAllBinsEmpty().')
      this.socket.sendRequest(this.deviceId, 'areAllBinsEmpty')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'areAllBinsEmpty() exception: ' + e)
    }
  }

  /**
   *  Async call to determine whether tickets are in hopper
   */
  isTicketInHopper() {
    try {
      this.logMessage(TraceLevels.LOG_ALERT, 'isTicketInHopper().')
      this.socket.sendRequest(this.deviceId, 'isTicketInHopper')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isTicketInHopper() exception: ' + e)
    }
  }

  /**
   *  Async call to determine whether a ticket has been inserted
   */
  isTicketInPrinter() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isTicketInPrinter().')
      this.socket.sendRequest(this.deviceId, 'isTicketInPrinter')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isTicketInPrinter() exception: ' + e)
    }
  }

  /**
   *  Async call to determine whether pectabs have previously been loaded into the printer
   */
  isPECTABLoadedInPrinter() {
    try {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPECTABLoadedInPrinter().')
      this.socket.sendRequest(this.deviceId, 'isPECTABLoadedInPrinter')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPECTABLoadedInPrinter() exception: ' + e)
    }
  }

  /**
   *  Async call to determine the ATBPrinterBinStatus
   */
  getBinStatus() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getBinStatus().')
      this.socket.sendRequest(this.deviceId, 'getBinStatus')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getBinStatus() exception: ' + e)
    }
  }

  /**
   *  Load a pectab into the printer
   *  @param {String} pectab - pectab to be loaded (can also be a file)
   *  @param {...String} params - pectab to be loaded (can also be a file)
   */
  loadPECTAB(pectab, ...params) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'loadPECTAB().')
      this.socket.sendCommand(this.deviceId, 'loadPECTAB', pectab, ...params)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'loadPECTAB() exception: ' + e)
    }
  }

  /**
   *  Load a pectab into the printer
   *  @param {String} pectab - pectab to be loaded in Encoded in Base64
   */
  loadPECTABBase64(pectab) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'loadPECTABBase64().')
      this.socket.sendCommand(this.deviceId, 'loadPECTAB', pectab, true)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'loadPECTABBase64() exception: ' + e)
    }
  }

  /**
   *  Load a pectab into the printer
   *  @param {String[]} arrayPectabs - array of pectabs to be loaded
   *  @param {Boolean} isBase64Encoded - true when encoded, false otherwise
   */
  loadPECTABArray(arrayPectabs, isBase64Encoded) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'loadPECTABArray() lth: ' + arrayPectabs.length)
      this.socket.sendCommand(this.deviceId, 'loadPECTABArray', arrayPectabs, isBase64Encoded)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'loadPECTABArray() exception: ' + e)
    }
  }

  /**
   *  Async call to eject the currently inserted coupon
   */
  eject() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'eject().')
      this.socket.sendCommand(this.deviceId, 'eject')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'eject() exception: ' + e)
    }
  }

  /**
   *  Async call to capture coupons currently held inside an escrow
   */
  capture() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'capture().')
      this.socket.sendCommand(this.deviceId, 'capture')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'capture() exception: ' + e)
    }
  }

  /**
   *  Async call to read the ticket contents
   */
  read() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'read().')
      this.socket.sendCommand(this.deviceId, 'read')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'read() exception: ' + e)
    }
  }

  /**
   *  Async call to offer the ticket to the user
   *  @param {int} timeout Timeout to wait in msec.
   */
  offer(timeout) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'offer(): ' + timeout)
      this.socket.sendRequest(this.deviceId, 'offer', timeout)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'offer() exception: ' + e)
    }
  }

  /**
   *  Print a data stream
   *  @param {String} stream Datastream
   *  @param {int} timeout Timeout to wait in msec.
   */
  print(stream, timeout) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'print() lth: ' + (stream ? stream.length : '0') + ', ' + timeout)
      this.socket.sendCommand(this.deviceId, 'print', stream, timeout)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'print() exception: ' + e)
    }
  }

  /**
   *  Print a data stream
   *  @param {String} stream Datastream in base 64 format
   *  @param {int} timeout Timeout to wait in msec.
   */
  printBase64(stream, timeout) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'printBase64(): ' + (stream ? stream.length : '0') + ', ' + timeout)
      this.socket.sendCommand(this.deviceId, 'print', stream, true, timeout)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'printBase64() exception: ' + e)
    }
  }

  /**
   *  Print an array of data streams
   *  @param {String[]} arrayStream array of Datastreams in base 64 format
   *  @param {int} timeout Timeout to wait in msec.
   */
  printArray(arrayStream, timeout) {
    //convert the javascript array and encapsulate it
    try {
      this.logMessage(
        TraceLevels.LOG_TRACE,
        'printArray() lth: ' + (arrayStream ? arrayStream.length : '0') + ', ' + timeout
      )
      this.socket.sendCommand(this.deviceId, 'printArray', arrayStream, false, timeout)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'printArray() exception: ' + e)
    }
  }

  /**
   *  Print an array of data streams in base 64 format
   *  @param {String[]} arrayStream array of Datastreams in base 64 format
   *  @param {int} timeout Timeout to wait in msec.
   */
  printBase64Array(arrayStream, timeout) {
    //convert the javascript array and encapsulate it
    try {
      this.logMessage(
        TraceLevels.LOG_TRACE,
        'printBase64Array() lth: ' + (arrayStream ? arrayStream.length : '0') + ', ' + timeout
      )
      this.socket.sendCommand(this.deviceId, 'printArray', arrayStream, true, timeout)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'printBase64Array() exception: ' + e)
    }
  }
}
