import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { AcuantStatus } from 'constants/Constants'

export default class AcuantClient {
  constructor(url) {
    this.connectionURL = url
    this.websocket = null
    this.connectionStatus = false
    this.responseObj = {}
    this.reader = new FileReader()
    this.blobQueue = []
    this.blobReading = false
    this._handler = null
    this._closeCallback = null
  }

  getStatus = () => {
    return this.connectionStatus
  }

  getSocket = () => {
    return this.websocket
  }

  setMessageHandler = (handler) => {
    if (this.websocket) {
      this._handler = handler
      this.websocket.onmessage = this.messageWrapper(handler)
      this.websocket.onclose = this.closeWrapper(handler)
    } else {
      appLog(TraceLevels.LOG_TRACE, `socket connection not found: set message handler fail`)
    }
  }

  filter = (string) => {
    // apisData structure:
    // {
    //   faceImage: responseObj.faceImage,
    //   lastName: responseObj.lastName,
    //   firstName: responseObj.firstName,
    //   dateOfBirth: responseObj.dateOfBirth,
    //   nationality: responseObj.nationality,
    //   gender: responseObj.gender,
    //   documentNo: responseObj.documentNo,
    //   issuingCountry: responseObj.issuingCountry,
    //   issuingState : responseObj.issuingState,
    //   expiryDate: responseObj.expiryDate,
    //   issuingCountryName: responseObj.issuingCountryName,
    //   nationalityName: responseObj.nationalityName,
    //   documentType: responseObj.documentType
    // }
    const response = string.split(':').map((ele) => {
      return ele.trim()
    })
    let newResponse = {}
    switch (response[0]) {
      case 'Face Image':
        const image = response[1] !== 'null' ? response[1] : null
        newResponse = { faceImage: image }
        break
      case 'Surname':
        newResponse = { lastName: response[1] }
        break
      case 'First Name':
        newResponse = { firstName: response[1] }
        break
      case 'Birth Date':
        if (response[1].split(' ').length >= 2) {
          newResponse = { dateOfBirth: response[1].split(' ')[0] }
        } else {
          newResponse = { dateOfBirth: response[1] }
        }
        break
      case 'Sex':
        if (response[1] === 'M') {
          newResponse = { gender: 'MALE' }
        } else if (response[1] === 'F') {
          newResponse = { gender: 'FEMALE' }
        } else {
          newResponse = { gender: 'OTHER' }
        }
        break
      case 'Document Number':
        newResponse = { documentNo: response[1] }
        break
      case 'Country Code':
        newResponse = { issuingCountry: response[1] }
        break
      case 'Issuing State Name':
        newResponse = { issuingState: response[1] }
        break
      case 'Expiration Date':
        if (response[1].split(' ').length >= 2) {
          newResponse = { expiryDate: response[1].split(' ')[0] }
        } else {
          newResponse = { expiryDate: response[1] }
        }

        break
      // case 'Document Type':
      //   newResponse = { documentType: response[1] }
      //   break
      case 'Document Class Name':
        newResponse = { documentType: response[1] }
        break
      case 'Authentication Result':
        newResponse = { authenticationResult: response[1] }
        break
      default:
        // nationality
        // issuingCountryName
        // nationalityName
        newResponse = {}
        break
    }
    this.responseObj = { ...this.responseObj, ...newResponse }
  }

  readBlobAsText = (blob) => {
    return new Promise((resolve, reject) => {
      this.reader.onload = () => {
        this.blobReading = false
        resolve(this.reader.result)
      }
      this.blobReading = true
      this.reader.readAsText(blob)
    })
  }

  readBlobQueue = async () => {
    if (this.blobQueue.length === 0) return

    let blob = this.blobQueue.shift()
    try {
      const text = await this.readBlobAsText(blob)
      let msg = decodeURIComponent(blob)
      appLog(TraceLevels.LOG_EXT_TRACE, 'messageWrapper acuantClient message handler, response:' + text)
      if (text !== 'EOM') {
        if (msg === '' || text === 'Exception') {
          this.responseObj = {}
        } else if (text === 'Please flip the document in the scanner') {
          this.responseObj = {
            authenticationResult: AcuantStatus.OPPOSITE
          }
        } else if (text.indexOf('\n') >= 0) {
          text.split('\n').forEach((string) => {
            this.filter(string.trim())
          })
        } else {
          this.filter(text)
        }
      } else {
        if (this.responseObj) {
          this._handler(this.responseObj)
          // if the authenticationResult response is "oppsite", only send the send the second side image, reuse the original message handler
          if (this.responseObj.authenticationResult !== AcuantStatus.OPPOSITE) {
            this.clearMessageHandler()
          }
          this.clearResponseObj()
          this.blobQueue = []
        } else {
          this.close()
          this.clearMessageHandler()
        }
      }
      await this.readBlobQueue()
    } catch (e) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'messageWrapper acuantClient fail, message catch error:' + e)
      this.responseObj = null
      this.close()
      this.clearMessageHandler()
    }
  }

  messageWrapper = (handler) => {
    return async (event) => {
      const blob = event.data
      this.blobQueue.push(blob)
      if (this.blobReading === false) {
        await this.readBlobQueue()
      }
    }
  }

  // messageWrapper = handler => {
  //   return async event => {
  //     let msg = decodeURIComponent(event.data)
  //     const reader = new FileReader()
  //     reader.onload = () => {
  //       const text = reader.result
  //       // const text = await event.data.text()
  //       appLog(TraceLevels.LOG_EXT_TRACE, 'messageWrapper acuantClient message handler, response:' + text)

  //       if (text !== 'EOM') {
  //         if (msg === '' || text === 'Exception') {
  //           this.responseObj = null
  //         } else if (text === 'Please flip the document in the scanner') {
  //           this.responseObj = {
  //             authenticationResult: AcuantStatus.OPPOSITE
  //           }
  //         } else if (text.indexOf('\n') >= 0) {
  //           text.split('\n').forEach(string => {
  //             this.filter(string.trim())
  //           })
  //         } else {
  //           this.filter(text)
  //         }
  //       } else {
  //         this.close()
  //         this.clearMessageHandler()
  //       }
  //     }
  //     reader.readAsText(event.data)
  //   }
  // }

  closeWrapper = (handler) => {
    return async (event) => {
      this.connectionStatus = false
      this.websocket = null
      // handler(this.responseObj)
      this.clearResponseObj()
      this.blobQueue = []
      if (this._closeCallback) {
        this._closeCallback()
        this._closeCallback = null
      }
      appLog(TraceLevels.LOG_TRACE, `socket connection close:  ${JSON.stringify(event)}`)
    }
  }

  clearMessageHandler = () => {
    if (this.websocket) {
      this.websocket.onmessage = this.messageCallback
      this.websocket.onclose = this.closeCallback
      this._handler = null
    } else {
      appLog(TraceLevels.LOG_TRACE, `socket connection not found: clear message handler fail`)
    }
  }

  clearResponseObj = () => {
    this.responseObj = {}
  }

  open = ({ onopen = null, onclose = null, onerror = null }) => {
    if (this.websocket) {
      appLog(TraceLevels.LOG_TRACE, `open socket connection fail: previous connection not yet clear`)
    } else {
      const socket = new WebSocket(this.connectionURL)
      socket.onopen = onopen ? onopen : this.openCallback

      socket.onmessage = this.messageCallback

      socket.onclose = onclose ? onclose : this.closeCallback

      socket.onerror = onerror ? onerror : this.errorCallback

      this.websocket = socket
    }
  }

  send = (data) => {
    if (this.websocket) {
      console.log('send method')
      appLog(TraceLevels.LOG_TRACE, `acuant socket data sent`)
      this.websocket.send(data)
    } else {
      appLog(TraceLevels.LOG_TRACE, `socket connection not found: data not send`)
    }
  }

  close = (callback) => {
    if (this.websocket) {
      console.log('close method')
      this.websocket.close()
      if (callback) {
        this._closeCallback = callback
      } else {
        this._closeCallback = null
      }
    } else {
      appLog(TraceLevels.LOG_TRACE, `socket connection not found: close socket connection fail`)
    }
  }

  messageCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, `default socket message handler: ${JSON.stringify(event)}`)
  }

  openCallback = (event) => {
    this.connectionStatus = true
    appLog(TraceLevels.LOG_TRACE, `socket connection open:  ${JSON.stringify(event)}`)
  }

  closeCallback = (event) => {
    this.connectionStatus = false
    this.websocket = null
    appLog(TraceLevels.LOG_TRACE, `socket connection close:  ${JSON.stringify(event)}`)
  }

  errorCallback = (error) => {
    this.connectionStatus = false
    this.websocket = null
    appLog(TraceLevels.LOG_TRACE, `socket connection error:  ${JSON.stringify(error)}`)
  }
}
