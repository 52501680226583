import {
    store,
    playSound,
    history,
    getBuildAccessibility,
    getAcuantClient,
    getDeviceManager,
    getSummaryStore
  } from 'main'
  import { TraceLevels, deviceIds } from 'embross-device-manager'
  import { ErrCodes } from 'constants/Errors'
  import { updatePaxValidationData } from 'actions/paxValidationActions'
  import { checkTravelDocAction, MEDIATYPES, PASSENGER_VALIDATION_STATUS, AcuantStatus } from 'constants/Constants'
  import { updateLocalData } from 'actions/localActions'
  import { appLog, logEvent } from 'utils/Logger'
  import { sendPhotoDetail } from 'actions/etsTransactions/sendPhotoDetail'
  import { checkTravelDoc } from 'custom/actions/checkTravelDoc'
  import { goToLocalGenericError, isEmpty, stringMatch, docTypeCheck, replacer } from 'utils/helper'
  import { genericCommand } from 'actions/etsTransactions/genericCommand'
  import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
  
  const acuantDocCheckHandler = (
    acuantClient,
    summaryStore,
    validatePaxResult,
    currentPassenger,
    retryCount,
    secondImage,
    mediaData,
    scanDocumentType
  ) => {
    return (responseObj) => {
      appLog(TraceLevels.LOG_EXT_TRACE, 'acuantClient message handler, response:' + JSON.stringify(responseObj, replacer))
      if (responseObj && responseObj.faceImage) {
        store.dispatch(updatePaxValidationData('updateFaceImage', responseObj.faceImage))
        store.dispatch(updatePaxValidationData('updateIsAccuantImage', true))
      }
  
      if (responseObj) {
        summaryStore.updateDocScan(
          responseObj.documentType,
          responseObj.issuingCountry,
          responseObj.issuingState,
          responseObj.authenticationResult === AcuantStatus.PASSED
        )
      }
  
      if (
        isEmpty(responseObj) ||
        responseObj.authenticationResult === AcuantStatus.FAILED ||
        responseObj.authenticationResult === AcuantStatus.ATTENTION ||
        responseObj.authenticationResult === AcuantStatus.UNKNOWN
      ) {
        if (responseObj && responseObj?.authenticationResult) {
          logEvents(EventFlows.Acuant, EventFunctions.AcuantCheck, `Fail, reason: ${responseObj.authenticationResult}`)
        } else {
          logEvents(EventFlows.Acuant, EventFunctions.AcuantCheck, 'Fail, unknown')
        }
        if (scanDocumentType) {
          logEvents(EventFlows.Acuant, EventFunctions.AcuantFailDoc, `Document type: ${scanDocumentType}`)
        }
  
        if (
          secondImage === null &&
          // docTypeCheck(responseObj.documentType) !== MEDIATYPES.PASSPORT &&
          scanDocumentType !== MEDIATYPES.PASSPORT &&
          !config.acuantSingleScan
        ) {
          history.push('ScanOppositeDoc')
        } else {
          // send bio info for fail acuant
          sendPhotoDetail(
            currentPassenger.ordinal,
            null,
            null,
            null,
            true,
            responseObj !== null ? docTypeCheck(responseObj.documentType) : docTypeCheck(null)
          )
          //
          acuantClient.clearMessageHandler()
          store.dispatch(updatePaxValidationData('updateDocRetryStauts', true))
          logEvent('DocCheckAccuantResult,' + responseObj?.authenticationResult)
          summaryStore.updateDocScanError('ACCUANT_AUTH_FAIL')
          if (retryCount + 1 === config.scanDocRetryCount) {
            // max retry hit, go to agent
            logEvent('DocCheckScan,reach max retry')
            const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
            faceTracking.disable()
            faceTracking.OnDeviceEvent = null
            let newValidateResults = []
            let updatedItem = {}
            validatePaxResult.forEach((item) => {
              if (item.ordinal === currentPassenger.ordinal) {
                updatedItem = item
                updatedItem.passportScanned = false
                updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
                updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
                updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
                updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED
  
                newValidateResults.push(updatedItem)
              } else {
                newValidateResults.push(item)
              }
            })
  
            const updatePaxStatus = {
              ordinal: currentPassenger.ordinal,
              docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
              faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
              privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
            }
  
            summaryStore.endDocScan(true)
            store.dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
            store.dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
            store.dispatch(updatePaxValidationData('initialPaxData'))
            store.dispatch(updateLocalData('updateDeviceAttempts', 0))
          } else {
            store.dispatch(updateLocalData('updateDeviceAttempts', retryCount + 1))
            logEvent('DocCheckScan,Retry ' + (retryCount + 1))
            goToLocalGenericError(
              'checkTravelDoc',
              ErrCodes.ERR_PASSPORT_INVALID_RETRY,
              'Error_PassportRetry',
              'ScanDocuments',
              null,
              {
                log: false
              }
            )
          }
        }
        acuantClient.close()
      } else if (responseObj.authenticationResult === AcuantStatus.PASSED) {
        acuantClient.clearMessageHandler()
  
        logEvents(EventFlows.Acuant, EventFunctions.AcuantCheck, 'Pass')
        logEvents(EventFlows.Acuant, EventFunctions.AcuantDocType, responseObj.documentType)
        logEvents(
          EventFlows.Acuant,
          EventFunctions.AcuantDocInfo,
          `${responseObj.issuingCountry} ${responseObj.issuingState}`
        )
  
        const acuantDocType = docTypeCheck(responseObj.documentType)
        logEvent('DocCheckAccuantDocType,' + acuantDocType)
        logEvent('DocCheckAccuantResult,Pass')
        store.dispatch(updatePaxValidationData('updateDocType', acuantDocType))
        if (scanDocumentType === MEDIATYPES.PASSPORT && acuantDocType === MEDIATYPES.PASSPORT && mediaData) {
          store.dispatch(
            checkTravelDoc(mediaData, null, checkTravelDocAction.CHECK_DOCINFO_ONLY, config.validateDocument, false)
          )
        } else {
          // get mediaData from acuant response
          delete responseObj.authenticationResult
          delete responseObj.issuingState
          delete responseObj.faceImage
          const formatResponse = { ...responseObj, documentType: acuantDocType }
          store.dispatch(
            checkTravelDoc(
              null,
              formatResponse,
              checkTravelDocAction.CHECK_DOCINFO_ONLY,
              config.validateDocument,
              secondImage === null && !config.acuantSingleScan
            )
          )
        }
        acuantClient.close()
      } else if (responseObj.authenticationResult === AcuantStatus.OPPOSITE) {
        appLog(TraceLevels.LOG_EXT_TRACE, 'acuantClient sent seconed image')
        if (secondImage !== null) {
          acuantClient.send(JSON.stringify(secondImage))
        } else {
          const closeCallback = () => {
            history.push('ScanOppositeDoc')
          }
          acuantClient.close(closeCallback)
        }
      }
    }
  }
  
  export const documentCheck = (firstImage, secondImage, mediaData) => {
    const acuantClient = getAcuantClient()
    const summaryStore = getSummaryStore()
    const currentPassenger = store.getState().paxValidation.currentPassenger
    const validatePaxResult = store.getState().localData.validatePaxResult
    const retryCount = store.getState().localData.errorDeviceAttempts
    const docRetryStatus = store.getState().paxValidation.docRetryStatus
    const scanDocumentType = store.getState().paxValidation.scanDocumentType
  
    const messageHandler = acuantDocCheckHandler(
      acuantClient,
      summaryStore,
      validatePaxResult,
      currentPassenger,
      retryCount,
      secondImage,
      mediaData,
      scanDocumentType
    )
    const EnableDuplex = secondImage !== null && !config.acuantSingleScan ? 'True' : 'False'
  
    acuantClient.setMessageHandler(messageHandler)
  
    history.push({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitDocCheck' } })
    appLog(TraceLevels.LOG_EXT_TRACE, 'acuantClient sent first image')
    acuantClient.send(JSON.stringify({ ...firstImage, EnableDuplex }))
  }
  