import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { PageContent, PageSubContent, PageText } from 'components/styledComponents'
import { populateItineraryInfo, isEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import useMedia from 'hooks/useMedia'

const BoardingInfo = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const { formatMessage } = intl
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const [itineraryData, setItineraryData] = useState(null)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    if (itineraryInfo == null || itineraryInfo == '') {
      appLog(TraceLevels.LOG_EXT_TRACE, 'No boading info')
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'Boading info AVAILABLE !!!')
      let itineraryData = populateItineraryInfo(itineraryInfo)
      if (itineraryData !== null) {
        setItineraryData(itineraryData)
      }
    }
  }, [itineraryInfo])

  const boadingTime = itineraryInfo ? itineraryInfo.outboundFlights[0].departureSchedule.boardTime : ''

  return (
    <PageContent
      flexFlow={'row'}
      justifyContent={'space-around'}
      height={themeContext.Itinerary.containerHeight[ratioKey]}
      padding={themeContext.Itinerary.containerPadding[ratioKey]}
    >
      <PageSubContent justifyContent={'space-between'} height="100%" padding={'0'}>
        <PageText
          fontSize={themeContext.Itinerary.bagWeightFontSize[ratioKey]}
          color={themeContext.Itinerary.primaryFontColor}
        >
          {itineraryData ? itineraryData.gate : '-'}
        </PageText>
        <PageText
          fontSize={themeContext.Itinerary.bagWeightTitleFontSize[ratioKey]}
          color={themeContext.Itinerary.secondaryFontColor}
        >
          {formatMessage(messages.GateTitle)}
        </PageText>
      </PageSubContent>

      <PageSubContent justifyContent={'space-between'} height="100%" padding={'0'}>
        <PageText
          fontSize={themeContext.Itinerary.bagWeightFontSize[ratioKey]}
          color={themeContext.Itinerary.primaryFontColor}
        >
          {itineraryData ? boadingTime : '-'}
        </PageText>
        <PageText
          fontSize={themeContext.Itinerary.bagWeightTitleFontSize[ratioKey]}
          color={themeContext.Itinerary.secondaryFontColor}
        >
          {formatMessage(messages.BoardingTimeTitle)}
        </PageText>
      </PageSubContent>
    </PageContent>
  )
}

export default BoardingInfo
