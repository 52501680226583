import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Bagtag Printer listener.
 *  @extends {Listener}
 */
export default class BagtagPrinterListener extends Listener
{
  
  /**
   *  Default bagtagPresent callback.  Called when the printer detects the presence of a bagtag in the coupon exit tray
   */
  bagtagPresent()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterListener.bagtagPresentMethod() called')
    this.setEvent('bagtagPresent', null)
  }

  /**
   *  Default bagtagRemoved callback.  Called when the printer detects the absence of a bagtag in the coupon exit tray
   */
  bagtagRemoved()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterListener.bagtagRemovedMethod() called')
    this.setEvent('bagtagRemoved', null)
  }

  /**
   *  Default bagtagPrinted callback.  Called every time the printer finishes printing a print stream
   *   @param {String} response - array[index, total, stream]
   *   <ul><li>index of the bagtag just printed</li>
   *   <li>total bagtags sent to the printer</li>
   *   <li>stream sent to the printer</li>
   *   </ul>
   */
  bagtagPrinted(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.bagtagPrinted() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('bagtagPrinted', res)
  }

  /**
   *  Default bagtagFailed callback.  Called every time the printer fails printing a print stream
   *   @param {String} response - array[index, total, stream]
   *   <ul><li>index of the bagtag which failed</li>
   *   <li>total bagtags sent to the printer</li>
   *   <li>stream sent to the printer</li>
   *   </ul>
   */
  bagtagFailed(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.bagtagFailedMethod() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('bagtagFailed', res)
  }

  /**
   *  Default bagtagPrintingComplete callback.  Called when the printer finishes printing a print stream
   *  Typically called only if the print command supports a vector of print streams to be printed
   */
  bagtagPrintingComplete()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.bagtagPrintingCompleteMethod() called')
    this.setEvent('bagtagPrintingComplete', null)
  }

  /**
   *  Default pectabLoaded callback.  Called every time the printer finishes loading a pectab stream
   *   @param {String} response - array[index, total]
   *   <ul><li>index of the pectab just loaded</li>
   *   <li>total pectabs sent to the printer</li>
   *   </ul>
   */
  pectabLoaded(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.pectabLoaded() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('pectabLoaded', res)
  }

  /**
   *  Default pectabFailed callback.  Called every time the printer fails loading a pectab stream
   *   @param {String} response - array[index, total]
   *   <ul><li>index of the pectab which failed</li>
   *   <li>total pectabs sent to the printer</li>
   *   </ul>
   */
  pectabFailed(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.pectabFailed() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('pectabFailed', res)
  }

  /**
   *  Default pectabLoadingComplete callback.  Called when the printer finishes loading a set of pectab streams
   *  Typically called only if the print command supports a vector of pectab streams to be loaded
   */
  pectabLoadingComplete()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.pectabLoadingComplete() called')
    this.setEvent('pectabLoadingComplete', null)
  }

  /**
   *  Default offer callback.
   *  @param {int} rc return code from offer: 0 - media absent, 1 - media present
   */
  offer(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterPrinterListener.offer() called')
    this.setEvent('offer', rc)
    if (rc === '104') { //media absent
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterListener.offer() - generate bagtagRemoved event')
      this.setEvent('bagtagRemoved', null)
    }
  }
  
  /**
   *  Default isPECTABLoadedInPrinter callback.
   *  @param {string} response '1' when PECTABs loaded in printer
   */
  isPECTABLoadedInPrinter(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.isPECTABLoadedInPrinter(): ' + response)
    this.setEvent('isPECTABLoadedInPrinter', response === '1')
  }
}
