import { deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import PassportReaderListener from 'listeners/passportReaderListener'

/**
 *  Device representing a Passport Reader
 *  @extends {Device}
 */
export default class PassportReader extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Passport Reader'
    /**
     * Device id - deviceIds.PASSPORT_READER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.PASSPORT_READER
    this.setListener(new PassportReaderListener())
  }

  /**
   *  Asynch call to check if reader is DIP reader
   */
  isDIP() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isDIP().')
      this.socket.sendRequest(this.deviceId, 'isDIP')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isDIP() exception: ' + e)
    }
  }
  /**
   *  Asynch call to check if reader is Swipe reader
   */
  isSwipe() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isSwipe().')
      this.socket.sendRequest(this.deviceId, 'isSwipe')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isSwipe() exception: ' + e)
    }
  }

  /**
   *  Asynch call to check if reader is Motorized reader
   */
  isMotorized() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isMotorized().')
      this.socket.sendRequest(this.deviceId, 'isMotorized')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isMotorized() exception: ' + e)
    }
  }

  /**
   *  Asynch call to check if passport is in the reader
   */
  isPassportInReader() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isPassportInReader().')
      this.socket.sendRequest(this.deviceId, 'isPassportInReader')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPassportInReader() exception: ' + e)
    }
  }
  /**
   *  Asynch call to check if passport is partially inserted
   */
  isPassportPartiallyInserted() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isPassportPartiallyInserted().')
      this.socket.sendRequest(this.deviceId, 'isPassportPartiallyInserted')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPassportPartiallyInserted() exception: ' + e)
    }
  }
  /**
   *  Asynch call to read
   */
  read() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'read().')
      this.socket.sendRequest(this.deviceId, 'read')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'read() exception: ' + e)
    }
  }

  /**
   *  Asynch call to setup the rfid reader
   *  @param {String[]} rfidCountryList
   *  @param {Number} pax
   */
  setup(rfidCountryList, pax) {
    try {
      if (pax === undefined) {
        this.logMessage(TraceLevels.LOG_TRACE, 'setup(): called with: ' + rfidCountryList)
        this.socket.sendRequest(this.deviceId, 'setup', rfidCountryList)
      } else {
        this.logMessage(TraceLevels.LOG_TRACE, 'setup(): called with: ' + rfidCountryList + ' pax: ' + pax)
        this.socket.sendRequest(this.deviceId, 'setup', rfidCountryList, pax)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setup() exception: ' + e)
    }
  }

  /**
   *  Asynch call to set the pax is for rfid reader
   *  @param {Number} paxId pax id
   */
  setPaxId(paxId) {
    try {
      if (paxId !== undefined) {
        this.logMessage(TraceLevels.LOG_TRACE, 'setPaxId(): called with: ' + paxId)
        this.socket.sendCommand(this.deviceId, 'setPaxId', paxId)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setPaxId() exception: ' + e)
    }
  }

  /**
   *  Asynch call to parse the mrz
   *  @param {String} mrz to parse
   */
  parse(mrz) {
    try {
      if (mrz !== undefined) {
        this.logMessage(TraceLevels.LOG_TRACE, 'parse(): called with: ' + (mrz ? mrz.substring(0, 4) : ''))
        this.socket.sendRequest(this.deviceId, 'parse', mrz)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'parse() exception: ' + e)
    }
  }

  /**
   * Get version of the passport reader.
   */
  getVersion() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion()')
      this.socket.sendRequest(this.deviceId, 'getVersion')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getVersion() exception: ' + e)
    }
  }
}
