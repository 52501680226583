import { identifyService } from 'actions/etsTransactions/identifyService'
import { updateLocalData } from 'actions/localActions'
import { Animation } from 'components'
import { PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { FALSE, FunctionCodes, ServiceNames, TransitionCodes } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { BagtagPrinterOnEvent } from 'devices/callbacks'
import { deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import useStateRef from 'hooks/useStateRef'
import { getBuildAccessibility, getDeviceManager, getTimeoutMgr } from 'main'
import * as React from 'react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { delay, getScreenId, goToLocalError, navigate, populateItineraryInfo } from 'utils/helper'
import { logInfo } from 'utils/Logger'
import { Footer } from '../footer'

const isQuitRequired = false
const isLangRequired = false

const PrintBagtag = (props) => {
  const timeoutMgr = getTimeoutMgr()
  const dispatch = useDispatch()
  const intl = useIntl()
  const paxDocuments = useSelector((state) => state.responses.paxDocuments)
  const bagtagResources = useSelector((state) => state.responses.bagtagResources)
  const transition = useSelector((state) => state.sessions.transition)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const bagtagPrinter = getDeviceManager().getDevice(deviceIds.BAGTAG_PRINTER)
  const [currentBagtag, setCurrentBagtag, currentBagtagRef] = useStateRef(0)
  const [enableConfirm, setEnableConfirm] = useState(false)
  const printedRef = useRef(false)
  const bagtagsRef = useRef([])
  const totalBagtag = paxDocuments.length
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const itineraryData = useMemo(() => populateItineraryInfo(itineraryInfo), [itineraryInfo])

  const printNext = () => {
    if (currentBagtagRef.current < totalBagtag) {
      logInfo('Bagtags: printing ' + (currentBagtagRef.current + 1) + ' of ' + totalBagtag)
      bagtagPrinter.print(bagtagsRef.current[currentBagtagRef.current], config.timeoutPrintCmd)
    } else {
      logInfo('Bagtags: print completed')
      // delay on leaving the page when bagtag finish printing
      setEnableConfirm(true)
    }
  }

  const bagtagPrinterCallback = (event) => {
    let err = {}
    logInfo('bagtagPrinterCallback ... event : ' + JSON.stringify(event))
    if (timeoutMgr) {
      timeoutMgr.resetTimer()
    }
    switch (event.key) {
      case 'pectabLoadingComplete':
        logInfo('totalBagtag: ' + totalBagtag)
        if (totalBagtag > 0) {
          logInfo('Print first bagtag.')
          bagtagPrinter.print(bagtagsRef.current[currentBagtagRef.current], config.timeoutPrintCmd)
        }
        break
      case 'bagtagPrinted':
        logInfo('event: bagtagPrinted')
        bagtagPrinter.offer(config.timeoutTakeBagtag)
        printedRef.current = true
        logEvents(EventFlows.BagDrop, EventFunctions.BTPrinted, bagtagsRef.current[currentBagtagRef.current - 1])
        setCurrentBagtag((preBagtag) => preBagtag + 1)
        break
      case 'offer':
        logInfo('event: offer', event)
        // 103 - when bagtag not taken after timeout 30s
        if ((event.value == 0 || event.value == 104 || event.value == 103) && printedRef.current) {
          printedRef.current = false
          delay(config.offerDelay).then(printNext())
        }
        break
      case 'commandTimeout':
        if (event.value === 'offer') {
          printedRef.current = false
          delay(config.offerDelay).then(printNext())
        }
      case 'bagtagPrintingComplete':
        logInfo('Event:  bagtagPrintingComplete ' + event)
        //
        //printNext()
        break
      case 'bagtagFailed':
        logEvents(EventFlows.BagDrop, EventFunctions.BTPrinted, 'bagtagFailed')
        logInfo('bagtag Failed. ' + JSON.stringify(err))
        goToLocalError('PrintBagtag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        break
      case 'pectabFailed':
        // err = {
        //   message: 'bagtag pectab Failed',
        //   detail: ErrCodes.BAGTAG_PRINT_ERROR,
        //   source: 'PrintBagtag.js'
        // }
        logInfo('loading bagtag pectab Failed. ' + JSON.stringify(err))
        goToLocalError('PrintBagtag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        break
      case 'pectabLoaded':
        logInfo('Event:  pectabLoaded ' + event.value.toString())
        break
      case 'statusChange':
        logInfo('Event:  statusChange ' + event)
        if ((event.value[0] > 300 && event.value[0] < 310) || event.value[0] === 108) {
          goToLocalError('PrintBagtag', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
        }
        break
      default:
    }
  }

  useEffect(() => {
    logInfo('>> (PrintBagtag.js) componentDidMount() - loadPECTABArray')
    if (paxDocuments) {
      bagtagsRef.current = paxDocuments
    }
    if (bagtagPrinter) {
      logInfo('set printer callback')
      bagtagPrinter.OnDeviceEvent = bagtagPrinterCallback
      bagtagPrinter.loadPECTABArray(bagtagResources, FALSE)
    } else {
      logInfo('bagtag printer is not available')
    }

    //  set style for size
    return () => {
      if (bagtagPrinter) {
        bagtagPrinter.OnDeviceEvent = BagtagPrinterOnEvent
      }
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    logInfo('(PrintBagtag.js): handleActions() ... ' + e.currentTarget.id)
    // TEST CODE
    if (e.currentTarget.id === 'testButtonPrinted') {
      //if (currentBagtagRef.current === 0) {
      let ev = {
        key: 'pectabLoadingComplete',
        value: 0
      }
      bagtagPrinterCallback(ev)
      //}
      setTimeout(() => {
        let ev = {
          key: 'bagtagPrinted',
          value: 0
        }
        bagtagPrinterCallback(ev)
        setTimeout(() => {
          let ev = {
            key: 'offer', //bagtag taken
            value: 104
          }
          bagtagPrinterCallback(ev)
        }, 500)
      }, 1000)
    } else if (e.currentTarget.id === 'testButtonError') {
      let ev = {
        // key: 'statusChange', //bagtagFailed or bagtagFailed
        // value: [203, true]
        key: 'bagtagFailed', //bagtagFailed or bagtagFailed
        value: null
      }
      return bagtagPrinterCallback(ev)
    }
    // end OF TEST CODE
    if (e.currentTarget.id === 'confirmBtn') {
      dispatch(updateLocalData('appFlow', 2))
      switch (transition) {
        case TransitionCodes.PRINT_DOCUMENT:
          dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.PRINT_BAGTAG))
          break
        case TransitionCodes.HEAVY_TAG:
          navigate('PutBagOnBelt', 2)
          break
        default:
      }
    }
  }

  const testData = [
    {
      id: 'DESC-1',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'testButtonPrinted',
      text: 'Print',
      group: 0,
      handler: handleActions
    },
    {
      id: 'DESC-2',
      text: 'Error Cases:',
      group: 1
    },
    {
      id: 'testButtonError',
      text: 'print error',
      group: 1,
      handler: handleActions
    }
  ]
  const { formatMessage } = intl
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])

  const title = formatMessage(messages.PrintBagtagsTitle)
  const subtitle = formatMessage(messages.PrintBagtagsSubTitle, {
    passengerName: itineraryData?.displayName,
    currentBT: currentBagtag >= totalBagtag ? totalBagtag : currentBagtag + 1,
    totalBT: totalBagtag
  })

  const buttonDoneText = formatMessage(messages.buttonDone)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'PrintBagtag',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        isLangRequired,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [title, subtitle]
          }
        ]
      }
    }
    if (enableConfirm) {
      accDef.sequenceDef.sequence.push({ id: 'confirmBtn', text: buttonDoneText, buttonId: 'confirmBtn' })
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const header = <PageHeader alignItems="center">{title}</PageHeader>
  const textSection = (
    <>
      <PageTitle justifyContent={textAlign}>{title}</PageTitle>
      <PageSubTitle justifyContent={textAlign} height="200px">
        {subtitle}
      </PageSubTitle>
    </>
  )
  // const animationSize = useMedia(null, [420, 350, 420], 420)
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const animationPrintBagtag = isLandscape
    ? themeContext.AnimationLandscape.PrintBagtag
    : themeContext.AnimationPortrait.PrintBagtag
  const animationSection = (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${animationPrintBagtag}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )
  const footer = (
    <>
      <Footer
        isQuitRequired={isQuitRequired}
        isBackRequired={false}
        isSkipRequired={false}
        isLangRequired={isLangRequired}
        isConfirmRequired={enableConfirm}
        confirmBtnText={buttonDoneText}
        confirmAction={handleActions}
        confirmIcon={themeContext.ConfirmButton.doneIcon}
        testData={testData}
      />
    </>
  )
  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = {
    landscapeMode: '50%',
    portraitMode: '95%'
  }

  const { UIDisplay } = useUIBase(
    {
      topSection: textSection,
      bottomSection: animationSection,
      footer
    },
    {
      contentWidth: contentWidth,
      itineraryHeights: ['200px', '120px', '200px']
    }
  )

  return <>{UIDisplay}</>
}

PrintBagtag.propTypes = {}

export default PrintBagtag
