import { deviceIds, methods, TraceLevels } from 'constants/Constants'
import { getKeyByValue, removeArrayBrackets } from 'utils/helper'
//const ms = 25000
/**
 * The internal LinkSocket class used by {@link DeviceManager} class handles websocket communication with the server.
 */
export default class LinkSocket {
  /**
   * @param {String} url of websocket server
   * @param {number} port of websocket server
   * @param {function(event: Event)} callback
   * @param {function()} getMaxTraceLevel
   * @param {boolean} noWebsocket
   * @param {function()} dmLog
   * @protected
   */
  constructor(url, port, callback, getMaxTraceLevel, noWebsocket, dmLog) {
    /**@protected
     * @type {string}*/
    this.url = url
    /** @private */
    this.port = port
    /** @private */
    this.websocket = null
    /** @private */
    this.requestId = 0
    /** @private */
    this.callbacks = []
    /** @private */
    this.timer = []
    /** @private */
    this.isOpened = false
    /** @private */
    this.listeners = new Map()
    /** @private */
    this.listeners.set('default', this.defaultEventHandler)
    /** @private */
    this.autoReconnect = true
    /** @private */
    this.wsCallback = callback
    /** @private */
    this.keepAliveTimer = null
    /** @private */
    this.noWebsocket = noWebsocket
    /** @private */
    this.getMaxTraceLevel = getMaxTraceLevel
    /** @private */
    this.restarting = false
    /** @private */
    this.dmLog = dmLog
    /**@private
     * @type {number}*/
    this.defaultTimeout = 60000
  }
  /**
   * Add listener for a device
   * @param {function(event: Event)} listener
   */
  addListener(listener) {
    //this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LinkSocket::[' + listener.device.deviceId + '] listener:', listener)
    this.listeners.set(listener.device.deviceId, listener)
    //this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LinkSocket::listeners:', this.listeners)
  }
  /**
   * Remove listener for a device
   * @param {number} deviceId
   */
  removeListener(deviceId) {
    this.listeners.delete(deviceId)
  }
  /**
   * DEfault event handler
   */
  defaultEventHandler() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LinkSocket::default eventHandler is called.')
  }
  /**
   * Open socket
   */
  open() {
    /* if (location.protocol === 'https:') {
      this.websocket = new WebSocket('wss://' + this.url + ':' + this.port)
    } else {
      this.websocket = new WebSocket('ws://' + this.url + ':' + this.port)
    } */
    this.websocket = new WebSocket('ws://' + this.url + ':' + this.port)
    this.websocket.onmessage = this.socketOnMessage.bind(this)
    this.websocket.onclose = this.socketOnClose.bind(this)
    this.websocket.onopen = this.socketOnOpen.bind(this)
    this.websocket.onerror = this.socketOnError.bind(this)
  }
  /**
   * Called when websocket is connected
   * @param {Event} event
   */
  socketOnOpen(event) {
    //console.log('WebSocket is connected.')
    this.isOpened = true
    /*
    if (this.keepAliveTimer === null) {
      this.keepAliveTimer = setInterval(()=> {
        this.sendRequest(deviceIds.APPLICATION_MANAGER, 'getCurrentState')
      }, 30000)
    }
    */
    this.wsCallback(event)
  }

  /**
   * Called when message arrived from web socket
   * @param {Event} event
   */
  socketOnMessage(event) {
    this.logMessage(TraceLevels.LOG_TRACE, 'socket on message => Event.data:' + event.data, true) // 3rd parameter indicates that secure logging need to be used
    let data = ''
    try {
      data = JSON.parse(event.data)
    } catch (err) {
      if (!(typeof event.data === 'string' && event.data.indexOf('Hello') >= 0)) {
        this.logMessage(TraceLevels.LOG_ALERT, 'parse event fail, err: ' + err)
      }
      data = event.data
    }
    if (typeof data === 'string' && data.indexOf('Hello') >= 0) {
      this.logMessage(TraceLevels.LOG_SYSTEM, 'Message from server:[' + data + ']')
      this.isOpened = true
      //this.logMessage(TraceLevels.LOG_TRACE,'listeners', this.listeners.get(deviceIds.APPLICATION_MANAGER))
      this.listeners.get(deviceIds.APPLICATION_MANAGER)['socketOpened'](this.restarting)
      this.restarting = false
    } else if (typeof data === 'object' && 'corrId' in data && !!this.callbacks[data.corrId]) {
      let callback = this.callbacks[data.corrId]
      delete this.callbacks[data.corrId]
      clearTimeout(this.timer[data.corrId])
      delete this.timer[data.corrId]
      //this.logMessage(TraceLevels.LOG_EXT_TRACE, data.corrId + ' resolve called with: ' + data.response)
      this.logMessage(
        TraceLevels.LOG_SYSTEM,
        'Response corrId:' + data.corrId + ' comp: ' + data.comp + ' methodId: ' + data.methodId
      )
      callback[0](data.response)
      let methodName = null
      if (data.comp !== deviceIds.APPLICATION_MANAGER) {
        switch (data.methodId) {
          case methods.status:
            methodName = 'statusResponse'
            break
          default:
            methodName = getKeyByValue(methods, data.methodId)
        }
      } else {
        methodName = getKeyByValue(methods, data.methodId)
      }
      //console.log('data:', data, ', methodName:', methodName)
      this.listeners.get(data.comp)[methodName](data.response)
    } else if (typeof data === 'object' && 'event' in data) {
      //      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'event: ' + JSON.stringify(data))
      //      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'listener: ' + this.listeners.get(data.comp)[data.event])
      try {
        this.listeners.get(data.comp)[data.event](removeArrayBrackets(data.params))
      } catch (err) {
        this.logMessage(TraceLevels.LOG_ALERT, 'error in listener for: ' + data.event + ' err: ' + err)
      }
    } else this.logMessage(TraceLevels.LOG_EXT_TRACE, 'command response no need to handle (no corrId).')
  } //console.log(
  /**
   * Called when error occurred on websocket
   * @param {Event} event
   */
  socketOnError(event) {
    this.logMessage(TraceLevels.ALERT, 'ws error: ' + event)
    this.isOpened = false
    this.wsCallback(event)
  }
  /**
   * Called when websocket is closed
   * @param {Event} event
   */
  socketOnClose(event) {
    this.isOpened = false
    this.logMessage(TraceLevels.LOG_SYSTEM, 'socketOnClose: ' + event.code + ' ' + event.reason + ' ' + event.wasClean)
    /*
    if (this.keepAliveTimer !== null) {
      clearInterval(this.keepAliveTimer)
      this.keepAliveTimer = null
    }
    */
    // may check event.code e.g. message to long 1009 is OK to reconnect
    if (this.autoReconnect) {
      this.logMessage(TraceLevels.LOG_SYSTEM, 'auto reconnect ...')
      setTimeout(() => {
        this.logMessage(TraceLevels.LOG_SYSTEM, 'reconnect - open')
        this.restarting = true
        this.open()
      }, 500)
    }
    this.wsCallback(event)
  }
  /**
   * Websocket is closed
   */
  close() {
    this.isOpened = false
    this.autoReconnect = false
    this.websocket.close()
  }
  /**
   * get next request id (correlation id)
   */
  getRequestId() {
    return ++this.requestId
  }
  /**
   * Send message on websocket
   * @param {number} comp - component id {@link src/constants/Constants.js~deviceIds}
   * @param {string} method - method name
   * @param {boolean} isSync - when false then the correlation id will be generated so the response can be matched with the request; when true then response is not expected.
   * @param {...String} params - parameters for the method
   */
  callRemoteMethod(comp, method, isSync, ...params) {
    //this.logMessage(TraceLevels.LOG_EXT_TRACE,'callRemoteMethod: comp=[' + comp + '] method =[' + method + '] isSync =[' + isSync + '] paras:', ...params)
    if (arguments.length < 2) return ''
    if (!this.isOpened) {
      this.logMessage(TraceLevels.LOG_SYSTEM, 'websocket is not opened')
      /* it may not open in time to send message
      if (this.autoReconnect) {
        this.logMessage(TraceLevels.LOG_EXT_TRACE, 'reconnect ...')
        this.open()
      }
      */
    }
    if (arguments.length === 2) isSync = true
    let corrid = -1
    let deferred = null
    if (!isSync) {
      corrid = this.getRequestId()
      this.logMessage(
        TraceLevels.LOG_TRACE,
        'new corrid generated: ' + corrid + ' for method:' + method + ' comp:' + comp
      )
      deferred = new Promise((resolve, reject) => {
        this.callbacks[corrid] = [resolve, reject]
        this.timer[corrid] = setTimeout(
          (cid, cmp, methodName) => {
            this.logMessage(
              TraceLevels.LOG_TRACE,
              'Timeout function this.callbacks[corrid]: [' + cid + '] ' + cmp + ' ' + methodName
            )
            if (this.callbacks[cid]) {
              this.logMessage(TraceLevels.LOG_TRACE, 'Timeout after ' + this.defaultTimeout + ' ms')
              // reject(new Error('Timeout after '+ms+' ms')); // (A)
              this.callbacks[cid][0]('timeout')
              this.listeners.get(cmp)['commandTimeout'](methodName)
              delete this.callbacks[cid]
              delete this.timer[cid]
            }
          },
          this.defaultTimeout,
          corrid,
          comp,
          method
        )
      })
    }

    let msg = this.buildJSONString(comp, method, corrid, ...params)
    if (this.isOpened) {
      this.websocket.send(msg)
    }

    //this.logMessage(TraceLevels.LOG_EXT_TRACE,'Sending: ' + msg + ' to the server!')
    if (!isSync) return deferred
    else return corrid
  }

  /**
   * Send command to the device - asynch response maybe generated
   * @param {number} dev - device id {@link src/constants/Constants.js~deviceIds}
   * @param {string} method - method name
   * @param {...String} params - parameters for the method
   */
  sendCommand(dev, method, ...params) {
    if (this.noWebsocket === true) return true
    //this.logMessage(TraceLevels.LOG_EXT_TRACE,'sendCommand:', arguments)
    return this.callRemoteMethod(dev, method, true, ...params)
  }

  /**
   * Send request to the device - the response is expected
   * @param {number} dev - device id {@link src/constants/Constants.js~deviceIds}
   * @param {string} method - method name
   * @param {...String} params - parameters for the method
   */
  sendRequest(dev, method, ...params) {
    if (this.noWebsocket === true) return true
    //this.logMessage(TraceLevels.LOG_EXT_TRACE,'sendRequest:', arguments)
    return this.callRemoteMethod(dev, method, false, ...params)
  }

  /**
   * Build message to be send using websocket
   * @param {number} comp - device id {@link src/constants/Constants.js~deviceIds}
   * @param {string} method - method name
   * @param {number} corrid - correlation id (-1 when response id not expected)
   * @param {...String} params - parameters for the method
   */
  buildJSONString(comp, method, corrid, ...params) {
    //this.logMessage(TraceLevels.LOG_EXT_TRACE,'comp: ' + comp + ' method: ' + method + ' corrid:' + corrid + ' # of arguments:' + arguments.length)
    // add more validation : comp number in range of devices, method string
    // add handling parameters
    let msg = {
      comp: comp,
      method: method,
      corrId: corrid,
      params: [...params],
    }
    return JSON.stringify(msg)
  }

  /**
   * Log the debug message
   * @param {number} level - device id {@link src/constants/Constants.js~TraceLevels}
   * @param {string} msg - message to log
   * @param {boolean} secure - mask sensitive data in the message
   */
  logMessage(level, msg, secure) {
    if (secure) {
      let text = msg.substring(0, 72)
      if (
        text.indexOf('ticketPrinted') > -1 ||
        text.indexOf('ticketFailed') > -1 ||
        text.indexOf('bagtagPrinted') > -1 ||
        text.indexOf('bagtagFailed') > -1
      ) {
        this.dmLog(level, 'LS: ' + msg.substring(0, 85) + '... msg lth: ' + msg.length)
      } else if (text.indexOf('Read') > -1 || text.indexOf('pictureTaken') > -1) {
        this.dmLog(level, 'LS: ' + text + '... msg lth: ' + msg.length)
      } else {
        this.dmLog(level, 'LS: ' + msg)
      }
    } else {
      this.dmLog(level, 'LS: ' + msg)
    }
  }

  /**
   * Set default timeout
   * @param {number} to - timeout
   */
  setDefaultTimeout(to) {
    this.defaultTimeout = to
  }
}
