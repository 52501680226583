import { configureStore, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
//import { resetSession, setLocale } from 'store/session/sessionSlice'
//import rootReducer, { RootState } from './rootReducer'
import rootReducer from '../reducers/reducers'

const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

/* if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}
 */
/* export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export const resetStore = (resetLocal: boolean = true) => {
  store.dispatch(resetSession())
  if( resetLocal ) {
    store.dispatch(setLocale(config.defaultLanguage))
  }
} */
export default reduxStore
