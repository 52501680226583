//import { push } from 'react-router-redux'
import { store, getAltStore, history } from 'main'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData } from 'actions/localActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { replacer, isNotEmpty, navigate, calBaggageLimit } from 'utils/helper'
import { reasonToStatusCode, continueQuitProcess } from 'utils/appTransactions'
import { TYPE_KIOSK, ServiceNames, TransitionCodes, FunctionCodes, StatusCodes } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'
import { addBags } from 'actions/etsTransactions/addBags'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'

function buildGetBagDetails() {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
      },
      jsonExtend: null,
      currentClientTime: 0,
      asyncRequested: false,
      allPassengers: true,
      forTotalWeightLimit: false
    }
  )
}

export function getBagDetails() {
  appLog(TraceLevels.LOG_TRACE, 'getBagDetails')

  return (dispatch) => {
    navigate('pleaseWait')
    let request = buildGetBagDetails()
    appLog(TraceLevels.LOG_TRACE, 'getBagDetails request ... ' + JSON.stringify(request, replacer))
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('getBagDetails/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_TRACE, 'getBagDetails response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        // check if OOS event happened between request(COMPLETE) and response
        // if request was QUIT, TIMEOUT or EEROR then transition code should be OK no need to modify
        if (store.getState().sessions.OOS && statusCode === StatusCodes.COMPLETE) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          switch (json.transition) {
            case TransitionCodes.GET_SBD_RECEIPT:
              appLog(TraceLevels.LOG_EXT_TRACE, 'transition: GET_SBD_RECEIPT')
              store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
              break
            case TransitionCodes.QUESTION_FOR_NEXT_BAG:
              dispatch(updateLocalData('BagsEntryDetail', json.etsResponse.passengerBaggages))
              dispatch(updateLocalData('updatePooling', json.etsResponse.pooling))
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                '(bagUpdateSBD.js) transition: QUESTION_FOR_NEXT_BAG, ask passenger is next bag require'
              )
              navigate('NextBagQuestion', 8)
              break
            case TransitionCodes.GET_BAGTAG_PRINTSTREAM:
              appLog(TraceLevels.LOG_EXT_TRACE, 'dispatch print bagtag page.')
              dispatch(getPrintStream('BAG_TAG'))
              break
            case TransitionCodes.END:
              navigate('ClosingRemarks', 8)
              break
            case TransitionCodes.ADD_BAGGAGE:
              dispatch(updateLocalData('BagsEntryDetail', json.etsResponse.passengerBaggages))
              dispatch(updateLocalData('updatePooling', json.etsResponse.pooling))
              if (config.showBagEntry) {
                navigate('BagsEntry', 21)
              } else {
                const formatAddBag = (bagsDetail, bagType, addAmount) => {
                  return bagsDetail.map((pax) => {
                    const baggageUpdate = [
                      {
                        baggageType: bagType,
                        quantity: addAmount,
                        baggageUnit: 'PIECE'
                      }
                    ]
                    return {
                      paxOrdinal: pax.paxOrdinal,
                      baggageUpdate
                    }
                  })
                }
                const bags = formatAddBag(json.etsResponse.passengerBaggages, 'REGULAR', 1)
                dispatch(addBags(bags))
              }
              break
            case TransitionCodes.SBD_PROCESS:
              appLog(TraceLevels.LOG_EXT_TRACE, '(BagsEntryDetail.js) url: PutBagOnBelt')
              if (store.getState().localData.baggageLoadedStatus) {
                navigate('BagProcessing', 2)
              } else {
                navigate('PutBagOnBelt', 2)
              }
              break
            default:
              // dispatch transition
              dispatch(updateSessions('updateTransition', json.transition))
              dispatch(transitions(json.transition))
          }
        } else if (json.error) {
          goToFetchGenericError('getBagDetails', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'getBagDetails()', 'error')
      })
  }
}
