import { TraceLevels } from 'embross-device-manager'
import { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
// import { injectIntl, FormattedMessage } from 'react-intl'
import { addBags } from 'actions/etsTransactions/addBags'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { updateLocalData } from 'actions/localActions'
import { DynamicImage, PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { PRINT_DOC_TYPE } from 'constants/Constants'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility } from 'main'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { Footer } from '../footer'

const isQuitRequired = false

const NextBagQuestion = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const paxBagDetails = useSelector((state) => state.localData.BagsEntryDetail)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)

  useEffect(() => {
    // update application to indicate passenger has already answer the question of
    // continue bag process or not
    // (disable need more bag button on put bag on belt page)
    dispatch(updateLocalData('updateNextBagQuestion', true))
  }, [])

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${sbdModel}/next-bag.png`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )

  const title = intl.formatMessage(messages.NextBagQuestionTitle)
  const subtitle = intl.formatMessage(messages.NextBagQuestionSubTitle)
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'NextBagQuestion',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [title, subtitle]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonYes), buttonId: 'confirmBtn' },
          { id: 'backBtn', text: intl.formatMessage(messages.buttonNo), buttonId: 'backBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {title}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {subtitle}
      </PageSubTitle>
    </>
  )

  const handleActions = (e) => {
    switch (e.currentTarget.id) {
      case 'backBtn':
        appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, No button press')
        if (config.enablePrintReceipt) {
          dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
        } else {
          //nextTransition = 'CLOSING_REMARKS'
          navigate('ClosingRemarks', 8)
        }
        break
      case 'confirmBtn':
        appLog(TraceLevels.LOG_TRACE, 'ShowDisclaimer page, Yes button press')
        //dispatch(getBagDetails())
        if (config.showBagEntry) {
          navigate('BagsEntry', 21)
        } else {
          const formatAddBag = (bagsDetail, bagType, addAmount) => {
            return bagsDetail.map((pax) => {
              const baggageUpdate = [
                {
                  baggageType: bagType,
                  quantity: addAmount,
                  baggageUnit: 'PIECE'
                }
              ]
              return {
                paxOrdinal: pax.paxOrdinal,
                baggageUpdate
              }
            })
          }
          const bags = formatAddBag(paxBagDetails, 'REGULAR', 1)
          dispatch(addBags(bags))
        }
        break
      default:
        break
    }
  }

  // const header = <PageHeader>{intl.formatMessage(messages.TotalWeightTitle)}</PageHeader>
  const header = <PageHeader>NEXT BAG QUESTION</PageHeader>
  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      isBackRequired={true}
      backBtnText={intl.formatMessage(messages.buttonNo)}
      backAction={handleActions}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmBtnText={intl.formatMessage(messages.buttonYes)}
      confirmAction={handleActions}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default NextBagQuestion
