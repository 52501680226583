import React, { useContext, useEffect, useState } from 'react'
import { PageTitle, PageContent, PageSubContent, Spacer, MainHeader, DynamicImage } from 'components/styledComponents'
import { useIntl } from 'react-intl'
import { history } from 'main'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { populateItineraryInfo } from 'utils/helper'
import { getScreenId } from 'utils/helper'
import { useLocation, useRoutes } from 'react-router-dom'

const Header = ({ headerText, isShowHeader = false }) => {
  const kioskInfo = useSelector((state) => state.kioskInfo)
  const airportCode = kioskInfo.airportCode
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  const outboundFlightsIndex = itineraryInfo ? itineraryInfo.outboundFlights.length - 1 : ''
  const cityName = itineraryInfo ? itineraryInfo.outboundFlights[outboundFlightsIndex].arrivalSchedule.schedule.airport.name : ''
  const stateName = itineraryInfo ? itineraryInfo.outboundFlights[outboundFlightsIndex].arrivalSchedule.schedule.airport.airportName : ''

  const cityState = cityName && stateName ? `${cityName}, ${stateName}` : ''

  return (
    <MainHeader height={themeContext.Header.height[ratioKey]} padding={themeContext.Header.padding[ratioKey]}>
      <PageContent flexFlow="column" width="100%" height="100%">
        <PageSubContent flexFlow={'row'} justifyContent="space-between" width="100%" padding="0">
          <PageSubContent padding="0">
            <DynamicImage
              imageName={themeContext.AirportLogo.logo}
              height={themeContext.AirportLogo.height[ratioKey]}
              width={themeContext.AirportLogo.width[ratioKey]}
              backgroundPosition={'center right'}
            />
          </PageSubContent>
          <PageSubContent padding="0">
            <PageTitle
              fontSize={themeContext.Header.fontSize[ratioKey]}
              fontWeight={themeContext.Header.fontWeight}
              color={themeContext.Header.color}
            >
              {screenId === 'welcome' || screenId === 'inactive' ? '' :  cityState}
            </PageTitle>
          </PageSubContent>
        </PageSubContent>
      </PageContent>
    </MainHeader>
  )
}
export default Header
