import React from 'react'
import PropTypes from 'prop-types'
import './auroraPanel.scss'
const AuroraPanel = props => {
  const panelStyle = {
    display: 'flex',
    flexFlow: 'column',
    width: props.width || '90%',    
    height: props.height || '95%',
    backgroundColor: props.panelBgColor || 'white',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px'
  }
  const contentStyle = {
    alignItems: props.alignItems || 'center',
    justifyContent: props.justifyContent || 'center',
  }
  const topBorderStyle = {
    width: '100%',
    height: '8px',
    backgroundColor: props.topBorderColor || 'red'
  }
  const isPanelRequired = props.isPanelRequired || true
  const panelContentCss = props.panelContentCss || 'panel-content'
  const showTopBorder = props.showTopBorder || true
  return isPanelRequired ? (
    <div className={props.cssName} style={panelStyle}>
      {showTopBorder ? <div style={topBorderStyle}></div> : ''}
      <div className={panelContentCss} style={contentStyle}>
        {props.children}
      </div>
    </div>
  ) : (
    <div className={props.cssName} style={panelStyle}>
      <div className={panelContentCss} style={contentStyle}>
        {props.children}
      </div>
    </div>
  )
}
AuroraPanel.protoTypes = {
  isPanelRequired: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.element,
  cssName: PropTypes.string,
  topBorderCss: PropTypes.string,
  panelContentCss: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  topBorderColor: PropTypes.string,
  showTopBorder: PropTypes.bool,
  panelBgColor: PropTypes.string
}

export default AuroraPanel
