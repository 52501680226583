import { selectPaxFlight } from 'actions/etsTransactions/selectPaxFlight'
import { Animation, EMBTable } from 'components'
import { PageContent, PageHeader, PageSubTitle, Spacer } from 'components/styledComponents'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useMedia from 'hooks/useMedia'
import { getAccessibilityDevice, playSound } from 'main'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { checkLandscape, generateBP, getScreenId, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { parseBarcode } from 'utils/mediaParser'
import { Footer } from '../footer'

const ScanBoardingPass = (props) => {
  const [landscapeMode, screenWidth, screenHeight] = checkLandscape()
  const dispatch = useDispatch()
  const displayName = 'ScanBoardingPass'
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const embTableWidth = useMedia(null, ['440px', '400px', '400px'], '400px')
  const tableTextAlign = useMedia(null, ['flex-start', 'flex-start', 'center'], 'center')
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const [paxData, setPaxData] = useState([])
  const testNumber = useRef(1)
  const selectedPassengers = useRef([])
  const location = useLocation()
  const scannedCallback = (scannedValue) => {
    //props.getItinerarySBD({ barcodeData: scannedValue }, '')
    const barcode = parseBarcode(scannedValue)
    const selectPax = itineraryInfo.passengerBagtagDetails.find((pax) => {
      return pax.passenger.firstName === barcode.firstName && pax.passenger.lastName === barcode.lastName
    })
    if (selectPax) {
      const newData = []
      paxData.forEach((pax) => {
        newData.push(pax)
      })
      newData.push({ data: [selectPax.passenger.firstName + ' ' + selectPax.passenger.lastName] })
      selectedPassengers.current.push(selectPax.passenger.ordinal)
      setPaxData(newData)
      if (selectedPassengers.current.length === itineraryInfo.passengerBagtagDetails.length) {
        const selected = []
        selectedPassengers.current.forEach((paxOrd) => {
          selected.push({ paxOrdinal: paxOrd, selected: true })
        })
        dispatch(selectPaxFlight(selected))
      }
    }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
  const columns = [
    {
      header: intl.formatMessage(messages.ScanBoardingPassPaxListHeader),
      width: embTableWidth,
      textAlign: tableTextAlign
    }
  ]
  const accessibility = useRef()
  const getNextPassenger = () => {
    const options = []
    let paxOrd = null
    itineraryInfo.passengerBagtagDetails.forEach((pax) => {
      if (!selectedPassengers.current.includes(pax.passenger.ordinal)) {
        options.push(pax.passenger.ordinal)
      }
    })
    if (options.length > 0) {
      if (options.length === 1) {
        paxOrd = options[0]
      } else {
        const random = Math.floor(Math.random() * options.length)
        paxOrd = options[random]
      }
    }
    //const pax = itineraryInfo.passengerBagtagDetails.find(pax => {return pax.passenger.ordinal === paxOrd})
    return paxOrd
  }
  useEffect(() => {
    let itinData = null
    let data = []
    let scannedPaxNames = ''
    accessibility.current = getAccessibilityDevice()
    if (!itineraryInfo) {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        '>> (ScanBoardingPass.js) componentDidMount() - itineraryInfo NOT AVAILABLE ...'
      )
      navigate('error')
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '(ScanBoardingPass.js) componentDidMount() - itineraryInfo AVAILABLE !!!')
      itineraryInfo.scanSequence.map((ord) => {
        for (let i = 0; i < itineraryInfo.passengerBagtagDetails.length; i++) {
          if (itineraryInfo.passengerBagtagDetails[i].passenger.ordinal === ord) {
            data.push({
              data: [
                itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
                  ' ' +
                  itineraryInfo.passengerBagtagDetails[i].passenger.lastName
              ]
            })
            selectedPassengers.current.push(ord)
            scannedPaxNames +=
              itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
              ' ' +
              itineraryInfo.passengerBagtagDetails[i].passenger.lastName +
              ','
          }
        }
      })
      console.log('data', data)
    }
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(ScanBoardingPass.js) call barcode enable function')
      enable()
      //turnLights('scanBarcode', true)
    }
    setPaxData(data)
    console.log('pax data:', data)
    
    const screenId = getScreenId(location.pathname)
    let accDef = {
      pathName: 'ScanBoardingPass',
      startIndex: 0,
      screenDescriptionId: 'ScanBoardingPassScreen',
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      sequenceDef: {
        sequence: [
          { id: 'ScanBoardingPassTitle', textId: 'ScanBoardingPassTitle' },
          { id: 'ScanBoardingPassSubTitle', textId: 'ScanBoardingPassSubTitle' },
          { id: 'scannedPaxList', textId: 'scannedPaxList', textParameters: [scannedPaxNames] },
          { id: 'quitBtn', textId: 'NavQuit', buttonId: 'quitBtn' },
          { id: 'langBtn', textId: 'NavSelectLanguage', buttonId: 'langBtn' },
          { id: 'confirmBtn', textId: 'NavContinue', buttonId: 'confirmBtn' }
        ],
        nextSequence: ['confirmBtn']
      }
    }
    // props.updateAccessibility(AccessibilityActions.UPDATE_ACCESSIBILITY, accDef)
    if (accessibility.current.enabled) {
      accessibility.current.DeviceEvent = {
        key: 'componentDidMount',
        value: accDef
      }
    }
    return () => {
      disable()
      //turnLights('scanBarcode', false)
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    let selectedPassengers = []
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanBoardingPass.js) handleClick() ... target: ' + e.currentTarget.id)
    let location = {
      state: {
        from: 'ScanBoardingPass',
        statistics: {
          paxOrdinal: null,
          paxName: null
        }
      }
    }
    /// TEST CODE

    let testPassager = null
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          testPassager = config.testData[getNextPassenger()]
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            // value: 'M1MAGAR/RAVI          EHVDTE  DELBOMAI 0624 119Y017D0002 15D>5180OO0041BAI              2A09823046994170 AI                        N*50600000K0900'
            // value: 'M1SHIMA/GOYAL         EJ72S9  DELBOMAI 0624 119Y017D0002 15D>5180OO0041BAI              2A09823046994170 AI                        N*50600000K0900'
            value: generateBP(
              itineraryInfo.recordLocator,
              testPassager.firstName,
              testPassager.lastName,
              testPassager.from,
              testPassager.to,
              testPassager.airLine,
              testPassager.flightNumber
            )
          })
          break
        case 'buttonScanUnreadable':
          console.log('test boarding pass is unreadable')
          testPassager = config.testData[testNumber.current]
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: null
          })
          break
        case 'buttonScanNotInPNR':
          console.log('test boarding pass is not in current PNR')
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: generateBP('AAAAAA', 'firstName', 'lastName', 'AUH', 'YVR', 'NK', '1001')
          })
          break
        case 'buttonScanDuplicate':
          console.log('test boarding pass is duplicate')
          testPassager = config.testData[0]
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: generateBP(
              itineraryInfo.recordLocator,
              testPassager.firstName,
              testPassager.lastName,
              'AUH',
              'YVR',
              'NK',
              '1001'
            )
          })
          break
      }
    }
    let passenger = null
    let passengerBagtagDetail = null
    switch (e.currentTarget.id) {
      case 'confirmBtn':
    }
  }
  const testButtons = [
    {
      id: 'buttonScanBarcodeTEST',
      text: 'Test',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanUnreadable',
      text: 'Unreadable',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanNotInPNR',
      text: 'Not in PNR',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanDuplicate',
      text: 'Duplicate',
      handler: handleActions,
      cssName: 'test-button-up'
    }
  ]

  const handleNextAction = (e) => {
    const selected = []
    selectedPassengers.current.forEach((paxOrd) => {
      selected.push({ paxOrdinal: paxOrd, selected: true })
    })
    dispatch(selectPaxFlight(selected))
  }
  const header = (
    <PageHeader id={'ScanBoardingPassTitle'}>{intl.formatMessage(messages.ScanBoardingPassTitle)}</PageHeader>
  )
  const textSection = (
    <>
      <PageSubTitle id={'ScanBoardingPassSubTitle'} alignItems="center" height={'60px'}>
        {formatMessage(messages.ScanBoardingPassSubTitle)}
      </PageSubTitle>
      <PageContent flexFlow="row">
        {landscapeMode ? null : <Spacer width="56px" />}
        <EMBTable
          columns={columns}
          headerCellCss={''}
          cellCss={''}
          scrollbarWidth={36}
          scrollbarGap={20}
          rows={paxData.reverse()}
          headerHeight={50}
          rowHeight={30}
          rowFontSize={20}
          headFontSize={20}
          maxDisplayRows={5}
        />
      </PageContent>
    </>
  )
  const animationScanBp = isLandscape ? themeContext.AnimationLandscape.ScanBp : themeContext.AnimationPortrait.ScanBp
  const imageLocation = `${themeContext.AnimationPath}/${sbdModel}/${animationScanBp}`
  const animationSection =
    paxData.length > 0 && isLandscape ? (
      ''
    ) : (
      <Animation imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
    )
  const footer = (
    <Footer
      isQuitRequired={true}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={handleNextAction}
      confirmBtnText={formatMessage(messages.buttonContinue)}
      testData={testButtons}
    />
  )
  const contentWidth = { landscapeMode: '90%', portraitMode: '90%' }
  const popupSection = <>{popupContainer.retryMediaAccess}</>
  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    { contentWidth: contentWidth, displayItinerary: true, itineraryHeights: ['200px', '120px', '200px'] }
  )

  return <>{UIDisplay}</>
}

export default ScanBoardingPass
