import Listener from './listener'
import { TraceLevels } from 'constants/Constants'
import {endsWith} from 'utils/helper'

/** Barcode Reader listener.
 *  @extends {Listener}
 */
export default class BarcodeReaderListener extends Listener
{
  
  /**
   *  Default barcodeReadInternal callback.
   *  @param {string} readData read barcode data
   */
  barcodeReadInternal(readData)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeReadInternal() called: ')
    try {
      let barcodeStrip = (readData && readData.length > 0 && endsWith(readData, '\r')) ? readData.substring(0, readData.length - 1) : readData
      this.setEvent('barcodeReadInternal', barcodeStrip)
    } catch(err) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeReadInternal() err: ' + err)
    }
  }

  /**
   *  Default parsedBarcodeData callback.
   *  @param {string} readData read barcode data
   */
  parsedBarcodeData(readData)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.parsedBarcodeData() called: ')
    try {
      let barcodeStrip = (readData && readData.length > 0 && endsWith(readData, '\r')) ? readData.substring(0, readData.length - 1) : readData
      this.setEvent('parsedBarcodeData', barcodeStrip)
    } catch(err) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.parsedBarcodeData() err: ' + err)
    }
  }

  /**
   *  Default barcodeSymbologyScanned callback.
   *  @param {string} readData read barcode data
   */
   barcodeSymbologyScanned(readData)
     {
       this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeSymbologyScanned() called: ')
       try {
         let barcodeStrip = (readData && readData.length > 0 && endsWith(readData, '\r')) ? readData.substring(0, readData.length - 1) : readData
         this.setEvent('barcodeSymbologyScanned', barcodeStrip)
       } catch(err) {
         this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeSymbologyScanned() err: ' + err)
       }
     }

  /**
   *  Default barcodeDamaged callback.
   */
  barcodeDamaged()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeDamagedMethod() called')
    this.setEvent('barcodeDamaged', null)
  }

  /**
   *  Default barcodeInserted callback.
   */
  barcodeInserted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeInsertedMethod() called')
    this.setEvent('barcodeInserted', null)
  }

  /**
   *  Default barcodeReadInternal callback.
   *  @param {string} data read barcode data
   */
  barcodeRead(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeReadMethod() called')
    this.setEvent('barcodeRead', data)
  }

  /**
   *  Default barcodeRemoved callback.
   */
  barcodeRemoved()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.barcodeRemovedMethod() called')
    this.setEvent('barcodeRemoved', null)
  }

  /**
   *  Default getVersion callback.
   *  @param {String} data
   */
  getVersion(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BarcodeReaderListener.getVersion() called')
    this.setEvent('getVersion', data)
  }
}
