import { selectPaxFlight } from 'actions/etsTransactions/selectPaxFlight'
import { Button, Checkbox, CheckboxStatus, EMBTable } from 'components'
import { PageContent, PageSubTitle, PageTitle, Spacer } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { getAccessibilityManager, getBuildAccessibility, playSound } from 'main'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, populateItineraryInfo } from 'utils/helper'
import debounce from '../../utils/debounce'
import { Footer } from '../footer'

const getData = (passengers, mainPassengerOrdinal, formatMessage) => {
  let selectableCheckboxStatus = CheckboxStatus.UNCHECKED
  let alreadyCheckIn = false
  let data = []
  let canSelectCount = 0
  console.log('getData...')
  if (passengers && passengers.length > 0) {
    passengers.map((paxInfo) => {
      let additionalText = ''
      let additionalText1 = ''
      let additionalText2 = ''
      // additionalText = ''
      alreadyCheckIn = false
      selectableCheckboxStatus = CheckboxStatus.UNCHECKED
      if (paxInfo.passenger.ordinal === mainPassengerOrdinal) {
        selectableCheckboxStatus = CheckboxStatus.CHECKED_DISABLED
        //setIsDisableConfirmButton(false)
      } else {
        canSelectCount++
      }

      /*if (paxInfo.passenger.paxTxnStatus.selectable) {
        // checked single passenger by default
        if (passengers.length === 1) {
          selectableCheckboxStatus = CheckboxStatus.CHECKED
          //this.disableConfirm = false
        } else {
          selectableCheckboxStatus = CheckboxStatus.UNCHECKED
        }
        canSelectCount++
      } else {
        selectableCheckboxStatus = CheckboxStatus.UNCHECKED_DISABLED
        canSelectCount--
      }*/

      /*if (
        pax.flightSpecific[0].checkinStatus === CHECK_IN_STATUS.CHECKED_IN_NOT_PRINTED ||
        pax.flightSpecific[0].checkinStatus === CHECK_IN_STATUS.CHECKED_IN_PRINTED
      ) {
        alreadyCheckIn = true
        alreadyCheckInCount++
        /!*if (this.props.enableFastPath === 'false') {
          selectableCheckboxStatus = CheckboxStatus.UNCHECKED_DISABLED
          selectableCount--
        }*!/
      } else {
        alreadyCheckIn = false
      }*/

      /*if (paxInfo.passenger.withInfant) {
        additionalText += ' +' + formatMessage(messages.Infant) + ' '
      }*/
      /*if (pax.showDOB) {
        dob = getDOB(pax.ordinal)
        additionalText += dob ? ' (' + formatDOB(dob) + ') ' : ''
      }
      if (pax.alreadyCheckIn) {
        additionalText1 = formatMessage(messages.AlreadyCheckedIn)
      }
      if (pax.inhibited) {
        additionalText2 = formatMessage(messages.InhibitPassenger)
      }*/
      //this.allAlreadyCheckIn = alreadyCheckInCount === passengers.length

      if (paxInfo.passenger.withInfant) {
        additionalText += ` +${formatMessage(messages.Infant)} `
      }

      let paxName = paxInfo.passenger.firstName + ' ' + paxInfo.passenger.lastName
      paxName = config.paxNameLength ? paxName.substring(0, config.paxNameLength) : paxName

      data.push({
        ordinal: paxInfo.passenger.ordinal,
        id: 'pax_' + paxInfo.passenger.ordinal.toString(),
        text: paxName,
        passengerType: paxInfo.passenger.passengerType,
        status: selectableCheckboxStatus,
        dateOfBirth: paxInfo.passenger.dateOfBirth,
        alreadyCheckIn: alreadyCheckIn,
        withInfant: paxInfo.passenger.withInfant,
        additionalText: additionalText,
        additionalText1: additionalText1,
        additionalText2: additionalText2
      })
    })
  }
  return data
}

const PassengerSelection = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(false)
  const [itineraryData, setItineraryData] = useState(null)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const isQuitRequired = !appMode
  const isLandscape = useCheckLandscape()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const columns = isLandscape
    ? [
        {
          header: '',
          width: '500px'
        },
        {
          header: '',
          width: '500px'
        }
      ]
    : [
        {
          header: '',
          width: themeContext.PassengerSelection.EMBTable?.width[ratioKey] ?? '700px'
        }
      ]

  const handleSelection = (updatedData) => {
    playSound.beepOK()
    console.log('onCheckboxClick id=' + updatedData[0].id + ', status=' + updatedData[0].status)
    let hasChecked = false
    data.map((item) => {
      if (item.id === updatedData[0].id) {
        item.status = updatedData[0].status
      }
      if (item.status === CheckboxStatus.CHECKED || CheckboxStatus.CHECKED_DISABLED) {
        hasChecked = true
      }
    })
    if (hasChecked) {
      setIsDisableConfirmButton(false)
    } else {
      setIsDisableConfirmButton(true)
    }
    setData([...data])
  }
  const debouncedHandleSelection = debounce(handleSelection, 50)

  const buildRows = (data) => {
    let canSelectCount = 0
    let rows = []
    let count = 0
    if (!data) {
      return rows
    }
    if (isLandscape) {
      data.forEach((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        const checkbox = (
          <Checkbox
            key={'key-' + index}
            id={cell.id}
            data={[checkboxData]}
            onClick={debouncedHandleSelection}
            additionalText={cell.additionalText}
            additionalText1={cell.additionalText1}
            additionalText2={cell.additionalText2}
            tabIndex={cell.tabIndex}
          />
        )
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        if (index % 2 === 0) {
          rows.push({ data: [checkbox] })
        } else {
          rows[count].data.push(checkbox)
          count++
        }
      })
    } else {
      data.map((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        rows.push({
          data: [
            <Checkbox
              key={'key-' + index}
              id={cell.id}
              data={[checkboxData]}
              onClick={debouncedHandleSelection}
              additionalText={cell.additionalText}
              additionalText1={cell.additionalText1}
              additionalText2={cell.additionalText2}
              tabIndex={cell.tabIndex}
            />
          ]
        })
      })
    }
    return rows
  }

  const getCheckAllButton = (data) => {
    let checkAllButton = data ? (
      <PageContent flexFlow="row" justifyContent="flex-start" style={{ width: isLandscape ? '90%' : '' }}>
        <Button
          id={'selectAllPassengers'}
          cssName={'large-blue-button-up'}
          color={themeContext.OutlineButton.color}
          bgColor={themeContext.OutlineButton.bgColor}
          border={themeContext.OutlineButton.border}
          width={themeContext.OutlineButton.width[ratioKey]}
          height={themeContext.OutlineButton.height[ratioKey]}
          fontSize={themeContext.OutlineButton.fontSize[ratioKey]}
          justifyContent={'center'}
          borderRadius={themeContext.OutlineButton.borderRadius[ratioKey]}
          onClick={() => {
            playSound.beepOK()
            data.forEach((item) => {
              if (item.status === CheckboxStatus.UNCHECKED) {
                item.status = CheckboxStatus.CHECKED
              }
            })
            setData([...data])
          }}
        >
          {formatMessage(messages.buttonSelectAll)}
        </Button>
      </PageContent>
    ) : (
      ''
    )
    return checkAllButton
  }

  useEffect(() => {
    const mainPassengerOrdinal =
      itineraryInfo && itineraryInfo.scanSequence.length > 0 ? itineraryInfo.scanSequence[0] : 0
    const passengers = itineraryInfo.passengerBagtagDetails
    const initData = getData(passengers, mainPassengerOrdinal, intl.formatMessage)
    setItineraryData(populateItineraryInfo(itineraryInfo))
    setData(initData)
  }, [])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const accMgr = getAccessibilityManager()

  useEffect(() => {
    if (data) {
      handleAccessibility()
    }
  }, [data, locale])

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined

    const accDef = {
      pathName: 'PassengerSelection',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: getSequence(),
        nextKeyNavId: 'confirmBtn'
      }
    }
    buildAccessibility(accDef)
  }

  const getSequence = () => {
    const screenTitle = `${intl.formatMessage(messages.PassengerSelectionTitle)}. ${intl.formatMessage(
      messages.PassengerSelectionSubTitle
    )}.`
    const language = accMgr.getLanguageDef(locale).language
    const sequence = [{ id: 'page-content', textId: 'OneDynamicTextWithDownKey', textParameters: [screenTitle] }]

    data.map((pax) => {
      // console.log('pax: ', pax)
      let parameters = []
      if (pax.status === CheckboxStatus.CHECKED) {
        parameters = [pax.text, language['Selected'], language['UnSelected']]
      } else {
        parameters = [pax.text, language['UnSelected'], language['Selected']]
      }
      if (pax.status === CheckboxStatus.CHECKED_DISABLED) {
        sequence.push({
          id: 'pax_' + pax.ordinal,
          textId: 'SelectedAndDisabledPassenger',
          textParameters: [pax.text],
          buttonId: 'pax_' + pax.ordinal
        })
      } else if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.UNCHECKED) {
        sequence.push({
          id: 'pax_' + pax.ordinal,
          textId: 'NavigateSelectPassenger',
          textParameters: parameters,
          buttonId: 'pax_' + pax.ordinal
        })
      }
    })
    if (data.length > 1) {
      sequence.push({
        id: 'selectAllPassengers',
        textId: 'SelectAllPassengers',
        buttonId: 'selectAllPassengers'
      })
    }
    sequence.push({ id: 'confirmBtn', textId: 'NavContinue', buttonId: 'confirmBtn' })

    return sequence
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSection = (
    <>
      <EMBTable
        columns={columns}
        rows={buildRows(data)}
        headerHeight={50}
        rowHeight={themeContext.PassengerSelection.rowHeight[ratioKey]}
        rowFontSize={30}
        headFontSize={30}
        maxDisplayRows={themeContext.PassengerSelection.maxDisplayRows[ratioKey]}
      />
      <Spacer height="20px" />
      {getCheckAllButton(data)}
    </>
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.PassengerSelectionTitle)}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.PassengerSelectionSubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={() => {
        let selectedPassengers = []
        data.forEach((pax) => {
          if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.CHECKED_DISABLED) {
            selectedPassengers.push({
              paxOrdinal: pax.ordinal,
              selected: true
            })
          } else {
            selectedPassengers.push({
              paxOrdinal: pax.ordinal,
              selected: false
            })
          }
        })
        dispatch(selectPaxFlight(selectedPassengers))
      }}
      confirmBtnText={formatMessage(messages.buttonContinue)}
      disableConfirmAction={isDisableConfirmButton}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default PassengerSelection
