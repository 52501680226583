import { store } from 'main'
import { goToFetchError } from 'utils/FetchWithTimeout'
import { fetchWithTimeout } from './FetchWithTimeout'
import { logDebug, logError, logInfo } from 'utils/Logger'
import { history } from 'main'

const endBiometricsTransaction = (sessionInfo, endTxnReason, errorCode, txnStateData) => {
  const request = {
    sessionInfo: sessionInfo,
    carrierCode: undefined,
    currentClientTime: new Date().getTime(),
    asyncRequested: true,
    endTxnReason: endTxnReason,
    errorCode: errorCode,
    txnStateData: txnStateData
  }
  logInfo('end transaction request:', JSON.stringify(request))
  fetchWithTimeout('endBiometricsTxn/', request)
    .then((json) => {
      if (json.etsResponse) {
        logInfo('end transaction  response:', JSON.stringify(json))
      } else {
        if (json.error) {
          goToFetchError('endBiometricsTransaction', json, store.dispatch, config.firstScreen)
        } else {
          store.dispatch(updateError(ErrCodes.ETS_COMM_ERROR, '', 'endBiometricsTransaction', OOS))
        }
      }
    })
    .catch((err) => {
      logError('endBiometricsTransaction Catch:' + err)
      store.dispatch(updateError(err.errCode, err.message, 'endBiometricsTransaction'))
      navigate('error')
    })
}
export default endBiometricsTransaction
