import { deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import PaymentListener from 'listeners/paymentListener'

/**
 *  Device representing Payment
 *  @extends {Device}
 */
export default class Payment extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Payment'
    /**
     * Device id - deviceIds.PAYMENT {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.PAYMENT
    this.setListener(new PaymentListener())
  }
  /**
   *  Async call to set the payment
   *  @param {String} key
   *  @param {String} value - amount of payment
   *  @example set("AMOUNT","50")
   */
  set(key, value) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'set(). key: ' + key + ', value: ' + value)
      this.socket.sendCommand(this.deviceId, 'set', key, value)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'set() exception: ' + e)
    }
  }

  /**
   * Async call to start payment transaction
   */
  startTransaction() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'startTransaction().')
      this.socket.sendCommand(this.deviceId, 'startTransaction')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'startTransaction() exception: ' + e)
    }
  }

  /**
   * Async call to get the result of the transaction as a string
   */
  getTransactionResult() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getTransactionResult().')
      this.socket.sendRequest(this.deviceId, 'getTransactionResult')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getTransactionResult() exception: ' + e)
    }
  }

  /**
   * Async call to get the current state of payment
   * @return STATE_UNAVAILABLE / STATE_INITIALIZING / STATE_WAITING / STATE_READY / STATE_PENDING / STATE_SUCCESS / STATE_FAILURE
   * @example
   * STATE_UNAVAILABLE – offline
   * STATE_INITIALIZING – initially initializing
   * STATE_WAITING – expecting user interaction and waiting for amount to be charged to customer
   * STATE_READY – amount has been set by the application
   * STATE_PENDING – transaction has been started by application and waiting result of transaction from device
   * STATE_SUCCESS – transaction has been approved
   * STATE_FAILURE – transaction has failed
   */
  getPaymentState() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getPaymentState().')
      this.socket.sendRequest(this.deviceId, 'getPaymentState')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getPaymentState() exception: ' + e)
    }
  }

  /**
   * Async call to cancel the current payment
   */
  cancelTransaction() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'cancelTransaction().')
      this.socket.sendCommand(this.deviceId, 'cancelTransaction')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'cancelTransaction() exception: ' + e)
    }
  }

  /**
   * Async call to clear the transaction data of payment
   */
  clearTransactionData() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'clearTransactionData().')
      this.socket.sendCommand(this.deviceId, 'clearTransactionData')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'clearTransactionData() exception: ' + e)
    }
  }
}
