import { deviceIds } from 'constants/Constants'
import device from 'devices/device'
import CardReaderListener from 'listeners/cardReaderListener'
import { TraceLevels } from 'constants/Constants'

/**
 *  Device representing a Card Reader
 *  @extends {Device}
 */
export default class CardReader extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Card Reader'
    /**
     * Device id - deviceIds.CARD_READER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.CARD_READER
    this.setListener(new CardReaderListener())
  }

  /**
   *  Asynch call to check if reader is DIP reader
   */
  isDIP()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'isDIP().')
      this.socket.sendRequest(this.deviceId, 'isDIP')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isDIP() exception: ' + e)
    }
  }
  /**
   *  Asynch call to check if reader is Swipe reader
   */
  isSwipe()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'isSwipe().')
      this.socket.sendRequest(this.deviceId, 'isSwipe')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isSwipe() exception: ' + e)
    }
  }

  /**
   *  Asynch call to check if reader is Motorized reader
   */
  isMotorized()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'isMotorized().')
      this.socket.sendRequest(this.deviceId, 'isMotorized')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isMotorized() exception: ' + e)
    }
  }

  /**
   *  Asynch call to check if reader can read magnetic stripe
   */
  isMagneticStripe()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'isMagneticStripe().')
      this.socket.sendRequest(this.deviceId, 'isMagneticStripe')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isMagneticStripe() exception: ' + e)
    }
  }
  
  /**
   *  Asynch call to check if the card is in the reader
   */
  isCardInReader()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'isCardInReader().')
      this.socket.sendRequest(this.deviceId, 'isCardInReader')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isCardInReader() exception: ' + e)
    }
  }
  
  /**
   *  Asynch call to check if the card is partially inserted in the reader
   */
  isCardPartiallyInserted()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'isCardPartiallyInserted().')
      this.socket.sendRequest(this.deviceId, 'isCardPartiallyInserted')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'isCardPartiallyInserted() exception: ' + e)
    }
  }
}
