/** @class A class containing values returned from CUSS status and event codes. Copied from CompFact.*/
export default class DeviceStatus
{
  /**
   * DeviceStatus constructor - defines constants (CUSS status and event codes)
   */
  constructor() {
    /**
     * The device is in an OK state. No errors.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=0 will map to this return code.
     * If the CUSS return code is 0 then the status code will be parsed. Any NON-ZERO CUSS return code will
     * be maped to SW_ERROR.
     * <br/>Value: 0
     * @type {number} @static @final*/
    this.OK = 0
  
    /**
     * The device is in an OK state but the command timed out. This is considered a soft error and not
     * fatal to the operation of the device.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.TIMEOUT will map to this return code.
     * <br/>Value: 1
     * @type {number} @static @final*/
    this.TIMEOUT = 1
  
    /**
     * The device is in an OK state but the command was cancelled. This is considered a soft error and not
     * fatal to the operation of the device.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.CANCELLED will map to this return
     * code.
     * <br/>Value: 3
     * @type {number} @static @final*/
    this.CANCELLED = 3
  
    /**
     * The device is in an OK state but the command was cancelled. This is considered a soft error and not
     * fatal to the operation of the device.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.OUT_OF_SEQUENCE or
     * statuscodes.WRONG_STATE will map to this return code.
     * This commonly occurs when the application is trying to manipulate the device while not in an active state.
     * <br/>Value: 5
     * @type {number} @static @final*/
    this.SEQUENCE_ERR = 5
  
    /**
     * The device is in an BAD state. This is considered a hard error and fatal to the operation of the device.
     * It ususally indicates that the device has a physical jam and requires physical intervention.
     *
     * In the CUSS implementation, a CUSS returncode=0 or statuscodes.MEDIA_JAMMED will map to this return code.
     * <br/>Value: 101
     * @type {number} @static @final*/
    this.JAM = 101
  
    /**
     * The device is in an OK state. This is considered a soft error. It ususally indicates the void bin the
     * device is almost full. For example, the capture bin for a card reader might almost be full.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_HIGH will map to this return
     * code.
     * <br/>Value: 102
     * @type {number} @static @final*/
    this.MEDIA_HIGH = 102
  
    /**
     * The device is in an OK state. This is considered a soft error and NOT fatal to the operation of the device.
     * It ususally indicates that media as been inserted into the device (and may or may not have been read).
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_PRESENT will map to this
     * return code.
     * <br/>Value: 103
     * @type {number} @static @final*/
    this.MEDIA_PRESENT = 103
  
    /**
     * The device is in a OK state. This is considered a soft error. It ususally indicates that
     * device does not have any media in it. For example card reader does not have a card inserted inside.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_ABSENT will map to this
     * return code.
     * <br/>Value: 104
     * @type {number} @static @final*/
    this.MEDIA_ABSENT = 104
  
    /**
     * This declaration has been deprecated. No replacement.
     * <br/>Value: 105
     * @deprecated
     * @type {number} @static @final*/
    this.MEDIA_SWIPE = 105
  
    /**
     * The device is in a BAD state. This is considered a hard error. It ususally indicates the void bin the
     * device is full. For example, the capture bin for a card reader is full and requires physical intervention
     * to empty.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_FULL will map to this return
     * code.
     * <br/>Value: 106
     * @type {number} @static @final*/
    this.MEDIA_FULL = 106
  
    /**
     * The device is in an OK state. This is considered a soft error. It ususally indicates that
     * device is low on media. For example the printer is running low on paper.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_LOW will map to this return
     * code.
     * <br/>Value: 107
     * @type {number} @static @final*/
    this.MEDIA_LOW = 107
  
    /**
     * The device is in a BAD state. This is considered a soft error. This indicates that media that is critical to the
     * operation of the device is missing. For example the printer is out of paper.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_EMPTY will map to this return
     * code.
     * <br/>Value: 108
     * @type {number} @static @final*/
    this.MEDIA_EMPTY = 108
  
    /**
     * The device is in a OK state. This is considered a soft error. This indicates that media the
     * device is trying to operation on is damage. For example, reading a card with a bad magnetic stripe.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.MEDIA_DAMAGED or
     * statuscode=statuscodes.PHYSICAL_ERROR will map to this return code.
     * <br/>Value: 109
     * @type {number} @static @final*/
    this.MEDIA_DAMAGED = 109
  
    /**
     * The device is in a OK state. This is considered a soft error. This indicates that media is not completely
     * inserted or in the transition to be inserted. For example, the action of inserting of a card will typically
     * trigger this return code.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=MEDIA_INCOMPLETELY_INSERTED will map to this
     * return code.
     * <br/>Value: 110
     * @type {number} @static @final*/
    this.MEDIA_INCOMPLETE_INSERTION = 110
  
    /**
     * The device is in an OK state. This is considered a soft error. This indicates there might be problem
     * with the data being sent or receive to the device. For example, reading a ATB ticket that the printer does
     * not have a PECTAB for.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.FORMAT_ERROR or
     * statuscodes.LENGTH_ERROR or statuscodes.DATA_MISSING or statuscode=statuscodes.MEDIA_MISPLACED will map to
     * this return code.
     * <br/>Value: 201
     * @type {number} @static @final*/
    this.FORMAT_ERROR = 201
  
    /**
     * The device is in an OK state. This is considered a soft error. This indicates there is data available for
     * reading.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.DATA_PRESENT will map to this
     * return code.
     * <br/>Value: 205
     * @type {number} @static @final*/
    this.DATA_PRESENT = 205
  
    /**
     * The device is in a BAD state. This is considered a hard error. This indicates there is something wrong with
     * the device.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscode=statuscodes.HARDWARE_ERROR or
     * statuscodes.CONSUMABLES will map to this return code.
     * <br/>Value: 302
     * @type {number} @static @final*/
    this.HW_ERROR = 302
  
    /**
     * The device is in a BAD state. This is considered a hard error. This indicates there is something wrong with the
     * device or the device is in a bad state.
     *
     * In the CUSS implementation, there is a wide range of return codes and status codes that can return this value. If the
     * returncode is NOT OK then this value is returned by default. If the CUSS return code is OK but the status code is
     * statuscodes.SOFTWARE_ERROR, statuscodes.CRITICAL_SOFTWARE_ERROR, statuscodes.THRESHOLD_USAGE
     * statuscodes.THRESHOLD_ERROR and any unknown status codes will map to this return code.
     * <br/>Value: 303
     * @type {number} @static @final*/
    this.SW_ERROR = 303
  
    /**
     * The device is in a BAD state. This is considered a hard error. This indicates there is something wrong with the device or
     * the device is in a bad state.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscodes.NOT_REACHABLE or statuscodes.NOT_RESPONDING will map to this return code.
     * <br/>Value: 305
     * @type {number} @static @final*/
    this.NOT_RESPONDING = 305
  
    /**
     * The device is in a BAD state. This is considered a hard error. This indicates there is something wrong with the device or
     * the device is in a bad state.
     *
     * In the CUSS implementation, a CUSS returncode=0 and statuscodes.CONFIGURATION_ERROR will map to this return code.
     * <br/>Value: 308
     * @type {number} @static @final*/
    this.CFG_ERROR = 308
  }
  
  /** Get the string for this status code.
   @param {number} code the status code to get the string for.
   @return {string} a string describing this status code.
   */
  getRCString(code)
  {
    switch (code)
    {
      case this.OK:
        return 'OK'
      case this.TIMEOUT:
        return 'Timed out'
      case this.CANCELLED:
        return 'Cancelled'
      case this.SEQUENCE_ERR:
        return 'Command sequence error'
      case this.JAM:
        return 'Jammed'
      case this.MEDIA_PRESENT:
        return 'media present or inserted'
      case this.MEDIA_ABSENT:
        return 'No media present and/or removed'
      case this.MEDIA_INCOMPLETE_INSERTION:
        return 'Incomplete insertion of media'
      case this.FORMAT_ERROR:
        return 'Format, parameter or length error'
      case this.HW_ERROR:
        return 'Hardware error'
      case this.SW_ERROR:
        return 'Software error'
      case this.NOT_RESPONDING:
        return 'Device not responding'
      case this.CFG_ERROR:
        return 'Configuration error'
      case this.MEDIA_LOW:
        return 'Media is low'
      case this.MEDIA_EMPTY:
        return 'Media is empty'
      case this.MEDIA_DAMAGED:
        return 'Media is damaged'
      case this.DATA_PRESENT:
        return 'Data Present'
      case this.MEDIA_HIGH:
        return 'Media High'
      case this.MEDIA_FULL:
        return 'Media Full'
      case this.MEDIA_SWIPE:
        return 'Media Swiped (deprecated)'
    }
    return ''
  }
}
