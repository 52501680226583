import {deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import FingerPrintScannerListener from 'listeners/fingerPrintScannerListener'

/**
 *  Device representing a Fingerprint Scanner
 *  @extends {Device}
 */
export default class FingerPrintScanner extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Fingerprint Scanner'
    /**
     * Device id - deviceIds.FINGERPRINT_SCANNER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.FINGERPRINT_SCANNER
    this.setListener(new FingerPrintScannerListener())
  }
  /**
   * Send setup string to the finger print scanner.
   * @param {String} data - setup string
   * @example <CONFIG_POSITION><XPOS>100</XPOS><YPOS>205</YPOS></CONFIG_POSITION><CONFIG_SCAN>LEFT_HAND</CONFIG_SCAN>
   */
  setup(data)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'setup(): ' + data)
      this.socket.sendRequest(this.deviceId, 'setup', data)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'setup() exception: ' + e)
    }
  }

  /**
   * Get version of the finger print scanner software.
   * @param {String} [key] when the key is not provided then the response will contain values for all keys listed below:
   * <ul><li>VENDOR_NAME</li>
   * <li>MODEL_NAME</li>
   * <li>SERIAL_NUMBER</li>
   * <li>FIRMWARE_VERSION</li>
   * <li>IMAGE_QUALITY_SW</li>
   * <li>COMPRESSION_SW</li>
   * <li>CDS_DRIVER_VERSION</li>
   * <li>CDS_DRIVER_DATE</li></ul>
   */
  getVersion(key)
  {
    try
    {
      if (key) {
        this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion(): ' + key)
        this.socket.sendRequest(this.deviceId, 'getVersion', key)
      } else {
        this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion()')
        this.socket.sendRequest(this.deviceId, 'getVersion')
      }
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'getVersion() exception: ' + e)
    }
  }
  
  /**
   * Show the fingerprint live stream.
   */
  show()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'show(): ' + this.deviceId)
      this.socket.sendRequest(this.deviceId, 'show')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'show() exception: ' + e)
    }
  }

  /**
   * Hide the fingerprint live stream.
   */
  hide()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'hide(): ' + this.deviceId)
      this.socket.sendRequest(this.deviceId, 'hide')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'hide() exception: ' + e)
    }
  }
  
  /** Enables the fingerprint device
   */
  /* enable()
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'enable(): ' + this.deviceId)
      this.socket.sendRequest(this.deviceId, 'enable')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'enable() exception: ' + e)
    }
  } */
}

