import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** ATB Printer listener.
 *  @extends {Listener}
 */
export default class ATBPrinterListener extends Listener
{

  /**
   *  Default ticketEjected callback.  Called when the printer ejects an inserted ticket
   */
  ticketEjected()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketEjected() called')
    this.setEvent('ticketEjected', null)
  }

  /**
   *  Default ticketInserted callback.  Called when the printer detects a ticket inserted into the printer
   */
  ticketInserted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketInserted() called')
    this.setEvent('ticketInserted', null)
  }

  /**
   *  Default ticketPresent callback.  Called when the printer detects the presense of a ticket in the coupon exit tray
   */
  ticketPresent()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketPresent() called')
    this.setEvent('ticketPresent', null)
  }

  /**
   *  Default ticketRemoved callback.  Called when the printer detects the absense of a ticket in the coupon exit tray
   */
  ticketRemoved()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketRemoved() called')
    this.setEvent('ticketRemoved', null)
  }

  /**
   *  Default ticketRead callback.  Called when the printer detects data from an inserted ticket
   *  @param {String} data The data read by the printer
   */
  ticketRead(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketRead() called')
    this.setEvent('ticketRead', data)
  }

  /**
   *  Default ticketPrinted callback.  Called every time the printer finishes printing a print stream
   *  @param {String} response - array[index, total, stream]
   *   <ul><li>index of the ticket just printed</li>
   *   <li>total tickets sent to the printer</li>
   *   <li>stream sent to the printer</li>
   *   </ul>
   */
  ticketPrinted(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketPrinted() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('ticketPrinted', res)
  }

  /**
   *  Default ticketFailed callback.  Called every time the printer fails printing a print stream
   *   @param {String} response - array[index, total, stream]
   *   <ul><li>index of the ticket which failed</li>
   *   <li>total tickets sent to the printer</li>
   *   <li>stream sent to the printer</li>
   *   </ul>
   */
  ticketFailed(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketFailed() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('ticketFailed', res)
  }

  /**
   *  Default ticketPrintingComplete callback.  Called when the printer finishes printing a print stream
   *  Typically called only if the print command supports a vector of print streams to be printed
   */
  ticketPrintingComplete()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.ticketPrintingComplete() called')
    this.setEvent('ticketPrintingComplete', null)
  }

  /**
   *  Default pectabLoaded callback.  Called every time the printer finishes loading a pectab stream
   *   @param {String} response - array[index, total]
   *   <ul><li>index of the pectab just loaded</li>
   *   <li>total pectabs sent to the printer</li>
   *   </ul>
   */
  pectabLoaded(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.pectabLoaded() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('pectabLoaded', res)
  }

  /**
   *  Default pectabFailed callback.  Called every time the printer fails loading a pectab stream
   *  @param {String} response - array[index, total]
   *   <ul><li>index of the pectab which failed</li>
   *   <li>total pectabs sent to the printer</li>
   *   </ul>
   */
  pectabFailed(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.pectabFailed() called')
    let res = response ? response.split(',').map(item => item.trim()) : []
    this.setEvent('pectabFailed', res)
  }

  /**
   *  Default pectabLoadingComplete callback.  Called when the printer finishes loading a set of pectab streams
   *  Typically called only if the print command supports a vector of pectab streams to be loaded
   */
  pectabLoadingComplete()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.pectabLoadingComplete() called')
    this.setEvent('pectabLoadingComplete', null)
  }
  
  /**
   *  Default offer callback.
   *  @param {int} rc return code from offer: 0 - media absent, 1 - media present
   */
  offer(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.offer() called')
    this.setEvent('offer', rc)
    if (rc === '104') { //media absent
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterListener.offer() - generate bagtagRemoved event')
      this.setEvent('bagtagRemoved', null)
    }
  }
  
  /**
   *  Default isPECTABLoadedInPrinter callback.
   *  @param {string} response '1' when PECTABs loaded in printer
   */
  isPECTABLoadedInPrinter(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterListener.isPECTABLoadedInPrinter(): ' + response)
    this.setEvent('isPECTABLoadedInPrinter', response === '1')
  }
}
