import { DynamicImage, PageContent, PageSubContent, PageSubTitle, PageTitle, Spacer } from 'components/styledComponents'
import { getBuildAccessibility, history } from 'main'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId } from 'utils/helper'
import { Footer } from '../footer'

const displayItinerary = false
const PhotoRetry = () => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const location = useLocation()
  const faceServiceUsedFor = useSelector((state) => state.settings.faceServiceUsedFor)
  const locale = useSelector((state) => state.localData.locale)
  const [photoErrorTitleText, setPhotoErrorTitleText] = useState(intl.formatMessage(messages.TakePhoto_Title_Error))
  const [photoErrorSubTitleText, setPhotoErrorSubTitleText] = useState(
    intl.formatMessage(messages.TakePhoto_SubTitle_Error)
  )
  const [photoErrorText, setPhotoErrorText] = useState('')
  /* useEffect(() => {
    setTimeout(() => {
      if (faceServiceUsedFor === 'RECOGNITION') {
        history.push('recognition')
      } else {
        history.push('takePhoto')
      }
    }, config.takePhotoPrepareTime * 1000)
  }, []) */
  useEffect(() => {
    const subtitle =
      location.state?.reason && location.state.reason.includes('MASK_DETECTED')
        ? intl.formatMessage(messages['Error_MASK_DETECTED'])
        : location.state.reason.includes('GLASSES_DETECTED')
        ? intl.formatMessage(messages['Error_GLASSES_DETECTED'])
        : location.state.reason.includes('SPOOFED_FACE_DETECTED')
        ? intl.formatMessage(messages['Error_SPOOFED_FACE_DETECTED'])
        : ''
    setPhotoErrorSubTitleText(subtitle)
    if (faceServiceUsedFor === 'RECOGNITION') {
      setPhotoErrorTitleText(intl.formatMessage(messages.RecognitionRetryTitle))
    }
  }, [location.state?.reason, locale])

  const handleOkButton = () => {
    if (faceServiceUsedFor === 'RECOGNITION') {
      history.push('recognition')
    } else {
      history.push('takePhoto')
    }
  }

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const screenId = getScreenId(location.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'PhotoRetry',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        displayItinerary,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [photoErrorTitleText, photoErrorSubTitleText]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  return (
    <>
      <PageContent flexFlow={'column'}>
        <PageContent>
          <DynamicImage imageName="warning.png" height={300} width={300} />
        </PageContent>
        <PageSubContent>
          <PageTitle>{photoErrorTitleText}</PageTitle>
        </PageSubContent>
        <PageSubContent justifyContent="center">
          <PageSubTitle>{photoErrorSubTitleText}</PageSubTitle>
        </PageSubContent>
        <Spacer height="20px" />
      </PageContent>
      <Footer
        displayItinerary={displayItinerary}
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmBtnText={intl.formatMessage(messages.buttonOk)}
        confirmAction={handleOkButton}
      />
    </>
  )
}

export default PhotoRetry
