import { v4 as uuidv4 } from 'uuid'
import { getDeviceManager, getTSDManager } from 'main'
import * as embrossDeviceManager from 'embross-device-manager'

export const schemaVersion = '1.0.0'

export const EventFlows = {
  UserAction: 'UserAction ',
  Application: 'Application ',
  PrivacyStatement: 'PrivacyStatement',
  ScanBCBP: 'ScanBCBP',
  DocScan: 'DocScan',
  DocCheck: 'DocCheck',
  FaceCapture: 'FaceCapture',
  FaceMatch1: 'FaceMatch1',
  FaceMatchN: 'FaceMatchN',
  Travelstream: 'Travelstream',
  BagDrop: 'BagDrop',
  BagScale: 'BagScale',
  DangerousGoods: 'DangerousGoods',
  Reservation: 'Reservation',
  AgentOverride: 'AgentOverride',
  Acuant: 'Acuant',
  Printing: 'Printing'
}

export const EventFunctions = {
  TransactionStart: 'TransactionStart',
  TransactionEnd: 'TransactionEnd',
  ETSTransactionStart: 'ETSTransactionStart',
  ETSTransactionEnd: 'ETSTransactionEnd',
  UserStart: 'UserStart',
  UserEnd: 'UserEnd',
  EnrollmentSuccess: 'EnrollmentSuccess',
  EnrollmentError: 'EnrollmentError',
  QRScan: 'QRScan',
  RFIDScan: 'RFIDScan',
  MultiTap: 'MultiTap',
  UserAcceptance: 'UserAcceptance', //Accepted/Declined
  ScanBCBPResult: 'ScanBCBPResult',
  BCBPScanned: 'BCBPScanned',
  ScanBCBPRetry: 'ScanBCBPRetry',
  DocScanResult: 'DocScanResult',
  DocScanStart: 'DocScanStart',
  DocType: 'DocType',
  DocScanRetry: 'DocScanRetry',
  DocMRZ: 'DocMRZ',
  DocDataDOB: 'DocDataDOB',
  DocDataCountry: 'DocDataCountry',
  DocScanError: 'DocScanError',
  DocScanDG2: 'DocScanDG2',
  DocCheckRetry: 'DocCheckRetry',
  DocCheckResult: 'DocCheckResult',
  DocCheckError: 'DocCheckError',
  AcuantCheck: 'AcuantCheck',
  AcuantDocType: 'AcuantDocType',
  AcuantSecondSide: 'AcuantSecondSide',
  AcuantFailDoc: 'AcuantFailDoc',
  AcuantDocInfo: 'AcuantDocInfo',
  FaceCaptureStart: 'FaceCaptureStart',
  FaceCaptureDetected: 'FaceCaptureDetected',
  FaceCaptureResult: 'FaceCaptureResult',
  FaceCaptureRetry: 'FaceCaptureRetry',
  FaceCaptureRetrySpoof: 'FaceCaptureRetrySpoof',
  FaceCaptureRetryQuality: 'FaceCaptureRetryQuality',
  FaceCaptureQuality: 'FaceCaptureQuality',
  FaceCaptureSpoof: 'FaceCaptureSpoof',
  FaceMatchInitiated: 'FaceMatchInitiated',
  FaceMatchResult: 'FaceMatchResult',
  FaceMatchScore: 'FaceMatchScore',
  FaceMatchRetry: 'FaceMatchRetry',
  FaceMatchError: 'FaceMatchError',
  BagDetected: 'BagDetected',
  BagTag: 'BagTag',
  BagWeight: 'BagWeight',
  BagDimensions: 'BagDimensions',
  BagInducted: 'BagInducted',
  Intrusion: 'Intrusion',
  BagError: 'BagError',
  BPPrinted: 'BPPrinted',
  BTPrinted: 'BTPrinted',
  ReceiptPrinted: 'ReceiptPrinted',
  HeavyTagPrinted: 'HeavyTagPrinted',
  PrintError: 'PrintError',
  PNR: 'PNR',
  BagAllowance: 'BagAllowance',
  NumberOfPAX: 'NumberOfPAX',
  NumberofBags: 'NumberofBags',
  ETSString: 'ETSString',
  Destination: 'Destination',
  Flight: 'Flight',
  Departure: 'Departure',
  GateFunction: 'GateFunction',
  GateError: 'GateError',
  TubUsed: 'TubUsed',
  WeightUnit: 'WeightUnit',
  DimensionUnit: 'DimensionUnit',
  ReservationError: 'ReservationError',
  NameMatch: 'NameMatch',
  NameMatchFailed: 'NameMatchFailed',
  NameMatchType: 'NameMatchType',
  RecognitionResult: 'RecognitionResult',
  ClosingRemark: 'ClosingRemark'
}

/* export interface AppLogDetail {
  header: Header
  stateData: StateData
}

export interface Header {
  schemaVersion: string
  timestamp: string
  eventTime: number
  eventSource: string //kioskId
  locationId: string
  subClientId: string //airline
  sessionId: string
  eventType: string //'APP_LOG_DETAILS'
  applicationType: string
} */

/* export interface StateData {
  eventFlow: EventFlows
  eventFunction: EventFunctions
  eventLog: string | number
} */

export const logEvents = (flow, func, result) => {
  const tsdMgr = getTSDManager()
  const dm = getDeviceManager()
  if (tsdMgr) {
    const [header, stateData] = tsdMgr.addEventLog(flow, func, result)

    if (stateData) {
      const record = { ...header, ...stateData.stateData }
      if (dm) {
        dm.sendNamedLogMsg('eventLog', embrossDeviceManager.TraceLevels.LOG_TRACE, JSON.stringify(record))
      } else {
        console.log('record:', record)
      }
    }
  }
}

export const sessionStart = () => {
  const tsdMgr = getTSDManager()
  const sessionId = uuidv4()
  if (tsdMgr) {
    tsdMgr.sessionId = sessionId
    logEvents(EventFlows.Application, EventFunctions.TransactionStart, `TransactionID ${sessionId}`)
  }
}

export const sessionEnd = (reason) => {
  const tsdMgr = getTSDManager()
  if (tsdMgr) {
    logEvents(EventFlows.Application, EventFunctions.TransactionEnd, 'Transaction ended')
    tsdMgr.sessionId = ''
  }
}
