import React, { useState, Fragment, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { IconButton } from 'components'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import useMedia from 'hooks/useMedia'

const SkipAct = (props) => {
  const intl = useIntl()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const themeContext = useContext(ThemeContext)

  return (
    <>
      <IconButton
        id={'skipBtn'}
        cssName={props.skipBtnStyle}
        color={themeContext.SkipButton.color}
        bgColor={themeContext.SkipButton.bgColor}
        width={themeContext.SkipButton.width[ratioKey]}
        height={themeContext.SkipButton.height[ratioKey]}
        disable={props.disableAction}
        onClick={props.skipAction || null}
        fontSize={themeContext.SkipButton.fontSize[ratioKey]}
        buttonPosition={themeContext.SkipButton.buttonPosition}
        iconLocation={themeContext.SkipButton.buttonPosition}
        padding={themeContext.SkipButton.padding}
        text={props.skipBtnText}
        icon={themeContext.SkipButton.icon}
        iconColor={themeContext.SkipButton.iconColor}
        borderRadius={themeContext.SkipButton.borderRadius[ratioKey]}
        border={themeContext.SkipButton.border ? themeContext.SkipButton.border : ''}
      />
    </>
  )
}
SkipAct.propTypes = {
  skipBtnStyle: PropTypes.string,
  skipAction: PropTypes.func
}

export default SkipAct
