import { store } from 'main'
import { appLog, logEvent } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import {
  PASSENGER_VALIDATION_STATUS,
  ServiceNames,
  TransitionCodes,
  FunctionCodes,
  APISTransitions,
  END_TXN_REASON
} from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { logEvents, EventFlows, EventFunctions } from 'utils/appEventLogger'
import { replacer, isNotEmpty, navigate } from 'utils/helper'

const generateValidationList = (checkinContract) => {
  const passengerDetails = checkinContract.passengers.map((pax) => {
    const paxDocChecked = pax.paxTxnStatus.documentCheckedOK
    return {
      ordinal: pax.ordinal,
      firstName: pax.firstName,
      lastName: pax.lastName,
      dateOfBirth: pax.dateOfBirth,
      docCheck: paxDocChecked ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.UNKNOWN,
      bioCheck: paxDocChecked ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.UNKNOWN,
      visaCheck: paxDocChecked ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.UNKNOWN,
      passportScanned: paxDocChecked
    }
  })

  return passengerDetails
}

export const updateApis = () => {
  // navigate('pleaseWait', { messageId: 'PleaseWaitUpdateApis' })

  const sessionInfo = store.getState().sessions.sessionInfo
  const req = {
    sessionInfo: {
      etsSessionID: sessionInfo.etsSessionID,
      emhaSessionID: sessionInfo.emhaSessionID
    },
    paxOrdinal: 0,
    forDomesticAPIS: false,
    action: 'START',
    checkinContractReturn: true,
    currentClientTime: new Date().getTime(),
    asyncRequested: true
  }

  return (dispatch) => {
    appLog(TraceLevels.LOG_TRACE, 'updateApis() dispatch request ...', JSON.stringify(req))
    navigate('pleaseWait')
    dispatch(updateSessions('updateETSRequest', true))

    fetchWithTimeout('updateApis/', req)
      .then((json) => {
        appLog(TraceLevels.LOG_TRACE, 'updateApis() receive response ... ', JSON.stringify(json))
        dispatch(updateSessions('updateETSRequest', false))
        if (isNotEmpty(json.etsResponse)) {
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }

          // update validatedPax result in store
          const generatedPaxList = generateValidationList(json.etsResponse.checkinContract)
          dispatch(updateLocalData('updateValidatePaxResult', generatedPaxList))
          //

          // update current passenger from passengerAPIS[0]
          const passengerOrdinal = json.etsResponse.passengerAPIS[0].paxOrdinal
          dispatch(updateLocalData('appFlow', 15))
          dispatch(updatePaxValidationData('initialPaxData'))
          const currentPax = generatedPaxList.filter((pax) => pax.ordinal === passengerOrdinal)
          dispatch(updatePaxValidationData('updateCurrentPassenger', currentPax[0]))

          switch (json.transition) {
            case TransitionCodes.PAX_VALIDATION:
              appLog(TraceLevels.LOG_EXT_TRACE, 'updateApis() receive response then dispatch getTravelDocs page. ')
              logEvents(EventFlows.DocScan, EventFunctions.DocScanStart, 'Initiated')

              // update validatedPax result in store
              const generatedPaxList = generateValidationList(json.etsResponse.checkinContract)
              dispatch(updateLocalData('updateValidatePaxResult', generatedPaxList))
              //

              // update current passenger from passengerAPIS[0]
              const passengerOrdinal = json.etsResponse.passengerAPIS[0].paxOrdinal
              dispatch(updateLocalData('appFlow', 15))
              dispatch(updatePaxValidationData('initialPaxData'))
              const currentPax = generatedPaxList.filter((pax) => pax.ordinal === passengerOrdinal)
              dispatch(updatePaxValidationData('updateCurrentPassenger', currentPax[0]))

              navigate('ScanDocuments', 21)
              break
            case TransitionCodes.CUSTOM_1:
              appLog(TraceLevels.LOG_EXT_TRACE, 'updateApis() receive response then dispatch TVSTakePhoto page. ')
              navigate('TVSTakePhoto', 21)
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'updateApis() receive response, call for transitions.')
          }
        } else if (json.error) {
          goToFetchGenericError('updateApis', json, dispatch)
        }
      })
      .catch((error) => {
        appLog(TraceLevels.LOG_ALERT, 'error:', error)
        // handleUnHandledError('updateApis', error)
        handleFetchErrors(error, 'updateApis()', 'error')
      })
  }
}
