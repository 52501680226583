import { deviceIds } from 'constants/Constants'
import device from 'devices/device'
import BaggageScaleListener from 'listeners/baggageScaleListener'
import { TraceLevels } from 'constants/Constants'

/**
 *  Device representing a Scale
 *  @extends {Device}
 */
export default class BaggageScale extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'BaggageScale'
    /**
     * Device id - deviceIds.BAGGAGE_SCALE {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.BAGGAGE_SCALE
    this.setListener(new BaggageScaleListener())
  }

  /**
   *  Asynch call to read data
   */
  read() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'read().')
      this.socket.sendRequest(this.deviceId, 'read')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'read() exception: ' + e)
    }
  }
  /**
   *  Sync call to set scale weight unit
   *  @param {String} unit of the unit can be “KG” or “G” or “LB”
   *  Call this function before any read or enable functions
   */
  setWeightUnit(unit) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'setWeightUnit')
      this.socket.sendRequest(this.deviceId, 'setWeightUnit', unit)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setWeightUnit() exception: ' + e)
    }
  }
  /**
   *  Async call to get scale max weight
   *  
   *  @return  a string value representing the weight (in the units specified)
   */
  getMaxWeight() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getMaxWeight')
      this.socket.sendRequest(this.deviceId, 'getMaxWeight')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getMaxWeight() exception: ' + e)
    }
  }

  /**
   * Show the camera live stream.
   * @param {String} mode - show setup string
   * @example <PreviewCentered>False</PreviewCentered><PreviewHeight>400</PreviewHeight><PreviewWidth>520</PreviewWidth><PreviewXPos>380</PreviewXPos><PreviewYPos>345</PreviewYPos>
   */
  show(mode) {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'show(): ' + mode)
      this.socket.sendCommand(this.deviceId, 'show', mode)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'show() exception: ' + e)
    }
  }
   
  /**
  * Hide the camera live stream.
  */
  hide() {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'hide(): ')
      this.socket.sendCommand(this.deviceId, 'hide')
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'hide() exception: ' + e)
    }
  }
}  