/**
 * ETS Request and Response - checkin
 */

import { store, history } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { ServiceNames, TransitionCodes, FunctionCodes, PRINT_DOC_TYPE } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer, isNotEmpty, navigate } from 'utils/helper'
import { genericCommand } from './genericCommand'
import { updateLocalData } from 'actions/localActions'

function buildGetPrintStream(documentType) {
  return {
    sessionInfo: {
      etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
      emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
    },
    //    'transitionToEcho': null,
    jsonExtend: null,
    currentClientTime: 0,
    asyncRequested: false,
    documentType: documentType,
  }
}

export function getPrintStream(documentType) {
  // appLog(TraceLevels.LOG_EXT_TRACE, 'getPrintStream() request begins ... ')
  let request = buildGetPrintStream(documentType)
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'getPrintStream() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate('pleaseWait')
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('/getPrintStream/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'getPrintStream() receive response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))

          // go to print bagtag page
          dispatch(updateResponses('updateBagtagResources', json.etsResponse.docPrintResources))
          dispatch(updateResponses('updatePassengerDocuments', json.etsResponse.docPrintStreams))
          if (documentType === PRINT_DOC_TYPE.BAG_TAG) {
            navigate('PrintBagtag', 21)
          } else if (documentType === PRINT_DOC_TYPE.SBD_RECEIPT) {
            navigate('PrintReceipt', 8)
          } else if (documentType === PRINT_DOC_TYPE.HEAVY_BAG_TAG) {
            navigate('PrintHeavytag', 21)
          }
        } else {
          goToFetchGenericError('getPrintStream', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'getPrintStream()', 'error')
      })
  }
}
