import { updateLocalData } from 'actions/localActions'
import { Animation } from 'components'
import { PageSubTitle, PageTitle } from 'components/styledComponents'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { Footer } from 'layouts/footer'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, playSound } from 'main'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, navigate, populateItineraryInfo } from 'utils/helper'
import { appLog } from 'utils/Logger'

const isQuitRequired = false
const Intrusion = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const appManager = getDeviceManager().getAppManager()
  const auroraHeight = useMedia(null, [730, 730, 0], 1050)
  const isLandscape = useCheckLandscape()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const mismatchBPError = useSelector((state) => state.localData.mismatchBPError)
  const locale = useSelector((state) => state.localData.locale)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const itineraryData = itineraryInfo ? populateItineraryInfo(itineraryInfo) : null
  const themeContext = useContext(ThemeContext)
  const location = useLocation()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(Intrusion.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    let url = '/'
    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        if (location.state && location.state.welcomeFlag) {
          SBDAppManager.setAppFlow(0)
          url = config.firstScreen
        } else {
          SBDAppManager.setAppFlow(2)
          SBDAppManager.setSQStart(-1)
          url = 'PutBagOnBelt'
        }
        break
      case 'buttonQuit':
        appManager.available()
        break
      default:
        url = '/'
    }

    appLog(TraceLevels.LOG_EXT_TRACE, '(Intrusion.js) handleActions() - url = "' + url + '"')
    navigate(url)
  }

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const intrusionMsg = [
      intl.formatMessage(messages.Error_ConveyorIntrusion_Title),
      intl.formatMessage(messages.Error_ConveyorIntrusion_SubTitle)
    ]
    const screenId = getScreenId(location.pathname)
    const accDef = {
      pathName: 'Intrusion',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [
          { id: 'page-content', textId: 'OneDynamicText', textParameters: [intrusionMsg.join('. ')] },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    dispatch(updateLocalData('updateBagWeight', '0'))
    if (mismatchBPError) {
      dispatch(updateLocalData('updateMismatchBPError', false))
    }
  }, [mismatchBPError])

  useEffect(() => {
    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(Intrusion.js) componentWillUnmount() - UNMOUNTED ...')
      SBDAppManager.resetIntrusionDuringDispatch()
    }
  }, [])

  const AnimationIntrusion = isLandscape
    ? themeContext.AnimationLandscape.Intrusion
    : themeContext.AnimationPortrait.Intrusion

  const animationSection = (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${AnimationIntrusion}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.Error_ConveyorIntrusion_Title)}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.Error_ConveyorIntrusion_SubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={false}
      isLangRequired={true}
      confirmBtnText={intl.formatMessage(messages.buttonOk)}
      confirmAction={handleActions}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>

  // return (
  //   <div className="page-container">
  //     <AuroraPanel height={auroraHeight} width={'100%'}>
  //       <PageContent flexFlow={'column'}>
  //         <PageTitle alignItems="center" flexFlow="row">
  //           {errIcon}
  //           {intl.formatMessage(messages.ErrorTitle)}
  //         </PageTitle>
  //         <ItineraryInfo itineraryData={itineraryData} />
  //         <PageContent flexFlow={isLandscape ? 'row' : 'column'}>
  //           <PageSubContent flex={1} width={contentWidth}>
  //             {isLandscape ? animationSection : textSection}
  //           </PageSubContent>
  //           <PageSubContent minHeight={'500px'} flex={2} width={contentWidth}>
  //             {isLandscape ? textSection : animationSection}
  //           </PageSubContent>
  //         </PageContent>
  //       </PageContent>
  //     </AuroraPanel>
  //     <PageFooter>
  //       <Footer
  //         isQuitRequired={false}
  //         isBackRequired={false}
  //         isSkipRequired={false}
  //         isConfirmRequired={true}
  //         langBtnStyle={'btn-green-up'}
  //         isLangRequired={true}
  //         confirmBtnText={intl.formatMessage(messages.buttonOk)}
  //         confirmBtnStyle={'btn-green-up'}
  //         confirmAction={handleActions}
  //       />
  //     </PageFooter>
  //   </div>
  // )
}

export default Intrusion
