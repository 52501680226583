import { deviceIds, PromiseCodes, TraceLevels, ApplicationState, AppStateEventType } from 'constants/Constants'
import ApplicationManagerListener from 'listeners/applicationManagerListener'
import device from 'devices/device'

/**
 *  Device representing an AppManager
 *  @extends {Device}
 */
export default class AppManager extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.isActive = false
    /**@private*/
    this.kioskId = null
    /**@private*/
    this.cussKioskId = null
    /**@private*/
    this.smKioskId = null
    /**@private*/
    this.location = null
    /**@private*/
    this.airportCode = null
    /**@private*/
    this.clientType = 'KIOSK'
    /**@private*/
    this.appVersion = ''
    /**@private*/
    this.locale = 'en'
    /**@private*/
    this.currentState = 2
    /**@private*/
    this.status = null
    /**@private*/
    this.isOK = null
    /**@private*/
    this.name = 'Application Manager'
    /**@private*/
    this.accessibleMode = false
    /**@private*/
    this.deviceId = deviceIds.APPLICATION_MANAGER
    /**@private*/
    this.listener = null
    /* this is callback function and set by application */
    /**@private*/
    this.onChange = null
    /**@private*/
    this.onActive = null
    /**@private*/
    this.onSocketOpened = null
    /**@private*/
    this.setListener(new ApplicationManagerListener())
    /**@private*/
    this.socket.addListener(this.listener)
    /**@private*/
    this.prom = null
    /**@private*/
    this.pTimer = null
    /**@private*/
    this.companyCode = null
    /**@private*/
    this.appName = null
    /**@private*/
    this.resetKioskAlert = false
    /**@private*/
    this.kioskTypeId = ''
    /**@private*/
    this.kioskType = ''
    /**@private*/
    this.vendor = null
    /**@private*/
    this.config = []
    /**@private*/
    this.smgrStationInId = ''
    this.appInUse = false
  }

  /**
   * get current state
   * @return {string} CUSS APP STATE
   */
  get CurrentState() {
    return this.currentState
  }

  /**
   * Set customer present
   * @param {boolean} value true / false indicates application is used
   */
  set AppInUse(value) {
    this.appInUse = value
  }

  /**
   * get customer present
   * @return {boolean} true / false indicates customer present
   */
  get AppInUse() {
    return this.appInUse
  }

  /**
   * Set onChange callback
   * @param {function(event: Event)} callback for on Change event
   */
  set OnChange(callback) {
    this.onChange = callback
  }

  /**
   * Set onActive callback
   * @param {function(event: Event)} callback for on Active event
   */
  set OnActive(callback) {
    this.onActive = callback
  }

  /**
   * Set on socket opened callback
   * @param {function(event: Event)} callback for on socket opened event
   */
  set OnSocketOpened(callback) {
    this.onSocketOpened = callback
  }

  /**
   * Set active state
   * @param {boolean} value true when app is active
   */
  set Active(value) {
    this.isActive = value
    if (this.onActive && typeof this.onActive === 'function' && value) this.onActive({ key: 'isActive', value: value })
  }

  /**
   * Set kiosk id
   * @param {string} value kiosk id
   */
  set KioskId(value) {
    if (this.kioskId !== value) {
      this.kioskId = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'kioskId', value: value })
      }
    }
  }
  /**
   * Set CussKioskId
   * @param {string} value cussKioskId
   */
  set CussKioskId(value) {
    if (this.cussKioskId !== value) {
      this.cussKioskId = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'cussKioskId', value: value })
      }
    }
  }
  /**
   * Set SmKioskId
   * @param {string} value smKioskId
   */
  set SmKioskId(value) {
    if (this.smKioskId !== value) {
      this.smKioskId = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'smKioskId', value: value })
      }
    }
  }

  /**
   * Set airport code
   * @param {string} value airport code
   */
  set AirportCode(value) {
    if (this.airportCode !== value) {
      this.airportCode = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'airportCode', value: value })
      }
    }
  }
  /**
   * Set location
   * @param {string} value location
   */
  set Location(value) {
    if (this.location !== value) {
      this.location = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'location', value: value })
      }
    }
  }
  /**
   * Set company code
   * @param {string} value company code
   */
  set CompanyCode(value) {
    if (this.companyCode !== value) {
      this.companyCode = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'companyCode', value: value })
      }
    }
  }

  /**
   * Set application name
   * @param {string} value application name
   */
  set AppName(value) {
    if (this.appName !== value) {
      this.appName = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'appName', value: value })
      }
    }
  }

  /**
   * Set vendor
   * @param {string} value vendor
   */
  set Vendor(value) {
    if (this.vendor !== value) {
      this.vendor = value
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: 'vendor', value: value })
      }
    }
  }

  /**
   * Set config
   * @param {string} value key
   * @param {string} value value
   */
  set Config({ key, value }) {
    const duplicate = this.config.filter((config) => config.key === key && config.value === value)
    if (duplicate.length === 0) {
      this.config.push({ key: key, value: value })
      if (this.onChange && typeof this.onChange === 'function') {
        this.onChange({ key: key, value: value })
      }
    }
  }

  /**
   * Sets the application state to INITIALIZE
   * initialize()  moved to CUSSAppLink,
   */
  initialize() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'AppManager.initialize()')
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'initialize')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'AppManager.initialize() exception: ' + e)
    }
  }

  /**
   * Sets the application state to AVAILABLE
   */
  available() {
    this.appInUse = false
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'send command available()')
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'available')
      if (this.dm.TSDMan) {
        this.dm.TSDMan.addAppStateEvent(AppStateEventType.APP_AVAILABLE)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'available() exception: ' + e)
    }
  }

  /**
   * Sets the application state to UNAVAILABLE
   */
  unavailable() {
    this.appInUse = false
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'unavailable()')
      if (this.resetKioskAlert) {
        this.kioskAlert('', false) // set the kiosk alert light to default
      }
      this.Active = false
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'unavailable')
      if (this.dm.TSDMan) {
        this.dm.TSDMan.addAppStateEvent(AppStateEventType.APP_UNAVAILABLE)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'unavailable() exception: ' + e)
    }
  }

  /**
   * Gets the KioskID.  This ID may be the Kiosk ID returned by the underlying platform implementation or
   * a mapped kiosk id.  In the case that this ID is mapped the application can get the real id by using
   * getActualCUSSKioskID()
   * @return {String}	(asynch) returns the KioskID
   */
  getKioskId() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getKioskID(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getKioskID')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getKioskID() exception: ' + e)
    }
  }

  /**
   * Gets the Kiosk ID as determined by the system manager. This can be the normal kiosk ID, the station name
   * can be prepended to it, or other changes as determined by the system manager.
   * @return {String}	(asynch) the system manager's version of the kiosk ID.
   */
  getSystemManagerKioskId() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getSystemManagerKioskID(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getSystemManagerKioskID')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getSystemManagerKioskID() exception: ' + e)
    }
  }

  /**
   * Gets the Location.  This Location may be the Airport Location returned by the underlying platform implementation
   * @return {String}	(asynch) returns the Airport Location
   */
  getLocation() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getLocation(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getLocation')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getLocation() exception: ' + e)
    }
  }

  /**
   *  Retrieve the actual CUSS kiosk ID
   *  @return {String} (asynch) the actual CUSS kiosk ID
   */
  getActualCUSSKioskId() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getActualCUSSKioskID(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getActualCUSSKioskID')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getActualCUSSKioskID() exception: ' + e)
    }
  }
  /**
   *  Send an application log to the platform implementation.
   *  @param {int} level level to log.  One of:
   *  AppManager.APP_BUSINESS (100)
   *  AppManager.APP_TECHNICAL (200)
   *  AppManager.APP_SECURITY (300)
   *  @param {String} text text to log.  For kiosk manager app log events log to APP_BUSINESS
   *  with the text line defined as
   *  CDS_APPLOG,ID,szText.
   *  For kiosk manager system log events log to APP_BUSINESS with the text line defined as
   *  CDS_SYSLOG,ID,szText.
   */
  sendApplicationLog(level, text) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'sendApplicationLog(): ' + level + ', ' + text)
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationLog', level, text)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'sendApplicationLog() exception: ' + e)
    }
  }

  /**
   * send lighting pattern to the platform
   * @param {string} [zone]   One of:
   *    predefined application zone in the ILLUMINATION.CFG e.g. "AREA_APP_ZONE1"
   *    "OFF" - turn off all predefined zones to the off pattern  -- default
   * @param {string} [pattern]   One of:
   *    predefined pattern in the ILLUMINATION.CFG e.g. "APP_PATTERN1"
   *    "OFF" - Turns off (predefined pattern BLACK) the predefined zone  -- default
   *    a free-form lighting pattern e.g.: "YELLOW|2|BLACK|3|GREEN|2|BLACK|43"   Blinks yellow/green in predefined zone 1 (default is to loop)
   */
  appLighting(zone, pattern) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'appLighting(): ' + zone + ', ' + pattern)
      if (zone === undefined || zone === 'OFF')
        // OFF - default
        this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationLog', 100, 'CDS_APPLOG,LIGHTING,OFF')
      else if (pattern === undefined || pattern === 'OFF')
        // set pattern to "OFF"
        this.socket.sendCommand(
          deviceIds.APPLICATION_MANAGER,
          'sendApplicationLog',
          100,
          'CDS_APPLOG,LIGHTING,' + zone + ',OFF'
        )
      else
        this.socket.sendCommand(
          deviceIds.APPLICATION_MANAGER,
          'sendApplicationLog',
          100,
          'CDS_APPLOG,LIGHTING,' + zone + ',' + pattern
        )
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'appLighting() exception: ' + e)
    }
  }

  /**
   * send lighting pattern to the platform alert area
   * @param {string} pattern   One of:
   *    predefined pattern in the ILLUMINATION.CFG e.g. "APP_PATTERN1"
   *    "OFF" - Turns off (predefined pattern BLACK) the predefined zone  -- default
   *    a free-form lighting pattern e.g.: "YELLOW|2|BLACK|3|GREEN|2|BLACK|43"   Blinks yellow/green in predefined zone 1 (default is to loop)
   * @param {boolean} on if false then the reset the lights in the alert area
   */
  kioskAlert(pattern, on) {
    this.logMessage(TraceLevels.LOG_TRACE, 'kioskAlert(): ' + pattern + ' on: ' + on)
    if (on)
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationLog', 100, 'CDS_APPLOG,ALERT,' + pattern)
    else {
      //this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationLog', 100, 'CDS_APPLOG,ALERT,' + pattern + ',OFF')
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationLog', 100, 'CDS_APPLOG,ALERT,NO')
    }
  }

  /**
   *  Retrieve the airport code from the underlying platform implementation
   *  @return {String} (asynch)the airport code
   */
  getAirportCode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getAirportCode(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getAirportCode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getAirportCode() exception: ' + e)
    }
  }

  /**
   *  Sets the current state of the application
   *  @param {int} state The state of the application as defined by the underlying platform implementation
   */
  setState(state) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'setState(): ' + state)
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'setState', state)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setState() exception: ' + e)
    }
  }

  /**
   *  Retrieve the current state of the application
   *  @param {Boolean} returnPromise - optional if true then the Promise will returned
   *  @param {Boolean} emulateActive - optional if true then resolve the promise with currentState
   *  @return {int} (asynch) The state of the application as defined by the underlying platform implementation
   */
  getCurrentState(returnPromise, emulateActive) {
    try {
      this.logMessage(
        TraceLevels.LOG_TRACE,
        'getCurrentState(): ' + (returnPromise === undefined ? '' : returnPromise + ' ' + this.promiseTimeout)
      )
      let pr = null
      if (returnPromise === true) {
        if (this.prom == null) {
          pr = new Promise((resolve, reject) => {
            this.prom = [resolve, reject]
            this.pTimer = setTimeout(() => {
              if (this.prom) {
                this.prom[PromiseCodes.REJECT](PromiseCodes.TIMEOUT)
                this.prom = null
              }
            }, this.promiseTimeout)
          })
        } else {
          // should not happen create a temp promise and reject it
          pr = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(PromiseCodes.UNEXPECTED)
            }, 10)
          })
        }
      }
      if (emulateActive) {
        if (returnPromise === true) {
          setTimeout(() => {
            this.prom[PromiseCodes.RESOLVE](this.currentState)
          }, 10)
        } else {
          return this.currentState
        }
      } else {
        this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getCurrentState')
      }
      if (returnPromise === true) {
        return pr
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getCurrentState() exception: ' + e)
    }
    return null
  }

  // Accessibility methods
  /**
   *  Confirms accessible mode for the application
   */
  confirmAccessibleMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'confirmAccessibleMode(): ')
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'confirmAccessibleMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'confirmAccessibleMode() exception: ' + e)
    }
  }
  /**
   *  Completes accessible mode for the application
   */
  completeAccessibleMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'completeAccessibleMode(): ')
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'completeAccessibleMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'completeAccessibleMode() exception: ' + e)
    }
  }
  /**
   *  Retrieve the pending accessible mode
   *  @return {boolean} (asynch) The pending accessible mode flag
   */
  isPendingAccessibleMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isPendingAccessibleMode(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'isPendingAccessibleMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isPendingAccessibleMode() exception: ' + e)
    }
  }
  /**
   *  Retrieve the accessible mode
   *  @return {boolean} (asynch) The accessible mode flag
   */
  isInAccessibleMode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'isInAccessibleMode(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'isInAccessibleMode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'isInAccessibleMode() exception: ' + e)
    }
  }

  /**
   * Gets the CompanyCode code retrieved from CUSSAppLink websupport.properties file.
   * @return {String}	(asynch) returns the companyCode code
   */
  getCompanyCode() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getCompanyCode(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getCompanyCode')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getCompanyCode() exception: ' + e)
    }
  }

  /**
   * Gets the Application Name retrieved from CUSSAppLink websupport.properties file.
   * @return {String}	(asynch) returns the application name
   */
  getAppName() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getAppName(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getAppName')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getAppName() exception: ' + e)
    }
  }

  /**
   *  set active when active for PSAM mode
   */
  setActiveWhenActive() {
    this.appInUse = true
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'setActiveWhenActive(): ')
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'setActiveWhenActive')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setActiveWhenActive() exception: ' + e)
    }
  }

  /**
   *  Reset kiosk alter flag
   *  @param {Boolean} flag - reset kiosk alert when calling unavailable
   */
  setResetKioskAlert(flag) {
    this.resetKioskAlert = flag
  }

  /**
   *  Send app version to CUSSAppLink
   *  @param {string} ver - application version
   *  @param {Boolean} [addCussAppLinkVer=false] - add CUSSAppLink version to the app version
   */
  sendVersion(ver, addCussAppLinkVer = false) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'sendVersion(): ' + ver)
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendVersion', ver, addCussAppLinkVer)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'sendVersion() exception: ' + e)
    }
  }
  /**
   *  get isActive state
   */
  getIsActive() {
    return this.isActive
  }

  /**
   * The method applicationTransfer() is used by the application to initiate an application transfer.
   * This functionality is defined in CUSS Addendum A.1.4.
   * @param {string} targetAppCompanyCode - the company code of the application to transfer to
   * @param {string} targetAppApplicationName - the application name of the application to transfer to
   * @param {string} transferData - Data to transfer to the target application
   * @return {string} (asynch) '0' success other fail
   */
  applicationTransfer(targetAppCompanyCode, targetAppApplicationName, transferData) {
    try {
      this.logMessage(
        TraceLevels.LOG_TRACE,
        'applicationTransfer(): ' + targetAppCompanyCode + ', ' + targetAppApplicationName
      )
      this.socket.sendRequest(
        deviceIds.APPLICATION_MANAGER,
        'applicationTransfer',
        targetAppCompanyCode,
        targetAppApplicationName,
        transferData
      )
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'applicationTransfer() exception: ' + e)
    }
  }

  /**
   * The method sendApplicationStatusReasonIndicator is used by the application to send a reason for the application status
   * This functionality is defined in CUSS Addendum A.1.42
   * @param {string} text - reason in plain text
   */
  sendApplicationStatusReasonIndicator(text) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'sendApplicationStatusReasonIndicator(): ' + text)
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationStatusReasonIndicator', text)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_TRACE, 'sendApplicationStatusReasonIndicator() exception: ' + e)
    }
  }

  /**
   * The method sendApplicationStatusTransactionIndicator is used by the application send an end transaction reason
   * @param {string} text - transaction ending reason in plain text
   */
  sendApplicationStatusTransactionIndicator(text) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'sendApplicationStatusTransactionIndicator(): ' + text)
      this.socket.sendCommand(deviceIds.APPLICATION_MANAGER, 'sendApplicationStatusTransactionIndicator', text)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_TRACE, 'sendApplicationStatusTransactionIndicator() exception: ' + e)
    }
  }

  /**
   * Gets the vendor id.
   * @return {String} (asynch) returns the vendor id
   */
  getVendor() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getVendor(): ')
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getVendor')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getVendor() exception: ' + e)
    }
  }

  /**
   * Gets the config parameter retrieved from CUSSAppLink websupport.properties file.
   * @param {string} key - the config parameter key
   * @return {String} returns the config parameter value, empty string when not found
   */
  getConfig(key) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getConfig(): ' + key)
      //console.log('appManager.js getConfig(): ' + JSON.stringify(key))
      this.socket.sendRequest(deviceIds.APPLICATION_MANAGER, 'getConfig', key)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getConfig() exception: ' + e)
    }
  }

  /**
   *  Set kiosk type id - to be read from websupport.properties
   *  @param {String} kioskTypeId - the id of the kiosk type in websupport.properties
   */
  setKioskTypeId(kioskTypeId) {
    this.kioskTypeId = kioskTypeId
  }
}
