import { addPayment } from 'actions/etsTransactions/addPayment'
import { DynamicImage, PageSubContent, PageSubTitle, PageText, Spacer } from 'components/styledComponents'
import { MEDIATYPES } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCardReader from 'hooks/useCardReader'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, history, playSound } from 'main'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { formatCurrency, getScreenId } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { Footer } from '../footer'

const PaymentCardSwipe = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const paymentInfo = useSelector((state) => state.responses.overweightPayment)
  const [results, setResults] = useState(paymentInfo ? paymentInfo : { totalAmount: 0 })
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const cardReaderCallback = (mediaData) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(paymentCardSwipe.js)  successful receive mediaData')
    dispatch(addPayment(false, mediaData, results.totalAmount, results.paymentObj.currencyCode))
  }
  const [enable, disable, passportReaderCallback, popupContainer] = useCardReader(cardReaderCallback)

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(paymentCardSwipe.js)  enable card reader ...')
    enable()

    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(paymentCardSwipe.js)  disable card reader ...')
      disable()
    }
  }, [])

  const handleActions = (e) => {
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(Welcome.js) handleClick() ... target: ' + e.currentTarget.id)
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'testBtn': // TODO test mode only ... to be removed
          let mediaData = {
            mediaTrackData: {
              track1: 'B4012001021000605^SMITH/JOHN                 ^211210100000000100010001000000',
              track2: '4012001021000605=21121010000001010001',
              track3: null,
              mediaType: MEDIATYPES.CC
            }
          }
          dispatch(addPayment(false, mediaData, results.totalAmount, results.currencyCode))
          break
        default:
          history.push(e.currentTarget.id)
      }
    }
  }
  const testData = [
    {
      id: 'testBtn',
      text: 'TEST',
      cssName: 'test-button-up',
      handler: handleActions
    }
  ]

  const animationSize = useMedia(null, [440, 440, 420], 420)
  const animationSection = (
    <>
      <DynamicImage
        imageName={`animations/embross/${sbdModel}/paymentCardInsert.gif`}
        cssName={'emb_animation_drawbox'}
        width={animationSize}
        height={animationSize}
      />
      <DynamicImage imageName={`creditcards.png`} cssName={'emb_animation_drawbox'} width={500} height={100} />
    </>
  )

  const title = intl.formatMessage(messages.PaymentCardSwipeTitle, { amount: formatCurrency(results.totalAmount) })
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'PaymentCardSwipe',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicText',
            textParameters: [title]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const textSection = (
    <PageSubContent width={'100%'} justifyContent={'center'}>
      <PageSubTitle alignItems="center" height={100}>
        {intl.formatMessage(messages.AcceptCards)}
      </PageSubTitle>
      <Spacer height={30} />
      <PageSubTitle>- {intl.formatMessage(messages.AMEX)}</PageSubTitle>
      <PageSubTitle>- {intl.formatMessage(messages.MasterCard)}</PageSubTitle>
      <PageSubTitle>- {intl.formatMessage(messages.VISA)}</PageSubTitle>
    </PageSubContent>
  )

  const header = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <PageText>{title}</PageText>
    </div>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        testData={testData}
      />
    </>
  )

  const popupSection = <>{popupContainer.removeCard}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    // { header, topSection: textSection, bottomSection: animationSection, footer, popupSection },
    { header, topSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default PaymentCardSwipe
