import React, { useRef, useState } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getDeviceManager } from 'main'
import { appLog, logEvent } from '../utils/Logger'
import { goToLocalGenericError } from 'utils/helper'
import { faceTrackingOnEvent } from 'devices/callbacks'
import {logEvents, sessionStart, EventFlows, EventFunctions} from 'utils/appEventLogger'

//import { sendPhotoMatch } from 'actions/etsTransactions/sendPhotoMatch'
function useFaceTracking(sendResult, showStreambyStream = true, eventHandler) {
  const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
  const [liveStreamUrl, setLiveStreamUrl] = useState(null)
  const faceTrackingCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTrackingOnEvent() is called: ' + event.key + ' value: ' + event.value)
    if (eventHandler) {
      eventHandler(event)
    }
    switch (event.key) {
      case 'faceAbsent':
        appLog(TraceLevels.LOG_TRACE, 'Event: ' + event.key + 'value:' + event.value)
        break
      case 'facePresent':
        appLog(TraceLevels.LOG_TRACE, 'Event: ' + event.key + 'value:' + event.value)
        break
      case 'faceDescriptor':
        //dispatch(sendPhotoMatch(event.value.split(',')[1]))
        logEvent('FaceMatch,photo captured')
        sendResult(event.value)
        break
      case 'enable':
        appLog(TraceLevels.LOG_TRACE, 'Event: enable: ' + event.value)
        // show stream only when enable call successfully and config.showPicture set to true
        logEvent('FaceMatch,enabled')
        if (config.showPicture && event.value === '0' && showStreambyStream) {
          // faceTrackingShow()
          faceTrackingGetStreamingURL()
        }
        break
      case 'show':
        appLog(TraceLevels.LOG_TRACE, 'Event: show rc: ' + event.value)
        break
      case 'hide':
        appLog(TraceLevels.LOG_TRACE, 'Event: hide rc: ' + event.value)
        break
      case 'dataError':
        //      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  dataError: ' + event.value)
        logEvent('FaceMatch,data error')
        // 1. hide camera stream
        // 2. show popup with instruction and Retry button
        // 3. if Retry button pressed (or timeout) then show camera (hide popup)
        /*this.faceTracking.hide()
        this.faceTracking.disable()*/
        break
      case 'getStreamingURL':
        appLog(TraceLevels.LOG_TRACE, 'Event:  getStreamingURL: ' + event.value)
        if (event.value !== '') {
          setLiveStreamUrl(event.value)
        } else {
          faceTrackingShow()
        }
        break
      case 'statusChange':
        if (event.key === 'statusChange' && event.value[1]) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'callback: face tracking is disconnected')
          logEvent('FaceMatch,camera disconnected')
          goToLocalGenericError('Callbacks', ErrCodes.CAMERA_ERROR, 'FaceTrackingError', 'END_TXN')
        } else {
          console.log('callbacks: face tracking status:', event.value[0])
        }
        break
      default:
        appLog(TraceLevels.LOG_TRACE, 'Event: unhandledEvent : ' + event.key + ' event value: ' + event.value)
        break
    }
  }
  const faceTrackingEnable = (bestShotTime, dg2Image) => {
    console.log('faceService call enable')
    faceTracking.OnDeviceEvent = faceTrackingCallback
    faceTracking.enable(bestShotTime, dg2Image)
  }
  const faceTrackingDisable = () => {
    console.log('faceService call disable')
    logEvent('FaceMatch,disabled')
    if (faceTracking) {
      faceTracking.OnDeviceEvent = faceTrackingOnEvent
      faceTracking.disable()
    }
  }
  const faceTrackingShow = () => {
    console.log('faceService call show')
    if (faceTracking) {
      faceTracking.show(config.faceTrackingModeWelcome)
      faceTracking.focusApp()
    }
  }
  const faceTrackingHide = () => {
    console.log('faceService call hide plus remove callback')
    if (faceTracking) {
      faceTracking.hide()
      faceTracking.OnDeviceEvent = null
    }
  }

  const faceTrackingGetStreamingURL = () => {
    if (faceTracking) {
      faceTracking.getStreamingURL()
    }
  }

  return [
    faceTrackingEnable,
    faceTrackingDisable,
    faceTrackingShow,
    faceTrackingHide,
    faceTrackingCallback,
    liveStreamUrl
  ]
}
export default useFaceTracking
