import React, { useState, useRef, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useIntl } from 'react-intl'
import { Popup, IconButton, AuroraPanel } from 'components'
import { PopupTitle, PopupSubTitle, Spacer, PageSubContent, DynamicImage } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { getAccessibilityManager } from '../../main'
import useMedia from 'hooks/useMedia'

/* interface PopupTimerProps {
  timeoutManger: TimeoutManager
  popupTimeoutDuration?: number
  timeoutDuration?: number
  isShowTimeoutPopup?: boolean
  onBtnClicked: (answer: string) => void
  yesMsg?: string
  noMsg?: string
  textMsg?: string
  subTextMsg?: string
  buttonGap?: number
  bgColor?: string
  buttonYesBgColor?: string
  buttonNoBgColor?: string
} */

const PopupTimer = (props) => {
  const intl = useIntl()
  const { timeoutManger, timeoutDuration, onBtnClicked, bgColor, buttonYesBgColor, buttonNoBgColor } = props
  const isLandscape = useCheckLandscape()
  const timeoutTimer = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const themeContext = useContext(ThemeContext)
  const textMsg = props.textMsg ? props.textMsg : 'screen timeout'
  const subTextMsg = props.subTextMsg ? props.subTextMsg : 'Do you still want to continue?'
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const popupTimeoutDuration = props.popupTimeoutDuration ? props.popupTimeoutDuration : 5
  const isShowTimeoutPopup = props.isShowTimeoutPopup ? props.isShowTimeoutPopup : true
  const accMgr = getAccessibilityManager()
  const screenMessage = `${textMsg}. ${subTextMsg}`
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        { id: 'timeoutLabel', textId: 'OneDynamicText', textParameters: [screenMessage] },
        { id: 'timeoutNo', text: intl.formatMessage(messages.buttonTimeoutNo), buttonId: 'timeoutNo' },
        { id: 'timeoutYes', text: intl.formatMessage(messages.buttonTimeoutYes), buttonId: 'timeoutYes' }
      ]
    }
  }

  /* const accMgr = getAccessibilityManager()
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        {
          id: 'popupTimerContent',
          text: `${textMsg}. ${subTextMsg}`
        },
        { id: 'yesNeedTimeBtn', text: intl.formatMessage(messages.buttonYes), buttonId: 'yesNeedTimeBtn' },
        { id: 'noNeedTimeBtn', text: intl.formatMessage(messages.buttonNo), buttonId: 'noNeedTimeBtn' }
      ]
    }
  } */

  useEffect(() => {
    const handleTimeout = () => {
      console.log('handleTimeout from PopupTimer')
      console.log('from popupTimer => IsShowTimeoutPopup:', isShowTimeoutPopup)
      if (isShowTimeoutPopup) {
        timeoutManger.stopTimer()
        setIsVisible(true)
        clearPopupTimer()
        startPopupTimer()
        accMgr.buildPopupGroup(true, accDef) // accessibility
      } else {
        if (onBtnClicked) {
          onBtnClicked('NO')
        }
      }
    }
    timeoutManger.setTimeoutCallback(handleTimeout)
  }, [isShowTimeoutPopup])

  const startPopupTimer = () => {
    timeoutTimer.current = setTimeout(onPopupTimeout, popupTimeoutDuration * 1000)
    console.log(
      'Timer startPopupTimer() ... START (POPUP) ... ',
      timeoutTimer,
      ' ... duration ... ',
      popupTimeoutDuration
    )
  }

  const clearPopupTimer = () => {
    if (timeoutTimer.current) {
      clearTimeout(timeoutTimer.current)
      timeoutTimer.current = null
    }
  }

  const onYesBtnClicked = () => {
    clearPopupTimer()
    // reset page timer
    timeoutManger.resetTimer(timeoutDuration)
    setIsVisible(false)
    //accMgr.buildPopupGroup(false, { action: 'QUIT' }) // accessibility
    accMgr.buildPopupGroup(false) // accessibility
    if (onBtnClicked) {
      onBtnClicked('YES')
    }
  }

  const onNoBtnClicked = () => {
    clearPopupTimer()
    timeoutManger.stopTimer()
    setIsVisible(false)
    //accMgr.buildPopupGroup(false, {}) // accessibility
    accMgr.buildPopupGroup(false) // accessibility
    if (onBtnClicked) {
      onBtnClicked('NO')
    }
  }

  const onPopupTimeout = () => {
    clearPopupTimer()
    timeoutManger.stopTimer()
    setIsVisible(false)
    //accMgr.buildPopupGroup(false, {}) // accessibility
    accMgr.buildPopupGroup(false) // accessibility
    if (onBtnClicked) {
      onBtnClicked('NO')
    }
  }

  return (
    <Popup visible={isVisible} cssDialogName="popup-dialog">
      <PageSubContent width="100%">
        {themeContext.Popup.showTimeoutImage ? (
          <PageSubContent padding={themeContext.Popup.imagePadding[ratioKey]}>
            <DynamicImage imageName={`question-mark-icon.svg`} width={200} height={200} />
          </PageSubContent>
        ) : (
          ''
        )}
        <div id="timeoutLabel" tabIndex="0">
          <PageSubContent flexFlow={'column'} padding="10px">
            <PopupTitle
              justifyContent={themeContext.TextJustifyContent}
              fontSize={themeContext.Popup.titleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              {textMsg}
            </PopupTitle>
            <PopupSubTitle
              justifyContent={themeContext.TextJustifyContent}
              fontSize={themeContext.Popup.subTitleFontSize[ratioKey]}
              fontWeight={themeContext.Popup.fontWeight}
            >
              {subTextMsg}
            </PopupSubTitle>
          </PageSubContent>
        </div>
        <Spacer height={'50px'} />
        <PageSubContent flexFlow={themeContext.Popup.buttonFlow}>
          <IconButton
            id={'timeoutNo'}
            color={themeContext.CancelButton.color}
            bgColor={themeContext.CancelButton.bgColor}
            width={themeContext.CancelButton.width[ratioKey]}
            height={themeContext.CancelButton.height[ratioKey]}
            //disable={props.disableAction}
            buttonPosition={themeContext.CancelButton.buttonPosition}
            iconLocation={themeContext.CancelButton.buttonPosition}
            padding={themeContext.CancelButton.padding}
            onClick={onNoBtnClicked}
            fontSize={themeContext.CancelButton.fontSize[ratioKey]}
            text={intl.formatMessage(messages.buttonTimeoutNo)}
            icon={themeContext.CancelButton.icon}
            iconColor={themeContext.CancelButton.iconColor}
            borderRadius={themeContext.CancelButton.borderRadius[ratioKey]}
            border={themeContext.CancelButton.border ? themeContext.CancelButton.border : ''}
          />
          {themeContext.Popup.buttonFlow === 'row' ? (
            <Spacer width={themeContext.Popup.buttonGap} />
          ) : (
            <Spacer height={themeContext.Popup.buttonGap} />
          )}
          <IconButton
            id={'timeoutYes'}
            color={themeContext.BackButton.color}
            bgColor={themeContext.BackButton.bgColor}
            width={themeContext.BackButton.width[ratioKey]}
            height={themeContext.BackButton.height[ratioKey]}
            //disable={props.disableAction}
            buttonPosition={themeContext.BackButton.buttonPosition}
            iconLocation={themeContext.BackButton.buttonPosition}
            padding={themeContext.BackButton.padding}
            onClick={onYesBtnClicked}
            fontSize={themeContext.BackButton.fontSize[ratioKey]}
            text={intl.formatMessage(messages.buttonTimeoutYes)}
            icon={themeContext.BackButton.icon}
            iconColor={themeContext.BackButton.iconColor}
            borderRadius={themeContext.BackButton.borderRadius[ratioKey]}
            border={themeContext.BackButton.border ? themeContext.BackButton.border : ''}
          />
        </PageSubContent>
      </PageSubContent>
    </Popup>
  )
}

export default PopupTimer
