import { useState, useEffect } from 'react'
const checklandscape = () => (window.screen.availWidth > window.screen.availHeight ? true : false)

const useCheckLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(checklandscape())
  const handler = () => {
    setIsLandscape(checklandscape())
  }

  useEffect(() => {
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return isLandscape
}
export default useCheckLandscape
