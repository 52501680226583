import { DynamicImage, PageContent, PageSubContent, PageSubTitle, PageTitle, Spacer } from 'components/styledComponents'
import { getBuildAccessibility, history } from 'main'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId } from 'utils/helper'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { Footer } from 'layouts/footer'

const displayItinerary = false
const TVSPhotoRetry = () => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const location = useLocation()
  const faceServiceUsedFor = useSelector((state) => state.settings.faceServiceUsedFor)
  const locale = useSelector((state) => state.localData.locale)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const [photoErrorTitleText, setPhotoErrorTitleText] = useState(intl.formatMessage(messages.TVSPhotoRetryTitle))
  const [photoErrorSubTitleText, setPhotoErrorSubTitleText] = useState(
    intl.formatMessage(messages.TakePhoto_SubTitle_Error)
  )
  const [photoErrorText, setPhotoErrorText] = useState('')
  /* useEffect(() => {
    setTimeout(() => {
      if (faceServiceUsedFor === 'RECOGNITION') {
        history.push('recognition')
      } else {
        history.push('takePhoto')
      }
    }, config.takePhotoPrepareTime * 1000)
  }, []) */
  useEffect(() => {
    const subtitle =
      location.state?.reason && location.state.reason.includes('MASK_DETECTED')
        ? intl.formatMessage(messages['Error_MASK_DETECTED'])
        : location.state.reason.includes('GLASSES_DETECTED')
        ? intl.formatMessage(messages['Error_GLASSES_DETECTED'])
        : location.state.reason.includes('SPOOFED_FACE_DETECTED')
        ? intl.formatMessage(messages['Error_SPOOFED_FACE_DETECTED'])
        : ''
    setPhotoErrorSubTitleText(subtitle)
    // if (faceServiceUsedFor === 'RECOGNITION') {
    //   setPhotoErrorTitleText(intl.formatMessage(messages.RecognitionRetryTitle))
    // }
  }, [location.state?.reason, locale])

  const handleOkButton = () => {
    history.push('TVSTakePhoto')
  }

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const screenId = getScreenId(location.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale, photoErrorSubTitleText])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'TVSPhotoRetry',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        displayItinerary,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [photoErrorTitleText, photoErrorSubTitleText]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonRetry), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.TakePhoto.photoNotMatchImage}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {photoErrorTitleText}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {photoErrorSubTitleText}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmBtnText={intl.formatMessage(messages.buttonRetry)}
        confirmAction={handleOkButton}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default TVSPhotoRetry
