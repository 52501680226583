const acc_fr = {
  /************************************************************/
  /**            Common Component Speeches                   **/
  /************************************************************/
  // Language speaks in its language
  en: 'English',
  ru: 'русский',
  pt: 'Português',
  fr: 'Français',
  ar: 'العربية',
  sw: 'Kiswahili',
  de: 'Deutsch',
  zh: '中文',
  sv: 'Svenska',
  el: 'Eλληνικά',
  nl: 'Nederlands',
  tr: 'Türkçe',
  it: 'Italiano',
  fi: 'Suomi',
  ur: 'اردو',
  es: 'Español',
  ja: '日本語',
  ko: '한국어',
  'zh-Hant': '繁體中文',
  // common
  ButtonYes: '',
  ButtonNo: '',
  NavQuit: 'Quitter l’application',
  NavBack: 'Retourner à l’étape précédente',
  NavSkip: 'Omettre',
  NavNext: '',
  NavSelectLanguage: 'Sélectionner une langue',
  NavConfirm: 'Confirmer',
  NavContinue: 'Continuer',
  NavDone: '',
  EndOfHelp: "fin de l'aide",
  PlusInfant: '',
  DOB: 'Date de naissance',
  FirstName: '',
  LastName: '',
  FlightNumber: '',
  Selected: '',
  UnSelected: '',
  ScanBoardingPass: '',
  PlaceBagOnBelt: '',
  AgentHelp: '',
  /************************************************************/
  /**                     Device Help                        **/
  /************************************************************/
  // FileName: Device Help Messages
  Barcode_Reader: '',
  Card_Reader: '',
  Passport_Reader: '',
  BagTag_Printer: '',
  Aea_BagDrop: '',
  Cuss_Accessibility_keypad: '',
  /************************************************************/
  /**            Basic Flow Accessibility Speeches           **/
  /************************************************************/
  // Headset inserted
  Application: "Bienvenue à la borne d'enregistrement WESTJET.",
  SelectLanguage: '',
  NavKeypadHelp: '',
  NotSupportAccess: '',
  StartApp: '',
  // Timeout popup
  TimeoutPopupDescription: '',
  TimeoutPopupYes: 'Oui, appuyez sur Entrée pour revenir à l’étape précédente',
  TimeoutPopupNo: 'Non, appuyez sur Entrée pour mettre fin à la transaction en cours',
  // Exit popup
  ExitPopupDescription:
    'Vous avez choisi d’annuler votre transaction. Êtes-vous certain? Cliquez sur Oui ou Non pour poursuivre.',
  ExitPopupYes: 'Oui, appuyez sur Entrée pour mettre fin à la transaction en cours',
  ExitPopupNo: 'Non, appuyez sur Entrée pour revenir à l’étape précédente',
  // Language popup
  LanguageSwitchPopupTitle:
    'Veuillez sélectionner votre langue préférée. Vous pouvez choisir l’une de deux langues, l’anglais ou le français. ' +
    'Appuyez sur la flèche vers le bas pour commencer, puis utilisez les flèches pour faire une sélection et enfin appuyez ' +
    'sur Entrée pour confirmer.',
  // MISC
  OneDynamicText: '',
  TwoDynamicText: '',
  /************************************************************/
  /**           Accessibility Speeches per Screen            **/
  /************************************************************/
  // FileName: 6.2 WELCOME SCREEN
  WelcomeScreenAllPrompt: '',
  WelcomeScreenBPPrompt: '',
  WelcomeScreenBTPrompt: '',
  WelcomeScreenDescription: '',
  // FileName: 6.5 Passenger Selection for bag drop
  PassengerSelectionScreenPrompt: '',
  NavigateSelectPassenger: '',
  SelectAllPassengers: 'Appuyez sur Entrée pour sélectionner tous les invités',
  SelectedAllPassengers: 'Tous les invités ont été sélectionnés. Appuyez sur la flèche vers le bas pour continuer',
  PassengerSelectionScreenDescription: '',
  // FileName: 6.6 Scan Boarding Pass
  ScanBoardingPassScreenPrompt: '',
  PassengerAlreadyScannedBP: '',
  // FileName: Confirm Total Weight within Pool Limits
  ConfirmTotalWeightTitle: '',
  ConfirmTotalWeightYesBtn: '',
  ConfirmTotalWeightNoBtn: '',
  // FileName: 6.14 Enter Number of Bag Tags to Print. {0} = each passenger, all passengers
  BagEntryScreenPrompt: '',
  BagEntryNavigatePassenger: '',
  BagEntryTotalBagTags: '',
  // FileName: 6.15 Bags Entry - Head of pool.
  BagEntryScreenHeadOfPoolPrompt: '',
  BagEntryNavigateHeadOfPool: '',
  BagEntryNavigateHeadOfPoolInc: '',
  BagEntryNavigateHeadOfPoolDec: '',
  BagEntryScreenDescription: '',
  // FileName: 6.16 Print Bag Tags
  PrintBagTagsScreenPrompt: '',
  // FileName: 6.17 Put Bag on Belt
  PutBagOnBeltScreenPrompt: '',
  // FileName: 6.18 Bag Processing
  BagProcessingScreenPrompt: '',
  // FileName: 6.19 Heavy Label Prompt
  PrintHeavytagsScreenPrompt: '',
  // FileName: 6.21 Put next bag on belt
  PutNextBagOnBelt: '',
  NoMoreBags: '',
  // FileName: 6.22 Claim Receipt Printing [TBD]
  ClaimReceiptPrintingScreenPrompt: '',
  // FileName: Device Helps
  HelpBardeLocation: '',
  HelpBeltLocation: '',
  // FileName: GenericErrors.js
  GenericErrorsScreenPrompt: '',
  FatalErrorsMessage: '',
  // FileName: ClosingRemarks.js
  ClosingRemarksScreenPrompt: '',
}

export default acc_fr
