/**
 * ETS Request and Response - addPayment
 *
 * {
    "sessionInfo":{
        "etsSessionID":"testKiosk1495736682546",
        "emhaSessionID":"testKiosk14957366825461495736682706"
    },
    "jsonExtend":null,
    "carrierCode":null,
    "currentClientTime":0,
    "asyncRequested":false,
    "terminalID":null,
    "paymentCard":null,
    "paymentDetail":{
        "statusCode":null,
        "authCode":null,
        "hostErrorCode":null,
        "auditNo":null,
        "transactionDate":null,
        "transactionTime":null,
        "amount":240.0,
        "referenceNo":null
    },
    "cvvCode":null,
    "payByCash":false,
    "trackData":{
        "track1":"B4000000000000000^O'CONNOR-SMITH/JOHN JACK",
        "track2":"4000000000000000=130833301",
        "track3":"",
        "mediaType":"CC",
        "isEncrypted":false
    }
  }
 */

import { store, history, getSummaryStore } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { ServiceNames, TransitionCodes, FunctionCodes } from 'constants/Constants'
//import { StateIds } from 'constants/Errors'
import { updateError } from 'actions/commonActions'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { transitions } from 'actions/etsTransactions/transitions'
import { isNotEmpty, replacer } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'

function buildAddPayment(payCash, mediaData, amount, currency) {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
      },
      jsonExtend: null,
      carrierCode: null,
      currentClientTime: 0,
      asyncRequested: false,
      terminalID: null,
      paymentCard: null,
      paymentDetail: {
        statusCode: null,
        authCode: null,
        hostErrorCode: null,
        auditNo: null,
        transactionDate: null,
        transactionTime: null,
        amount: amount,
        currency: currency,
        referenceNo: null,
      },
      cvvCode: null,
      payByCash: payCash,
    },
    mediaData
  )
}

export function addPayment(payCash, mediaData, amount, currency) {
  const summaryStore = getSummaryStore()
  appLog(TraceLevels.LOG_EXT_TRACE, 'addPayment() request begins ... ')
  let request = buildAddPayment(payCash, mediaData, amount, currency)
  let url = null
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'addPayment() dispatch request ... ' + JSON.stringify(request, replacer))
    url = Object.assign({}, location, {
      pathname: 'pleaseWait',
      state: {
        messageId: 'pleaseWaitAddPayment',
      },
    })
    // history.push(url)
    history.push('pleaseWait')

    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('/addPayment/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'addPayment() receive response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }

        if (isNotEmpty(json.etsResponse)) {
          summaryStore.updateBagData(summaryStore.currentBagtag, 'payment', true)
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))

          let url = ''
          switch (json.transition) {
            case TransitionCodes.PAYMENT_SUMMARY:
              appLog(TraceLevels.LOG_EXT_TRACE, 'addPayment() receive response, dispatch payment summary page. ')
              if (json.etsResponse.totalAmount > 0) {
                // update payment summary in store
                let paymentObj = Object.assign(
                  {},
                  { paymentItems: json.etsResponse.paymentItems },
                  { currencyCode: json.etsResponse.currencyCode },
                  { totalAmount: json.etsResponse.totalAmount },
                  { paymentSummary: json.etsResponse.paymentItemSummary }
                )
                dispatch(updateResponses('updatePayment', paymentObj))
                // display payment summary page
                url = Object.assign({}, { pathname: 'paymentSummary' })
                history.push(url)
              } else {
                // end payment process
                dispatch(identifyService(FunctionCodes.SET_COMPLETE, ServiceNames.ADD_PAYMENT))
              }
              break
            case TransitionCodes.PAYMENT_RECEIPT:
              // dispatch(updateResponses('updateReceipt', json.etsResponse.receiptPrintStream))
              // dispatch(updateResponses('updateReceiptResources', json.etsResponse.resources))
              // url = Object.assign({}, { pathname: 'printReceipt' })
              // history.push(url)
              history.push('DisplayPaymentReceipt')
              break
            case TransitionCodes.PAYMENT_INVOICE:
              dispatch(updateResponses('updateInvoice', json.etsResponse.receiptPrintStream))
              dispatch(updateResponses('updateInvoiceResources', json.etsResponse.resources))
              url = Object.assign({}, { pathname: 'printInvoice' })
              history.push(url)
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'addPayment() receive response, call for transitions.')
              dispatch(transitions(json.transition, json.etsResponse))
          }
        } else if (json.error) {
          if (json.error.errorCode === 'ERR_PAY_RETRY') {
            let location = {
              state: {
                from: 'paymentSummary',
                msgs: json.error.screenMsgs ? json.error.screenMsgs : '',
              },
            }
            let url = Object.assign({}, location, { pathname: 'paymentRetry' })
            appLog(TraceLevels.LOG_EXT_TRACE, 'addPayment() -> ERR_PAY_RETRY, transition to paymentRetry)')
            history.push(url)
          } else {
            goToFetchGenericError('addBags', json, dispatch)
          }
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'addPayment()', 'genericErrors')
      })
  }
}
