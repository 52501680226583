/************************************************************/
/**           EKC Store Customized Update Actions          **/
/************************************************************/
export const CustomActions = {
  UPDATE_CUSTOM_DATA: 'UPDATE_CUSTOM_DATA',
  UPDATE_CUSTOM_DATA_ELEMENT: 'UPDATE_CUSTOM_DATA_ELEMENT',
  INITIAL_CUSTOM_DATA: 'INITIAL_CUSTOM_DATA',
  CLEAR_CUSTOM_DATA: 'CLEAR_CUSTOM_DATA',
  UPDATE_TVS_PHOTO: 'UPDATE_TVS_PHOTO',
  UPDATE_BARCODE: 'UPDATE_BARCODE'
}
