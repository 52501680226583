import React, { useEffect, useState, useRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { getLanguageCodeFromId } from 'utils/helper'
import './clock.scss'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import { useSelector } from 'react-redux'

const defaultClockTimeStyle = {
  fontWeight: 400,
  fontSize: '25px',
  display: 'flex'
}

const defaultClockDateStyle = {
  fontWeight: 400,
  fontSize: '18px',
  display: 'flex'
}

const Clock = (props) => {
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const hourType = themeContext.Header.hourType ? themeContext.Header.hourType : 12
  const serverTime = useSelector((state) => state.localData.serverTime)
  const themeClockTimeStyle = themeContext?.clockTimeStyle ? themeContext?.clockTimeStyle[ratioKey] : undefined
  const locale = props.locale ? props.locale : 'en'
  const themeClockDateStyle = themeContext?.clockDateStyle ? themeContext?.clockDateStyle[ratioKey] : undefined

  const clockTimeStyle = {
    ...defaultClockTimeStyle,
    ...themeClockTimeStyle
  }

  const clockDateStyle = {
    ...defaultClockDateStyle,
    ...themeClockDateStyle
  }

  const getDateObject = (dateString) => {
    let dateObj = null
    let partDate = []
    let partTime = []
    let step1 = dateString.split('T')
    if (step1.length === 2) {
      partDate = step1[0].split('-')
      partTime = step1[1].split(':')
      if (partDate.length === 3 && partTime.length === 3) {
        dateObj = new Date(
          parseInt(partDate[0]),
          parseInt(partDate[1]),
          parseInt(partDate[2]),
          parseInt(partTime[0]),
          parseInt(partTime[1]),
          parseInt(partTime[2])
        )
      }
    }
    return dateObj
  }
  const interval = props.showSeconds ? 1000 : 60000
  const showTimeOption = props.showSeconds ?? false
    ? {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: hourType === 12 ? 'h11' : 'h23'
      }
    : /* {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: hourType === 12 ? 'h11' : 'h23'
      } */
        {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          //timeZone: "America/Los_Angeles",
        };
  const [currentDate, setCurrentDate] = useState(new Date())
  const intervalRef = useRef()
  const intl = useIntl()

  const tick = () => {
    if (currentDate) {
      setCurrentDate(new Date(currentDate.getTime() + interval))
    }
  }

  useEffect(() => {
    intervalRef.current = setInterval(tick, interval)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [currentDate])

  useEffect(() => {
    if(serverTime)
      setCurrentDate(new Date(serverTime))
  }, [serverTime])

  return (
    <div className="clock-wrapper" style={{ width: props.width }}>
      <div className={'clock-time'} id={'a_clock'} tabIndex={0} style={clockTimeStyle}>
        {new Intl.DateTimeFormat(getLanguageCodeFromId(locale), showTimeOption).format(currentDate)}
      </div>
      <div className={'clock-date'} id={'a_clock'} tabIndex={0} style={clockDateStyle}>
        {new Intl.DateTimeFormat(getLanguageCodeFromId(locale), {
          dateStyle: 'medium' // full, long, medium, short
        }).format(currentDate)}
      </div>
    </div>
  )
}

export default Clock
