import { addBags } from 'actions/etsTransactions/addBags'
import { Button, EMBTable } from 'components'
import { PageContent, PageHeader, PageText, PageTitle } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getAccessibilityDevice, getAccessibilityManager } from 'main'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { calBaggageLimit, checkLandscape, clone, getScreenId, isNotEmpty } from 'utils/helper'
import { Footer } from '../footer'

const BagsEntry = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const tableRowParam = useMedia(
    null,
    [
      { maxTableRows: 3, rowHeight: 80 },
      { maxTableRows: 3, rowHeight: 65 },
      { maxTableRows: 6, rowHeight: 75 }
    ],
    { maxTableRows: 6, rowHeight: 55 }
  )
  const { formatMessage } = intl
  const paxBagDetails = useSelector((state) => state.localData.BagsEntryDetail)
  const passengerInfo = useSelector((state) => state.responses.itineraryInfo.passengerBagtagDetails)
  const pooling = useSelector((state) => state.localData.pooling)
  const [total, setTotal] = useState(0)
  const [paxBags, setPaxBags] = useState([])
  const paxBagsRef = React.useRef(paxBags)
  const [dataRows, setDataRows] = useState([])
  const [landscapeMode, screenWidth, screenHeight] = checkLandscape()
  const accessibility = getAccessibilityDevice()
  const accMgr = getAccessibilityManager()
  const themeContext = useContext(ThemeContext)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  const getPaxBags = (bagType) => {
    let passengerBags = []
    let totalQty = 0
    let priceObj = null
    paxBagDetails.map((pax, index) => {
      let allowObj = pax.baggageAllowance.filter((item) => item.baggageType === bagType)[0]
      let checkObj = pax.baggageChecked.filter((item) => item.baggageType === bagType)[0]
      if (pax.baggagePricingInfo) {
        priceObj = pax.baggagePricingInfo.baggagePricingElements.filter((item) => {
          return item.baggageType === bagType
        })[0]
      }
      const minMaxLimit = calBaggageLimit(pax, bagType)
      const minLimit = minMaxLimit['minLimit']
      const maxLimit = minMaxLimit['maxLimit']
      const displayAmout = minMaxLimit['displayAmount']

      /*// get updated quantity from store
      let updPaxBags = paxBaggageUpdate.filter(item => {
        return item.paxOrdinal === pax.paxOrdinal
      })[0]
      let updPaxBag = updPaxBags.baggageUpdate.filter(item => {
        return item.baggageType === bagType
      })[0]
      let updPaxBagQty = isNotEmpty(updPaxBag) ? updPaxBag.quantity : 0
      totalQty += updPaxBagQty*/

      const withInfant = passengerInfo.filter((passenger) => passenger.passenger.ordinal === pax.paxOrdinal)[0]
        .passenger.withInfant

      const passenger = passengerInfo.filter((passenger) => passenger.passenger.ordinal === pax.paxOrdinal)[0].passenger

      const name = `${passenger.firstName} ${passenger.lastName}`

      passengerBags.push({
        paxIndex: index,
        paxOrdinal: pax.paxOrdinal,
        withInfant: withInfant,
        baggageType: bagType,
        baggageUnit: allowObj.baggageUnit,
        // paxFullName: pax.paxFullName,
        paxFullName: name,
        allowance: isNotEmpty(allowObj)
          ? allowObj.xbagAllowance + allowObj.freeAllowance + allowObj.loyaltyAllowance
          : 0,
        minLimit: minLimit,
        maxLimit: maxLimit,
        quantity: displayAmout
      })
      totalQty += displayAmout
    })
    return { paxBags: passengerBags, total: totalQty }
  }
  const handleQtyChange = (e) => {
    //TODO: head of pool update
    let parts = e.currentTarget.id.split('_')
    let buttonName = parts[0]
    let paxOrdinal = parseInt(parts[1])
    let newPaxBags = clone(paxBagsRef.current, true)
    let selected = newPaxBags.filter((pax) => {
      return pax.paxOrdinal === paxOrdinal
    })[0]
    if (selected) {
      if (buttonName === 'plusBtn') {
        setTotal(total + 1)
        selected.quantity++
      } else {
        if (selected.quantity > 0) {
          setTotal(total - 1)
          selected.quantity--
        }
      }
    }
    setPaxBags(newPaxBags)
    paxBagsRef.current = newPaxBags
  }
  const formatBags = (updatedBags) => {
    const orgPaxBags = getPaxBags('REGULAR').paxBags
    let updatedPaxBags = null
    const diffPaxBags = []
    orgPaxBags.map((orgPaxBag) => {
      updatedPaxBags = updatedBags.filter((updatedPaxBag) => {
        return updatedPaxBag.paxOrdinal === orgPaxBag.paxOrdinal
      })[0]
      if (updatedPaxBags) {
        diffPaxBags.push({
          paxOrdinal: updatedPaxBags.paxOrdinal,
          baggageUpdate: [
            {
              baggageType: updatedPaxBags.baggageType,
              quantity: updatedPaxBags.quantity - orgPaxBag.quantity,
              baggageUnit: 'PIECE'
            }
          ]
        })
      }
    })
    return diffPaxBags
  }
  const columns = [
    {
      header: '', //config.bagPoolType === 'PerPax' ? formatMessage(messages.Passengers) : formatMessage(messages.BagtagToPrint),
      width: '330px',
      textAlign: 'flex-start'
    },
    {
      header: '',
      width: '75px'
    },
    {
      header: '',
      width: '60px'
    },
    {
      header: '',
      width: '75px'
    }
  ]

  /**  Accessibility sequence **/
  useEffect(() => {
    console.log('paxBags: ', paxBags)
    let rows = []
    if (paxBags && paxBags.length > 0) {
      paxBags.map((pax) => {
        let displayName = pooling ? (
          formatMessage(messages.AddBagHeadOfPool)
        ) : (
          <>
            {pax.paxFullName}
            <br />
            {pax.withInfant ? <span className={'bag_entry_infant_span'}>+Infant</span> : null}
          </>
        )

        rows.push({
          id: pax.paxOrdinal,
          data: [
            displayName,
            <Button
              id={'minusBtn_' + pax.paxOrdinal}
              key={'minusBtn_' + pax.paxOrdinal}
              onClick={handleQtyChange}
              cssName={'bag-change-button'}
              fontSize={80}
              disable={pax.quantity <= pax.minLimit}
            >
              {'-'}
            </Button>,
            pax.quantity,
            <Button
              id={'plusBtn_' + pax.paxOrdinal}
              key={'plusBtn_' + pax.paxOrdinal}
              onClick={handleQtyChange}
              cssName={'bag-change-button'}
              fontSize={80}
              disable={pax.quantity === pax.maxLimit}
            >
              {'+'}
            </Button>
          ]
        })
      })
      console.log('rows:', rows)
      setDataRows(rows)
    } else {
      console.log("Error: There is no Passenger's bag information, Why ?")
    }
    if (paxBags.length > 0) {
      const title = pooling ? 'BagEntryScreenHeadOfPoolPrompt' : 'BagEntryScreenPrompt'
      const accDef = {
        pathName: 'BagEntry',
        startIndex: 0,
        screenDescription: 'BagEntryScreenDescription',
        ...getButtonDisplayConfig({
          themeContext,
          screenId
        }),
        sequenceDef: {
          sequence: [{ id: 'a_title', textId: title, textParameters: [total] }]
        }
      }
      // Head of pool
      if (pooling) {
        let bagEntryInstruction = 'BagEntryNavigateHeadOfPoolInc'
        if (total >= paxBags[0].maxLimit) {
          bagEntryInstruction = 'BagEntryNavigateHeadOfPoolDec'
        } else if (total > 0) {
          bagEntryInstruction = 'BagEntryNavigateHeadOfPool'
        }
        accDef.sequenceDef.sequence.push({
          id: 'row_0',
          textId: bagEntryInstruction,
          textParameters: [total],
          isCounter: true,
          minusButtonId: 'minusBtn_0',
          plusButtonId: 'plusBtn_0'
        })
      } else {
        paxBags.map((pax) => {
          accDef.sequenceDef.sequence.push({
            id: 'row_' + pax.paxOrdinal,
            textId: 'BagEntryNavigatePassenger',
            textParameters: [pax.paxFullName],
            isCounter: true,
            minusButtonId: 'minusBtn_' + pax.paxOrdinal,
            plusButtonId: 'plusBtn_' + pax.paxOrdinal
          })
        })
      }
      accDef.sequenceDef.sequence.push(
        { id: 'totalBagTags', textId: 'BagEntryTotalBagTags', textParameters: [total] },
        { id: 'confirmBtn', textId: 'NavNext', buttonId: 'confirmBtn' },
        { id: 'langBtn', textId: 'NavSelectLanguage', buttonId: 'langBtn' }
      )
      accMgr.buildAccessibilityGroup(accDef, accessibility)
    }
  }, [accessibility, paxBags, total])

  useEffect(() => {
    const passengerBags = getPaxBags('REGULAR')
    setTotal(passengerBags.total)
    setPaxBags(passengerBags.paxBags)
    paxBagsRef.current = passengerBags.paxBags
  }, [])

  const header = <PageHeader>{intl.formatMessage(messages.BagtagPrint)}</PageHeader>
  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmAction={() => {
          console.log('selected:', paxBags)
          dispatch(addBags(formatBags(paxBags)))
        }}
        confirmBtnText={formatMessage(messages.buttonConfirm)}
      />
    </>
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" height={'60px'}>
        {formatMessage(messages.SelectBagNumber)}
      </PageTitle>

      <PageContent flexFlow="column">
        <EMBTable
          showHeader={false}
          maxDisplayRows={tableRowParam.maxTableRows}
          columns={columns}
          rows={dataRows}
          headerHeight={80}
          scrollbarGap={10}
          rowHeight={tableRowParam.rowHeight}
          rowFontSize={30}
          headFontSize={30}
        />
        <PageText minHeight={'60px'}>{formatMessage(messages.TotalBagtags, { totalBags: total })}</PageText>
      </PageContent>
    </>
  )
  //const bottomSection = <PageContent width={'100%'} justifyContent={'center'} flexFlow={'column'} />
  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: null, footer },
    { contentWidth: '100%', itineraryHeights: ['200px', '120px', '200px'] }
  )

  return <>{UIDisplay}</>
  /* return (
    <div className="page-container">
      <div className="page-content">
        <AuroraPanel isPanelRequired={landscapeMode} height={landscapeStyle.auroraHeight} showTopBorder={false}>
          <div id="a_title" tabIndex="0">
            <div className="page-title" id="main_title" tabIndex="0">
              <FormattedMessage {...messages.BagtagPrint} />
            </div>
            <div className="bag-entry-sub-title" id="sub_title" tabIndex="0">
              <FormattedMessage {...messages.SelectBagNumber} />
            </div>
          </div>
          <EMBTable
            maxDisplayRows={landscapeStyle.tableParam.maxDisplayRows}
            columns={columns}
            rows={rows}
            headerHeight={50}
            rowHeight={landscapeStyle.tableParam.rowHeight}
            rowFontSize={30}
            headFontSize={30}
          />
          <div className={'bottom-text'} id="totalBagTags" tabIndex="0">
            <FormattedMessage {...messages.TotalBagtags} />
            <div className="bag_entry_summary_total">{total}</div>
          </div>
        </AuroraPanel>
      </div>
      <Footer
        isQuitRequired={true}
        quitBtnStyle={'btn-red-up'}
        quitBtnText={formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmAction={() => {
          console.log('selected:', paxBags)
          props.addBags(formatBags(paxBags))
        }}
        confirmBtnText={formatMessage(messages.buttonNext)}
        confirmBtnStyle={'btn-green-up'}
        langBtnStyle={'btn-green-up'}
      />
    </div>
  ) */
}

export default BagsEntry
