import { Animation } from 'components'
import { PageContent, PageHeader, PageSubTitle } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useMedia from 'hooks/useMedia'
import { getAccessibilityDevice, playSound } from 'main'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { generateBP, getScreenId, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { parseBarcode } from 'utils/mediaParser'
import { Footer } from '../footer'
import { updateLocalData } from 'actions/localActions'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan } from 'main'
import { deviceIds, TraceLevels } from 'embross-device-manager'
const HandheldScanBT = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const aeaBagDrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const isLandscape = window.screen.availWidth > window.screen.availHeight ? true : false
  const themeContext = useContext(ThemeContext)
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const testNumber = useRef(1)
  const location = useLocation()
  const appFlow = useSelector((state) => state.localData.appFlow)
  const scannedCallback = (scannedValue) => {
    appLog(TraceLevels.LOG_TRACE, '(HandheldScanBT.js) received scanned value:', scannedValue)
    dispatch(updateLocalData('scannedBagtag', scannedValue))
    if (appFlow === 2) {
      /* appLog(TraceLevels.LOG_EXT_TRACE, '(HandheldScanBT.js) componentDidMount() - Enable SBD via getSBDAppMan...')
      getSBDAppMan().setSQStart(-1)
      getSBDAppMan().setPutBagStart(new Date().getTime()) */

      //DeviceActions.callDevice(AEA_BAGDROP, 'enable')

      if (config.callCR == 'Y' && config.isCUSSRequired) {
        appLog(TraceLevels.LOG_EXT_TRACE, '==> (HandheldScanBT.js) componentDidMount() - Enable Scanner CR')
        //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CR', -10000)
        aeaBagDrop.sendAEA('CR', -10000)
      }

      // check if bag is there - delay for config.minPutBag*1000
      //state.set("PutBagStart", (new Date()).getTime()

      if (config.isCUSSRequired) {
        appLog(TraceLevels.LOG_EXT_TRACE, '==> (HandheldScanBT.js) componentDidMount() - send SQ')
        aeaBagDrop.sendAEA('SQ', -10000)
      }
    }
    if (appFlow > 2 && appFlow <= 5) {
      navigate('BagProcessing')
    }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)

  const accessibility = useRef()
  useEffect(() => {
    let data = []
    let scannedPaxNames = ''
    accessibility.current = getAccessibilityDevice()
   /*  if (!itineraryInfo) {
      appLog(TraceLevels.LOG_EXT_TRACE, '>> (HandheldScanBT.js) componentDidMount() - itineraryInfo NOT AVAILABLE ...')
      navigate('error')
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '(HandheldScanBT.js) componentDidMount() - itineraryInfo AVAILABLE !!!')
      itineraryInfo.scanSequence.map((ord) => {
        for (let i = 0; i < itineraryInfo.passengerBagtagDetails.length; i++) {
          if (itineraryInfo.passengerBagtagDetails[i].passenger.ordinal === ord) {
            data.push({
              data: [
                itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
                  ' ' +
                  itineraryInfo.passengerBagtagDetails[i].passenger.lastName
              ]
            })
            scannedPaxNames +=
              itineraryInfo.passengerBagtagDetails[i].passenger.firstName +
              ' ' +
              itineraryInfo.passengerBagtagDetails[i].passenger.lastName +
              ','
          }
        }
      })
      console.log('data', data)
    } */
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(HandheldScanBT.js) call barcode enable function')
      enable()
      //turnLights('scanBarcode', true)
    }

    const screenId = getScreenId(location.pathname)
    let accDef = {
      pathName: 'HandheldScanBT',
      startIndex: 0,
      screenDescriptionId: 'HandheldScanBTScreen',
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      sequenceDef: {
        sequence: [
          { id: 'HandheldScanBTTitle', textId: 'HandheldScanBTTitle' },
          { id: 'HandheldScanBTSubTitle', textId: 'HandheldScanBTSubTitle' },
          { id: 'quitBtn', textId: 'NavQuit', buttonId: 'quitBtn' },
          { id: 'langBtn', textId: 'NavSelectLanguage', buttonId: 'langBtn' },
          { id: 'confirmBtn', textId: 'NavContinue', buttonId: 'confirmBtn' }
        ],
        nextSequence: ['confirmBtn']
      }
    }
    // props.updateAccessibility(AccessibilityActions.UPDATE_ACCESSIBILITY, accDef)
    if (accessibility.current.enabled) {
      accessibility.current.DeviceEvent = {
        key: 'componentDidMount',
        value: accDef
      }
    }
    return () => {
      disable()
      //turnLights('scanBarcode', false)
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(HandheldScanBT.js) handleClick() ... target: ' + e.currentTarget.id)

    /// TEST CODE

    let testPassager = null
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: '9998321570'
          })
          /* testPassager = config.testData[getNextPassenger()]
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            // value: 'M1MAGAR/RAVI          EHVDTE  DELBOMAI 0624 119Y017D0002 15D>5180OO0041BAI              2A09823046994170 AI                        N*50600000K0900'
            // value: 'M1SHIMA/GOYAL         EJ72S9  DELBOMAI 0624 119Y017D0002 15D>5180OO0041BAI              2A09823046994170 AI                        N*50600000K0900'
            value: generateBP(
              itineraryInfo.recordLocator,
              testPassager.firstName,
              testPassager.lastName,
              testPassager.from,
              testPassager.to,
              testPassager.airLine,
              testPassager.flightNumber
            )
          }) */
          console.log('test boarding pass is readable')
          break
        case 'buttonScanUnreadable':
          console.log('test boarding pass is unreadable')
          testPassager = config.testData[testNumber.current]
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: null
          })
          break
        case 'buttonScanNotInPNR':
          console.log('test boarding pass is not in current PNR')
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: generateBP('AAAAAA', 'firstName', 'lastName', 'AUH', 'YVR', 'NK', '1001')
          })
          break
        case 'buttonScanDuplicate':
          console.log('test boarding pass is duplicate')
          testPassager = config.testData[0]
          barcodeReaderCallback({
            key: 'barcodeReadInternal',
            value: generateBP(
              itineraryInfo.recordLocator,
              testPassager.firstName,
              testPassager.lastName,
              'AUH',
              'YVR',
              'NK',
              '1001'
            )
          })
          break
      }
    }
  }
  const testButtons = [
    {
      id: 'buttonScanBarcodeTEST',
      text: 'Test',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanUnreadable',
      text: 'Unreadable',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanNotInPNR',
      text: 'Not in PNR',
      handler: handleActions,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanDuplicate',
      text: 'Duplicate',
      handler: handleActions,
      cssName: 'test-button-up'
    }
  ]
  
  const header = <PageHeader id={'HandheldScanBTTitle'}>{intl.formatMessage(messages.HandheldScanBTTitle)}</PageHeader>
  const textSection = (
    <>
      <PageSubTitle id={'HandheldScanBTSubTitle'} alignItems="center" height={'60px'} fontSize="26px">
        {formatMessage(messages.HandheldScanBTSubTitle)}
      </PageSubTitle>
    </>
  )
  const animationBagPlacement = isLandscape
    ? themeContext.AnimationLandscape.BagPlacement
    : themeContext.AnimationPortrait.BagPlacement
  const imageLocation = `${themeContext.AnimationPath}/${sbdModel}/${animationBagPlacement}`
  
  const animationSection = (
    <PageContent padding="padding-top: 30px;">
    <Animation imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
    </PageContent>
  )
  const footer = (
    <Footer
      isQuitRequired={true}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={false}
      testData={testButtons}
    />
  )
  const contentWidth = { landscapeMode: '90%', portraitMode: '90%' }
  const popupSection = <>{popupContainer.retryMediaAccess}</>
  const { UIDisplay } = useUIBase(
    { header: animationSection, topSection: textSection, bottomSection: header, footer, popupContainer: popupSection },
    { contentWidth: contentWidth, displayItinerary: true, itineraryHeights: ['200px', '120px', '200px'] }
  )

  return <>{UIDisplay}</>
}

export default HandheldScanBT
