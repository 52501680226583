import React, { useRef, useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDeviceManager, history } from 'main'
import { logInfo, logDebug, logEvent } from 'utils/Logger'
import { DynamicImage } from 'components/styledComponents'
import { useIntl } from 'react-intl'
import { doQuit } from 'utils/appTransactions'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import { faceTrackingOnEvent } from 'devices/callbacks'
import { goToLocalError, toBoolean, toNumber } from 'utils/helper'
import { ErrorCodes, TransitionCodes, KIOSK_PHOTO_RESULT_CODE } from 'constants/Constants'
import { updateLocalData } from 'actions/localActions'
import { logEvents, sessionStart, EventFlows, EventFunctions } from 'utils/appEventLogger'
import useBaseFaceService, { baseFaceServiceSetup } from 'custom/hooks/useBaseFaceService'

export const imageCheck = (dg2ImageExist, compareScore, photoInfo) => {
  let retry = false
  let reason = []

  if (dg2ImageExist && compareScore !== null && compareScore < config.photoPassingScore) {
    // not match
    retry = true
    reason.push('MATCH_SCORE_FAIL')
  }

  if (photoInfo !== undefined) {
    for (const key in photoInfo) {
      switch (key) {
        case 'SpoofedFaceImage':
        case 'CapturedMask':
        case 'CapturedGlasses':
          logDebug(`photoInfo key: ${key}, value: ${toBoolean(photoInfo[key])}`)
          break
        case 'CapturedImageWidth':
        case 'CapturedImageHeight':
        case 'Acceptability':
        case 'Quality':
        case 'SpoofProbability':
          logDebug(`photoInfo key: ${key}, value: ${toNumber(photoInfo[key])}`)
          break
      }
    }

    if (config?.spoofCheck && toBoolean(photoInfo?.SpoofedFaceImage)) {
      retry = true
      reason.push('SPOOFED_FACE_DETECTED')
    }

    if (config?.maskCheck && toBoolean(photoInfo?.CapturedMask)) {
      retry = true
      reason.push('MASK_DETECTED')
    }

    if (config?.glassesCheck && toBoolean(photoInfo?.CapturedGlasses)) {
      retry = true
      reason.push('GLASSES_DETECTED')
    }
  }

  return {
    retry,
    reason: reason.join(',')
  }
}

const useFaceService = (faceCaptureCallback, faceCaptureErrorCallback, dg2ImageExist) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const retryCount = useSelector((state) => state.localData.errorPhotoAttempts) | 0
  /* ImageDetail {
      capturedPhoto: string
      compareScore: number | null
      photoInfo: PhotoDetectionInfo | undefined
    }
    PhotoDetectionInfo {
      SpoofedFaceImage?: string
      CapturedMask?: string
      CapturedGlasses?: string
      CapturedImageWidth?: string
      CapturedImageHeight?: string
      Acceptability?: string
      Quality?: string
      SpoofProbability?: string
    }
  */
  const handleSuccess = (imageDetail) => {
    if (imageDetail.photoInfo) {
      const photoInfo = imageDetail.photoInfo
      const qulity = photoInfo && photoInfo.Quality
      const acceptability = photoInfo && photoInfo.Acceptability
      const spoofProbability = photoInfo && photoInfo.SpoofProbability
      if (qulity && acceptability && spoofProbability) {
        logEvents(
          EventFlows.FaceCapture,
          EventFunctions.FaceCaptureQuality,
          `qulity: ${qulity}, acceptability: ${acceptability}, spoof probability: ${spoofProbability}`
        )
      } else {
        logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureQuality, '')
      }

      const { retry, reason } = imageCheck(dg2ImageExist, imageDetail.compareScore, imageDetail.photoInfo)

      if (retry) {
        failHandler(imageDetail.capturedPhoto, imageDetail.compareScore, reason)
      } else {
        faceCaptureCallback(imageDetail.capturedPhoto, imageDetail.compareScore || 0)
        logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureResult, 'Success')
      }
    } else {
      faceCaptureCallback(parseFloat(imageDetail))
      logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureResult, 'Success')
    }
  }

  const handleRetryAttempts = (status) => {
    logInfo('(useFaceService.js) error is called for ', status)
    switch (status) {
      case 'enable':
        failHandler(null, 0, KIOSK_PHOTO_RESULT_CODE.LOW_SCORE)
        break
      case 'dataError':
        failHandler(null, 0, KIOSK_PHOTO_RESULT_CODE.LOW_SCORE)
        break
      case 'timeout':
        failHandler(null, 0, KIOSK_PHOTO_RESULT_CODE.TIMEOUT)
        break
      case 'toggleView':
        failHandler(null, 0, KIOSK_PHOTO_RESULT_CODE.LOW_SCORE)
        break
    }
  }

  const setup = { ...baseFaceServiceSetup, enableTimer: true, defaultLiveURL: config.streamURL }

  const [action, value, faceTrackingCallback] = useBaseFaceService(
    {
      handleSuccess: handleSuccess,
      handleError: handleRetryAttempts
    },
    setup
  )

  const failHandler = (capturedPhoto, compareScore, reason) => {
    logInfo('face capture fail')
    dispatch(updateLocalData('updatePhotoAttempts', retryCount + 1))
    if (retryCount < config.mediaAccessRetryCount) {
      logInfo('take phone retry:' + retryCount)
      logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureRetry, '')
      const location = {
        pathname: 'TVSPhotoRetry'
      }
      history.push(location, { reason })
    } else {
      dispatch(updateLocalData('updatePhotoAttempts', 0))
      logInfo('exceed maximum retry attempts.')
      goToLocalError('useFaceService', reason, 'ExceedMaxPhotoRetry', TransitionCodes.END_TXN, null, 'error')
    }
    logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureResult, reason)
  }
  const popupContainer = <></>

  return [
    action.enable,
    action.disable,
    action.toggle,
    value.url,
    value.faceAppear,
    faceTrackingCallback,
    popupContainer,
    action.analyze
  ]
}
export default useFaceService
