import React, { useContext, useEffect, useState, useRef } from 'react'
import { useIntl, MessageDescriptor } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { DynamicImage } from 'components/styledComponents'

import './countdown.scss'
export const CountdownActions = {
  READY: 'READY',
  START: 'START',
  STOP: 'STOP'
}
/* export interface CountDownProps {
  width?: string
  height?: string
  backgroundImage?: string
  color?: string
  fontSize?: number
  margin?: string
  callback?: Function
  time?: number
  labelLeft?: MessageDescriptor
  labelRight?: MessageDescriptor
  overrideStyle?: Object
  action: CountdownActions
  renderCustomCounterDiv?: Function
} */

const Countdown = ({
  color, // For count number
  margin = '0 10px', // For count number
  fontSize = 30,
  width = '35px', // For counter div
  height, // For counter div
  backgroundImage,
  callback,
  time,
  labelLeft,
  labelRight,
  overrideStyle, // For counter div style
  action,
  renderCustomCounterDiv
}) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const initialTime = time ? time : 15
  const [timeLeft, setTimeLeft] = useState(initialTime)
  const [display, setDisplay] = useState(true)
  const intervalRef = useRef(0)
  const countStyleRef = useRef({})
  const divStyleRef = useRef({
    color: themeContext.PageSubTitleColor, // Color for label
    fontSize: fontSize,
    width: width,
    height: height
  })

  const setCountStyle = () => {
    let style = {}
    if (fontSize) {
      style = { ...style, fontSize: fontSize }
    }
    if (color) {
      style = { ...style, color: color }
    }
    if (margin) {
      style = { ...style, margin: margin }
    }
    return style
  }

  countStyleRef.current = setCountStyle()

  if (overrideStyle) {
    divStyleRef.current = { ...divStyleRef.current, ...overrideStyle }
  }
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])
  useEffect(() => {
    console.log('action received:', action)
    if (action === CountdownActions.START) {
      setTimeLeft(initialTime)
      setDisplay(true)
      intervalRef.current = window.setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1)
      }, 1000)
    } else if (action === CountdownActions.STOP) {
      setDisplay(false)
      clearInterval(intervalRef.current)
    }
  }, [action])

  useEffect(() => {
    if (action !== CountdownActions.READY && timeLeft === 0) {
      clearInterval(intervalRef.current)
      setDisplay(false)
      action = CountdownActions.READY
      if (callback) {
        callback()
        console.log('callback called from CountdownTimer ...')
      } else {
        console.log('timeout, but no callback was set, so no action will perform.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  if (renderCustomCounterDiv) {
    return renderCustomCounterDiv(timeLeft)
  }

  return (
    <>
      {display ? (
        backgroundImage ? (
          <DynamicImage imageName={backgroundImage} width={width} height={height} style={divStyleRef.current}>
            <div className="countdown-text" >
              {labelLeft ? <div>{intl.formatMessage(labelLeft)}</div> : null}
              <div style={countStyleRef.current}>{timeLeft}</div>
              {labelRight ? <div>{intl.formatMessage(labelRight)}</div> : null}
            </div>
          </DynamicImage>
        ) : (
          <div className="countdown-text" style={divStyleRef.current}>
            {labelLeft ? <div>{intl.formatMessage(labelLeft)}</div> : null}
            <div style={countStyleRef.current}>{timeLeft}</div>
            {labelRight ? <div>{intl.formatMessage(labelRight)}</div> : null}
          </div>
        )
      ) : (
        ''
      )}
    </>
  )
}

export default Countdown
