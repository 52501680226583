import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { injectIntl, FormattedMessage } from 'react-intl'
import { Footer } from '../../layouts/footer'
import { playSound, history } from 'main'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { TestButtons, AuroraPanel, Button, Spacer, Checkbox, CheckboxStatus } from 'components'
import EMBTable from 'components/EMBTable'
import { clone } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import { updateLocalData } from 'actions/localActions'
import { useIntl } from 'react-intl'
import { PageTitle, PageSubTitle, PageSubContent, PageHeader } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { ThemeContext } from 'styled-components'

const VerifyDocuments = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  // const columns = [
  //   {
  //     header: '',
  //     width: '300px'
  //   },
  //   {
  //     header: intl.formatMessage(messages.Bio),
  //     width: '150px'
  //   },
  //   {
  //     header: intl.formatMessage(messages.Doc),
  //     width: '150px'
  //   }
  // ]
  const columns = isLandscape
    ? [
        {
          header: '',
          width: '500px'
        },
        {
          header: '',
          width: '500px'
        }
      ]
    : [
        {
          header: '',
          width: themeContext.PassengerSelection.scrollAreaWidth[ratioKey]
        }
      ]

  // ***** TEST DATA ******
  //const validatePaxResult = config.verifyDocumentsTestData
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const newValidatePaxResult = validatePaxResult
    ? validatePaxResult.map((item) => {
        item.id = 'pax_' + item.ordinal.toString()
        item.text = item.firstName + ' ' + item.lastName
        return item
      })
    : []
  const [results, setResults] = useState(newValidatePaxResult)
  const [enableScan, setEnableScan] = useState(false)
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(true)
  const scannedCallback = (scannedValue) => {
    let paxStatusList = results.map((pax) => {
      return {
        ordinal: pax.ordinal,
        docCheckOk: pax.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
        faceMatch: pax.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED
      }
    })
    dispatch(updateLocalData('updateValidatePaxResult', results))
    dispatch(genericCommand('overrideStatus', { barcodeData: scannedValue, paxStatusList: paxStatusList }, null))
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)

  useEffect(() => {
    if (config.isCUSSRequired && enableScan) {
      appLog(TraceLevels.LOG_TRACE, '(VerifyDocuments.js) call barcode enable function')
      enable()
      //turnLights('scanBarcode', true)
    }
    return () => {
      if (enableScan) {
        disable()
        //turnLights('scanBarcode', false)
        console.log('component is unmounted.')
      }
    }
  }, [enableScan])

  useEffect(() => {
    let isAllChecked = true
    results.forEach((row) => {
      if (
        (row.bioCheck !== PASSENGER_VALIDATION_STATUS.PASSED &&
          row.bioCheck !== PASSENGER_VALIDATION_STATUS.NOT_REQUIRED) ||
        (row.visaCheck !== PASSENGER_VALIDATION_STATUS.PASSED &&
          row.visaCheck !== PASSENGER_VALIDATION_STATUS.NOT_REQUIRED)
      ) {
        isAllChecked = false
      }
    })
    // send out and enable for partial list of passenger (allow agent to scan whenever they want)
    setEnableScan(true)

    // send out and enable for all passengers
    // setEnableScan(isAllChecked)
  }, [results])

  useEffect(() => {
    const initData = validatePaxResult
      ? validatePaxResult.map((item, i) => {
          if (item.bioCheck && item.docCheck) {
            if (
              item.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED &&
              item.docCheck === PASSENGER_VALIDATION_STATUS.PASSED
            ) {
              item.status = CheckboxStatus.CHECKED_DISABLED
              setIsDisableConfirmButton(false)
            } else if (
              item.bioCheck === PASSENGER_VALIDATION_STATUS.FAILED ||
              item.docCheck === PASSENGER_VALIDATION_STATUS.FAILED
            ) {
              item.status = CheckboxStatus.UNCHECKED
            } else if (
              item.bioCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED ||
              item.docCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
            ) {
              // Previous logic: bioCheck not required => checked disabled, visaCheck not requied => unchecked
              item.status = CheckboxStatus.CHECKED_DISABLED
            } else {
              item.status = CheckboxStatus.UNCHECKED
            }
          }
          return item
        })
      : []
    setResults(initData)
  }, [])

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_TRACE,
      '(VerifyDocuments.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )

    playSound.beepOK()

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        // this.handleExitPage()
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          value: 'OverrideSBD' // EMBROSSTEST
        })
        // props.genericCommand('overrideStatus', { barcodeData: 'EMBROSSTEST', paxOrdinalList: [0] }, null)
        // props.identifyService(FunctionCodes.COMPLETED, ServiceNames.AGENT_CHECK)
        break
      case 'backBtn':
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          value: 'OverrideSBD' // EMBROSSTEST
        })
        break
      case 'errorBtn':
        // this.handleExitPage()
        barcodeReaderCallback({
          key: 'barcodeDamaged',
          value: null
        })
        break

      default:
        break
    }
  }

  const handleSelection = (updatedData) => {
    playSound.beepOK()
    console.log('onCheckboxClick id=' + updatedData[0].id + ', status=' + updatedData[0].status)
    let hasChecked = false
    results.map((item) => {
      if (item.id === updatedData[0].id) {
        item.status = updatedData[0].status
      }
      if (item.status === CheckboxStatus.CHECKED || item.status === CheckboxStatus.CHECKED_DISABLED) {
        hasChecked = true

        item.bioCheck = PASSENGER_VALIDATION_STATUS.PASSED
        item.docCheck = PASSENGER_VALIDATION_STATUS.PASSED
      } else {
        item.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
        item.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
      }
    })
    if (hasChecked) {
      setIsDisableConfirmButton(false)
    } else {
      setIsDisableConfirmButton(true)
    }
    setResults([...results])
  }

  const buildRows = (data) => {
    let canSelectCount = 0
    let rows = []
    let count = 0
    if (!data) {
      return rows
    }
    if (isLandscape) {
      data.forEach((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        const checkbox = (
          <Checkbox
            key={'key-' + index}
            id={cell.id}
            data={[checkboxData]}
            onClick={handleSelection}
            additionalText={cell.additionalText}
            additionalText1={cell.additionalText1}
            additionalText2={cell.additionalText2}
            tabIndex={cell.tabIndex}
          />
        )
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        if (index % 2 === 0) {
          rows.push({ data: [checkbox] })
        } else {
          rows[count].data.push(checkbox)
          count++
        }
      })
    } else {
      data.map((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        rows.push({
          data: [
            <Checkbox
              key={'key-' + index}
              id={cell.id}
              data={[checkboxData]}
              onClick={handleSelection}
              additionalText={cell.additionalText}
              additionalText1={cell.additionalText1}
              additionalText2={cell.additionalText2}
              tabIndex={cell.tabIndex}
            />
          ]
        })
      })
    }
    return rows
  }

  const testData = [
    {
      id: 'confirmBtn',
      text: 'Confirm',
      cssName: 'test-button-up',
      handler: handleActions
    },
    {
      id: 'errorBtn',
      text: 'Error',
      cssName: 'test-button-up',
      handler: handleActions
    }
  ]

  const textSection = (
    <>
      <EMBTable
        columns={columns}
        rows={buildRows(results)}
        headerHeight={50}
        rowHeight={themeContext.PassengerSelection.rowHeight[ratioKey]}
        rowFontSize={30}
        headFontSize={30}
        maxDisplayRows={themeContext.PassengerSelection.maxDisplayRows[ratioKey]}
      />
    </>
  )

  const animationSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.VerifyDocTitle)}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.VerifyDocSubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={true}
        backAction={handleActions}
        backBtnText={intl.formatMessage(messages.buttonCompleteVerify)}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={true}
        isHelpRequired={false}
        testData={enableScan ? testData : null}
        disableBackAction={isDisableConfirmButton}
      />
      {/* {enableScan ? <TestButtons data={testData} /> : null} */}
    </>
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth,
      displayItinerary: false
    }
  )

  return <>{UIDisplay}</>
}

export default VerifyDocuments
