import { goToFetchError } from 'utils/FetchWithTimeout'
import { fetchWithTimeout } from './FetchWithTimeout'
import { store, history, getVersion } from 'main'
import { logInfo, logDebug, logEvent, logError } from 'utils/Logger'
import { ErrCodes } from 'constants/Errors'
import { updateFetchError } from 'actions/commonActions'
import matchOneToMany from './matchOneToMany'
import { logEvents, sessionEnd, EventFlows, EventFunctions } from 'utils/appEventLogger'

const startBiometricsTransaction = (capturedPhoto, locale = 'EN', sessionId = null, callback) => {
  history.push('pleaseWait')
  const kioskInfo = store.getState().kioskInfo
  const clientDetail = {
    location: kioskInfo.airportCode,
    clientID: kioskInfo.kioskId,
    clientTime: new Date(),
    kmClientID: kioskInfo.smKioskId,
    selectedLanguage: store.getState().localData.locale.toUpperCase(),
    clientType: 'BIO',
    appVersion: getVersion(),
    carrierCode: null
  }
  const req = {
    clientDetail: clientDetail,
    mediaData: capturedPhoto,
    sessionId: sessionId
  }

  logInfo('startBiometricsEtsTransaction request ... ' + JSON.stringify(req))
  fetchWithTimeout('startBiometricsTxn/', req)
    .then((json) => {
      logInfo('startBiometricsEtsTransaction response ... ' + JSON.stringify(json))
      if (json.etsResponse) {
        logEvents(EventFlows.FaceMatchN, EventFunctions.FaceMatchInitiated, 'Face match initialized')
        if (capturedPhoto) {
          matchOneToMany(capturedPhoto, json.etsResponse.sessionInfo, callback, true)
        }
      } else {
        if (json.error) {
          goToFetchError('startEtsTransaction', json, store.dispatch, 'error')
        } else {
          store.dispatch(updateError(ErrCodes.ETS_COMM_ERROR, '', 'startBiometricsEtsTransaction', OOS))
          navigate('error')
        }
      }
    })
    .catch((error) => {
      logError('startBiometricsEtsTransaction Catch:' + error)
      store.dispatch(updateFetchError(error.errCode, error.msg, 'startBiometricsEtsTransaction'))
      history.push('error')
    })
}
export default startBiometricsTransaction
