import { CustomActions } from 'custom/constants/CustomConstants'

export function updateCustomData(
  state = {
    customData: null
  },
  action
) {
  let data = Object.assign({}, state)
  switch (action.type) {
    case CustomActions.UPDATE_CUSTOM_DATA:
      data.customData = action.customData
      break
    case CustomActions.INITIAL_CUSTOM_DATA:
    case CustomActions.CLEAR_CUSTOM_DATA:
      break
    case CustomActions.UPDATE_TVS_PHOTO:
      data.TVSPhoto = action.TVSPhoto
      break
    case CustomActions.UPDATE_BARCODE:
      data.barcode = action.barcode
      break
    default:
      return state
  }
  return data
}
