import React, { useState, useEffect, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getSBDAppMan, playSound, getDeviceManager, getBuildAccessibility, getAccessibilityManager, store } from 'main'
import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { overweightCommand } from './overweightCommand'
import { TransitionCodes, SBDUpdateAction } from 'constants/Constants'
import { SourceType, ErrCodes, ContinueErrCodes, ErrorDictionary, ErrType } from 'constants/Errors'
import { END_TXN_REASON, TIMEOUT_POPUP, TIMEOUT_YES_BTN, TIMEOUT_NO_BTN, OOS, RETRY } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { PageHeader, PageTitle, PageSubTitle, DynamicImage, Spacer } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../../layouts/footer'
import { turnLights } from 'utils/lights'
import { appLog, logEvent } from 'utils/Logger'
import {
  replacer,
  populateItineraryInfo,
  isNotEmpty,
  checkLandscape,
  isEmpty,
  navigate,
  getScreenId
} from 'utils/helper'
import { PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { getEventLogger } from '../../main'
import OverweightTxn from './OverweightTxn'
import { Animation } from 'components'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'

const mapTransitionToPage = (source, transition, appFlow) => {
  let result = ''
  switch (transition) {
    case TransitionCodes.BARCODE_RETRY:
      if (appFlow === 5) {
        result = 'ErrorBPMismatch'
      } else {
        result = 'END_TXN'
      }
      break
    case TransitionCodes.CUSTOM_4:
      result = 'genericCommand'
      break
    case TransitionCodes.SBD_PROCESS:
      result = 'PutBagOnBelt'
      break
    case TransitionCodes.SCAN_BP:
      result = 'ScanBoardingPass'
      break
    case TransitionCodes.PAX_VALIDATION:
      result = 'ScanDocuments'
      break
    case TransitionCodes.AGENT_CHECK:
      result = 'AgentScan'
      break
    case TransitionCodes.END_TXN:
    case null:
    default:
      result = 'END_TXN'
      break
  }
  return result
}

const mapErrImage = (errCode, sbdModel) => {
  const isLandscape = useCheckLandscape()
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1100, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const warningSize = useMedia(null, [400, 350, 400], 400)
  const themeContext = useContext(ThemeContext)
  let location = `generic-agent.svg`
  switch (errCode) {
    case ErrCodes.BAG_NOSCAN:
    case ErrCodes.TRANSFER_NOT_SUPPORT:
    case ErrCodes.ERR_AGENT_PNR_NOT_FOUND:
      // 450*450
      // errImgClass = 'emb_animation_wrapper_bottom'
      // if (sbdModel === 'VBD') {
      //   location = `BagtagNotFound490VBD.png`
      // } else if (sbdModel === 'VBDR') {
      //   location = `BagtagNotFound490VBDR.png`
      // } else {
      //   location = `BagtagNotFound450.png`
      // }
      location = isLandscape ? themeContext.ImageErrorLandscape.bagNoScan : themeContext.ImageError.bagNoScan
      break
    // case ErrCodes.BAG_TOO_TALL:
    //   location = `${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan.gif`
    //   break
    case ErrCodes.BAG_UNDERHEIGHT:
    case ErrCodes.BAG_UNDERHEIGHT_ADDBAG:
    case ErrCodes.BAG_SHORT:
      location = themeContext.ImageError.underHeight
      break
    case ErrCodes.BAG_UNDERWEIGHT:
    case ErrCodes.BAG_UNDERWEIGHT_ADDBAG:
      location = themeContext.ImageError.underWeight
      break
    case ErrCodes.BAG_UNDERHEIGHT_USEBIN:
    case ErrCodes.BAG_UNDERWEIGHT_USEBIN:
      location = themeContext.ImageError.useBin
      break
    case ErrCodes.BAG_MAX_WEIGHT:
    case ErrCodes.BAG_MAX_WEIGHT_ADDBAG:
      location = isLandscape ? themeContext.ImageErrorLandscape.overMaxWeight : themeContext.ImageError.overMaxWeight
      break
    case ErrCodes.MAX_POOL_WEIGHT:
    case ErrCodes.BAG_OVERWEIGHT:
    case ErrCodes.OVERWEIGHT_AGENT:
    case ErrCodes.OVERWEIGHT_LAST:
      location = isLandscape ? themeContext.ImageErrorLandscape.overWeight : themeContext.ImageError.overWeight
      break
    case ErrCodes.BAG_LONG:
    case ErrCodes.BAG_LONG_ADDBAG:
      location = themeContext.ImageError.tooLong
      break
    case ErrCodes.NO_BAGS:
      location = isLandscape ? themeContext.ImageErrorLandscape.noBags : themeContext.ImageError.noBags
      break
    case ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED:
      location = themeContext.ImageError.generic
      break
    case ErrCodes.BAG_TOO_TALL:
    case ErrCodes.BAG_RATIO_TOO_HIGH:
      location = themeContext.ImageError.tooTall
      break
    case ErrCodes.WRONG_AIRPORT:
    case ErrCodes.ERR_STATION:
      location = isLandscape ? themeContext.ImageErrorLandscape.wrongAirport : themeContext.ImageError.wrongAirport
      break
    case ErrCodes.TOO_EARLY_BAGGAGE:
      location = isLandscape ? themeContext.ImageErrorLandscape.tooEarly : themeContext.ImageError.tooEarly
      break
    case ErrCodes.TOO_LATE_BAGGAGE:
      location = isLandscape ? themeContext.ImageErrorLandscape.tooLate : themeContext.ImageError.tooLate
      break
    case ErrCodes.CONVEYOR_ESTOP:
      location = isLandscape ? themeContext.ImageErrorLandscape.eStop : themeContext.ImageError.eStop
      break
    case ErrCodes.BAG_JAMMED:
      location = isLandscape ? themeContext.ImageErrorLandscape.bagJammed : themeContext.ImageError.bagJammed
      break
    // case ErrCodes.CONVEYOR_INTRUSION:
    //   result = <img className="emb_animation_drawbox" src={IntrusionAnimation} />
    case ErrCodes.ERR_PASSPORT_INVALID_RETRY:
    case ErrCodes.ERR_PASSPORT_INVALID:
    case ErrCodes.ERR_PASSPORT_BADNAME:
    case ErrCodes.ERR_PASSPORT_DOBMATCH:
      location = `${themeContext.AnimationPath}/${sbdModel}/${themeContext.ImageScanDocument}`
      break
    case ErrCodes.OVERSIZED:
      location = themeContext.ImageError.oversized
      break
    case ErrCodes.ERR_DATA_MISSING:
      location = themeContext.ImageError.dataMissing
      break
    case ErrCodes.NOT_ELIGIBLE_TO_ACCEPT:
      location = isLandscape ? themeContext.ImageErrorLandscape.notEligible : themeContext.ImageError.notEligible
      break
    default:
      location = themeContext.ImageError.generic
      break
  }
  if (location === themeContext.ImageError.generic) {
    return <Animation imageName={location} width={animationSize.width} height={animationSize.height} />
  } else {
    return <Animation imageName={location} width={animationSize.width} height={animationSize.height} />
  }
}

const GenericErrors = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const errorDetails = useSelector((state) => state.errorDetails)
  const locale = useSelector((state) => state.localData.locale)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const currentPassenger = useSelector((state) => state.paxValidation.currentPassenger)
  const iconSize = useMedia(null, [50, 40, 45], 45)
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )
  const errImage = mapErrImage(errorDetails.errCode, sbdModel)
  const [errMsgTitle, setErrMsgTitle] = useState('')
  const [errMsgSubTitle, setErrMsgSubTitle] = useState('')
  let timeoutRef = null
  let SBDAppManager = getSBDAppMan()

  const popupTimer = useRef(null)
  const [visible, setVisible] = useState(false)
  const accMgr = getAccessibilityManager()

  const getErrMsg = (errorCode, msgs, language) => {
    let errMsg = {
      errorTitle: '',
      errorSubTitle: ''
    }
    let message = msgs.filter((msg) => msg.languageCode.toUpperCase() === language.toUpperCase())
    if (message.length > 0) {
      let msgParts = message[0].screenMsg.split('***')
      if (msgParts.length > 1) {
        errMsg = {
          errorTitle: msgParts[0],
          errorSubTitle: msgParts[1]
        }
      } else {
        errMsg = {
          errorTitle: msgParts[0],
          errorSubTitle: ''
        }
      }
    }
    return errMsg
  }

  const setErrorMessage = () => {
    let errorMessage = {
      errorTitle: '',
      errorSubTitle: ''
    }
    if (errorDetails.sourceType === SourceType.FETCH) {
      if (Array.isArray(errorDetails.msg)) {
        errorMessage = getErrMsg(errorDetails.errCode, errorDetails.msg, locale)
      }
    } else {
      let msgParts = []
      let msgText = ''
      if (messages[errorDetails.msg]) {
        if (errorDetails.params) {
          msgText = intl.formatMessage(messages[errorDetails.msg], errorDetails.params)
        } else {
          msgText = intl.formatMessage(messages[errorDetails.msg])
        }
      }
      if (msgText.length > 0) {
        msgParts = msgText.split('\n\n')
      }
      if (msgParts.length == 1) {
        errorMessage.errorTitle = msgParts[0]
      } else if (msgParts.length > 1) {
        errorMessage.errorTitle = msgParts[0]
        errorMessage.errorSubTitle = msgParts[1]
      }
    }
    return errorMessage
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) - error source type = "' + errorDetails.sourceType + '"')
    const errorMessage = setErrorMessage()
    setErrMsgTitle(errorMessage.errorTitle)
    setErrMsgSubTitle(errorMessage.errorSubTitle)

    /** accessibility **/
    handleAccessibility()
  }, [locale, errorDetails])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : { displayName: '' }
    const errorMessage = setErrorMessage()
    const genericErrors = [errorMessage.errorTitle, errorMessage.errorSubTitle]
    const accDef = {
      pathName: 'error',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [itinerary.displayName, genericErrors.join('. ')]
          }
        ]
      }
    }
    if (errorDetails.errCode === ErrCodes.BAG_OVERWEIGHT) {
      accDef.sequenceDef.sequence.push({
        id: 'backBtn',
        text: intl.formatMessage(messages.OverWeightRepackBtn),
        buttonId: 'backBtn'
      })
      accDef.sequenceDef.sequence.push({
        id: 'skipBtn',
        text: intl.formatMessage(messages.OverWeightPayBtn),
        buttonId: 'skipBtn'
      })
    } else {
      accDef.sequenceDef.sequence.push({ id: 'confirmBtn', text: doneBtnText(), buttonId: 'confirmBtn' })
    }
    buildAccessibility(accDef)
  }

  // Repack popup accessibility
  const screenMessage = `${intl.formatMessage(messages.OverWeightRepackTitle)}. ${intl.formatMessage(
    messages.OverWeightRepackSubTitle
  )}`
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        { id: 'overweightTxnLabel', textId: 'OneDynamicText', textParameters: [screenMessage] },
        { id: 'overweightTxnYes', text: intl.formatMessage(messages.buttonQuitYes), buttonId: 'quitYes' }
      ]
    }
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) - errCode = "' + errorDetails.errCode + '"')
    logEvent('Error, ' + errorDetails.errCode)
    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) -  UNMOUNTED ...')
      getDeviceManager()
        .getAppManager()
        .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
      dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
      dispatch(updateLocalData('updateBqCommand', ''))
      dispatch(updateError(null)) // RESET ERROR
    }
  }, [])

  const handleExitPage = () => {
    const errTransition = errorDetails.transition
    const source = errorDetails.source
    const sourceType = errorDetails.sourceType
    console.log('WHERE ERROR PAGE GO TO:::', errorDetails)

    if (sourceType === SourceType.FETCH) {
      const transitionPage = mapTransitionToPage(source, errTransition, appFlow)
      if (transitionPage === 'END_TXN') {
        SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        getEventLogger().clientSessionId = null
      } else {
        if (transitionPage === 'PutBagOnBelt') {
          // when we want to go back to putbagonbelt page, remember to set the flow to 2.
          if (baggageLoadedStatus) {
            navigate('BagProcessing', 2)
          } else {
            navigate('PutBagOnBelt', 2)
          }
        } else if (transitionPage === 'genericCommand') {
          let updatedItem = {}
          validatePaxResult.forEach((item) => {
            if (item.ordinal === currentPassenger.ordinal) {
              updatedItem = item
            }
          })

          const updatePaxStatus = {
            ordinal: currentPassenger.ordinal,
            docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            faceMatch:
              updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED ||
              updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
          }
          dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
        } else {
          navigate(transitionPage)
        }
      }
    } else {
      if (errTransition) {
        if (errTransition === 'PutBagOnBelt') {
          if (baggageLoadedStatus) {
            navigate('BagProcessing', 2)
          } else {
            navigate('PutBagOnBelt', 2)
          }
        } else if (errTransition === 'Retry') {
          navigate('welcome', 0)
        } else if (errTransition === 'END_TXN') {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        } else if (errTransition === 'OOS') {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, OOS)
        } else {
          navigate(errTransition)
        }
      } else {
        SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
      }
    }
  }

  const handleActions = (e) => {
    if (timeoutRef !== null) {
      timeoutRef.getWrappedInstance().resetTimer()
    }
    appLog(TraceLevels.LOG_TRACE, '(ErrorPage.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"')

    playSound.beepOK()

    /// TEST CODE

    // end OF TEST CODE

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        handleExitPage()
        break
      case 'backBtn':
        playSound.beepOK()
        startPopupTimer()
        setVisible(true)
        accMgr.buildPopupGroup(true, accDef)
        break
      case 'skipBtn':
        navigate('OverweightAgentScan')
        break
      default:
        break
    }
  }

  const doneBtnText = () => {
    const errTransition = errorDetails.transition
    const source = errorDetails.source
    const sourceType = errorDetails.sourceType

    if (sourceType === SourceType.FETCH) {
      const transitionPage = mapTransitionToPage(source, errTransition, appFlow)
      if (transitionPage === 'END_TXN') {
        return intl.formatMessage(messages.buttonEndTxn)
      } else {
        if (transitionPage === 'PutBagOnBelt') {
          // when we want to go back to putbagonbelt page, remember to set the flow to 2.
          if (baggageLoadedStatus) {
            return intl.formatMessage(messages.buttonRetry)
          } else {
            return intl.formatMessage(messages.buttonEndTxn)
          }
        } else if (transitionPage === 'genericCommand') {
          return intl.formatMessage(messages.buttonContinue)
        } else if (transitionPage === 'ScanDocuments') {
          return intl.formatMessage(messages.buttonRetry)
        } else {
          return intl.formatMessage(messages.buttonEndTxn)
        }
      }
    } else {
      if (errTransition) {
        if (errTransition === 'PutBagOnBelt') {
          if (baggageLoadedStatus) {
            return intl.formatMessage(messages.buttonRetry)
          } else {
            return intl.formatMessage(messages.buttonContinue)
          }
        } else if (errTransition === 'Retry') {
          return intl.formatMessage(messages.buttonRetry)
        } else if (errTransition === 'END_TXN') {
          return intl.formatMessage(messages.buttonEndTxn)
        } else if (errTransition === 'OOS') {
          return intl.formatMessage(messages.buttonEndTxn)
        } else if (errTransition === 'ScanDocuments') {
          return intl.formatMessage(messages.buttonRetry)
        } else {
          return intl.formatMessage(messages.buttonOk)
        }
      } else {
        return intl.formatMessage(messages.buttonOk)
      }
    }
  }

  const popupCallback = (answer) => {
    playSound.beepOK()
    clearPopupTimer()
    if (answer === 'YES') {
      console.log('answer is yes')
      store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
      getSBDAppMan().doQuit(END_TXN_REASON.USER_QUIT, '')
      if (props.quitAction) {
        props.quitAction('quitAction')
      }
    } else {
      console.log('answer is NO')
    }
    setVisible(false)
    accMgr.buildPopupGroup(false, { disableFocus: true, keepPrevIndex: true })
  }

  const startPopupTimer = () => {
    popupTimer.current = setTimeout(() => {
      popupCallback('NO')
    }, config.timeoutPopup * 1000)
    console.log(
      'OverweightTxn startPopupTimer() ... START POPUP Timer ... ',
      popupTimer,
      ' ... duration ... ',
      config.timeoutPopup
    )
  }

  const clearPopupTimer = () => {
    if (popupTimer.current !== null) {
      clearTimeout(popupTimer.current)
      console.log('OverweightTxn clearPopupTimer() ... STOP POPUP Timer ... ', popupTimer.current)
      popupTimer.current = null
    }
  }

  const animationSection = errImage
  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {errMsgTitle}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {errMsgSubTitle}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <OverweightTxn visible={visible} popupCallback={popupCallback} buttonGap={'50px'} />
      <Footer
        isQuitRequired={false}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={errorDetails.errCode === ErrCodes.BAG_OVERWEIGHT ? true : false}
        isSkipRequired={errorDetails.errCode === ErrCodes.BAG_OVERWEIGHT ? true : false}
        isConfirmRequired={
          !(errorDetails && errorDetails.confirmDisable) &&
          !config.isTransferEnabled &&
          !(errorDetails.errCode === ErrCodes.BAG_OVERWEIGHT)
        }
        isLangRequired={true}
        confirmBtnText={doneBtnText()}
        confirmAction={handleActions}
        backBtnText={intl.formatMessage(messages.OverWeightRepackBtn)}
        backAction={handleActions}
        skipBtnText={intl.formatMessage(messages.OverWeightPayBtn)}
        skipAction={handleActions}
      />
    </>
  )

  // let contentWidth = isLandscape ? '50%' : '100%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '100%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default GenericErrors
