/*------------------- OUTOFSERVICEMANAGER ---------------------*/
import DeviceStatus from 'constants/deviceStatus'
import {
  ApplicationState,
  TraceLevels,
  PromiseCodes,
  RETURN_PROMISE,
  AUTHENTICATION_STEPS,
  VBDModeStatus,
} from 'constants/Constants'
import { fetchWithTimeout } from 'utils/FetchWithTimeout'
import { getKeyByValue, isInteger } from 'utils/helper'
import Heartbeat from './heartbeat'

/**
  An object which takes care of putting the application in and out of service based on network and device
 status. It makes use of the {@link Heartbeat} class.
 <br/>The OutOfServiceManager keeps two timeouts. The first controls network status. You can optionally
 specify a 'authenticate' URL which will execute first to notify the server that the kiosk is up, and get configuration
 or version information. Once that is done (or if it isn't specified), the heartbeat will begin. At the same time,
 the manager will continually check device status. If either network or devices go down, the manager will put
 the app into unavailable state, and bring it back when they come back up.
 <br/><h4 data-ice="title">IMPORTANT:</h4> If the manager determines the app needs to go down while a user is active, this will
 <b>NOT</b> be handled directly by the manager. You must implement {@link onActiveOOS} to deal with this situation
 and call {@link completeTransaction} yourself. Generally this will involve showing an error screen
 and calling {@link completeTransaction} with <i>forceUnavailable=true</i> once the user clicks OK.
 */
export default class OutOfServiceManager {
  /**
   * OutOfServiceManager default configuration - can be updated from Authenticate response
   * @param {String} authenticationUrl - full url to authenticate ETS request, if the url === null will disable authentication
   * @param {String} heartbeatUrl - full url to heartbeat ETS request
   * @param {Object} devMgr - embross-device-manager reference
   * @param {function(json: Object)} [cbOnAuthenticationCompleted] - callback function called when authentication request is completed.
   * @param {function()} [cbOnInService] - callback function called when the app is going into service.
   * @param {function()} [cbOnOutOfService] - callback function called when the app is going out of service.
   * @param {boolean} enable authentication using JsonwebToken.
   */
  constructor(
    authenticationUrl,
    heartbeatUrl,
    devMgr,
    cbOnAuthenticationCompleted,
    cbOnInService,
    cbOnOutOfService,
    enableAuthentication = false
  ) {
    /** @private enableAuthentication */
    this.enableAuthentication = enableAuthentication
    /** @private authenticationSteps */
    this.authenticationSteps = enableAuthentication ? AUTHENTICATION_STEPS.START : AUTHENTICATION_STEPS.CONFIRM
    /** @private authentication url */
    this.authenticationUrl = authenticationUrl
    /** @private heartbeat url */
    this.heartbeatUrl = heartbeatUrl
    /** @private device manager reference */
    this.deviceManager = devMgr
    /**
     * @private callback function called when the authentication request is completed
     * @type {function(json: Object)} */
    this.onAuthenticationCompleted = cbOnAuthenticationCompleted //this.onAuthenticationCompletedDefault
    /**
     * @private callback function called when the app is going into service.
     * @type {function()} */
    this.onInService = cbOnInService
    /**
     * @private callback function called when the app is going out of service.
     * @type {function()} */
    this.onOutOfService = cbOnOutOfService
    /** @private client type - set by {@link OutOfServiceManager.start} method */
    this.clientType = ''
    /** @private application version - set by {@link OutOfServiceManager.start} method */
    this.appVersion = ''
    /** @private language code - set by {@link OutOfServiceManager.start} method */
    this.locale = ''
    /** @private carrier code - set by {@link OutOfServiceManager.start} method */
    this.carrierCode = ''
    /** @private List of devices which must be empty to be considered OK.*/
    this.emptyDevices = []
    /** @private heartbeat class.*/
    this.heartbeat = null
    /** @private Authentication retry delay.*/
    this.authRetryDelay = 10000
    /** @private Timeout for device checks (AVAILABLE).*/
    this.deviceAvailTimeout = 30000
    /** @private Timeout for device checks (UNAVAILABLE).*/
    this.deviceUnavailTimeout = 30000
    /** @private Timeout for heartbeats (AVAILABLE).*/
    this.heartbeatAvailTimeout = 300000
    /** @private Timeout for heartbeats (UNAVAILABLE).*/
    this.heartbeatUnavailTimeout = 30000
    /** @private maximum time to wait for kioskid and location before calling authenticate and heartbeat */
    this.maxInitialWait = 1000
    /** @private heartbeat and authenticate ETS calls timeout */
    this.heartbeatTimeout = 0
    /** @private timer id used for device calls */
    this.deviceTimer = null
    /** @private timestamp when the deviceTimer was set */
    this.deviceTimerTS = null
    /** @private deviceTimer timeout value */
    this.deviceTimerTO = 0
    /** @private reset the deviceTimer when the delta between current timestamp and the deviceTimerTS
     *  is greater then this value in milliseconds   */
    this.deviceTimerMaxDelta = 2000
    /** @private timer id (interval) used for kioskId and location calls */
    this.startTimer = null
    /** @private Is the heartbeat OK?*/
    this.heartbeatOK = true
    /** @private Are devices OK?*/
    this.devicesOK = false
    /** @private Is everything OK?*/
    this.inService = false
    /** @private Started yet?*/
    this.isStarted = false
    /** @private optional callback - it allows the application to decide if the heartbeat response indicate that the ETS is in service */
    this.isHBResponseInService = null
    /** @private optional callback - it allows the application to use the ETS data received in the heartbeat response (version, ETS time, delta)*/
    this.serverDataUpdate = null

    /** @private Current known statuses of devices.*/
    this.statuses = {}
    /** @private Current known isOK statuses of devices.*/
    this.isOK = {}

    /** @private Should we check devices while in an ACTIVE state? for PSAM apps startTransaction should set it to false and endTransaction to true */
    this.checkDeviceActive = false
    /** @private Reference to the CUSS device status definitions {@link src/constants/deviceStatus.js~DeviceStatus}.*/
    this.componentRC = new DeviceStatus()

    /** @private server time delta maximum difference */
    this.serverTimeDeltaMaxDiff = 1000
    /** @private server time delta minimum change */
    this.serverTimeDeltaMinChange = 100
    /** @private first available delay */
    this.firstAvailDelay = 0

    /**
     * @private internal link to logging method
     * */
    this.logIt = null

    this.firstAvail = true

    this.manualSwitch = false

    localStorage.setItem('auth-token', null)
  }

  /** set heartbeat timeout
   *  @param {number} timeout - sets the timeout only when the parameter is an integer
   * */
  setHeartbeatTimeout(timeout) {
    if (isInteger(timeout)) this.heartbeatTimeout = timeout
  }

  /** Start the manager's processes. Should be called when the application is in UNAVAILABLE state.
   If the manager has already been started, this will do nothing.
   @param {String} language - language.
   @param {String} clientType - SBD , KIOSK.
   @param {String} appVersion - application version.
   @param {String} carrierCode - carrier code
   @param {Boolean} emulateHeartbeatOn - if true then the heartbeat is always on (no ETS calls are executed).
   */
  start(language, clientType, appVersion, carrierCode, emulateHeartbeatOn) {
    if (this.isStarted) return
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, '>>>>>>>>>>>>>> Starting OOSManager <<<<<<<<<<<<')
    this.clientType = clientType
    this.appVersion = appVersion
    this.locale = language
    this.carrierCode = carrierCode
    this.isStarted = true
    this.heartbeat = new Heartbeat(
      this.deviceManager,
      this.heartbeatTimeout,
      language,
      clientType,
      appVersion,
      carrierCode
    )
    this.heartbeat.onOutOfService = this.checkStatus.bind(this)
    this.heartbeat.onInService = this.checkStatus.bind(this)
    this.heartbeat.setUrl(this.heartbeatUrl)
    this.heartbeat.setInterval(this.heartbeatUnavailTimeout)
    this.heartbeat.setRetryInterval(this.heartbeatUnavailTimeout)
    this.heartbeat.setRetries(2)
    this.heartbeat.setServerTimeDeltaMaxDiff(this.serverTimeDeltaMaxDiff)
    this.heartbeat.setServerTimeDeltaMinChange(this.serverTimeDeltaMinChange)
    this.logIt = this._logmsg.bind(this)
    this.deviceManager.setOnRequiredDeviceOK(this._onRequiredDeviceOK.bind(this))

    if (!!this.isHBResponseInService)
      // overwrite callback if defined
      this.heartbeat.isResponseInService = this.isHBResponseInService
    if (!!this.serverDataUpdate)
      // overwrite callback if defined
      this.heartbeat.serverDataUpdate = this.serverDataUpdate

    // check if kioskId and location are provided in appManager - wait for it this.maxInitialWait - then getKiosk
    // do it forever now - FIX it
    this.startTimer = setInterval(() => {
      if (this.deviceManager.appManager.kioskId != null && this.deviceManager.appManager.location != null) {
        clearInterval(this.startTimer)
        if (this.authenticationUrl) {
          this._authenticate()
        }
        this.heartbeat.start(emulateHeartbeatOn)
        this._checkDevices()
      } else {
        this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'START - waiting for kioskId and location.')
      }
    }, 50)
  }

  /** Build authenticate ETS request
   */
  _buildAuthenticate(action) {
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, 'buildAuthenticate')
    return {
      action: action,
      clientDetail: {
        location: this.deviceManager.appManager.location,
        clientID: this.deviceManager.appManager.kioskId,
        clientTime: new Date(),
        kmClientID: this.deviceManager.appManager.smKioskId,
        selectedLanguage: this.locale,
        clientType: this.clientType,
        appVersion: this.appVersion,
        carrierCode: this.carrierCode,
      },
      key: '',
    }
  }

  /** process authenticate ETS request
   */
  _authenticate() {
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS.authenticate url:[' + this.authenticationUrl + ']')
    fetchWithTimeout(
      this.authenticationUrl,
      this._buildAuthenticate(this.authenticationSteps),
      this.heartbeatTimeout,
      this.logIt
    )
      .then((json) => {
        this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS.authenticate response: ' + JSON.stringify(json))
        if (this.enableAuthentication) {
        } else {
          this._authSuccess(json)
        }
      })
      .catch((err) => {
        this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS.authenticate Catch: ' + err)
        this._authFailed(err)
      })
  }

  /** Callback for when the authentication is complete.
   *  @param {Object} json - response from authenticate ETS call
   */
  _authSuccess(json) {
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'Authentication response')
    if (this.onAuthenticationCompleted(json)) {
      this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'Authentication success')
      this.heartbeat.start()
    } else {
      // retry authentication
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'Authentication failed - onAuthenticationCompleted returned false - retry after: ' + this.authRetryDelay
      )
      this.deviceManager.appManager.unavailable()
      setTimeout(this._authenticate.bind(this), this.authRetryDelay)
    }
  }

  /** Callback for when the authentication. Try again.
   * @param {Object} err - error response from authenticate ETS call
   */
  _authFailed(err) {
    this.deviceManager.dmLog(
      TraceLevels.LOG_SYSTEM,
      'Authentication failed: ' + err + ' retry after: ' + this.authRetryDelay
    )
    this.deviceManager.appManager.unavailable()
    setTimeout(this._authenticate.bind(this), this.authRetryDelay)
  }

  /** default callback  that trips when authentication success - will be replaced by the function provided in the constructor
  @callback
   @param {Object} json - response from authenticate ETS call
   @return {boolean} true when the json object indicate the sucessfull authentication.
  */
  _onAuthenticationCompletedDefault(json) {
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, 'OOS.onAuthenticationCompleted default json ' + json)
    return true
  }

  /** Force a check of device status. The next check will come after the expected number of seconds
   * (i.e. this will never cause a check to happen more often than the number of seconds configured).
   * */
  _checkDevices() {
    this.deviceManager.dmLog(TraceLevels.LOG_LEVEL2, 'OOS.checkDevices *****************************')
    if (this.deviceManager.noWebsocket === true) {
      this.devicesOK = true
      return
    }
    // get current Application state - as Promise
    this.deviceManager.appManager
      .getCurrentState(RETURN_PROMISE)
      .then((response) => {
        let appState = parseInt(response, 10)
        this.deviceManager.dmLog(
          TraceLevels.LOG_TRACE,
          '.OOS.checkDevices appState = ' +
            appState +
            ' [' +
            getKeyByValue(ApplicationState, appState) +
            ']' +
            ' checkDeviceActive = ' +
            this.checkDeviceActive
        )
        if (appState === ApplicationState.ACTIVE && !this.checkDeviceActive) {
          this.deviceManager.dmLog(
            TraceLevels.LOG_SYSTEM,
            "OOS.checkDevices application is active...when we go available we'll start again."
          )
          return
        }
        //this.statuses = {} //list of IDs -> statuses to pass into onDeviceCheck().
        //this.isOK = {} //list of IDs -> isOK to pass into onDeviceCheck()
        let devices = this.deviceManager.devices
        let devicesCnt = devices.length
        for (let i = 0; i < devices.length; i++) {
          this.deviceManager.dmLog(TraceLevels.LOG_EXT_TRACE, '..OOS.checkDevices device: ' + devices[i].name)
          devices[i]
            .statusIsOK(RETURN_PROMISE)
            .then((response) => {
              let status = devices[i].deviceStatus
              let ok = devices[i].isDeviceOk
              this.deviceManager.dmLog(
                TraceLevels.LOG_EXT_TRACE,
                '..OOS.checkDevices device:[' +
                  devices[i].name +
                  '] isDeviceOK: [' +
                  ok +
                  '] status: [' +
                  status +
                  '] response: [' +
                  response +
                  ']'
              )
              devicesCnt--
              if (devicesCnt === 0) this._checkDevices2(appState)
            })
            .catch((error) => {
              this.deviceManager.dmLog(TraceLevels.LOG_ALERT, '..OOS.checkDevices statusIsOK error: ' + error)
              if (error === PromiseCodes.TIMEOUT) {
                devicesCnt--
                if (devicesCnt === 0) this._checkDevices2(appState)
              }
            })
        }
      })
      .catch((error) => {
        this.deviceManager.dmLog(TraceLevels.LOG_ALERT, '..OOS.checkDevices getCurrentState error: ' + error)
        if (error === PromiseCodes.TIMEOUT) {
          this.devicesOK = false
          this._checkDevices2(2)
        }
      })
  }

  /** Analyze collected device statuses
   */
  _checkDevices2(appState) {
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, 'OOS.checkDevices2.')
    let logMessage = ''
    let oldOK = this.devicesOK
    let devsOK = true //temp
    const { combinedDevices, devices } = this.deviceManager
    for (let i = 0; i < devices.length; i++) {
      this.deviceManager.dmLog(TraceLevels.LOG_EXT_TRACE, '..OOS.checkDevices2 device: ' + devices[i].name)
      let status = devices[i].deviceStatus
      let ok = devices[i].isDeviceOk
      this.deviceManager.dmLog(
        TraceLevels.LOG_EXT_TRACE,
        '..OOS.checkDevices2 device:[' +
          devices[i].name +
          '] isDeviceOK: [' +
          ok +
          '] status: [' +
          status +
          '] ' +
          (devices[i].isRequired ? 'Required' : 'Optional')
      )
      if (ok && this.emptyDevices.indexOf(devices[i].deviceId) >= 0) {
        //check whether it's empty
        if (
          appState !== ApplicationState.ACTIVE &&
          (status === this.componentRC.MEDIAFULL ||
            status === this.componentRCMEDIAHIGH ||
            status === this.componentRC.MEDIAPRESENT)
        ) {
          this.deviceManager.dmLog(
            TraceLevels.LOG_EXT_TRACE,
            '..OOS.checkDevices2 ' + devices[i].name + ' still has documents or media inserted!'
          )
          ok = false
        }
      }

      /* if the device is not required then it is fine */
      if (!ok && devices[i].isRequired) {
        this.devicesOK = false
        devsOK = false
        logMessage += devices[i].name + ' not OK! '
      } else if (!ok) {
        //optional device can be not OK
        logMessage += devices[i].name + ' (not OK) '
      } else {
        logMessage += devices[i].name + (devices[i].isRequired ? ' OK. ' : ' (OK). ')
      }
      // check manual switch device
      if (devices[i].manualModeSwitch) {
        if (status === VBDModeStatus.VBD_MODE_MANUAL || status === VBDModeStatus.VBD_MODE_ERROR) {
          this.manualSwitch = true
          logMessage += ' manual mode ON. '
        } else {
          this.manualSwitch = false
          logMessage += ' manual mode OFF. '
        }
      }
    }
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'Checking combined devices' + combinedDevices.length)
    let combinedOk = false
    let device = null
    if (combinedDevices.length > 0) {
      for (let i = 0; i < combinedDevices.length; i++) {
        device = this.deviceManager.getDevice(combinedDevices[i])
        if (device && device.getIsDeviceOk()) {
          combinedOk = true
        }
      }
    } else {
      combinedOk = true
      this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'no combined devices')
    }
    if (!combinedOk) {
      this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'combined devices is not OK')
    }
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'devicesOK:' + this.devicesOK + ', combined devices:' + combinedOk)
    this.devicesOK = devsOK && combinedOk
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS.checkDevices2 ----> ' + logMessage)

    if (oldOK && !this.devicesOK && !this.manualSwitch) {
      this._kmLog(8, 'Transaction aborted - device error')
    }

    let timer = this.deviceAvailTimeout
    if (!this.inService) {
      timer = this.deviceUnavailTimeout
    }
    this.deviceManager.dmLog(
      TraceLevels.LOG_SYSTEM,
      'OOS.checkDevices2 appinservice: ' + this.inService + ' timer: ' + timer
    )
    if (this.deviceTimer) {
      clearTimeout(this.deviceTimer)
    }
    this.deviceTimerTS = new Date()
    this.deviceTimerTO = timer
    this.deviceTimer = setTimeout(this._checkDevices.bind(this), timer)

    if (appState === ApplicationState.INITIALIZE && !this.inService && !this.manualSwitch) {
      // maybe allow two failed tests
      this.deviceManager.appManager.unavailable()
    }

    /*
    if (!arguments[0]) {
      this.onDeviceCheck(this.statuses, this.isOK)
    }
    */
    this.checkStatus()
  }

  /** Check network and device status and act accordingly. Called also by {@link Heartbeat} class
   * @protected
   */
  checkStatus() {
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, 'OOS.checkStatus: ' + (this.manualSwitch ? 'manualSwitch ON' : ''))
    this.deviceManager.logMemoryUsage()
    // in no websocket then emulate application ACTIVE response (second parameter)
    this.deviceManager.appManager
      .getCurrentState(RETURN_PROMISE, this.deviceManager.noWebsocket === true)
      .then((response) => {
        let appState = parseInt(response, 10)
        let appManager = this.deviceManager.appManager
        this.heartbeatOK = this.heartbeat.isInService()
        this.deviceManager.dmLog(
          TraceLevels.LOG_SYSTEM,
          '----->OOS.checkStatus=> appState: ' +
            appState +
            ' devicesOK:' +
            this.devicesOK +
            ', heartbeatOK:' +
            this.heartbeatOK +
            ', inService:' +
            this.inService +
            (this.manualSwitch ? ', manualSwitch ON' : '') +
            ' ***'
        )
        if (this.manualSwitch) {
          if (!this.inService) {
            this.inService = true
            appManager.available()
            this.heartbeat.setInterval(this.heartbeatAvailTimeout)
            this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.checkStatus -> call this.onInService')
            setTimeout(this.onInService, 100)
          } else {
          }
        } else {
          if (this.devicesOK && this.heartbeatOK && !this.inService) {
            this.inService = true
            this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, '.OOS.checkStatus -> Application going into service.')
            if (this.firstAvail && this.firstAvailDelay !== 0) {
              this.firstAvail = false
              setTimeout(() => {
                this._kmLog(300, 'Application in service')
                appManager.available()
                this.heartbeat.setInterval(this.heartbeatAvailTimeout)
                this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.checkStatus -> call this.onInService')
                setTimeout(this.onInService, 100)
              }, this.firstAvailDelay * 1000)
            } else {
              this._kmLog(300, 'Application in service')
              appManager.available()
              this.heartbeat.setInterval(this.heartbeatAvailTimeout)
              this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.checkStatus -> call this.onInService')
              setTimeout(this.onInService, 100)
            }
          } else if (
            (!this.devicesOK || !this.heartbeatOK) &&
            (this.inService || appState === ApplicationState.AVAILABLE)
          ) {
            this.inService = false
            this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, '.OOS.checkStatus -> Application going out of service.')
            this._kmLog(301, 'Application out of service')
            if (appState === ApplicationState.ACTIVE) {
              this.onActiveOOS(!this.heartbeatOK) //it should call appManager.unavailable() by calling completeTransaction(forceUnavailable)
            } else if (appState === ApplicationState.AVAILABLE || appState === ApplicationState.INITIALIZE) {
              appManager.unavailable()
              setTimeout(this.onOutOfService, 100)
            }
            this.heartbeat.setInterval(this.heartbeatUnavailTimeout)
          }
        }
      })
      .catch((error) => {
        this.deviceManager.dmLog(TraceLevels.LOG_ALERT, '.OOS.checkStatus getCurrentState error: ' + error)
        if (error === PromiseCodes.TIMEOUT) {
        }
      })
  }

  /** Required callback for when the app needs to go out of service, but a user is currently active.
   @param {boolean} fromHeartbeat true if the reason for the OOS is the heartbeat, false if it's the devices.
   */
  onActiveOOS(fromHeartbeat) {
    if (fromHeartbeat) {
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS.onActiveOOS -> application OOS because ETS is not available'
      )
      this.completeTransaction(true)
    } else {
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS.onActiveOOS -> application OOS because some devices are not available'
      )
      this.completeTransaction(false)
    }
  }

  /** Set which devices need to be 'empty' to be considered OK. For example, if you pass in ATBPRINTER
   * to this, the app will not go into service if there are tickets still sitting and covering the exit
   * sensor.
   @param {...String} devices - device IDs to set as an 'empty' device.
   @see {DeviceManager}
   */
  setEmptyDevices(...devices) {
    this.emptyDevices = []
    for (let i = 0; i < arguments.length; i++) {
      this.emptyDevices.push(arguments[i])
    }
  }

  /** Get the Heartbeat used in this OutOfServiceManager. You can set the URL, arguments, or the
   {@link Heartbeat#onResponse} or {@link Heartbeat#isResponseInService} callbacks.
   @return {Heartbeat} the Heartbeat used in this OutOfServiceManager.
   */
  getHeartbeat() {
    return this.heartbeat
  }

  /** Set the time interval for heartbeats when the app is AVAILABLE.
   @param {number} timeout the time interval for heartbeats when the app is AVAILABLE.
   */
  setHeartbeatTime(timeout) {
    if (isInteger(timeout)) this.heartbeatAvailTimeout = timeout
  }

  /** Set the time interval for heartbeats when the app is UNAVAILABLE.
   @param {number} timeout the time interval for heartbeats when the app is UNAVAILABLE.
   */
  setHeartbeatTimeUnavail(timeout) {
    if (isInteger(timeout)) this.heartbeatUnavailTimeout = timeout
  }

  /** Set the time interval for device checks when the app is AVAILABLE.
   @param {number} timeout the time interval for device checks when the app is AVAILABLE.
   */
  setDeviceTime(timeout) {
    if (isInteger(timeout)) this.deviceAvailTimeout = timeout
  }

  /** Set the time interval for device checks when the app is UNAVAILABLE.
   @param {number} timeout the time interval for device checks when the app is UNAVAILABLE.
   */
  setDeviceTimeUnavail(timeout) {
    if (isInteger(timeout)) this.deviceUnavailTimeout = timeout
  }

  /** Returns whether or not we should be in service right now, based on network and device status.
   @return {boolean} true if we should be in service, false otherwise.
   */
  isInService() {
    return this.inService
  }

  /**
   Returns whether or not the devices are OK, regardless of whether the OOS Manager in general is in service.
   @return {boolean} true if the devices are OK, false otherwise.
   */
  isDevicesOK() {
    return this.devicesOK
  }

  /**
   Returns whether or not the heartbeat is OK, regardless of whether the OOS Manager in general is in service.
   @return {boolean} true if the heartbeat is OK, false otherwise.
   */
  isHeartbeatOK() {
    return this.heartbeatOK
  }

  /** Complete the CUSS transaction. If the application is ready for the next user, it will go available. Otherwise,
   it will go unavailable. (Note that only devices are checked at this point, not the heartbeat.)
   @param {boolean} [forceUnavailable] if true, the application will go unavailable immediately, even if
   devices are showing up OK.
   */
  completeTransaction(forceUnavailable) {
    this.deviceManager.dmLog(
      TraceLevels.LOG_SYSTEM,
      'OOS.completeTransaction start forceUnavailable: ' + forceUnavailable
    )
    if (this.deviceManager.noWebsocket === true) {
      this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS.completeTransaction exit: noWebsocket === true')
      return
    }
    if (this.deviceTimer) {
      clearTimeout(this.deviceTimer)
      this.deviceTimer = null
    }

    let appMan = this.deviceManager.getAppManager()
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.completeTransaction app manager.')
    if (this.inService && !forceUnavailable) {
      this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.completeTransaction() called. Going available.')
      appMan.available()
      this.deviceTimerTS = new Date()
      this.deviceTimerTO = this.deviceAvailTimeout
      this.deviceTimer = setTimeout(this._checkDevices.bind(this), this.deviceAvailTimeout)
    } else {
      if (forceUnavailable) {
        this.devicesOK = false //trick ourselves
        this.inService = false
        this.deviceManager.dmLog(
          TraceLevels.LOG_TRACE,
          '.OOS.completeTransaction() called. Forcing app into unavailable state.'
        )
      } //don't need to trick ourselves, devices/heartbeat aren't OK.
      else {
        if (!this.heartbeatOK) {
          if (!this.devicesOK) {
            this.deviceManager.dmLog(
              TraceLevels.LOG_TRACE,
              '.OOS.completeTransaction() called. Devices and heartbeat not OK, going unavailable.'
            )
          } else {
            this.deviceManager.dmLog(
              TraceLevels.LOG_TRACE,
              '.OOS.completeTransaction() called. Heartbeat not OK, going unavailable.'
            )
          }
        } else {
          this.deviceManager.dmLog(
            TraceLevels.LOG_TRACE,
            '.OOS.completeTransaction() called. Devices not OK, going unavailable.'
          )
        }
      }
      appMan.unavailable()
      if (this.heartbeat) {
        this.heartbeat.setInterval(this.heartbeatUnavailTimeout)
      }
      this.deviceTimerTS = new Date()
      this.deviceTimerTO = this.deviceUnavailTimeout
      this.deviceTimer = setTimeout(this._checkDevices.bind(this), this.deviceUnavailTimeout)
    }
  }

  /**
   Tell the OOSManager whether or not to check devices while the app is {@link ApplicationManager.ACTIVE}.
   The app goes into this state when a user is using the application. By default the OOSManager will not
   check devices in this state, as the assumption is that the app would detect if a necessary device is
   malfunctioning just before using it.
   @param {boolean} checkDevices true to check devices while ACTIVE, false otherwise.
   */
  setCheckDeviceActive(checkDevices) {
    this.checkDeviceActive = checkDevices
  }

  /**
   Callback that trips whenever a device check is executed. You will be passed an object that maps device IDs
   to the status of that device. E.g.:
   <br/><code>if (statuses[DeviceManager.CARDREADER] == this.componentRCMEDIAFULL) //do something
   <br/>if (!isOK[DeviceManager.ATBPRINTER]) //do something
   </code>
   @param {Object} statuses a mapping of device IDs to their current statuses.
   @param {Object} isOK a mapping of device IDs to the result of the isOK() call.
   */
  onDeviceCheck(statuses, isOK) {}

  /**
   Get the last known status of the given device. This will update itself each device check.
   @param {number} device the device ID to check.
   @return {number} the current known status of the device.
   */
  getDeviceStatus(device) {
    return this.statuses[device] || -1
  }

  /**
   Get the last known 'isOK' status of the given device. This will update itself each device check.
   @param {number} device the device ID to check.
   @return {boolean} the current known 'isOK' status of the device.
   */
  isDeviceOK(device) {
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.isDeviceOK() called: ' + device)
    let devices = this.deviceManager.devices
    for (let i = 0; i < devices.length; i++) {
      //this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.isDeviceOK() dev: ' + devices[i].deviceId + ' isOK: ' + devices[i].isDeviceOk)
      if (device === devices[i].deviceId) {
        return devices[i].isDeviceOk
      }
    }
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, '.OOS.isDeviceOK() device not found (return false): ' + device)
    return false
    //return this.isOK[device] || false
  }

  /**
   Send the message to KM
   @param {number} code - message number.
   @param {String} message text.
   */
  _kmLog(code, message) {
    this.deviceManager.appManager.sendApplicationLog(100, 'CDS_APPLOG,' + code + ', ' + message)
  }

  /** Set the callback function in the heartbeat object - to check if the ETS is in service
   @param {function(json: Object)} cb - it will be called each time the heartbeat response is received to verify the status of the ETS.
   */
  setIsHBResponseInService(cb) {
    this.isHBResponseInService = cb
  }

  /** Set the callback function in the heartbeat object - to update app data from the heartbeat response (server time, version)
   @param {function(version: String, etsTime: number, delta: number)} cb - it will be called each time the heartbeat response is received.
   */
  setServerDataUpdate(cb) {
    this.serverDataUpdate = cb
  }

  /**
   Logs the message
   @param {String} msg - message to log.
   */
  _logmsg(msg) {
    this.deviceManager.dmLog(TraceLevels.LOG_TRACE, msg)
  }

  _onRequiredDeviceOK(deviceName) {
    this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS._onRequiredDeviceOK: ' + deviceName)
    let now = new Date()
    if (
      this.deviceTimerTS > 0 &&
      this.deviceTimerTO > 0 &&
      now - this.deviceTimerTS < this.deviceTimerTO - this.deviceTimerMaxDelta
    ) {
      if (this.deviceTimer) {
        clearTimeout(this.deviceTimer)
        this.deviceTimer = null
      }
      this.deviceTimerTS = new Date()
      this.deviceTimerTO = 0
      this.deviceTimer = setTimeout(this._checkDevices.bind(this), 0)
    } else {
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS._onRequiredDeviceOK: delta: ' +
          (this.deviceTimerTS + this.deviceTimerTO - (now + this.deviceTimerMaxDelta))
      )
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS._onRequiredDeviceOK: elapsed: ' + (now - this.deviceTimerTS)
      )
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS._onRequiredDeviceOK: this.deviceTimerTS: ' + this.deviceTimerTS
      )
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS._onRequiredDeviceOK: this.deviceTimerTO: ' + this.deviceTimerTO
      )
      this.deviceManager.dmLog(TraceLevels.LOG_SYSTEM, 'OOS._onRequiredDeviceOK: now:                ' + now)
      this.deviceManager.dmLog(
        TraceLevels.LOG_SYSTEM,
        'OOS._onRequiredDeviceOK: this.deviceTimerMaxDelta: ' + this.deviceTimerMaxDelta
      )
    }
  }

  /**
  Set the maximum difference (delta) time between server and local time (in ms). Used in adjusting local time in logs.
  @param {number} newServerTimeDeltaMaxDiff in ms. Used by heartbeat.
  @protected
  */
  setServerTimeDeltaMaxDiff(newServerTimeDeltaMaxDiff) {
    this.serverTimeDeltaMaxDiff = newServerTimeDeltaMaxDiff
  }

  /**
  Set the minimum change in delta time between server and local time (in ms). Used in adjusting local time in logs.
  @param {number} newServerTimeDeltaMinChange in ms. Used by heartbeat.
  @protected
  */
  setServerTimeDeltaMinChange(newServerTimeDeltaMinChange) {
    this.serverTimeDeltaMinChange = newServerTimeDeltaMinChange
  }

  /**
  Set the first available delay call.
  @param {number} firstAvailDelaySec in sec.
  @protected
  */
  setFirstAvailDelay(firstAvailDelaySec) {
    this.firstAvailDelay = firstAvailDelaySec
  }
}
