/** @class
 *  Device representing a LaserPrinter
 */
import device from 'devices/device'
import {deviceIds, TraceLevels } from 'constants/Constants'
import LaserPrinterListener from 'listeners/LaserPrinterListener'

/**
 *  Device representing an Laser Printer
 *  @extends {Device}
 */
export default class LaserPrinter extends device
{
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Laser Printer'
    /**
     * Device id - deviceIds.LASER_PRINTER {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.LASER_PRINTER
    this.setListener(new LaserPrinterListener())
  }

  /**
   * Print file byte stream
   * @param {String} theStream - data stream to print
   */
  printFileByteStream(theStream)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printFileByteStream(): called')
      this.socket.sendCommand(this.deviceId, 'printFileByteStream', theStream, true)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printFileByteStream() exception: ' + e)
    }
  }

  /**
   * Print file byte stream in base64 format
   * @param {String} theStream - data stream to print in base64 format
   */
  printFileByteStreamBase64(theStream)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printFileByteStreamBase64(): called')
      this.socket.sendCommand(this.deviceId, 'printFileByteStream', theStream, true)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printFileByteStreamBase64 exception: ' + e)
    }
  }
  
  /**
   * Print jpg stream
   * @param {String} theStream - jpg to print in base64 format
   * @param {Number} timeout - print timeout
   * @param {Number} toneScale - e.g: 20
   * @param {Number} dpi - e.g: 300
   */
  printImageStream(theStream, timeout, toneScale, dpi)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printImageStream(): called timeout: ' + timeout + ' toneScale: ' + toneScale + ' dpi: ' + dpi)
      if (timeout === undefined)
        timeout = 20000
      if (toneScale === undefined)
        this.socket.sendCommand(this.deviceId, 'printImageStream', theStream, timeout)
      else
        this.socket.sendCommand(this.deviceId, 'printImageStream', theStream, timeout, toneScale, dpi)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printImageStream() exception: ' + e)
    }
  }
  
  /**
   * Print pdf stream
   * @param {String} theStream - pdf to print in base64 format
   * @param {Number} [timeout] - print timeout
   * @param {Number} [formHeight] - form height
   * @param {Number} [formWidth] - form width
   * @param {Number} [xMargin]   - x margin in pixels
   * @param {String} [printOptions] - print options e.g. :ORIENTATION=PORTRAIT
   */
  printPDFStream(theStream, timeout, formHeight, formWidth, xMargin, printOptions) {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'printPDFStream(): called timeout: ' + timeout + ' formHeight: ' + formHeight + ' formWidth: ' + formWidth + ' xMargin: ' + xMargin + ' printOptions: ' + printOptions)
      if (timeout === undefined)
        this.socket.sendCommand(this.deviceId, 'printPDFStream', theStream)
      else if (formHeight === undefined)
        this.socket.sendCommand(this.deviceId, 'printPDFStream', theStream, timeout)
      else if (formWidth === undefined)
        this.socket.sendCommand(this.deviceId, 'printPDFStream', theStream, timeout, formHeight)
      else if (xMargin === undefined)
        this.socket.sendCommand(this.deviceId, 'printPDFStream', theStream, timeout, formHeight, formWidth)
      else if (printOptions === undefined)
        this.socket.sendCommand(this.deviceId, 'printPDFStream', theStream, timeout, formHeight, formWidth, xMargin)
      else
        this.socket.sendCommand(this.deviceId, 'printPDFStream', theStream, timeout, formHeight, formWidth, xMargin, printOptions)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'printPDFStream() exception: ' + e)
    }
  }
  
   /**
    *  Asynch call to offer the page to the user
    *  @param {int} timeout Timeout to wait in msec.
    */
  offer(timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_TRACE, 'offer(): ' + timeout)
      this.socket.sendRequest(this.deviceId, 'offer', timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'offer() exception: ' + e)
    }
  }

}
