import { TestButtons } from 'components'
import BoardingInfo from 'components/BoardingInfo/BoardingInfo'
import ItineraryInfo from 'components/ItineraryInfo/ItineraryInfo'
import { DynamicImage, PageContent, PageFooter, PageSubContent, Spacer } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, isEmpty } from 'utils/helper'
import BackAct from './BackAct'
import CancelAct from './CancelAct'
import ConfirmAct from './ConfirmAct'
import './footer.scss'
import HelpAct from './HelpAct'
import LangAct from './LangAct'
import QuitAct from './QuitAct'
import SkipAct from './SkipAct'

export const Button = { QUIT: 'QUIT', LANG: 'LANG', HELP: 'HELP', LOGO: 'LOGO' }

const Footer = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const isLandscape = useCheckLandscape()
  /* const dispatch = useDispatch()
  const locale = useSelector(state => state.localData.locale) */
  const { formatMessage } = intl
  const {
    quitBtnText,
    quitBtnStyle,
    quitAction,
    langBtnStyle,
    isBackRequired,
    backBtnText,
    backAction,
    isSkipRequired,
    skipBtnText,
    skipAction,
    skipBtnStyle,
    isConfirmRequired,
    confirmBtnText,
    confirmAction,
    disableConfirmAction,
    cancelBtnText,
    cancelAction,
    confirmIcon,
    helpAction,
    testData,
    customBrandLogo,
    disableBackAction
  } = props
  const isQuitRequired = props.isQuitRequired ?? true
  const isLangRequired = props.isLangRequired ?? true
  const isSkipInCenter = props.isSkipInCenter ?? false
  const isSkipDisabled = props.isSkipDisabled ?? false
  const btnSpace = props.btnSpace ?? 'btn-nav-space'
  const displayItinerary = props.displayItinerary ?? true
  const confirmBtnStyle = props.confirmBtnStyle ?? 'confirm-btn'
  const backBtnStyle = props.backBtnStyle ?? 'back-btn'
  const cancelBtnStyle = props.cancelBtnStyle ?? 'cancel-btn'
  const isCancelRequired = props.isCancelRequired ?? false
  const isHelpRequired = props.isHelpRequired ?? true
  const atcAreaCollaps = props.atcAreaCollaps ?? false
  const displayBoardingInfo = props.displayBoardingInfo ?? false
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  const centerButtonGroupDefault = [
    [{ key: Button.QUIT }, { key: Button.LANG }, { key: Button.HELP }],
    null,
    [{ key: Button.LOGO }]
  ]

  const {
    isItineraryRequired: showItinerary,
    isQuitRequired: showQuit,
    isLangRequired: showLang,
    isHelpRequired: showHelpBtn
  } = getButtonDisplayConfig({
    themeContext,
    displayItinerary,
    isQuitRequired,
    isLangRequired,
    screenId
  })

  const centerButtonGroup = themeContext.Footer.centerButtonGroup
    ? themeContext.Footer.centerButtonGroup
    : centerButtonGroupDefault

  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const imageLocation = `${themeContext.BrandLogo}`

  const brandLogo = <DynamicImage imageName={imageLocation} width={71} height={12} />

  const carrierCode = useSelector((state) => state.localData.carrierCode)
  const kioskInfo = useSelector((state) => state.kioskInfo)

  const airlineLogo = config.useDefaultAirlineLogo ? (
    themeContext.AirlineLogo ? (
      <DynamicImage
        imageName={themeContext.AirlineLogo.logo}
        height={themeContext.AirlineLogo.height[ratioKey]}
        width={themeContext.AirlineLogo.width[ratioKey]}
        backgroundPosition={'center right'}
      />
    ) : (
      ''
    )
  ) : carrierCode ? (
    <DynamicImage
      imageName={'airlineLogo-' + carrierCode + '.png'}
      height={themeContext.AirlineLogo.height[ratioKey]}
      width={themeContext.AirlineLogo.width[ratioKey]}
      backgroundPosition={'center right'}
    />
  ) : (
    ''
  )

  const airportLogo = config.useDefaultAirportLogo ? (
    <DynamicImage
      imageName={themeContext.airportLogo}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  ) : (
    <DynamicImage
      imageName={`airportLogo-${kioskInfo.airportCode}.png`}
      height={themeContext.airportLogoHeight}
      width={themeContext.airportLogoWidth}
      backgroundPosition={'center right'}
    />
  )

  const footerLogo = config.swapLogo ? (
    <div className={'logo-wrapper-footer'}>{airportLogo}</div>
  ) : (
    <div className={'logo-wrapper-footer'}>{airlineLogo}</div>
  )

  const buttonSection = (
    <PageContent
      key="testButtons"
      flexFlow="row"
      justifyContent={
        themeContext.Footer.buttonSectionJustifyContent
          ? themeContext.Footer.buttonSectionJustifyContent[ratioKey]
          : isLandscape
          ? 'flex-end'
          : 'center'
      }
    >
      {isBackRequired ? (
        <BackAct
          backBtnStyle={backBtnStyle}
          backBtnText={isEmpty(backBtnText) ? formatMessage(messages.buttonBack) : backBtnText}
          backAction={backAction}
          disableAction={disableBackAction}
        />
      ) : (
        ''
      )}
      {isCancelRequired ? (
        <CancelAct
          cancelBtnText={isEmpty(cancelBtnText) ? formatMessage(messages.buttonCancel) : cancelBtnText}
          cancelAction={cancelAction}
          cancelBtnStyle={cancelBtnStyle}
        />
      ) : (
        ''
      )}
      {isBackRequired || isSkipRequired || isCancelRequired ? <Spacer width="20px" /> : ''}
      {isConfirmRequired ? (
        <ConfirmAct
          confirmBtnText={isEmpty(confirmBtnText) ? formatMessage(messages.buttonConfirm) : confirmBtnText}
          confirmAction={confirmAction}
          disableAction={disableConfirmAction}
          confirmBtnStyle={confirmBtnStyle}
          confirmIcon={confirmIcon}
        />
      ) : (
        ''
      )}
      {isSkipRequired && !isSkipInCenter ? (
        <SkipAct
          skipBtnText={isEmpty(skipBtnText) ? formatMessage(messages.buttonSkip) : skipBtnText}
          skipAction={skipAction}
          skipBtnStyle={skipBtnStyle}
          isDisabled={isSkipDisabled}
        />
      ) : (
        ''
      )}
      {testData ? <TestButtons data={testData} showPopup={true} showTestButton={config.showTestButtons} /> : ''}
    </PageContent>
  )

  const quitButton = showQuit ? (
    <QuitAct quitBtnText={quitBtnText} quitBtnStyle={quitBtnStyle} quitAction={quitAction} />
  ) : (
    ''
  )

  const langButton = showLang ? <LangAct langBtnStyle={langBtnStyle} /> : ''

  const helpButton = showHelpBtn ? (
    <HelpAct
      helpBtnText={props.helpBtnText ? props.helpBtnText : formatMessage(messages.buttonHelp)}
      helpAction={helpAction}
    />
  ) : (
    ''
  )

  const renderCenterButtonGroup = () => {
    let mainGroup = []

    centerButtonGroup.forEach((column) => {
      let group = []
      if (column) {
        column.forEach((item, i) => {
          switch (item.key) {
            case Button.QUIT:
              group.push(<div key={i}>{quitButton}</div>)
              break
            case Button.LANG:
              group.push(<div key={i}>{langButton}</div>)
              break
            case Button.HELP:
              group.push(<div key={i}>{helpButton}</div>)
              break
            case Button.LOGO:
              group.push(<div key={i}>{footerLogo}</div>)
              break
            default:
              break
          }
        })
        mainGroup.push(group)
      } else {
        mainGroup.push(<PageContent padding="0"></PageContent>)
      }
    })

    return (
      <PageSubContent
        flexFlow="row"
        width="100%"
        padding="0"
        height={themeContext.Footer.footerBtnHeight[ratioKey]}
        style={{
          backgroundColor: themeContext.Footer.bgColorCenter,
          boxShadow: themeContext.Footer.disableBoxShadow ? '' : 'inset 0px 0px 3px #0000001c'
        }}
      >
        {mainGroup[0]}
        {mainGroup[1]}
        {mainGroup[2]}
      </PageSubContent>
    )
  }

  return (
    <PageFooter height={themeContext.Footer.footerHeight[ratioKey]}>
      <PageSubContent
        flexFlow={
          themeContext.Footer.footerFlexFlow
            ? themeContext.Footer.footerFlexFlow[ratioKey]
            : isLandscape
            ? 'row'
            : 'column-reverse'
        }
        width="100%"
        height={atcAreaCollaps ? '' : themeContext.Footer.atcHeight[ratioKey]}
        padding={themeContext.Footer.atcPadding[ratioKey]}
      >
        {showItinerary ? <ItineraryInfo /> : <div></div>}
        {displayBoardingInfo ? <BoardingInfo /> : ''}
        {buttonSection}
      </PageSubContent>

      {renderCenterButtonGroup()}

      <PageSubContent flexFlow="row" padding="0" width="100%" height={themeContext.Footer.brandHeight[ratioKey]}>
        {customBrandLogo ? customBrandLogo : themeContext.ShowEMBlogo ? brandLogo : ''}
      </PageSubContent>
    </PageFooter>
  )
}

Footer.propTypes = {
  isQuitRequired: PropTypes.bool,
  quitBtnText: PropTypes.string,
  quitBtnStyle: PropTypes.string,
  quitAction: PropTypes.func,
  backAction: PropTypes.func,
  skipAction: PropTypes.func,
  confirmAction: PropTypes.func,
  helpAction: PropTypes.func,
  disableConfirmAction: PropTypes.bool,
  isBackRequired: PropTypes.bool,
  isLangRequired: PropTypes.bool,
  isSkipRequired: PropTypes.bool,
  isSkipInCenter: PropTypes.bool,
  isConfirmRequired: PropTypes.bool,
  backBtnText: PropTypes.string,
  backBtnStyle: PropTypes.string,
  skipBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  btnSpace: PropTypes.string,
  isSkipDisabled: PropTypes.bool,
  skipBtnStyle: PropTypes.string,
  displayStyle: PropTypes.string,
  confirmBtnStyle: PropTypes.string,
  testData: PropTypes.array,
  displayItinerary: PropTypes.bool,
  confirmBtnStyle: PropTypes.string,
  backBtnStyle: PropTypes.string,
  isCancelRequired: PropTypes.bool,
  cancelBtnText: PropTypes.string,
  displayBoardingInfo: PropTypes.bool,
  customBrandLogo: PropTypes.element
}

export default Footer
