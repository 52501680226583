import { updateError } from 'actions/commonActions'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { updateLocalData } from 'actions/localActions'
import { DynamicImage, PageHeader, PageSubTitle, PageTitle, Spacer } from 'components/styledComponents'
import { OOS, TransitionCodes } from 'constants/Constants'
import { ErrCodes, SourceType } from 'constants/Errors'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, playSound } from 'main'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, navigate, populateItineraryInfo } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { Footer } from '../footer'

const mapTransitionToPage = (source, transition, appFlow) => {
  let result = ''
  switch (transition) {
    case TransitionCodes.BARCODE_RETRY:
      if (appFlow === 5) {
        result = 'ErrorBPMismatch'
      } else {
        result = 'END_TXN'
      }
      break
    case TransitionCodes.CUSTOM_4:
      result = 'genericCommand'
      break
    case TransitionCodes.SBD_PROCESS:
      result = 'PutBagOnBelt'
      break
    case TransitionCodes.SCAN_BP:
      result = 'ScanBoardingPass'
      break
    case TransitionCodes.PAX_VALIDATION:
      result = 'ScanDocuments'
      break
    case TransitionCodes.AGENT_CHECK:
      result = 'AgentScan'
      break
    case TransitionCodes.END_TXN:
    case null:
    default:
      result = 'END_TXN'
      break
  }
  return result
}

const mapErrImage = (errCode, sbdModel) => {
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const warningSize = useMedia(null, [400, 350, 400], 400)
  const themeContext = useContext(ThemeContext)
  let location = `generic-agent.svg`
  switch (errCode) {
    case ErrCodes.BAG_NOSCAN:
    case ErrCodes.TRANSFER_NOT_SUPPORT:
      // 450*450
      // errImgClass = 'emb_animation_wrapper_bottom'
      // if (sbdModel === 'VBD') {
      //   location = `BagtagNotFound490VBD.png`
      // } else if (sbdModel === 'VBDR') {
      //   location = `BagtagNotFound490VBDR.png`
      // } else {
      //   location = `BagtagNotFound450.png`
      // }
      location = themeContext.ImageError.bagNoScan
      break
    // case ErrCodes.BAG_TOO_TALL:
    //   location = `${themeContext.AnimationPath}/${sbdModel}/bagPlacement_noScan.gif`
    //   break
    case ErrCodes.BAG_UNDERHEIGHT:
    case ErrCodes.BAG_UNDERHEIGHT_USEBIN:
    case ErrCodes.BAG_UNDERHEIGHT_ADDBAG:
    case ErrCodes.BAG_SHORT:
      location = themeContext.ImageError.underHeight
      break
    case ErrCodes.BAG_UNDERWEIGHT:
    case ErrCodes.BAG_UNDERWEIGHT_USEBIN:
    case ErrCodes.BAG_UNDERWEIGHT_ADDBAG:
      location = themeContext.ImageError.underWeight
      break
    case ErrCodes.BAG_MAX_WEIGHT:
    case ErrCodes.BAG_MAX_WEIGHT_ADDBAG:
    case ErrCodes.MAX_POOL_WEIGHT:
    case ErrCodes.BAG_OVERWEIGHT:
    case ErrCodes.OVERWEIGHT_AGENT:
    case ErrCodes.OVERWEIGHT_LAST:
      location = themeContext.ImageError.overWeight
      break
    case ErrCodes.BAG_LONG:
    case ErrCodes.BAG_LONG_ADDBAG:
      location = themeContext.ImageError.tooLong
      break
    case ErrCodes.NO_BAGS:
    case ErrCodes.RECORD_NOT_FOUND:
    case ErrCodes.TAG_ALREADY_ACTIVE:
      location = themeContext.ImageError.noBags
      break
    case ErrCodes.OVERWEIGHT_AGENT:
    case ErrCodes.OVERWEIGHT_LAST:
      location = themeContext.ImageError.overWeight
      break
    case ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED:
      location = themeContext.ImageError.generic
      break
    case ErrCodes.BAG_TOO_TALL:
    case ErrCodes.BAG_RATIO_TOO_HIGH:
      location = themeContext.ImageError.tooTall
      break
    case ErrCodes.WRONG_AIRPORT:
    case ErrCodes.ERR_STATION:
      location = themeContext.ImageError.wrongAirport
      break
    case ErrCodes.TOO_EARLY_BAGGAGE:
      location = themeContext.ImageError.tooEarly
      break
    case ErrCodes.TOO_LATE_BAGGAGE:
      location = themeContext.ImageError.tooLate
      break
    case ErrCodes.CONVEYOR_ESTOP:
      location = themeContext.ImageError.eStop
      break
    case ErrCodes.BAG_JAMMED:
      location = themeContext.ImageError.bagJammed
      break
    // case ErrCodes.CONVEYOR_INTRUSION:
    //   result = <img className="emb_animation_drawbox" src={IntrusionAnimation} />
    default:
      location = themeContext.ImageError.generic
      break
  }
  if (location === themeContext.ImageError.generic) {
    return (
      <DynamicImage
        imageName={location}
        cssName={'emb_animation_drawbox'}
        width={animationSize.width}
        height={animationSize.height}
      />
    )
  } else {
    return (
      <DynamicImage
        imageName={location}
        cssName={'emb_animation_drawbox'}
        width={animationSize.width}
        height={animationSize.height}
      />
    )
  }
}

const GenericErrors = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const errorDetails = useSelector((state) => state.errorDetails)
  const locale = useSelector((state) => state.localData.locale)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const iconSize = useMedia(null, [50, 40, 45], 45)
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const errIcon = (
    <DynamicImage imageName={`warningError.png`} cssName={'errorPage_iconWrapper'} width={iconSize} height={iconSize} />
  )
  const errImage = mapErrImage(errorDetails.errCode, sbdModel)
  const [errMsgTitle, setErrMsgTitle] = useState('')
  const [errMsgSubTitle, setErrMsgSubTitle] = useState('')
  let timeoutRef = null
  let SBDAppManager = getSBDAppMan()

  const getErrMsg = (errorCode, msgs, language) => {
    let errMsg = {
      errorTitle: '',
      errorSubTitle: ''
    }
    if (msgs && msgs.length > 0) {
      let message = msgs.filter((msg) => msg.languageCode.toUpperCase() === language.toUpperCase())
      if (message.length > 0) {
        let msgParts = message[0].screenMsg.split('***')
        if (msgParts.length > 1) {
          errMsg = {
            errorTitle: msgParts[0],
            errorSubTitle: msgParts[1]
          }
        } else {
          errMsg = {
            errorTitle: msgParts[0],
            errorSubTitle: ''
          }
        }
      }
    } else {
      let msgParts = []
      let msgText = ''
      msgText = intl.formatMessage(messages.Error_Default)
      if (msgText.length > 0) {
        msgParts = msgText.split('\n\n')
      }
      if (msgParts.length == 1) {
        errMsg.errorTitle = msgParts[0]
      } else if (msgParts.length > 1) {
        errMsg.errorTitle = msgParts[0]
        errMsg.errorSubTitle = msgParts[1]
      }
    }

    return errMsg
  }

  const setErrorMessage = () => {
    let errorMessage = {
      errorTitle: '',
      errorSubTitle: ''
    }
    if (errorDetails.sourceType === SourceType.FETCH) {
      if (Array.isArray(errorDetails.msg)) {
        errorMessage = getErrMsg(errorDetails.errCode, errorDetails.msg, locale)
      }
    } else {
      let msgParts = []
      let msgText = ''
      if (messages[errorDetails.msg]) {
        if (errorDetails.params) {
          msgText = intl.formatMessage(messages[errorDetails.msg], errorDetails.params)
        } else {
          msgText = intl.formatMessage(messages[errorDetails.msg])
        }
      }
      if (msgText.length > 0) {
        msgParts = msgText.split('\n\n')
      }
      if (msgParts.length == 1) {
        errorMessage.errorTitle = msgParts[0]
      } else if (msgParts.length > 1) {
        errorMessage.errorTitle = msgParts[0]
        errorMessage.errorSubTitle = msgParts[1]
      }
    }
    return errorMessage
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) - error source type = "' + errorDetails.sourceType + '"')
    const errorMessage = setErrorMessage()
    setErrMsgTitle(errorMessage.errorTitle)
    setErrMsgSubTitle(errorMessage.errorSubTitle)

    /** accessibility **/
    handleAccessibility()
  }, [locale, errorDetails])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const handleAccessibility = () => {
    const isConfirmBtnRequired =
      !(errorDetails && errorDetails.params && errorDetails.params.confirmDisable) && !config.isTransferEnabled

    const screenId = getScreenId(location.pathname)
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined
    console.log('itinerary', itinerary, bagWeightUnit, bagWeight)
    const errorMessage = setErrorMessage()
    const genericErrors = [errorMessage.errorTitle, errorMessage.errorSubTitle]
    const accDef = {
      pathName: 'error',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: isConfirmBtnRequired ? 'OneDynamicTextWithOKBtn' : 'OneDynamicText',
            textParameters: [genericErrors.join('. ')]
          }
        ]
      }
    }

    if (isConfirmBtnRequired) {
      accDef.sequenceDef.sequence.push({
        id: 'confirmBtn',
        text: doneBtnText(),
        buttonId: 'confirmBtn'
      })
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) - errCode = "' + errorDetails.errCode + '"')
    logEvent('Error, ' + errorDetails.errCode)
    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '>> (GenericError.js) -  UNMOUNTED ...')
      getDeviceManager()
        .getAppManager()
        .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
      dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
      dispatch(updateLocalData('updateBqCommand', ''))
      dispatch(updateError(null)) // RESET ERROR
    }
  }, [])

  const handleExitPage = () => {
    const errTransition = errorDetails.transition
    const source = errorDetails.source
    const sourceType = errorDetails.sourceType
    console.log('WHERE ERROR PAGE GO TO:::', errorDetails)
    dispatch(updateLocalData('updateBagWeight', '0'))
    if (sourceType === SourceType.FETCH) {
      const transitionPage = mapTransitionToPage(source, errTransition, appFlow)
      if (transitionPage === 'END_TXN') {
        SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        //getEventLogger().clientSessionId = null
      } else {
        if (transitionPage === 'PutBagOnBelt') {
          // when we want to go back to putbagonbelt page, remember to set the flow to 2.
          if (baggageLoadedStatus) {
            navigate('BagProcessing', 2)
          } else {
            navigate('PutBagOnBelt', 2)
          }
        } else if (transitionPage === 'genericCommand') {
          let updatedItem = {}
          validatePaxResult.forEach((item) => {
            if (item.ordinal === currentPassenger.ordinal) {
              updatedItem = item
            }
          })

          const updatePaxStatus = {
            ordinal: currentPassenger.ordinal,
            docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            faceMatch:
              updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED ||
              updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
          }
          dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
        } else {
          navigate(transitionPage)
        }
      }
    } else {
      if (errTransition) {
        if (errTransition === 'PutBagOnBelt') {
          if (baggageLoadedStatus) {
            navigate('BagProcessing', 2)
          } else {
            navigate('PutBagOnBelt', 2)
          }
        } else if (errTransition === 'END_TXN') {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
        } else if (errTransition === 'OOS') {
          SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, OOS)
        } else if (errTransition === 'Retry') {
          navigate('welcome', 0)
        } else {
          navigate(errTransition)
        }
      } else {
        SBDAppManager.doQuit(errorDetails.errCode, errorDetails.msg, '')
      }
    }
  }

  const handleActions = (e) => {
    if (timeoutRef !== null) {
      timeoutRef.getWrappedInstance().resetTimer()
    }
    appLog(TraceLevels.LOG_TRACE, '(ErrorPage.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"')

    playSound.beepOK()

    /// TEST CODE

    // end OF TEST CODE

    switch (e.currentTarget.id) {
      case 'confirmBtn':
        handleExitPage()
        break

      default:
        break
    }
  }

  const doneBtnText = () => {
    const errTransition = errorDetails.transition
    const source = errorDetails.source
    const sourceType = errorDetails.sourceType

    if (sourceType === SourceType.FETCH) {
      const transitionPage = mapTransitionToPage(source, errTransition, appFlow)
      if (transitionPage === 'END_TXN') {
        return intl.formatMessage(messages.buttonOk)
      } else {
        if (transitionPage === 'PutBagOnBelt') {
          // when we want to go back to putbagonbelt page, remember to set the flow to 2.
          if (baggageLoadedStatus) {
            return intl.formatMessage(messages.buttonRetry)
          } else {
            return intl.formatMessage(messages.buttonOk)
          }
        } else if (transitionPage === 'genericCommand') {
          return intl.formatMessage(messages.buttonContinue)
        } else {
          return intl.formatMessage(messages.buttonOk)
        }
      }
    } else {
      if (errTransition) {
        if (errTransition === 'PutBagOnBelt') {
          if (baggageLoadedStatus) {
            return intl.formatMessage(messages.buttonRetry)
          } else {
            return intl.formatMessage(messages.buttonContinue)
          }
        } else if (errTransition === 'END_TXN') {
          return intl.formatMessage(messages.buttonOk)
        } else if (errTransition === 'OOS') {
          return intl.formatMessage(messages.buttonOk)
        } else {
          return intl.formatMessage(messages.buttonOk)
        }
      } else {
        return intl.formatMessage(messages.buttonOk)
      }
    }
  }

  const animationSection = errImage
  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {errMsgTitle}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {errMsgSubTitle}
      </PageSubTitle>
    </>
  )

  const header = (
    <PageHeader>
      {errIcon}
      <Spacer width="10px" />
      {intl.formatMessage(messages.ErrorTitle)}
    </PageHeader>
  )

  const footer = (
    <Footer
      isQuitRequired={true}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={
        !(errorDetails && errorDetails.params && errorDetails.params.confirmDisable) && !config.isTransferEnabled
      }
      isLangRequired={true}
      confirmBtnText={doneBtnText()}
      confirmAction={handleActions}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '100%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '100%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default GenericErrors
