import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** AEA bagdrop listener.
 *  @extends {Listener}
 */
export default class AEABagdropListener extends Listener
{
  
  /**
   *  Default command completed callback.
   *  @param {string} response result of the sendAEA or setupAEA commands
   *  @example HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=0#SD=NORM
   */
  commandCompleted(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'AEABagdropListener.commandCompleted() called')
    this.setEvent('commandCompleted', response)
  }

  /**
   *  Default unsolicited message callback.
   *  @param {string} message unsolicited
   *  @example HDCSQNI#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#SD=BAGP
   */
  unsolicitedMessage(message)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'AEABagdropListener.unsolicitedMessage() called')
    this.setEvent('unsolicitedMessage', message)
  }

  /**
   *  Default sendAEA callback.
   *  @param {String} data - array [rc, cmd]
   *    <ul><li>rc: the return code of the executed command.</li>
   *    <li>cmd: executed command</li></ul>
   */
  sendAEA(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'AEABagdropListener.sendAEA() called')
    this.setEvent('sendAEA', data)
  }
  
  /**
   *  Default setupAEA callback.
   *  @param {String} data - array [rc, cmd]
   *    <ul><li>rc: the return code of the executed command.</li>
   *    <li>cmd: executed command</li></ul>
   */
  setupAEA(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'AEABagdropListener.setupAEA() called')
    this.setEvent('setupAEA', data)
  }
}
