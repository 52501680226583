import React, { useEffect } from 'react'
import { DynamicImage } from '../styledComponents'

const Animation = ({ width, height, imageName, isVideo = false }) => {
  return isVideo || imageName.indexOf('.ogg') > 0 ? (
    <video width={width} height={height} autoPlay muted loop>
      <source src={`images/${imageName}`} type="video/ogg"></source>
    </video>
  ) : (
    <DynamicImage width={width} height={height} imageName={imageName} />
  )
}

export default Animation
