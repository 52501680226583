import React, { useRef, useEffect, useState, useContext } from 'react'
import { Page, PageContent } from 'components/styledComponents'
import { Routes } from 'react-router-dom'
import 'resources/scss/main.scss'
import { useIntl } from 'react-intl'
import { getVersion, getAccessibilityDevice, getSBDAppMan, getTimeoutMgr, getDeviceManager } from 'main'
import { PopupTimer } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { getScreenId } from 'utils/helper'
import { END_TXN_REASON } from 'constants/Constants'
import Header from 'custom/layouts/header/header'
import { useLocation, useRoutes } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { appLog, logEvent } from 'utils/Logger'
import { deviceIds, TraceLevels } from 'embross-device-manager'

const CoreLayout = (props) => {
  const { formatMessage } = useIntl()
  const timeoutManger = getTimeoutMgr()
  const tapTimer = useRef(null)
  const tapCount = useRef(0)
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const kioskInfo = useSelector((state) => state.kioskInfo)
  const disableHeaderList = config.disableHeaderTextList ? config.disableHeaderTextList : []
  const disableTimeoutPaths = kioskInfo.PSAM
    ? ['outofservice', 'inactive', config.firstScreen]
    : ['outofservice', 'inactive', 'passengerselection']
  const disableTimeoutPopupPaths = kioskInfo.PSAM
    ? ['error', 'pleasewait']
    : ['error', 'pleasewait', config.firstScreen]
  const [isShowTimeoutPopup, setIsShowTimeoutPopup] = useState(false)
  const [isShowHeader, setIsShowHeader] = useState(true)
  const timeoutDuration = useRef(config.timeoutDefault)
  const showQuit = useRef(true)
  const [headerText, setHeaderText] = useState('BagdropTitle')
  const location = useLocation()
  const routes = useRoutes(props.routes)
  //functions
  const timeoutCallback = () => {
    console.log('popup timeoutCallback from CoreLayout')
  }
  const getTimeoutDuration = (screenId) => {
    var timeoutMS = 0
    switch (screenId) {
      /* case 'popup':
        timeoutMS = config.timeoutPopup
        break*/
      case 'pleasewait':
        timeoutMS = config.timeoutPleaseWait
        break
      case 'agentoverride':
        timeoutMS = config.timeoutAgentOverride ? config.timeoutAgentOverride : 300
        break
      case 'agentscan':
        timeoutMS = config.timeoutAgentOverride ? config.timeoutAgentOverride : 300
        break
      default:
        const timeoutValue = config['timeout' + screenId] //screenId all lower case
        if (timeoutValue) {
          timeoutMS = timeoutValue
        } else {
          timeoutMS = config.timeoutDefault
        }
    }
    timeoutMS = timeoutMS * 1000
    const accessibilityDevice = getAccessibilityDevice()
    if (accessibilityDevice.enabled) {
      timeoutMS = timeoutMS * config.accessibilityTimeoutMultiplier
    }
    return timeoutMS
  }

  const onTimeoutButtonClicked = (yesNoButton, isPopupTimeout = false) => {
    if (isPopupTimeout) {
      if (yesNoButton === 'NO') {
        console.log('popup time out.')
        getSBDAppMan().doQuit(END_TXN_REASON.TIMEOUT, 'Screen timeout')
        props.history.push('sessionTimeout')
      }
    } else {
      if (yesNoButton === 'NO') {
        console.log('time out No button clicked.')
        getSBDAppMan().doQuit(END_TXN_REASON.TIMEOUT, 'Screen timeout')
      }
    }
  }

  const handleTabAction = () => {
    if (config.enableTap) {
      if (!tapTimer.current) {
        tapTimer.current = setTimeout(() => {
          tapCount.current = 0
          tapTimer.current = null
        }, config.tapTimeout * 1000)
      }
      if (tapCount.current < config.numberofTap - 1) {
        tapCount.current++
      } else {
        if (tapTimer.current) {
          clearTimeout(tapTimer.current)
          tapTimer.current = null
        }
        tapCount.current = 0
        appLog(TraceLevels.LOG_EXT_TRACE, 'Diagnostics tirgger')
        logEvent('Other,Diagnostics tirgger')
        getDeviceManager().getAppManager().sendApplicationLog(100, 'CDS_APPLOG,CDS_DIAGNOSTICS_MAXIMIZE,1')
      }
    }
  }

  useEffect(() => {
    const screenId = getScreenId(location.pathname)
    timeoutDuration.current = getTimeoutDuration(screenId)
    setIsShowTimeoutPopup(disableTimeoutPopupPaths.indexOf(screenId) < 0)
    setIsShowHeader(disableHeaderList.indexOf(screenId) < 0)
    setHeaderText(screenId)
    if (!config.disableScreenTimeout) {
      if (disableTimeoutPaths.length > 0 && disableTimeoutPaths.indexOf(screenId) < 0) {
        // enable screen timer
        timeoutManger.setTimeoutDuration(timeoutDuration.current)
        // make sure the timer is cleared
        timeoutManger.stopTimer()
        // start timer
        console.log(
          'start timer for path:',
          location.pathname,
          ', screenId:' + screenId,
          ', timeout duration (ms):',
          timeoutDuration.current
        )
        timeoutManger.resetTimer(timeoutDuration.current)
      } else {
        console.log('screen:' + screenId + ' timer is disabled.')
        timeoutManger.stopTimer()
      }
    }
  }, [location])
  return (
    <Page>
      <div
        style={{
          left: '0px',
          top: '0px',
          position: 'absolute',
          height: '100px',
          width: '300px',
          zIndex: '1000',
          backgroundColor: 'transparent'
        }}
        onClick={handleTabAction}
      />
      <Header isShowHeader={isShowHeader} headerText={headerText} />
      <PageContent background={themeContext.backgroundColer}>{routes}</PageContent>
      <PopupTimer
        isShowTimeoutPopup={isShowTimeoutPopup}
        timeoutDuration={timeoutDuration.current}
        popupTimeoutDuration={config.timeoutPopup}
        timeoutManger={timeoutManger}
        textMsg={formatMessage(messages.NeedMoreTimeTitle)}
        subTextMsg={formatMessage(messages.NeedMoreTimeSubTitle)}
        onBtnClicked={onTimeoutButtonClicked}
        yesButtonId={'timeoutYes'}
        noButtonId={'timeoutNo'}
        buttonGap={'50px'}
        yesMsg={formatMessage(messages.buttonYes)}
        noMsg={formatMessage(messages.buttonNo)}
        accessibility={null}
        dispatch={dispatch}
        bgColor={themeContext.PopupBgColor}
        buttonYesBgColor={themeContext.YesNoButton.bgColor}
        buttonNoBgColor={themeContext.YesNoButton.bgColor}
        //buildPopupGroup={this.accMgr.buildPopupGroup}
      />
    </Page>
  )
}
export default CoreLayout
