import { store, history } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { transitions } from 'actions/etsTransactions/transitions'
import { TransitionCodes, FunctionCodes, ServiceNames } from '../../constants/Constants'
import { updateResponses } from 'actions/responseActions'
import { isNotEmpty, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer } from 'utils/helper'
import { updateLocalData } from 'actions/localActions'
import { updateSessions } from 'actions/sessionActions'

import { identifyService } from 'actions/etsTransactions/identifyService'

function buildRequest() {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID,
      },
      jsonExtend: null,
      currentClientTime: 0,
      asyncRequested: false,
      allPassengers: true,
      forTotalWeightLimit: true,
    }
  )
}

export function getTotalWeightInfo() {
  let request = buildRequest()
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'getTotalWeightInfo() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate('pleaseWait')
    return fetchWithTimeout('getBagDetails/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'getTotalWeightInfo() receive response ... ' + JSON.stringify(json, replacer))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'getTotalWeightInfo() receive response. ')
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))
          dispatch(
            updateLocalData('updateTotalWeightLimit', {
              totalWeightLimit: json.etsResponse.totalWeightLimit,
              unit: json.etsResponse.baggageUnit,
            })
          )
          switch (json.transition) {
            case TransitionCodes.SHOW_BAG_WEIGHT_LIMIT:
              navigate('ConfirmTotalWeight', 21)
              break
            case TransitionCodes.NONE:
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                '(BagsEntryDetail.js) url: identifyService, ',
                ServiceNames.SBD_CONFIRM_WEIGHT_LIMIT
              )
              dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_CONFIRM_WEIGHT_LIMIT))
              break
            default:
              // dispatch transition
              dispatch(updateSessions('updateTransition', json.transition))
              dispatch(transitions(json.transition))
          }
        } else if (json.error) {
          goToFetchGenericError('getTotalWeightInfo', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'getTotalWeightInfo()', 'error')
      })
  }
}
