import React, { useEffect } from 'react'
import {SwitchBase} from 'components/switch'
import './checkbox.scss'

/* export interface CheckboxData {
  id: string
  text: string
  status: SwitchBaseStatus
} */

export {SwitchBaseStatus as CheckboxStatus } from 'components/switch'
const Checkbox = (props) => {
  const {
    data,
    additionalText1,
  } = props
  const containerStyle = props.containerStyle || 'checkbox-container'
  const labelStyle = props.labelStyle || 'checkbox-label'
  const additionalTextStyle = props.additionalTextStyle || 'checkbox-additional-text'
  const additionalText1Style = props.additionalText1Style || 'checkbox-additional-text1'
  const additionalText2Style = props.additionalText2Style || 'checkbox-additional-text2'
  const iconStyleChecked = props.iconStyleChecked || 'checkbox-checked'
  const iconStyleUnchecked = props.iconStyleUnchecked || 'checkbox-unchecked'
  const iconStyleCheckedDisabled = props.iconStyleCheckedDisabled || 'checkbox-checked-disabled'
  const iconStyleUncheckedDisabled = props.iconStyleUncheckedDisabled || 'checkbox-unchecked-disabled'
  const syncData = props.syncData || true
  const disableLabel = props.disableLabel || false
  const labelPlacement = props.labelPlacement || 'end'
  const [checkboxData, setCheckboxData] = React.useState(data)

  useEffect(() => {
    if(syncData) {
      setCheckboxData(data)
    }
  }, [syncData, data])

  const handleCheckboxChange = (id, status) => {
    const updatedCheckboxData = checkboxData.map((data) => {
      if (data.id === id) {
        data.status = status
      }
      return data
    })
    setCheckboxData(updatedCheckboxData)
    props.onClick(updatedCheckboxData)
  }

  return (
    <>
      {checkboxData.map((option) => (
        <SwitchBase
          key={option.id}
          id={option.id}
          text={option.text}
          status={option.status}
          onClick={handleCheckboxChange}
          containerStyle={containerStyle}
          labelPlacement={labelPlacement}
          labelStyle={labelStyle}
          disableLabel={disableLabel}
          additionalText1={additionalText1}
          additionalTextStyle={additionalTextStyle}
          additionalText1Style={additionalText1Style}
          additionalText2Style={additionalText2Style}
          iconStyleChecked={iconStyleChecked}
          iconStyleUnchecked={iconStyleUnchecked}
          iconStyleCheckedDisabled={iconStyleCheckedDisabled}
          iconStyleUncheckedDisabled={iconStyleUncheckedDisabled}
        />
      ))}
    </>
  )
}

export default Checkbox
