import {deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import AEABagdropListener from 'listeners/aeaBagdropListener'

/**
 *  Device representing an AEABagdrop
 *  @extends {Device}
 */
export default class AEABagdrop extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm)
  {
    super(socket, dm)
    /**@private*/
    this.name = 'AEA Bagdrop'
    /**
     * Device id - deviceIds.AEA_BAGDROP {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.AEA_BAGDROP
    this.setListener(new AEABagdropListener())
  }

  /**
   * Send AEA command to the bagdrop.
   * @param {String} data - AEA command
   * @param {Number} timeout - timeout in ms
   */
  sendAEA(data, timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'sendAEA(): ' + data + ' timeout: ' + timeout)
      this.socket.sendRequest(this.deviceId, 'sendAEA', data, timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'sendAEA() exception: ' + e)
    }
  }

  /**
   * Send AEA setup command to the bagdrop.
   * @param {String} data - AEA setup command
   * @param {Number} timeout - timeout in ms
   */
  setupAEA(data, timeout)
  {
    try
    {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'setupAEA(): ' + data + ' timeout: ' + timeout)
      this.socket.sendRequest(this.deviceId, 'setupAEA', data, timeout)
    }
    catch (e)
    {
      this.logMessage(TraceLevels.LOG_ALERT, 'setupAEA() exception: ' + e)
    }
  }
}
