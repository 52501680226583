import React, { useState, useContext } from 'react'
import { PageContent, PageTitle, PageSubContent, DynamicImage, Spacer } from 'components/styledComponents'
import { Button, IconButton } from 'components'
import useUIBase from 'hooks/ui/useUIBase'
import { Footer } from '../../layouts/footer'
import { ThemeContext } from 'styled-components'
import { history } from 'main'

const CustomPage = (props) => {
  const themeContext = useContext(ThemeContext)

  const quitActionHandle = () => {
    history.push('welcome')
  }

  return (
    <>
      <PageContent flexFlow="column">
        <DynamicImage imageName={'Abd_ColorTest.jpg'} cssName={'emb_animation_drawbox'} width={600} height={900} />
        <IconButton
          id={'confirmBtn'}
          color={themeContext.ConfirmButton.color}
          bgColor={themeContext.ConfirmButton.bgColor}
          width={themeContext.ConfirmButton.width}
          height={themeContext.ConfirmButton.height}
          onClick={quitActionHandle}
          fontSize={20}
          buttonPosition={themeContext.ConfirmButton.buttonPosition}
          iconLocation={themeContext.ConfirmButton.buttonPosition}
          text={"Back"}
          icon={themeContext.ConfirmButton.icon}
          iconColor={themeContext.ConfirmButton.iconColor}
          borderRadius={themeContext.ConfirmButton.borderRadius}
          border={themeContext.ConfirmButton.border ? themeContext.ConfirmButton.border : ''}
        />
      </PageContent>
    </>
  )
}
export default CustomPage
