import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import useUIBase from 'hooks/ui/useUIBase'
import { selectPaxFlight } from 'actions/etsTransactions/selectPaxFlight'
import { Button, CheckboxStatus, Checkbox, EMBTable } from 'components'
import { PageContent, PageTitle, PageSubTitle, PageSubContent, Spacer } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { Footer } from '../../layouts/footer'
import { playSound, getAccessibilityManager, getBuildAccessibility } from 'main'
import { clone, populateItineraryInfo, getScreenId } from 'utils/helper'
import useMedia from 'hooks/useMedia'
import { BaggageStatus } from 'constants/Constants'

const getData = (passengers, mainPassengerOrdinal, formatMessage) => {
  let selectableCheckboxStatus = CheckboxStatus.UNCHECKED
  let alreadyCheckIn = false
  let data = []
  let canSelectCount = 0
  console.log('getData...')
  if (passengers && passengers.length > 0) {
    passengers.map((paxInfo) => {
      let additionalText = ''
      let additionalText1 = ''
      let additionalText2 = ''
      // additionalText = ''
      alreadyCheckIn = false
      selectableCheckboxStatus = CheckboxStatus.CHECKED
      if (paxInfo.passenger.ordinal === mainPassengerOrdinal) {
        selectableCheckboxStatus = CheckboxStatus.CHECKED_DISABLED
        //setIsDisableConfirmButton(false)
      } else {
        canSelectCount++
      }

      if (paxInfo.passenger.withInfant) {
        additionalText += ` +${formatMessage(messages.Infant)} `
      }

      if (paxInfo.bagTags) {
        const numberOfBags = paxInfo.bagTags.filter((bagDetail) => {
          return bagDetail.status !== BaggageStatus.ACTIVATED
        }).length
        additionalText1 = `${numberOfBags} ${
          numberOfBags > 1 ? formatMessage(messages.Bags) : formatMessage(messages.Bag)
        }`
      }
      data.push({
        ordinal: paxInfo.passenger.ordinal,
        id: 'pax_' + paxInfo.passenger.ordinal.toString(),
        text: paxInfo.passenger.firstName + ' ' + paxInfo.passenger.lastName,
        passengerType: paxInfo.passenger.passengerType,
        status: selectableCheckboxStatus,
        dateOfBirth: paxInfo.passenger.dateOfBirth,
        alreadyCheckIn: alreadyCheckIn,
        withInfant: paxInfo.passenger.withInfant,
        additionalText: additionalText,
        additionalText1: additionalText1,
        additionalText2: additionalText2
      })
    })
  }
  return data
}

const PassengerSelection = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(false)
  const [itineraryData, setItineraryData] = useState(null)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const isQuitRequired = true
  const isLandscape = useCheckLandscape()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const columns = isLandscape
    ? [
        {
          header: '',
          width: '500px'
        },
        {
          header: '',
          width: '500px'
        }
      ]
    : [
        {
          header: '',
          width: themeContext.PassengerSelection.scrollAreaWidth[ratioKey]
        }
      ]

  const handleSelection = (updatedData) => {
    playSound.beepOK()
    console.log('onCheckboxClick id=' + updatedData[0].id + ', status=' + updatedData[0].status)
    let hasChecked = false
    data.map((item) => {
      if (item.id === updatedData[0].id) {
        item.status = updatedData[0].status
      }
      if (item.status === CheckboxStatus.CHECKED || CheckboxStatus.CHECKED_DISABLED) {
        hasChecked = true
      }
    })
    if (hasChecked) {
      setIsDisableConfirmButton(false)
    } else {
      setIsDisableConfirmButton(true)
    }
    setData([...data])
  }
  const buildRows = (data) => {
    let canSelectCount = 0
    let rows = []
    let count = 0
    if (!data) {
      return rows
    }
    if (isLandscape) {
      data.forEach((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        const checkbox = (
          <Checkbox
            key={'key-' + index}
            id={cell.id}
            data={[checkboxData]}
            onClick={handleSelection}
            additionalText={cell.additionalText}
            additionalText1={cell.additionalText1}
            additionalText2={cell.additionalText2}
            tabIndex={cell.tabIndex}
          />
        )
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        if (index % 2 === 0) {
          rows.push({ data: [checkbox] })
        } else {
          rows[count].data.push(checkbox)
          count++
        }
      })
    } else {
      data.map((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        rows.push({
          data: [
            <Checkbox
              key={'key-' + index}
              id={cell.id}
              data={[checkboxData]}
              onClick={handleSelection}
              additionalText={cell.additionalText}
              additionalText1={cell.additionalText1}
              additionalText2={cell.additionalText2}
              tabIndex={cell.tabIndex}
            />
          ]
        })
      })
    }
    return rows
  }

  const getCheckAllButton = (data) => {
    let checkAllButton = data ? (
      <PageContent
        flexFlow="row"
        justifyContent="flex-start"
        padding={isLandscape ? '10px 0 0 0' : '30px 0 0 0'}
        style={{ width: isLandscape ? '100%' : '' }}
      >
        <Button
          id={'selectAllPassengers'}
          cssName={'large-blue-button-up'}
          color={themeContext.OutlineButton.color}
          bgColor={themeContext.OutlineButton.bgColor}
          border={themeContext.OutlineButton.border}
          width={themeContext.OutlineButton.width[ratioKey]}
          height={themeContext.OutlineButton.height[ratioKey]}
          fontSize={themeContext.OutlineButton.fontSize[ratioKey]}
          justifyContent={'flex-start'}
          borderRadius={themeContext.OutlineButton.borderRadius[ratioKey]}
          disableShadow={themeContext.OutlineButton.disableShadow}
          onClick={() => {
            playSound.beepOK()
            data.forEach((item) => {
              if (item.status === CheckboxStatus.UNCHECKED) {
                item.status = CheckboxStatus.CHECKED
              }
            })
            setData([...data])
          }}
        >
          {formatMessage(messages.buttonSelectAll)}
        </Button>
      </PageContent>
    ) : (
      ''
    )
    return checkAllButton
  }

  useEffect(() => {
    const mainPassengerOrdinal =
      itineraryInfo && itineraryInfo.scanSequence.length > 0 ? itineraryInfo.scanSequence[0] : 0
    const passengers = itineraryInfo.passengerBagtagDetails
    const initData = getData(passengers, mainPassengerOrdinal, intl.formatMessage)
    setItineraryData(populateItineraryInfo(itineraryInfo))
    setData(initData)
  }, [locale])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const accMgr = getAccessibilityManager()

  useEffect(() => {
    if (data) {
      handleAccessibility()
    }
  }, [data, locale])

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const accDef = {
      pathName: 'PassengerSelection',
      startIndex: 0,
      isQuitRequired: themeContext.Footer?.displayQuitList
        ? themeContext.Footer?.displayQuitList?.includes(screenId)
        : true,
      isLangRequired: themeContext.Footer?.displayLangList
        ? themeContext.Footer?.displayLangList?.includes(screenId)
        : true,
      isHelpRequired: themeContext.Footer?.displayHelpList
        ? themeContext.Footer?.displayHelpList?.includes(screenId)
        : false,
      isItineraryRequired: themeContext.Footer?.displayItineraryList
        ? themeContext.Footer?.displayItineraryList?.includes(screenId)
        : true,
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: getSequence(),
        nextKeyNavId: 'confirmBtn'
      }
    }
    buildAccessibility(accDef)
  }

  const getSequence = () => {
    const screenTitle = `${intl.formatMessage(messages.PassengerSelectionTitle)}. ${intl.formatMessage(
      messages.PassengerSelectionSubTitle
    )}.`
    const language = accMgr.getLanguageDef(locale).language
    const sequence = [{ id: 'page-content', textId: 'OneDynamicText', textParameters: [screenTitle] }]
    if (data.length > 1) {
      sequence.push({
        id: 'selectAllPassengers',
        textId: 'SelectAllPassengers',
        buttonId: 'selectAllPassengers'
      })
    }
    data.map((pax) => {
      // console.log('pax: ', pax)
      let parameters = []
      if (pax.status === CheckboxStatus.CHECKED) {
        parameters = [pax.text, language['Selected'], language['UnSelected']]
      } else {
        parameters = [pax.text, language['UnSelected'], language['Selected']]
      }
      if (pax.status === CheckboxStatus.CHECKED_DISABLED) {
        sequence.push({
          id: 'pax_' + pax.ordinal,
          textId: 'SelectedAndDisabledPassenger',
          textParameters: [pax.text],
          buttonId: 'pax_' + pax.ordinal
        })
      } else if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.UNCHECKED) {
        sequence.push({
          id: 'pax_' + pax.ordinal,
          textId: 'NavigateSelectPassenger',
          textParameters: parameters,
          buttonId: 'pax_' + pax.ordinal
        })
      }
    })
    sequence.push({ id: 'confirmBtn', text: formatMessage(messages.buttonContinue), buttonId: 'confirmBtn' })

    return sequence
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.PassengerSelectionTitle)}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.PassengerSelectionSubTitle)}
      </PageSubTitle>
    </>
  )

  const textSection = (
    <>
      <EMBTable
        columns={columns}
        rows={buildRows(data)}
        headerHeight={50}
        rowHeight={themeContext.PassengerSelection.rowHeight[ratioKey]}
        rowFontSize={30}
        headFontSize={30}
        maxDisplayRows={themeContext.PassengerSelection.maxDisplayRows[ratioKey]}
      />
      {getCheckAllButton(data)}
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={!appMode}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={() => {
        let selectedPassengers = []
        data.forEach((pax) => {
          if (pax.status === CheckboxStatus.CHECKED || pax.status === CheckboxStatus.CHECKED_DISABLED) {
            selectedPassengers.push({
              paxOrdinal: pax.ordinal,
              selected: true
            })
          } else {
            selectedPassengers.push({
              paxOrdinal: pax.ordinal,
              selected: false
            })
          }
        })
        dispatch(selectPaxFlight(selectedPassengers))
      }}
      confirmBtnText={formatMessage(messages.buttonContinue)}
      disableConfirmAction={isDisableConfirmButton}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default PassengerSelection
