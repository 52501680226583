import { APPLICATION_MANAGER} from 'constants/Constants'

export function updateAppData(key, value) {
  //console.log('updateAppData:' + key + ': value', value)
  return {
    type: APPLICATION_MANAGER,
    key: key,
    value: value
  }
}
