import React, { Fragment, useRef } from 'react'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getSBDAppMan, playSound } from 'main'
import { appLog } from '../utils/Logger'
import { END_TXN_REASON } from '../constants/Constants'
import { Button, Popup } from 'components'
import { DynamicImage, Spacer } from 'components/styledComponents'

function usePopup({ popupRef, titleText, contentText, contentImage, footerStyle, buttons }) {
  const renderPopup = () => {
    return (
      <Popup ref={popupRef}>
        <div className="popup-body" tabIndex="0">
          <div className="popup-title">{titleText}</div>
          <div className="popup-content">{getPopupContents()}</div>
          <div className={footerStyle ? footerStyle : 'popup-buttons'}>{getPopupButtons()}</div>
        </div>
      </Popup>
    )
  }

  const getPopupContents = () => {
    const imageDIV = contentImage ? (
      <DynamicImage
        imageName={contentImage.url}
        cssName={contentImage.cssName ? contentImage.cssName : 'animation'}
        width={contentImage.width ? contentImage.width : 420}
        height={contentImage.height ? contentImage.height : 420}
      />
    ) : (
      ''
    )
    return (
      <Fragment>
        {imageDIV}
        {contentText}
      </Fragment>
    )
  }

  const getPopupButtons = () => {
    let buttonGroup = []
    buttons.map((button, index) => {
      let buttonStyle = button.style ? button.style : 'btn-popup'
      // TODO : handle dynamic onClick event
      let buttonOnClick = () => {
        playSound.beepOK()
        if (index === 0) {
          getSBDAppMan().doQuit(END_TXN_REASON.USER_QUIT, 'Total weight is more than maximum allowed.')
        } else {
          hide()
        }
      }
      index !== 0 && buttonGroup.push(<Spacer key={'spacer-' + index} width={20} />)
      buttonGroup.push(
        <Button
          key={'popup-btn-' + button.id + index}
          id={button.id}
          cssName={buttonStyle}
          onClick={() => buttonOnClick()}
        >
          {button.text}
        </Button>
      )
    })
    return buttonGroup
  }

  const show = () => {
    popupRef.current.show()
  }

  const hide = () => {
    if (popupRef) {
      popupRef.current.hide()
    }
  }

  return [renderPopup, show, hide]
}

export default usePopup
