import { deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import IrisCameraListener from 'listeners/irisCameraListener'

/**
 *  Device representing An Iris Camera
 *  @extends {Device}
 */
export default class IrisCamera extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Iris Camera'
    /**
     * Device id - deviceIds.IRIS_CAMERA {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.IRIS_CAMERA
    this.setListener(new IrisCameraListener())
  }
  /**
   * Send setup string to the iris camera.
   * @param {String} data - setup string
   * @example
   */
  setup(data) {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'setup(): ' + data)
      this.socket.sendRequest(this.deviceId, 'setup', data)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setup() exception: ' + e)
    }
  }

  /**
   * Get version of the iris camera software.
   * @param {String} [key] when the key is not provided then the response will contain values for all keys listed below:
   */
  getVersion(key) {
    try {
      if (key) {
        this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion(): ' + key)
        this.socket.sendRequest(this.deviceId, 'getVersion', key)
      } else {
        this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion()')
        this.socket.sendRequest(this.deviceId, 'getVersion')
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getVersion() exception: ' + e)
    }
  }

  /**
   * Set language for the iris camera software.
   * @param {int} langId language id - one of: {@link src/constants/Constants.js~IrisCameraLanguages}
   */
  setLanguage(langId) {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'setLanguage(): ' + langId)
      this.socket.sendRequest(this.deviceId, 'setDeviceLanguage', langId)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'setLanguage() exception: ' + e)
    }
  }

  /**
   * Capture iris
   */
  captureIris() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'captureIris().')
      this.socket.sendRequest(this.deviceId, 'irisCameraCapture')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'captureIris() exception: ' + e)
    }
  }

  /**
   * Enroll iris
   * @param {string} id id
   * @param {string} fn first name
   * @param {string} ln last name
   * @param {string} gender gender 'M' or 'F'
   */
  enrollIris(id, fn, ln, gender) {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'enrollIris(): ' + id + ' ' + fn + ' ' + ln + ' ' + gender)
      this.socket.sendRequest(this.deviceId, 'irisCameraEnroll', id, fn, ln, gender)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'enrollIris() exception: ' + e)
    }
  }
  /**
   * recognize iris
   */
  recognizeIris() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'recognizeIris().')
      this.socket.sendRequest(this.deviceId, 'irisCameraRecognize')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'recognizeIris() exception: ' + e)
    }
  }

  /**
   * delete db iris
   */
  irisCameraDeleteDB() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'irisCameraDeleteDB().')
      this.socket.sendRequest(this.deviceId, 'irisCameraDeleteDB')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'irisCameraDeleteDB() exception: ' + e)
    }
  }
}
