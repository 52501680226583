export const getButtonDisplayConfig = ({
  themeContext,
  displayItinerary = true,
  isQuitRequired = true,
  isLangRequired = true,
  screenId
}) => {
  return {
    isItineraryRequired: themeContext.Footer.displayItineraryList
      ? themeContext.Footer.displayItineraryList.includes(screenId)
      : displayItinerary,
    isQuitRequired: themeContext.Footer.displayQuitList
      ? themeContext.Footer.displayQuitList.includes(screenId)
      : isQuitRequired,
    isLangRequired: themeContext.Footer.displayLangList
      ? themeContext.Footer.displayLangList.includes(screenId)
      : isLangRequired,
    isHelpRequired:
      config.enableHelpBtn && themeContext.Footer.displayHelpList
        ? themeContext.Footer.displayHelpList.includes(screenId)
        : false
  }
}
