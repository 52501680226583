/**
 * ETS Request and Response - selectPaxFlight
 */

import { store, history } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import {
  TransitionCodes,
  APISTransitions,
  PASSENGER_VALIDATION_STATUS,
  FunctionCodes,
  ServiceNames
} from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData } from 'actions/localActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { isNotEmpty } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer, navigate } from 'utils/helper'
import { getTotalWeightInfo } from 'actions/etsTransactions/getTotalWeightInfo'
import { identifyService } from 'actions/etsTransactions/identifyService'
function buildSelectPaxFlight(selectedPax, isReturnFlight) {
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
      },
      //    'transitionToEcho': null,
      jsonExtend: null,
      currentClientTime: 0,
      asyncRequested: false,
      passengerSelected: selectedPax,
      flightSelected: [],
      returnFlightSelected: isReturnFlight,
      checkinContractReturn: false,
      forFastPath: false
    }
  )
}

export function selectPaxFlight(selectedPax, isReturnFlight = false, checkinPaxFlag = false) {
  // appLog(TraceLevels.LOG_EXT_TRACE, 'selectPaxFlight() request begins ... ')
  let request = buildSelectPaxFlight(selectedPax, isReturnFlight)
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'selectPaxFlight() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate('pleaseWait')
    return fetchWithTimeout('selectPaxFlight/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'selectPaxFlight() receive response ... ' + JSON.stringify(json, replacer))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          // console.log('selectPaxFlight response ... options ... ',json.etsResponse.checkinConfigOptions)
          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))
          let passengers = json.etsResponse.itineraryInfo.passengers
          let selectedPassengers = []
          let prevItineraryInfo = store.getState().responses.itineraryInfo
          let newPassengerBagtagDetails = []
          let scanSequence = prevItineraryInfo.scanSequence
          let tempPax = null
          if (passengers && passengers.length > 0) {
            passengers.forEach((passenger) => {
              if (scanSequence.indexOf(passenger.ordinal) === -1) {
                scanSequence.push(passenger.ordinal)
              }
              tempPax = prevItineraryInfo.passengerBagtagDetails.find(
                (pax) => pax.passenger.ordinal === passenger.ordinal
              )
              if (tempPax) {
                newPassengerBagtagDetails.push({ ...tempPax, bpScanned: true })
              }
              /* newPassengerBagtagDetails = newPassengerBagtagDetails.map((details) => {
                if (details.passenger.ordinal === passenger.ordinal) {
                  return { ...details, bpScanned: true }
                }  else {
                  return details
                } 
              }) */
              selectedPassengers.push({
                ordinal: passenger.ordinal,
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                dateOfBirth: passenger.dateOfBirth,
                bioCheck: PASSENGER_VALIDATION_STATUS.UNKNOWN,
                visaCheck: PASSENGER_VALIDATION_STATUS.UNKNOWN,
                passportScanned: false
              })
            })
            prevItineraryInfo.passengerBagtagDetails = newPassengerBagtagDetails
            prevItineraryInfo.scanSequence = scanSequence
          }
          dispatch(updateResponses('updateItinerary', prevItineraryInfo))
          dispatch(updateLocalData('updateValidatePaxResult', selectedPassengers))
          switch (json.transition) {
            case TransitionCodes.SBD_PROCESS:
              dispatch(updateLocalData('appFlow', 2))
              //let url = Object.assign({}, { pathname: 'PutBagOnBelt' })
              appLog(TraceLevels.LOG_EXT_TRACE, '(selectPaxFlight.js) url: PutBagOnBelt')
              if (store.getState().localData.baggageLoadedStatus) {
                history.push('BagProcessing')
              } else {
                history.push('PutBagOnBelt')
              }
              break
            case TransitionCodes.GET_BAG_WEIGHT_LIMIT:
              appLog(TraceLevels.LOG_EXT_TRACE, 'selectPaxFlight() receive response then dispatch start APIS request. ')
              dispatch(getTotalWeightInfo())
              break
            case TransitionCodes.NONE:
              dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_CONFIRM_WEIGHT_LIMIT))
              break
            case TransitionCodes.DANGEROUS_GOODS:
              navigate('HazMatQuestion', 21)
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'selectPaxFlight() receive response, call for transitions.')
              dispatch(transitions(json.transition, json.etsResponse))
          }
        } else if (json.error) {
          goToFetchGenericError('selectPaxFlight', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'selectPaxFlight()', 'error')
      })
  }
}
