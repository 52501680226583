import { deviceIds, TraceLevels } from 'constants/Constants'
import device from 'devices/device'
import FaceTrackingListener from 'listeners/faceTrackingListener'

/**
 *  Device representing a Face Tracker (camera)
 *  @extends {Device}
 */
export default class FaceTracking extends device {
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'Face Tracking'
    this.streamingUrl = ''
    /**
     * Device id - deviceIds.FACE_TRACKING {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.FACE_TRACKING
    this.setListener(new FaceTrackingListener())
  }

  get StreamingUrl() {
    return this.streamingUrl
  }

  set StreamingUrl(url) {
    this.streamingUrl = url
  }
  
  /**
   * Show the camera live stream.
   * @param {String} mode - show setup string
   * @example <PreviewCentered>False</PreviewCentered><PreviewHeight>400</PreviewHeight><PreviewWidth>520</PreviewWidth><PreviewXPos>380</PreviewXPos><PreviewYPos>345</PreviewYPos>
   */
  show(mode) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'show(): ' + mode)
      this.socket.sendRequest(this.deviceId, 'show', mode)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'show() exception: ' + e)
    }
  }

  /**
   * Hide the camera live stream.
   */
  hide() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'hide(): ')
      this.socket.sendRequest(this.deviceId, 'hide')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'hide() exception: ' + e)
    }
  }

  /**
   * Analyze passport and taken photos. Needs to be used when config.sharePassportPhoto is false.
   * @param {String} photoTypeOrPassportImage - when there only one parameter then it will be passport image. Otherwise it will be a type of the image 'vis' or 'ir' and the photo to compare will be retrieved from the FaceTracking component.
   * @param {String} [image1] - passport photo
   * @param {String} [image2] - photo to compare
   * @example faceTracking.analyze(DG2Photo)
   */
  analyze(photoTypeOrPassportImage, image1, image2) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'analyze(): image1: ')
      if (image1 === undefined && image2 === undefined)
        this.socket.sendRequest(this.deviceId, 'analyze', photoTypeOrPassportImage)
      else this.socket.sendRequest(this.deviceId, 'analyze', photoTypeOrPassportImage, image1, image2)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'analyze() exception: ' + e)
    }
  }

  /** Enables the face tracking device using Face Service or LFIS (PIK)
   * @param {String} [mrzOrBestShotTime] - passport mrz or <BestShotTime>1</BestShotTime>. Best shot time is used by Face Service.
   * @param {String} [rfMrzOrPhoto] - passport mrz from the chip or photo to compare to(used with Face Service).
   * @param {Number} [okPhotoThreshold] - threshold for photo OK.
   */
  enable(mrzOrBestShotTime, rfMrzOrPhoto, okPhotoThreshold) {
    try {
      if (mrzOrBestShotTime === undefined && rfMrzOrPhoto === undefined) {
        this.logMessage(TraceLevels.LOG_TRACE, 'enable(): ' + this.deviceId)
        this.socket.sendRequest(this.deviceId, 'enable')
      } else {
        if (okPhotoThreshold === undefined) {
          okPhotoThreshold = 0
        }
        let param1Sub = mrzOrBestShotTime !== undefined ? mrzOrBestShotTime.substring(0, 8) : ''
        let param2Sub = rfMrzOrPhoto ? rfMrzOrPhoto.substring(0, 8) : ''
        this.logMessage(
          TraceLevels.LOG_TRACE,
          'enable()' +
            this.deviceId +
            ' mrz/bestShot: ' +
            param1Sub +
            ' rfMrz/Photo: ' +
            param2Sub +
            ' okPhotoThreshold: ' +
            okPhotoThreshold
        )
        this.socket.sendRequest(this.deviceId, 'enable', mrzOrBestShotTime, rfMrzOrPhoto, okPhotoThreshold)
      }
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'enable() exception: ' + e)
    }
  }

  /** Clears shared data in faceTracking and PassportReader */
  clearSharedData() {
    this.logMessage(TraceLevels.LOG_TRACE, 'clearSharedData()')
    this.socket.sendCommand(this.deviceId, 'clearSharedData')
  }

  /** Toggle view between two cameras */
  toggleView() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'toggleView(): ')
      this.socket.sendRequest(this.deviceId, 'toggleView')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'toggleView() exception: ' + e)
    }
  }

  /** Switch view to the camera with camId
   * @param {String} [camId] - if not provided then it will switch to the primary camera
   */
  switchView(camId) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'switchView(): ' + (camId === undefined ? '' : camId))
      this.socket.sendRequest(this.deviceId, 'switchView', camId === undefined ? '' : camId)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'switchView() exception: ' + e)
    }
  }

  /** Select the camera by camId
   * @param {String} camId - camera  id
   */
  selectCamera(camId) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'selectCamera(): ' + camId)
      //this.socket.sendRequest(this.deviceId, 'hide')
      this.socket.sendRequest(this.deviceId, 'selectCamera', camId)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'selectCamera() exception: ' + e)
    }
  }

  /** Changes focus to the application from the live stream
   */
  focusApp() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'focusApp()')
      this.socket.sendCommand(this.deviceId, 'focusApp')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'focusApp() exception: ' + e)
    }
  }

  /**
   *  Asynch call to get stream url
   */
  getStreamingURL() {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getStreamingURL().')
      this.socket.sendRequest(this.deviceId, 'getStreamingURL')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getStreamingURL() exception: ' + e)
    }
  }

  /**
   * Get EES face quality assessment result
   * @param {*} base64image
   */
  getEESFaceQualityAssessmentResult(base64image) {
    try {
      this.logMessage(TraceLevels.LOG_TRACE, 'getStreamingURL().')
      this.socket.sendRequest(this.deviceId, 'getEESFaceQualityAssessmentResult', base64image)
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getEESFaceQualityAssessmentResult() exception: ' + e)
    }
  }

  /**
   * Get version of the face tracking.
   */
  getVersion() {
    try {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'getVersion()')
      this.socket.sendRequest(this.deviceId, 'getVersion')
    } catch (e) {
      this.logMessage(TraceLevels.LOG_ALERT, 'getVersion() exception: ' + e)
    }
  }
}
