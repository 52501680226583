// import { push } from 'react-router-redux'
import { store, getSBDAppMan, getVersion, history } from 'main'
import { fetchWithTimeout, fetchWithUrl } from 'utils/FetchWithTimeout'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

function buildBiometicsInfo(paxOrdinal, photo, photoScore, captureAttempt, attemptFail, documentType) {
  if (attemptFail === true) {
    return Object.assign(
      {},
      {
        paxOrdinal: paxOrdinal,
        faceMatchInfo: {
          matchedScore: null,
          cameraPhotoImage: null,
          documentType: documentType,
          documentPhotoImage: null,
          attemptedCount: null,
          threshold: null
        },
        fingerPrintInfo: null,
        clientId: store.getState().kioskInfo.kioskId,
        sessionInfo: {
          etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
          emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
        },
        carrierCode: null,
        currentClientTime: 0,
        asyncRequested: false,
        jsonExtend: null
      }
    )
  } else {
    return Object.assign(
      {},
      {
        paxOrdinal: paxOrdinal,
        faceMatchInfo: {
          matchedScore: Number(photoScore),
          cameraPhotoImage: photo,
          documentType: store.getState().paxValidation.docType,
          documentPhotoImage: store.getState().paxValidation.paxFaceImage,
          attemptedCount: captureAttempt,
          threshold: Number(config.photoPassingScore)
        },
        fingerPrintInfo: null,
        clientId: store.getState().kioskInfo.kioskId,
        sessionInfo: {
          etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
          emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
        },
        carrierCode: null,
        currentClientTime: 0,
        asyncRequested: false,
        jsonExtend: null
      }
    )
  }
}

export function sendPhotoDetail(
  paxOrdinal,
  photo,
  photoScore,
  captureAttempt,
  attemptFail = false,
  documentType = null
) {
  return dispatch => {
    let request = buildBiometicsInfo(paxOrdinal, photo, photoScore, captureAttempt, attemptFail, documentType)
    appLog(TraceLevels.LOG_EXT_TRACE, `send Photo Detail request ${JSON.stringify(request)}`)
    return fetchWithTimeout('sendBiometricsInfo/', request)
      .then(json => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'send Photo Detail Success')
      })
      .catch(err => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'send Photo Detail Fail')
      })
  }
}
