import { fetchWithTimeout } from 'utils/FetchWithTimeout'
import { TraceLevels } from 'constants/Constants'
import { debugObject } from 'utils/helper'

/**
 * The heartbeat class is used by {@link OutOfServiceManager} class.
 * It must not be used by the application directly but the application should set several configuration parameters and
 * define callbacks using {@link OutOfServiceManager} class.
 * It executes heartbeat calls to the ETS.
 */
export default class Heartbeat {
  /**
   * @param {Object} devMgr - embross-device-manager reference
   * @param {number} timeout - ETS call timeout
   * @param {String} locale - language code.
   * @param {String} clientType - SBD , KIOSK.
   * @param {String} appVersion - application version.
   * @param {String} carrierCode - carrier code
   * @protected
   */
  constructor(devMgr, timeout, locale, clientType, appVersion, carrierCode) {
    /** @private device manager reference */
    this.dm = devMgr
    /** @private heartbeat url */
    this.url = null
    /** @private heartbeat  ETS calls timeout */
    this.timeout = timeout
    /** @private client type - SBD or KIOSK */
    this.clientType = clientType
    /** @private application version */
    this.appVersion = appVersion
    /** @private language code */
    this.locale = locale
    /** @private carrier code */
    this.carrierCode = carrierCode
    /** @private heartbeat ETS request interval */
    this.interval = null
    /** @private heartbeat ETS request retry interval */
    this.retryInterval = 30000
    /** @private whether or not we're in service*/
    this.inService = false
    /** @private timer id*/
    this.timer = null
    /** @private Number of retries before going out of service.*/
    this.retries = 0
    /** @private the number of retries before going out of service */
    this.maxRetries = 0
    /** @private sentinel - heartbeat started or not */
    this.started = false
    /**
     * callback to validate if the ETS is in service based on received response
     * @type {function(json: Object)}
     * @protected */
    this.isResponseInService = this.isResponseInServiceCB
    /**
     * callback to update app data from the heartbeat response (server time, version)
     * @type {function(version: String, etsTime: number, delta: number)} cb
     * @protected */
    this.serverDataUpdate = null
    /** @private server version */
    this.serverVersion = ''
    /** @private server time delta */
    this.serverTimeDelta = 0
    /** @private server time delta maximum difference */
    //this.serverTimeDeltaMaxDiff = (dmConfig && dmConfig.serverTimeDeltaMaxDiff) ? dmConfig.serverTimeDeltaMaxDiff : 1000
    this.serverTimeDeltaMaxDiff = 1000
    /** @private server time delta minimum change */
    //this.serverTimeDeltaMinChange = (dmConfig && dmConfig.serverTimeDeltaMinChange) ? dmConfig.serverTimeDeltaMinChange : 100
    this.serverTimeDeltaMinChange = 100
  }
  
  /** Start the heartbeat. Used by {@link OutOfServiceManager} class.
  @param {Boolean} emulateHeartbeatOn if true then the heartbeat is always on (no ETS calls are executed).
  @protected
  */
  start(emulateHeartbeatOn)
  {
    if (this.started) return
    if (emulateHeartbeatOn)
    {
      this.interval = 0
      this.inService = true
      this.started = true
      return
    }
    this._doRequest()
  }

  /** Stop the heartbeat. Used by {@link OutOfServiceManager} class.
   @protected  */
  stop()
  {
    this.interval = 0
  }

  /** set heartbeat ETS requests interval. Used by {@link OutOfServiceManager} class.
   * @param {number} millis - interval in milliseconds
   @protected  */
  setInterval(millis)
  {
    this.interval = millis
  }

  /** set heartbeat ETS requests retry interval. Used by {@link OutOfServiceManager} class.
   * @param {number} millis - interval in milliseconds
   @protected  */
  setRetryInterval(millis)
  {
    this.retryInterval = millis
  }
  /** set heartbeat ETS requests url. Used by {@link OutOfServiceManager} class.
   * &param {String} url to ETS heartbeat request
   @protected  */
  setUrl(url) {
    this.url = url
  }

  /** set heartbeat ETS requests url. Used by {@link OutOfServiceManager} class.
   * &returns true when the app is in service otherwise return false
   @protected  */
  isInService() {
    return this.inService
  }

  /** process heartbeat ETS request
   */
  _doRequest() {
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB.doRequest doHeartbeat start with:[' + this.url + ']')
    /* console.log(
      'host timeout:',
      this.timeout,
      'heartbeat interval',
      this.interval,
      'heartbeat short interval:',
      this.retryInterval,
      ''
    ) */
    //let numberOfRetry = 0
    let request = {
      'clientDetail':
      {
        'location': this.dm.appManager.location,
        'clientID': this.dm.appManager.kioskId,
        'clientTime': new Date(),
        'kmClientID': this.dm.appManager.smKioskId,
        'selectedLanguage': this.locale,
        'clientType': this.clientType,
        'appVersion': this.appVersion,
        'carrierCode': this.carrierCode
      }
    }
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'ETS Heartbeat request: ' + JSON.stringify(request))
    //if (this.timer) clearTimeout(this.timer)
    fetchWithTimeout(this.url, request, this.timeout, this.dm.dmLog)  // use default timeout
      .then(json => {
        this.dm.dmLog(TraceLevels.LOG_TRACE, 'ETS Heartbeat response', json)
        this.dm.dmLog(TraceLevels.LOG_TRACE, 'ETS response ... inService ... ... ' + json.etsResponse.inService)
        this._heartbeatSuccess(json)
      })
      .catch(err => {
        this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB.doRequest Catch: ' + err)
        this._heartbeatFailed(err)
      })
  }

  /** Callback  that trips when the heartbeat goes into service.
   * overridden by {@link OutOfServiceManager.checkStatus}
   @callback
   @protected
   */
  onInService()
  {
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB.onInService default onInService')
  }

  /** Callback  that trips when the heartbeat goes out of service.
   @callback overridden by {@link OutOfServiceManager.checkStatus}
   @protected
   */
  onOutOfService()
  {
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB.onOutOfService default onOutOfService')
  }

  /** Process OK ETS heartbeat response
   * @param {Object} json - response from heartbeat ETS call
   */
  _heartbeatSuccess(json)
  {
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB.heartbeatSuccess json: ' + JSON.stringify(json))
    //update serverData
    this._updateServerData(json)
    if (this.isResponseInService(json))
    {
      this.retries = 0
      if (!this.inService)
      {
        this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB ->Going into service')
        this.inService = true
        this.dm.appManager.sendApplicationLog(100, 'CDS_HOSTSTAT,HOSTSTAT_UP')
        try {
          this.onInService(json)
        }
        catch(e) {
          this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB -> Error in inService: ' + debugObject(e))
        }
      }
    }
    else if (this.inService)
    {
      if (this.retries < this.maxRetries)
      {
        this.retries++
        this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB isResponseInService() false. Number of retries left: ' + (this.maxRetries - this.retries))
      }
      else
      {
        this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB isResponseInService() false: Going out of service')
        this.inService = false
        this.dm.appManager.sendApplicationLog(100, 'CDS_HOSTSTAT,HOSTSTAT_DOWN')
        try {
          this.onOutOfService(json)
        }
        catch(e) {
          this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB Error in onOutOfService: ' + debugObject(e))
        }
      }
    }
    else if (!this.started) //first response
    {
      this.dm.appManager.sendApplicationLog(100, 'CDS_HOSTSTAT,HOSTSTAT_DOWN')
    }
    
    if (this.interval > 0)
    {
      this.timer = setTimeout(() => {
        this._doRequest()
      }, this.interval)
    }

    this.started = true
    this.onResponse(json)
  }

  /** Process error ETS heartbeat response
   * @param {Object} json - response from heartbeat ETS call
   */
  _heartbeatFailed(json)
  {
    this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB.heartbeatFailed...')
    if (this.inService) {
      if (this.retries < this.maxRetries) {
        this.retries++
        this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB ETS response shows not in service. Number of retries left: ' + (this.maxRetries - this.retries))
      }
      else {
        this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB ETS response shows not in service: Going out of service')
        this.inService = false
        this.dm.appManager.sendApplicationLog(100, 'CDS_HOSTSTAT,HOSTSTAT_DOWN')
        try {
          this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB call onOutOfService: ' + JSON.stringify(json))
          this.onOutOfService(json)
        }
        catch(e) {
          this.dm.dmLog(TraceLevels.LOG_ALERT, 'HB Error in onOutOfService: ' + debugObject(e))
        }
      }
    }
    else if (!this.started) //first response, send KM log
    {
      this.dm.appManager.sendApplicationLog(100, 'CDS_HOSTSTAT,HOSTSTAT_DOWN')
    }

    if (this.retryInterval > 0)
    {
      this.timer = setTimeout(() => {
        this._doRequest()
      }, this.retryInterval)
    }
    this.started = true
  }

  /**
   Set the number of retries before going out of service. Using this will allow you to drop one or more
   bad heartbeats without going OOS you can use this if you are seeing spotty network access where one bad
   heartbeat may happen here and there without the network actually going down.
   @param {number} retries the number of retries to allow.
   @protected
   */
  setRetries(retries)
  {
    this.maxRetries = retries
  }

  /** Callback  called when any successful heartbeat response has been received.
   @param {Object} json - response from heartbeat ETS call
   @callback
   @protected
   */
  onResponse(json)
  {
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB onResponse json: ' + json)
  }
  
  /** Indicates whether or not the returned json tells us we're in service or not. By default,
   any valid json response indicates we're in service.
   @param {Object} json ETS heartbeat response
   @callback
   @protected
  */
  isResponseInServiceCB(json)
  {
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB isResponseInServiceCB json: ' + json)
    return true
  }

  /** Extract server version, time, calculate delta from the ETS heartbeat response
   * calls {@link serverDataUpdate }
   @param {Object} json ETS heartbeat response
   */
  _updateServerData(json)
  {
    let serverVersion = json.etsResponse.serverDetail.etsVersion
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB serverversion: ' + serverVersion)
    let serverTime = json.etsResponse.serverDetail.currentDateTime
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB currentDateTime: ' + json.etsResponse.serverDetail.currentDateTime)
    let delta = 0
    let deltaUpdated = false
    if (serverTime != null) {
      let d = new Date()
      let ms = d.getTime()
      delta = ms - serverTime
    }
    this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB serverTime delta: ' + delta)
    
    if ((delta > this.serverTimeDeltaMaxDiff &&
        Math.abs(this.serverTimeDelta - delta) > this.serverTimeDeltaMinChange)) {
      this.dm.dmLog(TraceLevels.LOG_TRACE, 'HB serverTime delta update: ' + delta)
      this.serverTimeDelta = delta
      deltaUpdated = true
      // call the method of TSDManager to update server delta time
      if (this.dm.TSDMan) {
        this.dm.TSDMan.updateServerDeltaTime(delta)
      }
    }
    
    if (this.serverVersion !== serverVersion || deltaUpdated) {
      this.serverVersion = serverVersion
      if (this.serverDataUpdate !== null)
      {
        // callback to main.js
        this.serverDataUpdate(serverVersion, serverTime, this.serverTimeDelta)
      }
    }
  }

  /**
  Set the maximum difference (delta) time between server and local time (in ms). Used in adjusting local time in logs.
  @param {number} newServerTimeDeltaMaxDiff in ms.
  @protected
  */
  setServerTimeDeltaMaxDiff(newServerTimeDeltaMaxDiff)
  {
    this.serverTimeDeltaMaxDiff = newServerTimeDeltaMaxDiff
  }
  
  /**
  Set the minimum change in delta time between server and local time (in ms). Used in adjusting local time in logs.
  @param {number} newServerTimeDeltaMinChange in ms.
  @protected
  */
  setServerTimeDeltaMinChange(newServerTimeDeltaMinChange)
  {
    this.serverTimeDeltaMinChange = newServerTimeDeltaMinChange
  }
}
