import { PaxValidationActions } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

export function updatePaxValidationData(type, obj) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'updatePaxValidationData ... ' + type)
  switch (type) {
    case 'updateDocumentImg':
      return {
        type: PaxValidationActions.UPDATE_DOCUMENT_IMG,
        paxDocImage: obj
      }
    case 'updateFaceImage':
      return {
        type: PaxValidationActions.UPDATE_FACE_IMG,
        paxFaceImage: obj
      }
    case 'updatePaxPhotoStatus':
      return {
        type: PaxValidationActions.UPDATE_PAX_PHOTO_STATUS,
        paxPhotoStatus: obj
      }
    case 'updatePaxFaceImg':
      return {
        type: PaxValidationActions.UPDATE_PAX_FACE_IMG,
        paxFaceImage: obj
      }
    case 'updateCurrentPassenger':
      return {
        type: PaxValidationActions.UPDATE_CURRENT_PASSENGER,
        currentPassenger: obj
      }
    case 'updateDocRetryStauts':
      return {
        type: PaxValidationActions.UPDATE_DOC_RETRY_STATUS,
        docRetryStatus: obj
      }
    case 'updateDocType':
      return {
        type: PaxValidationActions.UPDATE_DOC_TYPE,
        docType: obj
      }
    case 'updatePhotoMatchDetail':
      return {
        type: PaxValidationActions.UPDATE_PHOTO_MATCH_DETAIL,
        photoMatchDetail: obj
      }
    case 'updateIsAccuantImage':
      return {
        type: PaxValidationActions.UPDATE_IS_ACCUANT_IMAGE,
        isAccuantImage: obj
      }
    case 'updateScanDocumentType':
      return {
        type: PaxValidationActions.UPDATE_SCAN_DOCUMENT_TYPE,
        scanDocumentType: obj
      }
    case 'initialPaxData':
      return {
        type: PaxValidationActions.INITIAL_PAX_DATA
      }
    default:
  }
}
