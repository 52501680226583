import { DynamicImage, PageSubTitle, PageTitle } from 'components/styledComponents'
import { FALSE } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { ATBPrinterOnEvent } from 'devices/callbacks'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import useStateRef from 'hooks/useStateRef'
import { getBuildAccessibility, getDeviceManager } from 'main'
import React, { useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, goToLocalError, navigate, populateItineraryInfo } from 'utils/helper'
import { appLog, logEvent } from 'utils/Logger'
import { Footer } from '../footer'

const isLangRequired = false
const isQuitRequired = false
const PrintReceipt = (props) => {
  const intl = useIntl()
  const atbPrinter = getDeviceManager().getDevice(deviceIds.ATB_PRINTER)
  const ticketPectab = useSelector((state) => state.responses.bagtagResources)
  const ticket = useSelector((state) => state.responses.paxDocuments)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const bagsDetail = useSelector((state) => state.localData.BagsDetail)
  const themeContext = useContext(ThemeContext)
  const [currentTicket, setCurrentTicket, currentTicketRef] = useStateRef(0)
  const ticketsRef = useRef()
  if (ticket) {
    ticketsRef.current = ticket.slice()
  }
  const [totalWeight, setTotalWeight, totalWeightRef] = useStateRef(0)
  const [numOfBags, setNumOfBags, numOfBagsRef] = useStateRef(0)

  const atbPrinterCallback = (event) => {
    let isError = false
    //this.timeoutManger.resetTimer()
    appLog(TraceLevels.LOG_EXT_TRACE, 'atbPrinterCallback ... event : ' + JSON.stringify(event))
    switch (event.key) {
      case 'pectabLoadingComplete':
        console.log('template is loaded, ready to print document...')
        setCurrentTicket(1)
        atbPrinter.print(ticketsRef.current[currentTicketRef.current - 1], 10000)
        break
      case 'ticketPrintingComplete':
        console.log('atbPrinterCallback() : ticketPrintingComplete ... ')
        logEvent('PrintingReceipt: 1')          
        logEvents(
          EventFlows.Printing,
          EventFunctions.ReceiptPrinted,
          'Receipt printed'
        )
        navigate('ClosingRemarks', 8)
        break
      case 'pectabFailed':
        appLog(TraceLevels.LOG_EXT_TRACE, 'pectab failed.')        
        logEvents(
          EventFlows.Printing,
          EventFunctions.ReceiptPrinted,
          'Receipt printed failed, pectab failed'
        )
        isError = true
        break
      case 'ticketFailed':
        appLog(TraceLevels.LOG_EXT_TRACE, 'ticket failed. ')
        isError = true
        logEvent('PrintingReceipt: Failed')
        logEvents(
          EventFlows.Printing,
          EventFunctions.ReceiptPrinted,
          'Receipt printed failed'
        )
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange. ' + event.value.toString())
        logEvents(
          EventFlows.Printing,
          EventFunctions.ReceiptPrinted,
          'Receipt printed failed, ' + event.value.toString()
        )
        if (event.value.length === 2) {
          if (event.value[1]) {
            // true means fatal error
            appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange ... atb printer failed. ')
            isError = true
          }
        }
        break
      default:
    }
    if (isError) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'print receipt Failed. ')
      goToLocalError('PrintReceipt', ErrCodes.RECEIPT_PRINT_ERROR, 'BagDropDeviceError', 'OOS', null, 'error')
    }
  }

  const handleActions = (e) => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(PrintReceipt.js): handleActions() ... ' + e.currentTarget.id)
    // TEST CODE
    if (e.currentTarget.id === 'testButtonPrinted') {
      let ev = {
        key: 'pectabLoadingComplete',
        value: 0
      }
      atbPrinterCallback(ev)
      setTimeout(() => {
        let ev = {
          key: 'ticketPrintingComplete',
          value: 0
        }
        atbPrinterCallback(ev)
      }, 1000)
    } else if (e.currentTarget.id === 'testButtonError') {
      let ev = {
        // key: 'statusChange', //bagtagFailed or bagtagFailed
        // value: [203, true]
        key: 'ticketFailed', //bagtagFailed or bagtagFailed
        value: null
      }
      return atbPrinterCallback(ev)
    }
    // end OF TEST CODE
  }

  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '>> (PrintReceipt.js) componentDidMount() - loadPECTABArray')
    if (atbPrinter) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'set printer callback')
      atbPrinter.OnDeviceEvent = atbPrinterCallback
      atbPrinter.loadPECTABArray(ticketPectab, FALSE)
    }
    //  set style for size
    return () => {
      if (atbPrinter) {
        atbPrinter.OnDeviceEvent = ATBPrinterOnEvent
      }
    }
  }, [])

  useEffect(() => {
    if (bagsDetail) {
      bagsDetail.forEach((bagData) => {
        let weight = parseFloat(bagData.measurements[0].replace('KG', ''))
        let weightSum = (totalWeightRef.current + weight).toFixed(1)
        setTotalWeight(parseFloat(weightSum))
        setNumOfBags((bags) => bags + 1)
      })
    }
  }, [bagsDetail])

  const testData = [
    {
      id: 'DESC',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'testButtonPrinted',
      text: 'Print',
      group: 0,
      handler: handleActions
    },
    {
      id: 'DESC',
      text: 'Error Cases:',
      group: 1
    },
    {
      id: 'testButtonError',
      text: 'print error',
      group: 1,
      handler: handleActions
    }
  ]
  const { formatMessage } = intl
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const header = null

  const title = formatMessage(messages.PrintDocumentsTitle)
  const subtitle = formatMessage(messages.PrintDocuments, {
    numberOfBags: numOfBagsRef.current,
    totalWeight: totalWeightRef.current
  })

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale, title])

  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined
    const accDef = {
      pathName: 'PrintReceipt',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        isLangRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicText',
            textParameters: [title]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {title}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {subtitle}
      </PageSubTitle>
    </>
  )
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const animationSection = (
    <DynamicImage
      imageName={`animations/embross/${sbdModel}/print-receipt.png`}
      cssName={'animation'}
      width={animationSize.width}
      height={animationSize.height}
    />
  )
  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isLangRequired={false}
        isConfirmRequired={false}
        testData={testData}
      />
    </>
  )
  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = {
    landscapeMode: '50%',
    portraitMode: '90%'
  }

  const { UIDisplay } = useUIBase(
    {
      header,
      topSection: textSection,
      bottomSection: animationSection,
      footer
    },
    {
      contentWidth: contentWidth,
      itineraryHeights: ['200px', '120px', '200px']
    }
  )

  return <>{UIDisplay}</>
}

PrintReceipt.propTypes = {}

export default PrintReceipt
