import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

export default class AEACommand {
  constructor() {
    /** AEA BAGDROP command.*/
    this._cmd = ''

    /** AEA BAGDROP command response prefix.*/
    this._prefix = 'HDC'

    /** AEA BAGDROP command response.*/
    this._response = ''

    /** AEA BAGDROP unsolicited message.*/
    this._msg = ''

    /** AEA BAGDROP parsed response.*/
    this._parsedResponse = null
  }

  /*
   * returns:
   *   0 - OK
   *   1 - format error: no prefix, too short
   *   2 - unexpected response
   */
  parse(message) {
    //appLog(TraceLevels.LOG_EXT_TRACE,"parse message:"+message);
    if (message.length < 7) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'AEACommand.parse message.length < 7 ' + message)
      return 1
    }
    if (message.indexOf(this._prefix) != 0) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'AEACommand.parse index of prefix!=0 ' + message + ' prefix: ' + this._prefix)
      return 1
    }
    const msg = message.substring(this._prefix.length)
    const tokens = msg.split('#')
    const tokensLen = tokens.length
    this._parsedResponse = new Object()

    //appLog(TraceLevels.LOG_EXT_TRACE,"parse tokens[0]:"+tokens[0]);
    this._parsedResponse['CMD'] = tokens[0]
    if (tokens[0].indexOf('ERR') == 0)
      //ERR2 or ERR5#xxxx
      this._parsedResponse['ERR'] = msg
    else if (tokens[0].indexOf('OK') == 2 || tokens[0].indexOf('NI') == 2) {
      // SQNI/BQNI or EPOK# or PROK#..
      const cmd = tokens[0].substr(0, 2)
      if (tokens[0].indexOf('OK') == 2) this._parsedResponse['OK'] = cmd
      else this._parsedResponse['NI'] = cmd
      if (cmd.indexOf('SQ') == 0 || cmd.indexOf('BQ') == 0 || cmd.indexOf('EP') == 0 || cmd.indexOf('ES') == 0) {
        for (let i = 1; i < tokensLen; i++) {
          let params = tokens[i].split('=')
          if (params.length == 2) {
            if (params[0] == 'LP') {
              // multiple Licence plates
              if (this._parsedResponse['LP'] != null && this._parsedResponse['LP'] != 'undefined')
                this._parsedResponse['LP'] = this._parsedResponse['LP'] + '$' + params[1]
              else this._parsedResponse['LP'] = params[1]
            } else this._parsedResponse[params[0]] = params[1]
          } // some SQ/SQNI parameters BBx, BSx, PBx, IAx
          else this._parsedResponse[params[0]] = params[0]
        }
      } else if (cmd.indexOf('PR') == 0) {
        this._parsedResponse['CC'] = msg //PR is a response to CC command save full response
      } else if (cmd.indexOf('AV') == 0 || cmd.indexOf('PV') == 0 || cmd.indexOf('CT') == 0) {
        this._parsedResponse[cmd] = msg //save full response
      } else if (cmd.indexOf('RI') == 0) {
        // PVxxxxx#CDyyyyy etc - 2 char parameter name
        for (let i = 1; i < tokensLen; i++) {
          if (tokens[i].length > 1) this._parsedResponse[tokens[i].substr(0, 2)] = tokens[i].substring(2)
        }
      } else if (cmd.indexOf('RC') == 0) {
        //B1#ZMW....   - one char parameter name + some complex: WU...Zx....TM....
        for (let i = 1; i < tokensLen; i++) {
          if (tokens[i].length > 0) this._parsedResponse[tokens[i].substr(0, 1)] = tokens[i].substring(1)
        }
        //				this.saveBagdropLimits();
      } else if (
        cmd.indexOf('LA') == 0 ||
        cmd.indexOf('CW') == 0 ||
        cmd.indexOf('CR') == 0 ||
        cmd.indexOf('CE') == 0 ||
        cmd.indexOf('CB') == 0
      ) {
        // no additional data
        this._parsedResponse[cmd] = msg // msg should be empty string
      } // unexpected response
      else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'AEACommand.parse unexpected response: ' + message)
        return 2
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'AEACommand.parse unexpected token: ' + tokens[0])
      this._parsedResponse['UNEXP'] = tokens[0] // return format error?
      return 1
    }
    return 0
  }

  getParsedMessage() {
    return this._parsedResponse
  }
}
