import { SettingActions } from 'constants/Constants'
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

export function updateSettings(type, obj) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'updateSettings ... ' + type)
  switch (type) {
    case 'updateServiceDefinition':
      return {
        type: SettingActions.UPDATE_SERVICE_DEFINITION,
        serviceDefinition: obj
      }
    case 'updateServiceConfiguration':
      return {
        type: SettingActions.UPDATE_SERVICE_CONFIGURATION,
        serviceConfiguration: obj
      }
    case 'updateFirstTransition':
      return {
        type: SettingActions.UPDATE_FIRST_TRANSITION,
        firstTransition: obj
      }
    case 'updateServerTimeString':
      return {
        type: SettingActions.UPDATE_SERVER_TIME,
        serverTimeString: obj
      }
    case 'updateFaceServiceUsedFor':
      return {
        type: SettingActions.UPDATE_FACESERVICE_USEDFOR,
        faceServiceUsedFor: obj
      }
    case 'updateSbdScanExtend':
      return {
        type: SettingActions.SBD_SCAN_EXTEND,
        sbdScanExtend: obj
      }
    /*default:
      return {
        type: Actions.CLEAR_DATA,
        null
      }*/
  }
}
