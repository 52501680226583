import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Face Tracking listener.
 *  @extends {Listener}
 */
export default class FaceTrackingListener extends Listener {
  /**
   *  Default faceAbsent callback.
   */
  faceAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.faceAbsent() called')
    this.setEvent('faceAbsent')
  }

  /**
   *  Default facePresent callback.
   */
  facePresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.facePresent() called')
    this.setEvent('facePresent')
  }

  /**
   *  Default pictureTaken callback.
   *  @param {String} data visible photo dimensions, visible photo, ir photo dimensions, ir photo, photo quality
   *  @example 356-356, /9j/4AAQSkZJ..., 199-199, iVBORw0KGgoAAAAN,
   *  <CapturedEyesOpen>false</CapturedEyesOpen><CapturedMouthClosed>true</CapturedMouthClosed><CapturedFrontal>true</CapturedFrontal>
   */
  pictureTaken(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.pictureTaken() called')
    this.setEvent('pictureTaken', data)
  }

  /**
   *  Default trackingEvent callback.
   *  @param {String} data code, description
   *  <br/>The code is sum of the following values:
   *  <ul><li>1   ready</li>
   *  <li>2   enabled</li>
   *  <li>4   preview</li>
   *  <li>8   Valid Face</li>
   *  <li>16  No Frontal</li>
   *  <li>32  Eye Closed</li>
   *  <li>64  Mouth Opened</li>
   *  <li>128 Glassed Found</li>
   *  </ul>
   *  Description is based on values 8 to 128 and can be a concatenation of several code descriptions.
   *  The "No Valid Face Detected" is added to the description when the valid face code(8) is not "on".
    @example 15, Valid Face
    71, Mouth Opened
    39, No Valid Face Detected, Eye Closed
   */
  trackingEvent(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.trackingEvent() called')
    this.setEvent('trackingEvent', data)
  }

  /**
   *  Default dataError callback.
   *  @param {String} data status, fatal
   *  <ul><li>status: status is a CUSS status code.</li>
   *    <li>fatal: boolean fatal - Determine whether the status is fatal. If fatal error, application should take appropriate measures to go out of service.</li></ul>
   *  </ul>
   *  @example 302, false
   */
  dataError(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.dataError() called')
    this.setEvent('dataError', data)
  }

  /**
   *  Default analyze callback.
   *  @param {string} data visible photo analyze results, ir photo analyze results
   *  @example <Score>2036<\/Score><AlgorithmEmployed>CGTFace<\/AlgorithmEmployed>
   *  <AlgorithmVersionIdentifier>4.5.0.3259<\/AlgorithmVersionIdentifier><AlgorithmExecutionTime>1219<\/AlgorithmExecutionTime>
   *  ,<Score>2127<\/Score><AlgorithmEmployed>CGTFace<\/AlgorithmEmployed>
   *  <AlgorithmVersionIdentifier>4.5.0.3259<\/AlgorithmVersionIdentifier>
   *  <AlgorithmExecutionTime>1125<\/AlgorithmExecutionTime>
   */
  analyze(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.analyze() called')
    this.setEvent('analyze', data)
  }

  /**
   *  Default analyzeResult callback. When the config.sharePassportPhoto is true then the analyze is call directly by CUSSAppLink
   *  after the photo is taken.
   *  @param {string} data visible photo analyze results, ir photo analyze results
   *  @example <Score>2036<\/Score><AlgorithmEmployed>CGTFace<\/AlgorithmEmployed>
   *  <AlgorithmVersionIdentifier>4.5.0.3259<\/AlgorithmVersionIdentifier><AlgorithmExecutionTime>1219<\/AlgorithmExecutionTime>
   *  ,<Score>2127<\/Score><AlgorithmEmployed>CGTFace<\/AlgorithmEmployed>
   *  <AlgorithmVersionIdentifier>4.5.0.3259<\/AlgorithmVersionIdentifier>
   *  <AlgorithmExecutionTime>1125<\/AlgorithmExecutionTime>
   */
  analyzeResult(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.analyzeResult() called')
    this.setEvent('analyzeResult', data)
  }

  /**
   *  Default show callback.
   *  @param {number} rc return code
   */
  show(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.show() called')
    this.setEvent('show', rc)
  }

  /**
   *  Default hide callback.
   *  @param {number} rc return code
   */
  hide(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.hide() called')
    this.setEvent('hide', rc)
  }

  /**
   *  Default toggleView callback.
   *  @param {number} rc return code
   */
  toggleView(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.toggleView() called')
    this.setEvent('toggleView', rc)
  }

  /**
   *  Default switchView callback.
   *  @param {string} data rc, info
   *  <ul><li>rc: return code.</li>
   *    <li>info: current and primary view ids in xml format.</li></ul>
   *  </ul>
   *  @example "0","<CurrentView>0<\/CurrentView><PrimaryView>0<\/PrimaryView>"
   */
  switchView(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.switchView() called')
    this.setEvent('switchView', data)
  }

  /**
   *  Default selectCamera callback.
   *  @param {number} rc return code
   */
  selectCamera(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.selectCamera() called')
    this.setEvent('selectCamera', rc)
  }

  /**
   *  Default countDown callback.
   *  @param {number} seconds
   */
  countDown(seconds) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.countDown() called')
    this.setEvent('countDown', seconds)
  }

  /**
   *  Default faceDescriptor callback.
   *  @param {String} data - comma separated string 
   *    0: face descriptor string (this parameter is null unless we are using luna face service in the lower level)
   *    1: base64 jpeg facial image
   *    2: the score obtained after 1:1 comparison
   *    3: details of the facial image in XML format, the format is 
          <SpoofedFaceImage>false</SpoofedFaceImage>
          <CapturedImageWidth>2300</CapturedImageWidth>
          <CapturedImageHeight>2000</CapturedImageHeight>
          <CapturedMask>true</CapturedMask>
          <CapturedGlasses>true</CapturedGlasses>
   */
  faceDescriptor(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.faceDescriptor() called')
    this.setEvent('faceDescriptor', data)
  }

  /**
   *  to return result of the EES kit assessment
   *  @param {String} result - result of the EES kit assessment
   * @example
   *    <USKResult>
   *      <BioQualityScore>95</BioQualityScore>
   *      <ConsolidatedQualityScore>95</ConsolidatedQualityScore>
   *      <FaceQualityDecision>ACCEPTABLE_FACE</FaceQualityDecision>
   *      <FaceQualityWarning>NOWARNING</FaceQualityWarning>
   *    </USKResult>
   *  In case there is an exception with the USK kit, the format is the below
   *     <USKResult>
   *        <USKException>LicenseException</USKException>
   *      </USKResult>
   *  The BioQuality and Consolidated Score is from value 0 to 100
   *  For the exception cases, if it’s a license issue it will show LicenseException and app can choose to go OOS, if its any other exception it will show that exception
   */
  eesFaceQualityAssessment(result) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'eesFaceQualityAssessment() result:', result)
    this.setEvent('eesFaceQualityAssessment', result)
  }

  /**
   *  Default faceMatching callback.
   *  @param {String} data - face descriptor, photo, similarity value
   */
  faceMatching(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.faceMatching() called')
    this.setEvent('faceMatching', data)
  }

  /**
   *  Default enable callback.
   *  @param {number} rc return code
   */
  enable(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.enable() called')
    this.setEvent('enable', rc)
  }

  /**
   *  Default disable callback.
   *  @param {number} rc return code
   */
  disable(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.disable() called')
    this.setEvent('disable', rc)
  }

  /**
   *  Default getStreamingURL callback.
   *  @param {string} response return url
   */
  getStreamingURL(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.getStreamingURL() called')
    this.device.StreamingUrl = response
    this.setEvent('getStreamingURL', response)
  }
  /**
   *  Default getVersion callback.
   *  @param {String} data
   */
  getVersion(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FaceTrackingListener.getVersion() called')
    this.setEvent('getVersion', data)
  }
}
