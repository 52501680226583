class BagData {
  constructor(
    ordinal = 0,
    tagNumber = '',
    weight = 0,
    weightValue = '',
    height = 0,
    length = 0,
    depth = 0,
    measure = ''
  ) {
    this.ordinal = ordinal
    this.tag = tagNumber
    this.weight = weight
    this.weightValue = weightValue
    this.height = height
    this.length = length
    this.depth = depth
    this.measure = measure

    this.errors = null
    this.agentOverride = false
    this.payment = false
    this.payValue = null
    this.inducted = false
  }
  set Weight(weight) {
    this.weight = weight
  }
  set WeightValue(weightValue) {
    this.weightValue = weightValue
  }
  set Height(height) {
    this.height = height
  }
  set Length(length) {
    this.length = length
  }
  set Depth(depth) {
    this.depth = depth
  }
  set Measure(measure) {
    this.measure = measure
  }
  set Errors(errors) {
    if (this.errors === null) {
      this.errors = errors
    } else {
      this.errors = `${this.errors},${errors}`
    }
  }
  set AgentOverride(agentOverride) {
    this.agentOverride = agentOverride
  }
  set Payment(payment) {
    this.payment = payment
  }
  set PayValue(payValue) {
    this.payValue = payValue
  }
  set Inducted(inducted) {
    this.inducted = inducted
  }

  generateOutput = () => {
    return {
      tag: this.tag,
      weight: this.weight,
      weightValue: this.weightValue,
      height: this.height,
      length: this.length,
      depth: this.depth,
      measure: this.measure,
      errors: this.errors,
      agentOverride: this.agentOverride,
      payment: this.payment,
      payValue: this.payValue,
      inducted: this.inducted
    }
  }
}

class DocScanData {
  constructor() {
    this.scanattempts = 0
    this.docType = null
    this.country = null
    this.state = null
    this.accuantAuth = null
    this.idError = null
    this.manualVerify = null
  }

  updateScanAttempts = () => {
    this.scanattempts += 1
  }

  updateError = error => {
    if (this.idError === null) {
      this.idError = error
    } else {
      this.idError = `${this.idError},${error}`
    }
  }

  set DocType(docType) {
    this.docType = docType
  }
  set Country(country) {
    this.country = country
  }
  set State(state) {
    this.state = state
  }
  set AccuantAuth(accuantAuth) {
    this.accuantAuth = accuantAuth
  }
  set IdError(idError) {
    this.idError = idError
  }
  set ManualVerify(manualVerify) {
    this.manualVerify = manualVerify
  }

  generateOutput = () => {
    return {
      scanattempts: this.scanattempts,
      docType: this.docType,
      country: this.country,
      state: this.state,
      accuantAuth: this.accuantAuth,
      idError: this.idError,
      manualVerify: this.manualVerify
    }
  }
}

class FacialData {
  constructor(privacyAccept = true) {
    this.privacyAccept = privacyAccept
    this.captureAttempts = null
    this.captureResult = null
    this.nonCaptureReason = null
    this.matchAttempts = null
    this.matchScore = null
    this.matchResult = null
    this.matchError = null
    this.manualVerify = null
  }
  set PrivacyAccept(privacyAccept) {
    this.privacyAccept = privacyAccept
  }
  set CaptureAttempts(captureAttempts) {
    this.captureAttempts = captureAttempts
  }
  set CaptureResult(captureResult) {
    this.captureResult = captureResult
  }
  set NonCaptureReason(nonCaptureReason) {
    this.nonCaptureReason = nonCaptureReason
  }
  set MatchAttempts(matchAttempts) {
    this.matchAttempts = matchAttempts
  }
  set MatchScore(matchScore) {
    this.matchScore = matchScore
  }
  set MatchResult(matchResult) {
    this.matchResult = matchResult
  }
  set MatchError(matchError) {
    this.matchError = matchError
  }
  set ManualVerify(manualVerify) {
    this.manualVerify = manualVerify
  }

  updateCaptureAttempts = () => {
    this.captureAttempts += 1
  }

  updateMatchAttempts = () => {
    this.matchAttempts += 1
  }

  updateError = error => {
    if (this.error === null) {
      this.error = error
    } else {
      this.error = `${this.error},${error}`
    }
  }

  generateOutput = () => {
    return {
      privacyAccept: this.privacyAccept,
      captureAttempts: this.captureAttempts,
      captureResult: this.captureResult,
      nonCaptureReason: this.nonCaptureReason,
      matchAttempts: this.matchAttempts,
      matchScore: this.matchScore,
      matchResult: this.matchResult,
      matchError: this.matchError,
      manualVerify: this.manualVerify
    }
  }
}

export default class SummaryStore {
  constructor(kioskId = '', defaultLanguage = '') {
    this.deviceID = kioskId
    this.language = defaultLanguage

    this.resetStore()
  }

  updateTouch = () => {
    this.touch += 1
  }

  updateBagDispath = () => {
    this.numofbags += 1
  }

  updateTubNumber = () => {
    this.numoftubs += 1
  }

  updateTotaloverrides = () => {
    this.totaloverrides += 1
  }

  resetStore = () => {
    this.currentPax = null
    this.currentBagtag = null
    this.currentDocScan = null
    this.currentPhotoMatch = null

    this.transactionDetail = new Map()
    this.bagList = new Map()
    this.combineList = new Map()

    this.pnr = null
    this.carrier = null
    this.flight = null
    this.dep = null
    this.arr = null

    this.startTime = null
    this.endTime = null
    this.transactionID = null

    this.numofpax = 0
    this.numofbags = 0
    this.touch = 0
    this.numoftubs = 0
    this.totaloverrides = 0
    this.endtxncode = null
    this.generalErrors = null
    this.pax = []
    this.totalDuration = 0
    console.log('RESET CHECK BAG LIST', this.bagList)
  }

  set CurrentPax(currentPax) {
    this.currentPax = currentPax
  }

  set Language(language) {
    this.language = language
  }

  set TransactionID(transactionID) {
    this.transactionID = transactionID
  }

  set RecordLocator(recordLocator) {
    this.pnr = recordLocator
  }
  set Carrier(carrier) {
    this.carrier = carrier
  }
  set Flight(flight) {
    this.flight = flight
  }
  set Departure(departure) {
    this.dep = departure
  }
  set Arrival(arrival) {
    this.arr = arrival
  }

  set BaggageDetail(baggage) {
    this.baggageDetail = baggage
  }

  set StartTime(startTime) {
    this.startTime = startTime
  }

  set EndTime(endTime) {
    this.endTime = endTime
  }

  set Numofpax(numofpax) {
    this.numofpax = numofpax
  }

  set CurrentBagtag(currentBagtag) {
    this.currentBagtag = currentBagtag
  }

  set Endtxncode(endtxncode) {
    this.endtxncode = endtxncode
  }

  set Totaloverrides(totaloverrides) {
    this.totaloverrides = totaloverrides
  }
  set Endtxncode(endtxncode) {
    this.endtxncode = endtxncode
  }
  set GeneralErrors(generalErrors) {
    if (this.generalErrors === null) {
      this.generalErrors = generalErrors
    } else {
      this.generalErrors = `${this.generalErrors},${generalErrors}`
    }
  }

  updateTransactionDetail = (ordinal, type, data) => {
    const ordinalName = ordinal
    let updateObj = this.transactionDetail.get(ordinalName)
    switch (type) {
      case 'doc':
        if (updateObj === undefined) {
          updateObj = {
            doc: [data]
          }
        } else {
          updateObj = {
            ...updateObj,
            doc: [data]
          }
        }
        break
      case 'face':
        if (updateObj === undefined) {
          updateObj = {
            face: data
          }
        } else {
          updateObj = {
            ...updateObj,
            face: data
          }
        }
        break
      default:
        break
    }
    this.transactionDetail.set(ordinalName, updateObj)
  }

  startDocScan = () => {
    this.currentDocScan = new DocScanData()
  }

  updateDocScan = (docType = null, country = null, state = null, accuantAuth = null) => {
    if (this.currentDocScan) {
      this.currentDocScan.updateScanAttempts()
      this.currentDocScan.DocType = docType
      this.currentDocScan.Country = country
      this.currentDocScan.State = state
      this.currentDocScan.AccuantAuth = accuantAuth
    }
  }

  updateDocScanError = error => {
    if (this.currentDocScan) {
      this.currentDocScan.updateError(error)
    }
  }

  endDocScan = (manualVerify = false) => {
    if (this.currentDocScan) {
      this.currentDocScan.ManualVerify = manualVerify
      this.updateTransactionDetail(this.currentPax, 'doc', this.currentDocScan.generateOutput())
      this.currentDocScan = null
    }
  }

  startFacialData = privacyAccept => {
    this.currentPhotoMatch = new FacialData(privacyAccept)
  }

  updateFacialData = (matchScore = null, matchResult = null, captureResult = null) => {
    if (this.currentPhotoMatch) {
      this.currentPhotoMatch.updateCaptureAttempts()
      this.currentPhotoMatch.updateMatchAttempts()

      this.currentPhotoMatch.MatchScore = matchScore
      this.currentPhotoMatch.MatchResult = matchResult
      this.currentPhotoMatch.CaptureResult = captureResult
    }
  }

  updateFacialDataError = error => {
    if (this.currentPhotoMatch) {
      this.currentPhotoMatch.updateError(error)
    }
  }

  endFacialData = (manualVerify = false) => {
    if (this.currentPhotoMatch) {
      this.currentPhotoMatch.manualVerify = manualVerify
      this.updateTransactionDetail(this.currentPax, 'face', this.currentPhotoMatch.generateOutput())
      this.currentPhotoMatch = null
    }
  }

  startBagData = (ordinal, tagNumber, weight, weightValue, height, length, depth, measure) => {
    if (this.bagList.has(tagNumber)) {
      this.updateBagData(tagNumber, 'weight', weight)
      this.updateBagData(tagNumber, 'weightValue', weightValue)
      this.updateBagData(tagNumber, 'height', height)
      this.updateBagData(tagNumber, 'length', length)
      this.updateBagData(tagNumber, 'depth', depth)
      this.updateBagData(tagNumber, 'measure', measure)
    } else {
      const newBagData = new BagData(ordinal, tagNumber, weight, weightValue, height, length, depth, measure)
      this.bagList.set(tagNumber, newBagData)
    }
  }

  updateBagData = (tagNumber, type, data) => {
    if (this.bagList.has(tagNumber)) {
      let bagData = this.bagList.get(tagNumber)
      switch (type) {
        case 'weight':
          bagData.Weight = data
          break
        case 'weightValue':
          bagData.WeightValue = data
          break
        case 'height':
          bagData.Height = data
          break
        case 'length':
          bagData.Length = data
          break
        case 'depth':
          bagData.Depth = data
          break
        case 'measure':
          bagData.Measure = data
          break
        case 'agentOverride':
          bagData.AgentOverride = data
          break
        case 'payment':
          bagData.Payment = data
          break
        case 'payValue':
          bagData.PayValue = data
          break
        case 'inducted':
          bagData.Inducted = data
          break
        case 'errors':
          bagData.Errors = data
      }
      this.bagList.set(tagNumber, bagData)
    }
  }

  generateSummary() {
    // console.log('this.deviceID :::', this.deviceID)
    // console.log('this.language :::', this.language)
    // this.transactionDetail
    // bagList
    // console.log('this.pnr :::', this.pnr)
    // console.log('this.carrier :::', this.carrier)
    // console.log('this.flight :::', this.flight)
    // console.log('this.dep :::', this.dep)
    // console.log('this.arr :::', this.arr)

    // console.log('this.startTime :::', this.startTime)
    // console.log('this.endTime :::', this.endTime)
    // console.log('this.totalDuration :::', this.endTime - this.startTime)
    // console.log('this.transactionID :::', this.transactionID)

    // console.log('this.numofpax:::', this.numofpax)
    // console.log('this.numofbags:::', this.numofbags)
    // console.log('this.touch:::', this.touch)
    // console.log('this.numoftubs:::', this.numoftubs)
    // console.log('this.totaloverrides:::', this.totaloverrides)
    // console.log('this.endtxncode:::', this.endtxncode)
    // console.log('this.generalErrors:::', this.generalErrors)

    // show bag list
    // console.log('CHECK transactionDetail :::', this.transactionDetail)
    for (const ele of this.transactionDetail) {
      const ordinal = ele[0]
      const paxData = ele[1]

      this.combineList.set(ordinal, paxData)
    }

    // console.log('CHECK bagList :::', this.bagList)

    for (const bag of this.bagList) {
      const bagData = bag[1]
      if (bagData.ordinal !== null) {
        if (this.combineList.has(bagData.ordinal)) {
          const paxData = this.combineList.get(bagData.ordinal)
          if (paxData.bag === undefined) {
            this.combineList.set(bagData.ordinal, { ...paxData, bag: [bagData.generateOutput()] })
          } else {
            this.combineList.set(bagData.ordinal, { ...paxData, bag: [...paxData.bag, bagData.generateOutput()] })
          }
        } else {
          this.combineList.set(bagData.ordinal, { bag: [bagData.generateOutput()] })
        }
      }
    }

    // console.log('CHECK combineList :::', this.combineList)
    for (const ele of this.combineList) {
      this.pax = [...this.pax, ele[1]]
    }

    // console.log('this.pax:::', this.pax)

    return {
      startTime: this.startTime,
      transactionID: this.transactionID,
      language: this.language,
      pnr: this.pnr,
      numOfPax: this.numofpax,
      numOfBags: this.numofbags,
      carrier: this.carrier,
      flight: this.flight,
      dep: this.dep,
      arr: this.arr,
      touch: this.touch,
      numOfTubs: this.numoftubs,
      totalOverrides: this.totaloverrides,
      totalDuration: (this.endTime - this.startTime) / 1000,
      endTime: this.endTime,
      endTxnCode: this.endtxncode,
      generalErrors: this.generalErrors,
      pax: this.pax
    }
  }
}
