import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Laser Printer listener.
 *  @extends {Listener}
 */
export default class LaserPrinterListener extends Listener
{

  /**
   *  Default pagePrintComplete callback.
   *  @param {String} data index, totalPages
   */
  pagePrintComplete(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.pagePrintComplete() called index: totalPages: ' + data)
    this.setEvent('pagePrintComplete', data)
  }

  /**
   *  Default pagePrintFailed callback.
   *  @param {String} data index, totalPages
   */
  pagePrintFailed(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.pagePrintFailed() called index: totalPages: ' + data)
    this.setEvent('pagePrintFailed', data)
  }

  /**
   *  Default pagePrinted callback.  Called when a page is printed
   *  @param {String} data isPaperNormal, isPaperLow
   */
  pagePrinted(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.pagePrinted() called isPaperNormal: isPaperLow: ' + data)
    this.setEvent('pagePrinted', data)
  }

  /**
   *  Default pagePresent callback.
   *  @param {String} data index, totalPages
   */
  pagePresent()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.pagePresent() called : ')
    this.setEvent('pagePresent', '')
  }

  /**
   *  Default pageRemoved callback.
   *  @param {String} data index, totalPages
   */
  pageRemoved()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.pagePresent() called : ')
    this.setEvent('pageRemoved', '')
  }


  /**
   	 *  Default printerError callback.  Called when an error occurred
   	 *  @param {String} data returnCode, description, isFatal
   	 */
  printerError(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.printerError() called returnCode: description: isFatal: ' + data)
    this.setEvent('printerError', data)
  }

  /**
   *  Default printerTrayStatusChanged callback.  Called when status changes for a tray
   *  @param {String} data trayNumber, trayStatus
   */
  printerTrayStatusChanged(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.printerTrayStatusChanged() called trayNumber: trayStatus: ' + data)
    this.setEvent('printerTrayStatusChanged', data)
  }

  /**
   *  Default printFileByteStream callback.
   *  @param {number} rc return code from printFileByteStream
   */
  printFileByteStream(rc)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.printFileByteStream() called rc: ' + rc)
    this.setEvent('printFileByteStream', rc)
  }
  
  /**
   *  Default printImageStream callback.
   *  @param {number} rc return code from printImageStream
   */
  printImageStream(rc)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.printImageStream() called rc: ' + rc)
    this.setEvent('printImageStream', rc)
  }
  
  /* laser printer errors */
  /**
   *  Default pagePrintFailure callback.
   *  @param {string} data errorDescr, rc
   */
  pagePrintFailure(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.pagePrintFailure() called errorDescr: rc: ' + data)
    this.setEvent('pagePrintFailure', data)
  }

  /**
   *  Default offer callback.
   *  @param {int} rc return code from offer: 104 - media absent, 103 - media present
   */
  offer(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.offer() called')
    this.setEvent('offer', rc)
    if (rc === '104') { //media absent
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'LaserPrinterListener.offer() - generate pageRemoved event')
      this.setEvent('pageRemoved', null)
    }
  }
}
