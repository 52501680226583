import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { PageTitle, PageSubTitle, DynamicImage } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility } from 'main'
import { Footer } from 'layouts/footer'
import useMedia from 'hooks/useMedia'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'

const PleaseWait = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const locale = useSelector((state) => state.localData.locale)
  const [displayMessage, setDisplayMessage] = useState('')
  const [displayTitle, setDisplayTitle] = useState(intl.formatMessage(messages.PleaseWaitTitle))
  const location = useLocation()
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  useEffect(() => {
    if (location?.state?.messageId) {
      if (location.state.messageId === 'PleaseWaitFindReservation') {
        setDisplayMessage(
          intl.formatMessage(
            config.sbdPlatform === 'SITA' ? messages.PleaseWaitFindReservationSITA : messages[location.state.messageId]
          )
        )
      } else {
        intl.formatMessage(messages[location.state.messageId])
      }
      if (location.state.messageId === 'PleaseWaitFindReservation') {
        setDisplayTitle(
          intl.formatMessage(
            config.sbdPlatform === 'SITA'
              ? messages.PleaseWaitFindReservationTitleSITA
              : messages.PleaseWaitFindReservationTitle
          )
        )
      }
    } else if (props.pleaseWaitTextId) {
      if (props.pleaseWaitTextId === 'PleaseWaitFindReservation') {
        setDisplayMessage(
          intl.formatMessage(
            config.sbdPlatform === 'SITA' ? messages.PleaseWaitFindReservationSITA : messages[props.pleaseWaitTextId]
          )
        )
      } else {
        intl.formatMessage(messages[props.pleaseWaitTextId])
      }
      if (props.pleaseWaitTextId === 'PleaseWaitFindReservation') {
        setDisplayTitle(
          intl.formatMessage(
            config.sbdPlatform === 'SITA'
              ? messages.PleaseWaitFindReservationTitleSITA
              : messages.PleaseWaitFindReservationTitle
          )
        )
      }
    }
  }, [location, props.pleaseWaitTextId])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  /* useEffect(() => {
    if (location?.state?.messageId || props.pleaseWaitTextId) {
      if (displayMessage !== '') {
        handleAccessibility()
      }
    } else {
      handleAccessibility()
    }
  }, [displayMessage]) */

  useEffect(() => {
    handleAccessibility()
  }, [locale, displayMessage])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'pleaseWait',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [displayTitle, displayMessage]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  // check SBD platform config
  appLog(TraceLevels.LOG_TRACE, `pleaseWait.js config.sbdPlatform = ${config.sbdPlatform}`)

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${themeContext.AnimationPleaseWait}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {displayTitle}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {displayMessage}
      </PageSubTitle>
    </>
  )

  const header = null

  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={false}
        isHelpRequired={false}
        displayItinerary={false}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}
export default PleaseWait
