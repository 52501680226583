import React, { useRef, useState, useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ThemeContext } from 'styled-components'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getDeviceManager, getTimeoutMgr, getSBDAppMan, getAccessibilityManager, getAccessibilityDevice } from 'main'
import { END_TXN_REASON, MEDIATYPES, ACC_POPUPS } from 'constants/Constants'
import { Button, Popup, IconButton } from 'components'
import {
  DynamicImage,
  PageContent,
  PageSubContent,
  PageTitle,
  PopupTitle,
  PopupSubTitle,
  Spacer
} from 'components/styledComponents'
import { updateLocalData } from 'actions/localActions'
import { passportReaderOnEvent } from 'devices/callbacks'
import { maskPCI, goToLocalGenericError } from 'utils/helper'
import { appLog } from 'utils/Logger'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { Animation } from 'components'
import { ErrCodes } from 'constants/Errors'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const parsePassportData = (data) => {
  let result = {}
  let docImage = {
    Vis: null,
    UV: null,
    IR: null
  }
  let faceImage = null
  let docType = null
  let mediaData = {
    track1: null,
    track2: null,
    track3: null,
    mediaType: null
  }
  let tracks = []
  // e-passport:
  // passportData[0] = mrz string separated by '_@'
  // passportData[1] = rfmrz string separated by '_@' (optional)       - in <EPASSPORT_DG1>..</EPASSPORT_DG1>  for RF
  // passportData[2] = rfphoto base64 encoded photo string (optional)
  // passportData[3] = parsed mrz or rfmrz(in present) as an array of strings (optional if the global parameter ParseMedia is true
  //      then Media library will be used to parse passport data)
  //      if case of parse errors then it will contain error code
  // passportData[4] = if there is a chip:  RFCHIP=Y
  // passportData[5] = efcom base64 encoded string (optional)
  // passportData[6] = efsod base64 encoded string (optional)
  // passportData[7] = dg1file base64 encoded string (optional)
  // passportData[8] = dg2file base64 encoded string (optional)
  // passportData[9] = vis base64 encoded image if available (optional)
  // passportData[10] = uv base64 encoded image if available (optional)
  // passportData[11] = ir base64 encoded image if available (optional)
  // passportData[12] = cropped photo base64 encoded image if available (optional)
  // future might have more
  // passportData.length >= 13

  // no rfid passport/ idcard
  // passportData[0] = mrz string separated by '_@'
  // passportData[1] = vis base64 encoded image if available (optional)
  // passportData[2] = uv base64 encoded image if available (optional)
  // passportData[3] = ir base64 encoded image if available (optional)
  // passportData[4] = cropped photo base64 encoded image if available (optional)
  // future might have more
  // passportData.length >= 5
  try {
    let parsedDataStart = data.indexOf('[')
    let passportData = []
    if (parsedDataStart >= 0) {
      passportData = data.substring(0, parsedDataStart).split(',')
      let parsedDataEnd = data.indexOf(']')
      // if (parsedDataEnd + 2 < data.length) extendedPassportData = data.substring(parsedDataEnd + 2).split(',')
    } else {
      passportData = data.split(',')
    }
    passportData = passportData.map((item) => item.trim())
    if (passportData.length >= 13) {
      docImage.Vis = passportData[9] !== '' && passportData[9] !== 'null' ? passportData[9] : null
      docImage.UV =
        passportData[10] !== '' && passportData[10] !== 'null' && config.useDocUVImage ? passportData[10] : null
      docImage.IR =
        passportData[11] !== '' && passportData[11] !== 'null' && config.useDocIRImage ? passportData[11] : null
      faceImage = passportData[2] !== '' && passportData[2] !== 'null' ? passportData[2] : passportData[12]
      docType = MEDIATYPES.PASSPORT
      result = { ...result, faceImage, docImage }
    } else if (passportData.length >= 5 && passportData.length < 13) {
      docImage.Vis = passportData[1] !== '' && passportData[1] !== 'null' ? passportData[1] : null
      docImage.UV =
        passportData[2] !== '' && passportData[2] !== 'null' && config.useDocUVImage ? passportData[2] : null
      docImage.IR =
        passportData[3] !== '' && passportData[3] !== 'null' && config.useDocIRImage ? passportData[3] : null
      faceImage = passportData[4] ? passportData[4] : null
      result = { ...result, faceImage, docImage }
    } else {
      faceImage = passportData[2]
      result = { ...result, faceImage }
    }

    // If MRZ data not empty, it is passport type
    if (passportData[0] !== '' && passportData[0].startsWith('P')) {
      docType = MEDIATYPES.PASSPORT
      tracks = passportData[0].split('_@')
      mediaData.track1 = tracks[0]
      mediaData.track2 = tracks[1]
      mediaData.mediaType = MEDIATYPES.PASSPORT
    } else {
      docType = MEDIATYPES.IDCARD
      mediaData = null
    }

    result = { ...result, mediaData, docType }
    return result
  } catch {
    return null
  }
}

const parseScannertData = (data) => {
  let result = {
    mediaData: null,
    docType: null,
    faceImage: null,
    docImage: null
  }
  let parseData = null
  try {
    // document scaned
    parseData = parsePassportData(data)
    result.mediaData = parseData.mediaData
    result.faceImage = parseData.faceImage
    result.docImage = parseData.docImage
    result.docType = parseData.docType
  } catch {
    result = {
      mediaData: null,
      docType: null,
      faceImage: null,
      docImage: null
    }
  }
  return result
}

function usePassportScanner(passportScannedCallback, sbdModel, resetTimerFlag = true) {
  let removeTimer = null
  let processingTimer = null
  let mediaData = null
  let imageData = null
  let docType = null
  let passportDataReceived = false
  let passportRemoved = false
  const retryMessage = useRef('ScanDocs_RetrySubTitle')
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const anmRemovePassport = `${themeContext.AnimationPath}/${sbdModel}/RemovePassport.gif`
  //const [retryAttempts, setRetryAttempts] = useState(0)
  const retryAttempts = useRef(0)
  const [showPopupRemove, setShowPopupRemove] = useState(false)
  const [showPopupRetry, setShowPopupRetry] = useState(false)
  const [maxRetryReached, setMaxRetryReached] = useState(false)
  const [showPopupProcessing, setShowPopupProcessing] = useState(false)
  const passportReader = getDeviceManager().getDevice(deviceIds.PASSPORT_READER)
  const timeoutTakeDocs = config.timeoutTakeDocs * 1000
  const maxScanAttempts = config.scanDocRetryCount
  const isLandscape = useCheckLandscape()
  const accDef = {
    removePassport: {
      sequenceDef: {
        type: ACC_POPUPS.POPUP,
        sequence: [
          {
            id: 'removePassport',
            textId: 'TwoDynamicText',
            textParameters: [
              intl.formatMessage(messages.ScanDocs_RemoveDoc),
              intl.formatMessage(messages.ScanDocs_RemoveDoc_SubTitle)
            ]
          }
        ]
      }
    },
    retryMediaAccess: {
      sequenceDef: {
        type: ACC_POPUPS.POPUP,
        sequence: [
          {
            id: 'retryMediaAccess',
            textId: 'TwoDynamicText',
            textParameters: [
              intl.formatMessage(messages.Error_PassportInvalid),
              intl.formatMessage(messages.Error_MediaRetriesRemaining, {
                remains: maxScanAttempts - (retryAttempts.current + 1)
              })
            ]
          },
          { id: 'btnOK', text: intl.formatMessage(messages.buttonOk), buttonId: 'btnOK' }
        ]
      }
    },
    processingPassport: {
      sequenceDef: {
        type: ACC_POPUPS.POPUP,
        sequence: [
          {
            id: 'processingPassport',
            textId: 'TwoDynamicText',
            textParameters: [
              intl.formatMessage(messages.ScanDocs_ProcessingDoc),
              intl.formatMessage(messages.ScanDocs_ProcessingDoc_SubTitle)
            ]
          }
        ]
      }
    }
  }
  /* useEffect(() => {
    console.log('usePassportScanner: maxRetryReached changed.',  maxRetryReached)
    if (maxRetryReached) {
      setRetryMessage('ScanDocs_MaxSubTitle')
    }
  }, [maxRetryReached]) */
  function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  const passportReaderCallback = (event) => {
    if (resetTimerFlag) {
      // Reset timer
      getTimeoutMgr().resetTimer()
      appLog(
        TraceLevels.LOG_TRACE,
        '(usePassportScanner.js) passportReaderOnEvent() is called from client: ' + event.key
      )
    }
    /*
      should only contain device specific logic in the callback. common device callback logic should put in the useErrorAttemptHandle hook.
    */
    // deviceErrorHandler(event.key)
    let tracks = []
    switch (event.key) {
      case 'passportInserted':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportInserted')
        if (config.showDocProcessingPopup) {
          console.log('show processing passport popup...')
          processingTimer = setTimeout(() => {
            if (!showPopupProcessing) {
              setShowPopupProcessing(true)
              getAccessibilityManager().buildPopupGroup(true, accDef.processingPassport)
            }
            appLog(TraceLevels.LOG_EXT_TRACE, 'Event: passportInserted display processing passport popup')
          }, timeoutTakeDocs)
          appLog(
            TraceLevels.LOG_TRACE,
            'Event: passportInserted processing passport timer: ' + processingTimer + ' ' + timeoutTakeDocs
          )
        }
        break
      case 'passportDataError':
      case 'passportDamaged':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportDamaged')
        setShowPopupProcessing(false)
        logEvents(EventFlows.DocScan, EventFunctions.DocScanRetry, 'Document damaged/data error')
        handleRetryAttempts()
        break
      case 'passportRemoved':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportRemoved')
        setShowPopupProcessing(false)
        if (removeTimer) {
          clearTimeout(removeTimer)
          //removePassportRef.current.hide()
          setShowPopupRemove(false)
        }
        if (passportDataReceived) {
          console.log('mediaData: ', mediaData)
          console.log('imageData: ', imageData)
          passportScannedCallback(mediaData, imageData, docType)
        }
        passportRemoved = true
        break
      case 'passportReadInternal':
        //appLog(TraceLevels.LOG_TRACE, 'Event:  passportReadInternal ' + maskPCI(event.value, deviceIds.PASSPORT_READER))
        //console.log('passport return data::::', event.value)
        // Set passport data
        if (event.value !== '') {
          // parseData
          let data = parseScannertData(event.value)
          imageData = {
            faceImage: data.faceImage,
            docImage: data.docImage
          }
          mediaData = data.mediaData
          docType = data.docType

          if (imageData.faceImage) {
            logEvents(EventFlows.DocScan, EventFunctions.DocScanDG2, 'Yes')
          } else {
            logEvents(EventFlows.DocScan, EventFunctions.DocScanDG2, 'No')
          }
        } else {
          logEvents(EventFlows.DocScan, EventFunctions.DocScanRetry, 'No document scan data')
          handleRetryAttempts()
          appLog(TraceLevels.LOG_EXT_TRACE, 'Event: passportReadInternal scanned mediaData is empty, try again...')
        }
        /** Clear processing popup once data received */
        if (config.showDocProcessingPopup && processingTimer) {
          console.log('processing popup clear')
          clearTimeout(processingTimer)
          //removePassportRef.current.hide()
          setShowPopupProcessing(false)
        }
        /** Send MediaData to ETS: Received passportRemoved event before the MediaData has been collected **/
        if (passportRemoved) {
          console.log('mediaData: ', mediaData)
          passportScannedCallback(mediaData, imageData, docType)
        } else {
          console.log('Passport is not removed yet...')
          removeTimer = setTimeout(() => {
            if (!showPopupRemove) {
              //removePassportRef.current.show()
              setShowPopupRemove(true)
              getAccessibilityManager().buildPopupGroup(true, accDef.removePassport)
            }
            appLog(TraceLevels.LOG_EXT_TRACE, 'Event: passportReadInternal display remove passport animation popup')
          }, timeoutTakeDocs)
          appLog(
            TraceLevels.LOG_TRACE,
            'Event: passportReadInternal passport remove timer: ' + removeTimer + ' ' + timeoutTakeDocs
          )
        }
        passportDataReceived = true
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_TRACE, 'Event:  statusChange ' + event.value.toString())
        if (event.value[1]) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'callback: passport  reader is disconnected')
          goToLocalGenericError('Callbacks', ErrCodes.PASSPORT_ERROR, 'PassportReaderError', 'END_TXN')
        } else {
          console.log('callbacks: passport reader status:', event.value[0])
        }
        break
      default:
    }
  }
  const enable = () => {
    passportReader.OnDeviceEvent = passportReaderCallback
    passportReader.enable()
  }
  const disable = () => {
    passportReader.OnDeviceEvent = passportReaderOnEvent
    passportReader.disable()
    //removePassportRef.current.hide()
    setShowPopupRemove(false)
    if (removeTimer) {
      clearTimeout(removeTimer)
      removeTimer = null
    }
    //clear processing popup timer
    setShowPopupProcessing(false)
    if (config.showDocProcessingPopup && processingTimer) {
      clearTimeout(processingTimer)
      processingTimer = null
    }
  }
  // General error handling for the device callback. Always call after the main callback function
  // useErrorAttemptHandle(eventValue)

  const handleRetryAttempts = () => {
    setShowPopupRetry(true)
    if (retryAttempts.current + 1 === maxScanAttempts) {
      retryAttempts.current = 0
      setMaxRetryReached(true)
      retryMessage.current = 'ScanDocs_MaxSubTitle'
      logEvents(EventFlows.DocScan, EventFunctions.DocScanResult, 'Fail')
      //getSBDAppMan().doQuit(END_TXN_REASON.MEDIA_ACCESS_RETRY, 'Maximum media access retries exceeded')
    } else {
      retryAttempts.current += 1
      getAccessibilityManager().buildPopupGroup(true, accDef.retryMediaAccess)
    }
  }

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 500, height: 500 },
    { width: 300, height: 300 }
  ])

  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const popupContainer = {
    removePassport: (
      <Popup visible={showPopupRemove}>
        {/* <div id="removePassport" tabIndex="0">
          <span className={'page-title'}>
            <FormattedMessage {...messages.ScanDocs_RemoveDoc} />
          </span>
        </div>
        <div className="emb-ScanDocuments-bodyContainerPopup">
          <div className="emb-body-center">
            <DynamicImage imageName={anmRemovePassport} cssName={'animation'} width={420} height={420} />
          </div>
        </div> */}
        <PageSubContent width="100%">
          <Spacer height={'50px'} />
          <PageSubContent>
            <DynamicImage
              imageName={themeContext.ImageScanDocumentRemove}
              width={animationSize.width}
              height={animationSize.height}
            />
          </PageSubContent>
          <div id="removePassport" tabIndex="0">
            <PageSubContent flexFlow={'column'}>
              <PopupTitle
                fontSize={themeContext.Popup.titleFontSize[ratioKey]}
                fontWeight={themeContext.Popup.fontWeight}
              >
                {formatMessage(messages.ScanDocs_RemoveDoc)}
              </PopupTitle>
              <PopupSubTitle
                fontSize={themeContext.Popup.subTitleFontSize[ratioKey]}
                fontWeight={themeContext.Popup.fontWeight}
              >
                {formatMessage(messages.ScanDocs_RemoveDoc_SubTitle)}
              </PopupSubTitle>
            </PageSubContent>
          </div>
        </PageSubContent>
      </Popup>
    ),
    retryMediaAccess: (
      <Popup visible={showPopupRetry}>
        <PageSubContent width="100%">
          {themeContext.ImageScanDocument ? (
            <PageSubContent>
              <Animation
                imageName={`${themeContext.AnimationPath}/${sbdModel}/${themeContext.ImageScanDocument}`}
                // imageName={themeContext.ImageScanDocument}
                width={animationSize.width}
                height={animationSize.height}
              />
            </PageSubContent>
          ) : (
            <PageSubContent flexFlow={'row'} padding={'0'}>
              <PageSubContent padding={isLandscape ? '0 20px' : '0 5px'}>
                <DynamicImage
                  imageName={'scan-card.png'}
                  width={isLandscape ? 300 : 260}
                  height={isLandscape ? 215 : 185}
                />
                <PageSubContent width={isLandscape ? '300px' : '260px'} background={'grey'} padding={'0 10px'}>
                  <PageTitle minHeight={'60px'} fontSize={'16px'} color={'white'}>
                    {formatMessage(messages.ScanCard_Title)}
                  </PageTitle>
                </PageSubContent>
              </PageSubContent>
              <PageSubContent padding={isLandscape ? '0 20px' : '0 5px'}>
                <DynamicImage
                  imageName={'scan-passport.png'}
                  width={isLandscape ? 300 : 260}
                  height={isLandscape ? 215 : 185}
                />
                <PageSubContent width={isLandscape ? '300px' : '260px'} background={'grey'} padding={'0 10px'}>
                  <PageTitle minHeight={'60px'} fontSize={'16px'} color={'white'}>
                    {formatMessage(messages.ScanPassport_Title)}
                  </PageTitle>
                </PageSubContent>
              </PageSubContent>
            </PageSubContent>
          )}
          <div id="retryMediaAccess" tabIndex="0">
            <PageSubContent flexFlow={'column'}>
              <PopupTitle
                fontSize={themeContext.Popup.titleFontSize[ratioKey]}
                fontWeight={themeContext.Popup.fontWeight}
              >
                {formatMessage(messages.ScanDocs_RetryTitle)}
              </PopupTitle>
              <PopupSubTitle
                fontSize={themeContext.Popup.subTitleFontSize[ratioKey]}
                fontWeight={themeContext.Popup.fontWeight}
              >
                {formatMessage(messages[retryMessage.current])}
              </PopupSubTitle>
            </PageSubContent>
          </div>
          <PageSubContent flexFlow={'row'}>
            <IconButton
              id={'btnOK'}
              color={themeContext.ConfirmButton.color}
              bgColor={themeContext.ConfirmButton.bgColor}
              width={themeContext.ConfirmButton.width[ratioKey]}
              height={themeContext.ConfirmButton.height[ratioKey]}
              buttonPosition={themeContext.ConfirmButton.buttonPosition}
              iconLocation={themeContext.ConfirmButton.buttonPosition}
              //disable={props.disableAction}
              onClick={() => {
                setShowPopupRetry(false)
                if (maxRetryReached) {
                  getSBDAppMan().doQuit(END_TXN_REASON.MEDIA_ACCESS_RETRY, 'Maximum media access retries exceeded')
                }
              }}
              text={formatMessage(messages.buttonOk)}
              fontSize={themeContext.ConfirmButton.fontSize[ratioKey]}
              icon={themeContext.ConfirmButton.icon}
              iconColor={themeContext.ConfirmButton.iconColor}
              borderRadius={themeContext.ConfirmButton.borderRadius[ratioKey]}
              border={themeContext.ConfirmButton.border ? themeContext.ConfirmButton.border : ''}
            />
          </PageSubContent>
        </PageSubContent>
      </Popup>
    ),
    processingPassport: (
      <Popup visible={showPopupProcessing}>
        <PageSubContent width="100%">
          <Spacer height={'50px'} />
          <PageSubContent>
            <DynamicImage
              imageName={`${themeContext.AnimationPath}/${themeContext.AnimationPleaseWait}`}
              width={animationSize.width}
              height={animationSize.height}
            />
          </PageSubContent>
          <div id="processingPassport" tabIndex="0">
            <PageSubContent flexFlow={'column'}>
              <PopupTitle
                fontSize={themeContext.Popup.titleFontSize[ratioKey]}
                fontWeight={themeContext.Popup.fontWeight}
              >
                {formatMessage(messages.ScanDocs_ProcessingDoc)}
              </PopupTitle>
              <PopupSubTitle
                fontSize={themeContext.Popup.subTitleFontSize[ratioKey]}
                fontWeight={themeContext.Popup.fontWeight}
              >
                {formatMessage(messages.ScanDocs_ProcessingDoc_SubTitle)}
              </PopupSubTitle>
            </PageSubContent>
          </div>
        </PageSubContent>
      </Popup>
    )
  }

  return [enable, disable, passportReaderCallback, popupContainer]
}

export default usePassportScanner
