import { updateLocalData } from 'actions/localActions'
import { updateSessions } from 'actions/sessionActions'
import { Animation } from 'components'
import { PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { FALSE } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { BagtagPrinterOnEvent } from 'devices/callbacks'
import { deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan } from 'main'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { logInfo } from 'utils/Logger'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, goToLocalError, navigate, populateItineraryInfo } from 'utils/helper'
import { Footer } from '../footer'

const isQuitRequired = false
const isLangRequired = false

const PrintHeavytag = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const paxDocuments = useSelector((state) => state.responses.paxDocuments)
  const bagtagResources = useSelector((state) => state.responses.bagtagResources)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const bagtags = paxDocuments.slice()
  const SBDAppManager = getSBDAppMan()
  const aeaBagDrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const totalBagtag = bagtags.length
  const [enableConfirm, setEnableConfirm] = useState(false)
  const bagtagPrinter = getDeviceManager().getDevice(deviceIds.BAGTAG_PRINTER)
  const [currentBagtag, setCurrentBagtag] = useState(-1)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    setTimeout(() => {
      handleAccessibility()
    }, 200)
  }, [enableConfirm])

  const location = useLocation()
  const screenId = getScreenId(location.pathname)
  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined

    const accDef = {
      pathName: 'PrintHeavytag',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isLangRequired,
        isQuitRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: enableConfirm ? 'OneDynamicTextWithDownKey' : 'OneDynamicText',
            textParameters: [
              `${formatMessage(messages.PrintHeavytags)}, ${formatMessage(messages.PrintHeavytagsInstruction)}`
            ]
          }
        ]
      }
    }

    if (enableConfirm) {
      accDef.sequenceDef.sequence.push({
        id: 'confirmBtn',
        text: formatMessage(messages.buttonDone),
        buttonId: 'confirmBtn'
      })
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const bagtagPrinterCallback = (event) => {
    let err = {}
    logInfo('bagtagPrinterCallback ... event : ' + JSON.stringify(event))
    /* if (this.timeoutRef) {
      this.timeoutRef.getWrappedInstance().resetTimer()
    } */
    switch (event.key) {
      case 'pectabLoadingComplete':
        logInfo('totalBagtag: ' + totalBagtag)
        if (totalBagtag > 0) {
          logInfo('Print first bagtag.')
          setCurrentBagtag(1)
          bagtagPrinter.print(bagtags[0], config.timeoutPrintCmd)
        }
        break
      case 'bagtagPrinted':
        bagtagPrinter.offer(config.timeoutTakeBagtag)
        logEvents(EventFlows.BagDrop, EventFunctions.HeavyTagPrinted, 1)
        break
      case 'offer':
        // 103 - when bagtag not taken after timeout 30s
        if (event.value == 0 || event.value == 104 || event.value == 103) {
          /* if (currentBagtag < totalBagtag) {
            setCurrentBagtag(currentBagtag => {currentBagtag + 1})
            logInfo('Bagtags: printing ' + (currentBagtag + 1) + ' of ' + totalBagtag)
            bagtagPrinter.print(bagtags[currentBagtag], config.timeoutPrintCmd)
          } else {
            logInfo('Heavy tag: print completed')
            setEnableConfirm(true)
          } */
          logInfo('Heavy tag: print completed')
          setEnableConfirm(true)
        }
        break
      case 'bagtagPrintingComplete':
        break
      case 'bagtagFailed':
        logEvents(EventFlows.BagDrop, EventFunctions.HeavyTagPrinted, 'bagtagFailed')
        goToLocalError('PrintHeavyTag', ErrCodes.BAGTAG_PRINT_ERROR, 'BagDropDeviceError', 'END_TXN', null, 'error')
        break
      case 'pectabFailed':
        goToLocalError('PrintHeavyTag', ErrCodes.BAGTAG_PRINT_ERROR, 'BagDropDeviceError', 'END_TXN', null, 'error')
        break
      case 'pectabLoaded':
        break
      case 'commandTimeout':
        if (event.value === 'offer') {
          logInfo('Heavy tag: print completed')
          setEnableConfirm(true)
        }
      case 'statusChange':
        logInfo('Event:  statusChange ' + event.value.toString())
        if (event.value.length === 2) {
          if (event.value[1]) {
            // true means fatal error
            goToLocalError('PrintHeavyTag', ErrCodes.BAGTAG_PRINT_ERROR, 'BagDropDeviceError', 'OOS', null, 'error')
          }
        }
        break
      default:
    }
  }

  useEffect(() => {
    if (bagtagPrinter) {
      logInfo('set printer callback')
      bagtagPrinter.OnDeviceEvent = bagtagPrinterCallback
      console.log('call bagtagPrinter.loadPECTABArray')
      bagtagPrinter.loadPECTABArray(bagtagResources, FALSE)
    } else {
      logInfo('bagtag printer is not available')
    }

    setCurrentBagtag(0)
    return () => {
      if (bagtagPrinter) {
        bagtagPrinter.OnDeviceEvent = BagtagPrinterOnEvent
      }
    }
  }, [])

  // handle bottom right action buttons
  const handleActions = (e) => {
    let event = null
    logInfo('(PrintHeavytag.js): handleActions() ... ' + e.currentTarget.id)
    // TEST CODE
    switch (e.currentTarget.id) {
      case 'buttonPrint':
        event = {
          key: 'pectabLoadingComplete',
          value: 0
        }
        bagtagPrinterCallback(event)
        setTimeout(() => {
          event = {
            key: 'offer',
            value: 0
          }
          bagtagPrinterCallback(event)
        }, 1000)
        break
      case 'buttonFail':
        event = {
          key: 'bagtagFailed',
          value: 0
        }
        bagtagPrinterCallback(event)
        break
      case 'confirmBtn':
        dispatch(updateSessions('updateIsHeavytagRequired', true))
        dispatch(updateSessions('updateAttachHeavyRetryAttemptCounts', 0))
        dispatch(updateLocalData('updateBqCommand', ''))
        SBDAppManager.setAppFlow(2)
        if (config.isCUSSRequired) {
          logInfo('==> (PrintHeavytag.js) appFlow = 2 send SQ')
          aeaBagDrop.sendAEA('SQ', -10000)
        } else {
          navigate('bagProcessing', 3)
        }
        break
    }
  }

  const testData = [
    {
      //id: 'DESC',
      id: 'DESC-1',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'buttonPrint',
      text: 'Print',
      group: 0,
      handler: handleActions
    },
    {
      //id: 'DESC',
      id: 'DESC-2',
      text: 'Error Cases:',
      group: 1
    },
    {
      id: 'buttonFail',
      text: 'print error',
      group: 1,
      handler: handleActions
    }
  ]

  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const header = <PageHeader alignItems="center">{formatMessage(messages.PrintHeavytagsTitle)}</PageHeader>

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {formatMessage(messages.PrintHeavytags)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {formatMessage(messages.PrintHeavytagsInstruction)}
      </PageSubTitle>
    </>
  )

  const animationSize = useMedia(null, [420, 350, 420], 420)
  const animationPrintBagtag = isLandscape
    ? themeContext.AnimationLandscape.PrintBagtag
    : themeContext.AnimationPortrait.PrintBagtag
  const animationSection = (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${animationPrintBagtag}`}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationsize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
      cssName={'animation'}
    //isVideo={true}
    />
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={isQuitRequired}
        isBackRequired={false}
        isSkipRequired={false}
        isLangRequired={isLangRequired}
        isConfirmRequired={enableConfirm}
        confirmBtnText={formatMessage(messages.buttonDone)}
        confirmAction={handleActions}
        testData={testData}
      />
    </>
  )

  let contentWidth = {
    landscapeMode: '50%',
    portraitMode: '90%'
  }

  const { UIDisplay } = useUIBase(
    {
      topSection: textSection,
      bottomSection: animationSection,
      footer
    },
    {
      contentWidth: contentWidth,
      itineraryHeights: ['200px', '120px', '200px']
    }
  )

  return <>{UIDisplay}</>
}

export default PrintHeavytag
