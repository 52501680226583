import { updateError } from 'actions/commonActions'
import { updateLocalData } from 'actions/localActions'
import { DynamicImage, PageSubTitle, PageTitle } from 'components/styledComponents'
import { ErrCodes } from 'constants/Errors'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, playSound } from 'main'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { formatBagTagNumber, getScreenId, navigate, populateItineraryInfo } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { Footer } from '../footer'

const isQuitRequired = false
const ErrorMismatch = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const errorDetails = useSelector((state) => state.errorDetails)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const bagtag = useSelector((state) => state.localData.bagtag)
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    dispatch(updateLocalData('updateBagWeight', '0'))
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '>> (ErrorMismatch.js) componentDidMount() - this.props.appFlow = "' +
        appFlow +
        '" error: ' +
        JSON.stringify(errorDetails)
    )
    appLog(TraceLevels.LOG_EXT_TRACE, '==> CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
    dispatch(updateLocalData('updateErrorCodeList', errorDetails.errCode))
    getDeviceManager()
      .getAppManager()
      .sendApplicationLog(100, 'CDS_APPLOG,1420,Error: ' + errorDetails.errCode)
    return () => {
      dispatch(updateLocalData('updateBqCommand', ''))
      dispatch(updateError(null))
    }
  }, [])

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(ErrorMismatch.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()
    const errTransition = errorDetails.transition
    switch (e.currentTarget.id) {
      case 'confirmBtn':
        if (errTransition) {
          if (errTransition === 'PutBagOnBelt') {
            navigate(errTransition, 2)
          } else if (errTransition === 'END_TXN') {
            SBDAppManager.doQuit(errorDetails.errorCode, errorDetails.errorMsg, '')
          } else if (errTransition === 'OOS') {
            SBDAppManager.doQuit(errorDetails.errorCode, errorDetails.errorMsg, OOS)
          } else if (errTransition === 'Retry') {
            navigate('welcome', 0)
          } else {
            navigate(errTransition)
          }
        } else {
          SBDAppManager.doQuit(errorDetails.errorCode, errorDetails.errorMsg, '')
        }
        break
      default:
    }
  }

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  let heading = intl.formatMessage(messages.ErrorMismatch1_hdr) ////TAG_NOT_FOUND_IN_PNR
  let instMsg = intl.formatMessage(messages.ErrorMismatch1_inst)
  let image = themeContext.ImageError.mismatch1
  let scannedBagtagMsg =
    bagtag && bagtag.length > 10
      ? intl.formatMessage(messages.ScannedBagTag2, { bagtag: formatBagTagNumber(bagtag) })
      : intl.formatMessage(messages.ScannedBagTag, { bagtag: formatBagTagNumber(bagtag) })

  if (errorDetails.errCode === ErrCodes.MULTIPLE_TAGS) {
    heading = intl.formatMessage(messages.ErrorMismatch2_hdr)
    instMsg = intl.formatMessage(messages.ErrorMismatch2_inst)
    image = themeContext.ImageError.mismatch2
  } else if (errorDetails.errCode === ErrCodes.MULTIPLE_BAGS) {
    heading = intl.formatMessage(messages.ErrorMismatch3_hdr)
    instMsg = intl.formatMessage(messages.ErrorMismatch3_inst)
    image = themeContext.ImageError.mismatch3
  }

  const animationSection = (
    <DynamicImage imageName={image} cssName={'animation'} width={animationSize.width} height={animationSize.height} />
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {heading}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {instMsg} {themeContext.showMismatchBagtag ? { scannedBagtagMsg } : ''}
      </PageSubTitle>
    </>
  )
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale, errorDetails])

  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : { displayName: '' }
    const errorMsg = themeContext.showMismatchBagtag ? [heading, instMsg, scannedBagtagMsg] : [heading, instMsg]
    const screenId = getScreenId(location.pathname)
    const accDef = {
      pathName: 'ErrorMismatch',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicTextWithDownKey',
            textParameters: [errorMsg.join('. ')]
          },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }

  const quitBtnText = () => {
    const errTransition = errorDetails.transition

    if (errTransition) {
      if (errTransition === 'PutBagOnBelt') {
        return intl.formatMessage(messages.buttonRetry)
      } else if (errTransition === 'END_TXN') {
        return intl.formatMessage(messages.buttonOk)
      } else if (errTransition === 'OOS') {
        return intl.formatMessage(messages.buttonOk)
      } else {
        return intl.formatMessage(messages.buttonOk)
      }
    } else {
      return intl.formatMessage(messages.buttonOk)
    }
  }

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={handleActions}
      confirmBtnText={quitBtnText()}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ErrorMismatch
