import React, { useRef, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { CustomScrollBox } from 'components'
import { ThemeContext } from 'styled-components'
import useMedia from 'hooks/useMedia'
import './EMBTable.scss'
//keep this code here for data format
/*columns = [
  {
    header: 'column1',
    width: '200px'
    textAlign: 'flex-start'
  },
  {
    header: 'column1',
    width: '200px'
  }
],
  rows = [
  {
    data: ['a', 'b']
  },
  {
    data: ['a', 'b']
  }
]*/

const EMBTable = (props) => {
  const {
    width,
    height,
    tableCss,
    rowCss,
    cellCss,
    headerRowCss,
    headerCellCss,
    rowSelectedCSS,
    columns,
    rows,
    scrollbarGap,
  } = props

  const popupRefScroll = useRef(null)
  const isRowSelectable = props.isRowSelectable || false
  const headerHeight = props.headerHeight || 30
  const rowHeight = props.rowHeight || 25
  const headFontSize =props.headFontSize || 25
  const rowFontSize = props.rowFontSize || 20
  const maxDisplayRows = props.maxDisplayRows || 6
  const scrollbarWidth = props.scrollbarWidth || 45
  const showHeader = props.showHeader | false 
  const generateRows = (inputRows) => {
    let newRows = []
    let cells = []
    let columnStyle = null
    let cssRow = rowCss ? rowCss : 'emb-table-row'
    let cssRowSelected = rowSelectedCSS ? rowSelectedCSS : 'emb-table-selected-row'
    let cssCell = cellCss ? cellCss : 'emb-table-cell'
    inputRows.forEach((row, rowIndex) => {
      cells = []
      row.data.forEach((cell, cellIndex) => {
        columnStyle = {
          width: columns[cellIndex].width,
          height: rowHeight + 'px',
          fontSize: rowFontSize + 'px',
          justifyContent: columns[cellIndex].textAlign ? columns[cellIndex].textAlign : 'center',
          borderBottom: themeContext.PassengerSelection.rowBorderBottom
        }
        cells.push(
          <div key={'cell_' + rowIndex + '_' + cellIndex} className={cssCell} style={columnStyle}>
            {cell}
          </div>
        )
      })

      let realRowCss = row.selected ? cssRowSelected : cssRow
      newRows.push(
        <div
          key={'row_' + rowIndex}
          id={cssRow + '-' + rowIndex}
          className={realRowCss}
          tabIndex={0}
          onClick={() => {
            if (isRowSelectable) {
              let newRows = []
              rows.map((row, index) => {
                if (index === rowIndex) {
                  row.selected = true
                } else {
                  row.selected = false
                }
                newRows.push(row)
              })
            }
          }}
        >
          {cells}
        </div>
      )
    })
    return newRows
  }
  const [header, setHeader] = useState('')
  const [tableWidth, setTableWidth] = useState(width)
  const themeContext = useContext(ThemeContext)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    let cssHeaderCell = headerCellCss ? headerCellCss : 'emb-table-header-cell'
    let headerRow = []
    let totalWidth = 0
    columns.forEach((column, index) => {
      totalWidth += parseInt(column.width)
      let columnStyle = {
        width: column.width,
        height: headerHeight + 'px',
        fontSize: headFontSize + 'px',
      }
      headerRow.push(
        <div key={'hColumn' + index} className={cssHeaderCell} style={columnStyle}>
          {column.header}
        </div>
      )
    })
    headerRow.push(<div key={'scrollbarGap'} style={{ width: scrollbarGap + 'px' }}></div>)
    headerRow.push(<div key={'scrollbarWidth'} style={{ width: scrollbarWidth + 'px' }}></div>)
    totalWidth += scrollbarGap + scrollbarWidth
    setHeader(headerRow)
    if (!width) {
      setTableWidth(totalWidth)
    }
    //setRenderRows(generateRows(rows))
  }, [])
  return (
    <div
      className={tableCss ? tableCss : 'emb-table'}
      style={{
        height: height
          ? height
          : showHeader
          ? rowHeight * maxDisplayRows + headerHeight + 'px'
          : rowHeight * maxDisplayRows,
        width: tableWidth + 'px',
        padding: themeContext.PassengerSelection.EMBTable.padding[ratioKey],
        borderRadius: themeContext.PassengerSelection.EMBTable.borderRadius[ratioKey],
        border: `2px solid ${themeContext.PassengerSelection.EMBTable.borderColor}`
      }}
    >
      {showHeader ? <div className={headerRowCss ? headerRowCss : 'emb-table-header-row'}>{header}</div> : ''}
      <CustomScrollBox
        key="scrollTable"
        id="scrollTable"
        height={themeContext.PassengerSelection.scrollAreaHeight[ratioKey]} // height of scroll area (offset)
        buttonDownIcon="scroll-arrow-down.svg"
        buttonUpIcon="scroll-arrow-up.svg"
        iconSize={themeContext.PassengerSelection.scrollIconSize[ratioKey]}
        scrollBarWidth={themeContext.PassengerSelection.scrollBarWidth[ratioKey]}
      >
        {generateRows(rows)}
      </CustomScrollBox>
    </div>
  )
}

EMBTable.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  headFontSize: PropTypes.number,
  rowFontSize: PropTypes.number,
  maxDisplayRows: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      width: PropTypes.string,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      data: PropTypes.array,
    })
  ),
  scrollbarWidth: PropTypes.number,
  tableCss: PropTypes.string,
  rowCss: PropTypes.string,
  cellCss: PropTypes.string,
  isRowSelectable: PropTypes.bool,
  scrollbarGap: PropTypes.number,
  showHeader: PropTypes.bool,
}


export default EMBTable
