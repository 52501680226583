import Listener from './listener'
import { TraceLevels } from 'constants/Constants'
import { endsWith } from 'utils/helper'

/** Baggage Scale listener.
 *  @extends {Listener}
 */
export default class BaggageScaleListener extends Listener {
  /**
   *  Default barcodeReadInternal callback.
   *  @param {string} readData read scale data
   */
  baggageReadInternal(readData) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.baggageReadInternal() called: ')
    try {
      let barcodeStrip =
        readData && readData.length > 0 && endsWith(readData, '\r')
          ? readData.substring(0, readData.length - 1)
          : readData
      this.setEvent('baggageReadInternal', barcodeStrip)
    } catch (err) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.baggageReadInternal() err: ' + err)
    }
  }

  /**
   *  Default baggageAbsent callback.
   */
  baggageAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.baggageAbsentMethod() called')
    this.setEvent('baggageAbsent', null)
  }

  /**
   *  Default baggagePresent callback.
   */
  baggagePresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.baggagePresentMethod() called')
    this.setEvent('baggagePresent', null)
  }

  /**
   *  Default barcodeReadInternal callback.
   *  @param {string} data read barcode data
   */
  barcodeRead(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.barcodeReadMethod() called')
    this.setEvent('barcodeRead', data)
  }

  /**
   *  Default barcodeRemoved callback.
   */
  barcodeRemoved() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.barcodeRemovedMethod() called')
    this.setEvent('barcodeRemoved', null)
  }

  /**
   *  Default setWeightUnit callback.
   */
  setWeightUnit() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.setWeightUnitMethod() called')
    this.setEvent('setWeightUnit', null)
  }

  /**
   *  Default setWeightUnit callback.
   */
  getMaxWeight(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'BaggageScaleListener.getMaxWeightMethod() called')
    this.setEvent('getMaxWeight', data)
  }
}
