import Listener from './listener'
import { TraceLevels, FingerPrintScannerErrors } from 'constants/Constants'
//import {getKeyByValue} from 'utils/helper'

/** Fingerprint Scanner listener.
 *  @extends {Listener}
 */
export default class FingerPrintScannerListener extends Listener {
  /**
   *  Default dataAvailable callback.
   *  @param {String[]} data wsq fingerprint image, score, image height, image width
   *  @example /6D/ogARAP8F3AZAAl3kBElaAv5u/6QA…,
   *  <Score><FingerprintImage><Height>1500</Height><Width>1600</Width>
   *  <Date>2017-10-13</Date><Time>16:14:32</Time><Duration>245</Duration><IsImageConcatenated>False</IsImageConcatenated>
   *  <IsCapturePlatenDirty>True</IsCapturePlatenDirty></FingerprintImage><IndividualFingerDetail><FingerPositionCode>7</FingerPositionCode>
   *  <IsMissingOrUnavailable>False</IsMissingOrUnavailable><FingerprintSegmentDetail><NFIQ>5</NFIQ><VendorScore>0</VendorScore>
   *  <X>1316</X><Y>520</Y><Height>397</Height><Width>260</Width></FingerprintSegmentDetail></IndividualFingerDetail>
   *  <IndividualFingerDetail><FingerPositionCode>8</FingerPositionCode><IsMissingOrUnavailable>False</IsMissingOrUnavailable>
   *  <FingerprintSegmentDetail><NFIQ>5</NFIQ><VendorScore>0</VendorScore><X>918</X><Y>260</Y><Height>366</Height><Width>276</Width>
   *  </FingerprintSegmentDetail></IndividualFingerDetail><IndividualFingerDetail><FingerPositionCode>9</FingerPositionCode>
   *  <IsMissingOrUnavailable>False</IsMissingOrUnavailable><FingerprintSegmentDetail><NFIQ>5</NFIQ><VendorScore>0</VendorScore>
   *  <X>520</X><Y>384</Y><Height>351</Height><Width>288</Width></FingerprintSegmentDetail></IndividualFingerDetail>
   *  <IndividualFingerDetail><FingerPositionCode>10</FingerPositionCode><IsMissingOrUnavailable>False</IsMissingOrUnavailable>
   *  <FingerprintSegmentDetail><NFIQ>5</NFIQ><VendorScore>0</VendorScore><X>31</X><Y>751</Y><Height>382</Height><Width>304</Width>
   *  </FingerprintSegmentDetail></IndividualFingerDetail></Score>,
   *  1600, 1500
   */
  dataAvailable(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.dataAvailable() called')
    this.setEvent('dataAvailable', data)
  }
  /**
   *  Default mediaPresent callback.
   */
  mediaPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.mediaPresent() called')
    this.setEvent('mediaPresent', null)
  }
  /**
   *  Default mediaAbsent callback.
   */
  mediaAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.mediaAbsent() called')
    this.setEvent('mediaAbsent', null)
  }
  /**
   *  Default processing callback.
   */
  processing() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.processing() called')
    this.setEvent('processing', null)
  }

  /**
   *  Default dataError callback.
   *  @param {number} status
   *    status values:
         <ul><li>31 DATA_ERROR_FINGER_DO_NOT_FIT</li>
         <li>32 DATA_ERROR_NO_FINGERS_FOUND</li>
         <li>33 DATA_ERROR_WRONG_HAND</li>
         <li>34 DATA_ERROR_MISSING_FINGERS</li>
         <li>35 DATA_ERROR_NOTHING</li>
         <li>36 DATA_ERROR_UNIQNESS</li>
         <li>37 DATA_ERROR_SEQUENCE</li>
         <li>38 DATA_ERROR_QUALITY_CHECK</li>
         <li>39 DATA_ERROR_ROLL_WIDTH_RATIO</li>
         <li>40 DATA_ERROR_DISPLAY_GENERATION</li>
         <li>41 DATA_ERROR_IMAGE_GENERATION</li>
         <li>42 DATA_ERROR_API_CORE</li>
         <li>43 DATA_ERROR_API_PLUGIN</li>
         <li>44 DATA_ERROR_ERROR_PROCESSING</li>
         <li>45 DATA_ERROR_ERROR_TIMEOUT</li>
         <li>46 DATA_ERROR_ERROR_UNKNOWN</li></ul>
 */
  dataError(status) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.dataError() called: ' + status)
    let errCode = FingerPrintScannerErrors.DATA_ERROR_ERROR_UNKNOWN
    try {
      errCode = parseInt(status, 10)
    } catch (err) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.dataError() error parsing: ' + status)
    }
    /*
    let desc = getKeyByValue(FingerPrintScannerErrors, 44)
    try {
      desc = getKeyByValue(FingerPrintScannerErrors, errCode)
    } catch (err2) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.dataError() error converting to key: ' + status)
    }
    this.setEvent('dataError', [status, desc])
    */
    this.setEvent('dataError', errCode)
  }

  /**
   *  Default setup callback.
   *  @param {number} code return code from setup
   */
  setup(code) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.setup() called')
    this.setEvent('setup', code)
  }
  /**
   *  Default getVersion callback.
   *  @param {String} data
   */
  getVersion(data) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.getVersion() called')
    this.setEvent('getVersion', data)
  }

  /**
   *  Default show callback.
   *  @param {number} rc return code
   */
  show(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.show() called')
    this.setEvent('show', rc)
  }

  /**
   *  Default hide callback.
   *  @param {number} rc return code
   */
  hide(rc) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'FingerPrintScannerListener.hide() called')
    this.setEvent('hide', rc)
  }

  /**
   *  to return result of the EES kit assessment
   *  @param {String} result return code
   * @example
   * The format of the string is the below in case there are no exceptions
   * <USKResult>
   *  <SlapQualityScore></SlapQualityScore>
   *  <SlapQualityDecision>SLAP_CHECK_OK</SlapQualityDecision >
   * </USKResult>
   * In case there is an exception with the USK kit, the format is the below
   * <USKResult>
   *  <USKException>LicenseException</USKException>
   * </USKResult>
   */
  eesFingerprintSlapQualityAssessment(result) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'eesFingerprintSlapQualityAssessment result: ' + result)
    this.setEvent('eesFingerprintSlapQualityAssessment', result)
  }

  indexFingerPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'indexFingerPresent called')
    this.setEvent('indexFingerPresent', null)
  }

  indexFingerPressHarder() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'indexFingerPressHarder called')
    this.setEvent('indexFingerPressHarder', null)
  }

  indexFingerAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'indexFingerAbsent called')
    this.setEvent('indexFingerAbsent', null)
  }

  middleFingerPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'middleFingerPresent called')
    this.setEvent('middleFingerPresent', null)
  }

  middleFingerPressHarder() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'middleFingerPressHarder called')
    this.setEvent('middleFingerPressHarder', null)
  }

  middleFingerAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'middleFingerAbsent called')
    this.setEvent('middleFingerAbsent', null)
  }

  ringFingerPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ringFingerPresent called')
    this.setEvent('ringFingerPresent', null)
  }

  ringFingerPressHarder() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ringFingerPressHarder called')
    this.setEvent('ringFingerPressHarder', null)
  }

  ringFingerAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'ringFingerAbsent called')
    this.setEvent('ringFingerAbsent', null)
  }

  littleFingerPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'littleFingerPresent called')
    this.setEvent('littleFingerPresent', null)
  }

  littleFingerPressHarder() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'littleFingerPressHarder called')
    this.setEvent('littleFingerPressHarder', null)
  }
  littleFingerAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'littleFingerAbsent called')
    this.setEvent('littleFingerAbsent', null)
  }

  leftThumbPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'leftThumbPresent called')
    this.setEvent('leftThumbPresent', null)
  }

  leftThumbAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'leftThumbAbsent called')
    this.setEvent('leftThumbAbsent', null)
  }

  rightThumbPresent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'rightThumbPresent called')
    this.setEvent('rightThumbPresent', null)
  }

  rightThumbAbsent() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'rightThumbAbsent called')
    this.setEvent('rightThumbAbsent', null)
  }

  leftThumbPressHarder() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'leftThumbPressHarder called')
    this.setEvent('leftThumbPressHarder', null)
  }

  rightThumbPressHarder() {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'rightThumbPressHarder called')
    this.setEvent('rightThumbPressHarder', null)
  }
}
