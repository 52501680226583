import { PaxValidationActions } from 'constants/Constants'

export function updatePaxValidationData(state = {}, action) {
  let data = Object.assign({}, state)
  switch (action.type) {
    case PaxValidationActions.INITIAL_PAX_DATA:
      return {
        scanDocumentType: null,
        paxFaceImage: null,
        paxDocImage: null,
        paxPhotoStatus: false,
        currentPassenger: null,
        docType: null,
        docRetryStatus: false,
        isAccuantImage: null,
        photoMatchDetail: {
          captureImage: null,
          matchScore: null
        }
      }
    case PaxValidationActions.UPDATE_DOCUMENT_IMG:
      data.paxDocImage = action.paxDocImage
      break
    case PaxValidationActions.UPDATE_DOC_TYPE:
      data.docType = action.docType
      break
    case PaxValidationActions.UPDATE_PHOTO_MATCH_DETAIL:
      data.photoMatchDetail = action.photoMatchDetail
      break
    case PaxValidationActions.UPDATE_FACE_IMG:
      data.paxFaceImage = action.paxFaceImage
      break
    case PaxValidationActions.UPDATE_PAX_PHOTO_STATUS:
      data.paxPhotoStatus = action.paxPhotoStatus
      break
    case PaxValidationActions.UPDATE_PAX_FACE_IMG:
      data.paxFaceImage = action.paxFaceImage
      break
    case PaxValidationActions.UPDATE_CURRENT_PASSENGER:
      data.currentPassenger = action.currentPassenger
      break
    case PaxValidationActions.UPDATE_DOC_RETRY_STATUS:
      data.docRetryStatus = action.docRetryStatus
      break
    case PaxValidationActions.UPDATE_SCAN_DOCUMENT_TYPE:
      data.scanDocumentType = action.scanDocumentType
      break
    case PaxValidationActions.UPDATE_IS_ACCUANT_IMAGE:
      data.isAccuantImage = action.isAccuantImage
      break
    default:
      return state
  }
  return data
}
