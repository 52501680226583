import { APPLICATION_MANAGER, LocalActions } from 'constants/Constants'

export function updateAppData(key, value) {
  console.log('updateAppData:' + key + ': value', value)
  return {
    type: APPLICATION_MANAGER,
    key: key,
    value: value,
  }
}

export function updateDeviceHelp(deviceId, value, comp = null) {
  return {
    type: LocalActions.DEVICE_HELP,
    key: deviceId,
    comp: comp,
    value: value,
  }
}

export function updateManualScanMode(enabled) {
  return {
    type: LocalActions.MANUAL_SCAN_MODE,
    manualScanMode: enabled
  }
}