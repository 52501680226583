/**
 * alternate Store for big objects
 */
import { TraceLevels } from 'embross-device-manager'
import { appLog } from 'utils/Logger'

export default class AltStore {
  constructor() {
    this.printStream = null
    this.emrtd = null
    this.dg2Photo = null
    this.photos = null
  }
  //PRINT STREAM
  setPrintStream(printStr) {
    this.printStream = printStr
  }

  getPrintStream() {
    return this.printStream
  }

  isPrintStreamProvided() {
    return this.printStream === null ? false : true
  }
  // DG2 PHOTO
  addDG2Photo(ix, photo) {
    if (this.dg2Photo === null) {
      this.dg2Photo = new Array(config.maxPassengers)
    }
    this.dg2Photo[ix] = photo
  }
  getDG2Photo(ix) {
    if (this.isDG2Photo(ix)) {
      return this.dg2Photo[ix]
    } else {
      return ''
    }
  }
  isDG2Photo(ix) {
    if (this.dg2Photo === null) {
      return false
    }
    if (ix < this.dg2Photo.length && this.dg2Photo[ix]) {
      return true
    } else {
      return false
    }
  }
  clearDG2Photos() {
    this.dg2Photo = null
  }
  // PHOTOS
  addPhotos(ix, visPhoto, irPhoto) {
    if (this.photos === null) {
      this.photos = new Array(config.maxPassengers)
    }
    this.photos[ix] = { visPhoto: visPhoto, irPhoto: irPhoto }
  }
  getVisPhoto(ix) {
    if (this.isVisPhoto(ix)) {
      return this.photos[ix].visPhoto
    } else {
      return ''
    }
  }
  isVisPhoto(ix) {
    if (this.photos === null) {
      return false
    }
    if (
      ix < this.photos.length &&
      this.photos[ix] !== undefined &&
      this.photos[ix].visPhoto !== undefined &&
      this.photos[ix].visPhoto !== '' &&
      this.photos[ix].visPhoto !== null
    ) {
      return true
    } else {
      return false
    }
  }
  clearPhotos() {
    this.photos = null
  }

  // eMRTD data
  addEMRTD(ix, emrtd) {
    if (this.emrtd === null) {
      this.emrtd = new Array(config.maxPassengers)
    }
    this.emrtd[ix] = emrtd
  }
  getEMRTD(ix) {
    if (this.emrtd === null) {
      return null
    }
    if (ix < this.emrtd.length && this.emrtd[ix] !== undefined) {
      return this.emrtd[ix]
    } else {
      return null
    }
  }
  clearEMRTD() {
    this.emrtd = null
  }

  clearAll() {
    this.clearPhotos()
    this.clearDG2Photos()
    this.clearEMRTD()
    this.printStream = null
  }
}
