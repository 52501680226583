/**
 * ETS Request and Response - Generic Command
 *
 * {

    "sessionInfo":{
        "etsSessionID":"testKiosk1495039487013",
        "emhaSessionID":"testKiosk14950394870131495039488056"
    },
    "jsonExtend":null,
    "carrierCode":null,
    "currentClientTime":0,
    "asyncRequested":false,
    "requestName":"addEmailData",
    "jsonMessage":"{\"contactEmail\":\"test@gmail.com\"}",
    "transitionToEcho":null

}
 */

import { store, history, getSummaryStore } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { ServiceNames, TransitionCodes, FunctionCodes, APISTransitions, PRINT_DOC_TYPE } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData } from 'actions/localActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { identifyService } from 'actions/etsTransactions/identifyService'
import { getBagDetails } from 'actions/etsTransactions/getBagDetails'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { isNotEmpty, navigate } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { replacer } from 'utils/helper'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { updateApis } from 'actions/etsTransactions/updateApis'

function buildGenericCommand(reqName, jsonMsg, transEcho) {
  if (jsonMsg) {
    return Object.assign(
      {},
      {
        sessionInfo: {
          etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
          emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
        },
        jsonExtend: null,
        carrierCode: null,
        currentClientTime: 0,
        asyncRequested: false,
        requestName: reqName,
        jsonMessage: JSON.stringify(jsonMsg),
        transitionToEcho: transEcho
      }
    )
  } else {
    return Object.assign(
      {},
      {
        sessionInfo: {
          etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
          emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
        },
        jsonExtend: null,
        carrierCode: null,
        currentClientTime: 0,
        asyncRequested: false,
        requestName: reqName,
        transitionToEcho: transEcho
      }
    )
  }
}

export function genericCommand(reqName, msgObj, transEcho) {
  const summaryStore = getSummaryStore()
  summaryStore.CurrentPax = null
  let url = ''
  appLog(TraceLevels.LOG_EXT_TRACE, 'genericCommand() request begins ... ')
  let request = buildGenericCommand(reqName, msgObj, transEcho)
  return (dispatch) => {
    appLog(TraceLevels.LOG_EXT_TRACE, 'genericCommand() dispatch request ... ' + JSON.stringify(request, replacer))
    navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitNextStep' } })
    dispatch(updateSessions('updateETSRequest', true))
    return fetchWithTimeout('genericCommand/', request)
      .then((json) => {
        appLog(TraceLevels.LOG_EXT_TRACE, 'genericCommand() receive response ... ' + JSON.stringify(json, replacer))
        dispatch(updateSessions('updateETSRequest', false))
        if (store.getState().sessions.OOS) {
          return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
        }
        if (isNotEmpty(json.etsResponse)) {
          //override success
          if (reqName === 'overrideStatus' && msgObj.bagTagData !== undefined) {
            summaryStore.updateTotaloverrides()
            summaryStore.updateBagData(msgObj.barcodeData, 'agentOverride', true)
          }

          // update service name and transition in store
          if (store.getState().sessions.currentServiceName !== json.etsResponse.currentServiceName) {
            dispatch(updateSessions('updateServiceName', json.etsResponse.currentServiceName))
          }
          dispatch(updateSessions('updateTransition', json.transition))
          dispatch(updateSessions('updateAgentOverrideError', null))
          // dispatch based on transition code
          switch (json.transition) {
            case TransitionCodes.HEAVY_TAG:
              dispatch(getPrintStream(PRINT_DOC_TYPE.HEAVY_BAG_TAG))
              break
            case TransitionCodes.PAX_STATUS_INFO:
              navigate('VerifyDocuments', 21)
              break
            case TransitionCodes.SBD_PROCESS:
              if (store.getState().localData.baggageLoadedStatus) {
                navigate('BagProcessing', 2)
              } else {
                navigate('PutBagOnBelt', 2)
              }
              break
            case 'NONE':
              dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.CUSTOM_4))
              break
            case TransitionCodes.GET_BAGGAGE_DETAILS:
              dispatch(getBagDetails())
              break
            case TransitionCodes.VALIDATE_AGENT:
              history.push('AgentScan')
              break
            case TransitionCodes.ADD_APIS_V_2:
              appLog(TraceLevels.LOG_EXT_TRACE, 'genericCommand() receive response to start passenger validation. ')
              //TODO: do we need something like validation start call to ETS ?
              dispatch(updateApis())
              break
            case TransitionCodes.PAX_VALIDATION:
              appLog(TraceLevels.LOG_EXT_TRACE, '(genericCommand.js) url: ScanDocuments')
              dispatch(updatePaxValidationData('initialPaxData'))
              const currentPax = store
                .getState()
                .localData.validatePaxResult.find((pax) => pax.passportScanned !== true)
              dispatch(updatePaxValidationData('updateCurrentPassenger', currentPax))
              history.push('ScanDocuments')
              break
            case TransitionCodes.PASSENGER_SELECTION:
              history.push('passengerSelection')
              break
            default:
              appLog(TraceLevels.LOG_EXT_TRACE, 'genericCommand() receive response, call for transitions.')
              dispatch(transitions(json.transition, json.etsResponse))
          }
        } else if (json.error) {
          goToFetchGenericError('genericCommand', json, dispatch)
        }
      })
      .catch((err) => {
        handleFetchErrors(err, 'genericCommand()', 'error')
      })
  }
}
