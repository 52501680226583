/**
 * ETS Request and Response - checkTravelDoc
 */

import { store, history, getAcuantClient, getSummaryStore } from 'main'
import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { TransitionCodes, checkTravelDocAction, PASSENGER_VALIDATION_STATUS, AcuantStatus } from 'constants/Constants'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { appLog, logEvent } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'
import { isNotEmpty, replacer, getNextPassenger, goToLocalGenericError, navigate } from 'utils/helper'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { ErrCodes } from 'constants/Errors'
import { logEvents, sessionStart, EventFlows, EventFunctions } from 'utils/appEventLogger'
import useFaceService from 'hooks/useFaceService'
import { updateCustomData } from 'custom/actions/customActions'

function buildRequest(mediaData, apisData, action, allowOpposite) {
  // const jsonExtend = { allowOpposite: allowOpposite }
  return Object.assign(
    {},
    {
      sessionInfo: {
        etsSessionID: store.getState().sessions.sessionInfo.etsSessionID,
        emhaSessionID: store.getState().sessions.sessionInfo.emhaSessionID
      },
      currentClientTime: 0,
      asyncRequested: false,
      paxOrdinal: store.getState().paxValidation.currentPassenger.ordinal,
      forDomesticAPIS: false,
      action: action,
      // action: 'MODIFY',
      jsonExtend: null,
      mediaTrackData: mediaData,
      apisData: apisData ? { documents: [apisData] } : null,
      checkinContractReturn: true
    }
  )
}

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export function checkTravelDoc(mediaData, apisData, action, pleaseWaitRequire = true, allowOpposite) {
  const acuantClient = getAcuantClient()
  const summaryStore = getSummaryStore()
  const paxFaceImage = store.getState().paxValidation.paxFaceImage
  const retryCount = store.getState().localData.errorDeviceAttempts
  let request = buildRequest(mediaData, apisData, action, allowOpposite)
  let currentPassenger = null
  let validateResult = store.getState().localData.validatePaxResult

  return (dispatch) => {
    if (paxFaceImage === 'null' || !paxFaceImage) {
      if (allowOpposite) {
        history.push('ScanOppositeDoc')
      } else {
        currentPassenger = store.getState().paxValidation.currentPassenger
        logEvents(EventFlows.DocCheck, EventFunctions.DocCheckError, 'Face image was not found in document')
        if (retryCount + 1 === config.scanDocRetryCount) {
          // max retry hit, go to agent
          logEvents(EventFlows.DocCheck, EventFunctions.DocCheckResult, 'Fail')
          logEvent('DocCheckScan,reach max retry')
          let newValidateResults = []
          let updatedItem = {}
          validateResult.forEach((item) => {
            if (item.ordinal === currentPassenger.ordinal) {
              updatedItem = item
              updatedItem.passportScanned = false
              updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
              updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
              updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
              updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED

              newValidateResults.push(updatedItem)
            } else {
              newValidateResults.push(item)
            }
          })

          const updatePaxStatus = {
            ordinal: currentPassenger.ordinal,
            docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
          }

          summaryStore.endDocScan(true)
          dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
          dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
          dispatch(updatePaxValidationData('initialPaxData'))
          dispatch(updateLocalData('updateDeviceAttempts', 0))
        } else {
          appLog(TraceLevels.LOG_TRACE, '(checkTravelDoc.js) Face image was not found in document. Go to agent.')
          dispatch(updatePaxValidationData('updateDocRetryStauts', true))
          dispatch(updateLocalData('updateDeviceAttempts', retryCount + 1))
          goToLocalGenericError(
            'checkTravelDoc',
            ErrCodes.ERR_PASSPORT_INVALID_RETRY,
            'Error_PassportRetry',
            'ScanDocuments',
            null,
            {
              log: false
            }
          )
        }
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'checkTravelDoc() dispatch request ... ' + JSON.stringify(request, replacer))
      if (pleaseWaitRequire) {
        history.push({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitDocCheck' } })
      }
      return fetchWithTimeout('checkTravelDoc/', request)
        .then((json) => {
          appLog(TraceLevels.LOG_EXT_TRACE, 'checkTravelDoc() receive response ... ' + JSON.stringify(json, replacer))
          if (store.getState().sessions.OOS) {
            return dispatch(transitions(TransitionCodes.OOS_ERROR, null))
          }
          if (isNotEmpty(json.etsResponse)) {
            logEvents(EventFlows.DocCheck, EventFunctions.DocCheckResult, 'Success')
            if (store.getState().paxValidation.currentPassenger) {
              currentPassenger = store.getState().paxValidation.currentPassenger
            } else {
              let updatedcurrentPax = {}
              const etsCurrentPax = json.etsResponse.itineraryInfo.passengers[0]
              validateResult.forEach((item) => {
                if (item.ordinal === etsCurrentPax.ordinal) {
                  updatedcurrentPax = item
                }
              })
              currentPassenger = updatedcurrentPax
              dispatch(updatePaxValidationData('updateCurrentPassenger', currentPassenger))
            }

            if (json.transition === TransitionCodes.CUSTOM_4) {
              let newValidateResults = []
              let updatedItem = {}
              validateResult.forEach((item) => {
                if (item.ordinal === currentPassenger.ordinal) {
                  updatedItem = item
                  // updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
                  // updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
                  // updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED

                  // newValidateResults.push(updatedItem)
                }
                //  else {
                //   newValidateResults.push(item)
                // }
              })

              const updatePaxStatus = {
                ordinal: currentPassenger.ordinal,
                docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
                privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED,
                faceMatch:
                  updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED ||
                  updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
              }

              summaryStore.endDocScan(true)
              // dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
              // acuantClient.close()
              dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
            } else {
              if (json.etsResponse.jsonExtend) {
                dispatch(updateLocalData('updateIsLastPassenger', true))
              } else {
                dispatch(updateLocalData('updateIsLastPassenger', false))
              }
              switch (json.transition) {
                case TransitionCodes.APIS_GET_VISA:
                  for (let i = 0; i < validateResult.length; i++) {
                    if (validateResult[i].ordinal === currentPassenger.ordinal) {
                      validateResult[i].passportScanned = true
                      validateResult[i].docCheck = PASSENGER_VALIDATION_STATUS.PASSED
                      validateResult[i].bioCheck = PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
                      validateResult[i].visaCheck = json.etsResponse.visaRequired
                        ? PASSENGER_VALIDATION_STATUS.UNKNOWN
                        : PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
                    }
                  }

                  dispatch(updateLocalData('updateValidatePaxResult', validateResult))
                  dispatch(checkTravelDoc(mediaData, apisData, checkTravelDocAction.CHECK_VISA_REQUIRED, true))
                  break
                case TransitionCodes.FACE_RECOGNITION:
                  summaryStore.endDocScan(false)
                  let privacyAccept = true
                  for (let i = 0; i < validateResult.length; i++) {
                    if (validateResult[i].ordinal === currentPassenger.ordinal) {
                      privacyAccept = validateResult.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
                      validateResult[i].passportScanned = true
                      validateResult[i].docCheck = PASSENGER_VALIDATION_STATUS.PASSED
                      validateResult[i].bioCheck = PASSENGER_VALIDATION_STATUS.UNKNOWN
                      validateResult[i].visaCheck = json.etsResponse.visaRequired
                        ? PASSENGER_VALIDATION_STATUS.UNKNOWN
                        : PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
                    }
                  }
                  dispatch(updateLocalData('updateValidatePaxResult', validateResult))
                  dispatch(updateLocalData('updateDeviceAttempts', 0))
                  summaryStore.startFacialData(privacyAccept)
                  acuantClient && acuantClient.close()
                  if (store.getState().custom.TVSPhoto && paxFaceImage) {
                    history.push('AnalyzePhoto')
                  } else {
                    dispatch(updateCustomData('updateTVSPhoto', null))
                    history.push('TakePhoto')
                  }
                  break
                case TransitionCodes.PAX_VALIDATION:
                  //update current pax and loop scan document
                  appLog(TraceLevels.LOG_EXT_TRACE, '(bagUpdateSBD.js) url: ScanDocuments')
                  store.dispatch(updatePaxValidationData('initialPaxData'))
                  const currentPax = store
                    .getState()
                    .localData.validatePaxResult.find((pax) => pax.passportScanned !== true)
                  store.dispatch(updatePaxValidationData('updateCurrentPassenger', currentPax))
                  history.push('ScanDocuments')
                  break
                case TransitionCodes.SBD_PROCESS:
                  if (store.getState().localData.baggageLoadedStatus) {
                    navigate('BagProcessing', 2)
                  } else {
                    navigate('PutBagOnBelt', 2)
                  }
                  break
                default:
              }
              // }
            }
          } else if (json.error) {
            if (allowOpposite) {
              history.push('ScanOppositeDoc')
            } else {
              logEvents(EventFlows.DocCheck, EventFunctions.DocCheckResult, 'Fail')
              logEvents(EventFlows.DocCheck, EventFunctions.DocCheckError, json.error.errorCode)
              if (
                json.error.errorCode === ErrCodes.ERR_PASSPORT_INVALID ||
                retryCount + 1 === config.scanDocRetryCount
              ) {
                currentPassenger = store.getState().paxValidation.currentPassenger
                let newValidateResults = []
                let updatedItem = {}
                validateResult.forEach((item) => {
                  if (item.ordinal === currentPassenger.ordinal) {
                    updatedItem = item
                  }
                })

                const updatePaxStatus = {
                  ordinal: currentPassenger.ordinal,
                  docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
                  privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED,
                  faceMatch:
                    updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED ||
                    updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.NOT_REQUIRED
                }

                const newValidateResult = validateResult.map((item) => {
                  if (item.ordinal === currentPassenger.ordinal) {
                    item.passportScanned = true
                    item.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
                    item.bioCheck = PASSENGER_VALIDATION_STATUS.UNKNOWN
                  }

                  return item
                })
                dispatch(updateLocalData('updateValidatePaxResult', newValidateResult))
                dispatch(updateLocalData('updateDeviceAttempts', 0))
                summaryStore.endDocScan(true)
                dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
              } else {
                dispatch(updateLocalData('updateDeviceAttempts', retryCount + 1))
                if (json.transition === TransitionCodes.PAX_VALIDATION) {
                  dispatch(updatePaxValidationData('updateDocRetryStauts', true))
                }
                goToFetchGenericError('checkTravelDoc', json, dispatch, { error: json.error.errorCode })
              }
            }
          }
        })
        .catch((err) => {
          handleFetchErrors(err, 'checkTravelDoc', 'error')
        })
    }
  }
}

const procesPhoto = (photoScore, photo) => {
  const validatePaxResult = store.getState().localData.validatePaxResult
  const currentPassenger = store.getState().paxValidation.currentPassenger
  const summaryStore = getSummaryStore()

  //update reslut in validatePaxResult
  let passingScore = config.photoPassingScore
  let newValidateResults = []
  let updatedItem = null
  validatePaxResult.forEach((item) => {
    if (item.ordinal === currentPassenger.ordinal) {
      updatedItem = item
      // updatedItem.bioCheck = compareScientificNumber(photoScore, passingScore)
      updatedItem.bioCheck =
        photoScore >= passingScore ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.FAILED
      newValidateResults.push(updatedItem)
    } else {
      newValidateResults.push(item)
    }
  })

  const updatePaxStatus = {
    ordinal: currentPassenger.ordinal,
    docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
    faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
    privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
  }

  summaryStore.endFacialData(updatedItem.bioCheck !== PASSENGER_VALIDATION_STATUS.PASSED)

  store.dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
  store.dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
}
