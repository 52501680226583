/** @class
 *  Device representing a VBD mode switch device
 */
import device from 'devices/device'
import {deviceIds } from 'constants/Constants'
import VBDModeListener from 'listeners/vbdModeListener'

/**
 *  Device representing VBDMode
 *  @extends {Device}
 */
export default class VBDMode extends device
{
  /**
   * @param {LinkSocket} socket - socket object
   * @param {DeviceManager} dm - device manager object
   */
  constructor(socket, dm) {
    super(socket, dm)
    /**@private*/
    this.name = 'VBDMode'
    /**
     * Device id - deviceIds.VBD_MODE {@link src/constants/Constants.js~deviceIds}
     * @type {Number}
     */
    this.deviceId = deviceIds.VBD_MODE
    this.setListener(new VBDModeListener())
  }

}
