import {
  TraceLevels,
  TSDTypes,
  ScreenFlowEventType,
  AppStateEventType,
  DeviceEventType,
  SessionInfoEventType,
  deviceIds,
  EventTypeName,
} from 'constants/Constants'
import { isInteger, toISOStringWithTimezone } from 'utils/helper'
/**
 * The TSDManager facilitates collecting and storing session statistics:
 * 	 <ul><li>screen interaction</li>
 *   <li>screen flow</li>
 *   <li>device interaction</li>
 *   <li>host interactions</li>
 *   <li>application state</li></ul>
 * The TSDManaget should be created once in the main.js and it interacts closely with the deviceManager to collect device interaction
 * events. It provides methods, to be used by the application, for all other even types.
 * <br/><code>tsdManager = new TSDManager(onOOSEvent)</code>
 * <br/><code>devMgr.setTSDManager(tsdManager)</code>
 * <br/>The optional callback function cbOOSEvent is called when device event indicates a fatal condition and the application should
 * go to 'unavailable' state at the end of the current transaction.
 * If gathering statistics, main purpose of the TSDManager, is not required then add <code>tsdManager.noLogs()</code> to main.js
 * <br/>The TSDManager can store up to 1000 events
 * <br/>The event time is a delta time since the start of the transaction, outside of the transaction is an epoch time.
 * @see https://embross.atlassian.net/browse/PIK-16
 */
export default class TSDManager {
  /**
   * TSD control default configuration - can be updated from Authenticate response
   * @param {function(devId: number, code: number)} [cbOOSevent] - callback function for OOSEvents.
   * @param {number} [version] - version of the tsd event json format. Default version 0 - before analytics.
   */
  constructor(cbOOSevent = null, version = 0) {
    /** log screen interaction events flag */
    this.logScreenInteractionEvent = true
    /** log screen flow events flag */
    this.logScreenFlowEvent = true
    /** log device interaction events flag */
    this.logDeviceInteractionEvent = true
    /** log application state events flag */
    this.logApplicationStateEvent = true
    /** log business flow events flag */
    this.logBusinessFlowEvent = true
    /** log host interface events flag */
    this.logHostInterfaceEvent = true
    /** log host interface events flag */
    this.logAppLogDetails = true
    /**
     * callback function to report Out Of Service type event
     * @type {function(devId: number, code: number)} */
    this.OOSevent = cbOOSevent // if all logging is off but the callback is defined then just execute callback
    /** @type {function(level: number, msg: String, addTS: Boolean)} */
    this.logger = null
    /** version of the tsd event json format. Default version 0 - before analytics. */
    this.formatVersion = version
    // buffered events
    /** maximum events to store (default 1000).*/
    this.maxEventsToBuffer = 1000
    /**
     * internal buffer to store events, each event object has event.tsdType of {@link src/constants/Constants.js~TSDTypes}
     * @type {Object[]} */
    this.eventsBuffer = []

    /** start transaction timestamp*/
    this.startTxn = 0 // set by AppStateEventType.TXN_START
    /** ETS session id*/
    this.sessionId = ''
    /** start session timestamp*/
    this.startSession = 0 // set by SessionInfoEventType.SESSION_START

    /** last screen id*/
    this.screenId = ''
    /** last popup screen id*/
    this.popupScreenId = ''
    /** server delta time - updated in updateServerDeltaTime when called by heartbeat.js*/
    this.serverDeltaTime = 0
    /** updateServerDeltaTime was called in the middle of the transaction - delay update*/
    this.serverDeltaTimePending = 0
    /** clear buffer at the start of the transaction */
    this.clearAtStartTxn = true
    /************************************************************************/
    // new added properties
    /*************************************************************************/
    this.applicationType = null
    this.kioskId = null
    this.carrierCode = null
    this.locationId = null
  }

  /**
   * @param {function(level: number, msg: String, addTS: Boolean)} extLogger - logger function.
   * The deviceManager sets the logger to it's own logger <code>this.TSDMan.setLogger(this.dmLog)</code>
   */
  setLogger(extLogger) {
    this.logger = extLogger
    this.logIt(TraceLevels.LOG_TRACE, 'TSD set logger.')
  }

  /**
   * Suppress storing all events. Used only when callback cbOOSEvents is used.
   */
  noLogs() {
    this.logScreenInteractionEvent = false
    this.logScreenFlowEvent = false
    this.logDeviceInteractionEvent = false
    this.logApplicationStateEvent = false
    this.logBusinessFlowEvent = false
    this.logHostInterfaceEvent = false
    this.logAppLogDetails = false
  }

  /**
   * Allow or suppress storing screen interaction events.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogScreenInteractionEvent(flag) {
    this.logScreenInteractionEvent = flag
  }

  /**
   * Allow or suppress storing screen flow events.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogScreenFlowEvent(flag) {
    this.logScreenFlowEvent = flag
  }

  /**
   * Allow or suppress storing device interaction events.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogDeviceInteractionEvent(flag) {
    this.logDeviceInteractionEvent = flag
  }

  /**
   * Allow or suppress storing application state events.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogApplicationStateEvent(flag) {
    this.logApplicationStateEvent = flag
  }

  /**
   * Allow or suppress storing business flow events.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogBusinessFlowEvent(flag) {
    this.logBusinessFlowEvent = flag
  }

  /**
   * Allow or suppress storing host interface events.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogHostInterfaceEvent(flag) {
    this.logHostInterfaceEvent = flag
  }

  /**
   * Allow or suppress storing application log detials.
   * @param {Boolean} flag - if true then events will be stored.
   */
  setLogAppLogDetails(flag) {
    this.logAppLogDetails = flag
  }

  /**
   * Set default buffer clear at the start of the transaction
   * @param {Boolean} clearBuffer - if true then the default buffer clear will be called at the start transaction.
   */
  setClearAtStartTxn(clearBuffer) {
    this.clearAtStartTxn = clearBuffer
  }

  /**
   * Clear all stored events and transaction information.
   * <br/>The application is responsible of clearing the stored events at the end of the transaction.
   * @param {Boolean} keepBeforeTxn - if true then device and state events will be preserved
   * @param {Number}  timeLimit - if not provided than last 30 seconds, if -1 then keep all otherwise provided number
   */
  clearTxn(keepBeforeTxn, timeLimit) {
    if (keepBeforeTxn) {
      if (this.startTxn !== 0) {
        //previous transaction data not cleared
        let ix = this.eventsBuffer.findIndex((item) => item.eventType === AppStateEventType.TXN_START)
        if (ix >= 0) {
          this.logIt(TraceLevels.LOG_TRACE, ' clearTxn: cleared prev startTxn at index:' + ix)
          this.eventsBuffer = this.eventsBuffer.filter((item, index) => index > ix)
        }
      }
      let tLimit = 30
      if (!isNaN(timeLimit) && isInteger(timeLimit)) {
        tLimit = timeLimit
      }
      if (tLimit > 0) {
        // too many events before start transaction - leave only last {timeLimit} seconds
        let oldSize = this.eventsBuffer.length
        let et = this.getEventTime()
        tLimit = tLimit * 1000
        this.eventsBuffer = this.eventsBuffer.filter((item) => et - item.eventTime < tLimit)
        this.logIt(TraceLevels.LOG_TRACE, ' clearTxn: cleared old events cnt: ' + (oldSize - this.eventsBuffer.length))
      }
    } else {
      this.eventsBuffer = []
    }
    this.startTxn = 0
    this.startSession = 0
    this.sessionId = ''
    this.screenId = ''
    this.popupScreenId = ''
    if (this.serverDeltaTimePending !== 0) {
      this.serverDeltaTime = this.serverDeltaTimePending
      this.serverDeltaTimePending = 0
    }
  }

  /**
   * Add screen interaction event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logScreenInteractionEvent} is true and there is room in the
   * buffer {@link maxEventsToBuffer}.
   * @param {String} eventId - one of {@link src/constants/Constants.js~ScreenInteractionEventType}.
   * @param {String} screenId - the screen id - if empty then last screen id will be used {@link this.screenId}.
   * @param {String} elementId - screen element id.
   * @param {number} [xPos] - x value of (x,y) coordinate of the screen touch.
   * @param {number} [yPos] - y value of (x,y) coordinate of the screen touch.
   * @param {String} [description] - description.
   */
  addScrInterEvent(eventId, screenId, elementId, xPos, yPos, description) {
    if (this.logScreenInteractionEvent && this.checkSpace()) {
      if (this.formatVersion === 0) {
        let obj = {
          tsdType: TSDTypes.SCREEN_INTERACTION,
          eventId: eventId,
          sessionID: this.sessionId,
          eventTime: this.getEventTime(),
          screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
          xpos: xPos != null ? xPos : -1,
          ypos: yPos != null ? yPos : -1,
        }
        if (elementId) {
          obj.elementID = elementId
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addScrInterEvent: ' + JSON.stringify(obj))
        this.eventsBuffer.push(obj)
      } else if (this.formatVersion === 1) {
        let obj2 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          stateData: {
            eventId: eventId,
            screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
            x: xPos != null ? xPos : -1,
            y: yPos != null ? yPos : -1,
          },
        }
        if (elementId) {
          obj2.stateData.elementID = elementId
        }
        if (description) {
          obj2.stateData.description = description
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addScrInterEvent2: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          eventType: EventTypeName.SCREEN_INTERACTION_EVENT_DETAILS,
          stateData: {
            eventID: eventId,
            screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
            x: xPos != null ? xPos : -1,
            y: yPos != null ? yPos : -1,
          },
        }
        if (elementId) {
          obj3.stateData.elementID = elementId
        }
        if (description) {
          obj3.stateData.description = description
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addScrInterEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      }
    }
  }

  /**
   * Add device interaction event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logDeviceInteractionEvent} is true, there is room in the
   * buffer {@link maxEventsToBuffer} and {@link mapCBtoDevEventType} internal function translates the eventType and codeId to
   * one of the {@link src/constants/Constants.js~DeviceEventType}.
   * <br/>The {@link src/devices/device.js} generates all device events.
   * @param {String} eventId - response or event name received from the device.
   * @param {number} deviceId - one of {@link src/constants/Constants.js~deviceIds}.
   * @param {String} deviceName - device name as defined in the device class (e.g.: 'Barcode Reader')
   * @param {String} [codeId] - response or event value received from the device.
   * @param {String} [screenId] - response or event value received from the device.
   * @param {String} deviceType - device type (e.g.: 'BarcodeReader')
   * @param {String} deviceVersion - device version
   * @param {String} [description] - description.
   */
  addDeviceInterEvent(eventId, deviceId, deviceName, codeId, screenId, deviceType, deviceVersion, description) {
    if ((this.logDeviceInteractionEvent && this.checkSpace()) || this.OOSevent !== null) {
      let evt = this.mapCBtoDevEventType(eventId, codeId, deviceId)
      if (!evt || !this.logDeviceInteractionEvent) return
      if (this.formatVersion === 0) {
        let obj = {
          tsdType: TSDTypes.DEVICE_INTERACTION,
          eventId: evt,
          sessionID: this.sessionId,
          eventTime: this.getEventTime(),
          screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
          deviceName: deviceName,
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addDeviceInterEvent: ' + JSON.stringify(obj))
        this.eventsBuffer.push(obj)
      } else if (this.formatVersion === 1) {
        let obj2 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          stateData: {
            eventId: eventId,
            screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
            device: {
              name: deviceName,
            },
          },
        }
        if (deviceType) {
          obj2.stateData.device.type = deviceType
        }
        if (deviceVersion) {
          obj2.stateData.device.version = deviceVersion
        }
        if (description) {
          obj2.stateData.description = description
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addDeviceInterEvent2: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          eventType: EventTypeName.DEVICE_INTERACTION_EVENT_DETAILS,
          stateData: {
            eventID: evt,
            deviceScreenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
            device: {
              name: deviceName,
            },
          },
        }
        if (deviceType) {
          obj3.stateData.device.type = deviceType
        }
        if (deviceVersion) {
          obj3.stateData.device.version = deviceVersion
        }
        if (description) {
          obj3.stateData.description = description
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addDeviceInterEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      }
    }
  }

  /**
   * Add screen flow event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logScreenFlowEvent} is true and there is room in the
   * buffer {@link maxEventsToBuffer}.
   * <br/>The application is responsible of generating these events. The typical solution is to add code to the main.js
   * for regular pages and to basePage.js for popup pages.
   * To intercept all location changes add to main.js:
   * <br/><code>history.listen(location => { ... </code>
   * <br/>The location object can have information about the traveler:
   * <code>location.state.statistics.paxOrdinal
   *  location.state.statistics.paxName</code>
   * @param {String} eventId - one of {@link src/constants/Constants.js~ScreenFlowEventType}.
   * @param {String} screenId - screen id.
   * @param {String} targetScreenId - target screen id.
   * @param {String} transitionCode - transition code.
   * @param {String} [descriptionOrTravelerId] - traveler id for version 0 otherwise description.
   * @param {String} [travelerName] - traveler name only for version 0.
   * @param {String} [errorCode] - error code for version 0 otherwise if description empty then add to description.
   *
   */
  addScrFlowEvent(eventId, screenId, targetScreenId, transitionCode, descriptionOrTravelerId, travelerName, errorCode) {
    if (this.logScreenFlowEvent && this.checkSpace()) {
      if (eventId === ScreenFlowEventType.LOCAL_TRANSITION || eventId === ScreenFlowEventType.CONTROL_TRANSITION) {
        if (targetScreenId) this.screenId = targetScreenId
        this.popupScreenId = ''
      } else if (eventId === ScreenFlowEventType.POPUP_END) {
        targetScreenId = targetScreenId === null ? '' : this.screenId
      }
      if (this.formatVersion === 0) {
        let obj = {
          tsdType: TSDTypes.SCREEN_FLOW,
          eventId: eventId,
          sessionID: this.sessionId,
          eventTime: this.getEventTime(),
          screenID: this.popupScreenId !== '' ? this.popupScreenId : this.screenId,
        }
        if (targetScreenId) {
          obj.targetScreenID = targetScreenId
        }
        if (transitionCode) {
          obj.transitionCode = transitionCode
        }
        if (descriptionOrTravelerId !== undefined && descriptionOrTravelerId !== '') {
          obj.travelerID = descriptionOrTravelerId
        }
        if (travelerName !== undefined && travelerName !== '') {
          obj.travelerName = travelerName
        }
        if (errorCode !== undefined && errorCode !== '') {
          obj.errorCode = errorCode
        }
        if (eventId === ScreenFlowEventType.POPUP_END) {
          this.popupScreenId = ''
        } else if (eventId === ScreenFlowEventType.POPUP_START) {
          this.popupScreenId = targetScreenId
        } else if (
          eventId === ScreenFlowEventType.LOCAL_TRANSITION ||
          eventId === ScreenFlowEventType.CONTROL_TRANSITION
        ) {
          this.screenId = targetScreenId
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addScrFlowEvent: ' + JSON.stringify(obj))
        this.eventsBuffer.push(obj)
      } else if (this.formatVersion === 1) {
        let obj2 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          stateData: {
            eventId: eventId,
            screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
            targetScreenID: targetScreenId ? targetScreenId : '',
            transitionCode: transitionCode,
          },
        }
        if (descriptionOrTravelerId) {
          obj2.stateData.description = descriptionOrTravelerId
        }
        if (!descriptionOrTravelerId && errorCode !== undefined && errorCode !== '') {
          obj2.stateData.description = 'ErrorCode=' + errorCode
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addScrFlowEvent: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          eventType: EventTypeName.SCREEN_FLOW_EVENT_DETAILS,
          stateData: {
            eventID: eventId,
            screenID: this.popupScreenId !== '' ? this.popupScreenId : screenId ? screenId : this.screenId,
            targetScreenID: targetScreenId ? targetScreenId : '',
            transitionCode: transitionCode,
          },
        }
        if (descriptionOrTravelerId) {
          obj3.stateData.description = descriptionOrTravelerId
        }
        if (!descriptionOrTravelerId && errorCode !== undefined && errorCode !== '') {
          obj3.stateData.description = 'ErrorCode=' + errorCode
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addScrFlowEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      }
    }
  }

  /**
   * Add application state event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logApplicationStateEvent} is true and there is room in the
   * buffer {@link maxEventsToBuffer}.
   * <br/>The application is responsible of generating these events.
   * @param {String} eventId - one of {@link src/constants/Constants.js~AppStateEventType}.
   * @param {String} [sessionID] - session id.
   * @param {String} [description] - description.
   */
  addAppStateEvent(eventId, sessionID, description) {
    if (this.logApplicationStateEvent && this.checkSpace()) {
      let et = this.getEventTime()
      if (eventId === AppStateEventType.TXN_START) {
        if (this.clearAtStartTxn) {
          this.clearTxn(true)
        }
        this.startTxn = et
        this.sessionId = sessionID ? sessionID : ''
      }
      
      let header = {
        schemaVersion: this.formatVersion,
        timestamp: toISOStringWithTimezone(new Date()),
        eventTime: new Date().getTime(),
        eventSource: this.kioskId,
        locationID: this.locationId,
        subClientID: this.carrierCode,
        sessionID: this.sessionId,
        eventID: eventId,
        eventType: EventTypeName.APP_STATE_EVENT_DETAILS,
        applicationType: this.applicationType,
      }
      let stateData = {
        stateData: eventId
      }
      this.eventsBuffer.push({ ...header, ...stateData })
      /* if (this.formatVersion === 0) {
        let obj = {
          tsdType: TSDTypes.APPLICATION_STATE,
          eventId: eventId,
          sessionID: sess,
          eventTime: et,
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addAppStateEvent: ' + JSON.stringify(obj))
        this.eventsBuffer.push(obj)
      } else if (this.formatVersion === 1) {
        let obj2 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          stateData: {
            eventId: eventId,
          },
        }
        if (description) {
          obj2.stateData.description = description
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addAppStateEvent2: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          eventType: EventTypeName.APP_STATE_EVENT_DETAILS,
          stateData: {
            eventID: eventId,
          },
        }
        if (description) {
          obj3.stateData.description = description
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addAppStateEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      } */
    }
  }

  /**
   * Add host interface event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logHostInterfaceEvent} is true and there is room in the
   * buffer {@link maxEventsToBuffer}.
   * <br/>The application is responsible of generating these events.
   * @param {String} eventId - one of {@link src/constants/Constants.js~HostInterfaceEventType}.
   * @param {String} url - url of the request or response (version 0 - full url otherwise just endpointAddress.
   * @param {String} [descrOrServiceName] - description used with host exceptions in version 0 otherwise serviceName.
   * @param {String} [message] - message if the request or response.
   */
  addHostInterfaceEvent(eventId, url, descrOrServiceName, message) {
    if (this.logHostInterfaceEvent && this.checkSpace()) {
      if (this.formatVersion === 0) {
        let obj = {
          tsdType: TSDTypes.HOST_INTERFACE,
          eventId: eventId,
          sessionID: this.sessionId,
          eventTime: this.getEventTime(),
          endpointAddress: url,
        }
        if (descrOrServiceName !== undefined && descrOrServiceName !== '') {
          obj.description = descrOrServiceName
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addHostInterfaceEvent: ' + JSON.stringify(obj))
        this.eventsBuffer.push(obj)
      } else if (this.formatVersion === 1) {
        let obj2 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          stateData: {
            eventId: eventId,
            endpointAddress: url,
            serviceName: descrOrServiceName,
          },
        }
        if (message !== undefined && message !== '') {
          obj2.stateData.message = message
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addHostInterfaceEvent2: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId,
          eventType: EventTypeName.HOST_INTERFACE_EVENT_DETAILS,
          stateData: {
            eventID: eventId,
            endpointAddress: url,
            serviceName: descrOrServiceName,
          },
        }
        if (message !== undefined && message !== '') {
          obj3.stateData.message = message
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addHostInterfaceEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      }
    }
  }

  /**
   * Add environment info event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logApplicationStateEvent} is true and there is room in the
   * buffer {@link maxEventsToBuffer}.
   * <br/>The application is responsible of generating these events.
   * @param {String} eventId - one of {@link src/constants/Constants.js~EnvInfoEventType}.
   * @param {String} name - software name.
   * @param {String} version - software version.
   * @param {String} [description] - description.
   */
  addEnvInfoEvent(eventId, name, version, description) {
    if (this.logApplicationStateEvent && this.checkSpace()) {
      /* if (this.formatVersion === 1 || this.formatVersion === 0) {
        let obj2 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId ? this.sessionId : '',
          stateData: {
            eventId: eventId,
            appInfo: {
              name: name,
              version: version,
            },
          },
        }
        if (description) {
          obj2.stateData.description = description
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addEnvInfoEvent2: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: this.getEventTime(),
          sessionID: this.sessionId ? this.sessionId : '',
          eventType: EventTypeName.ENVIRONMENT_DETAILS,
          stateData: {
            eventID: eventId,
            appInfo: {
              name: name,
              version: version,
            },
          },
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addEnvInfoEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      } */
        let header = {
          schemaVersion: this.formatVersion,
          timestamp: toISOStringWithTimezone(new Date()),
          eventTime: new Date().getTime(),
          eventSource: this.kioskId,
          locationID: this.locationId,
          subClientID: this.carrierCode,
          sessionID: this.sessionId,
          eventID: eventId,
          eventType: EventTypeName.ENVIRONMENT_DETAILS,
          applicationType: this.applicationType,
        }
        let stateData = {
          eventID: eventId,
            appInfo: {
              name: name,
              version: version,
            },
        }
        this.eventsBuffer.push({ ...header, ...stateData })
    }
  }

  /**
   * Add session info event to the internal buffer.
   * The event is stored in the internal buffer when the {@link logApplicationStateEvent} is true and there is room in the
   * buffer {@link maxEventsToBuffer}.
   * <br/>The application is responsible of generating these events.
   * @param {String} eventId - one of {@link src/constants/Constants.js~SessionInfoEventType}.
   * @param {String} [language] - BCP 47 language tag value - See IANA Language Subtag Registry (https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry).
   * @param {String} [sessionData] - Detailed information regarding the current session in comma-separated string format. For example, PNR=ABC123,ErrorCode=ERR_PRINTER, ....
   * @param {String} [description] - description.
   */
  addSessionInfoEvent(eventId, language, sessionData, description) {
    if (this.logApplicationStateEvent && this.checkSpace()) {
      let et = this.getEventTime()
      if (eventId === SessionInfoEventType.SESSION_START) {
        if (this.clearAtStartTxn) {
          this.clearTxn(true)
        }
        this.startSession = et
      }
      let header = {
        schemaVersion: this.formatVersion,
        timestamp: toISOStringWithTimezone(new Date()),
        eventTime: new Date().getTime(),
        eventSource: this.kioskId,
        locationID: this.locationId,
        subClientID: this.carrierCode,
        sessionID: this.sessionId,
        eventID: eventId,
        eventType: EventTypeName.SESSION_SUMMARY_DETAILS,
        applicationType: this.applicationType,
      }
      let stateData = {
        stateData: sessionData
      }
      this.eventsBuffer.push({ ...header, ...stateData })
      /* if (this.formatVersion === 1 || this.formatVersion === 0) {
        let obj2 = {
          eventTime: et,
          sessionID: this.sessionId ? this.sessionId : '',
          stateData: {
            eventId: eventId,
            startTime: this.startSession,
          },
        }
        if (eventId === SessionInfoEventType.SESSION_END) {
          obj2.stateData.endTime = et
        }
        if (language) {
          obj2.stateData.language = language
        }
        if (sessionData) {
          obj2.stateData.sessionData = sessionData
        }
        if (description) {
          obj2.stateData.description = description
        }
        this.logIt(TraceLevels.LOG_LEVEL6, ' addSessionInfoEvent2: ' + JSON.stringify(obj2))
        this.eventsBuffer.push(obj2)
      } else {
        let obj3 = {
          eventTime: et,
          sessionID: this.sessionId ? this.sessionId : '',
          eventType: EventTypeName.SESSION_SUMMARY_DETAILS,
          stateData: {
            eventID: eventId,
            startTime: this.startSession,
          },
        }
        if (eventId === SessionInfoEventType.SESSION_END) {
          obj3.stateData.endTime = et
        }
        if (language) {
          obj3.stateData.language = language
        }
        if (sessionData) {
          obj3.stateData.sessionData = sessionData
        }
        if (description) {
          obj3.stateData.description = description
        }
        //this.logIt(TraceLevels.LOG_LEVEL6, ' addSessionInfoEventDataAnalytics: ' + JSON.stringify(obj3))
        this.eventsBuffer.push(obj3)
      } */

    }
  }

  /**
   * update server delta time - delay update if in the middle of the transaction
   * @param {number} delta - delta between server time (received in the heartbeat) and local time.
   */
  updateServerDeltaTime(delta) {
    this.logIt(TraceLevels.LOG_TRACE, 'TSD updateServerDeltaTime: ' + delta)
    if (this.startTxn === 0) {
      this.serverDeltaTime = delta
      this.serverDeltaTimePending = 0
    } else {
      this.serverDeltaTimePending = delta
    }
  }

  /**
   * Returns the event time - in version 0: delta from the start transaction or epoch time adjusted by ETS server delta time
   *                          in version 1+: epoch time adjusted by ETS server delta time
   * @return {number} event time.
   */
  getEventTime() {
    let ts = new Date().getTime() + this.serverDeltaTime
    return this.formatVersion === 0 && this.startTxn !== 0 ? ts - this.startTxn : ts
  }

  /**
   * Returns the all events stored in the buffer.
   * @return {event[]} events stored in the buffer.
   */
  getEvents() {
    return this.eventsBuffer
  }

  /**
   * Internal method - translates received message (key, codeId) from the device to the predefined DeviceEventType.
   * @param {String} key - method or event name from the device.
   * @param {String} codeId - response or event value received from the device.
   * @param {number} deviceId - one of {@link src/constants/Constants.js~deviceIds}.
   * @return {String} one of {@link src/constants/Constants.js~DeviceEventType} or empty string when not translated.
   */
  mapCBtoDevEventType(key, codeId, deviceId) {
    let ret = ''
    //    this.logIt(TraceLevels.LOG_LEVEL6, 'TSD mapCBtoDevEventType key: ' + key + ' code: ' + codeId + ' devId: ' + deviceId)
    switch (key) {
      case 'barcodeInserted':
      case 'cardInserted':
      case 'passportInserted':
      case 'ticketInserted':
        ret = DeviceEventType.MEDIA_INSERTED
        break
      case 'barcodeRemoved':
      case 'cardRemoved':
      case 'passportRemoved':
      case 'ticketRemoved':
      case 'bagtagRemoved':
        ret = DeviceEventType.MEDIA_REMOVED
        break
      case 'barcodeDamaged':
      case 'cardDamaged':
      case 'passportDamaged':
        ret = DeviceEventType.MEDIA_DAMAGED
        break
      case 'dataError':
        if (deviceIds.FACE_TRACKING === deviceId && this.isFatalError(deviceId, codeId, 'true')) {
          // face tracking e.g. 302, true
          ret = DeviceEventType.OFFLINE
        } else {
          ret = DeviceEventType.MEDIA_DAMAGED
        }
        break
      case 'barcodeReadInternal':
      case 'barcodeRead':
      case 'cardReadInternal':
      case 'cardRead':
      case 'passportReadInternal':
      case 'passportRead':
      case 'ticketRead':
        ret = DeviceEventType.DATA_READ
        break
      case 'getKioskDeviceHelp':
        ret = DeviceEventType.HELP_LEVEL_INVOKED
        break
      case 'headsetInserted':
        ret = DeviceEventType.HEADSET_INSERTED
        break
      case 'headsetRemoved':
        ret = DeviceEventType.HEADSET_REMOVED
        break
      case 'keyPressed':
        ret = DeviceEventType.NAVIGATION_KEY_PRESSED
        break
      case 'pictureTaken':
        ret = DeviceEventType.PICTURE_TAKEN
        break
      case 'faceDescriptor':
        ret = DeviceEventType.FACE_DESCRIPTOR
        break
      case 'ticketPrintingComplete':
      case 'bagtagPrintingComplete':
      case 'pagePrintComplete':
        ret = DeviceEventType.PRINT_ENDED
        break
      case 'printerError':
        this.logIt(TraceLevels.LOG_LEVEL6, 'printerError: ' + codeId)
        if (this.isFatalError(deviceId, codeId, 'true')) {
          if (codeId.indexOf('101,') === 0) {
            // 101, LPT_PTR_JAM, true
            ret = DeviceEventType.JAMMED
          } else if (codeId.indexOf('108,') === 0) {
            // 108, LPT_PTR_PAPER_OUT, true
            ret = DeviceEventType.OUT
          } else {
            ret = DeviceEventType.OFFLINE
          }
        }
        break
      case 'statusChange': // [errCode, fatal?] - if fatal then call OOSevent()
        if (Array.isArray(codeId)) {
          this.logIt(TraceLevels.LOG_LEVEL6, 'TSD statusChange codeId: ' + codeId)
          if (codeId[1]) {
            // fatal
            if (this.OOSevent !== null) {
              this.OOSevent(deviceId, codeId[0])
            }
            ret = DeviceEventType.OFFLINE
          } else {
            ret = DeviceEventType.STATUS_CHANGED
          }
        } else {
          if (this.isFatalError(deviceId, codeId, 'true')) {
            ret = DeviceEventType.OFFLINE
          } else {
            ret = DeviceEventType.STATUS_CHANGED
          }
        }
        break
      case 'statusIsOK': // rc , ok  302,0  -- if not OK then OOSEvent
        if (this.isFatalError(deviceId, codeId, '0')) {
          ret = DeviceEventType.OFFLINE
        }
        break
      case 'ticketEjected':
      case 'cardEjected':
        ret = DeviceEventType.EJECTED
        break
      case 'pectabLoaded':
      case 'pectabLoadingComplete':
        ret = DeviceEventType.LOADED
        break
      case 'ticketPresent':
      case 'bagtagPresent':
        ret = DeviceEventType.PRESENT
        break
      case 'ticketPrinted':
      case 'bagtagPrinted':
      case 'pagePrinted':
        ret = DeviceEventType.PRINTED
        break
      case 'passportDataError':
        ret = DeviceEventType.DATA_ERROR
        break
      case 'ticketFailed':
      case 'bagtagFailed':
      case 'pagePrintFailed':
      case 'pectabFailed':
        ret = DeviceEventType.FAILED
        break
      case 'cardInsertedIncomplete':
        ret = DeviceEventType.INSERTED_INCOMPLETE
        break
      case 'accessingRFChip':
        ret = DeviceEventType.ACCESSING_RF_CHIP
        break
      case 'printerTrayStatusChanged':
        ret = DeviceEventType.STATUS_CHANGED
        break

      // TBD
      case 'cardInThroat':
        ret = ''
        break

      // the default will be ''
      case 'getCurrentState':
      case 'notificationText':
      case 'isOK':
      case 'getStatusHeadset':
      case 'getStatusKeypad':
      case 'getStatusTTS':
      case 'isHeadsetAvailable':
      case 'speechCompleted':
      case 'speechPaused':
      case 'speechStarted':
      case 'speechStopped':
      case 'isAccessibilitySupported':
      case 'play':
      case 'sysmgrCommand':
      case 'setup':
      case 'faceAbsent':
      case 'facePresent':
      case 'trackingEvent':
      case 'countDown':
      case 'analyze':
      case 'analyzeResult':
      case 'show':
      case 'inputChanged':
      case 'outputChanged':
      case 'printFileByteStream':
        break

      case DeviceEventType.ENABLED:
      case DeviceEventType.DISABLED:
        ret = key
        break

      default:
        ret = ''
    }
    return ret
  }

  /**
   * Internal method - determines if the input values indicate a fatal device error.
   * @param {number} deviceId - one of {@link src/constants/Constants.js~deviceIds}.
   * @param {String} codeId - response or event value received from the device.
   * @param {String} val - value which indicates a fatal error in the codeId (e.g. 'true' for codeId='101, LPT_PTR_JAM, true')
   * or '0' for codeId='302,0'
   * @return {Boolean} if true then input vales indicate the fatal error of the device.
   */
  isFatalError(deviceId, codeId, val) {
    this.logIt(TraceLevels.LOG_TRACE, 'TSD isFatalError deviceId: ' + deviceId + ' codeId: ' + codeId + ' val: ' + val)
    if (codeId) {
      let params = codeId.split(',') // expected string with two values: rc, fatal? or 3 rc, description, fatal?
      if ((params.length === 2 && params[1].trim() === val) || (params.length === 3 && params[2].trim() === val)) {
        if (this.OOSevent !== null) {
          this.OOSevent(deviceId, params[0].trim())
        }
        return true
      }
    }
    return false
  }

  /**
   * Returns true when there is space in the internal buffer {@link eventsBuffer}.
   * @return {Boolean} true when there is space in the internal buffer for the event.
   */
  checkSpace() {
    let ret = true
    if (this.maxEventsToBuffer > 0) {
      if (this.eventsBuffer.length >= this.maxEventsToBuffer) {
        ret = false
        this.logIt(TraceLevels.LOG_ALERT, 'TSD no room to buffer events.')
      }
    }
    return ret
  }

  /**
   * Internal method - logs the message using the logger. When logger is not assigned then uses <code>//console.log(msg)</code>.
   * @param {number} level - one of {@link src/constants/Constants.js~TraceLevels}.
   * @param {String} msg - message to log.
   */
  logIt(level, msg) {
    if (this.logger != null) {
      this.logger(level, msg)
    } else if (level <= TraceLevels.LOG_SECURE) {
      console.log(msg)
    }
  }
  /********************************************************************************* */
  // added function
  /* public method - add the message to buffer using new header.
   * @param {EventFlow} flow - one of {@link src/constants/Constants.js~TraceLevels}.
   * @param {EventFunction} func
   * @param {String} msg - message to log.
   * @return [header, stateData]
   */
  addEventLog(flow, func, msg) {
    let header = null,
      stateData = null
    if (this.logAppLogDetails && this.checkSpace()) {
      header = {
        schemaVersion: this.formatVersion,
        timestamp: toISOStringWithTimezone(new Date()),
        eventTime: new Date().getTime(),
        eventSource: this.kioskId,
        locationID: this.locationId,
        subClientID: this.carrierCode,
        sessionID: this.sessionId,
        eventID: 'APPLICATION_LOG',
        eventType: 'APP_LOG_DETAILS',
        applicationType: this.applicationType,
      }
      stateData = {
        stateData: {
          eventFlow: flow,
          eventFunction: func,
          eventLog: msg,
        },
      }
      this.eventsBuffer.push({ ...header, ...stateData })
    }
    return [header, stateData]
  }
}
