import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import useUIBase from 'hooks/ui/useUIBase'
import { selectPaxFlight } from 'actions/etsTransactions/selectPaxFlight'
import { Button, CheckboxStatus, Checkbox, EMBTable } from 'components'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { PageContent, PageTitle, PageSubTitle, PageSubContent, Spacer } from 'components/styledComponents'
import useCheckLandscape from 'hooks/useCheckLandscape'
import { Footer } from '../../layouts/footer'
import { playSound, getAccessibilityManager, getBuildAccessibility } from 'main'
import { clone, populateItineraryInfo, getScreenId } from 'utils/helper'
import useMedia from 'hooks/useMedia'
import { BaggageStatus } from 'constants/Constants'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const getData = (passengers, mainPassengerOrdinal, formatMessage) => {
  let selectableCheckboxStatus = CheckboxStatus.UNCHECKED
  let alreadyCheckIn = false
  let data = []
  let canSelectCount = 0
  console.log('getData...')
  if (passengers && passengers.length > 0) {
    passengers.map((paxInfo) => {
      let additionalText = ''
      let additionalText1 = ''
      let additionalText2 = ''
      // additionalText = ''
      alreadyCheckIn = false
      selectableCheckboxStatus = CheckboxStatus.UNCHECKED_DISABLED
      if (paxInfo.passenger.ordinal === mainPassengerOrdinal) {
        selectableCheckboxStatus = CheckboxStatus.CHECKED
        //setIsDisableConfirmButton(false)
      } else {
        canSelectCount++
      }

      if (paxInfo.passenger.withInfant) {
        additionalText += ` +${formatMessage(messages.Infant)} `
      }

      if (paxInfo.bagTags) {
        const numberOfBags = paxInfo.bagTags.filter((bagDetail) => {
          return bagDetail.status !== BaggageStatus.ACTIVATED
        }).length
        additionalText1 = `${numberOfBags} ${
          numberOfBags > 1 ? formatMessage(messages.Bags) : formatMessage(messages.Bag)
        }`
      }
      data.push({
        ordinal: paxInfo.passenger.ordinal,
        id: 'pax_' + paxInfo.passenger.ordinal.toString(),
        text: paxInfo.passenger.firstName + ' ' + paxInfo.passenger.lastName,
        passengerType: paxInfo.passenger.passengerType,
        status: selectableCheckboxStatus,
        dateOfBirth: paxInfo.passenger.dateOfBirth,
        alreadyCheckIn: alreadyCheckIn,
        withInfant: paxInfo.passenger.withInfant,
        additionalText: additionalText,
        additionalText1: additionalText1,
        additionalText2: additionalText2
      })
    })
  }
  return data
}

const VerifyPayment = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const paxOrdinal = useSelector((state) => state.localData.bagObj.bagTagObject.paxOrdinal)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(false)
  const [itineraryData, setItineraryData] = useState(null)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const isQuitRequired = true
  const isLandscape = useCheckLandscape()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const columns = isLandscape
    ? [
        {
          header: '',
          width: '500px'
        },
        {
          header: '',
          width: '500px'
        }
      ]
    : [
        {
          header: '',
          width: themeContext.PassengerSelection.scrollAreaWidth[ratioKey]
        }
      ]

  const handleSelection = (updatedData) => {
    playSound.beepOK()
    console.log('onCheckboxClick id=' + updatedData[0].id + ', status=' + updatedData[0].status)
    let hasChecked = false
    data.map((item) => {
      if (item.id === updatedData[0].id) {
        item.status = updatedData[0].status
      }
      if (item.status === CheckboxStatus.CHECKED || CheckboxStatus.CHECKED_DISABLED) {
        hasChecked = true
      }
    })
    if (hasChecked) {
      setIsDisableConfirmButton(false)
    } else {
      setIsDisableConfirmButton(true)
    }
    setData([...data])
  }
  const buildRows = (data) => {
    let canSelectCount = 0
    let rows = []
    let count = 0
    if (!data) {
      return rows
    }
    if (isLandscape) {
      data.forEach((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        const checkbox = (
          <Checkbox
            key={'key-' + index}
            id={cell.id}
            data={[checkboxData]}
            onClick={handleSelection}
            additionalText={cell.additionalText}
            additionalText1={cell.additionalText1}
            additionalText2={cell.additionalText2}
            tabIndex={cell.tabIndex}
          />
        )
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        if (index % 2 === 0) {
          rows.push({ data: [checkbox] })
        } else {
          rows[count].data.push(checkbox)
          count++
        }
      })
    } else {
      data.map((cell, index) => {
        const checkboxData = {
          id: cell.id,
          text: cell.text,
          status: cell.status
        }
        if (cell.status === CheckboxStatus.UNCHECKED) {
          canSelectCount++
        }
        rows.push({
          data: [
            <Checkbox
              key={'key-' + index}
              id={cell.id}
              data={[checkboxData]}
              onClick={handleSelection}
              additionalText={cell.additionalText}
              additionalText1={cell.additionalText1}
              additionalText2={cell.additionalText2}
              tabIndex={cell.tabIndex}
            />
          ]
        })
      })
    }
    return rows
  }

  const handelPayment = (e) => {
    switch (e.currentTarget.id) {
      case 'backBtn':
        logEvents(EventFlows.AgentOverride, EventFunctions.QRScan, 'Agent override: Bypass payment')
        dispatch(genericCommand('overrideOverweightStatus', { payment: false }, null))
        break
      case 'skipBtn':
        logEvents(EventFlows.AgentOverride, EventFunctions.QRScan, 'Agent override: Guest paid')
        dispatch(genericCommand('overrideOverweightStatus', { payment: true }, null))
        break
    }
  }

  useEffect(() => {
    const mainPassengerOrdinal = paxOrdinal
    const passengers = itineraryInfo.passengerBagtagDetails
    const initData = getData(passengers, mainPassengerOrdinal, intl.formatMessage)
    setItineraryData(populateItineraryInfo(itineraryInfo))
    setData(initData)
  }, [locale])

  const animationSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.VerifyPaymentTitle)}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.VerifyPaymentSubTitle)}
      </PageSubTitle>
    </>
  )

  const textSection = (
    <>
      <EMBTable
        columns={columns}
        rows={buildRows(data)}
        headerHeight={50}
        rowHeight={themeContext.PassengerSelection.rowHeight[ratioKey]}
        rowFontSize={30}
        headFontSize={30}
        maxDisplayRows={themeContext.PassengerSelection.maxDisplayRows[ratioKey]}
      />
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={!appMode}
      isBackRequired={true}
      backAction={handelPayment}
      backBtnText={formatMessage(messages.buttonBypass)}
      isSkipRequired={true}
      skipAction={handelPayment}
      skipBtnText={formatMessage(messages.buttonGuestPaid)}
      isConfirmRequired={false}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default VerifyPayment
