/**
 *  Remove [] brackets from the string
 *  @param {string} str
 *  @return {string} string without square brackets
 */
export function removeArrayBrackets(str) {
  let target = str
  if (str.indexOf('[') === 0) target = str.substring(1, str.length - 1)
  return target
}

/**
 *  Return the key from the object based on the value
 *  @param {Object} source literal object
 *  @param {string|number} value
 *  @return {string|number} key of the object
 */
export function getKeyByValue(source, value) {
  let target = ''
  for (let prop in source) {
    if (source.hasOwnProperty(prop)) {
      if (source[prop] === value) target = prop
    }
  }
  return target
}

/**
 *  Return the current time
 *  @return {string} current time hh:mm:ss:ms
 */
export function getTime() {
  let myDate = new Date()
  return myDate.getHours() + ':' + myDate.getMinutes() + ':' + myDate.getSeconds() + ':' + myDate.getMilliseconds()
}

/**
 *  Debug object
 *  @param {Object} e object
 *  @return {string} object in debug form
 */
export function debugObject(e) {
  return e
}

/**
 *  Checks if the value is an integer
 *  @param {number} value to test
 *  @return {boolean} true when the value is an integer otherwise false
 */
export function isInteger(value) {
  if (undefined === value || value === null) {
    return false
  }
  return value % 1 === 0
}

/**
 *  Checks if the first argument ends with the provided suffix
 *  @param {String} str string to test
 *  @param {String} suffix string at the end
 *  @return {boolean} true when the str ends with suffix
 */
export function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Pad a number to 2 digits
export const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0')
// Get timezone offset in ISO format (+hh:mm or -hh:mm)
export const getTimezoneOffset = (date) => {
  const tzOffset = -date.getTimezoneOffset()
  const diff = tzOffset >= 0 ? '+' : '-'
  return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60)
}

export const toISOStringWithTimezone = (date) => {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  )
}
