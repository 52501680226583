export default class PlaySound {
  constructor(good, bad) {
    // console.log('good:', good)
    this.beepOKSound = new Audio()
    this.beepOKSound.src = good
    this.beepOKSound.load()
    this.beepErrSound = new Audio()
    this.beepErrSound.src = bad
    this.beepErrSound.load()
    this.enabled = true
  }

  setEnabled(value) {
    this.enabled = value
  }

  beepOK() {
    if (this.enabled) {
      let playPromise = this.beepOKSound.play()
      if (playPromise) {
        playPromise.catch(e => {
          console.log('PlaySound exception:', e)
        })
      } else {
        //console.log('play sound is not a promise.')
      }
    }
  }
  beepError() {
    if (this.enabled) {
      let playPromise = this.beepErrSound.play()
      if (playPromise) {
        playPromise.catch(e => {
          console.log('PlaySound exception:', e)
        })
      } else {
        //console.log('play sound is not a promise.')
      }
    }
  }
}
