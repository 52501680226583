/* import { useState, useRef, useEffect } from 'react'

 function useStateRef(initialValue) {
  const [value, setValue] = useState(initialValue)

  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return [value, setValue, ref]
}

export default useStateRef */

import { useState, useRef, useEffect, useCallback } from 'react'

function useStateRef(initialValue) {
  const [value, setValue] = useState(initialValue)

  const ref = useRef(value)
  const changeValue = useCallback(function(val) {
    ref.current = typeof val === "function" ?
    val(ref.current) : val
    setValue(ref.current)
  }, [])

  return [value, changeValue, ref]
}

export default useStateRef
