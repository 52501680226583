import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { PageTitle, PageSubTitle, DynamicImage } from 'components/styledComponents'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility, getSummaryStore, store, history } from 'main'
import { Footer } from 'layouts/footer'
import useMedia from 'hooks/useMedia'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useFaceService from 'custom/hooks/useFaceService'
import { updateCustomData } from 'custom/actions/customActions'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { sendPhotoDetail } from 'actions/etsTransactions/sendPhotoDetail'
import { TransitionCodes, checkTravelDocAction, PASSENGER_VALIDATION_STATUS, AcuantStatus } from 'constants/Constants'
import { logDebug, logInfo } from 'utils/Logger'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const AnalyzePhoto = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const locale = useSelector((state) => state.localData.locale)
  const dispatch = useDispatch()
  const [displayMessage, setDisplayMessage] = useState('')
  const [displayTitle, setDisplayTitle] = useState(intl.formatMessage(messages.PleaseWaitTitle))
  const currentPassenger = store.getState().paxValidation.currentPassenger
  const paxFaceImage = store.getState().paxValidation.paxFaceImage
  const location = useLocation()
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const summaryStore = getSummaryStore()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  const procesPhoto = (photoScore, photo) => {
    //update reslut in validatePaxResult
    let validateResult = store.getState().localData.validatePaxResult
    let passingScore = config.photoPassingScore
    let newValidateResults = []
    let updatedItem = null
    validateResult.forEach((item) => {
      if (item.ordinal === currentPassenger.ordinal) {
        updatedItem = item
        // updatedItem.bioCheck = compareScientificNumber(photoScore, passingScore)
        updatedItem.bioCheck =
          photoScore >= passingScore ? PASSENGER_VALIDATION_STATUS.PASSED : PASSENGER_VALIDATION_STATUS.FAILED
        newValidateResults.push(updatedItem)
      } else {
        newValidateResults.push(item)
      }
    })

    const updatePaxStatus = {
      ordinal: currentPassenger.ordinal,
      docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
      faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
      privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
    }

    summaryStore.endFacialData(updatedItem.bioCheck !== PASSENGER_VALIDATION_STATUS.PASSED)

    logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Success')
    dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
    dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
    dispatch(updateCustomData('updateTVSPhoto', null))
  }

  const faceAnalyzeCallback = (analyzeScore) => {
    if (analyzeScore && analyzeScore > config.photoPassingScore) {
      dispatch(
        sendPhotoDetail(currentPassenger.ordinal, store.getState().custom.TVSPhoto, analyzeScore, 0, false, null)
      )
      procesPhoto(analyzeScore)
    } else {
      logEvents(EventFlows.FaceMatch1, EventFunctions.FaceMatchResult, 'Fail')
      dispatch(updateCustomData('updateTVSPhoto', null))
      history.push('TakePhoto')
    }
  }

  const [
    faceServiceEnable,
    faceServiceDisable,
    cameraToggle,
    liveStreamUrl,
    faceApper,
    faceServiceCallback,
    container,
    analyze
  ] = useFaceService(faceAnalyzeCallback)

  useEffect(() => {
    analyze(paxFaceImage, store.getState().custom.TVSPhoto)
  }, [])

  // handle test buttons
  const handleTestClick = (e) => {
    logInfo('(TakePhoto.js) handleTestClick() ... ' + e.currentTarget.id)
    switch (e.currentTarget.id) {
      case 'buttonCaptured':
        faceServiceCallback({
          key: 'facePresent',
          value: null
        })
        faceServiceCallback({
          key: 'analyze',
          value: ['0.9', '2']
        })
        break
      case 'buttonFailed':
        faceServiceCallback({
          key: 'facePresent',
          value: null
        })
        faceServiceCallback({
          key: 'analyze',
          value: ['0.1', '2']
        })
        break
      default:
        logDebug('(TakePhoto.js) default url: /')
    }
  }

  // test buttons
  const testData = [
    {
      id: 'buttonCaptured',
      text: 'Captured',
      handler: handleTestClick
    },
    {
      id: 'buttonFailed',
      text: 'Failed',
      handler: handleTestClick
    }
  ]

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()

  useEffect(() => {
    handleAccessibility()
  }, [locale, displayMessage])

  const handleAccessibility = () => {
    const accDef = {
      pathName: 'pleaseWait',
      startIndex: 0,
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters: [displayTitle, displayMessage]
          }
        ]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${themeContext.AnimationPleaseWait}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.PleaseWaitTitle)}
      </PageTitle>
      {/* <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.PleaseWaitTitle)}
      </PageSubTitle> */}
    </>
  )

  const header = null

  const footer = (
    <>
      <Footer
        isQuitRequired={false}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={false}
        isHelpRequired={false}
        displayItinerary={false}
        testData={testData}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}
export default AnalyzePhoto
