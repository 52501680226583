import React, { useState } from 'react'
import { PageContent, PageTitle, PageSubContent, PageText } from 'components/styledComponents'
import { Button } from 'components'
import { history, store, getSBDAppMan } from 'main'
import { initialStore } from 'actions/etsTransactions/sessionAct'
const Inactive = (props) => {
  const testButton = config.isCUSSRequired ? (
    ''
  ) : (
    <PageSubContent>
      <Button
        id="mybutton"
        height="75px"
        justifyContent="center"
        onClick={() => {
          const aeaMgr = getSBDAppMan()
          aeaMgr.notifyActive()
          initialStore(store.dispatch)
          history.push(config.firstScreen)
        }}
      >
        Start
      </Button>
    </PageSubContent>
  )

  return (
    <PageContent flexFlow="column">
      <PageText height="100px" fontSize="40px" color="black">
        Application is initializing ...
      </PageText>
      <PageSubContent>{testButton}</PageSubContent>
    </PageContent>
  )
}
export default Inactive
