import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { DynamicImage, PageSubTitle, PageTitle } from 'components/styledComponents'
import { TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, history, playSound } from 'main'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId } from 'utils/helper'
import { turnLights } from 'utils/lights'
import { appLog } from 'utils/Logger'
import { Footer } from '../footer'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const AgentScan = (props) => {
  const intl = useIntl()
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const scannedCallback = (scannedValue) => {
    logEvents(EventFlows.AgentOverride, EventFunctions.QRScan, 'Agent QR code scanned for override')
    dispatch(genericCommand('validateAgent', { barcodeData: scannedValue }, null))
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)
  useEffect(() => {
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(AgentScan.js) call barcode enable function')
      enable()
      turnLights('kioskAgentHelp', true)
    }
    return () => {
      console.log('component is unmounted.')
      disable()
      turnLights('kioskAgentHelp', false)
    }
  }, [])
  const handleActions = (e) => {
    appLog(TraceLevels.LOG_TRACE, '(AgentScan.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"')
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'buttonScanBarcodeTEST':
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          //value: 'EMBROSSTEST'
          value: 'OverrideSBD'
        })
        break
      case 'buttonScanBarcodeTESTDamaged':
        barcodeReaderCallback({
          key: 'barcodeDamaged',
          value: null
        })
        break
      case 'buttonScanBarcodeTESTERROR':
        barcodeReaderCallback({
          key: 'barcodeReadInternal',
          value: ''
        })
        break
      case 'buttonVerifyDocuments':
        history.push('VerifyDocuments')
        break
    }
  }

  // const errIcon = <div className="errorPage_defaultIcon" />
  // const errImage = <div className="errorPage_defaultImage" />

  const testButtons = [
    {
      id: 'buttonScanBarcodeTEST',
      text: 'Scan',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonScanBarcodeTESTDamaged',
      text: 'Damaged',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonScanBarcodeTESTERROR',
      text: 'Error',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonVerifyDocuments',
      text: 'Verify Document',
      handler: handleActions,
      group: 0
    }
  ]

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const textParameters = [intl.formatMessage(messages.AgentScanTitle), intl.formatMessage(messages.AgentScanSubTitle)]
    const accDef = {
      pathName: 'AgentScan',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters }]
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  const imageLocation = `generic-agent.svg`
  const animationSection = (
    <DynamicImage imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
  )
  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.AgentScanTitle)}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.AgentScanSubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        isLangRequired={true}
        isHelpRequired={false}
        testData={testButtons}
      />
    </>
  )

  const popupSection = <>{popupContainer.retryMediaAccess}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default AgentScan
