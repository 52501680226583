import { callBagUpdate, callBagUpdateAsynch, updateActivatedBags } from 'actions/etsTransactions/bagUpdateSBD'
import { getBagDetails } from 'actions/etsTransactions/getBagDetails'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { getPrintStream } from 'actions/etsTransactions/getPrintStream'
import { startEtsTransaction } from 'actions/etsTransactions/sessionAct'
import { setBagProcessStatus, updateLocalData } from 'actions/localActions'
import {
  BAG_TXN_NOMORE,
  BaggageStatus,
  ETS_TXN_STATUS,
  PRINT_DOC_TYPE,
  SBDUpdateAction,
  TransitionCodes,
  TYPE_KIOSK,
  END_TXN_REASON
} from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { AEABagdropOnEvent } from 'devices/callbacks'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import {
  getDeviceManager,
  getEventLogger,
  getSBDAppMan,
  getSummaryStore,
  getTSDManager,
  getVersion,
  history
} from 'main'
import AEACommand from 'utils/AEACommand'
import { appLog } from 'utils/Logger'
import { EventFlows, EventFunctions, logEvents, sessionStart } from 'utils/appEventLogger'
import { startCUSSTransaction } from 'utils/appTransactions'
import {
  delay,
  findOrdinalByTag,
  format1107msg,
  formatBagTagDisplay,
  formatBagTagNumber,
  getScreenId,
  getWeightUnitFromConfig,
  goToLocalError,
  goToLocalGenericError,
  isEmpty,
  navigate
} from 'utils/helper'
import { updateError, updateLocalError } from '../actions/commonActions'
import {
  completeCUSSTransaction,
  doETSTransactionEnd,
  getEndTxtState,
  handleAppTransfer,
  sendErrorLog
} from '../utils/appTransactions'

export default class AEASBDAppManager {
  constructor(store) {
    this.store = store
    this.bagdropLimits = new Object()
    this.appFlow = -2
    this.inIngestLoop = false
    this.SQStart = -1
    this.BQStart = 0
    this.BHSXStart = 0
    this.dispatchBHSXTimer = 0 //used for max waiting time when dispatch bag but BHS is busy
    this.IntrusionStart = 0
    this.PutBagStart = 0
    this.IntrusionDuringDispatch = false
    this.bagRepositionCount = 0
    this.bagMaxWeight = 0
    this.bagMaxWeightUnit = ''
    this.delayedNavigationTimer = 0
    this.quitReason = ''
    this.quitDetail = ''
    this.store.dispatch(updateLocalData('appFlow', this.appFlow))
    this.aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
    this.aeaBagdrop.OnDeviceEvent = this.aeaBagdropCallback.bind(this)
    this.epCmdOK = false
    this.rcCmdOK = false
    this.inRevalidatingBag = false
    this.heavyBagsList = ''
    this.setDefaultBagdropLimits()
    this.tsdMgr = getTSDManager()
    this.eStopPressed = false
  }

  aeaBagdropCallback(event) {
    //appLog(TraceLevels.LOG_EXT_TRACE, 'aeaBagdropCallback() is called from client: ' + event.key)

    switch (event.key) {
      case 'commandCompleted':
        this.commandCompleted(event.value)
        break
      case 'unsolicitedMessage':
        this.unsolicitedMessage(event.value)
        break
      case 'statusResponse':
        this.status(event.value)
        break
      case 'statusChange':
        appLog(TraceLevels.LOG_EXT_TRACE, 'aeaBagdropCallback statusChange: ' + event.value)
        this.statusChange(event.value)
        break
      case 'statusIsOK':
        // used by OOS - skip here
        break
      case 'setupAEA':
      case 'sendAEA':
        this.processReturnCode(event.key, event.value)
        break
      case 'getKioskDeviceHelp':
        AEABagdropOnEvent(event)
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'aeaBagdropCallback unexpected event: ' + event.key + ' ' + event.value)
    }
  }

  notifyActive() {
    this.appFlow = this.store.getState().localData.appFlow
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.notifyActive appFlow: ' + this.appFlow)
    if (!this.epCmdOK) {
      // let EPcmd = 'EP#BSTMODE=T'
      let EPcmd = 'EP'
      if (config.cmdEPparameters !== '') {
        EPcmd = EPcmd + config.cmdEPparameters
      }
      this.aeaBagdrop.setupAEA(EPcmd, 1000)
    }
    if (!this.rcCmdOK) {
      this.aeaBagdrop.setupAEA('RC', 10000)
    }
    this.setAppFlow(0)
  }

  hostResponse() {
    appLog(TraceLevels.LOG_EXT_TRACE, 'Host response callback............')
  }

  getInIngestLoop() {
    return this.inIngestLoop
  }

  revalidateBag() {
    appLog(TraceLevels.LOG_EXT_TRACE, 'RevalidateBag - from getItinerary - pooling')
    this.inRevalidatingBag = true
    // bagtag may not be in BQ response - get the last bagtag
    appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp call BQ')
    this.setAppFlow(5)
    this.BQStart = new Date().getTime()
    this.aeaBagdrop.sendAEA('BQ', -10000)
    this.store.dispatch(setBagProcessStatus(1, true))
  }

  setAppFlow(newAppFlow) {
    this.appFlow = newAppFlow
    this.store.dispatch(updateLocalData('appFlow', this.appFlow))
  }

  //handle initialization CommandCompleted  states -2,-1 and 0
  ccInit(msg) {
    const PSAM = this.store.getState().kioskInfo.PSAM
    const cussTxnStarted = this.store.getState().sessions.cussTxnStarted
    const etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
    if (this.appFlow <= 0) {
      /*********************************************************************************************
       *   RC or EP was called                                                                      *
       *********************************************************************************************/
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'])
      switch (msg['OK']) {
        case 'RC':
          this.saveBagdropLimits(msg) // save SBD limits
          if (config.sbdPlatform === 'EMBROSS') {
            this.rcCmdOK = true
          }
          break
        case 'EP':
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp EP result BSTMODE=' + msg['BSTMODE'])
          logEvents(EventFlows.BagDrop, EventFunctions.WeightUnit, msg['BSWMODE'])
          logEvents(EventFlows.BagDrop, EventFunctions.DimensionUnit, msg['BSTSIZE'])
          if (config.sbdPlatform === 'EMBROSS') {
            this.epCmdOK = true
          }
          this.setAppFlow(0)
          break
        case 'SQ':
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
          if (msg['SD'] === 'EMCY') {
            /* goToLocalGenericError('commandCompleted appFlow 0', ErrCodes.CONVEYOR_ESTOP, 'Error_ConveyorEStop', 'OOS', {
              confirmDisable: true
            }) */
          } //else if (msg['BS'] !== '0' && (msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX')) {
          else if (
            (msg['BS'] === '1' && (msg['SD'] != 'INTR' || msg['CB'] != '5')) ||
            msg['SD'] === 'BAGE' ||
            msg['SD'] === 'BAGP'
          ) {
            // bag placed
            this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
            appLog(TraceLevels.LOG_EXT_TRACE, 'call CC#PA to get confirm message')
            let commandResult = this.sendAEACommand('CC#PA', config.loadTimeout, this.appFlow, config.firstScreen)
            if (commandResult !== -99 && commandResult !== -100) {
              navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitBagProcess' } }, 20)
            }
          } else if (msg['SD'] === 'NORM' && this.SQStart === 0) {
            //On intrusion screen && intrusion cleared
            // instead of OK button
            appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Welcome')
          } else if (msg['SD'] === 'INTR' || msg['CB'] === '5' || msg['BS'] === '4') {
            // call SQ again
            this.SQStart = -1
            appLog(TraceLevels.LOG_EXT_TRACE, 'call SQ (CCInit)')
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'CCInit')
              }.bind(this)
            )
            /* this.store.dispatch(updateLocalData('updateBqCommand', ''))
            if (this.SQStart !== -1) {
              let newTime = new Date().getTime()
              if (this.SQStart > 0 && newTime - this.SQStart > config.maxClearBagTagLookupIntrusionTime * 1000) {
                this.SQStart = 0
                this.IntrusionStart = new Date().getTime()
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  'goto --> Intrusion screen: ' + (newTime - this.SQStart) + ' IntrusionStart: ' + this.IntrusionStart
                )
                logEvents(EventFlows.BagDrop, EventFunctions.Intrusion, 'detected')
                this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
                this.stopDelayedNavigation()
                navigate({ pathname: 'Intrusion', state: { welcomeFlag: true } }, 9)
                //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
                appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from  ccInit')
                this.aeaBagdrop.sendAEA('SQ', -10000)
              } else {
                // try again SQ
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  'cmdComp call SQ again newTime: ' + newTime + ' elapsed: ' + (newTime - this.SQStart)
                )
                delay(config.delayAEAcmd).then(
                  function () {
                    this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'CCInit')
                  }.bind(this)
                )
              }
            } else if (this.SQStart === -1) {
              // there is initial intrusion and bag on the belt
              this.SQStart = new Date().getTime()
              appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQstart + ' call SQ (CCInit)')
              delay(config.delayAEAcmd).then(
                function () {
                  this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'CCInit')
                }.bind(this)
              )
            } */
            //  else {
            //   appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing SQstart - skip - wait for intrusion.')
            // }
          } else if (msg['SD'] !== 'NORM' && msg['SD'] !== 'BHSX') {
            appLog(TraceLevels.LOG_EXT_TRACE, 'SD indicates an error condition: ' + msg['SD'])
            goToLocalGenericError(
              'commandCompleted appFlow 0',
              ErrCodes.SEE_AGENT_GENERAL,
              'Error_Default',
              'END_TXN',
              null
            )
          } else if (config.repeatSQOnPutBagOnBelt > 0) {
            appLog(TraceLevels.LOG_EXT_TRACE, 'calling SQ again to wait for bag present...')
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 0, 'repeatSQOnWelcome')
              }.bind(this)
            )
          }
          break
        default:
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response: ' + msg['OK'])
      }
    } else {
      /*********************************************************************************************
       *   EP was called again                                                                     *
       *********************************************************************************************/
      if (msg['OK'] === 'EP') {
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp EP result BSTMODE=' + msg['BSTMODE'])
        this.epCmdOK = true
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response.')
      }
    }
  }

  /*********************************************************************************************
   *   SQ called - waiting to clear intrusion                                                   *
   *********************************************************************************************/
  cc_2(msg) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
    //        appLog(TraceLevels.LOG_EXT_TRACE,'current page: ' + this.getCurrentPage())
    if (msg['OK'] === 'SQ') {
      if (msg['SD'] === 'EMCY') {
        // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ESTOP, '', 'commandCompleted appFlow 2', 'OOS'))
        /* goToLocalGenericError('commandCompleted appFlow 2', ErrCodes.CONVEYOR_ESTOP, 'Error_ConveyorEStop', 'OOS', {
          confirmDisable: true
        }) */
        // history.push('error')
      } //else if (msg['BS'] !== '0' && (msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX')) {
      else if (
        (msg['BS'] === '1' && (msg['SD'] != 'INTR' || msg['CB'] != '5')) ||
        msg['SD'] === 'BAGE' ||
        msg['SD'] === 'BAGP'
      ) {
        if (msg['SD'] === 'BAGP' && msg['BS'] === '4') {
          appLog(TraceLevels.LOG_EXT_TRACE, 'bag detected but bag is out of bounds')
          this.checkMaxReposition(
            'commandCompleted appFlow 2',
            ErrCodes.BAG_UNABLE_TO_MEASURE,
            'Error_BAG_UNABLE_TO_MEASURE',
            'PutBagOnBelt',
            null
          )
        } else {
          // bag placed
          appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Bag Processing screen')
          logEvents(EventFlows.BagDrop, EventFunctions.BagDetected)
          this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
          this.setAppFlow(3)
          this.store.dispatch(updateLocalData('bagtag', null))
          this.store.dispatch(updateLocalData('bagMeasurements', null))
          this.store.dispatch(updateLocalData('bagtagObject', null))
          this.store.dispatch(updateLocalData('prevBagOnQBelt', msg['SD'] === 'BHSX'))
          navigate('BagProcessing', 3)
          // make sure that Put bag on belt was displayed config.minPutBag seconds
          /* if (history.location && history.location.pathname && history.location.pathname === '/PutBagOnBelt') {
          this.navigateWithMinDisplay('BagProcessing', config.minPutBag, 'PutBagStart')
        } */
        }
      } else if (msg['SD'] == 'BHSX') {
        //try again
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call SQ again, appFlow 2')
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'CC2')
          }.bind(this)
        )
      } else if (msg['SD'] === 'NORM' && this.SQStart === 0) {
        //On intrusion screen && intrusion cleared
        // instead of OK button
        appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Put bag on belt')
        this.SQStart = -1
        navigate('PutBagOnBelt', 2)
      } else if (msg['SD'] === 'INTR' || msg['CB'] === '5') {
        // try again SQ
        appLog(TraceLevels.LOG_EXT_TRACE, 'appFlow = 2,  ignore intrusion send SQ again')
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'CC2')
          }.bind(this)
        )
        /* this.store.dispatch(updateLocalData('updateBqCommand', ''))
        if (this.SQStart !== -1) {
          let newTime = new Date().getTime()
          if (this.SQStart > 0 && newTime - this.SQStart > config.maxClearIntrusionTime * 1000) {
            this.SQStart = 0
            this.IntrusionStart = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'goto --> Intrusion screen: ' + (newTime - this.SQStart) + ' IntrusionStart: ' + this.IntrusionStart
            )
            this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
            this.stopDelayedNavigation()
            logEvents(EventFlows.BagDrop, EventFunctions.Intrusion, 'Detected')
            navigate('Intrusion', 9)
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
            logEvents(EventFlows.BagDrop, EventFunctions.Intrusion, 'start')
            appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_2')
            this.aeaBagdrop.sendAEA('SQ', -10000)
          } else {
            // try again SQ
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'cmdComp call SQ again newTime: ' + newTime + ' elapsed: ' + (newTime - this.SQStart)
            )
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'CC2')
              }.bind(this)
            )
          }
        } else {
          // there is initial intrusion and bag on the belt
          this.SQStart = new Date().getTime()
          appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQstart + ' call SQ (CC2)')
          delay(config.delayAEAcmd).then(
            function () {
              this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'CC2')
            }.bind(this)
          )
        } */
      } else if (msg['SD'] !== 'NORM' && msg['SD'] !== 'BHSX') {
        appLog(TraceLevels.LOG_EXT_TRACE, 'SD indicates an error condition: ' + msg['SD'])
        // this.store.dispatch(updateError(ErrCodes.SEE_AGENT_GENERAL, '', 'commandCompleted appFlow 2'))
        goToLocalGenericError(
          'commandCompleted appFlow 2',
          ErrCodes.SEE_AGENT_GENERAL,
          'Error_Default',
          'END_TXN',
          null
        )
        // history.push('error')
      } else if (config.repeatSQOnPutBagOnBelt > 0) {
        appLog(TraceLevels.LOG_EXT_TRACE, 'calling SQ again to wait for bag present...')
        //this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'repeatSQOnPutBagOnBelt')
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, 2, 'repeatSQOnPutBagOnBelt')
          }.bind(this)
        )
        //         } else if (msg['SD'] == 'NORM' && this.SQStart > 0) {  intrusion cleared without going to intrusion page
        //       	appLog(TraceLevels.LOG_EXT_TRACE,'clear intrusion timer.')
        //           this.SQStart = -1
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response: ' + msg['OK'])
    }
  }

  /*********************************************************************************************
   *   CC#PA was called - only PROK#P expected  (BGNO can happen with delayed navigation)      *
   *********************************************************************************************/
  cc_4(msg) {
    if (msg['ERR'] != null) {
      let errMsg = ''
      let errcode = msg['ERR']
      let msgCode = ''
      let params = null
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp: ' + errcode)

      // handle SITA HDCERR2CC
      if (errcode.indexOf('ERR2') === 0) {
        appLog(TraceLevels.LOG_EXT_TRACE, `ERR2 error, CC#C1 reverse bag, app flow ${this.appFlow}`)
        this.disableISBD()
        return
      }

      if (errcode.indexOf('ERR5#') === 0) {
        /*let param0 = ''
        let param1 = ''*/
        errcode = errcode.substring(5)
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp errorcode: ' + errcode)
        if (errcode === 'BGNO') {
          //errMsg = ErrCodes.BAG_MISSING
          logEvents(EventFlows.BagDrop, EventFunctions.BagError, 'bag missing')
          appLog(TraceLevels.LOG_EXT_TRACE, 'CC#PA BGNO --> Put bag on belt')
          this.SQStart = -1
          navigate('PutBagOnBelt', 2)
          return
        } else if (errcode === 'OVLT') {
          if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_LONG_ADDBAG
            msgCode = 'Error_BagLongAddBag'
          } else {
            errMsg = ErrCodes.BAG_LONG
            msgCode = 'Error_BagLong'
          }
        } else if (errcode === 'BAGJ') {
          errMsg = ErrCodes.BAG_JAMMED
          msgCode = 'Error_BagJammed'
        } else if (errcode === 'OVWT') {
          if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_MAX_WEIGHT_ADDBAG
            msgCode = 'Error_BagOverweightMaxAddBag'
          } else {
            errMsg = ErrCodes.BAG_MAX_WEIGHT
            msgCode = 'Error_BagOverweightMax'
          }
          params = {
            maxWeight: this.bagdropLimits.upperScaleLimit,
            weightUnit: config.customBagWeightUnit ? config.customBagWeightUnit : this.bagdropLimits.measurement
          }
        } else if (errcode === 'OVWU') {
          if (config.useBinForSmallBags) {
            errMsg = ErrCodes.BAG_UNDERWEIGHT_USEBIN
            msgCode = 'Error_BagUnderweightUseBin'
          } else if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_UNDERWEIGHT_ADDBAG
            msgCode = 'Error_BagUnderweightAddBag'
          } else {
            errMsg = ErrCodes.BAG_UNDERWEIGHT
            msgCode = 'Error_BagUnderweight'
          }
          params = {
            minWeight: this.bagdropLimits.lowerScaleLimit,
            weightUnit: config.customBagWeightUnit ? config.customBagWeightUnit : this.bagdropLimits.measurement
          }
        } else if (errcode === 'OVFL') {
          if (config.useBinForSmallBags) {
            errMsg = ErrCodes.BAG_UNDERHEIGHT_USEBIN
            msgCode = 'Error_BagUnderheightUseBin'
          } else if (this.getNumberOfNotActivatedBags() > 1) {
            errMsg = ErrCodes.BAG_UNDERHEIGHT_ADDBAG
            msgCode = 'Error_BagUnderheightAddBag'
          } else {
            errMsg = ErrCodes.BAG_UNDERHEIGHT
            msgCode = 'Error_BagUnderheight'
          }
        } else if (errcode === 'BGMV') {
          errMsg = ErrCodes.BAG_UNSTABLE
          msgCode = 'Error_UnableToWeigh'
        } else if (errcode === 'CBIO' || errcode === 'UNKN') {
          errMsg = ErrCodes.CONVEYOR_ERROR
          msgCode = 'Error_HWError'
        } else if (errcode === 'BHSS') {
          errMsg = ErrCodes.BHS_ERROR
          msgCode = 'Error_HWError'
        } else if (errcode !== 'OVHG' && errcode !== 'BGPR' && errcode !== 'OVSH' && errcode !== 'INTR') {
          errMsg = ErrCodes.SEE_AGENT_GENERAL
          msgCode = 'Error_Default'
        }

        if (errMsg !== '')
          this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 4', 'END_TXN', params))
        // this.store.dispatch(updateError(errMsg, '', 'commandCompleted appFlow 4', param0, param1))
      }
      // TODO: why set appFlow = 0? doesn't make scense.
      //this.appFlow = 0
      //this.store.dispatch(updateLocalData('appFlow', this.appFlow))
      if (errcode === 'OVHG') {
        // this.store.dispatch(updateError(ErrCodes.BAG_TOO_TALL, '', 'commandCompleted appFlow 4'))
        this.checkMaxReposition(
          'commandCompleted appFlow 4',
          ErrCodes.BAG_TOO_TALL,
          'Error_BagTooTall',
          'PutBagOnBelt',
          null
        )
      } else if (errcode === 'OVSH') {
        // this.store.dispatch(updateError(ErrCodes.BAG_SHORT, '', 'commandCompleted appFlow 4'))
        let tempmsgCode = config.useBinForSmallBags ? 'RepositionBag4' : 'RepositionBag3'
        this.checkMaxReposition('commandCompleted appFlow 4', ErrCodes.BAG_SHORT, tempmsgCode, 'PutBagOnBelt', null)
      } else if (errcode === 'BGPR') {
        // this.store.dispatch(updateError(ErrCodes.BAG_NOSCAN, '', 'commandCompleted appFlow 4'))
        // this.checkMaxReposition(
        //   'commandCompleted appFlow 4',
        //   ErrCodes.BAG_NOSCAN,
        //   'RepositionBag1',
        //   'PutBagOnBelt',
        //   null
        // )
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call BQ ')
        this.setAppFlow(5)
        this.BQStart = new Date().getTime()
        //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
        appLog(TraceLevels.LOG_EXT_TRACE, '==> send BQ ')
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
        this.aeaBagdrop.sendAEA('BQ', -10000)
        this.store.dispatch(updateLocalData('bagtag', null))
        this.store.dispatch(setBagProcessStatus(1, true))
        return
      } else if (errcode === 'TOMB') {
        // this.store.dispatch(updateError(ErrCodes.MULTIPLE_BAGS, '', 'commandCompleted appFlow 4'))
        appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
        // this.setAppFlow(2)
        goToLocalError(
          'commandCompleted appFlow 4',
          ErrCodes.MULTIPLE_BAGS,
          'ErrorMismatch3',
          'PutBagOnBelt',
          null,
          'ErrorMismatch'
        )
      } else if (
        errMsg == ErrCodes.BAG_MAX_WEIGHT_ADDBAG ||
        errMsg == ErrCodes.BAG_LONG_ADDBAG ||
        errMsg == ErrCodes.BAG_UNDERWEIGHT_ADDBAG ||
        errMsg == ErrCodes.BAG_UNDERWEIGHT_USEBIN ||
        errMsg == ErrCodes.BAG_UNDERHEIGHT_ADDBAG ||
        errMsg == ErrCodes.BAG_UNDERHEIGHT_USEBIN
      ) {
        this.checkMaxReposition('commandCompleted appFlow 4', errMsg, msgCode, 'PutBagOnBelt', params)
      } else if (errcode === 'INTR') {
        this.store.dispatch(updateLocalData('updateBqCommand', ''))
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.IntrusionStart = new Date().getTime()
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          'wait for intrusion to clear (load) -> goto Intrusion IntrusionStart: ' + this.IntrusionStart
        )
        this.stopDelayedNavigation()
        logEvents(EventFlows.BagDrop, EventFunctions.Intrusion, 'Detected')
        navigate('Intrusion', 9)
        //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
        appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_4')
        this.aeaBagdrop.sendAEA('SQ', -10000)
      } else {
        navigate('error', 10)
      }
    } else if (msg['OK'] === 'PR') {
      if (msg['CC'].split('#')[1].includes('C')) {
        // handle SITA HDCERR2CC
        appLog(TraceLevels.LOG_EXT_TRACE, `call CC#PA to get confirm message, ${msg['CC']}, app flow ${this.appFlow}`)
        this.sendAEACommand('CC#PA', config.loadTimeout, this.appFlow, config.firstScreen)
      } else {
        //only response to CCPA
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call BQ ')
        this.setAppFlow(5)
        this.BQStart = new Date().getTime()
        //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
        appLog(TraceLevels.LOG_EXT_TRACE, '==> send BQ')
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
        this.aeaBagdrop.sendAEA('BQ', -10000)
        this.store.dispatch(updateLocalData('bagtag', null))
        this.store.dispatch(setBagProcessStatus(1, true))
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected OK=' + msg['OK'])
    }
  }

  /*********************************************************************************************
   *   BQ was called then SQ was called to check if 'ready to induct' BHSX                     *
   *********************************************************************************************/
  cc_5(msg) {
    let bagMeasurements = ''
    let bagTagObject = ''
    let heavyTag = ''
    let heavytagAttached = false
    const summaryStore = getSummaryStore()

    if (msg['OK'] !== 'BQ' && msg['OK'] !== 'SQ') {
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected OK=' + msg['OK'])
      return
    }
    /***********************************************
     *  response to BQ
     ***********************************************/
    if (msg['OK'] === 'BQ') {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'cmdComp OK=' +
          msg['OK'] +
          ' LP=' +
          msg['LP'] +
          ' HT=' +
          msg['HT'] +
          ' LT=' +
          msg['LT'] +
          ' SW=' +
          msg['SW'] +
          ' CW=' +
          msg['CW'] +
          ' BS=' +
          msg['BS']
      )
      //deal with heavy tag
      const heavyCode = '0000011111'
      if (msg['LP'] && msg['LP'].indexOf(heavyCode) > -1) {
        heavytagAttached = true
        const tagParts = msg['LP'].split('$')
        if (tagParts.length > 1) {
          if (tagParts[0].indexOf(heavyCode) > -1) {
            heavyTag = tagParts[0]
            msg['LP'] = tagParts[1]
          } else {
            heavyTag = tagParts[1]
            msg['LP'] = tagParts[0]
          }
        }
        if (msg['LP'] === heavyCode) {
          msg['LP'] = null
        }
      }
      const tubUsed = msg['BT'] === '1'
      logEvents(EventFlows.BagDrop, EventFunctions.TubUsed, tubUsed ? 'Yes' : 'No')
      this.store.dispatch(updateLocalData('updateTubUsed', tubUsed))

      if (msg['SW'] === '5' || msg['SW'] === '6') {
        let maxWeight = 40
        let weightUnit = config.customBagWeightUnit
          ? config.customBagWeightUnit
          : this.bagdropLimits.measurement
          ? this.bagdropLimits.measurement
          : 'KG'
        //Bag weight exceeds scale upper capacity limit
        if (msg['SW'] === '5' && this.bagdropLimits.upperScaleLimit) {
          // by JIRA ticket BDA-190. this condition will never meet in platform layer. therefore, this block will never use.
          // maxWeight = this.bagdropLimits.upperScaleLimit
          //Bag weight exceeds airport BHS belt capacity limit
        } else if (msg['SW'] === '6' && this.bagdropLimits.upperScaleLimit) {
          maxWeight = this.bagdropLimits.upperScaleLimit
        }
        // check if more bags can be processed
        // add the current bagtag to heavy bags list
        if (msg['LP'] && msg['LP'] !== 'ERR' && msg['LP'].length <= 10) {
          this.heavyBagsList = this.heavyBagsList + formatBagTagDisplay(msg['LP']) + ','
        }

        //TODO: for sure the logic here is not right !
        if (this.getNumberOfNotActivatedBags() > 1) {
          // this.store.dispatch(
          //   updateError(ErrCodes.BAG_MAX_WEIGHT_ADDBAG, '', 'commandCompleted appFlow 5', maxWeight, weightUnit)
          // )
          this.checkMaxReposition(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_MAX_WEIGHT_ADDBAG,
            'Error_BagOverweightMaxAddBag',
            'PutBagOnBelt',
            { maxWeight: maxWeight, weightUnit: weightUnit }
          )
        } else {
          this.setAppFlow(0)
          // this.store.dispatch(
          //   updateError(ErrCodes.BAG_MAX_WEIGHT, '', 'commandCompleted appFlow 5', maxWeight, weightUnit)
          // )
          goToLocalGenericError(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_MAX_WEIGHT,
            'Error_BagOverweightMax',
            'END_TXN',
            { maxWeight: maxWeight, weightUnit: weightUnit }
          )
          // history.push('error')
        }
      } else if (msg['SW'] === '0') {
        this.setAppFlow(0)
        // this.store.dispatch(updateError(ErrCodes.BAG_MISSING, '', 'commandCompleted appFlow 5'))
        goToLocalGenericError(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_MISSING,
          'Error_BagMissing',
          'PutBagOnBelt',
          null
        )
        logEvents(EventFlows.BagDrop, EventFunctions.BagError, 'bag missing')
        // history.push('error')
      } else if (msg['SW'] === '2') {
        // unstable weight
        if (this.BQStart > 0) {
          let newTime = new Date().getTime()
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            'cmdComp BQStart=' +
              this.BQStart +
              ' newTime=' +
              newTime +
              ' timeout=' +
              config.maxWaitTimeForStableWeight * 1000
          )
          if (newTime - this.BQStart > config.maxWaitTimeForStableWeight * 1000) {
            this.setAppFlow(0)
            logEvents(EventFlows.BagDrop, EventFunctions.BagError, 'Error msg: Bag unstable')
            // this.store.dispatch(updateError(ErrCodes.BAG_UNSTABLE, '', 'commandCompleted appFlow 5'))
            goToLocalGenericError(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_UNSTABLE,
              'Error_UnableToWeigh',
              'END_TXN',
              null
            )
            // history.push('error')
          } else {
            // try again BQ
            appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp call BQ again')
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
            this.aeaBagdrop.sendAEA('BQ', -10000)
          }
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing BQStart')
        }
      } else if (msg['SW'] === '4') {
        // weight is stable
        const manualScanMode = this.store.getState().deviceInfo.manualScanMode
        let currBagtag = msg['LP']
        if (manualScanMode) {
          if (currBagtag === 'ERR') {
            currBagtag = this.store.getState().localData.scannedBagtag
            this.inRevalidatingBag = false
          }
        } else {
          if (this.inRevalidatingBag && currBagtag === 'ERR') {
            currBagtag = this.store.getState().localData.bagtag
            this.inRevalidatingBag = false
          }
        }
        const targetOrdinal = findOrdinalByTag(msg['LP'])
        if (targetOrdinal !== null) {
          summaryStore.startBagData(
            targetOrdinal,
            msg['LP'],
            parseInt(msg['CW'].substring(0, msg['CW'].length - 2)),
            msg['CW'].substring(msg['CW'].length - 2),
            parseInt(msg['HT'].substring(0, msg['HT'].length - 2)),
            parseInt(msg['LT'].substring(0, msg['LT'].length - 2)),
            parseInt(msg['WT'].substring(0, msg['WT'].length - 2)),
            this.bagdropLimits.dimensions === 'M' ? 'CM' : 'IN'
          )
          summaryStore.CurrentBagtag = msg['LP']
          logEvents(EventFlows.BagDrop, EventFunctions.BagTag, msg['LP'])
          logEvents(
            EventFlows.BagDrop,
            EventFunctions.BagDimensions,
            'width=' + msg['WT'] + ' height=' + msg['HT'] + ' length=' + msg['LT']
          )
          logEvents(EventFlows.BagDrop, EventFunctions.BagWeight, msg['CW'])
        }
        // weight is stable
        const bagWeightNumber = msg['CW'].slice(0, -2)
        this.store.dispatch(updateLocalData('updateBagWeight', bagWeightNumber))
        this.store.dispatch(setBagProcessStatus(2))

        if (!this.validateBagtag(currBagtag))
          // exit if not valid (navigation started in the validation code if errors)
          return

        this.store.dispatch(setBagProcessStatus(2, true))
        //			displayBagdropLimits()

        // msg['BT'] value meaning:
        // 0 - no tub
        // 1 - tub detected
        // 2 - bag is not conveyable needs tub

        let tubStatus = msg['BT']

        // tubProvided value:
        // true - airport provide tub for pax
        // false - airport don't provide tub
        let tubProvided = true
        let tubUsed = msg['BT'] === '1'

        if (tubUsed) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'TUB used - skip underweight, too short and too flat.')
        }

        if (
          !this.validateWeightAndDimensions(
            msg['CW'],
            msg['HT'],
            msg['LT'],
            msg['WT'],
            tubStatus,
            tubProvided,
            currBagtag
          )
        ) {
          // exit if not valid (navigation started in the validation code if errors)
          return
        }

        //Dispatch bag - section
        // check if the previous bag is still being processed - (Q - belt cleared) config param - do several checks (delay)

        // disable bagtag scanner
        if (config.callCW === 'Y') {
          //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CW', -10000)
          this.aeaBagdrop.sendAEA('CW', -10000)
          appLog(TraceLevels.LOG_EXT_TRACE, '==> Disable scanner CW')
        }
        // send Bag info to host - just before dispatch
        let width = msg['WT']
        if (width == null) width = -1

        bagMeasurements = [msg['CW'], msg['HT'], msg['LT'], width, tubUsed]
        this.store.dispatch(updateLocalData('bagMeasurements', bagMeasurements))
        this.store.dispatch(updateLocalData('updateBqCommand', msg))

        if (this.store.getState().localData.prevBagOnQBelt) {
          //execute SQ as there was a bag on Q belt (could be gone by now)
          this.BHSXStart = 0
          //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
          this.aeaBagdrop.sendAEA('SQ', -10000)
          appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ (CC5)')
          this.store.dispatch(setBagProcessStatus(3))
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'call BAG VERIFY ---------->')
          this.store.dispatch(setBagProcessStatus(4))
          bagTagObject = this.store.getState().localData.bagtagObject //set in validateBagtag
          let overCode = bagTagObject ? this.getOverrideCode(bagTagObject.baseTagNo) : null
          // redirect to accept bag screen
          //navigate('AcceptingBag')
          //check if heavytag attached
          //let heavytagAttached = false
          if (msg['BC'] && msg['BC'].indexOf('HEAVY') > -1) {
            heavytagAttached = true
          }
          delay(config.delayBagUpdateCall).then(function () {
            callBagUpdate(bagTagObject, SBDUpdateAction.VERIFY, bagMeasurements, overCode, heavytagAttached)
          })
          // if all OK then  host response should execute CC#RA
        }
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp incorrect SW status - ')
      }
      /***********************************************
       *  response to SQ - waiting for BHSX to clear - timeout
       ***********************************************/
    } else {
      //add more code to analyze possible errors
      if (msg['SD'] !== 'BHSX') {
        //- prev bag gone - can execute dispatch
        this.store.dispatch(setBagProcessStatus(3, true))
        this.store.dispatch(setBagProcessStatus(4))
        bagTagObject = this.store.getState().localData.bagtagObject //set in validateBagtag
        bagMeasurements = this.store.getState().localData.bagMeasurements //set in validateBagtag
        let overCode = bagTagObject ? this.getOverrideCode(bagTagObject.baseTagNo) : null
        if (bagMeasurements && bagTagObject) {
          delay(config.delayBagUpdateCall).then(function () {
            callBagUpdate(bagTagObject, SBDUpdateAction.VERIFY, bagMeasurements, overCode)
          })
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'ignore SQ command, waiting BQ response')
        }
      } else if (msg['SD'] === 'BHSX') {
        // check if previous bag gone  - loop
        appLog(TraceLevels.LOG_EXT_TRACE, 'CC5 call SQ when SD=BHSX BHSXStart: ' + this.BHSXStart)
        navigate('PleaseWait')
        if (this.BHSXStart === 0) {
          delay(config.delayAEAcmd).then(
            function () {
              this.sendAEACommand('SQ', config.defaultAeaTimeout, 5, 'CC5-1')
            }.bind(this)
          )
          this.BHSXStart = new Date().getTime()
        } else if (this.BHSXStart > 0) {
          let newTime = new Date().getTime()
          if (newTime - this.BHSXStart > config.maxClearQBelt * 1000) {
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'config.maxClearQBelt elapsed ' + config.maxClearQBelt + ' goto --> error screen '
            )
            this.BHSXStart = -1
            // this.store.dispatch(updateError(ErrCodes.SEE_AGENT_GENERAL, '', 'maxClearQBelt'))
            goToLocalGenericError('maxClearQBelt', ErrCodes.SEE_AGENT_GENERAL, 'Error_Default', 'END_TXN', null)
            // history.push('error')
          } else {
            // try again SQ
            appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call SQ again (CC5)')
            delay(config.delayAEAcmd).then(
              function () {
                this.sendAEACommand('SQ', config.defaultAeaTimeout, 5, 'CC5-2')
              }.bind(this)
            )
          }
        }
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'SQ missing BHSX ')
      }
    }
  }

  /*********************************************************************************************
   *   CC#RA was called                                                                        *
   *********************************************************************************************/
  releaseBag() {
    let bagTagObject = ''
    let bagMeasurements = ''
    const summaryStore = getSummaryStore()
    appLog(TraceLevels.LOG_EXT_TRACE, 'call BAG_ACCEPTED ---------->')
    this.store.dispatch(setBagProcessStatus(5))
    this.resetIntrusionDuringDispatch()
    bagTagObject = this.store.getState().localData.bagtagObject
    bagMeasurements = this.store.getState().localData.bagMeasurements
    let overCode = bagTagObject ? this.getOverrideCode(bagTagObject.baseTagNo) : null
    logEvents(EventFlows.BagDrop, EventFunctions.BagInducted, bagTagObject.baseTagNo)
    const bagsCount = this.store.getState().localData.numberOfProcessedBags
    this.store.dispatch(updateLocalData('numberOfProcessedBags', bagsCount + 1))
    this.store.dispatch(updateLocalData('updateBqCommand', ''))
    if (config.activeBagFirst) {
      summaryStore.updateBagData(summaryStore.currentBagtag, 'inducted', true)
      summaryStore.CurrentBagtag = null
      summaryStore.updateBagDispath()
      let nextTransition = ''
      let printData = ''
      this.store.dispatch(updateLocalData('updateBagWeight', '0'))
      this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
      this.store.dispatch(setBagProcessStatus(5, true))
      // send message to SBDTRACK - bag activated
      let bagtag = this.store.getState().localData.bagtag
      let itineraryInfo = this.store.getState().responses.itineraryInfo
      let bagData = this.store.getState().localData.bagObj
      let msg = format1107msg(bagtag, itineraryInfo, bagData)
      this.store.dispatch(updateLocalData('BagsDetail', bagData))
      this.store.dispatch(updateLocalData('transactionDetail', msg))
      appLog(TraceLevels.LOG_EXT_TRACE, '==> CDS_APPLOG,1107,' + msg)
      getDeviceManager()
        .getAppManager()
        .sendApplicationLog(100, 'CDS_APPLOG,1107,' + msg)
      updateActivatedBags(bagtag)
      updateLocalData('overrideCode', {}) // reset override code
      getSBDAppMan().resetRepositionCount() // bag reposition count per bag - comment it out if per transaction
      const transition = this.store.getState().sessions.transition
      if (transition === TransitionCodes.GET_BAGGAGE_DETAILS) {
        this.store.dispatch(updateLocalData('appFlow', 8))
        this.store.dispatch(getBagDetails())
      } else if (transition === TransitionCodes.QUESTION_FOR_NEXT_BAG) {
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          '(bagUpdateSBD.js) transition: QUESTION_FOR_NEXT_BAG, ask passenger is next bag require'
        )
        nextTransition = 'NEXT_BAG_QUESTION'
        navigate('NextBagQuestion', 8)
      } else if (transition === TransitionCodes.GET_SBD_RECEIPT) {
        appLog(TraceLevels.LOG_EXT_TRACE, '(bagUpdateSBD.js) transition: GET_SBD_RECEIPT')
        this.store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
      } else if (printData != '') {
        nextTransition = 'HANDLE_BAG_INGESTION'
      } else if (transition === TransitionCodes.END) {
        appLog(TraceLevels.LOG_EXT_TRACE, 'TransitionCodes END, host response goto --> Closing Remarks')
        delay(config.delayBagActivatedCheckmark).then(() => {
          this.store.dispatch(updateLocalData('appFlow', 8))
          if (config.enablePrintReceipt) {
            this.store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
          } else {
            nextTransition = 'CLOSING_REMARKS'
            navigate('ClosingRemarks', 8)
          }
        })
      } else if (config.asynchBagProcessing === 'F') {
        if (config.disableValidateBagtag) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Put Bag on Belt')

          delay(config.delayBagActivatedCheckmark).then(() => {
            nextTransition = 'PROCESS_BAG'
            navigate('PutBagOnBelt', 2)
          })
        } else if (getSBDAppMan().getNumberOfNotActivatedBags() == 0) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Closing Remarks')
          delay(config.delayBagActivatedCheckmark).then(() => {
            this.store.dispatch(updateLocalData('appFlow', 8))
            if (config.enablePrintReceipt) {
              this.store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
            } else {
              nextTransition = 'CLOSING_REMARKS'
              navigate('ClosingRemarks', 8)
            }
          })
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'host response goto --> Put Bag on Belt')

          delay(config.delayBagActivatedCheckmark).then(() => {
            nextTransition = 'PROCESS_BAG'
            navigate('PutBagOnBelt', 2)
          })
        }
      } else {
        //handleBagIngestion.delay(config.delaySbdCall);
        this.store.dispatch(updateLocalData('appFlow', 7))
        getDeviceManager().getDevice(deviceIds.AEA_BAGDROP).status()
      }
      this.store.dispatch(setBagProcessStatus(0, true))
    } else {
      delay(config.delayBagUpdateCall).then(function () {
        callBagUpdate(bagTagObject, SBDUpdateAction.ACCEPTED, bagMeasurements, overCode)
      })
      this.store.dispatch(updateLocalData('appFlow', 6))
      // host response will decide where to go next: Closing Remarks, Print or Put Bag
    }
  }
  cc_6(msg) {
    let bagTagObject = ''
    let bagMeasurements = ''
    const summaryStore = getSummaryStore()

    if (msg['ERR'] != null) {
      let errcode = msg['ERR']
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp: ' + errcode)
      if (errcode.indexOf('ERR5#') === 0) {
        errcode = errcode.substring(5)
        let errMsg = ''
        let msgCode = ''
        let transitionPage = ''
        let params = null
        if (errcode === 'BGNO') {
          errMsg = ErrCodes.BAG_MISSING
          msgCode = 'Error_BagMissing'
          transitionPage = 'END_TXN'
        } else if (errcode === 'OVLT') {
          errMsg = ErrCodes.BAG_LONG
          msgCode = 'Error_BagLong'
          transitionPage = 'END_TXN'
        } else if (errcode === 'BAGJ') {
          errMsg = ErrCodes.BAG_JAMMED
          msgCode = 'Error_BagJammed'
          transitionPage = 'OOS'
        } else if (errcode === 'OVWT') {
          errMsg = ErrCodes.BAG_MAX_WEIGHT
          msgCode = 'Error_BagOverweightMax'
          transitionPage = 'END_TXN'
          params = {
            maxWeight: this.bagdropLimits.upperScaleLimit,
            weightUnit: config.customBagWeightUnit ? config.customBagWeightUnit : this.bagdropLimits.measurement
          }
        } else if (errcode === 'OVWU') {
          errMsg = ErrCodes.BAG_UNDERWEIGHT
          msgCode = 'Error_BagUnderweight'
          transitionPage = 'END_TXN'
          params = {
            minWeight: this.bagdropLimits.lowerScaleLimit,
            weightUnit: this.bagdropLimits.measurement
          }
        } else if (errcode === 'BHSX') {
          // //previous bag on queue belt
          if (this.IntrusionDuringDispatch) {
            appLog(TraceLevels.LOG_EXT_TRACE, 'Dispatch --> Put bag on belt (IntrusionDuringDispatch)')
            this.SQstart = -1
            navigate('PutBagOnBelt', 2)
            return
          } else {
            if (this.dispatchBHSXTimer == 0) {
              this.dispatchBHSXTimer = new Date().getTime()
              delay(config.delayDispatchRetry).then(() => {
                appLog(TraceLevels.LOG_EXT_TRACE, 'send CC#RA again...')
                if (config.isCUSSRequired) {
                  this.sendAEACommand('CC#RA', config.defaultAeaTimeout, this.appFlow, 'CC6-BHSX')
                }
              })
              return
            } else if (new Date().getTime() - this.dispatchBHSXTimer < config.maxRetryBHSX * 1000) {
              delay(config.delayDispatchRetry).then(() => {
                appLog(TraceLevels.LOG_EXT_TRACE, 'send CC#RA again...')
                if (config.isCUSSRequired) {
                  this.sendAEACommand('CC#RA', config.defaultAeaTimeout, this.appFlow, 'CC6-BHSX')
                }
              })
              return
            } else {
              errMsg = ErrCodes.BHS_ERROR
              msgCode = 'Error_BHSX_Timeout'
              transitionPage = 'END_TXN'
            }
          }
        } else if (errcode === 'CBIO' || errcode === 'UNKN') {
          errMsg = ErrCodes.CONVEYOR_ERROR
          msgCode = 'Error_HWError'
          transitionPage = 'END_TXN'
        } else if (errcode !== 'OVHG' && errcode !== 'OVSH' && errcode !== 'INTR') {
          errMsg = ErrCodes.SEE_AGENT_GENERAL
          msgCode = 'Error_Default'
          transitionPage = 'END_TXN'
        }
        if (errMsg !== '')
          this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 6', transitionPage, params))
        if (transitionPage !== 'END_TXN') {
          logEvents(EventFlows.BagDrop, EventFunctions.BagError, errMsg)
        }
        // this.store.dispatch(updateError(errMsg, '', 'commandCompleted appFlow 6'))
      }
      //this.setAppFlow(0) /// ?????
      if (errcode === 'OVHG') {
        // this.store.dispatch(updateError(ErrCodes.BAG_TOO_TALL, '', 'commandCompleted appFlow 6'))
        this.checkMaxReposition(
          'commandCompleted appFlow 6',
          ErrCodes.BAG_TOO_TALL,
          'Error_BagTooTall',
          'PutBagOnBelt',
          null
        )
      } else if (errcode === 'OVSH') {
        // this.store.dispatch(updateError(ErrCodes.BAG_SHORT, '', 'commandCompleted appFlow 6'))
        let tempmsgCode = config.useBinForSmallBags ? 'RepositionBag4' : 'RepositionBag3'
        this.checkMaxReposition('commandCompleted appFlow 6', ErrCodes.BAG_SHORT, tempmsgCode, 'PutBagOnBelt', null)
      } else if (errcode === 'INTR') {
        this.store.dispatch(updateLocalData('updateBqCommand', ''))
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.IntrusionStart = new Date().getTime()
        appLog(
          TraceLevels.LOG_EXT_TRACE,
          'wait for intrusion to clear (dispatch) IntrusionStart: ' + this.IntrusionStart
        )
        this.stopDelayedNavigation()
        logEvents(EventFlows.BagDrop, EventFunctions.Intrusion, 'Detected')
        navigate('Intrusion', 9)
        //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
        appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_6')
        this.aeaBagdrop.sendAEA('SQ', -10000)
      } else {
        navigate('error', 10)
      }
    } else if (msg['CC'] === 'PROK#R0') {
      this.releaseBag()
    } else {
      // how to deal with PROK#B
      console.log(
        'cc_6: receive PROK#B,' +
          'Baggage move ok, but not yet released due to BHS not allowing it to be fed into system'
      )
    }
  }

  /*********************************************************************************************
   *   SQ called - waiting to clear intrusion (from Bag processing)                            *
   *********************************************************************************************/
  cc_9(msg) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp OK=' + msg['OK'] + ' BS=' + msg['BS'] + ' SD=' + msg['SD'])
    if (msg['OK'] === 'SQ') {
      if (msg['SD'] === 'EMCY') {
        //this.store.dispatch(updateError(ErrCodes.CONVEYOR_ESTOP, '', 'commandCompleted appFlow 9', 'OOS'))
        /* goToLocalGenericError('commandCompleted appFlow 9', ErrCodes.CONVEYOR_ESTOP, 'Error_ConveyorEStop', 'OOS', {
          confirmDisable: true
        }) */
        // history.push('error')
      } //else if (msg['BS'] !== '0' && (msg['SD'] === 'BAGE' || msg['SD'] === 'BAGP' || msg['SD'] === 'BHSX')) {
      else if (
        (msg['BS'] === '1' && (msg['SD'] != 'INTR' || msg['CB'] != '5')) ||
        msg['SD'] === 'BAGE' ||
        msg['SD'] === 'BAGP'
      ) {
        this.resetIntrusionDuringDispatch()
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
        logEvents(EventFlows.BagDrop, EventFunctions.Intrusion, 'cleared')
        if (history.location.state && history.location.state.welcomeFlag) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Welcome screen')
          this.setAppFlow(0)
          // make sure that Intrusion was displayed config.minIntrusion seconds
          this.navigateWithMinDisplay(config.firstScreen, config.minIntrusion, 'IntrusionStart')
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Bag Processing screen')
          this.setAppFlow(3)
          this.store.dispatch(updateLocalData('prevBagOnQBelt', msg['SD'] === 'BHSX'))
          // make sure that Intrusion was displayed config.minIntrusion seconds
          this.navigateWithMinDisplay('BagProcessing', config.minIntrusion, 'IntrusionStart')
        }
        // } else if (msg['BS'] === '4') {
        //   // out of bounds handle
        //   this.BSErrorHandle(msg, 'commandCompleted appFlow 9', ErrCodes.OUT_OF_BOUNDS, 'Error_OutOfBounds')
        // } else if (msg['BS'] === '2') {
        //   // multi bag error handle
        //   this.BSErrorHandle(msg, 'commandCompleted appFlow 9', ErrCodes.MULTIPLE_BAGS, 'Error_MultipleBags')
      } else if (msg['SD'] === 'BHSX' || msg['SD'] === 'INTR' || msg['CB'] === '5' || msg['BS'] === '4') {
        //try again
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp call SQ again (CC9)')
        delay(config.delayAEAcmd).then(
          function () {
            this.sendAEACommand('SQ', config.defaultAeaTimeout, 9, 'CC9')
          }.bind(this)
        )
      } else if (msg['SD'] === 'NORM') {
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.resetIntrusionDuringDispatch()
        if (history.location.state && history.location.state.welcomeFlag) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion NO BAG --> Welcome screen')
          this.setAppFlow(0)
          // make sure that Intrusion was displayed config.minIntrusion seconds
          this.navigateWithMinDisplay(config.firstScreen, config.minIntrusion, 'IntrusionStart')
        } else {
          //NO BAG
          // instead of OK button
          appLog(TraceLevels.LOG_EXT_TRACE, 'Intrusion --> Put bag on belt')
          this.setAppFlow(2)
          this.SQstart = -1
          this.navigateWithMinDisplay('PutBagOnBelt', config.minIntrusion, 'IntrusionStart')
        }
      } else if (msg['SD'] !== 'NORM') {
        // this.store.dispatch(updateError(ErrCodes.SEE_AGENT_GENERAL, '', 'maxClearQBelt'))
        goToLocalGenericError('maxClearQBelt', ErrCodes.SEE_AGENT_GENERAL, 'Error_Default', 'END_TXN', null)
        // history.push('error')
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '')
    }
  }

  /*********************************************************************************************
   *   CC#PA called - Welcome screen bagtag lookup process                                      *
   *********************************************************************************************/
  cc_20(msg) {
    const cussTxnStarted = this.store.getState().sessions.cussTxnStarted
    const etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
    if (config.isBagtagLookupEnable) {
      if (msg['ERR'] != null) {
        let errcode = msg['ERR']
        let errMsg = ''
        let msgCode = ''
        let params = null
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp: ' + errcode)

        // handle SITA HDCERR2CC
        if (errcode.indexOf('ERR2') === 0) {
          appLog(TraceLevels.LOG_EXT_TRACE, `ERR2 error, CC#C1 reverse bag, app flow ${this.appFlow}`)
          this.disableISBD()
        }

        if (errcode.indexOf('ERR5#') === 0) {
          errcode = errcode.substring(5)
          appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp errorcode: ' + errcode)
          if (errcode === 'BGNO') {
            //errMsg = ErrCodes.BAG_MISSING
            appLog(TraceLevels.LOG_EXT_TRACE, 'CC#PA BGNO --> Welcome, repeat SQ cmd to check status')
            // this.setAppFlow(0)
            // history.push(config.firstScreen)
            errMsg = ErrCodes.BAG_MISSING
            msgCode = 'Error_BagMissing'
            // this.aeaBagdrop.sendAEA('SQ', -10000)
            //sendEventLog('eventLog', 'this is event log message')
          } else if (errcode === 'OVLT') {
            errMsg = ErrCodes.BAG_LONG
            msgCode = 'Error_BagLong'
          } else if (errcode === 'BAGJ') {
            errMsg = ErrCodes.BAG_JAMMED
            msgCode = 'Error_BagJammed'
          } else if (errcode === 'OVWT') {
            errMsg = ErrCodes.BAG_MAX_WEIGHT
            msgCode = 'Error_BagOverweightMax'
            params = {
              maxWeight: this.bagdropLimits.upperScaleLimit,
              weightUnit: config.customBagWeightUnit ? config.customBagWeightUnit : this.bagdropLimits.measurement
            }
          } else if (errcode === 'OVWU') {
            if (config.useBinForSmallBags) {
              errMsg = ErrCodes.BAG_UNDERWEIGHT_USEBIN
              msgCode = 'Error_BagUnderweightUseBin'
            } else {
              errMsg = ErrCodes.BAG_UNDERWEIGHT
              msgCode = 'Error_BagUnderweight'
            }
            params = {
              minWeight: this.bagdropLimits.lowerScaleLimit,
              weightUnit: this.bagdropLimits.measurement
            }
          } else if (errcode === 'OVFL') {
            if (config.useBinForSmallBags) {
              errMsg = ErrCodes.BAG_UNDERHEIGHT_USEBIN
              msgCode = 'Error_BagUnderheightUseBin'
            } else {
              errMsg = ErrCodes.BAG_UNDERHEIGHT
              msgCode = 'Error_BagUnderheight'
            }
          } else if (errcode === 'BGMV') {
            errMsg = ErrCodes.BAG_UNSTABLE
            msgCode = 'Error_UnableToWeigh'
          } else if (errcode === 'CBIO' || errcode === 'UNKN') {
            errMsg = ErrCodes.CONVEYOR_ERROR
            msgCode = 'Error_HWError'
          } else if (errcode === 'BHSS') {
            errMsg = ErrCodes.BHS_ERROR
            msgCode = 'Error_HWError'
          } else if (errcode === 'TOMB') {
            // this.store.dispatch(updateError(ErrCodes.MULTIPLE_BAGS, '', 'commandCompleted appFlow 4'))
            appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error multiple bags.')
            this.setAppFlow(10)
            goToLocalError(
              'commandCompleted appFlow 20',
              ErrCodes.MULTIPLE_BAGS,
              'ErrorMismatch3',
              'Retry',
              null,
              'ErrorMismatch'
            )
            return
            // history.push('ErrorMismatch')
          } else if (errcode === 'BGPR') {
            // appLog(TraceLevels.LOG_EXT_TRACE, 'no bagtag found goto --> Error.')
            // this.setAppFlow(10)
            // goToLocalGenericError('commandCompleted appFlow 0', ErrCodes.BAG_NOSCAN, 'RepositionBag1', 'END_TXN', null)
            // return
            appLog(TraceLevels.LOG_EXT_TRACE, '==> send BQ')
            this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
            this.BQStart = new Date().getTime()
            this.aeaBagdrop.sendAEA('BQ', -10000)
            return
          } else if (errcode === 'INTR') {
            this.store.dispatch(updateLocalData('updateBqCommand', ''))
            this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
            this.setAppFlow(9)
            this.IntrusionStart = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'wait for intrusion to clear (load) -> goto Intrusion IntrusionStart: ' + this.IntrusionStart
            )
            this.stopDelayedNavigation()
            // history.push('Intrusion')
            navigate({ pathname: 'Intrusion', state: { welcomeFlag: true } })
            //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'SQ', -10000)
            appLog(TraceLevels.LOG_EXT_TRACE, '==> send SQ from cc_20')
            this.aeaBagdrop.sendAEA('SQ', -10000)
            return
          } else if (errcode === 'OVHG') {
            errMsg = ErrCodes.BAG_TOO_TALL
            msgCode = 'Error_BagTooTall'
          } else if (errcode === 'OVSH') {
            errMsg = ErrCodes.BAG_SHORT
            msgCode = config.useBinForSmallBags ? 'RepositionBag4' : 'RepositionBag3'
          } else if (errcode === 'BGNX') {
            errMsg = ErrCodes.BAG_REQUIRE_USEBIN
            msgCode = 'Error_BagRequireUseBin'
          } else if (errcode !== 'OVHG' && errcode !== 'BGPR' && errcode !== 'OVSH' && errcode !== 'INTR') {
            errMsg = ErrCodes.SEE_AGENT_GENERAL
            msgCode = 'Error_Default'
          }

          if (errMsg === ErrCodes.BHS_ERROR || errMsg === ErrCodes.CONVEYOR_ERROR || errMsg === ErrCodes.BAG_JAMMED) {
            goToLocalGenericError('commandCompleted appFlow 20', errMsg, msgCode, 'OOS', params)
          } else if (errMsg !== '') {
            logEvents(EventFlows.BagDrop, EventFunctions.BagError, errMsg)
            //this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 20', 'Retry', params))
            goToLocalGenericError('commandCompleted appFlow 20', errMsg, msgCode, 'Retry', params)
          }
        }
        //navigate('error', 10)
      } else if (msg['OK']) {
        switch (msg['OK']) {
          case 'PR':
            if (msg['CC'].split('#')[1].includes('C')) {
              // handle SITA HDCERR2CC
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                `call CC#PA to get confirm message, ${msg['CC']}, app flow ${this.appFlow}`
              )
              this.sendAEACommand('CC#PA', config.loadTimeout, this.appFlow, config.firstScreen)
            } else {
              appLog(TraceLevels.LOG_EXT_TRACE, '==> send BQ')
              this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
              this.BQStart = new Date().getTime()
              this.aeaBagdrop.sendAEA('BQ', -10000)
            }
            break
          case 'BQ':
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              `cmdComp BQ result !cussTxnStarted : ${!cussTxnStarted}, this.appFlow: ${this.appFlow}, msg['BS']: ${
                msg['BS']
              }`
            )
            if ((msg['BS'] === 'BAGP' || msg['BS'] === 'BAGE') && msg['SW'] === '4') {
              this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
              // change to uniqe a appflow when we start cussTransaction and send the bagtagnumber to ets.
              let bagtagnumber = formatBagTagDisplay(msg['LP'])
              console.log(`Event:  scanned bagtag : ${bagtagnumber}`)
              const eventLogger = getEventLogger()
              // TODO: need figure out how to deal with more bags in same transaction, we should keep same clientSessionId in same ETS session
              let etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
              if (isEmpty(this.tsdMgr.sessionId) && config.isBagtagLookupEnable) {
                sessionStart()
              }
              logEvents(EventFlows.BagDrop, EventFunctions.BagDetected)
              // appLog(TraceLevels.LOG_TRACE, `Event:  scanned bagtag : ${bagtagnumber}`)
              if (!msg['HT'] || !msg['LT'] || !msg['WT']) {
                appLog(TraceLevels.LOG_EXT_TRACE, 'missing dimension --> HW Error.')
                this.setAppFlow(10)
                goToLocalGenericError(
                  'commandCompleted appFlow 0',
                  ErrCodes.CONVEYOR_ERROR,
                  'Error_HWError',
                  'OOS',
                  null
                )
              } else if (msg['HT'] === 'ERR' || msg['LT'] === 'ERR' || msg['WT'] === 'ERR') {
                appLog(TraceLevels.LOG_EXT_TRACE, 'missing dimension')
                this.setAppFlow(10)
                goToLocalGenericError(
                  'commandCompleted appFlow 20',
                  ErrCodes.BAG_UNABLE_TO_MEASURE,
                  'Error_UnableToMeasure',
                  'Retry',
                  null
                )
              } else if (msg['BT'] === '2') {
                appLog(TraceLevels.LOG_EXT_TRACE, 'unconveyable bag')
                this.setAppFlow(10)
                goToLocalGenericError(
                  'commandCompleted appFlow 20',
                  ErrCodes.UNCONVEYABLE_BAG,
                  'Error_UnconveyableBag',
                  'Retry',
                  null
                )
              } else if (bagtagnumber) {
                this.store.dispatch(updateLocalData('bagtag', bagtagnumber))
                logEvents(EventFlows.BagDrop, EventFunctions.BagTag, bagtagnumber)
                logEvents(EventFlows.BagDrop, EventFunctions.BagWeight, msg['CW'])
                logEvents(
                  EventFlows.BagDrop,
                  EventFunctions.BagDimensions,
                  'width=' + msg['WT'] + ' height=' + msg['HT'] + ' length=' + msg['LT']
                )
                if (bagtagnumber === 'ERR') {
                  appLog(TraceLevels.LOG_EXT_TRACE, 'no bagtag found goto --> Error.')
                  this.setAppFlow(10)
                  goToLocalGenericError(
                    'commandCompleted appFlow 20',
                    ErrCodes.BAG_NOSCAN,
                    'RepositionBag1',
                    'END_TXN',
                    null
                  )
                } else if (bagtagnumber.length > 10) {
                  // one bagtag length is 10
                  appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
                  this.setAppFlow(10)
                  goToLocalError(
                    'commandCompleted appFlow 20',
                    ErrCodes.MULTIPLE_TAGS,
                    'ErrorMismatch2',
                    'END_TXN',
                    null,
                    'ErrorMismatch'
                  )
                } else {
                  const bagWeightNumber = msg['CW'].slice(0, -2)
                  this.store.dispatch(updateLocalData('updateBagWeight', bagWeightNumber))
                  const faceService = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
                  //TODO: do we really need hide ?
                  //getDeviceManager().getDevice(deviceIds.FACE_TRACKING).hide()
                  //getDeviceManager().getDevice(deviceIds.FACE_TRACKING).OnDeviceEvent = null
                  this.setAppFlow(17)
                  let location = {
                    state: {
                      from: 'Welcome',
                      statistics: {
                        paxOrdinal: null,
                        paxName: null
                      }
                    }
                  }
                  if (!cussTxnStarted) {
                    getSBDAppMan().resetData()
                    startCUSSTransaction()
                  }
                  if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_OK) {
                    if (clientConfig.hostURL) {
                      this.store.dispatch(getItinerarySBD({ barcodeData: bagtagnumber }, location))
                    }
                  } else {
                    if (config.isTransferEnabled && config.isCUSSRequired) {
                      //todo: maybe we need another flow value
                      const airlineCode = bagtagnumber.substring(1, 4)
                      const airlineInfo = config.transferInfo.find((item) => {
                        return item.airlineCode === airlineCode
                      })
                      if (airlineInfo) {
                        appLog(
                          TraceLevels.LOG_EXT_TRACE,
                          'transfer to: ' + airlineInfo.companyCode + ' ' + airlineInfo.applicationName
                        )
                        this.setAppFlow(0)
                        handleAppTransfer(
                          airlineInfo.companyCode,
                          airlineInfo.applicationName,
                          'TRANSFER_INFO={"data:", "my data"}',
                          null
                        )
                      } else {
                        //todo: show airline is not supported
                        /* console.log('airline is not supported')
                        goToLocalGenericError(
                          'commandCompleted appFlow 20',
                          ErrCodes.TRANSFER_NOT_SUPPORT,
                          'ErrorTransferNotSupport',
                          'END_TXN',
                          { bagtag: formatBagTagNumber(bagtagnumber) }
                        ) */
                        appLog(
                          TraceLevels.LOG_EXT_TRACE,
                          'transfer to default: ' +
                            config.defaultTransferInfo.companyCode +
                            ' ' +
                            config.defaultTransferInfo.applicationName
                        )
                        this.setAppFlow(0)
                        handleAppTransfer(
                          config.defaultTransferInfo.companyCode,
                          config.defaultTransferInfo.applicationName,
                          'TRANSFER_INFO={"data:", "my data"}',
                          null
                        )
                      }
                    } else {
                      getSBDAppMan().enableISBD()
                      if (clientConfig.hostURL) {
                        startEtsTransaction(
                          this.store,
                          TYPE_KIOSK,
                          getVersion(),
                          { barcodeData: bagtagnumber },
                          this.tsdMgr.sessionId
                        )
                      }
                    }
                  }
                  this.store.dispatch(updateLocalData('updateBqCommand', msg))
                }
              }
            } else {
              if (this.BQStart > 0) {
                let newTime = new Date().getTime()
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  'cmdComp BQStart=' +
                    this.BQStart +
                    ' newTime=' +
                    newTime +
                    ' timeout=' +
                    config.maxWaitTimeForStableWeight * 1000
                )
                if (newTime - this.BQStart > config.maxWaitTimeForStableWeight * 1000) {
                  this.store.dispatch(
                    updateLocalError(
                      ErrCodes.BAG_UNSTABLE,
                      'Error_UnableToWeigh',
                      'commandCompleted appFlow 20',
                      'END_TXN',
                      null
                    )
                  )
                  navigate('error', 10)
                } else {
                  // try again BQ
                  appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp call BQ again')
                  //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'BQ', -10000)
                  this.aeaBagdrop.sendAEA('BQ', -10000)
                }
              } else {
                appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp missing BQStart')
              }
              if (msg['SW'] === '6') {
                let errMsg = ErrCodes.BAG_MAX_WEIGHT
                let msgCode = 'Error_BagOverweightMax'
                let params = {
                  maxWeight: this.bagdropLimits.upperScaleLimit,
                  weightUnit: config.customBagWeightUnit ? config.customBagWeightUnit : this.bagdropLimits.measurement
                }
                this.store.dispatch(updateLocalError(errMsg, msgCode, 'commandCompleted appFlow 20', 'END_TXN', params))
                navigate('error', 10)
              } else {
                // this.aeaBagdrop.sendAEA('SQ', -10000)
                appLog(
                  TraceLevels.LOG_EXT_TRACE,
                  `BQOK return msg['BS']= ${msg['BS']} and msg['SW']=${msg['SW']} lead to not successful startup`
                )
              }
            }
            break
          default:
            //navigate(config.firstScreen, 0)
            // this.aeaBagdrop.sendAEA('SQ', -10000)
            appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response: ' + msg['OK'])
        }
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, '==> Bagtag lookup disable, set appflow back to 0')
      navigate(config.firstScreen, 0)
    }
  }

  commandCompleted(response) {
    this.appFlow = this.store.getState().localData.appFlow
    //  appLog(TraceLevels.LOG_EXT_TRACE,'AEASBDAppManager.commandCompleted appFlow: ' + this.appFlow + ' response1: ' + response[0])
    //    response = removeArrayBrackets(response[0])
    appLog(TraceLevels.LOG_EXT_TRACE, '==> cmdComp: appFlow: ' + this.appFlow + ' response: ' + response)
    //this.store.dispatch(updateLocalData('AEA_BagdropMsg', response))
    let cmd = new AEACommand()
    let rc = cmd.parse(response)
    // return of rc not 0  - log?
    if (rc !== 0) {
      //appLog(TraceLevels.LOG_EXT_TRACE,'cmdComp parse rc:'+rc + ' errorCode: '+state.get('error.errorCode'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp parse rc:' + rc)
      return
    }
    let msg = cmd.getParsedMessage()

    //skip LA responses
    if (msg['OK'] === 'LA') return

    switch (this.appFlow) {
      case -2:
      case -1:
      case 0:
      case 1:
        this.ccInit(msg) // RC, EP
        break
      case 2:
        this.cc_2(msg) // SQ called - waiting to clear intrusion
        break
      case 4:
        this.cc_4(msg) // CC#PA was called
        break
      case 5:
        this.cc_5(msg) // BQ was called then SQ was called to check if 'ready to induct' BHSX
        break
      case 6:
        this.cc_6(msg) //CC#RA was called
        break
      case 7:
        /*********************************************************************************************
         *   Waiting for bag to be inducted - if config.asynchBagProcessing='N' or ('S' and last bag)*
         *********************************************************************************************/
        break
      case 9:
        this.cc_9(msg) //SQ called - waiting to clear intrusion (from Bag processing)
        break
      case 10:
        // skip CC#C0 response processing
        break
      case 20:
        this.cc_20(msg)
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp unexpected command response.')
    }
  }

  unsolicitedMessage(message) {
    this.appFlow = this.store.getState().localData.appFlow
    //const PSAM = this.store.getState().kioskInfo.PSAM
    const cussTxnStarted = this.store.getState().sessions.cussTxnStarted
    const etsTxnStatus = this.store.getState().sessions.ETSTxnStatus
    appLog(TraceLevels.LOG_EXT_TRACE, '==> unsolicitedMessage appFlow: ' + this.appFlow + ' message: ' + message)
    //    message = removeArrayBrackets(message[0])
    //    appLog(TraceLevels.LOG_EXT_TRACE,'--> uMsg received: ' + message)
    if (message == null) return

    let cmd = new AEACommand()
    let rc = cmd.parse(message)
    // return of rc not 0  - log?
    if (rc !== 0) {
      //appLog(TraceLevels.LOG_EXT_TRACE,'cmdComp parse rc:'+rc + ' errorCode: '+state.get('error.errorCode'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg parse rc:' + rc)
      return
    }
    let msg = cmd.getParsedMessage()
    /*************************************** */
    /** handle transfer start */
    /*************************************** */
    if (config.isTransferEnabled) {
      if (msg['SD'] === 'INTR' || msg['CB'] === '5' || msg['BS'] === '4') {
        let currentScreen = getScreenId(history.location.pathname)
        console.log('screenId:', currentScreen)
        if (currentScreen.indexOf('welcome') < 0) {
          navigate(config.firstScreen, 0)
        } else {
          this.setSQStart(-1)
          this.aeaBagdrop.sendAEA('SQ', -10000)
          this.setAppFlow(0)
        }
      }
    }
    /*************************************** */
    /** handle transfer end */
    /*************************************** */
    // skip BQNI messages
    if (message.indexOf('BQNI') >= 0) {
      if (msg['BS'] === 'BAGP' || msg['BS'] === 'BAGE') {
        if (this.appFlow === 9 || (this.appFlow === 10 && msg['CW'])) {
          const bagWeightNumber = msg['CW'].slice(0, -2)
          this.store.dispatch(updateLocalData('updateBagWeight', bagWeightNumber))
        } else {
          appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped: ' + message)
          this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', true))
        }
      }
      return
    }
    this.store.dispatch(updateLocalData('AEA_BagdropMsg', message))

    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'uMsg: ' + msg['CMD'] + ' appFlow: ' + this.appFlow + ' page: ' + this.getCurrentPage()
    )
    if (msg['NI'] === 'SQ') {
      if (msg['SD'] === 'NORM') {
        appLog(TraceLevels.LOG_EXT_TRACE, `INTRUSION : bag is clear from belt.`)
        this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
        this.store.dispatch(updateLocalData('updateBagWeight', 0))
      }
      if (msg['SD'] === 'INTR') {
        appLog(TraceLevels.LOG_EXT_TRACE, `INTRUSION : intrusion triggered...`)
        this.store.dispatch(updateLocalData('updateBqCommand', ''))
        if (this.appFlow <= 0 && config.isBagtagLookupEnable) {
          // start when bag is there?   && msg['BS']!='0'
          if (this.SQStart > 0) {
            let curTime = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'uMsg INTR already timer started: ' +
                this.SQStart +
                ' time: ' +
                curTime +
                ' elapsed: ' +
                (curTime - this.SQStart)
            )
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg INTR timer started. SQ executed.')
            this.SQStart = new Date().getTime()
            appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQStart + ' call SQ (UM2)')
            delay(config.delayAEAcmd).then(
              function () {
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after delay (UM2)')
                this.sendAEACommand('SQ', config.defaultAeaTimeout, this.appFlow, 'UM2')
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after SQ (UM2)')
              }.bind(this)
            )
          }
        } else if (this.appFlow === 2) {
          // start when bag is there?   && msg['BS']!='0'
          if (this.SQStart > 0) {
            let curTime = new Date().getTime()
            appLog(
              TraceLevels.LOG_EXT_TRACE,
              'uMsg INTR already timer started: ' +
                this.SQStart +
                ' time: ' +
                curTime +
                ' elapsed: ' +
                (curTime - this.SQStart)
            )
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg INTR timer started. SQ executed.')
            this.SQStart = new Date().getTime()
            appLog(TraceLevels.LOG_EXT_TRACE, 'SQstart: ' + this.SQStart + ' call SQ (UM2)')
            delay(config.delayAEAcmd).then(
              function () {
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after delay (UM2)')
                this.sendAEACommand('SQ', config.defaultAeaTimeout, this.appFlow, 'UM2')
                appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after SQ (UM2)')
              }.bind(this)
            )
          }
        } else if (this.appFlow >= 4 && this.appFlow <= 5) {
          this.store.dispatch(updateLocalData('updateBaggageLoadedStatus', false))
          this.IntrusionStart = new Date().getTime()
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            'wait for intrusion to clear - call SQ (UM45)  --> goto Intrusion IntrusionStart: ' + this.IntrusionStart
          )
          navigate('Intrusion', 9)
          this.store.dispatch(updateError(null)) // RESET ERROR
          this.stopDelayedNavigation()
          delay(config.delayAEAcmd).then(
            function () {
              this.sendAEACommand('SQ', config.defaultAeaTimeout, this.appFlow, 'UM45')
            }.bind(this)
          )
        } else if (this.appFlow === 6) {
          this.IntrusionDuringDispatch = true
          appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg Intrusion during dispatch.')
        }
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg SQNI skipped.')
      }
    }
    if (this.appFlow > 6 || this.appFlow < 2) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped (not in bag dispatching process): ' + message)
      return
    }
    if (this.appFlow > 20) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'uMsg skipped (in biometrics): ' + message)
      return
    }
    //HDCPROK#B
    //HDCPROK#R0
    if (this.appFlow === 6) {
      if (msg['CC'] === 'PROK#R0') {
        appLog(TraceLevels.LOG_EXT_TRACE, 'call releaseBag()')
        this.releaseBag()
      }
    }
  }

  status(statusCode) {
    this.appFlow = this.store.getState().localData.appFlow
    appLog(TraceLevels.LOG_EXT_TRACE, '==> AEASBDAppManager.status appFlow: ' + this.appFlow + ' status: ' + statusCode)
    if (this.appFlow === 7 && statusCode < 1000) {
      this.inIngestLoop = false
      let bagTagObject = this.store.getState().localData.bagtagObject
      let bagMeasurements = this.store.getState().localData.bagMeasurements
      delay(config.delayBagUpdateCall).then(function () {
        callBagUpdate(bagTagObject, SBDUpdateAction.INGESTED, bagMeasurements)
      })
    } else if (statusCode < 1000) {
      // must be asynch wait	- check if prev object defined
      this.inIngestLoop = false
      let bagTagObject = this.store.getState().localData.prevBagtagObject
      let bagMeasurements = this.store.getState().localData.prevBagMeasurements
      if (!isEmpty(bagTagObject))
        delay(config.delayBagUpdateCall).then(function () {
          callBagUpdateAsynch(bagTagObject, SBDUpdateAction.INGESTED, bagMeasurements)
        })
      else appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status no prev bagtag object - skip')
    } else {
      if (this.inIngestLoop === false) {
        if (config.asynchBagProcessing === 'S' && this.getNumberOfNotActivatedBags() === 0) {
          // only last bag is processed in a synchronous way
          this.inIngestLoop = true
          navigate('PleaseWait')
          //delay(config.delayBagUpdateCall).then(DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'status', 'status'))
          delay(config.delayBagUpdateCall).then(
            function () {
              this.aeaBagdrop.status()
            }.bind(this)
          )
        } else {
          // bag processing asynch between transactions
          // on interval check status - if status indicates that PE1 is cleared
          // then execute callBagUpdate with the prevBagtag and BAG_INGESTED status
          let currBagtag = this.store.getState().localData.bagtagObject
          let currBagMeasurements = this.store.getState().localData.bagMeasurements
          this.store.dispatch(updateLocalData('prevBagtagObject', currBagtag))
          this.store.dispatch(updateLocalData('prevBagMeasurements', currBagMeasurements))
          //waitForBagOnAirportBelt(true);   //asynch
          this.inIngestLoop = true
          // delay(config.delayBagUpdateCall).then(DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'status', 'status'))
          delay(config.delayBagUpdateCall).then(
            function () {
              this.aeaBagdrop.status()
            }.bind(this)
          )
          appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after waitForBagOnAirportBelt(true)')
          if (this.getNumberOfNotActivatedBags() === 0) {
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status goto --> Closing Remarks')
            this.appFlow = 8
            if (config.enablePrintReceipt) {
              navigate('PrintReceipt', 8)
            } else {
              navigate('ClosingRemarks', 8)
            }
          } else {
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status goto --> Put Bag on Belt')
            this.appFlow = 2
            navigate('PutBagOnBelt', 2)
          }
        }
      } else {
        //delay(config.delayBagUpdateCall).then(DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'status', 'status'))
        //delay(config.delayAEAcmd).then(this.aeaBagdrop.status())
        appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status before delay')
        delay(config.delayAEAcmd).then(
          function () {
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after delay')
            this.aeaBagdrop.status()
            appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.status after status')
          }.bind(this)
        )
      }
    }
  }

  // handle fatal status changes  e.g. 302,true
  statusChange(statusCodeType) {
    let appFlow = this.store.getState().localData.appFlow
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '==> statusChange: ' + statusCodeType + ' appFlow: ' + appFlow + ' page: ' + this.getCurrentPage()
    )
    let fatalError = false
    if (Array.isArray(statusCodeType) && statusCodeType.length === 2) {
      fatalError = statusCodeType[1]
    } else if (!Array.isArray(statusCodeType) && statusCodeType.indexOf(',') > 0) {
      let sData = statusCodeType.split(',')
      fatalError = sData[1] === 'true'
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange unexpected format.')
    }
    if (fatalError) {
      this.setAppFlow(10)
      // if no transaction yet - goto OOS
      let transactionStarted = this.store.getState().sessions.cussTxnStarted
      appLog(TraceLevels.LOG_EXT_TRACE, 'statusChange --> outOfService; transaction started: ' + transactionStarted)
      /* if (!transactionStarted) {
        let appm = getDeviceManager().getAppManager()
        if (appm && !appm.getIsActive()) {
          appLog(TraceLevels.LOG_TRACE, '==> statusChange: app is not in active state - skip it.')
        } else {
          this.resetData()
          completeCUSSTransaction(true)
        }
      } else */
      if (this.getCurrentPage() !== 'error') {
        this.stopDelayedNavigation()
        // this.store.dispatch(updateError(ErrCodes.CONVEYOR_ERROR, '', 'statusChange', 'OOS'))
        switch (statusCodeType[0]) {
          case '139':
          case 139:
            this.eStopPressed = true
            goToLocalGenericError('commandCompleted appFlow 0', ErrCodes.CONVEYOR_ESTOP, 'Error_ConveyorEStop', 'OOS', {
              confirmDisable: true
            })
            break
          default:
            if (!this.eStopPressed) {
              goToLocalGenericError('statusChange', ErrCodes.DEVICE_ERROR, 'Error_HWError', 'OOS', null)
            }
            break
        }
        // history.push('error')
      }
    } else {
      switch (statusCodeType[0]) {
        // handle SITA platform error code 151, Unconveyable Bag Error
        case '151':
        case 151:
          if (this.appFlow < 5 || this.appFlow === 20) {
            if (config.useBinForSmallBags) {
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                `==> statusChange: app flow ${this.appFlow}, error code 151, Error_UnconveyableBag`
              )
              this.checkMaxReposition(
                `commandCompleted appFlow${this.appFlow}`,
                ErrCodes.UNCONVEYABLE_BAG,
                'Error_UnconveyableBagUseBin',
                this.appFlow === 20 ? 'END_TXN' : 'Retry',
                null
              )
            } else {
              appLog(
                TraceLevels.LOG_EXT_TRACE,
                `==> statusChange: app flow ${this.appFlow}, error code 151, Error_UnconveyableBag`
              )
              this.checkMaxReposition(
                `commandCompleted appFlow${this.appFlow}`,
                ErrCodes.UNCONVEYABLE_BAG,
                'Error_UnconveyableBag',
                'END_TXN',
                null
              )
            }
          }
          break
        default:
          //goToLocalGenericError('statusChange', ErrCodes.CONVEYOR_ERROR, 'Error_HWError', 'OOS', null)
          break
      }
    }
  }

  // data is rc,cmd
  processReturnCode(aeaCall, data) {
    let appFlow = this.store.getState().localData.appFlow
    appLog(TraceLevels.LOG_EXT_TRACE, 'processReturnCode: ' + data + ' appFlow: ' + appFlow)
    let anData = null
    if (!Array.isArray(data) && data.indexOf(',') > 0) {
      anData = data.split(',')
    } else if (Array.isArray(data) && data.length === 2) {
      anData = data
    }
    if (anData != null) {
      if (anData[0] !== '0' && anData[0] !== 0) {
        // to do: process exception
        let cmd = anData[1].substr(0, 2)
        if (appFlow === -1 && cmd === 'EP') {
          // the EP will be execute on activate again - skip here
          this.epCmdOK = false
          return
        }
        appLog(TraceLevels.LOG_ALERT, 'unexpected rc from AEA cmd: ' + data + ' appFlow: ' + appFlow)
      }
    }
  }

  sendAEACommand(cmd, to, validInAppFlow, msg) {
    let appFlow = this.store.getState().localData.appFlow
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '==> aeaCommand: ' +
        cmd +
        ' validInAppFlow: ' +
        validInAppFlow +
        ' appFlow: ' +
        appFlow +
        ' to: ' +
        to +
        '  msg: ' +
        msg
    )
    if (appFlow !== validInAppFlow && validInAppFlow !== 100) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'aeaCommand: not valid appFlow skip it.')
      return -100
    }
    if (to == null) to = -10000
    if (!isEmpty(this.store.getState().errorDetails) && cmd.indexOf('CC#C') === -1) {
      // error handling
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'aeaCommand: ' + cmd + ' skipped  errorDetails: ' + this.store.getState().errorDetails
      )
      return -99
    }
    //DeviceActions.getResponse(deviceIds.AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', cmd, to)
    this.aeaBagdrop.sendAEA(cmd, to)
  }

  setDefaultBagdropLimits() {
    this.bagdropLimits.upperScaleLimit = 100
    this.bagdropLimits.lowerScaleLimit = 1
    this.bagdropLimits.heavyScaleLimit = 80
    this.bagdropLimits.measurement = getWeightUnitFromConfig()
    this.bagdropLimits.dimensions = 'M'
    this.bagdropLimits.widthMin = 0
    this.bagdropLimits.widthMax = 0
    this.bagdropLimits.lengthMin = 100
    this.bagdropLimits.lengthMax = 1200
    this.bagdropLimits.heightMin = 50
    this.bagdropLimits.heightMax = 800
    this.bagdropLimits.maxBags = 1
    this.store.dispatch(updateLocalData('updateBagWeightUnit', this.bagdropLimits.measurement))
  }

  // save bagdrop limits in the this
  saveBagdropLimits(parsedResponse) {
    // scale limits
    if (parsedResponse['W'] != null) {
      let w = parsedResponse['W']
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC W: ' + w)
      if (w.length === 13) {
        this._extractValues(w, 'upperScaleLimit', 'U', 0, 3)
        this._extractValues(w, 'lowerScaleLimit', 'L', 4, 2)
        this._extractValues(w, 'heavyScaleLimit', 'H', 7, 2)
        if (w.substr(10, 2) === 'KG' || w.substr(10, 2) === 'LB') {
          this.bagdropLimits.measurement = w.substr(10, 2)
          this.store.dispatch(updateLocalData('updateBagWeightUnit', w.substr(10, 2)))
        } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect measurement')
      } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC W: incorrect size.')
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits: missing RC Wxxxxx')
    // dimensions limits
    if (parsedResponse['Z'] != null) {
      let w = parsedResponse['Z']
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC Z: ' + w)
      if (w.length === 31) {
        if (w.substr(0, 1) === 'I' || w.substr(0, 1) === 'M') this.bagdropLimits.dimensions = w.substr(0, 1)
        else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect measurement')
        this._extractValues(w, 'widthMin', 'W', 1, 4)
        this._extractValues(w, 'widthMax', '.', 6, 4)
        this._extractValues(w, 'lengthMin', 'L', 11, 4)
        this._extractValues(w, 'lengthMax', '.', 16, 4)
        this._extractValues(w, 'heightMin', 'H', 21, 4)
        this._extractValues(w, 'heightMax', '.', 26, 4)
      } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC Z: incorrect size.')
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits: missing RC Zxxxxx')
    // belt (conveyor) limits
    if (parsedResponse['T'] != null) {
      let w = parsedResponse['T']
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC T: ' + w)
      if (w.length === 8) {
        this._extractValues(w, 'maxBags', 'M', 0, 1)
      } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC Z: incorrect size.')
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits: missing RC Txxxxx')

    this.displayBagdropLimits()
  }

  displayBagdropLimits() {
    // display limits
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits upperScaleLimit: ' + this.bagdropLimits.upperScaleLimit)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits lowerScaleLimit: ' + this.bagdropLimits.lowerScaleLimit)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits heavyScaleLimit: ' + this.bagdropLimits.heavyScaleLimit)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits measurement: ' + this.bagdropLimits.measurement)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits dimensions: ' + this.bagdropLimits.dimensions)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits widthMin: ' + this.bagdropLimits.widthMin)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits widthMax: ' + this.bagdropLimits.widthMax)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits lengthMin: ' + this.bagdropLimits.lengthMin)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits lengthMax: ' + this.bagdropLimits.lengthMax)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits heightMin: ' + this.bagdropLimits.heightMin)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits heightMax: ' + this.bagdropLimits.heightMax)
    appLog(TraceLevels.LOG_EXT_TRACE, 'displayBagdropLimits maxBags: ' + this.bagdropLimits.maxBags)
  }

  _extractValues = function (w, limit, charcode, sPos, len) {
    if (w.charAt(sPos) === charcode) {
      const t = parseInt(w.substr(sPos + 1, len), 10)
      appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC ' + limit + ': ' + w.substr(sPos + 1, len) + ' t=' + t)
      if (isNaN(t)) appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect ' + limit)
      else this.bagdropLimits[limit] = t
    } else appLog(TraceLevels.LOG_EXT_TRACE, 'saveBagdropLimits RC: incorrect ' + limit)
  }

  /*
   * input cw - CW string from BQ command
   * input [maxBagWeight] - optional max bag weight
   * input [maxBagWeightUnit] - optional max bag weight unit
   * input [tubUsed] - optional if true then tab used skip under weight error
   * >0 - weight
   * -1 too heavy
   * -2 too light
   * -3 cw format error
   * -4 undefined
   * if the maxBagWeight or maxBagWeightUnit are not provided (or empty strings) or maxBagWeight is not a number or maxBagWeightUnit is not unit used by SBD
   *    then the additional check is skipped
   */
  checkWeight(cw, maxBagWeight, bagMaxWeightUnit, tubUsed) {
    if (cw == null) return -4
    if (cw.lastIndexOf(this.bagdropLimits.measurement) !== cw.length - 2) return -3
    const weight = parseFloat(cw.substring(0, cw.length - 2))
    if (isNaN(weight)) return -3
    if (!tubUsed && weight < this.bagdropLimits.lowerScaleLimit) return -2
    if (weight > this.bagdropLimits.upperScaleLimit) return -1

    if (
      maxBagWeight !== '' &&
      bagMaxWeightUnit !== '' &&
      !isNaN(maxBagWeight) &&
      bagMaxWeightUnit === this.bagdropLimits.measurement
    ) {
      if (weight > parseFloat(maxBagWeight)) return -1
    }

    return weight
  }

  /* dimType 1-height, 2-length, 3-width
   * dim - dimension
   * tubUsed - if true then tub used - skip too small, short errors
   * >0 - dimension OK
   *  0 - not supported by bagdrop
   * -1 too tall/long
   * -2 too flat/short
   * -3 format error
   * -4 undefined
   */
  checkDimension(dimType, dim, tubUsed) {
    if (dim == null) return -4
    let min = 0
    let max = 0
    switch (dimType) {
      case 1:
        min = this.bagdropLimits.heightMin
        max = this.bagdropLimits.heightMax
        break
      case 2:
        min = this.bagdropLimits.lengthMin
        max = this.bagdropLimits.lengthMax
        break
      case 3:
        min = this.bagdropLimits.widthMin
        max = this.bagdropLimits.widthMax
        break
    }
    const dimUnit = this.bagdropLimits.dimensions === 'M' ? 'CM' : 'IN'
    if (dim.lastIndexOf(dimUnit) !== dim.length - 2) return -3
    if (dim === 'ERR')
      if (max === 0) return 0
      else return -4
    const dimension = parseInt(dim.substring(0, dim.length - 2))
    appLog(TraceLevels.LOG_EXT_TRACE, 'checkDimension: ' + dim + ' ' + dimension)
    if (isNaN(dimension)) return -3
    let tempDimension = dimension
    if (dimUnit === 'CM')
      // convert mm to cm
      tempDimension = dimension * 10
    if (!tubUsed && tempDimension < min) return -2
    if (tempDimension > max) return -1

    return dimension
  }

  /* if maxHtoLratio <= 1 or l or h are null or undefined or ERR then return false
   *  else return true when l * ratio < h
   */
  overHtoLratio(maxHtoLratio, h, l) {
    if (maxHtoLratio <= 1 || l == null || l === 'ERR' || h == null || h === 'ERR') return false
    let lInt = parseInt(l.substring(0, l.length - 2))
    let hInt = parseInt(h.substring(0, h.length - 2))
    if (isNaN(lInt) || isNaN(hInt)) return false
    return lInt * maxHtoLratio < hInt
  }

  /* if maxHtoWratio <= 1 or l or h are null or undefined or ERR then return false
   *  else return true when l * ratio < h
   */
  overHtoWratio(maxHtoWratio, h, w) {
    if (maxHtoWratio <= 1 || w == null || w === 'ERR' || h == null || h === 'ERR') return false
    if (config.minWidth && config.minWidth > w) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'overHtoWratio - skip width check: ' + w + ' minWidth: ' + config.minWidth)
      return false
    }
    let wInt = parseInt(w.substring(0, w.length - 2))
    let hInt = parseInt(h.substring(0, h.length - 2))
    if (isNaN(wInt) || isNaN(hInt)) return false
    return wInt * maxHtoWratio < hInt
  }

  getSelectedPassenger() {
    return this.store.getState().responses.itineraryInfo.passengerBagtagDetails.passenger
    //let passengers = this.store.getState().responses.passengerBagtagDetails
    /*
     for (var i = 0; i < passengers.length; i++){
     if (passengers[i].status == 'selected')
     return passengers[i];
     }
     */
    //return passengers[0]
  }

  getBags() {
    let bags = []
    if (this.store.getState().responses.itineraryInfo) {
      let itInfo = this.store.getState().responses.itineraryInfo
      if (itInfo.passengerBagtagDetails) {
        for (let i = 0; i < itInfo.passengerBagtagDetails.length; i++) {
          for (let j = 0; j < itInfo.passengerBagtagDetails[i].bagTags.length; j++) {
            let bpScanned = itInfo.passengerBagtagDetails[i].bpScanned
            let bag = itInfo.passengerBagtagDetails[i].bagTags[j]
            bag.paxOrdinal = itInfo.passengerBagtagDetails[i].passenger.ordinal
            bag.paxName =
              itInfo.passengerBagtagDetails[i].passenger.firstName +
              ' ' +
              itInfo.passengerBagtagDetails[i].passenger.lastName
            bag.bpScanned = bpScanned
            bags.push(bag)
          }
        }
      }
    }
    return bags
  }

  // skip bags on heavyBagsList
  getNumberOfNotActivatedBags() {
    const bags = this.getBags()
    let NumberOfNotActivatedBags = 0
    for (let i = 0; i < bags.length; i++) {
      //if (bags[i].status=='BAG_PENDING')
      if (
        bags[i].bpScanned &&
        bags[i].status !== BaggageStatus.ACTIVATED &&
        this.heavyBagsList.indexOf(bags[i].baseTagNo + ',') < 0
      ) {
        NumberOfNotActivatedBags++
      }
    }
    return NumberOfNotActivatedBags
  }

  getCurrentFlight() {
    const city = this.store.getState().kioskInfo.airportCode
    const flights = this.store.getState().responses.itineraryInfo.flights
    for (let i = 0; i < flights.length; i++) {
      if (flights[i].departureSchedule.schedule.airport.code === city) return flights[i]
    }
    return null
  }

  /** Complete the quit transaction with the given reason.*/
  doQuit(reason, text, OOSTransition, from) {
    //updated code to handle BAGUPDATE (error, timeout, quit, no more bags)
    history.push('pleaseWait')
    let appFlow = this.store.getState().localData.appFlow
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'doQuit: appFlow: ' + appFlow + ' reason: ' + reason + ' ' + (OOSTransition === 'OOS' ? 'OOS' : '')
    )
    /* if (from) {
      sessionEnd(reason + ' from ' + from)
    } else {
      sessionEnd(reason)
    }
 */

    //stop any delayed navigation
    this.stopDelayedNavigation()

    if (
      this.store.getState().localData.baggageLoadedStatus &&
      (reason === END_TXN_REASON.USER_QUIT || reason === END_TXN_REASON.TIMEOUT)
    ) {
      if (config.isBagtagLookupEnable || appFlow >= 4) {
        this.disableISBD()
      }
    }

    if (appFlow === 6 && reason === ErrCodes.USER_QUIT) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'doQuit: skip - dispatch active: ' + appFlow + ' ' + this.getCurrentPage())
    } else if (appFlow === 8) {
      // call doQuit in closing Remarks
      this.quitReason = reason
      this.quitDetail = OOSTransition
      this.handleQuitProcess()
      // this.resetData()
    } else if (appFlow > 1 && appFlow !== 10 && appFlow < 20) {
      // to avoid double click; not allowed when CC#RA started
      this.setAppFlow(10)
      this.quitReason = reason
      this.quitDetail = OOSTransition
      // this.resetData()
      if (config.sendBagUpdateExitCases === 'Y') {
        callBagUpdate('', reason, []) // the quit reason applies to all bags which are not COMPLETE
      } else if (appFlow !== 8) {
        // closing remarks already displayed
        if (config.enablePrintReceipt && this.store.getState().localData.numberOfProcessedBags > 0) {
          appLog(TraceLevels.LOG_EXT_TRACE, 'Build template for print')
          this.store.dispatch(getPrintStream(PRINT_DOC_TYPE.SBD_RECEIPT))
        } else if (this.store.getState().localData.numberOfProcessedBags > 0) {
          if (config.waitForIngested && this.inIngestLoop) {
            navigate('PleaseWait')
          } else {
            delay(0.1).then(
              function () {
                this.handleQuitProcess()
              }.bind(this)
            )
          }
        } else {
          delay(0.1).then(
            function () {
              this.handleQuitProcess()
            }.bind(this)
          )
        }
      }
    } else if (appFlow <= 1 || appFlow > 20) {
      sendErrorLog(reason)
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'doQuit: isTransactionStarted: ' + this.store.getState().sessions.cussTxnStarted
      )
      if (appFlow >= 1 || this.store.getState().sessions.cussTxnStarted) {
        let endTxnState = getEndTxtState(reason)
        doETSTransactionEnd(endTxnState, reason)
        // this.resetData()
      }
      // let lang = this.store.getState().localData.locale.toUpperCase()
      // getTSDManager().addSessionInfoEvent(SessionInfoEventType.SESSION_END, lang, formatTSDdescription(reason))
      if (OOSTransition === 'OOS') {
        completeCUSSTransaction(true)
      } else {
        completeCUSSTransaction(false)
      }
    } else if (appFlow === 10) {
      // this.resetData()
      // error during print from ErrorPage
      this.quitReason = reason
      this.quitDetail = OOSTransition
      this.handleQuitProcess()
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'doQuit: unexpected appFlow: ' + appFlow + ' ' + this.getCurrentPage())
      // all appFlow codes handles above
    }
  }

  handleQuitProcess() {
    appLog(TraceLevels.LOG_EXT_TRACE, 'Quit reason: ' + this.quitReason + ' ' + this.quitDetail)
    if (this.quitReason === BAG_TXN_NOMORE) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'handleQuitProcess goto --> ClosingRemarks')
      this.setAppFlow(8)
      navigate('ClosingRemarks', 8)
      return
    }
    sendErrorLog(this.quitReason)
    let endTxnState = getEndTxtState(this.quitReason)
    doETSTransactionEnd(endTxnState, this.quitReason)
    // let lang = this.store.getState().localData.locale.toUpperCase()
    // getTSDManager().addSessionInfoEvent(SessionInfoEventType.SESSION_END, lang, formatTSDdescription(this.quitReason))
    completeCUSSTransaction(this.quitDetail === 'OOS')
    this.resetData()
  }

  enableISBD() {
    if (config.callCR === 'T') {
      this.sendAEACommand('CR', config.cancelTimeout, 100, 'enableISBD') // valid in any appFlow
    }
  }

  disableISBD() {
    if (config.sbdPlatform === 'SITA') {
      if (config.callCR === 'T') {
        this.sendAEACommand('CW', config.cancelTimeout, 100, 'disableISBD') // valid in any appFlow
      } else {
        this.sendAEACommand('CC#C1', config.cancelTimeout, 100, 'disableISBD') // valid in any appFlow
      }
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, `AEAsbdAppMan disableISBD() sbdPlatform: ${config.sbdPlatform}`)
    }
  }

  resetIntrusionDuringDispatch() {
    this.IntrusionDuringDispatch = false
  }

  resetData() {
    //reset transaction level variables
    this.inIngestLoop = false
    this.SQStart = -1
    this.BQStart = 0
    this.BHSXStart = 0
    this.IntrusionStart = 0
    this.PutBagStart = 0
    this.IntrusionDuringDispatch = false
    this.bagRepositionCount = 0
    this.bagMaxWeight = 0
    this.bagMaxWeightUnit = ''
    this.bagIngested = 0 // maybe not needed
    if (this.delayedNavigationTimer > 0) clearTimeout(this.delayedNavigationTimer)
    this.delayedNavigationTimer = 0
    this.quitReason = ''
    this.quitDetail = ''
    this.heavyBagsList = ''

    this.store.dispatch(updateLocalData('numberOfProcessedBags', 0))
    this.store.dispatch(updateError(null))
  }

  setSQStart(val) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.setSQStart: ' + val)
    this.SQStart = val
  }

  setIntrusionStart(val) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.setIntrusionStart: ' + val)
    this.IntrusionStart = val
  }

  setPutBagStart(val) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'AEASBDAppManager.setPutBagStart: ' + val)
    this.PutBagStart = val
  }

  getCurrentPage() {
    return history.location
  }

  resetRepositionCount() {
    this.bagRepositionCount = 0
  }

  navigateAfterDelay(page) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'navigateAfterDelay page: ' + page + ' timerId: ' + this.delayedNavigationTimer)
    if (this.delayedNavigationTimer !== 0) {
      this.delayedNavigationTimer = 0
      navigate(page)
    }
  }

  getErrorCode() {
    /*let ed = this.store.getState().errorDetails
    let ec = this.store.getState().errorDetails ? this.store.getState().errorDetails.errCode : ''
    let nt = Object.keys(this.store.getState().errorDetails).length === 0*/
    if (!isEmpty(this.store.getState().errorDetails)) return this.store.getState().errorDetails.errCode
    else return ''
  }

  stopDelayedNavigation() {
    let delayedNavigationTimer = this.delayedNavigationTimer
    if (delayedNavigationTimer !== 0) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'stopDelayedNavigation timerId: ' + delayedNavigationTimer)
      clearTimeout(delayedNavigationTimer)
      this.delayedNavigationTimer = 0
    }
  }

  navigateWithMinDisplay(page, minDisplay, startTimerName) {
    // make sure that Intrusion/PutBagOnBelt was displayed minDisplay seconds
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'navigateWithMinDisplay page: ' + page + ' minDisplay: ' + minDisplay + ' startTimerName: ' + startTimerName
    )
    let oldDelayedNavigationTimer = this.delayedNavigationTimer
    let startTimer = this.IntrusionStart
    if (startTimerName === 'PutBagStart') {
      startTimer = this.PutBagStart
    } else {
      startTimer = this.IntrusionStart
    }
    //  	var startTimer = state.getNumber(startTimerName);
    //  	state.set(startTimerName, -1); // clear timer
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'navigateWithMinDisplay startTimer: ' +
        startTimerName +
        ': ' +
        startTimer +
        ' oldDelayedNavigationTimer: ' +
        oldDelayedNavigationTimer
    )
    if (startTimer != null) {
      if (oldDelayedNavigationTimer !== 0) {
        appLog(TraceLevels.LOG_EXT_TRACE, 'navigateWithMinDisplay - already waiting - skip this one')
      } else {
        let newTime = new Date().getTime()
        appLog(TraceLevels.LOG_EXT_TRACE, 'startTimer delta: ' + (newTime - startTimer))
        if (newTime - startTimer > minDisplay * 1000 - config.minDeltaToStartTimer) navigate(page)
        else {
          let pageDelay = minDisplay * 1000 - (newTime - startTimer) //1000
          //var delayedNavigationTimer = navigateAfterDelay.delay(pageDelay,page)
          //  				this.delayedNavigationTimer = window.setTimeout(function(page) {
          //  					this.navigateAfterDelay(page)
          //  		        }, pageDelay)
          this.delayedNavigationTimer = setTimeout(this.navigateAfterDelay.bind(this, page), pageDelay)
          appLog(
            TraceLevels.LOG_EXT_TRACE,
            'startTimer page delay: ' + pageDelay + ' timerid: ' + this.delayedNavigationTimer
          )
        }
      }
    } else {
      navigate(page)
    }
  }

  checkMaxReposition(funcName, errCode, msgCode, transitionPage, params, summaryParams = null) {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'bagRepositionCount: ' +
        this.bagRepositionCount +
        ' config.maxBagRepositionRetries: ' +
        config.maxBagRepositionRetries
    )
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      'checkMaxReposition params:' +
        ' call from: ' +
        funcName +
        ' error Code: ' +
        errCode +
        ' tansition after error page: ' +
        transitionPage +
        ' params for the message: ' +
        params
    )
    this.setAppFlow(10)
    if (
      config.maxBagRepositionRetries &&
      config.maxBagRepositionRetries >= 0 &&
      this.bagRepositionCount >= config.maxBagRepositionRetries
    ) {
      // this.store.dispatch(updateError(ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED, '', 'checkMaxReposition'))
      goToLocalGenericError(
        funcName,
        ErrCodes.BAG_REPOSITION_RETRIES_EXCEEDED,
        'Error_RepositionTriesExceeded',
        'END_TXN',
        null,
        summaryParams
      )
      // history.push('error')
    } else {
      //TODO should we set appFlow =2 here ? should be 10
      //this.setAppFlow(2)
      this.bagRepositionCount++
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Reposition bag screen.')
      goToLocalGenericError(funcName, errCode, msgCode, transitionPage, params, summaryParams)
      // history.push('RepositionBag')
    }
  }

  validateBagtag(
    currBagtag // return false when the bag tag not valid
  ) {
    //check - only one bagtag allowed
    appLog(TraceLevels.LOG_EXT_TRACE, 'Bagtag: ' + currBagtag)
    if (currBagtag == null || currBagtag === 'ERR') {
      // should not happen - but happens
      // this.store.dispatch(updateError(ErrCodes.BAG_NOSCAN, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition('commandCompleted appFlow 5', ErrCodes.BAG_NOSCAN, 'RepositionBag1', 'PutBagOnBelt', null)
      return false
    }
    this.store.dispatch(updateLocalData('bagtag', currBagtag))

    if (currBagtag.length > 10) {
      // one bagtag length is 10
      // this.store.dispatch(updateError(ErrCodes.MULTIPLE_TAGS, '', 'commandCompleted appFlow 5'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
      goToLocalError(
        'commandCompleted appFlow 5',
        ErrCodes.MULTIPLE_TAGS,
        'ErrorMismatch2',
        'PutBagOnBelt',
        null,
        'ErrorMismatch'
      )
      // history.push('ErrorMismatch')
      return false
    }

    // Assumed that batgatg length is 10 at this point
    //check if bagtag is on the list of passenger's bagtags with 'not activated' status
    //check if the pax scanned the BP - if not
    let bags = this.getBags()
    // let bagtagFound = false
    // let alreadyActive = false
    let bpNotScanned = false
    //let notActiveBags = false
    let scannedPax = this.store.getState().responses.itineraryInfo.scanSequence
    let compBagTag = formatBagTagNumber(currBagtag)
    const { alreadyActive, notActiveBags } = this.checkActiveBag(bags, compBagTag)
    let bagtagFound

    if (config.disableValidateBagtag) {
      // Westjet custom flow
      const bag = bags[0]
      delete bag.bpScanned
      bag.bagIdentifier = compBagTag
      bag.baseTagNo = compBagTag
      bag.status = BaggageStatus.ADDED
      this.store.dispatch(updateLocalData('bagtagObject', bag))

      if (scannedPax.indexOf(bag.paxOrdinal) <= -1) {
        this.store.dispatch(updateLocalData('updateErrorName', bag.paxName))
      }

      bagtagFound = false
    } else {
      // Original bag tag validation
      bagtagFound = this.checkBagTagFoundCheck(bags, compBagTag, scannedPax)
    }

    if (config.enableBagtagAciveCheck && alreadyActive) {
      let transitionPage = notActiveBags && !config.endTxnWhenBagtagAlreadyActive ? 'PutBagOnBelt' : 'END_TXN'
      goToLocalGenericError(
        'commandCompleted appFlow 5',
        ErrCodes.TAG_ALREADY_ACTIVE,
        'Error_BagtagAlreadyActive',
        transitionPage,
        null
      )
      // history.push('error')
      return false
    }

    // currently is not using in the core
    if (bpNotScanned) {
      // goto Error Mismatch
      // add name as details
      // this.store.dispatch(updateError(ErrCodes.TAG_SCANNED_BP_NOT_SCANNED, '', 'commandCompleted appFlow 5'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatchBP.')
      goToLocalError(
        'commandCompleted appFlow 5',
        ErrCodes.TAG_SCANNED_BP_NOT_SCANNED,
        'ErrorMismatch4',
        'PutBagOnBelt',
        null,
        'ErrorBPMismatch'
      )
      // history.push('ErrorBPMismatch')
      return false
    }

    if (config.enableBagtagFoundCheck && bagtagFound) {
      // goto Error Mismatch
      // this.store.dispatch(updateError(ErrCodes.TAG_NOT_FOUND_IN_PNR, '', 'commandCompleted appFlow 5'))
      appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error mismatch.')
      goToLocalError(
        'commandCompleted appFlow 5',
        ErrCodes.TAG_NOT_FOUND_IN_PNR,
        'ErrorMismatch1',
        'PutBagOnBelt',
        null,
        'ErrorMismatch'
      )
      // history.push('ErrorMismatch')
      return false
    } /* else {
      const bag = {...bags[0]}
      delete bag.bpScanned
      bag.bagIdentifier = compBagTag
      bag.baseTagNo = compBagTag
      bag.status = BaggageStatus.ADDED
      this.store.dispatch(updateLocalData('bagtagObject', bag))
    } */
    // clear Mismatch BP error
    this.store.dispatch(updateLocalData('updateMismatchBPError', false))

    return true // ALL OK
  }

  // alreadyActive: true when bagtag is Active stage, false mean passing
  // notActiveBags: true when there are non active bag, false mean all bags are actived
  checkActiveBag(bags, compBagTag) {
    let result = []
    let notActiveBags = false

    bags.forEach((bag) => {
      if (bag.status !== BaggageStatus.ACTIVATED) {
        notActiveBags = true
      }

      if (bag.baseTagNo === compBagTag && bag.status === BaggageStatus.ACTIVATED) {
        result.push(bag)
      }
    })

    return {
      notActiveBags,
      alreadyActive: result.length > 0
    }
  }

  // bagtagFound: true when the bagtag is not part of PNR, false mean passing
  checkBagTagFoundCheck(bags, compBagTag, scannedPax) {
    return (
      bags.filter((bag) => {
        const match = bag.baseTagNo === compBagTag && bag.status !== BaggageStatus.ACTIVATED
        if (match) {
          delete bag.bpScanned
          this.store.dispatch(updateLocalData('bagtagObject', bag))
          if (scannedPax.indexOf(bag.paxOrdinal) <= -1) {
            this.store.dispatch(updateLocalData('updateErrorName', bag.paxName))
          }
        } /* else {
          const newBagObject = { ...bags[0], baseTagNo: compBagTag }
          delete newBagObject.bpScanned
          this.store.dispatch(updateLocalData('bagtagObject', newBagObject))
        } */
        return match
      }).length === 0
    )
  }

  validateHeavyBag(heavyLabel, cw) {
    // example
    // HDCBQOK#LP=0123456789#BC=1HEAVY#HT=24CM#WT=ERR#LT=55CM#SW=4#CW=16.0KG#BT=0#BS=BAGP
    const weight = parseFloat(cw.substring(0, cw.length - 2))
    if (config.checkHeavyLabel) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'heavy label check is true')
      if (weight > config.heavyBagWeight) {
        //bag is heavy as weighs more than config weight. Attach heavy label
        appLog(TraceLevels.LOG_EXT_TRACE, 'bag weight: ' + weight)
        appLog(TraceLevels.LOG_EXT_TRACE, 'heavy label ' + heavyLabel)
        if (!heavyLabel || heavyLabel === '' || heavyLabel === 'undefined') {
          //heavy label not attached for heavy bag
          //if not valid show error
          appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error heavy bag label.')
          //set app flow 10, so that after the error of attach heavy tag is displayed, user gets a chance to click
          //on the OK button instead of the flow continuing automatically when it detects the heavy tag is attached.
          /* this.setAppFlow(10)
          goToLocalGenericError('heavy label', ErrCodes.BAG_HEAVY_ATTACHLABEL, 'Error_HeavyLabel', 'PutBagOnBelt', {
            heavyWeight: config.heavyBagWeight,
          }) */
          return false
        }
        if (heavyLabel.includes('HEAVY')) {
          return true
        } else {
          //if not valid show error
          appLog(TraceLevels.LOG_EXT_TRACE, 'goto --> Error heavy bag label.')
          //set app flow 10, so that after the error of attach heavy tag is displayed, user gets a chance to click
          //on the OK button instead of the flow continuing automatically when it detects the heavy tag is attached.
          this.setAppFlow(10)
          goToLocalGenericError('heavy label', ErrCodes.BAG_HEAVY_ATTACHLABEL, 'Error_HeavyLabel', 'PutBagOnBelt', {
            heavyWeight: config.heavyBagWeight
          })
          return false
        }
      }
    }
    return true
  }
  // return false when not valid
  validateWeightAndDimensions(weight, height, len, width, tubStatus, tubProvided, bagtag) {
    // check if all values in SBD limits
    let tubUsed = tubStatus === '1'
    let tubRequirementCheck = tubStatus === '2'
    if (tubRequirementCheck) {
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_REQUIRE_USEBIN,
        'Error_BagRequireUseBin',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    }
    // Check weight
    let weightCheck = this.checkWeight(weight, this.bagMaxWeight, this.bagMaxWeightUnit, tubUsed)
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp weight: ' + weightCheck)
    //let errMsg = ''
    if (weightCheck < 0) {
      let param0 = ''
      let param1 = config.customBagWeightUnit ? config.customBagWeightUnit : this.bagdropLimits.measurement
      switch (weightCheck) {
        case -1:
          param0 = this.bagdropLimits.upperScaleLimit
          if (this.getNumberOfNotActivatedBags() > 1) {
            // this.store.dispatch(
            //   updateError(ErrCodes.BAG_MAX_WEIGHT_ADDBAG, '', 'commandCompleted appFlow 5', param0, param1)
            // )
            this.checkMaxReposition(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_MAX_WEIGHT_ADDBAG,
              'Error_BagOverweightMaxAddBag',
              'PutBagOnBelt',
              { maxWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
          } else {
            this.setAppFlow(0)
            // this.store.dispatch(updateError(ErrCodes.BAG_MAX_WEIGHT, '', 'commandCompleted appFlow 5', param0, param1))
            goToLocalGenericError(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_MAX_WEIGHT,
              'Error_BagOverweightMax',
              'END_TXN',
              { maxWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
            // history.push('error')
          }
          break
        case -2:
          param0 = this.bagdropLimits.lowerScaleLimit
          if (config.useBinForSmallBags) {
            // this.store.dispatch(
            //   updateError(ErrCodes.BAG_UNDERWEIGHT_USEBIN, '', 'commandCompleted appFlow 5', param0, param1)
            // )
            if (tubProvided) {
              this.checkMaxReposition(
                'commandCompleted appFlow 5',
                ErrCodes.BAG_UNDERWEIGHT_USEBIN,
                'Error_BagUnderweightUseBin',
                'PutBagOnBelt',
                { minWeight: param0, weightUnit: param1 },
                {
                  tagNumber: bagtag
                }
              )
            } else {
              // if tub not provide from airport
              this.checkMaxReposition(
                'commandCompleted appFlow 5',
                ErrCodes.BAG_UNDERWEIGHT_USEBIN,
                'Error_BagUnderweightUseBin',
                'PutBagOnBelt',
                { minWeight: param0, weightUnit: param1 },
                {
                  tagNumber: bagtag
                }
              )
            }
          } else if (this.getNumberOfNotActivatedBags() > 1) {
            // this.store.dispatch(
            //   updateError(ErrCodes.BAG_UNDERWEIGHT_ADDBAG, '', 'commandCompleted appFlow 5', param0, param1)
            // )
            this.checkMaxReposition(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_UNDERWEIGHT_ADDBAG,
              'Error_BagUnderweightAddBag',
              'PutBagOnBelt',
              { minWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
          } else {
            this.setAppFlow(0)
            // this.store.dispatch(updateError(ErrCodes.BAG_UNDERWEIGHT, '', 'commandCompleted appFlow 5', param0, param1))
            goToLocalGenericError(
              'commandCompleted appFlow 5',
              ErrCodes.BAG_UNDERWEIGHT,
              'Error_BagUnderweight',
              'END_TXN',
              { minWeight: param0, weightUnit: param1 },
              {
                tagNumber: bagtag
              }
            )
            // history.push('error')
          }
          break
        default:
          // errMsg = ErrCodes.BAG_UNABLE_TO_WEIGHT
          // this.store.dispatch(updateError(errMsg, '', 'commandCompleted appFlow 5'))
          this.setAppFlow(0)
          goToLocalGenericError(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNABLE_TO_WEIGHT,
            'Error_UnableToWeigh',
            'END_TXN',
            null,
            {
              tagNumber: bagtag
            }
          )
          // history.push('error')
          break
      }
      return false
    }

    // Check height
    let heightCheck = this.checkDimension(1, height, tubUsed)
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp height: ' + heightCheck)
    if (heightCheck === -1) {
      // this.store.dispatch(updateError(ErrCodes.BAG_TOO_TALL, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_TOO_TALL,
        'Error_BagTooTall',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    } else if (heightCheck === -2) {
      if (config.useBinForSmallBags) {
        // this.store.dispatch(updateError(ErrCodes.BAG_UNDERHEIGHT_USEBIN, '', 'commandCompleted appFlow 5'))
        if (tubProvided) {
          this.checkMaxReposition(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNDERHEIGHT_USEBIN,
            'Error_BagUnderheightUseBin',
            'PutBagOnBelt',
            null,
            {
              tagNumber: bagtag
            }
          )
        } else {
          // if tub not provide from airport
          this.checkMaxReposition(
            'commandCompleted appFlow 5',
            ErrCodes.BAG_UNDERHEIGHT,
            'Error_BagUnderheight',
            'PutBagOnBelt',
            null,
            {
              tagNumber: bagtag
            }
          )
        }
      } else if (this.getNumberOfNotActivatedBags() > 1) {
        // this.store.dispatch(updateError(ErrCodes.BAG_UNDERHEIGHT_ADDBAG, '', 'commandCompleted appFlow 5'))
        this.checkMaxReposition(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_UNDERHEIGHT_ADDBAG,
          'Error_BagUnderheightAddBag',
          'PutBagOnBelt',
          null,
          {
            tagNumber: bagtag
          }
        )
      } else {
        this.setAppFlow(0)
        // this.store.dispatch(updateError(ErrCodes.BAG_UNDERHEIGHT, '', 'commandCompleted appFlow 5'))
        goToLocalGenericError(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_UNDERHEIGHT,
          'Error_BagUnderheight',
          'END_TXN',
          null,
          {
            tagNumber: bagtag
          }
        )
        // history.push('error')
      }
      return false
    } else if (heightCheck < -2) {
      // this.store.dispatch(updateError(ErrCodes.BAG_UNABLE_TO_MEASURE, '', 'commandCompleted appFlow 5'))
      this.setAppFlow(0)
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_UNABLE_TO_MEASURE,
        'Error_UnableToMeasure',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      // history.push('error')
      return false
    }

    // Check length
    const lengthCheck = this.checkDimension(2, len, tubUsed)
    appLog(TraceLevels.LOG_EXT_TRACE, 'cmdComp length: ' + lengthCheck)
    if (lengthCheck === -1) {
      if (this.getNumberOfNotActivatedBags() > 1) {
        // this.store.dispatch(updateError(ErrCodes.BAG_LONG_ADDBAG, '', 'commandCompleted appFlow 5'))
        this.checkMaxReposition(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_LONG_ADDBAG,
          'Error_BagLongAddBag',
          'PutBagOnBelt',
          null,
          {
            tagNumber: bagtag
          }
        )
      } else {
        this.setAppFlow(0)
        // this.store.dispatch(updateError(ErrCodes.BAG_LONG, '', 'commandCompleted appFlow 5'))
        goToLocalGenericError('commandCompleted appFlow 5', ErrCodes.BAG_LONG, 'Error_BagLong', 'END_TXN', null, {
          tagNumber: bagtag
        })
        // history.push('error')
      }
      return false
    } else if (lengthCheck === -2) {
      // check if height to length ratio is is OK
      if (this.overHtoLratio(config.maxHtoLratioTooShort, height, len))
        // this.store.dispatch(updateError(ErrCodes.BAG_RATIO_TOO_HIGH, '', 'commandCompleted appFlow 5'))
        this.checkMaxReposition(
          'commandCompleted appFlow 5',
          ErrCodes.BAG_RATIO_TOO_HIGH,
          'RepositionBag2',
          'PutBagOnBelt',
          null,
          {
            tagNumber: bagtag
          }
        )
      // continue with bag too short
      else {
        // this.store.dispatch(updateError(ErrCodes.BAG_SHORT, '', 'commandCompleted appFlow 5'))
        let tempmsgCode = config.useBinForSmallBags && tubProvided ? 'RepositionBag4' : 'RepositionBag3'
        this.checkMaxReposition('commandCompleted appFlow 5', ErrCodes.BAG_SHORT, tempmsgCode, 'PutBagOnBelt', null, {
          tagNumber: bagtag
        })
      }
      return false
    } else if (lengthCheck < -2) {
      // this.store.dispatch(updateError(ErrCodes.BAG_UNABLE_TO_MEASURE, '', 'commandCompleted appFlow 5'))
      this.setAppFlow(0)
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_UNABLE_TO_MEASURE,
        'Error_UnableToMeasure',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      // history.push('error')
      return false
    }
    // check vertical
    // check if height to length ratio is is OK
    if (this.overHtoLratio(config.maxHtoLratioAll, height, len)) {
      // this.store.dispatch(updateError(ErrCodes.BAG_RATIO_TOO_HIGH, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_RATIO_TOO_HIGH,
        'RepositionBag2',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    }

    // check if height to width ratio is is OK (when width is available)
    if (this.overHtoWratio(config.maxHtoWratioAll, height, width)) {
      // this.store.dispatch(updateError(ErrCodes.BAG_RATIO_TOO_HIGH, '', 'commandCompleted appFlow 5'))
      this.checkMaxReposition(
        'commandCompleted appFlow 5',
        ErrCodes.BAG_RATIO_TOO_HIGH,
        'RepositionBag2',
        'PutBagOnBelt',
        null,
        {
          tagNumber: bagtag
        }
      )
      return false
    }

    return true // All OK
  }

  getOverrideCode(bagtag) {
    let ovr = this.store.getState().localData.overrideCode
    if (ovr && bagtag && ovr.bagtag === bagtag) {
      return ovr.code
    } else {
      return ''
    }
  }
  getAirLineInfoFromBagtag(bagtag) {
    const airlineCode = bagtag.substring(1, 4)
    const airLine = config.transferInfo.find((item) => {
      return item.digitCode === airlineCode
    })
    return airLine
  }
  BSErrorHandle(msg, appFlow, errCode, errMsg) {
    appLog(TraceLevels.LOG_EXT_TRACE, 'BS indicates an error condition: ' + msg['BS'])
    goToLocalGenericError(appFlow, errCode, errMsg, 'END_TXN', null)
  }
}