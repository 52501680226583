import React, { useState, Fragment, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { IconButton } from 'components'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import useMedia from 'hooks/useMedia'

const CancelAct = (props) => {
  const intl = useIntl()
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const themeContext = useContext(ThemeContext)

  return (
    <>
      <IconButton
        id={'cancelBtn'}
        cssName={props.cancelBtnStyle}
        color={themeContext.CancelButton.color}
        bgColor={themeContext.CancelButton.bgColor}
        width={themeContext.CancelButton.width[ratioKey]}
        height={themeContext.CancelButton.height[ratioKey]}
        disable={props.disableAction}
        onClick={props.cancelAction || null}
        fontSize={themeContext.CancelButton.fontSize[ratioKey]}
        iconLocation={themeContext.CancelButton.buttonPosition}
        isLeftBtn={true}
        text={props.cancelBtnText}
        icon={'cancel-icon.svg'}
        iconColor={themeContext.CancelButton.iconColor}
      />
    </>
  )
}
CancelAct.propTypes = {
  cancelBtnStyle: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelAction: PropTypes.func,
  cancelBtnStyle: PropTypes.string
}

export default CancelAct
