import { getTSDManager, history } from 'main'
import { replacer } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { TraceLevels } from 'embross-device-manager'

function buildTSDdata(kioskId, airportCode, events) {
  let txnStateLogs = []
  for (let i = 0; i < events.length; i++) {
    let obj = Object.assign({}, events[i], { eventSource: kioskId, locationID: airportCode })
    txnStateLogs.push(obj)
  }
  appLog(TraceLevels.LOG_EXT_TRACE, 'buildTSDdata: ' + JSON.stringify(txnStateLogs, replacer))
  return txnStateLogs
}

//post current events and clear sent events
export function postTSD(kioskId, airportCode) {
  if (!config.sendStats) {
    appLog(TraceLevels.LOG_TRACE, 'postTSD.js - sendStats is false. exit.')
    return
  }
  let events = getTSDManager().getEvents()
  if (Array.isArray(events)) {
    let data = buildTSDdata(kioskId, airportCode, events)
    let fullUrl = config.statsServer + config.stats_path + config.stats_uri

    appLog(TraceLevels.LOG_TRACE, 'postTSD.js - post: ' + data.length)
    fetch(fullUrl, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      mode: 'no-cors',
      body: JSON.stringify(data),
    })
      .then(function (data) {
        console.log('postTSD request succeeded with JSON response', data)
      })
      .catch(function (error) {
        console.log('postTSD request failed', error)
      })
  }
}
