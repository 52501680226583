/***************************************************************************************
 * this is first screen of this application, it start with the following scenarios:
 * 1. single app without attract loop (Dedicated or Persistent Single-application Mode)
 * 2. multi app mode active by transfer from other app (bio-matrix), receive pnr number
 * 3. multi app or single app mode with attract loop
 *
 ***************************************************************************************/
import { identifyService } from 'actions/etsTransactions/identifyService'
import { updateLocalData } from 'actions/localActions'
import { DynamicImage, PageContent, PageSubContent, PageSubTitle, PageTitle } from 'components/styledComponents'
import { FunctionCodes, ServiceNames } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan } from 'main'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, populateItineraryInfo } from 'utils/helper'
import { Footer } from '../footer'

const hazMatItems = [
  'BagsHazMat_Weapons',
  'BagsHazMat_Fireworks',
  'BagsHazMat_Flammable',
  'BagsHazMat_Batteries',
  'BagsHazMat_CompressedGas',
  'BagsHazMat_HouseholdItems',
  'BagsHazMat_Corrosives',
  'BagsHazMat_Other'
]

const DangerItem = ({ item }) => {
  const intl = useIntl()
  const isLandscape = useCheckLandscape()

  return (
    <div
      id={item}
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: isLandscape ? '8px 0' : '0',
        gap: '19px'
      }}
    >
      <DynamicImage imageName={`${item}.svg`} cssName={'animation'} width={70} height={70} />
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'left',
          width: '100%'
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '25px'
          }}
        >
          {intl.formatMessage(messages[item])}
        </div>
        <div
          style={{
            fontSize: '20px'
          }}
        >
          {intl.formatMessage(messages[`${item}_desc`])}
        </div>
      </div>
    </div>
  )
}

const langBtnRequired = true
const HazMatQuestion = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const bottomFontSize = useMedia(null, [32, 25, 30], 30)
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const isLandscape = useCheckLandscape()
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  //const airportCode = useSelector(state => state.kioskInfo.airportCode)

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  const handleAccessibility = () => {
    const title = formatMessage(messages.HazMatTitle)
    const hazMatDetails = hazMatItems.map(
      (item) => `${formatMessage(messages[item])}. ${formatMessage(messages[`${item}_desc`]).replace('\n', ' ')}..`
    )
    const subTitle = formatMessage(messages.HazMatSubTitle)
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined

    const accDef = {
      pathName: 'HazMatQuestion',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isLangRequired: langBtnRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicTextWithDownKey',
            textParameters: [`${title}. ${hazMatDetails.join(' ')} ${subTitle}.`]
          },
          { id: 'confirmBtn', textId: 'NavConfirm', buttonId: 'confirmBtn' }
        ]
      }
    }
    buildAccessibility(accDef)
  }

  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const handleActions = (e) => {
    const aeaBagDrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
    switch (e.currentTarget.id) {
      case 'backBtn':
        // TODO: Call identify service once ETS is ready
        // dispatch(identifyService(FunctionCodes.BYPASSED, ServiceNames.SBD_PAX_VALIDATION))
        getSBDAppMan().doQuit(ErrCodes.USER_QUIT, '')
        break
      case 'confirmBtn':
        logEvents(EventFlows.DangerousGoods, EventFunctions.UserAcceptance, 'Accepted')
        dispatch(identifyService(FunctionCodes.SET_COMPLETE, ServiceNames.DANGEROUS_GOODS_QUESTIONS))
        break
      case 'bagMissing':
        dispatch(updateLocalData('appFlow', 21))
        aeaBagDrop.onDeviceEvent({
          key: 'unsolicitedMessage',
          value: 'HDCSQNI#OS=0#BC=0#CB=0#SW=0#BB=0#BS=0#CA=0#SD=NORM'
        })
        break
      default:
        break
    }
  }

  let testButtons = [
    /*  {
       id: 'normalDESC',
       text: 'Normal Cases:',
       handler: handleActions,
       group: 0
     }, */
    {
      id: 'bagMissing',
      text: 'bag missing',
      handler: handleActions,
      group: 0
    }
  ]
  const dangerMessage = () => {
    const left = []
    const right = []
    const allInOne = []
    if (isLandscape) {
      for (let i = 0; i < hazMatItems.length; i++) {
        if (i < hazMatItems.length / 2) {
          left.push(<DangerItem key={'item' + i} item={hazMatItems[i]} />)
        } else {
          right.push(<DangerItem key={'item' + i} item={hazMatItems[i]} />)
        }
      }
      return (
        <PageContent flexFlow="rows" width="100%" gap="55px" padding="0px 36px">
          <PageSubContent width={'100%'} justifyContent={'space-evenly'} flex={1} padding="0px">
            {left}
          </PageSubContent>
          {/* <Spacer width={'2%'} /> */}
          <PageSubContent width={'100%'} justifyContent={'space-evenly'} flex={1} padding="0px">
            {right}
          </PageSubContent>
        </PageContent>
      )
    } else {
      for (let i = 0; i < hazMatItems.length; i++) {
        allInOne.push(<DangerItem key={'item' + i} item={hazMatItems[i]} />)
      }
      return (
        <PageSubContent width={'100%'} justifyContent={'space-evenly'} flex={1} padding={'0'}>
          {allInOne}
        </PageSubContent>
      )
    }
  }

  const animationSection = dangerMessage()

  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        color={themeContext.PrimaryFontColor}
        height="80px"
        fontWeight={500}
        style={{ paddingBottom: isLandscape ? '' : '20px' }}
      >
        {formatMessage(messages.HazMatTitle)}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor} fontSize={'25px'}>
        {formatMessage(messages.HazMatSubTitle)}
      </PageSubTitle>
    </>
  )

  const header = null //<PageHeader alignItems="center">{formatMessage(messages.HazMatHeader)}</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={true}
      quitBtnText={formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={true}
      isLangRequired={langBtnRequired}
      confirmBtnText={formatMessage(messages.buttonConfirm)}
      confirmAction={handleActions}
      testData={testButtons}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header, topSection: textSection, bottomSection: animationSection, footer },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default HazMatQuestion
