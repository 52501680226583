import Listener from './listener'
import { TraceLevels, IrisCameraErrors } from 'constants/Constants'
//import {getKeyByValue} from 'utils/helper'

/** Fingerprint Scanner listener.
 *  @extends {Listener}
 */
export default class IrisCameraListener extends Listener
{
  /**
   *  Default dataAvailable callback.
   *  @param {String[]} data ...
   *  @example
   */
  dataAvailable(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.dataAvailable() called')
    this.setEvent('dataAvailable', data)
  }
  /**
   *  Default mediaPresent callback.
   */
  mediaPresent()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.mediaPresent() called')
    this.setEvent('mediaPresent', null)
  }
  /**
   *  Default mediaAbsent callback.
   */
  mediaAbsent()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.mediaAbsent() called')
    this.setEvent('mediaAbsent', null)
  }
  
  /**
   *  Default dataError callback.
   *  @param {number} status
   *    status values:

 */
  dataError(status)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.dataError() called: ' + status)
    let errCode = IrisCameraErrors.DATA_ERROR_ERROR_UNKNOWN
    try {
      errCode = parseInt(status, 10)
    } catch (err) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.dataError() error parsing: ' + status)
    }
    /*
    let desc = getKeyByValue(IrisCameraErrors, 44)
    try {
      desc = getKeyByValue(IrisCameraErrors, errCode)
    } catch (err2) {
      this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.dataError() error converting to key: ' + status)
    }
    this.setEvent('dataError', [status, desc])
    */
    this.setEvent('dataError', errCode)
  }

  /**
   *  Default setup callback.
   *  @param {number} code return code from setup
   */
  setup(code)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.setup() called')
    this.setEvent('setup', code)
  }
  /**
   *  Default getVersion callback.
   *  @param {String} data
   */
  getVersion(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.getVersion() called')
    this.setEvent('getVersion', data)
  }
  /**
   *  Default imageStatus callback.
   *  @param {String} data left image status (OK, fake, maybe fake) and right image status (OK, fake, maybe fake)
   */
  imageStatus(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.imageStatus() called: ' + data)
    this.setEvent('imageStatus', data)
  }
  /**
   *  Default playAudio callback.
   *  @param {String} data language id, message id, language string
   */
  playAudio(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.playAudio() called: ' + data)
    this.setEvent('playAudio', data)
  }
  
  /**
   *  Default irisCameraCapture callback.
   *  @param {String} data array of 10 contains:
   *                       responseStatus (0 OK, 1 not provided),
   *                       pImageRC (primary image return code),
   *                       sImageRC (secondary image return code),
   *                       fImageRC (face image return code),
   *                       pImage (primary image if present),
   *                       pRawImage (primary raw image if present),
   *                       sImage (secondary image if present),
   *                       sRawImage (secondary image if present),
   *                       fImage (face image if present),
   *                       hasImagerAcquiredAllData
   */
  irisCameraCapture(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.irisCameraCapture() called: ' + data)
    this.setEvent('irisCameraCapture', data)
  }
  
  /**
   *  Default irisCameraEnroll callback.
   *  @param {String} data array of 21 contains:
   *                       responseStatus (0 OK, 1 not provided),
   *                       isIrisCaptureOK,
   *                       isEnrollOK,
   *                       lastName,
   *                       firstName,
   *                       gender,
   *                       userId,
   *                       imageQualityThreshold,
   *                       rightImageStatus,
   *                       leftImageStatus,
   *                       rightIrisQuality,
   *                       leftIrisQuality,
   *                       primaryImageRc,
   *                       secondaryImageRc,
   *                       faceImageRc,
   *                       hasImagerAcquiredAllData,
   *                       primaryImage(base64),
   *                       primaryRawImage(base64),
   *                       secondaryImage(base64),
   *                       secondaryRawImage(base64),
   *                       faceImage(base64)
   */
  irisCameraEnroll(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.irisCameraEnroll() called: ' + data)
    this.setEvent('irisCameraEnroll', data)
  }
  
  /**
   *  Default irisCameraRecognize  callback.
   *  @param {String} data array of 16 contains:
   *                       responseStatus (0 OK, 1 not provided),
   *                       isIrisCaptureOK,
   *                       isRecognizeOK,
   *                       lastName,
   *                       firstName,
   *                       gender,
   *                       userId,
   *                       rightImageStatus,
   *                       leftImageStatus,
   *                       primaryImageRc,
   *                       secondaryImageRc,
   *                       hasImagerAcquiredAllData,
   *                       primaryImage(base64),
   *                       primaryRawImage(base64),
   *                       secondaryImage(base64),
   *                       secondaryRawImage(base64),
   */
  irisCameraRecognize(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.irisCameraRecognize() called: ' + data)
    this.setEvent('irisCameraRecognize', data)
  }
  /**
   *  Default captureStatus callback.
   *  @param {String} data iris capture status
   */
  captureStatus(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.captureStatus() called: ' + data)
    this.setEvent('captureStatus', data)
  }
  /**
   *  Default lensStatus callback.
   *  @param {String} data left and right iris lens statuses
   */
  lensStatus(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.lensStatus() called: ' + data)
    this.setEvent('lensStatus', data)
  }
  /**
   *  Default captureCompleted callback.
   */
  captureCompleted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.captureCompleted() called.')
    this.setEvent('captureCompleted')
  }
  /**
   *  Default getRange callback.
   *  @param {String} data range status and distance
   */
  getRange(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.getRange() called: ' + data)
    this.setEvent('getRange', data)
  }
  /**
   *  Default captureAborted callback.
   */
  captureAborted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.captureAborted() called.')
    this.setEvent('captureAborted')
  }
  /**
   *  Default captureFailed callback.
   */
  captureFailed()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.captureFailed() called.')
    this.setEvent('captureFailed')
  }
  /**
   *  Default irisCameraDeleteDB callback.
   *  @param {boolean} data status of the database delete
   */
  irisCameraDeleteDB(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.irisCameraDeleteDB() called. ' + data)
    this.setEvent('irisCameraDeleteDB')
  }
  /**
   *  Default setDeviceLanguage callback.
   *  @param {boolean} data status of the language change
   */
  setDeviceLanguage(data)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'IrisCameraListener.setDeviceLanguage() called. ' + data)
    this.setEvent('setDeviceLanguage')
  }
}
