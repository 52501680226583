import * as React from 'react'
import { Countdown, CountdownActions } from 'components/countdown'
import { DynamicImage } from 'components/styledComponents'
import { ThemeContext } from 'styled-components'
import Frame from 'react-frame-component'
/* interface CountdownLiveViewProp {
  liveUrl: string | null
  countdownDuration?: number
  width: number
  enableCountdown?: boolean
  countdownFontSize?: number
  countdownAction?: CountdownActions
  countdownCallback?: () => void
} */

const CountdownLiveView = ({
  liveUrl,
  countdownDuration = 5,
  width,
  enableCountdown = false,
  countdownFontSize = 60,
  countdownAction = CountdownActions.READY,
  countdownCallback = () => {}
}) => {
  const themeContext = React.useContext(ThemeContext)
  const height = width * themeContext.liveView.aspectRatio
  const liveViewWrapperStyle = {
    display: 'flex', //required
    flexFlow: 'column',
    margin: '0 auto',
    width: width + 'px',
  }
  
  const stream = (
    <Frame
      initialContent={`<!DOCTYPE html><html><head></head><body style="margin: 0"><div id="insertFrame"></div></body></html>`}
      mountTarget="#insertFrame"
      style={{
        width: width + 'px',
        height: height + 'px',
        border: 'none'
      }}
    >
      <div
        style={{
          display: 'flex',
          backgroundImage: liveUrl ? `url(${liveUrl})` : `url('images/placeHolder.svg')`,
          width: width + 'px',
          height: height + 'px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          alignItems: 'flex-end',
          justifyContent: 'center',
          overflow: 'hidden',
          textAlign: 'center'
        }}
      >
        {enableCountdown ? (
        <Countdown
          time={countdownDuration}
          action={countdownAction}
          fontSize={countdownFontSize}
          width={'100%'}
          height={countdownFontSize + 'px'}
          callback={() => {
            if (countdownCallback) {
              countdownCallback()
            }
          }}
        />
      ) : (
        <></>
      )}
      </div>
    </Frame>
  )
  return (
    <div style={liveViewWrapperStyle}>
      {stream}
      
    </div>
  )
}

export default CountdownLiveView
