import Listener from './listener'
import { TraceLevels } from 'constants/Constants'

/** Accessibility listener.
 *  @extends {Listener}
 */
export default class AccessibilityListener extends Listener {

  /**
   *  Default get status headset callback: statusHeadset
   *  @param {number} response status headset
   */
  getStatusHeadset(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.statusHeadset() called: ' + response)
    this.device.headSetStatus = response
    this.setEvent('statusHeadset', response)
  }

  /**
   *  Default get status keypad callback: statusKeypad
   *  @param {number} response status keypad
   */
  getStatusKeypad(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.statusKeypad() called: ' + response)
    this.device.keypadStatus = response
    this.setEvent('statusKeypad', response)
  }

  /**
   *  Default get status TTS callback: statusTTS
   *  @param {number} response status TTS
   */
  getStatusTTS(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.statusTTS() called: ' + response)
    this.device.ttsStatus = response
    this.setEvent('statusTTS', response)
  }
  
  /**
   *  Default headsetInserted callback.  Called when the headset is inserted, will be reported as callback enabled(true) 
   */
  headsetInserted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.headsetInserted() called')
    this.device.enabled = true
    this.setEvent('enabled', true)
  }

  /**
   *  Default isHeadsetAvailable callback.
   *  @param {boolean} response true when headset is available false otherwise
   */
  isHeadsetAvailable(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.isHeadsetAvailable() called')
    this.device.headsetAvailable = response
    this.setEvent('isHeadsetAvailable', response)
  }
  
  /**
   *  Default isHeadsetInserted callback.
   *  @param {boolean} response true when headset is inserted false otherwise
   */
  isHeadsetInserted(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.isHeadsetInserted() called')
    this.setEvent('isHeadsetInserted', response)
  }
  
  /**
   *  Default headsetRemoved callback.  Called when the headset is removed, will be reported as callback enabled(false) 
   */
  headsetRemoved()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.headsetRemoved() called')
    this.device.enabled = false
    this.setEvent('enabled', false)
  }
  
  /**
   *  Default isKeypadAvailable callback.
   *  @param {boolean} response true when keypad is available false otherwise
   */
  isKeypadAvailable(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.isKeypadAvailable() called')
    this.setEvent('isKeypadAvailable', response)
  }
  
  /**
   *  Default isTTSAvailable callback.
   *  @param {boolean} response true when TTS is available false otherwise
   */
  isTTSAvailable(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.isTTSAvailable() called')
    this.setEvent('isTTSAvailable', response)
  }
  
  /**
   *  Default keyPressed callback.  Called when the accessible keypad key is pressed
   *  @param {string} msg  key code
   */
  keyPressed(msg)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.keyPressed() called: ' + msg)
    this.setEvent('keyPressed', msg)
  }
  
  /**
   *  Default speechCompleted callback.  Called when the speech is completed
   */
  speechCompleted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.speechCompleted() called')
  }
  
  /**
   *  Default speechPaused callback.  Called when the speech is paused
   */
  speechPaused()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.speechPaused() called')
  }
  
  /**
   *  Default speechStarted callback.  Called when the speech is started
   */
  speechStarted()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.speechStarted() called')
  }
  
  /**
   *  Default speechStopped callback.  Called when the speech is stopped
   */
  speechStopped()
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.speechStopped() called')
  }

  /**
   *  Default isAccessibilitySupported callback.
   *  @param {boolean} response true when accessibility is supported false otherwise
   */
  isAccessibilitySupported(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.isAccessibilitySupported() called: ' + response)
    this.setEvent('isAccessibilitySupported', response)
  }

  /**
   *  Default speak callback.
   *  @param {string} response
   */
  speak(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.speak() called: ' + response)
    this.setEvent('speak', response)
  }
  
  /**
   *  Default play callback.
   *  @param {string} response
   */
  play(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.play() called: ' + response)
    this.setEvent('play', response)
  }

  /**
   *  Default enable keypad callback.
   *  @param {string} response
   */
  enableKeypad(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.enableKeypad() called: ' + response)
  }

  /**
   *  Default emable TTS callback.
   *  @param {string} response
   */
  enableTTS(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.enableTTS called: ' + response)
  }

  /**
   *  Default confirmAccessibleMode callback.
   *  @param {string} response
   */
  confirmAccessibleMode(response) {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'CUSSAccessibilityListener.confirmAccessibleMode called: ' + response)
  }
  
  /**
   *  Default getHeadsetDeviceHelp callback.
   *  @param {string} response headset device help from platform
   */
  getHeadsetDeviceHelp(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getHeadsetDeviceHelp() ' + this.device.name)
    this.setEvent('getHeadsetDeviceHelp', response)
  }
  
  /**
   *  Default getKeypadDeviceHelp callback.
   *  @param {string} response keypad device help from platform
   */
  getKeypadDeviceHelp(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getKeypadDeviceHelp() ' + this.device.name)
    this.setEvent('getKeypadDeviceHelp', response)
  }
  
  /**
   *  Default getKeypadsEvents callback.
   *  @param {string} response keypad events from platform
   */
  getKeypadEvents(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getKeypadEvents(): ' + response)
    this.setEvent('getKeypadEvents', response)
  }
  
  /**
   *  Default getKeypadType callback.
   *  @param {string} response keypad type from platform
   */
  getKeypadType(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getKeypadType(): ' + response)
    this.setEvent('getKeypadType', response)
  }
  
  /**
   *  Default getAvailableLanguages callback.
   *  @param {string} response available languages for accessibility  from platform
   */
  getAvailableLanguages(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getAvailableLanguages(): ' + response)
    this.setEvent('getAvailableLanguages', response)
  }
  
  /**
   *  Default getDeviceHelpLanguages callback.
   *  @param {string} response available languages for accessibility  from platform
   */
  getDeviceHelpLanguages(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getDeviceHelpLanguages(): ' + response)
    this.setEvent('getDeviceHelpLanguages', response)
  }
  
  /**
   *  Default getDefaultLanguage callback.
   *  @param {string} response default language for accessibility  from platform
   */
  getDefaultLanguage(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getDefaultLanguage(): ' + response)
    this.setEvent('getDefaultLanguage', response)
  }
  
  /**
   *  Default getScreenDiagonal callback.
   *  @param {string} response screen diagonal in mm
   */
  getScreenDiagonal(response)
  {
    this.logMessage(TraceLevels.LOG_EXT_TRACE, 'Listener.getScreenDiagonal(): ' + response)
    this.setEvent('getScreenDiagonal', response)
  }
}
