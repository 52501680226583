import styled from 'styled-components'

const screenSize = {
  landscape1920: `(min-width: 1281px) and (orientation: landscape)`,
  landscape1280: `(min-width: 1025px) and (max-width: 1919px) and (orientation: landscape)`,
  landscape1024: `(min-width: 1024px) and (max-width: 1279px) and (orientation: landscape)`,
  portrait768: `(max-width: 768px) and (orientation: portrait)`,
  portrait1080: `(min-width: 769px) and (orientation: portrait)`
}

const flexbox = (props) => {
  let flexFlow = 'rows'
  let justifyContent = 'center'
  let alignItems = 'center'
  if (props) {
    if (props.flexFlow) {
      flexFlow = props.flexFlow
    }
    if (props.justifyContent) {
      justifyContent = props.justifyContent
    }
    if (props.alignItems) {
      alignItems = props.alignItems
    }
  }
  return `
    display: flex;
    flex-flow: ${flexFlow};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
  `
}
export const Page = styled.div`
  ${flexbox({ flexFlow: 'column', justifyContent: 'flex-start', alignItems: 'center' })}
  text-align: center;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  color: ${(props) => props.theme.PrimaryFontColor || '#ddeff0'};
  background-color: ${(props) => props.theme.BackgroundColor || '#ddeff0'};
  ${(props) =>
    props.theme.BackgroundImage &&
    `
    background: url(${props.theme.BackgroundImage}) no-repeat center center fixed;
    background-size: cover;
  `}
`

export const MainHeader = styled.div`
  ${flexbox({ flexFlow: 'row', justifyContent: 'center', alignItems: 'center' })}
  box-sizing: border-box;
  width: 100%;
  padding: ${(props) => props.padding};
  color: ${(props) => props.theme.Header.color};
  background-color: ${(props) => props.theme.Header.bgColor};
  ${(props) => {
    return props.theme.Header.bgImage ? `background: url(${props.theme.Header.bgImage});` : ''
  }}
  background-repeat: no-repeat;
  background-size: cover;
  height: ${(props) => props.height};
`
export const PagePanel = styled.div`
  ${flexbox({ flexFlow: 'column' })}
  flex-flow: ${(props) => props.flexFlow};
  flex: 1;
  text-align: center;
  width: ${(props) => props.width || `calc(100% - 100px)`};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor || 'white'};
  border-radius: 10px;
  margin: ${(props) => props.margin || '50px'};
  padding: ${(props) => props.padding || '50px'};
`

export const AppTitle = styled.div`
  ${flexbox({ justifyContent: 'flex-end', alignItems: 'center' })}
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.appTitleColor};
  @media ${screenSize.landscape1280} {
    font-size: 58px;
    padding-right: 60px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 50px;
    padding-right: 50px;
  }
  @media ${screenSize.portrait} {
    font-size: 47px;
  }
`

export const PageContent = styled.div`
  ${flexbox({ justifyContent: 'center', alignItems: 'center' })}
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-flow: ${(props) => props.flexFlow || 'column'};
  flex: 1;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height};
  box-sizing: border-box;
  padding: ${(props) => props.padding};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => props.background};
  gap: ${(props) => props.gap};
`

export const PageSubContent = styled.div`
  ${flexbox({ flexFlow: 'column' })}
  flex-flow: ${(props) => props.flexFlow};
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  flex: ${(props) => (props.flex ? props.flex : 'none')};
  text-align: ${(props) => props.textAlign || 'center'};
  box-sizing: border-box;
  padding: ${(props) => props.padding || '20px'};
  background: ${(props) => props.background};
`

export const PageHeader = styled.div`
  ${flexbox({ flexFlow: `${(props) => props.flexFlow}` })}
  width: 100%;
  //font-family: 'Lexend SemiBold';
  font-weight: 600;
  letter-spacing: 1.2px;
  color: ${(props) => props.theme.PrimaryFontColor};
  box-sizing: border-box;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  @media ${screenSize.landscape1280} {
    min-height: 90px;
    font-size: 50px;
    top: 0;
  }
  @media ${screenSize.landscape1024} {
    min-height: 80px;
    font-size: 90px;
    top: 0;
  }
  @media ${screenSize.portrait} {
    min-height: 90px;
    font-size: 40px;
    top: 80px;
  }
`

export const PageTitle = styled.div`
  ${flexbox({ flexFlow: `${(props) => props.flexFlow || 'column'}` })}
  width: 100%;
  //font-family: 'Lexend SemiBold';
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize || '40px'};
  min-height: ${(props) => props.minHeight};
  white-space: pre-wrap;
  letter-spacing: 1.4px;
  color: ${(props) => (props.color ? props.color : props.theme.PageTitle.color)};
  align-items: ${(props) => props.alignItems || 'center'};
  height: ${(props) => props.height};
  justify-content: ${(props) => props.theme.TextJustifyContent};
  text-align: ${(props) => props.theme.TextAlign};
  text-transform: ${(props) => (props.theme.TitleCase ? 'capitalize' : '')};
`

export const PageSubTitle = styled.div`
  ${flexbox()}
  width: 100%;
  //font-family: 'Lexend Regular';
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  min-height: ${(props) => props.minHeight || '80px'};
  white-space: pre-wrap;
  letter-spacing: 1px;
  line-height: 1.4;
  color: ${(props) => (props.color ? props.color : props.theme.PageSubTitle.color)};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.theme.TextJustifyContent};
  text-align: ${(props) => props.theme.TextAlign};
  padding-top: ${(props) => props.paddingTop ? props.paddingTop : props.theme.PageSubTitle.paddingTop};
`

export const PageText = styled.div`
  ${flexbox()}
  width: ${(props) => props.width || '100%'};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  min-height: ${(props) => props.minHeight};
  white-space: pre-wrap;
  letter-spacing: 1px;
  //line-height: 40px;
  color: ${(props) => (props.color ? props.color : props.theme.PrimaryFontColor)};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
`

export const DynamicImage = styled.div`
  ${flexbox({ flexFlow: 'column', justifyContent: 'center', alignItems: 'center' })}
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  background-image: url(images/${(props) => props.imageName});
  background-position: ${(props) => props.backgroundPosition || 'center center'};
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${(props) => props.background};
`

export const Spacer = styled.div`
  width: ${(props) => props.width || '1px'};
  height: ${(props) => props.height || '1px'};
`

export const ItineraryTitle = styled.div`
  ${flexbox({ flexFlow: 'column' })}
  width: 100%;
  white-space: pre-wrap;
  @media ${screenSize.landscape1280} {
    height: 100px;
  }
  @media ${screenSize.landscape1024} {
    height: 100px;
  }
  @media ${screenSize.portrait} {
    height: 60px;
  }
`

export const BagProcessingInnerSpan = styled.span`
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  @media ${screenSize.landscape1280} {
    font-size: 23px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 20px;
  }
  @media ${screenSize.portrait} {
    font-size: 3.1vw;
  }
`

export const PageFooter = styled.div`
  ${flexbox({ flexFlow: 'column', justifyContent: 'flex-end', alignItems: 'center' })}
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${(props) => props.theme.Footer.bgColor};
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  ${(props) => {
    return props.theme.Footer.bgImage ? `background: url(${props.theme.Header.bgImage});` : ''
  }}
`

export const ErrorTitle = styled.div`
  ${flexbox({
    flexFlow: 'column',
    justifyContent: 'center'
  })}
  width: 90%;
  font-weight: bold;
  color: ${(props) => props.theme.PageTitleColor};
  white-space: pre-wrap;
  @media ${screenSize.landscape1280} {
    font-size: 40px;
    align-items: flex-start;
    min-height: ${(props) => props.minHeight || '200px'};
  }
  @media ${screenSize.landscape1024} {
    font-size: 35px;
    align-items: flex-start;
    min-height: ${(props) => props.minHeight || '200px'};
  }
  @media ${screenSize.portrait} {
    font-size: 36px;
    margin-top: 50px;
    align-items: center;
  }
`

export const ErrorSubTitle = styled.div`
  ${flexbox({
    flexFlow: 'column',
    justifyContent: 'center'
  })}
  width: 90%;
  color: ${(props) => props.theme.PageSubTitleColor};
  white-space: pre-wrap;
  min-height: ${(props) => props.minHeight || '100px'};
  @media ${screenSize.landscape1280} {
    font-size: 36px;
    align-items: flex-start;
  }
  @media ${screenSize.landscape1024} {
    font-size: 30px;
    align-items: flex-start;
  }
  @media ${screenSize.portrait} {
    font-size: 36px;
    height: 140px;
    align-items: center;
  }
`

export const ErrorDetail = styled.div`
  ${flexbox({ flexFlow: 'column', alignItems: `${(props) => props.alignItems || 'center'}` })}
  width: 90%;
  color: ${(props) => props.theme.PageSubTitleColor};
  white-space: pre-wrap;
  @media ${screenSize.landscape1280} {
    font-size: 24px;
    min-height: 100px;
  }
  @media ${screenSize.landscape1024} {
    font-size: 24px;
    min-height: 80px;
  }
  @media ${screenSize.portrait} {
    font-size: 26px;
    text-align: center;
    min-height: 100px;
  }
`

export const LanguageButton = styled.button`
  width: 298px;
  height: 64px;
  font-size: 26px;
  font-weight: normal;
  color: white;
  background-color: navy;
  margin: 0;
  border: none;
  background-image: url(/images/btn_Language_up.png);
`

export const ButtonBox = styled.div`
  ${flexbox({ flexFlow: 'row' })}
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight};
  height: ${(props) => props.height};
  flex: ${(props) => (props.flex ? props.flex : 'auto')};
  text-align: ${(props) => props.textAlign};
  padding: 20px;
`

export const PopupTitle = styled.div`
  ${flexbox()}
  width: 100%;
  //font-family: 'Lexend SemiBold';
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  white-space: pre-wrap;
  letter-spacing: 0.8px;
  margin: ${(props) => props.margin};
  color: ${(props) => (props.color ? props.color : props.theme.PageTitle.color)};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  text-align: ${(props) => props.theme.TextAlign};
`

export const PopupSubTitle = styled.div`
  ${flexbox()}
  width: 100%;
  //font-family: 'Lexend Light';
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  white-space: pre-wrap;
  letter-spacing: 0.6px;
  color: ${(props) => (props.color ? props.color : props.theme.PageSubTitle.color)};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  text-align: ${(props) => props.theme.TextAlign};
  padding: ${(props) => props.padding};
  padding-top: 20px;
`
