import { DynamicImage, PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, playSound } from 'main'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useMedia from 'hooks/useMedia'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, navigate, populateItineraryInfo } from 'utils/helper'
import { logInfo } from 'utils/Logger'
import { Footer } from '../footer'

const AttachHeavyTag = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const SBDAppManager = getSBDAppMan()
  const themeContext = useContext(ThemeContext)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const title = intl.formatMessage(messages.AttachHeavyTagTitle)
  const subTitle = intl.formatMessage(messages.AttachHeavyTagSubTitle)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const isQuitRequired = !appMode

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const location = useLocation()
  const screenId = getScreenId(location.pathname)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const baggageLoadedStatus = useSelector((state) => state.localData.baggageLoadedStatus)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined

    const accDef = {
      pathName: 'AttachHeavyTag',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [
          { id: 'page-content', textId: 'OneDynamicTextWithDownKey', textParameters: [[title, subTitle].join('. ')] },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonOk), buttonId: 'confirmBtn' }
        ]
      }
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const animationSection = (
    <DynamicImage
      imageName={`${themeContext.AnimationPath}/${sbdModel}/next-bag.png`}
      cssName={'emb_animation_drawbox'}
      width={themeContext.AnimationWidth ? themeContext.AnimationWidth : animationSize}
      height={themeContext.AnimationHeight ? themeContext.AnimationHeight : animationSize}
    />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {title}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        color={themeContext.PrimaryFontColor}
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {subTitle}
      </PageSubTitle>
    </>
  )

  const handleConfirm = () => {
    const aeaBagDrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
    playSound.beepOK()
    SBDAppManager.setAppFlow(2)
    if (config.isCUSSRequired) {
      // Baggage is on belt
      if (baggageLoadedStatus) {
        logInfo('==> (AttachHeavyTag.js) appFlow = 2 send SQ')
        aeaBagDrop.sendAEA('SQ', -10000)
      } else {
        navigate('putBagOnBelt', 2)
      }
    } else {
      navigate('bagProcessing', 3)
    }
  }

  const header = <PageHeader>{intl.formatMessage(messages.TotalWeightHeader)}</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isCancelRequired={false}
      isLangRequired={true}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmBtnText={intl.formatMessage(messages.buttonOk)}
      confirmAction={handleConfirm}
    />
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default AttachHeavyTag
