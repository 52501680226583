/**********************************************************/
// this callback is called when both kioskId and location
// is received from platform. for example we can use this
//callback to change config at kiosk level or location level.

import { config } from 'dotenv'
import { getDeviceManager, getTSDManager } from 'main'

/******************************************************** */
export const kioskIdAndLocationReady = (kioskId, location) => {
  console.log('kioskIdAndLocationReady, kioskId:', kioskId, ', location:', location)
  const tsdMgr = getTSDManager()
  if (tsdMgr) {
    tsdMgr.kioskId = kioskId
    tsdMgr.locationId = location
  }
}
